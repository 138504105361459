import React from "react";
import { useTranslation } from "react-i18next";
import { textTruncate } from "../../utils/functions";

const ItemCard = (props) => {
  const {
    baseCSS = false,
    item: { image = "", title = "", slug = "" },
    showLearnMore = false,
  } = props;
  const { t } = useTranslation("quest_ItemCard");
  return (
    <div className={!baseCSS && "carousel-item"}>
      <img className="carousel-image" src={image || ""} alt="carousel quest" />
      <div className="carousel-title">{textTruncate(title, 80) || ""}</div>
      <div className="carousel-border" />
      {showLearnMore && (
        <a href={`/quests/${slug}`}>
          <div className="carousel-click-through">{t("learnMore")}</div>{" "}
        </a>
      )}
    </div>
  );
};

export default ItemCard;
