/* eslint-disable react/jsx-no-bind */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import ShareIcon from "@material-ui/icons/Share";
import "./PopOver.scss";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    maxWidth: "100%",
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-around",

    "& div": {
      marginTop: "10px",
    },
  },
  paper: {
    position: "absolute",
    overflowX: "unset",
    overflowY: "unset",
    maxWidth: "calc(100vw - 32px)",
    maxHeight: "calc(100vh - 32px)",
    marginTop: "5px",
  },
}));

const theDomain =
  process.env.NODE_ENV === "production"
    ? "https://noiz.gg"
    : process.env.NODE_ENV === "staging"
    ? "https://staging.noiz.gg"
    : "https://staging.noiz.gg";

export default function SimplePopover() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { t } = useTranslation("simplePopover");

  const href = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1,
    window.location.href.length
  );

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="shareBtn" onClick={handleClick}>
        <ShareIcon />
      </div>
      <Popover
        id={id}
        open={open}
        className={`${classes.paper} mui-fixed`}
        anchorEl={anchorEl}
        disableScrollLock
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={`${classes.typography}`}>
          {/* <div></div>
          <div> <FacebookIcon /></div> */}
          <div>
            {" "}
            <EmailShareButton
              url={`${theDomain}/quests/${href}`}
              subject={t("checkOut")}
              body="This be de body"
            >
              {" "}
              <EmailIcon />
            </EmailShareButton>
          </div>
          <div>
            {" "}
            <FacebookShareButton
              url={`${theDomain}/quests/${href}`}
              title={t("checkOut")}
            >
              {" "}
              <FacebookIcon />
            </FacebookShareButton>{" "}
          </div>
          <div>
            {" "}
            <TwitterShareButton
              url={`${theDomain}/quests/${href}`}
              title={t("checkOut")}
            >
              {" "}
              <TwitterIcon />
            </TwitterShareButton>
          </div>
        </div>
      </Popover>
    </>
  );
}
