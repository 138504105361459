import React from "react";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useTranslation } from "react-i18next";
import { analyticsGetSiteWide } from "../../../utils/apiv2";
const AnalyticsButton = (props) => {
  //   const {  } = props;
  //   const onScreen = useOnScreen(ref, '100px', 0.5);
  const [isLoading, setLoading] = React.useState(false);
  const { t } = useTranslation("analyticsPull");
  const { token = null } = props.auth;
  const getAnalytics = async (token) => {
    if (token) {
      setLoading(true);
      const dateString = new Date().toISOString().slice(0, 10);
      try {
        const analytics = await analyticsGetSiteWide();
        const url = window.URL.createObjectURL(new Blob([analytics.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `site_wide_analytics_${dateString}.zip`); // or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      } catch (err) {}
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        color: "white !important",
      }}
    >
      {isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <strong style={{ fontSize: "10px" }}>
              {t("analyticsMessage")}
            </strong>
          </div>
          <div>&nbsp;</div>
          <div>
            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          </div>
        </div>
      )}

      {!isLoading && (
        <Button color="secondary" onClick={() => getAnalytics(token)}>
          <CloudDownloadIcon /> &nbsp; {t("analytics")}
        </Button>
      )}
    </div>
  );
};

export default AnalyticsButton;
