/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */

import React from "react";
import { Link } from "react-router";
import classNames from "classnames";
import moment from "moment-timezone";
import "react-id-swiper/src/styles/css/swiper.css";
import "./Checkout.scss";
import ConnectCheckout from "./ConnectCheckout";
import { withTranslation } from "react-i18next";

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { data: game } = this.props.config.sfgame;
    const { questSlug, username } = this.props.params;
    const { publicKey: stripePublicKey } = this.props.config.stripe;
    let backUrl = "";
    if (questSlug && username) {
      backUrl = `/buy/${game.slug}/${questSlug}/${username}`;
    } else {
      backUrl = `/buy/${game.slug}`;
    }

    let price = game.price;

    if (game.salePrice && game.saleEndDate) {
      const today = moment().tz("America/Los_Angeles");
      const hoursOffset = today.utcOffset() / 60;
      const saleDate = new Date(game.saleEndDate);
      const now = moment(new Date()).add(hoursOffset, "hours").toDate();
      if (saleDate > now) {
        price = game.salePrice;
      }
    }
    const { t } = this.props;
    return (
      <section className="SFGame__checkout">
        <section className="ModalMaster">
          <section
            className={classNames("Modal Modal__store__game__checkout active")}
          >
            <Link
              className="Modal__layer"
              to={backUrl}
              href={backUrl}
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <Link
                className="Modal__close-btn"
                to={backUrl}
                href={backUrl}
                role="button"
                aria-hidden
              />
              <div className="Modal__left">
                <div
                  className="Modal__left__image"
                  style={{ backgroundImage: `url(${game.cover})` }}
                >
                  <div className="Cover__corner__1" />
                  <div className="Cover__corner__2" />
                </div>
                <div className="Modal__left__title">{t("quickCheckout")}</div>
                <div className="Modal__left__description">
                  {t("description")}
                </div>
              </div>
              <div
                className="Modal__right"
                style={{ backgroundImage: `url(${game.cover})` }}
              >
                <ConnectCheckout
                  {...this.props}
                  name="Noiz"
                  description={game.name}
                  amount={price}
                  publicKey={stripePublicKey}
                  slug={game.slug}
                />
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation("checkout")(Checkout);
