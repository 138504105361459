import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";

import { simpleNotification } from "../../../../../utils/notifications";

import CustomTextField from "../../CustomInputs/CustomTextField";
import Icon from "../../../../../global/images/icons/Icon";

import "./DeliverableModal.scss";

const boxStyling = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  width: "vw(80)",
  bgcolor: "#34363a",
  // border: '2px solid #000',
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const DeliverableModal = (props) => {
  const {
    handleCloseModal,
    modal,
    modalContent,
    modalContent: { content, note = "", title },
    handlePostRequirementSubmit,
  } = props;

  const { t } = useTranslation("portalLeft_reviewDeliverable");
  const [rejected, setRejected] = useState(false);
  const [rejectionNote, setRejectionNote] = useState(note);
  const [rejectionError, setRejectionError] = useState(false);

  const handleActions = (action) => {
    const data = { ...modalContent };
    data.status = action;
    if (action === "rejected") {
      data.note = rejectionNote;
    }

    handlePostRequirementSubmit(data);
  };

  return (
    <Modal
      open={modal}
      // onClose={handleCloseModal}
    >
      <Box sx={boxStyling}>
        <div className="DeliverableModal">
          <div className="DeliverableModal__Close" onClick={handleCloseModal}>
            <Icon name="xmark" />
          </div>
          <div className="DeliverableModal__Title">{title}</div>
          {!!content && (
            <div className="DeliverableModal__Content">
              <div className="DeliverableModal__Subheader">
                {t("userSubmitted")}:
              </div>
              <div
                className="DeliverableModal__Content__Submission"
                onClick={() => {
                  navigator.clipboard.writeText(content);
                  simpleNotification({
                    level: "success",
                    title: t("copiedToClipboard"),
                    message: t("submissionCopied"),
                  });
                }}
              >
                {content}
              </div>
            </div>
          )}
          {rejected && (
            <div className="DeliverableModal__Content">
              <CustomTextField
                header={t("rejectionNote")}
                name="rejectionNote"
                onChange={(name, rejectionNote) =>
                  setRejectionNote(rejectionNote)
                }
                value={rejectionNote}
                error={rejectionError}
              />
            </div>
          )}
          <div className="DeliverableModal__Actions">
            {rejected && (
              <div
                className="DeliverableModal__Actions__Button Back"
                onClick={() => setRejected(false)}
              >
                <Icon name="arrowleft" />
              </div>
            )}
            <div
              className="DeliverableModal__Actions__Button Reject"
              onClick={() => {
                if (!rejected) {
                  setRejected(true);
                } else {
                  if (!rejectionNote || rejectionNote?.trim() === "") {
                    setRejectionError(true);
                    simpleNotification({
                      level: "error",
                      title: t("error"),
                      message: t("rejectionNote"),
                    });
                  } else {
                    handleActions("rejected");
                  }
                }
              }}
            >
              <Icon name="xmark" />
            </div>
            {!rejected && (
              <div
                className="DeliverableModal__Actions__Button Approve"
                onClick={() => handleActions("completed")}
              >
                <Icon name="check" />
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeliverableModal;
