/* eslint-disable react/no-array-index-key */

import React from "react";
import Back from "../shared/Back";
import shortid from "shortid";
import { browserHistory } from "react-router";
import { isEmpty, find } from "lodash";
import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";
import Truncate from "react-truncate";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/css/swiper.css";
import { simpleNotification } from "../../utils/notifications";
import { isAuthenticated } from "../../utils/authChecker";
import { dayjs, localizeIncorrectDate } from "../../utils/dateTime";
import "./Game.scss";
import { withTranslation } from "react-i18next";

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.thumbSwiper = null;
    this.masterSwiper = null;
    this.agreeBounty = null;
    this.agreeKey = null;
    this.state = {
      activeTab: 1,
      activeGalleryIndex: 0,
      swiper: null,
    };
  }

  UNSAFE_componentWillMount() {
    if (isAuthenticated()) {
      const { token, user } = this.props.auth;
      const isBasicInfoCompleted =
        user.firstName && user.lastName && user.country;
      if (!isBasicInfoCompleted) {
        this.props.router.replace("/complete-registration");
      } else {
        this.props.getGame(this.props.router.params.gameId, token);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.config.game.isLoading === true &&
      nextProps.config.game.isLoading === false
    ) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.config.game.errors.hasError) {
        if (nextProps.config.game.errors.code === "E_GAME_NOT_AVAILABLE") {
          this.props.router.replace("/not-found");
        } else {
          this.props.router.replace("/not-found");
        }
      }
    }
    if (
      this.props.user.myPreApprovedGameKeys.isRequestingAGameKey === true &&
      nextProps.user.myPreApprovedGameKeys.isRequestingAGameKey === false
    ) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.user.myPreApprovedGameKeys.errors.hasError) {
        simpleNotification({
          level: "error",
          title: nextProps.user.myPreApprovedGameKeys.errors.code,
          message: nextProps.user.myPreApprovedGameKeys.errors.message,
        });
      } else {
        this.openModal("KeyComing");
      }
    }
    if (
      this.props.user.myBounties.isJoiningToABounty === true &&
      nextProps.user.myBounties.isJoiningToABounty === false
    ) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.user.myBounties.errors.hasError) {
        simpleNotification({
          level: "error",
          title: nextProps.user.myBounties.errors.code,
          message: nextProps.user.myBounties.errors.message,
        });
      } else {
        this.changeModalStep(2);
      }
    }
    if (
      this.props.user.myBounties.isLeavingABounty === true &&
      nextProps.user.myBounties.isLeavingABounty === false
    ) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.user.myBounties.errors.hasError) {
        simpleNotification({
          level: "error",
          title: nextProps.user.myBounties.errors.code,
          message: nextProps.user.myBounties.errors.message,
        });
      } else {
        this.closeModal();
      }
    }
  }

  getQuest = (quest, userQuest) => {
    if (quest.comingSoon) {
      return null;
    } else if (
      userQuest &&
      (userQuest.status === "normal" || userQuest.status === "approved")
    ) {
      browserHistory.push(`/my-quests/${quest.id}`);
    } else {
      this.props.router.push(`/quests/${quest.slug || quest.id}`);
    }
  };

  generateGameQuestsHTML(quests, isLoading, myQuests, myUserQuests, game) {
    const { t } = this.props;

    if (game && quests && !isLoading && quests.length > 0) {
      const today = dayjs();
      return quests.map((quest, i) => {
        const userQuest = find(myUserQuests, (o) => o.quest === quest.id);
        let declined = false;
        let applied = false;
        if (userQuest && userQuest.status === "declined") {
          declined = true;
        } else if (userQuest && userQuest.status === "pending") {
          applied = true;
        }

        const alreadyIn = find(
          myQuests,
          (questItem) => questItem.id === quest.id
        );
        let dateInterval;
        if (quest.startDateTime && quest.endDateTime) {
          dateInterval = `${localizeIncorrectDate(quest.startDateTime).format(
            "MM/DD"
          )} - ${localizeIncorrectDate(quest.endDateTime).format("MM/DD")}`;
        }
        /*  <-------- START - End Date Time (ended) Validation -------->  */
        let questEnded = false;
        if (quest.endDateTime) {
          questEnded = localizeIncorrectDate(quest.endDateTime) < today;
        }
        /*  <-------- END - End Date Time (ended) Validation -------->  */
        // START - Button HTML
        let buttonHTML = <React.Fragment />;
        if (questEnded) {
          buttonHTML = (
            <button className="SeeQuest disabled">{t("questEnded")}</button>
          );
        } else if (quest.comingSoon) {
          buttonHTML = (
            <button className="SeeQuest disabled">{t("comingSoon")}</button>
          );
        } else if (applied) {
          buttonHTML = (
            <button className="SeeQuest disabled">{t("applied")}</button>
          );
        } else if (declined) {
          buttonHTML = (
            <button className="SeeQuest disabled">
              <div className="tooltip">
                {t("declined")} <i className="fa fa-info-circle" aria-hidden />
                <span className="tooltiptext">{t("declinedToolTip")}</span>
              </div>
            </button>
          );
        } else if (alreadyIn) {
          buttonHTML = <button className="SeeQuest">{t("joined")}</button>;
        } else {
          buttonHTML = (
            <button
              className={classNames("SeeQuest", {
                disabled:
                  typeof quest.openSpots === "undefined" ||
                  quest.openSpots <= 0,
              })}
            >
              {t("seeQuest")}
            </button>
          );
        }
        // END - Button HTML
        // START - Tiered quest title
        let questTitle = quest.title;
        if (
          quest.type === "tiered" ||
          quest.type === "tiered-one-time" ||
          quest.type === "tiered-multi-days"
        ) {
          const unpaidQuest2 = quest.paymentAmount < 0;
          const hours = Math.floor(quest.requiredStreamTimeMinutes / 60);
          const minutes = quest.requiredStreamTimeMinutes % 60;
          const textMinutes = minutes !== 0 ? ` ${minutes} ${t("minutes")}` : "";
          let textHours = "";
          if (hours === 1) {
            textHours = `1 ${t("hour")}`;
          } else if (hours > 1) {
            textHours = `${hours} ${t("hour")}s`;
          }
          if (quest.paymentAmount === 0 && !unpaidQuest2) {
            questTitle = quest.title;
            if (quest.openSpots > 0 || alreadyIn) {
              questTitle = `${quest.title}`;
            }
          } else if (quest.frequency === "one-time" && !unpaidQuest2) {
            questTitle = `${quest.title} - ${t("playFor")} ${textHours}${textMinutes} ${t("andEarn")}! - $${quest.paymentAmount}`;
          } else if (quest.frequency === "daily" && !unpaidQuest2) {
            questTitle = `${quest.title} - ${t("playFor")} ${textHours}${textMinutes}${t("andEarn")}! - $${quest.paymentAmount}`;
          } else {
            questTitle = quest.title;
          }
        }
        // END - Tiered quest title
        const shouldRender = !quest.preview ? true : !!quest.preview;
        return (
          shouldRender && (
            <section
              key={quest.id}
              className={classNames("QuestCard", {
                "coming-soon": quest.comingSoon,
              })}
              onClick={this.getQuest.bind(this, quest, userQuest)}
              aria-hidden
            >
              <section className="QuestCard__wrapper">
                <div className="QuestCard__image">
                  <figure
                    style={{
                      backgroundImage: `url(${game ? game.cover : ""})`,
                    }}
                    alt={t("gameCover")}
                  />
                </div>

                <section
                  className="QuestCard__level"
                  style={{
                    backgroundImage: "url(/images/shield-blue.svg)",
                    opacity: quest.noizPoints && quest.noizPoints > 0 ? 1 : 0,
                  }}
                >
                  <div className="QuestCard__level--wrapper">
                    <div className="QuestCard__level--count">
                      {quest.noizPoints || 0}
                    </div>
                  </div>
                </section>
                <section className="QuestCard__details">
                  <div className="QuestCard__background-top" />
                  <div className="QuestCard__background-bottom" />
                  <section className="QuestCard__details-wrapper">
                    <section className="QuestCard__details--row">
                      <i
                        className={classNames("QuestCard__details--icon", {
                          "noiz-video-camera": quest.type === "paid bounty",
                          "noiz-game-controller":
                            quest.type === "early access key" ||
                            quest.type === "influencers early access",
                          "noiz-thumbs-up": quest.type === "alpha feedback",
                          "noiz-discount": quest.type === "referral",
                          "fa fa-gear": quest.type === "default",
                          "fa fa-clock-o": quest.type === "timed",
                          "fa fa-external-link": quest.type === "link out",
                        })}
                      />
                      <section className="QuestCard__details--info">
                        <section className="primary-info">
                          <div className="QuestCard__details--name">
                            {questTitle}
                          </div>
                        </section>
                        <section className="sub-info">
                          <div className="QuestCard__details--game-name">
                            {game.name}
                          </div>
                          <div className="QuestCard__details--date-interval">
                            {dateInterval || t("noDate")}
                          </div>
                        </section>
                      </section>
                    </section>
                    <section className="QuestCard__details--row">
                      {/* Button HTML */}
                      {buttonHTML}
                    </section>
                    <section className="QuestCard__details--row">
                      {typeof quest.openSpots !== "undefined" &&
                        quest.openSpots > 0 && (
                          <div
                            className="QuestCard__details--open-slots"
                            style={{
                              color:
                                questEnded || quest.hideOpenSpots
                                  ? "#212227"
                                  : "#83858c",
                            }}
                          >
                            {/* {quest.usersIn || 0}/{quest.openSpots || 0} open spots */}
                            {quest.openSpots - quest.usersIn || 0}{" "}
                            {t("openSpots")}
                          </div>
                        )}

                      {(typeof quest.openSpots === "undefined" ||
                        quest.openSpots <= 0) && (
                        <div
                          className="QuestCard__details--open-slots"
                          style={{
                            color:
                              questEnded || quest.hideOpenSpots
                                ? "#212227"
                                : "#83858c",
                          }}
                        >
                          <span>0 {t("openSpots")}</span>
                        </div>
                      )}
                    </section>
                  </section>
                </section>
                {quest.comingSoon && (
                  <section className="QuestCard__info-panel">
                    <h4 className="QuestCard__info-panel--title">
                      {t("comingSoon")}
                    </h4>
                    <section className="QuestCard__info-panel--details">
                      <Truncate lines={10} ellipsis={<span>...</span>}>
                        {ReactHtmlParser(quest.description)}
                      </Truncate>
                    </section>
                  </section>
                )}
              </section>
              <div className="QuestCard__corner__1" />
              <div className="QuestCard__corner__2" />
              <div className="QuestCard__corner__3" />
              <div className="QuestCard__corner__4" />
            </section>
          )
        );
      });
    }
    // If no active games show this message
    return <div className="NoQuests">{t("NoQuestsAtThisMoment")}.</div>;
  }

  handleTabChange = (param) => {
    if (param !== 2) this.pauseAutoPlay();
    else this.resumeAutoPlay();

    this.setState({
      activeTab: param,
    });
  };

  goToSlide = (index) => {
    this.setState({
      activeGalleryIndex: index,
    });
  };

  pauseAutoPlay = () => {
    if (this.state.swiper && this.state.swiper.autoplay)
      this.state.swiper.autoplay.stop();
  };

  resumeAutoPlay = () => {
    if (this.state.swiper && this.state.swiper.autoplay)
      this.state.swiper.autoplay.start();
  };

  generateThumbnails() {
    return this.props.config.game.data.gallery.map((item, i) => {
      switch (item.type) {
        case "video":
          return (
            <button
              key={`thumb-slide-${i}`}
              onMouseEnter={this.pauseAutoPlay}
              onMouseLeave={this.resumeAutoPlay}
              className={classNames("thumb-slide", {
                active: this.state.activeGalleryIndex === i,
              })}
            >
              <div
                className="thumb-overlay swiper-lazy"
                style={{ backgroundImage: `url('${item.thumb}')` }}
              >
                <div className="play-icon">
                  <i className="play-icon fa fa-play" />
                </div>
              </div>
            </button>
          );
        case "image":
          return (
            <button
              key={`thumb-slide-${i}`}
              onMouseEnter={this.pauseAutoPlay}
              onMouseLeave={this.resumeAutoPlay}
              className={classNames("thumb-slide", {
                active: this.state.activeGalleryIndex === i,
              })}
            >
              <div
                className="thumb-overlay swiper-lazy"
                style={{ backgroundImage: `url('${item.thumb}')` }}
              />
            </button>
          );
        default:
          return (
            <button
              key={`thumb-slide-${i}`}
              onMouseEnter={this.pauseAutoPlay}
              onMouseLeave={this.resumeAutoPlay}
              className={classNames("thumb-slide", {
                active: this.state.activeGalleryIndex === i,
              })}
            >
              <div
                className="thumb-overlay swiper-lazy"
                style={{ backgroundImage: `url('${item.thumb}')` }}
              />
            </button>
          );
      }
    });
  }

  render() {
    const gameGalleryParams = {
      direction: window.innerWidth <= 768 ? "horizontal" : "vertical",
      spaceBetween: 25,
      slidesPerView: "auto",
      centeredSlides: true,
      autoplay: {
        delay: 3000,
      },
      on: {
        slideChange: () => {
          this.setState({ activeGalleryIndex: this.state.swiper.activeIndex });
        },
      },
      speed: 1000,
      scrollBar: false,
      containerClass: "GameGalleryThumb",
      scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
      },
      freeMode: false,
      freeModeMomentum: false,
      slideToClickedSlide: true,
      mousewheel: true,
    };

    const {
      data: game,
      isLoading: isLoadingGame,
      quests,
    } = this.props.config.game;
    const { data: myQuests } = this.props.user.myQuests;
    const { data: myUserQuests } = this.props.user.myUserQuests;

    const swiper = !isEmpty(game) &&
      !isLoadingGame &&
      game.gallery &&
      game.gallery.length > 0 && (
        <Swiper
          ref={(node) => {
            if (node && !this.state.swiper)
              this.setState({ swiper: node.swiper });
          }}
          {...gameGalleryParams}
        >
          {this.generateThumbnails()}
        </Swiper>
      );

    const gameGallery = game.gallery || [];
    const gameCategories = game.categories || [];
    const gameSocials = game.socialNetworks || [];
    const isGame = game && game.sponsorType === "game";

    const { t } = this.props;

    return (
      <section className="Game">
        <section className="Game__container">
          {this.props.auth.token && <Back />}
          <section className="GameInfo">
            <figure
              className="GameInfo__image"
              style={{
                backgroundImage: `url(${game.coverPortrait || game.cover})`,
              }}
            >
              <div className="corner-top-right" />
              <div className="corner-bottom-left" />
            </figure>
            <section className="GameInfo__details">
              <h1 className="GameInfo__details--title">{game.name}</h1>
              <section className="GameInfo__details--bottom">
                <section className="GameInfo__details--description">
                  {ReactHtmlParser(game.description)}
                </section>
                <section className="GameInfo__tags">
                  <div>
                    {isGame ? `${t("developer")}:` : `${t("sponsor")}:`}
                    <span className="tag">{game.developer}</span>
                  </div>
                  {game.publisher && isGame && (
                    <div>
                      {t("publisher")}:{" "}
                      <span className="tag">{game.publisher}</span>
                    </div>
                  )}
                  {isGame && (
                    <>
                      {gameCategories && gameCategories.length > 0 && (
                        <div>
                          {t("categories")}:
                          {gameCategories.map((item) => (
                            <span key={shortid.generate()} className="tag">
                              {item.name}
                            </span>
                          ))}
                        </div>
                      )}
                      <div>
                        {t("releaseDate")}:
                        <span className="tag">
                          {!game.releaseDate || game.releaseDate === "-"
                            ? `${t("comingSoon")}`
                            : dayjs(game.releaseDate).format("MMMM DD, YYYY")}
                        </span>
                      </div>
                    </>
                  )}
                </section>
                <section className="GameInfo__ctas">
                  <section className="GameInfo__ctas--social">
                    {gameSocials.length > 0 &&
                      gameSocials.map((socialNetwork, i) => (
                        <a
                          href={`${socialNetwork.url}`}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          <i className={`fa fa-${socialNetwork.name}`} />
                        </a>
                      ))}
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section className="Games__primary">
            <div className="Games__primary--wrapper">
              <div className="Games__primary--tabs">
                <ul>
                  <li>
                    <button
                      className={classNames("", {
                        active: this.state.activeTab === 1,
                      })}
                      onClick={this.handleTabChange.bind(this, 1)}
                    >
                      {t("quests")}
                     
                    </button>
                  </li>
                  <li>
                    <button
                      className={classNames("", {
                        active: this.state.activeTab === 2,
                      })}
                      onClick={this.handleTabChange.bind(this, 2)}
                    >
                       {t("gallery")}
                      
                    </button>
                  </li>
                </ul>
              </div>
              <div
                className={classNames("PrimaryQuestsList", {
                  active: this.state.activeTab === 1,
                })}
              >
                {game &&
                  quests &&
                  !isLoadingGame &&
                  this.generateGameQuestsHTML(
                    quests,
                    isLoadingGame,
                    myQuests,
                    myUserQuests,
                    game
                  )}
              </div>
              <div
                className={classNames("PrimaryGameMedia", {
                  active: this.state.activeTab === 2,
                })}
              >
                <section className="GameGallery">
                  <section className="GameGallery__wrapper">
                    {gameGallery.length > 0 && (
                      <section
                        className="GameGallery__current-item"
                        onMouseEnter={this.pauseAutoPlay}
                        onMouseLeave={this.resumeAutoPlay}
                        onTouchStart={this.pauseAutoPlay}
                      >
                        {gameGallery[this.state.activeGalleryIndex] &&
                          gameGallery[this.state.activeGalleryIndex].type ===
                            "video" && (
                            <iframe
                              className="youtube-video"
                              title="youtube-video"
                              width="100%"
                              height="100%"
                              src={`${
                                gameGallery[this.state.activeGalleryIndex].url
                              }?autoplay=1&rel=0`}
                              frameBorder="0"
                              allowFullScreen
                            />
                          )}
                        {gameGallery[this.state.activeGalleryIndex] &&
                          gameGallery[this.state.activeGalleryIndex].type ===
                            "image" && (
                            <section
                              className="GameGallery__current-image"
                              style={{
                                backgroundImage: `url('${
                                  gameGallery[this.state.activeGalleryIndex]
                                    .thumb
                                }')`,
                              }}
                            />
                          )}
                      </section>
                    )}
                    {swiper}
                  </section>
                </section>
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

Game.propTypes = {
  // router: PropTypes.shape({
  //   replace: PropTypes.func.isRequired,
  // }).isRequired,
};

export default withTranslation("game")(Game);
