import MobileDetect from "mobile-detect";

/* eslint-disable import/prefer-default-export */
export const localeURI = (uri) => {
  const url = new URL(window.location);
  const { protocol, hostname } = url;
  return url.port === "3000" ? `${protocol}//${hostname}:1337${uri}` : uri;
};

export const md = () => new MobileDetect(window.navigator.userAgent);

export const konami = [
  "ArrowUp",
  "ArrowUp",
  "ArrowDown",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowLeft",
  "ArrowRight",
  "b",
  "a",
];

export const socialNetworks = [
  "facebook",
  "instagram",
  "twitch",
  "twitter",
  "youtube",
];

export const addRedirectFromLogin = (router, route) => {
  router.push(`/sign-in/?redirectto=${route}`);
};

export const pullRedirectParamAndRedirect = (router) => {
  const { redirectto = "" } = router.location.query;
  if (redirectto) {
    router.push(redirectto);
  }
};

export const uploadToS3 = (file, preSignedUrl, eventListener) => {
  const xhr = new XMLHttpRequest();
  xhr.open("PUT", preSignedUrl);
  xhr.setRequestHeader("Content-Type", file.type);
  xhr.addEventListener("load", eventListener);
  xhr.send(file);
};

export const multiLanguage = localStorage.getItem("i18nextLng") || "en";
