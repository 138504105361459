import React from "react";
import classNames from "classnames";
import "./QuestDetailPage.scss";
import { find, without } from "lodash";
import { Link } from "react-router";
import Tooltip from "@material-ui/core/Tooltip";
import {
  genericUrlRegex,
  twitterUrlRegex,
} from "../../../utils/regularExpresions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CustomCheckbox from "./CustomCheckbox";
import Loading from "../../loading/Loading";
import expandMobile from "../../../components/images/quest-details-page/expand-icon-mobile.svg";
import condenseMobile from "../../../components/images/quest-details-page/condense-icon-mobile.svg";
import expandDesktop from "../../../components/images/quest-details-page/expand-icon-desktop.svg";
import condenseDesktop from "../../../components/images/quest-details-page/condense-icon-desktop.svg";
import anime from "animejs";
import {
  QUEST_GRACE_PERIOD_DAYS,
  formatLink,
  getCompletionStatus,
  getDisplayGameTitle,
  getDefaultIcon,
  getNumApprovedRequirements,
  getNumInReviewRequirements,
  getPaymentAmount,
  getQuestHashtagsTitle,
  getQuestRequirements,
  getReferralBonus,
  getStreamTrackTitle,
  getTrackingHashtagsTitle,
  isQuestOver,
  isQuestInGracePeriod,
  preloadRequirement,
  removeCommissionRounding,
  rounding,
  textTruncate,
} from "../../../utils/functions";
import { localizeIncorrectDate, token12v24 } from "../../../utils/dateTime";
import { withTranslation } from "react-i18next";

const MOBILE_SCREEN_WIDTH = 768;

class QuestDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      leaveQuestModal: false,
      leaveQuestReason: null,
      leavingQuest: false,
      leftQuest: false,
      submissions: [],
      errors: [],
      errorMessages: [],
      editRequirementId: false,
      uqRequirements: [],
      quest: false,
      questOver: false,
      questInGracePeriod: false,
      collapsedRequirements: [],
      canCollapseRequirements: [],
      sticky: false,
      activeRequirementId: false,
      mobile: false,
      statusBarOpen: false,
      animatingRequirementId: false,
      navStickyTop: false,
      navStickyBottom: false,
      tooltipOpen: false,
      gracePeriodModal: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  // Handle change to a requirement's input
  handleChange(value, reqId) {
    const { errors, errorMessages, submissions } = this.state;
    submissions[reqId] = value;
    errors[reqId] = false;
    errorMessages[reqId] = false;

    this.setState({
      submissions,
      errors,
      errorMessages,
    });
  }

  isPreview = () => {
    const prev = this.props.router.getCurrentLocation().pathname.split("/")[2];
    return prev === "preview";
  };

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    this.props.getQuest(questId, false, token);
    this.props.getUserReferralsActivity(token);
    this.props.getMyQuestsPageData(token);
    this.setState({ questId });
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener("resize", this.updateWindowWidth);
    document.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
    document.removeEventListener("scroll", this.handleScroll);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { questId } = this.props.router.params;

    // Handle user leaving quest
    if (
      this.props.user.myQuests.isLeavingAQuest &&
      !nextProps.user.myQuests.isLeavingAQuest
    ) {
      this.setState({
        leftQuest: true,
      });
    }

    const { data: myUserQuests, isLoading: myUserQuestsIsLoading } =
      nextProps.user.myUserQuests;
    const { data: userQuestReferral, isLoading: userQuestIsLoading } =
      nextProps.config.userQuestReferral;

    // Recieving requirement data after user submitted something
    if (this.props.config.userQuestReferral.isLoading && !userQuestIsLoading) {
      this.setState({
        uqRequirements: userQuestReferral.requirements,
      });

      // Setting regular requirement data
    } else if (
      this.props.user.myUserQuests.isLoading &&
      !myUserQuestsIsLoading
    ) {
      const { collapsedRequirements, canCollapseRequirements } = this.state;
      const userQuest = find(myUserQuests, (q) => q.quest === questId);

      // Save user quest requirements
      if (userQuest) {
        this.setState({
          uqRequirements: userQuest.requirements,
        });
      }

      if (
        !collapsedRequirements.length &&
        !canCollapseRequirements.length &&
        userQuest &&
        userQuest.requirements
      ) {
        // Set default collapsed requirements (anything completed) & any collapsable
        if (!this.state.mobile) {
          const collapsedRequirements = [];
          const canCollapseRequirements = [];

          for (let i = 0; i < userQuest.requirements.length; i++) {
            const req = userQuest.requirements[i];
            if (req.status === "completed") {
              collapsedRequirements.push(req.questReqId);
              canCollapseRequirements.push(req.questReqId);
            }
          }

          this.setState({
            collapsedRequirements,
            canCollapseRequirements,
          });
        }
      }
    }

    // Save quest data to state
    if (
      this.props.config.quest.isLoading &&
      !nextProps.config.quest.isLoading
    ) {
      const quest = nextProps.config.quest.data;
      const { activeRequirementId } = this.state;
      let newActiveRequirementId = activeRequirementId;

      if (quest.requirements) {
        const requirements = getQuestRequirements(quest, false);
        if (!activeRequirementId && requirements.length) {
          newActiveRequirementId = requirements[0].id;
        }
      }

      // Get status of quest end date & grace period
      const questInGracePeriod = isQuestInGracePeriod(quest);
      const questOver = isQuestOver(quest);

      this.setState({
        quest,
        questInGracePeriod,
        questOver,
        gracePeriodModal: !!(questInGracePeriod && questOver),
        activeRequirementId: newActiveRequirementId,
      });
    }
  }

  // Listener function for window resizing
  updateWindowWidth() {
    const { mobile } = this.state;
    if (!mobile && window.innerWidth <= MOBILE_SCREEN_WIDTH)
      this.setState({ mobile: true });
    else if (mobile && window.innerWidth > MOBILE_SCREEN_WIDTH)
      this.setState({ mobile: false });
  }

  // Listener function for window scroll
  handleScroll() {
    const requirementContainers = document.getElementsByClassName(
      "RequirementContainer"
    );

    // Find the closest requirement to current scroll position
    let firstRec = requirementContainers[0];
    for (let i = requirementContainers.length - 1; i >= 0; i--) {
      const rect = requirementContainers[i].getBoundingClientRect();
      if (rect.top >= 0) {
        firstRec = requirementContainers[i];
      }
    }

    // Save the ID of that requirement to the state for use in sidebar nav rendering
    if (firstRec) {
      const { activeRequirementId } = this.state;
      const reqId = firstRec.id;

      if (reqId !== activeRequirementId) {
        this.setState({
          activeRequirementId: reqId,
        });
      }
    }

    // Confirm if we can see footer or not
    const { navStickyBottom } = this.state;
    const ContactFooter = document.getElementsByClassName("noiz-footer");
    const footerVisible =
      window.innerHeight - ContactFooter[0].getBoundingClientRect().top >= 0;

    // Make table of contents fixed when footer is in view
    if (footerVisible && !navStickyBottom) {
      this.setState({ navStickyBottom: window.scrollY });
    } else if (!footerVisible) {
      this.setState({ navStickyBottom: false });
    }

    // Handle status bar sticky on scroll
    const statusBar = document.getElementById("status-bar");
    const statusBarContainer = document.getElementById("status-bar-container");

    if (statusBar && statusBarContainer) {
      const statusBarRect = statusBar.getBoundingClientRect();
      const statusBarContainerRect = statusBarContainer.getBoundingClientRect();
      const { sticky } = this.state;

      if (statusBarRect.top <= 0 && !sticky) {
        this.setState({
          sticky: true,
        });
      } else if (window.scrollY <= statusBarContainerRect.top + 100) {
        this.setState({
          sticky: false,
        });
      }
    }

    // Handle side nav sticky on scroll
    const NavScrollContainer = document.getElementById("nav-container");

    if (NavScrollContainer) {
      const navScrollContainerRec = NavScrollContainer.getBoundingClientRect();

      // Stick when we hit top of scroll container
      if (navScrollContainerRec.top <= 100) {
        this.setState({
          navSticky: true,
          navStickyTop: 100, // top position for when nav is sticky
        });

        // If we pass top of container on the way back up, unstick
      } else {
        this.setState({
          navSticky: false,
          navStickyTop: false,
        });
      }
    }
  }

  // Animate scroll to requirement
  scrollTo(reqId) {
    const requirementContainers = document.getElementsByClassName(
      "RequirementContainer"
    );
    const container = find(requirementContainers, (req) => req.id === reqId);
    const pos = container ? container.getBoundingClientRect() : false;
    if (pos) window.scrollBy(0, pos.top - 60);
  }

  // Collapse requirement, update state, and set height to auto
  postAnimation(reqId) {
    this.toggleCollapseRequirement(reqId);
    this.setState({
      animatingRequirementId: false,
    });
    document.getElementById(`box-${reqId}`).style.height = "auto";
  }

  // Animate the background and collapse on requirement
  animateBackgroundCollapse(reqId) {
    const _this = this;
    this.setState({ animatingRequirementId: reqId });

    // Animate height, if needed
    const requirementContainer = document.getElementById(`box-${reqId}`);
    const requirementRect = requirementContainer
      ? requirementContainer.getBoundingClientRect()
      : false;

    let animateHeight = false;
    if (requirementRect && requirementRect.height > 80) animateHeight = true;

    // Swipe across background
    anime({
      targets: `#background-${reqId}`,
      width: "calc(100% - 64px)",
      duration: 300,
      easing: "linear",
      complete(anim) {
        // Collapse & opacity
        anime({
          targets: `#background-${reqId}`,
          opacity: 0,
          delay: 200,
          duration: 300,
          easing: "linear",
          complete(anim) {
            if (!animateHeight) {
              _this.postAnimation(reqId);
            }
          },
        });

        if (animateHeight) {
          anime({
            targets: `#box-${reqId}`,
            delay: 200,
            duration: 300,
            height: "80px",
            easing: "linear",
            complete(anim) {
              _this.postAnimation(reqId);
            },
          });
        }
      },
    });
  }

  // Animate opening & closing of status bar
  animateStatusBar() {
    const { statusBarOpen } = this.state;
    const _this = this;

    // Animate open
    let height = "150px";

    // Animate closed
    if (statusBarOpen) height = "0px";

    anime({
      targets: "#status-bar-content-mobile",
      height,
      duration: 300,
      easing: "linear",
      complete(anim) {
        _this.setState({
          statusBarOpen: !statusBarOpen,
        });
      },
    });
  }

  // Show/hide leave quest modal
  leaveQuestModalToggle() {
    this.setState({
      leaveQuestModal: !this.state.leaveQuestModal,
    });
  }

  // Leave quest & show the left quest modal
  leaveQuest(questId) {
    const { token } = this.props.auth;

    this.setState({
      leavingQuest: true,
    });

    this.props.leaveQuest(questId, this.state.leaveQuestReason, token);
  }

  // Validate & update requirement in DB
  submitRequirement(req) {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    const { errors, errorMessages, submissions } = this.state;
    const submission = submissions[req.id];
    const { t } = this.props;
    if (submission) {
      // Validate submission types
      if (
        req.type === "submit-tweet" &&
        (submission === "" || !twitterUrlRegex.test(submission))
      ) {
        errors[req.id] = true;
        errorMessages[req.id] = `${t(
          "submitTweetError"
        )}: https://www.twitter.com/path-to-content`;
        this.setState({ errors, errorMessages });
        return;
      } else if (
        req.type === "submit-link" &&
        (submission === "" || !genericUrlRegex.test(submission))
      ) {
        errors[req.id] = true;
        errorMessages[req.id] = `${t(
          "submitLinkError"
        )}: https://www.twitch.tv/path-to-content`;
        this.setState({ errors, errorMessages });
        return;
      }

      this.setState({
        editRequirementId: false,
      });

      const { approvalRequired, id, title, type, subtype } = req;
      const reqToUpdate = {
        content: submission,
        questId,
        reqId: id,
        title,
        type,
        subtype,
        approvalRequired,
      };

      const data = JSON.stringify(reqToUpdate);
      this.props.submitRequirementData(data, token);
    }
  }

  // Check off requirement & update in DB
  checkBox(req, questId) {
    const { id, type, title, approvalRequired } = req;
    const {
      canCollapseRequirements,
      collapsedRequirements,
      mobile,
      animatingRequirementId,
    } = this.state;

    if (!animatingRequirementId) {
      // Disallow check off on submission requirements
      if (type.includes("submit") || type === "display-stream-time") {
        const { errors } = this.state;
        errors[id] = true;

        this.setState({
          errors,
        });

        return;
      }

      const { token } = this.props.auth;
      this.props.checkRequirement(
        id,
        true,
        type,
        title || "",
        questId,
        approvalRequired,
        token
      );

      // On desktop, collapse the requirement
      if (!mobile && !collapsedRequirements.includes(id)) {
        this.animateBackgroundCollapse(req.id);

        const newCanCollapseRequirements = canCollapseRequirements;
        canCollapseRequirements.push(id);

        this.setState({
          canCollapseRequirements: newCanCollapseRequirements,
        });
      }
    }
  }

  // Copy text from element where id = reqId
  copyText = (reqId) => {
    document.getElementById(reqId).select();
    document.execCommand("copy");
    this.openTooltip(reqId);
  };

  // Open, then close tooltip for copy button on requirement
  openTooltip(reqId) {
    this.setState(
      {
        tooltipOpen: reqId,
      },
      () => {
        setTimeout(() => {
          this.setState({
            tooltipOpen: false,
          });
        }, 1000);
      }
    );
  }

  // Set requirement that is currently being edited
  editRequirement(reqId) {
    this.setState({
      editRequirementId: reqId,
    });

    const el = document.getElementById(`input-${reqId}`);
    if (el) el.placeholder = "";
  }

  // Return icon element for requirement box
  getIcon(req, quest) {
    // Custom icon
    const { icon, subtype, type } = req;
    if (icon) {
      return <img className="imageIcon" alt="icon" src={icon} />;
    }

    // Default icon
    const defaultLogo = getDefaultIcon(type, subtype, quest, quest.game);
    if (
      ["distribute-key", "display-download"].includes(type) ||
      (type === "display-text" && subtype === "game-name")
    ) {
      return (
        <div
          className="imageIcon"
          style={{ backgroundImage: `url(${defaultLogo})` }}
        />
      );
    }
    if (subtype === "schedule") {
      return (
        <div
          className="imageIcon schedule"
          style={{ backgroundImage: `url(${defaultLogo})` }}
        />
      );
    }
    return (
      <div className="icon">
        <img alt="verification" src={defaultLogo} />{" "}
      </div>
    );
  }

  // Return value for distribution in requirement box
  getDistributeValue(req, quest) {
    const { type, subtype } = req;
    const { data: myGameKeys } = this.props.config.myGameKeys;

    const { t } = this.props;

    if (type === "distribute-key") {
      const gameKeyObj = find(
        myGameKeys,
        (gameKeyItem) => gameKeyItem.game.id === quest.game.id
      );

      if (gameKeyObj && gameKeyObj.key) return gameKeyObj.key;
      return t("noKeyAssigned");
    } else if (type === "distribute-link" && quest) {
      if (subtype === "affiliate") {
        const { user } = this.props.auth;
        const affiliateLink = `${origin}/buy/${quest.game.slug}/${quest.slug}/${user.username}`;
        return affiliateLink;
      } else if (subtype === "campaign") {
        if (quest.promoLink)
          return formatLink(quest.promoLink) || t("noLinkAssigned");
        return t("noLinkAssigned");
      }
    }

    return t("nothingAssigned");
  }

  // Toggle requirement collapsed/open
  toggleCollapseRequirement(reqId) {
    const { collapsedRequirements } = this.state;

    if (collapsedRequirements.includes(reqId)) {
      this.setState({
        collapsedRequirements: [
          ...without(this.state.collapsedRequirements, reqId),
        ],
      });
    } else {
      collapsedRequirements.push(reqId);
      this.setState({
        collapsedRequirements,
      });
    }
  }

  // Get title for requirement box
  getTitle(req, quest) {
    const { type, subtype, title } = req;
    const { game, requiredStreamTimeMinutes } = quest;

    switch (type) {
      case "display-hashtags":
        if (subtype === "quest") return getQuestHashtagsTitle(quest);
        else if (subtype === "tracking") return getTrackingHashtagsTitle(quest);
        break;
      case "display-text":
        if (subtype === "game-name") return getDisplayGameTitle(game);
        break;
      case "display-stream-time":
        return getStreamTrackTitle(requiredStreamTimeMinutes);
      default:
        break;
    }

    if (title) return title;

    return "";
  }

  // Return link for download requirement
  getDownload(req, quest) {
    const { type, subtype } = req;
    const { requiredBanner, overlayImage } = quest;

    if (type === "display-download") {
      if (subtype === "banner") return requiredBanner;
      else if (subtype === "overlay") return overlayImage;
    }

    return null;
  }

  // Return boolean for if requirement is collapsable
  getIsCollapsable(req) {
    const { canCollapseRequirements } = this.state;

    if (!canCollapseRequirements.includes(req.id)) {
      return false;
    } else if (req.description === "") {
      if (req.type === "display-text" || req.type === "display-hashtags") {
        return false;
      }
    }

    return true;
  }

  // Return label that appears above copy/submit box
  getLabel(req) {
    const { label, type, subtype } = req;

    const { t } = this.props;

    if (label) return label;

    const { quest } = this.state;
    const preload = preloadRequirement(type, subtype, quest, quest.game, {}, t);

    if (preload.label) return preload.label;

    return false;
  }

  // Modal for when user has left quest
  generateLeftQuestModal() {
    const { t } = this.props;
    return (
      <section className="Modal active">
        <div
          onClick={this.leaveQuestModalToggle.bind(this)}
          className="Modal__layer"
          aria-hidden
        />
        <section className="Modal__wrapper LeaveQuest">
          <div style={{ marginTop: "30px" }}>
            <h3> {t("leftThisQuest")}</h3>
            <Link to="/my-quests" href="/my-quests">
              <button className="left">{t("returnToMyQuests")}</button>
            </Link>
          </div>
        </section>
      </section>
    );
  }

  // Modal for user to choose to leave quest
  generateLeaveQuestModal(questId) {
    const { leavingQuest } = this.state;
    const { t } = this.props;
    return (
      <section className="Modal active">
        <div
          onClick={this.leaveQuestModalToggle.bind(this)}
          className="Modal__layer"
          aria-hidden
        />
        {leavingQuest ? (
          <section className="Modal__wrapper LeaveQuest">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          </section>
        ) : (
          <section className="Modal__wrapper LeaveQuest">
            <h1>{t("generateLeaveQuestModal.title")}</h1>
            <span>{t("generateLeaveQuestModal.tip")}</span>
            <br />
            <span>{t("generateLeaveQuestModal.tip2")}</span>
            <br />
            <span
              style={{
                alignSelf: "start",
                fontSize: "10px",
                marginBottom: "2px",
              }}
            >
              {t("reason")} ({t("optional")})
            </span>
            <TextareaAutosize
              aria-label="minimum height"
              rowsMin={3}
              onChange={(e) =>
                this.setState({ leaveQuestReason: e.target.value })
              }
              style={{
                backgroundColor: "#18191d",
                color: "#fff",
                margin: "10px",
              }}
            />
            <div style={{ margin: "30px 0 20px 0" }}>
              <button
                className="leave"
                onClick={this.leaveQuest.bind(this, questId)}
              >
                {t("leaveQuest")}
              </button>
              <button
                className="cancel"
                onClick={this.leaveQuestModalToggle.bind(this)}
              >
                {t("cancel")}
              </button>
            </div>
          </section>
        )}
      </section>
    );
  }

  // Modal for grace period reminder
  generateGracePeriodModal(quest) {
    const { user } = this.props.auth;
    const dateString = localizeIncorrectDate(quest.endDateTime)
      .add(QUEST_GRACE_PERIOD_DAYS, "day")
      .format(`MMMM Do, YYYY[ by ]${token12v24(user)}:mm a z`);
    const { t } = this.props;
    return (
      <section className="Modal active">
        <div
          onClick={() => {
            this.setState({ gracePeriodModal: false });
          }}
          className="Modal__layer"
          aria-hidden
        />
        <section className="Modal__wrapper">
          <h1 style={{ margin: "20px 40px" }}>
            {" "}
            {t("generateGracePeriodModal.submissions")} {dateString}
          </h1>
          <span> {t("generateGracePeriodModal.tip")} </span>
          <button
            style={{ margin: "32px 0 12px 0" }}
            onClick={() => {
              this.setState({ gracePeriodModal: false });
            }}
          >
            {" "}
            {t("ok")}{" "}
          </button>
        </section>
      </section>
    );
  }

  // Create requirement box
  generateRequirementBox(req, quest, index, timedActivityObj, numReqs) {
    const { t } = this.props;
    const { approvalRequired, type, description, placeholder, tooltip } = req;
    const { requiredStreamTimeMinutes } = quest;
    const {
      collapsedRequirements,
      editRequirementId,
      errors,
      errorMessages,
      mobile,
      animatingRequirementId,
      questOver,
      questInGracePeriod,
      tooltipOpen,
    } = this.state;
    const timePlaying =
      timedActivityObj && timedActivityObj.timePlaying
        ? timedActivityObj.timePlaying
        : 0;
    const noEditing = !!(questOver && !questInGracePeriod);
    const isSubmission = type.indexOf("submit") > -1;
    const isDistribute = type.indexOf("distribute") > -1;
    const { uqRequirements } = this.state;
    const userQuestRequirement = find(
      uqRequirements,
      (r) => r.questReqId === req.id
    );
    const label = this.getLabel(req);
    const collapsed = !!(
      collapsedRequirements && collapsedRequirements.includes(req.id)
    );
    const collapseable = this.getIsCollapsable(req);
    let checked = false;
    let content = false;
    let status = false;
    let note = false;
    const last = index === numReqs - 1;
    const first = index === 0;

    if (userQuestRequirement) {
      status = userQuestRequirement.status;
      content = userQuestRequirement.content;
      note = userQuestRequirement.note;
      if (status === "completed" || status === "to-review") checked = true;
      else if (status === "rejected") checked = false;
    } else if (
      type === "display-stream-time" &&
      timePlaying >= requiredStreamTimeMinutes
    ) {
      checked = true;
    } else if (collapsed || collapseable) {
      checked = true;
    }

    // Needs attention when req is rejected, or req is not submitted and quest is over
    const needsAttention =
      status === "rejected" ||
      (!["to-review", "completed"].includes(status) &&
        questOver &&
        approvalRequired);

    // Submission disabled after grace period, when not editing submitted req, and on completed reqs
    const submissionDisabled =
      noEditing ||
      (status === "to-review" && req.id !== editRequirementId) ||
      status === "completed";

    const requirementsBoxClasses = classNames({
      RequirementBox: true,
      rejected: needsAttention,
      "in-review": status === "to-review",
    });

    const topBarClasses = classNames({
      topBar: true,
      first,
    });

    const bottomBarClasses = classNames({
      bottomBar: true,
      last,
    });

    const contentRightClasses = classNames({
      "content-right": true,
      collapseable,
    });

    let arrowIcon = false;
    if (collapsed || animatingRequirementId === req.id) {
      if (mobile) arrowIcon = expandMobile;
      else arrowIcon = expandDesktop;
    } else if (mobile) arrowIcon = condenseMobile;
    else arrowIcon = condenseDesktop;

    return (
      <div className="RequirementContainer" id={req.id}>
        {/* Timeline */}
        <div className="timeline">
          <div className={topBarClasses} />
          <CustomCheckbox
            checked={checked}
            status={status}
            disabled={
              status === "completed" ||
              status === "to-review" ||
              type === "display-stream-time"
            }
            onChange={this.checkBox.bind(this, req, quest.id)}
          />
          <div className={bottomBarClasses} />
        </div>
        <div className={requirementsBoxClasses} id={`box-${req.id}`}>
          <div className="RequirementBackground" id={`background-${req.id}`} />
          <React.Fragment>
            {/* Icon */}
            {this.getIcon(req, quest)}
            {/* Collapsed state ( title only) */}
            {collapsed === true ? (
              <div className="content">
                <h2> {this.getTitle(req, quest)}</h2>
              </div>
            ) : (
              /* Expanded state */
              <div className="content">
                {/* Stream time */}
                {type === "display-stream-time" ? (
                  this.generateStreamTimeBox(
                    requiredStreamTimeMinutes,
                    timePlaying,
                    description
                  )
                ) : (
                  /* General title, description, label */
                  <React.Fragment>
                    <h2> {this.getTitle(req, quest)}</h2>
                    {description ? (
                      <span
                        className="description"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    ) : null}
                    {label ? <span className="label">{label}</span> : null}
                  </React.Fragment>
                )}

                {/* Submit content */}
                {isSubmission ? (
                  <div className="copyContent">
                    <input
                      id={`input-${req.id}`}
                      placeholder={content || placeholder || t("enterHere")}
                      onChange={(e) =>
                        this.handleChange(e.target.value, req.id)
                      }
                      type="text"
                      disabled={submissionDisabled}
                      onFocus={(e) => (e.target.placeholder = "")}
                      className={errors[req.id] ? "error" : ""}
                    />

                    {/* Submit & edit buttons */}
                    {status === "to-review" && req.id !== editRequirementId ? (
                      <div className="review">
                        {!noEditing ? (
                          <button
                            className="edit"
                            onClick={this.editRequirement.bind(this, req.id)}
                          >
                            {t("edit")}
                          </button>
                        ) : null}
                        <span className="reviewStatus">
                          {t("underReview")}{" "}
                        </span>
                      </div>
                    ) : status !== "completed" ? (
                      noEditing ? (
                        <span className="reviewStatus error">
                          {" "}
                          {t("questFailed")}{" "}
                        </span>
                      ) : (
                        <button
                          onClick={this.submitRequirement.bind(this, req)}
                        >
                          {req.id !== editRequirementId
                            ? t("submit")
                            : t("update")}
                        </button>
                      )
                    ) : null}
                  </div>
                ) : /* Distribute content */
                isDistribute ? (
                  <div className="copyContent">
                    <input
                      readOnly
                      className="action-box"
                      type="text"
                      id={`copy-${req.id}`}
                      value={this.getDistributeValue(req, quest)}
                      placeholder={t("referralLinkPlaceholder")}
                    />
                    <Tooltip
                      open={tooltipOpen === `copy-${req.id}`}
                      title="Copied!"
                      disableHoverListener
                      placement="top"
                    >
                      <button
                        className={classNames("action-box-button")}
                        onClick={this.copyText.bind(this, `copy-${req.id}`)}
                      >
                        {t("copy")}
                      </button>
                    </Tooltip>
                  </div>
                ) : /* Download content */
                type === "display-download" ? (
                  <a
                    href={this.getDownload(req, quest)}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    download
                  >
                    <button className="download">{t("download")}</button>
                  </a>
                ) : null}

                {/* Below content notes & errors */}
                {errors[req.id] && errorMessages[req.id] ? (
                  <span className="error">{errorMessages[req.id]}</span>
                ) : note && status === "rejected" ? (
                  <span className="note">{note}</span>
                ) : noEditing &&
                  isSubmission &&
                  !["completed", "to-review"].includes(status) ? (
                  <span className="reviewStatus-mobile error">
                    {" "}
                    {t("questFailed")}{" "}
                  </span>
                ) : null}
                {status === "to-review" && req.id !== editRequirementId ? (
                  <span className="reviewStatus-mobile">
                    {" "}
                    {t("underReview")}{" "}
                  </span>
                ) : null}
              </div>
            )}
          </React.Fragment>

          {/* Tooltip & expand/collapse button */}
          <span className={contentRightClasses}>
            {collapseable ? (
              <div
                className="collapseArea"
                onClick={this.toggleCollapseRequirement.bind(this, req.id)}
              />
            ) : null}
            <img
              className="collapseToggle"
              alt="collapse-toggle"
              src={arrowIcon}
            />
            {tooltip ? (
              <Tooltip
                interactive
                title={<span dangerouslySetInnerHTML={{ __html: tooltip }} />}
                placement="left"
              >
                <div className="toolTip"> i </div>
              </Tooltip>
            ) : null}
          </span>
        </div>
      </div>
    );
  }

  // Create stream time box
  generateStreamTimeBox(requiredTimeMinutes, streamedTimeMinutes, description) {
    const { t } = this.props;
    const hoursStreamed = streamedTimeMinutes
      ? Math.floor(streamedTimeMinutes / 60).toFixed(0)
      : 0;
    const minutesStreamed = streamedTimeMinutes
      ? (streamedTimeMinutes % 60).toFixed(0)
      : 0;
    const timeString = getStreamTrackTitle(requiredTimeMinutes);
    let statusString = t("streamIncomplete");
    const indicatorClasses = { indicator: true };
    if (streamedTimeMinutes >= requiredTimeMinutes) {
      statusString = t("streamComplete");
      indicatorClasses.complete = true;
    } else if (streamedTimeMinutes > 0) {
      statusString = t("streamInProgress");
    }

    return (
      <React.Fragment>
        <h2>{timeString}</h2>
        {description ? (
          <span
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : null}
        <div className="timeBox">
          <div className="valueTrack">
            <div className="count">{hoursStreamed}</div>
            <div className="description"> HRS </div>
          </div>
          <div className="valueTrack">
            <div className="count">{minutesStreamed}</div>
            <div className="description"> MIN </div>
          </div>
        </div>
        <div className="timeStatus">
          <span className={classNames(indicatorClasses)} />
          <span>{statusString}</span>
        </div>
      </React.Fragment>
    );
  }

  // Create lefthand-side navigation item
  generateRequirementNav(req, index, quest) {
    const { activeRequirementId, questOver, uqRequirements } = this.state;
    const userQuestRequirement = find(
      uqRequirements,
      (r) => r.questReqId === req.id
    );

    // Error state on rejected requirements, and missing requirements when quest is over
    let error = false;
    if (questOver && !userQuestRequirement && req.approvalRequired)
      error = true;
    else if (userQuestRequirement && userQuestRequirement.status === "rejected")
      error = true;

    const classes = classNames({
      navItem: true,
      rejected: error,
    });

    // Restrict title length
    let title = this.getTitle(req, quest);
    title = textTruncate(title, 30);

    return (
      <li className={req.id === activeRequirementId ? "active" : ""}>
        <div className={classes} onClick={this.scrollTo.bind(this, req.id)}>
          {title}
        </div>
        {error ? (
          <i
            className="fa fa-exclamation-triangle"
            style={{ margin: "0 0 0 5px" }}
            aria-hidden="true"
          />
        ) : null}
      </li>
    );
  }

  // Get box for noiz store commission information
  generateCommissionBox(quest, userQuest, referralActivityObj) {
    const { user } = this.props.auth;
    const {
      commissionAmount,
      commissionPercentage,
      referralPaymentType,
      referralBonus,
      salesForBonus,
      bonusValue,
      referralBonusType,
      referralBonusTimes,
    } = quest;
    const { tooltipOpen } = this.state;
    const affiliateLink = `${window.location.origin}/buy/${quest.game.slug}/${quest.slug}/${user.username}`;
    const { t } = this.props;

    let numberOfReferrals = 0;
    let totalEarnings = 0;
    let bonus = 0;

    if (referralActivityObj) {
      numberOfReferrals = referralActivityObj.numberOfReferrals;
      totalEarnings = referralActivityObj.totalEarnings;

      if (totalEarnings && numberOfReferrals) {
        bonus = getReferralBonus(userQuest, quest);
      }
    }

    const earnings = totalEarnings
      ? removeCommissionRounding(totalEarnings / 100 + bonus)
      : 0;

    let commissionBaseString = "";
    let commissionBonusString = "";

    // Base payment
    if (commissionPercentage && referralPaymentType === "commission-percentage")
      commissionBaseString = `Earn ${commissionPercentage}% of every game sale`;
    else if (commissionAmount && referralPaymentType === "commission-amount")
      commissionBaseString = `Earn $${commissionAmount} for every game sale`;

    // Bonus payment
    if (referralBonus) {
      if (bonusValue) {
        if (referralBonusType === "bonus-in-amount") {
          commissionBonusString = `plus $${bonusValue}`;
        } else if (referralBonusType === "bonus-in-percentage") {
          commissionBonusString = `plus ${bonusValue}%`;
        }

        if (salesForBonus)
          commissionBonusString += ` every ${salesForBonus} sale(s)`;
        if (referralBonusTimes === "once")
          commissionBonusString += " (maximum of once)";
      }
    }

    return (
      <div className="CommissionBox">
        <div className="static-content">
          <h2> {t("commission")} </h2>
          <span>
            {commissionBaseString +
              (commissionBonusString ? `, ${commissionBonusString}` : "")}
          </span>
        </div>

        <div className="variable-content">
          <div className="values">
            <div className="valueTrack">
              <div className="count">{numberOfReferrals || 0}</div>
              <div className="description">{t("sales")}</div>
            </div>
            <div className="valueTrack">
              <div className="count">{`$${earnings}`}</div>
              <div className="description">{t("commission")}</div>
            </div>
          </div>
          <div className="trackingLink">
            <div className="labelLink">
              <span className="label"> {t("trackLink ")}</span>
              <input
                readOnly
                className="action-box"
                type="text"
                id="referral-link"
                value={affiliateLink}
                placeholder={t("referralLinkPlaceholder")}
              />
            </div>
            <Tooltip
              open={tooltipOpen === "referral-link"}
              title={`${t("copied")}!`}
              disableHoverListener
              placement="top"
            >
              <button
                className={classNames("action-box-button")}
                onClick={this.copyText.bind(this, "referral-link")}
              >
                {t("copy")}
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }

  // Create top-page status bar
  generateStatusBar(quest, userQuest, timePlaying) {
    const { user } = this.props.auth;
    const { userCanleave, endDateTime } = quest;
    const { mobile, sticky, statusBarOpen, questOver, questInGracePeriod } =
      this.state;

    const { t } = this.props;

    let payment = false;
    const { paymentAmount } = getPaymentAmount(
      quest.memberTiers,
      user,
      quest.viewerTiers,
      userQuest
    );

    if (paymentAmount > 0) {
      payment = `$${rounding(paymentAmount)}`;
    } else if (paymentAmount === -1) {
      payment = "Free Key";
    }

    let dateString = "";
    let statusClass = "";
    const questStatusInfo = getCompletionStatus(quest, userQuest, timePlaying);

    // Quest completed
    if (questStatusInfo && questStatusInfo.status === "C") {
      statusClass = "completed";
      dateString = t("questCompleted");

      // Grace period
    } else if (questOver && questInGracePeriod) {
      statusClass = "error";
      const endDateString = localizeIncorrectDate(endDateTime)
        .add(QUEST_GRACE_PERIOD_DAYS, "days")
        .format(`MMMM Do YYYY, ${token12v24(user)}:mm a z`);
      dateString = `${t("submissionsDueBy")} ${
        endDateString ? endDateString.toUpperCase() : ""
      }`;

      // Quest over
    } else if (questOver) {
      statusClass = "error";
      dateString = t("questFailedUp");

      // Quest in progress
    } else {
      const endDateString = localizeIncorrectDate(endDateTime).format(
        `MMMM Do YYYY, ${token12v24(user)}:mm a z`
      );
      dateString = `${t("completeBy")} ${
        endDateString ? endDateString.toUpperCase() : ""
      }`;
      statusClass = "in-progress";
    }

    let arrowIcon = expandMobile;
    if (statusBarOpen) arrowIcon = condenseMobile;

    // Mobile
    if (mobile) {
      const paymentClasses = classNames({
        freeKey: !!(payment && payment === "Free Key"),
      });

      return (
        <div className="StatusBarContainer" id="status-bar-container">
          <section className="StatusBar" id="status-bar">
            <h2>{quest ? quest.title : ""}</h2>
            <div id="status-bar-content-mobile">
              <div style={{ display: "flex", flexDirection: "row" }}>
                {payment ? (
                  <div
                    className={classNames("paymentContainer", paymentClasses)}
                  >
                    <h3 className="payment">{payment}</h3>
                  </div>
                ) : null}
                <div className="streamingPlatform">
                  <i className="fa fa-twitch" /> Twitch
                </div>
              </div>
              <div className={classNames("status", statusClass)}>
                {dateString}
              </div>
              {userCanleave ? (
                <button
                  className="leave-quest"
                  onClick={this.leaveQuestModalToggle.bind(this)}
                >
                  {t("leaveQuest")}
                </button>
              ) : null}
            </div>
            <img
              alt="collapse-toggle"
              onClick={this.animateStatusBar.bind(this)}
              src={arrowIcon}
            />
          </section>
        </div>
      );

      // Desktop
    }
    const statusBarClasses = classNames({
      StatusBar: true,
      sticky,
    });

    return (
      <div
        className="StatusBarContainer"
        id="status-bar-container"
        style={{ height: "75px" }}
      >
        <section className={statusBarClasses} id="status-bar">
          <div className="left">
            <h2>{quest ? quest.title : ""}</h2>
            {payment ? (
              <div className="paymentContainer">
                <h3 className="payment">{payment}</h3>
              </div>
            ) : null}
            <div className="streamingPlatform">
              <i className="fa fa-twitch" /> Twitch
            </div>
            <div className={classNames("status", statusClass)}>
              {dateString}
            </div>
          </div>
          {userCanleave ? (
            <button
              className="leave-quest"
              onClick={this.leaveQuestModalToggle.bind(this)}
            >
              {t("leaveQuest")}
            </button>
          ) : null}
        </section>
      </div>
    );
  }

  generateMissingRequirementsBar(quest, userQuest, timePlaying) {
    const { user } = this.props.auth;
    const { requirements } = userQuest;
    const { mobile, questOver, questInGracePeriod } = this.state;
    const rejectedRequirements = requirements
      ? requirements.filter((r) => r.status === "rejected")
      : [];
    const missingRejectedReqs = [];
    const { t } = this.props;
    let notifString = "";

    // Quest is not over & nothing rejected, return
    if (!questOver && !rejectedRequirements.length) {
      return;

      // Quest is not over and requirements are rejected
    } else if (!questOver && rejectedRequirements.length) {
      notifString = t("questOverMessage");

      // Get all rejected requirements
      const submittableRequirements = getQuestRequirements(quest, true);
      for (let i = 0; i < rejectedRequirements.length; i++) {
        const req = rejectedRequirements[i];
        const questReq = submittableRequirements
          ? submittableRequirements.find((r) => r.id === req.questReqId)
          : false;
        if (questReq) missingRejectedReqs.push(questReq);
      }

      // Quest is over
    } else if (questOver) {
      // Get all rejected or missing requirements
      const submittableRequirements = getQuestRequirements(quest, true);
      for (let i = 0; i < submittableRequirements.length; i++) {
        const req = submittableRequirements[i];
        const userQuestReq = requirements
          ? requirements.find((r) => r.questReqId === req.id)
          : false;
        if (
          !userQuestReq ||
          (userQuestReq && userQuestReq.status === "rejected")
        )
          missingRejectedReqs.push(req);
      }

      const endDateTimeWithGrace = localizeIncorrectDate(quest.endDateTime)
        .add(QUEST_GRACE_PERIOD_DAYS, "day")
        .format(`MMMM Do, YYYY[ at ]${token12v24(user)}:mm a z`);

      // Quest in grace period
      if (questInGracePeriod) {
        notifString = `${t(
          "questInGracePeriodMessage1"
        )} ${endDateTimeWithGrace}. ${t("questInGracePeriodMessage2")}:`;
      } else {
        notifString = `<b>${t("questInGracePeriodMessage3")}.</b> ${t(
          "questInGracePeriodMessage4"
        )} ${endDateTimeWithGrace}. ${t("questInGracePeriodMessage5")}:`;
      }
    }

    if (missingRejectedReqs.length) {
      return (
        <div className="missingRequirementsBar">
          <span dangerouslySetInnerHTML={{ __html: notifString }} />
          <ul>
            {missingRejectedReqs.map((req) => (
              <li onClick={this.scrollTo.bind(this, req.id)}>
                {textTruncate(this.getTitle(req, quest), mobile ? 30 : 80)}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  }

  render() {
    const {
      t,
      config: {
        quest: { isLoading: isLoadingQuest },
      },
      router: {
        params: { questId },
      },
      user: {
        myUserQuests: { data: myUserQuests, isLoading: isLoadingMyUserQuests },
        referralsActivity: {
          data: referralsActivity,
          isLoading: isLoadingReferralsActivity,
        },
        timedActivity: {
          data: timedActivity,
          isLoading: isLoadingTimedActivity,
        },
      },
    } = this.props;

    const {
      activeRequirementId,
      gracePeriodModal,
      leaveQuestModal,
      leftQuest,
      navStickyBottom,
      quest,
    } = this.state;

    // User has left quest
    if (leftQuest) {
      return (
        <section className="QuestDetailPage">
          {this.generateLeftQuestModal()}
        </section>
      );
    }

    // Quest stuff is loading
    const isLoading =
      isLoadingQuest ||
      isLoadingMyUserQuests ||
      isLoadingReferralsActivity ||
      isLoadingTimedActivity;
    const userQuest = find(myUserQuests, (q) => q.quest === questId) || {};

    // Make sure user is in quest
    let userQuestValid = false;
    if (userQuest) {
      const { status } = userQuest;
      if (status === "approved" || status === "normal") {
        userQuestValid = true;
      }
    }

    // Invalid quest for this user
    if (!isLoading && (!quest || !userQuestValid) && !this.isPreview()) {
      return (
        <section className="QuestDetailPage">
          <section className="QuestDetailPage__container">
            <div className="noQuestFound">
              {t("noQuestFound")}.
              <div>
                <Link to="/my-quests" href="/my-quests">
                  <button className="left">{t("returnToMyQuests")}</button>
                </Link>
              </div>
            </div>
          </section>
        </section>
      );
    }

    const { isReferral = false, requirements = [], game = {} } = quest;

    const navClasses = classNames({
      NavContainer: true,
      sticky: this.state.navSticky && !navStickyBottom,
      stop: !!navStickyBottom,
    });

    // Check which nav section has active requirement
    const questRequirements = requirements;
    let activeNavHeader = "beforeStream";
    if (
      questRequirements &&
      questRequirements.duringStream &&
      find(
        questRequirements.duringStream,
        (req) => req.id === activeRequirementId
      )
    ) {
      activeNavHeader = "duringStream";
    } else if (
      questRequirements &&
      questRequirements.afterStream &&
      find(
        questRequirements.afterStream,
        (req) => req.id === activeRequirementId
      )
    ) {
      activeNavHeader = "afterStream";
    }

    const referralActivityObj = referralsActivity
      ? find(referralsActivity, (obj) => obj.questId === quest.id)
      : false;
    const timedActivityObj = find(
      timedActivity,
      (obj) => obj && obj.quest && obj.quest._id === quest.id
    );

    const beforeStream =
      requirements && requirements.beforeStream
        ? requirements.beforeStream
        : [];
    const duringStream =
      requirements && requirements.duringStream
        ? requirements.duringStream
        : [];
    const afterStream =
      requirements && requirements.afterStream ? requirements.afterStream : [];

    const beforeStreamIndex = beforeStream && beforeStream.length ? 1 : 0;
    const duringStreamIndex =
      duringStream && duringStream.length ? beforeStreamIndex + 1 : 0;
    const afterStreamIndex =
      afterStream && afterStream.length ? duringStreamIndex + 1 : 0;

    // Stick to top while scrolling;
    let { navStickyTop } = this.state;
    if (navStickyBottom) {
      navStickyTop = navStickyBottom;
    }

    const timePlaying =
      timedActivityObj && timedActivityObj.timePlaying
        ? timedActivityObj.timePlaying
        : 0;

    const graceModal =
      gracePeriodModal &&
      getNumApprovedRequirements(quest, userQuest) +
        getNumInReviewRequirements(quest, userQuest) <
        getQuestRequirements(quest, true).length;

    return (
      <section
        className="QuestDetailPage"
        style={{
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundImage: `linear-gradient(to bottom, rgba(24, 25, 29, 1), rgba(24, 25, 29, 0.7)), linear-gradient(to bottom, rgba(58, 116, 224, 0.25), rgba(161, 66, 255, 0.25)), url(${game.cover})`,
        }}
      >
        <section className="QuestDetailPage__container">
          <Loading isLoading={isLoading} />
          {this.generateStatusBar(quest, userQuest, timePlaying)}
          {this.generateMissingRequirementsBar(quest, userQuest, timePlaying)}
          <section className="QuestDetailPage__scrollContainer">
            {/* Lefthand Nav Bar */}
            <div
              className="NavScrollContainer"
              id="nav-container"
              style={{ height: "calc(100vh - 215px)", width: "500px" }}
            >
              <div
                className={navClasses}
                style={{ top: navStickyTop }}
                id="nav"
              >
                {/* Before Stream */}
                {beforeStream && beforeStream.length ? (
                  <div className="streamSection">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span
                        className={
                          activeNavHeader === "beforeStream" ? "active" : ""
                        }
                      >
                        {beforeStreamIndex}
                      </span>
                      <h2>{t("beforeTheStream")}</h2>
                    </div>
                    <ul>
                      {beforeStream.map((req, index) =>
                        this.generateRequirementNav(req, index, quest)
                      )}
                    </ul>
                  </div>
                ) : null}

                {/* During Stream */}
                {duringStream && duringStream.length ? (
                  <div className="streamSection">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span
                        className={
                          activeNavHeader === "duringStream" ? "active" : ""
                        }
                      >
                        {duringStreamIndex}
                      </span>
                      <h2>{t("duringTheStream")}</h2>
                    </div>
                    <ul>
                      {duringStream.map((req, index) =>
                        this.generateRequirementNav(req, index, quest)
                      )}
                    </ul>
                  </div>
                ) : null}

                {/* After Stream */}
                {afterStream && afterStream.length ? (
                  <div className="streamSection">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span
                        className={
                          activeNavHeader === "afterStream" ? "active" : ""
                        }
                      >
                        {afterStreamIndex}
                      </span>
                      <h2>{t("afterTheStream")}</h2>
                    </div>
                    <ul>
                      {afterStream.map((req, index) =>
                        this.generateRequirementNav(req, index, quest)
                      )}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>

            {/* Main Content Container */}
            <div className="ReqContainer" id="ReqContainer">
              {isReferral
                ? this.generateCommissionBox(
                    quest,
                    userQuest,
                    referralActivityObj
                  )
                : null}
              {beforeStream && beforeStream.length ? (
                <React.Fragment>
                  <h1> {t("beforeTheStream")} </h1>
                  {requirements.beforeStream.map((r, index) =>
                    this.generateRequirementBox(
                      r,
                      quest,
                      index,
                      timedActivityObj,
                      requirements.beforeStream.length
                    )
                  )}
                </React.Fragment>
              ) : null}
              {duringStream && duringStream.length ? (
                <React.Fragment>
                  <h1> {t("duringTheStream")} </h1>
                  {requirements.duringStream.map((r, index) =>
                    this.generateRequirementBox(
                      r,
                      quest,
                      index,
                      timedActivityObj,
                      requirements.duringStream.length
                    )
                  )}
                </React.Fragment>
              ) : null}
              {afterStream && afterStream.length ? (
                <React.Fragment>
                  <h1> {t("afterTheStream")} </h1>
                  {requirements.afterStream.map((r, index) =>
                    this.generateRequirementBox(
                      r,
                      quest,
                      index,
                      timedActivityObj,
                      requirements.afterStream.length
                    )
                  )}
                </React.Fragment>
              ) : null}
            </div>
          </section>
        </section>
        {leaveQuestModal && this.generateLeaveQuestModal(quest.id)}
        {graceModal && this.generateGracePeriodModal(quest)}
        <div className="BottomGradient" />
      </section>
    );
  }
}

export default withTranslation("questDetailPage")(QuestDetailPage);
