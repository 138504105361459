import React from 'react';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';

import Logo from '../logo/Logo';
import './Footer.scss';

const year = new Date().getFullYear();

function Footer({ authenticated, isBrandUser, visible }) {
  const { t } = useTranslation("footer");
  if (!visible) return null;
  const onQuestPage = new RegExp('/quests/').test(window.location.pathname);
  const constrain = ['/quests', '/contact'].includes(window.location.pathname);
  return (
    <footer className={`noiz-footer ${onQuestPage ? "on-quest-page" : ""}`}>
      <div className={`inner-container ${constrain ? "constrain" : ""}`}>
        <div className="top-container">
          <Logo />
          <div className="links-container">
            <div className="links-left">
              <Link to="/about-us">{t("about")}</Link>
              <Link to="/faqs">{t("faq")}</Link>
              <Link to="/contact">{t("contact")}</Link>
            </div>
            <div className="links-right">
              <Link to={authenticated ? "/my-account" : "/sign-in"}>
                {t("myAccount")}
              </Link>
              {isBrandUser && (
                <Link to={authenticated ? "/" : "/sign-in"}>
                  {t("dashboard")}
                </Link>
              )}
              <Link to={authenticated ? "/quests" : "/sign-in"}>
                {t("quests")}
              </Link>
            </div>
          </div>
          <div className="social">
            <Link
              className="icon"
              href="https://www.facebook.com/noizgg"
              target="_blank"
            >
              <svg
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#83858c"
                  d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                />
              </svg>
            </Link>
            <Link
              className="icon"
              href="https://twitter.com/noizgg"
              target="_blank"
            >
              <svg
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#83858c"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                />
              </svg>
            </Link>
            <Link
              className="icon"
              target="_blank"
              href="https://discordapp.com/invite/N2PrJxK"
            >
              <svg
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="#83858c"
                  d="M297.216 243.2c0 15.616-11.52 28.416-26.112 28.416-14.336 0-26.112-12.8-26.112-28.416s11.52-28.416 26.112-28.416c14.592 0 26.112 12.8 26.112 28.416zm-119.552-28.416c-14.592 0-26.112 12.8-26.112 28.416s11.776 28.416 26.112 28.416c14.592 0 26.112-12.8 26.112-28.416.256-15.616-11.52-28.416-26.112-28.416zM448 52.736V512c-64.494-56.994-43.868-38.128-118.784-107.776l13.568 47.36H52.48C23.552 451.584 0 428.032 0 398.848V52.736C0 23.552 23.552 0 52.48 0h343.04C424.448 0 448 23.552 448 52.736zm-72.96 242.688c0-82.432-36.864-149.248-36.864-149.248-36.864-27.648-71.936-26.88-71.936-26.88l-3.584 4.096c43.52 13.312 63.744 32.512 63.744 32.512-60.811-33.329-132.244-33.335-191.232-7.424-9.472 4.352-15.104 7.424-15.104 7.424s21.248-20.224 67.328-33.536l-2.56-3.072s-35.072-.768-71.936 26.88c0 0-36.864 66.816-36.864 149.248 0 0 21.504 37.12 78.08 38.912 0 0 9.472-11.52 17.152-21.248-32.512-9.728-44.8-30.208-44.8-30.208 3.766 2.636 9.976 6.053 10.496 6.4 43.21 24.198 104.588 32.126 159.744 8.96 8.96-3.328 18.944-8.192 29.44-15.104 0 0-12.8 20.992-46.336 30.464 7.68 9.728 16.896 20.736 16.896 20.736 56.576-1.792 78.336-38.912 78.336-38.912z"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div className="bottom-container">
          <div className="copyright">
            {year} © {t("copyrightText")}
          </div>
          <nav className="navigation-bottom">
            <Link href="/privacy-policy">{t("privacyPolicy")}</Link>
            <Link href="/terms-of-use">{t("termsOfUse")}</Link>
            <a
              href="https://noiz.freshdesk.com/support/home"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              {t("support")}
            </a>
          </nav>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
