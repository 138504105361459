import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { findFlagUrlByCountryName } from "country-flags-svg";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";

import defaultAvatar from "../../../../../images/avatars/defaultSpaceman.png";
import GlossyDropdown from "../../CustomInputs/GlossyDropdown";
import Icon from "../../../../../global/images/icons/Icon";
import LiveTag from "../../../../../global/components/live-tag/LiveTag";
import LoadingGif from "../../assets/LoadingGif";
import NotFound from "../../../../not-found/NotFound";
import SocialIcons from "../../../../../global/components/SocialIcons/SocialIcons";

import { nFormatter } from "../../../../../utils/functions";
import { getQuestInvites, postQuestInvite } from "../../../../../utils/apiv2";

import "./StreamerProfile.scss";

const TOP_STREAMED_CARD_SIZE = 217;

const StreamerMastheadStatItem = ({ title, subtitle, country = false }) => (
  <div className="StreamerMastheadStatItem">
    {country ? (
      <Tooltip title={title} placement="top">
        <img
          className="StreamerMastheadStatItem__Flag"
          src={country}
          alt={title}
        />
      </Tooltip>
    ) : (
      <div className="StreamerMastheadStatItem__Title">{title}</div>
    )}
    <div className="StreamerMastheadStatItem__Subtitle">{subtitle}</div>
  </div>
);

const TopStreamedCard = ({ img, title }) => (
  <div className="TopStreamedCard" key={`${img || "img"}-${title || "title"}`}>
    <img className="TopStreamedCard__Img" src={img} alt={title} />
    <div className="TopStreamedCard__Title">{title}</div>
  </div>
);

const StreamerProfile = (props) => {
  const ref = useRef(null);

  const {
    currentUser,
    brandUser: {
      streamers: { data: streamers, isLoading: streamersLoading },
      quests: {
        data: { quests },
        isLoading: questsLoading,
      },
    },
    tab,
    token,
    getBrandStreamer,
  } = props;

  const { t } = useTranslation("portalLeft_streamerProfile");
  const [componentWidth, setComponentWidth] = useState(0);
  const [getQuestInvitesLoading, setGetQuestInvitesForQuestStreamerLoading] =
    useState(false);
  const [postQuestInviteLoading, setPostQuestInviteLoading] = useState(false);
  const [questInvites, setQuestInvites] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const willGetQuestInvites = async () => {
    setGetQuestInvitesForQuestStreamerLoading(true);
    const data = await getQuestInvites({ streamerUsername: currentUser });
    if (data?.data?.data) {
      setQuestInvites(data?.data?.data);
    }
    setGetQuestInvitesForQuestStreamerLoading(false);
  };

  useEffect(() => {
    if (currentUser) {
      getBrandStreamer(currentUser, token);
      willGetQuestInvites();
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      getBrandStreamer(currentUser, token);
      willGetQuestInvites();
    }
  }, [currentUser]);

  useEffect(() => {
    setComponentWidth(ref?.current?.clientWidth || 0);
  }, [ref.current]);

  const isLoading = streamersLoading || questsLoading || getQuestInvitesLoading;

  if (isLoading) {
    return <LoadingGif />;
  }

  const foundStreamer = streamers.find((s) => s.username === currentUser);

  const handleSendingQuestInvite = async (questId) => {
    setPostQuestInviteLoading(true);

    const data = await postQuestInvite({
      notificationType: "email",
      questId,
      streamerId: foundStreamer?.id,
    });

    if (data?.data?.data) {
      setQuestInvites((prevState) => [...prevState, data?.data?.data]);
    }

    setPostQuestInviteLoading(false);
  };

  // const participatedQuests = foundStreamer?.userQuests?.map(uq => uq.quest) || [];

  if (!foundStreamer && !isLoading) {
    return (
      <NotFound
        home={`/portal?component=default&tab=${tab}`}
        buttonText={"Back"}
        text={<p>{`${currentUser?.toUpperCase()} ${t("notFound")}`}</p>}
      />
    );
  }

  const cardsForScreen =
    Math.floor(componentWidth / TOP_STREAMED_CARD_SIZE) || 1;

  const {
    avatar,
    connectedAccounts,
    country,
    displayName,
    ipCountry,
    isLive: userIsLive = {},
    freezeTwitchViewers = {},
    language,
    statistics = {},
    twitchAVGOverride,
    user30,
    id: userId,
    // username,
  } = foundStreamer;

  const {
    description: twitchDesc = "",
    followers = 0,
    profile_image_url: profileImgUrl = "",
    username: twitchUsername = "",
    view_count: viewCount = 0,
  } = connectedAccounts?.twitch || {};

  const { description: twitterDesc = "", username: twitterUsername = "" } =
    connectedAccounts?.twitter || {};

  const { username: youtubeUsername = "" } = connectedAccounts?.youtube || {};

  const { isFrozen = false } = freezeTwitchViewers;
  const { AVG30 = 0 } = statistics;

  const { isLive = false, game = "" } = userIsLive;

  const about = twitchDesc || twitterDesc || "";
  const countryFlag = findFlagUrlByCountryName(country || ipCountry);
  const averageViewers = isFrozen ? twitchAVGOverride : AVG30;

  const uniqueStreamedGames =
    user30?.reduce((acc, streamedGame) => {
      if (!acc.find((g) => g.gameString === streamedGame.gameString)) {
        acc.push(streamedGame);
      }
      return acc;
    }, []) || [];

  const questInviteOptions = quests
    .filter((q) => q.status === "active")
    .map((q) => {
      const option = {
        img:
          q?.cover ||
          q?.coverPortrait ||
          q?.game?.cover ||
          q?.game?.coverPortrait,
        value: q.id,
        label: q.title,
      };

      const foundQuestInvite = questInvites.find(
        (qi) => qi.quest === q.id && qi.streamer === userId
      );

      if (foundQuestInvite) {
        option.disabled = true;
      }

      return option;
    });

  const StreamerMasthead = () => (
    <div className="StreamerMasthead">
      {quests?.length && (
        <div className="StreamerMasthead__Quests">
          <GlossyDropdown
            dropdownText={t("inviteToQuest")}
            loading={postQuestInviteLoading}
            options={questInviteOptions}
            onSubmit={(questId) => handleSendingQuestInvite(questId)}
            submitText={t("invite")}
          />
        </div>
      )}
      {isLive && (
        <div className="StreamerMasthead__LiveTag">
          <LiveTag />
        </div>
      )}
      <div className="StreamerMasthead__Background">
        <img
          className="StreamerMasthead__Background__Avatar"
          src={avatar || profileImgUrl || defaultAvatar}
          alt={displayName}
        />
        <img
          className="StreamerMasthead__Avatar"
          src={avatar || profileImgUrl || defaultAvatar}
          alt={displayName}
        />
      </div>
      <div className="StreamerMasthead__Name">{displayName}</div>
      <div className="StreamerMasthead__Button__Wrapper">
        <div
          className={classnames("StreamerMasthead__Button", isLive && "IsLive")}
          onClick={() =>
            window.open(`https://twitch.tv/${twitchUsername}`, "_blank")
          }
        >
          {isLive ? `${t("playing")} ${game}` : t("twitch")}
          <Icon name="linkout" color="#242529" />
        </div>
      </div>
      <div className="StreamerMasthead__Stats">
        <StreamerMastheadStatItem
          subtitle={t("accv")}
          title={nFormatter(averageViewers, 1)}
        />
        {countryFlag && (
          <StreamerMastheadStatItem
            subtitle={t("location")}
            country={countryFlag}
            title={country || ipCountry}
          />
        )}
        {language && (
          <StreamerMastheadStatItem subtitle={t("language")} title={language} />
        )}
        <StreamerMastheadStatItem
          subtitle={t("totalViews")}
          title={nFormatter(viewCount, 1)}
        />
        <StreamerMastheadStatItem
          subtitle={t("followers")}
          title={nFormatter(followers, 1)}
        />
      </div>
      {!!about && (
        <div className="StreamerMasthead__About">
          <div className="StreamerMasthead__About__Title">{t("about")}</div>
          {about}
        </div>
      )}
    </div>
  );

  const StreamerTopStreamed = () => (
    <div className="StreamerTopStreamed">
      <div className="StreamerTopStreamed__Title">{t("topStreamed")}</div>
      <div className="StreamerTopStreamed__Cards">
        {uniqueStreamedGames?.slice(0, cardsForScreen).map((u) => (
          <TopStreamedCard key={u.title} img={u.box_art} title={u.gameString} />
        ))}
        {showMore &&
          uniqueStreamedGames?.length > cardsForScreen &&
          uniqueStreamedGames
            .slice(cardsForScreen)
            .map((u) => (
              <TopStreamedCard
                key={u.title}
                img={u.box_art}
                title={u.gameString}
              />
            ))}
        {uniqueStreamedGames?.length > cardsForScreen && (
          <div
            className="StreamerTopStreamed__Cards__ShowMore"
            onClick={() => setShowMore(!showMore)}
          >
            <Icon name={showMore ? "caretup" : "caretdown"} />
            &nbsp; {t("show")} {showMore ? `${t("less")}` : `${t("more")}`}{" "}
            &nbsp;
            <Icon name={showMore ? "caretup" : "caretdown"} />
          </div>
        )}
      </div>
    </div>
  );

  const StreamerSocialMedia = () => (
    <div className="StreamerSocialMedia">
      <div className="StreamerSocialMedia__Title">{t("socialMedia")}</div>
      <div className="StreamerSocialMedia__Icons">
        {twitchUsername && (
          <div className="StreamerSocialMedia__Icon">
            <SocialIcons
              network="twitch"
              tooltip={t("twitch")}
              url={`https://twitch.tv/${twitchUsername}`}
            />
          </div>
        )}
        {twitterUsername && (
          <div className="StreamerSocialMedia__Icon">
            <SocialIcons
              network="twitter"
              tooltip={t("twitch")}
              url={`https://twitter.com/${twitterUsername}`}
            />
          </div>
        )}
        {youtubeUsername && (
          <div className="StreamerSocialMedia__Icon">
            <SocialIcons
              network="youtube"
              tooltip={t("youtube")}
              url={`https://youtube.com/user/${youtubeUsername}`}
            />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div ref={ref} className="StreamerProfile">
      <StreamerMasthead />
      {uniqueStreamedGames?.length > 0 && <StreamerTopStreamed />}
      <StreamerSocialMedia />
    </div>
  );
};

export default StreamerProfile;
