import React, { Component } from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import Notifications from "react-notification-system-redux";
import classNames from "classnames";
import { ThemeProvider } from "@material-ui/styles";
import "font-awesome/css/font-awesome.css";

import { isAuthenticated } from "../../utils/authChecker";
import MyQuests from "../my-quests/MyQuests";
import QuickUpdate from "../quick-update/QuickUpdate";
import {
  twitterDiscordHeader,
  twitterDiscordSubheader,
} from "../../utils/messages";
import LoginUpdateForm from "../forms/LoginUpdateForm";
import { noiz } from "../../utils/material_ui/Theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import Footer from "../footer/Footer";
import MainNav from "../navigation/MainNav.js";
import { getSubdomain, isProduction, throttle } from "../../utils/functions";

import "./Main.scss";
import { getBroadcasts } from "../../utils/apiv2";

const pathname = () => window.location.pathname.split("/")[1];
const isLogin = () =>
  ["sign-up", "sign-in", "recover", "complete-registration"].includes(
    pathname()
  );
const isRestrictedPathname = () =>
  ["", "/", "game", "games", "about-us", "quests", "quest"].includes(
    pathname()
  );

class Main extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.joinQuestThrottled = throttle(this.joinQuest, 3000);
    this.state = {
      myQuestsMenuOpen: false,
      showPopup: false,
    };
  }

  UNSAFE_componentWillMount() {
    const subdomain = getSubdomain(window.location.host);
    if (subdomain === "brands" || subdomain === "brands.staging") {
      window.location.replace("https://noiz.gg/brand-services");
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      const { token } = this.props.auth;
      if (isProduction()) {
        ReactGA.initialize("UA-116227928-1");
        ReactGA.ga("set", "userId", this.props.auth.user.id);
        ReactGA.ga("set", "dimension1", "User");
      }
      if (!this.props.config.quests.isLoading) {
        this.props.getInitialInfo(token);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user, token } = this.props.auth;
    if (user.role !== "brand") {
      this.forceCompleteInfo(nextProps);
      this.forceConnectAccount(nextProps);
    }
    const thePath = this.props.router.location.pathname;
    const inBrandService = thePath === "/brand-services";

    if (
      this.props.user.myQuests.isLoading &&
      !nextProps.user.myQuests.isLoading
    ) {
      this.shouldOpenPopUp("twitterDiscordUpdate", token);
      window.Intercom("update", {
        app_id: "ito0ceux",
        name: user.username, // Full name
        email: user.email, // Email address
        created_at: user.createdAt, // Signup date as a Unix timestamp
        hide_default_launcher: !inBrandService,
      });
      // keeping that token and user profile info fresh as a daisy
      if (this.props.auth.user) {
        this.props.validateUserHandshake(
          this.props.auth.user.validationHandshake || "",
          this.props.auth.token
        );
      }
    }
  }

  getQuest = (questId, openModal) =>
    this.props.getQuest(questId, openModal, this.props.auth.token);

  togglePopup = () => this.setState({ showPopup: !this.state.showPopup });

  shouldOpenPopUp = async (broadcastString) => {
    const { user } = this.props.auth;
    const { twitter, discord, streamingConsoles } = user;
    const stillNeedInfo = !twitter || !discord || !streamingConsoles;
    const isNotTwitchCon =
      window.location.pathname !== "/make-some-noiz" &&
      window.location.pathname !== "/connect-accounts";
    if (!user.role === "brand" && stillNeedInfo && isNotTwitchCon) {
      const broadcast = await getBroadcasts(broadcastString);
      const { data } = broadcast;
      if (!data.broadcastValue) {
        this.setState({ showPopup: true });
      }
    }
  };

  forceCompleteInfo = ({ auth, router }) => {
    const { isAuthenticated, user: u } = auth;
    const incomplete =
      !u.timezone ||
      !u.firstName ||
      !u.lastName ||
      !u.timezone ||
      !u.birthDate ||
      !u.gender ||
      !u.country;
    if (isAuthenticated && incomplete && isRestrictedPathname()) {
      router.replace("/complete-registration");
    }
  };

  forceConnectAccount = (nextProps) => {
    const { connectedAccounts } = nextProps.auth.user;
    const { pathname } = this.props.router.location;
    if (
      nextProps.auth.isAuthenticated &&
      !connectedAccounts.twitch.id &&
      !connectedAccounts.youtube.id &&
      pathname !== "/connect-accounts" &&
      pathname !== "/complete-registration" &&
      pathname !== "/make-some-noiz"
    ) {
      this.props.router.replace("/connect-accounts");
    }
  };

  joinQuest = (questId, data) => {
    this.props.joinQuest(questId, data, this.props.auth.token);
  };

  leaveQuest = (questId) => {
    this.props.leaveQuest(questId);
  };

  closeQuest = () => {
    this.props.closeQuest();
  };

  toggleMyQuestsMenu() {
    this.setState({ myQuestsMenuOpen: !this.state.myQuestsMenuOpen });
  }

  render() {
    const {
      auth,
      auth: { user },
      notifications,
      router,
      router: {
        location: { pathname },
      },
    } = this.props;

    const isBrandUser = user.role === "brand";
    const isAdmin = user.role === "admin";
    const isClientPortal =
      (isAuthenticated() && isBrandUser) ||
      (!isAuthenticated() && window.location.pathname.includes("portal"));
    const mainClasses = classNames("Main", { Auth: isAuthenticated() });

    return (
      <ThemeProvider theme={noiz}>
        <CssBaseline />
        <section className={mainClasses}>
          <MainNav
            auth={auth}
            brandUser={this.props.brandUser}
            isBrandUser={isBrandUser}
            isAdmin={isAdmin}
            isClientPortal={isClientPortal}
            pathname={pathname}
            router={router}
            subdomain={getSubdomain()}
            toggleMyQuestsMenu={this.toggleMyQuestsMenu.bind(this)}
            user={this.props.user}
            visible={!isLogin()}
          />
          <section className="Main_Container">
            {React.cloneElement(this.props.children, this.props)}
          </section>
          <Footer
            authenticated={isAuthenticated()}
            isBrandUser={isBrandUser}
            isAdmin={isAdmin}
            visible={
              !isClientPortal && pathname !== "/admin/insights" && !isLogin()
            }
          />
          <MyQuests
            active={this.state.myQuestsMenuOpen}
            getQuest={this.getQuest.bind(this)}
            myQuests={this.props.user.myQuests}
            myUserQuests={this.props.user.myUserQuests}
            timedActivity={this.props.user.timedActivity}
            toggle={this.toggleMyQuestsMenu.bind(this)}
            user={user}
          />
          <Notifications
            notifications={notifications}
            style={{
              NotificationItem: {
                DefaultStyle: {
                  boxShadow: "0px 0px 1px rgba(0,0,0,1)",
                },
              },
            }}
          />
          <QuickUpdate
            assignLoginUpdateForm={this.props.assignLoginUpdateForm}
            auth={auth}
            broadcast="twitterDiscordUpdate"
            Form={LoginUpdateForm}
            header={twitterDiscordHeader}
            hidden={!this.state.showPopup}
            showPopup={this.state.showPopup}
            subHeader={twitterDiscordSubheader}
            submitCallback={this.props.updateUserInfo.bind(this)}
            togglePopup={this.togglePopup}
          />
        </section>
      </ThemeProvider>
    );
  }
}

Main.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({}).isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Main;
