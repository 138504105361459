/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router";
import classNames from "classnames";
import moment from "moment-timezone";
import ReactHtmlParser from "react-html-parser";
import TruncateMarkup from "react-truncate-markup";
import { CSVLink } from "react-csv";
import { filter, orderBy, without } from "lodash";
import { simpleNotification } from "../../../../utils/notifications";
import {
  numberWithCommas,
  getQuestRequirements,
} from "../../../../utils/functions";
import { campaignDetailedQuestsUsers } from "../../../../utils/downloadReports";
import { withTranslation } from "react-i18next";
import "./Campaigns.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import ReactTooltip from "react-tooltip";

import { getCampaignExtraTime } from "../../../../utils/apiv2";

class Campaigns extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      tab: 1,
      modal: 0,
      campaign: {},
      showingElements: [],
      loadingReport: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    localStorage.setItem(
      "campaignsPathname",
      this.props.router.location.pathname
    );
    if (!this.props.commandCenter.campaigns.isLoading) {
      this.props.getCCCampaignListByGame(gameId, token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      localStorage.getItem("campaignsPathname") !==
      nextProps.router.location.pathname
    ) {
      localStorage.setItem(
        "campaignsPathname",
        nextProps.router.location.pathname
      );
      const { token } = this.props.auth;
      const { gameId } = this.props.router.params;
      if (!this.props.commandCenter.campaigns.isLoading) {
        this.props.getCCCampaignListByGame(gameId, token);
      }
    }
  }

  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [...showingElements, elementId],
      });
    } else {
      this.setState({
        showingElements: [...without(showingElements, elementId)],
      });
    }
  };

  toEditRoute = (gameId, campaignId) => {
    this.props.router.push(
      `/admin/command-center/${gameId}/campaigns/${campaignId}/edit`
    );
  };

  toPortalRoute = (campaignId) => {
    this.props.router.push(
      `/portal?component=report&tab=quests&campaignId=${campaignId}`
    );
  };

  copyCampaignId = (campaignId) => {
    navigator.clipboard.writeText(campaignId);
    const { t } = this.props;

    simpleNotification({
      level: "success",
      title: t("copySuccessTitle"),
      message: `${campaignId}`,
      autoDismiss: 2,
    });
  };

  downloadReport = (campaign) => {
    const { token } = this.props.auth;
    this.props.getACampaignQuestsReport(campaign.id, token);
    this.setState({
      modal: 1,
      campaign,
    });
  };

  downloadExtraValueReport = async (campaign) => {
    const { t } = this.props;
    this.setState({ loadingReport: true });
    try {
      const analytics = await getCampaignExtraTime(campaign.id);
      const url = window.URL.createObjectURL(new Blob([analytics.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Extra_Time_Analytics_${campaign.name}.csv`
      ); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      if (err.response.statusText === "E_NO_DATA_REPORT") {
        simpleNotification({
          level: "error",
          title: t("noReportTitle"),
          message: t("noReportMessage"),
          autoDismiss: 7,
        });
      }
      console.log(
        err.response.statusText,
        "failure in download extra time report"
      );
      const that = this;
      setTimeout(() => that.setState({ loadingReport: false }), 2000);
    }
    const that2 = this;
    setTimeout(() => that2.setState({ loadingReport: false }), 1000);
  };

  generateSummaryBoxHTML = (campaigns, gameId) => {
    const { showingElements } = this.state;
    const { t } = this.props;
    const today = moment().tz("America/Los_Angeles");
    const hoursOffset = -today.utcOffset() / 60;
    return campaigns.map((campaign) => {
      const startDateTime = moment(campaign.startDateTime)
        .tz("America/Los_Angeles")
        .add(hoursOffset, "hours");
      const endDateTime = moment(campaign.endDateTime)
        .tz("America/Los_Angeles")
        .add(hoursOffset, "hours");

      const {
        budget,
        goals,
        viewerMinutesWatched,
        numUniqueStreamsMinMet,
        numUniqueStreamersMinMet,
        totalStreamsCompleted,
        completed,
      } = campaign;

      const uniqueStreams =
        numUniqueStreamsMinMet && numUniqueStreamsMinMet >= 0
          ? numUniqueStreamsMinMet
          : totalStreamsCompleted;
      const uniqueStreamers =
        numUniqueStreamersMinMet && numUniqueStreamersMinMet >= 0
          ? numUniqueStreamersMinMet
          : completed;

      const possible = campaign.possible || 0;
      const payable = campaign.payable || 0;

      const budgetPercentagePossible = ((possible / budget) * 100).toFixed(2);
      const budgetPercentagePayable = ((payable / budget) * 100).toFixed(2);

      const viewerMinutesWatchedNum = viewerMinutesWatched || 0;
      const viewerMinutesWatchedGoal =
        goals && goals.viewerMinutesWatched ? goals.viewerMinutesWatched : 0;
      const viewerMinutesPercentage =
        viewerMinutesWatchedGoal && viewerMinutesWatchedNum
          ? (
              (viewerMinutesWatchedNum / viewerMinutesWatchedGoal) *
              100
            ).toFixed(2)
          : 0;

      const uniqueStreamersGoal =
        goals && goals.uniqueStreamers ? Number(goals.uniqueStreamers) : 0;
      const uniqueStreamersPercentage =
        uniqueStreamersGoal && uniqueStreamers
          ? ((uniqueStreamers / uniqueStreamersGoal) * 100).toFixed(2)
          : 0;

      const uniqueStreamsGoal =
        goals && goals.uniqueStreams ? Number(goals.uniqueStreams) : 0;
      const uniqueStreamsPercentage =
        uniqueStreamsGoal && uniqueStreams
          ? ((uniqueStreams / uniqueStreamsGoal) * 100).toFixed(2)
          : 0;

      const summaryCreatedDate = campaign.summaryCreatedAt
        ? moment(campaign.summaryCreatedAt)
            .tz("America/Los_Angeles")
            .format("YYYY-MM-DD HH:mm a")
        : "n/a";

      return (
        <div className="Box" key={campaign.id}>
          <div className="Box__left">
            <ReactTooltip />
            <div className="Box__title">
              {campaign.name} - {startDateTime.format("MMMM DD")} {t("to")}{" "}
              {endDateTime.format("MMMM DD")}
              &nbsp;
              <i
                data-tip="Copy Campaign ID."
                style={{ cursor: "pointer", "font-size": "60%" }}
                onClick={() => this.copyCampaignId(campaign.id)}
                onKeyPress={() => this.copyCampaignId(campaign.id)}
                className="fa fa-clipboard"
              />
            </div>
            <div className="Box__row" style={{ margin: "30px" }}>
              {/* Budget (Possible) */}
              <div className="Box__item Box__item--4">
                <div className="Box__item--title">
                  {t("budgetPossible")}
                  <span
                    style={{ marginLeft: "5px" }}
                    data-tip={t("possibleTip")}
                  >
                    <i className="fa fa-info-circle" aria-hidden />
                  </span>
                </div>
                <div className="Box__item--value">
                  <LinearProgress
                    variant="determinate"
                    value={
                      budgetPercentagePossible > 100
                        ? 100
                        : budgetPercentagePossible
                    }
                  />
                  {`${`$${numberWithCommas(
                    possible.toFixed(2)
                  )}`} / ${`$${numberWithCommas(
                    budget
                  )}`} (${budgetPercentagePossible}%)`}
                </div>
              </div>

              {/* Budget (Payable) */}
              <div className="Box__item Box__item--4">
                <div className="Box__item--title">
                  {t("budgetPayable")}
                  <span
                    style={{ marginLeft: "5px" }}
                    data-tip={t("payableTip")}
                  >
                    <i className="fa fa-info-circle" aria-hidden />
                  </span>
                </div>
                <div className="Box__item--value">
                  <LinearProgress
                    variant="determinate"
                    value={
                      budgetPercentagePayable > 100
                        ? 100
                        : budgetPercentagePayable
                    }
                  />
                  {`${`$${numberWithCommas(
                    payable.toFixed(2)
                  )}`} / ${`$${numberWithCommas(
                    budget
                  )}`} (${budgetPercentagePayable}%)`}
                </div>
              </div>

              {/* Unique Streamers Completed */}
              {uniqueStreamsGoal ? (
                <div className="Box__item Box__item--4">
                  <div className="Box__item--title">
                    {t("uniqueStreams")}
                    <span
                      style={{ marginLeft: "5px" }}
                      data-tip={t("uniqueStreamsTip")}
                    >
                      <i className="fa fa-info-circle" aria-hidden />
                    </span>
                  </div>
                  <div className="Box__item--value">
                    <LinearProgress
                      variant="determinate"
                      value={
                        uniqueStreamsPercentage > 100
                          ? 100
                          : uniqueStreamsPercentage
                      }
                    />
                    {`${uniqueStreams} / ${uniqueStreamsGoal} (${uniqueStreamsPercentage}%)`}
                  </div>
                </div>
              ) : (
                <div className="Box__item Box__item--4">
                  <div className="Box__item--title">
                    {t("uniqueStreams")}
                    <span
                      style={{ marginLeft: "5px" }}
                      data-tip={t("uniqueStreamsTip")}
                    >
                      <i className="fa fa-info-circle" aria-hidden />
                    </span>
                  </div>
                  {uniqueStreams}
                </div>
              )}

              {/* Unique Streamers */}
              {uniqueStreamersGoal ? (
                <div className="Box__item Box__item--4">
                  <div className="Box__item--title">
                    {t("uniqueStreamers")}
                    <span
                      style={{ marginLeft: "5px" }}
                      data-tip={t("uniqueStreamersTip")}
                    >
                      <i className="fa fa-info-circle" aria-hidden />
                    </span>
                  </div>
                  <div className="Box__item--value">
                    <LinearProgress
                      variant="determinate"
                      value={
                        uniqueStreamersPercentage > 100
                          ? 100
                          : uniqueStreamersPercentage
                      }
                    />
                    {`${uniqueStreamers} / ${uniqueStreamersGoal} (${uniqueStreamersPercentage}%)`}
                  </div>
                </div>
              ) : (
                <div className="Box__item Box__item--4">
                  <div className="Box__item--title">
                    {t("uniqueStreamers")}
                    <span
                      style={{ marginLeft: "5px" }}
                      data-tip={t("uniqueStreamersTip")}
                    >
                      <i className="fa fa-info-circle" aria-hidden />
                    </span>
                  </div>
                  {uniqueStreamers}
                </div>
              )}
              {/* Viewer Mins Watched */}
              {viewerMinutesWatchedGoal ? (
                <div className="Box__item Box__item--4">
                  <div className="Box__item--title">
                    {t("totalWatchedGoal")}
                  </div>
                  <div className="Box__item--value">
                    <LinearProgress
                      variant="determinate"
                      value={
                        viewerMinutesPercentage > 100
                          ? 100
                          : viewerMinutesPercentage
                      }
                    />
                    {`${numberWithCommas(
                      viewerMinutesWatchedNum
                    )} / ${numberWithCommas(
                      viewerMinutesWatchedGoal
                    )} (${viewerMinutesPercentage}%)`}
                  </div>
                </div>
              ) : (
                <div className="Box__item Box__item--4">
                  <div className="Box__item--title">{t("totalWatched")}</div>
                  {viewerMinutesWatchedNum
                    ? numberWithCommas(viewerMinutesWatchedNum)
                    : 0}
                </div>
              )}
            </div>
            <div className="Box__updatedDate">
              {t("updatedDate")}: {summaryCreatedDate || "n/a"}
            </div>
            <div className="Box__quests">
              {t("includedQuests")}:{" "}
              {campaign.quests ? campaign.quests.length : "0"}
            </div>
            <div className="Box__quests">
              {t("includedQuests")}:{" "}
              {campaign.quests ? campaign.quests.length : "0"}
            </div>
            <div className="Box__users">
              {t("brandUsers")}: {this.renderBrandUsers(campaign.brandUsers)}
            </div>
            {showingElements.indexOf(campaign.id) === -1 && (
              <TruncateMarkup
                lines={7}
                ellipsis={
                  <React.Fragment>
                    <button
                      className="Box__readmore"
                      onClick={this.toggleElementInfo.bind(this, campaign.id)}
                    >
                      {t("readMore")}
                    </button>
                  </React.Fragment>
                }
              >
                <div>{ReactHtmlParser(campaign.summary)}</div>
              </TruncateMarkup>
            )}
            {showingElements.indexOf(campaign.id) !== -1 && (
              <React.Fragment>
                {ReactHtmlParser(campaign.summary)}
                <button
                  className="Box__readmore"
                  onClick={this.toggleElementInfo.bind(this, campaign.id)}
                >
                  {t("readLess")}
                </button>
              </React.Fragment>
            )}
            <div className="Box__cta">
              <button
                onClick={this.toEditRoute.bind(this, gameId, campaign.id)}
              >
                {t("edit")}
              </button>
              <button onClick={this.downloadReport.bind(this, campaign)}>
                <i className="fa fa-download" />
              </button>
              <button
                onClick={this.downloadExtraValueReport.bind(this, campaign)}
                className={this.state.loadingReport ? "disabled-report" : ""}
              >
                {t("btnReport")}
              </button>
              <button onClick={this.toPortalRoute.bind(this, campaign.id)}>
                {t("brandDashboard")}
              </button>
            </div>
          </div>
        </div>
      );
    });
  };

  changeTab = (value) => {
    this.setState({
      tab: value,
    });
  };

  closeModal = () => {
    this.setState({
      modal: 0,
    });
  };

  renderBrandUsers = (brandUsers) => {
    if (!brandUsers || brandUsers.length === 0) {
      return 0;
    }
    const usersEmailsArray = brandUsers.map((b) => b.email);
    const usersEmailsString = `(${usersEmailsArray.join(", ")})`;
    return `${brandUsers.length} ${usersEmailsString}`;
  };

  renderModal() {
    const { isLoading: tieredReportsIsLoading, data: tieredReports } =
      this.props.admin.tieredReports;
    const { isLoading: referralReportsIsLoading } =
      this.props.admin.referralReports;
    const { campaign } = this.state;
    const { t } = this.props;

    const appReqs = [];

    for (let i = 0; i < campaign.quests.length; i += 1) {
      const requirements = getQuestRequirements(campaign.quests[i], true);

      if (requirements) {
        for (let j = 0; j < requirements.length; j += 1) {
          const req = requirements[j];
          if (req.approvalRequired) {
            appReqs[req.id] = req.title;
            // Add vod views column for vod reqs
            if (req.type === "submit-link" && req.subtype === "vod") {
              appReqs[`${req.id}-views`] = "VOD Views";
              appReqs[`${req.id}-lastPulled`] = "VOD Views Last Pulled";
            }
          }
        }
      }
    }

    const reqHeaders = [];
    Object.keys(appReqs).forEach((id) => {
      reqHeaders.push({ label: appReqs[id], key: id });
    });

    const { headers, data } = campaignDetailedQuestsUsers(
      tieredReports,
      reqHeaders,
      campaign.chatMentions
    );

    return (
      <section className="CCCampaignsModalMaster active">
        <section className="Modal active">
          <div
            onClick={this.closeModal.bind(this)}
            className="Modal__layer"
            aria-hidden
          />
          <section className="Modal__wrapper">
            {(tieredReportsIsLoading || referralReportsIsLoading) && (
              <div className="Modal__loading">
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
              </div>
            )}
            {!(tieredReportsIsLoading || referralReportsIsLoading) && (
              <React.Fragment>
                <div className="Modal__header">{t("downloadReport")}</div>
                <div className="Modal__body">
                  <CSVLink
                    data={data}
                    headers={headers}
                    filename={
                      campaign && campaign.name
                        ? `Campaign Report - ${campaign.name}.csv`
                        : "report.csv"
                    }
                  >
                    <Tooltip title={t("campaignDetailsTip")} placement="top">
                      <i className="fa fa-download" />
                    </Tooltip>
                  </CSVLink>
                </div>
                <div className="Modal__footer">
                  <button onClick={this.closeModal.bind(this)}>
                    {t("close")}
                  </button>
                </div>
              </React.Fragment>
            )}
          </section>
        </section>
      </section>
    );
  }

  render() {
    const { gameId } = this.props.router.params;
    const { data: campaigns, isLoading } = this.props.commandCenter.campaigns;
    const { tab, modal } = this.state;
    const { t } = this.props;

    const today = moment().tz("America/Los_Angeles");
    const hoursOffset = -today.utcOffset() / 60;

    let currentCampaigns = filter(
      campaigns,
      (x) =>
        moment(x.endDateTime)
          .tz("America/Los_Angeles")
          .add(hoursOffset, "hours") > today
    );
    let endedCampaigns = filter(
      campaigns,
      (x) =>
        moment(x.endDateTime)
          .tz("America/Los_Angeles")
          .add(hoursOffset, "hours") <= today
    );

    currentCampaigns = orderBy(currentCampaigns, ["endDateTime"], ["asc"]);
    endedCampaigns = orderBy(endedCampaigns, ["endDateTime"], ["desc"]);

    return (
      <section className="CCCampaigns">
        <Link
          to={`/admin/command-center/${gameId}/campaigns/create`}
          activeClassName="active"
          href={`/admin/command-center/${gameId}/campaigns/create`}
        >
          <button className="CCCampaigns__create--btn CTAButton__purple">
            {t("createCampaign")}
          </button>
        </Link>
        <section className="ToggleTable">
          <section className="ToggleTable__header">
            <h3 className="ToggleTable__header-title">{t("title")}</h3>
            <div className="ToggleTable__tabs">
              <div
                className={classNames("ToggleTable__tab", {
                  active: tab === 1,
                })}
                onClick={this.changeTab.bind(this, 1)}
                aria-hidden
              >
                {t("current")}
              </div>
              <div
                className={classNames("ToggleTable__tab", {
                  active: tab === 2,
                })}
                onClick={this.changeTab.bind(this, 2)}
                aria-hidden
              >
                {t("ended")}
              </div>
            </div>
          </section>
          {isLoading && (
            <section className="ToggleTable__table-wrapper">
              <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
            </section>
          )}
          {!isLoading && tab === 1 && currentCampaigns.length > 0 && (
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                {this.generateSummaryBoxHTML(currentCampaigns, gameId)}
              </section>
            </section>
          )}
          {!isLoading && tab === 1 && currentCampaigns.length === 0 && (
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="ToggleTable--empty">
                  {t("noCurrentCampaigns")}
                </div>
              </section>
            </section>
          )}
          {!isLoading && tab === 2 && endedCampaigns.length > 0 && (
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                {this.generateSummaryBoxHTML(endedCampaigns, gameId)}
              </section>
            </section>
          )}
          {!isLoading && tab === 2 && endedCampaigns.length === 0 && (
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="ToggleTable--empty">
                  {t("noEndedCampaigns")}
                </div>
              </section>
            </section>
          )}
        </section>
        {modal === 1 && this.renderModal()}
      </section>
    );
  }
}

Campaigns.propTypes = {};

export default withTranslation("campaigns")(Campaigns);
