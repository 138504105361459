import React, { useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from "classnames";
import { findFlagUrlByCountryName } from "country-flags-svg";
import { useTranslation } from "react-i18next";

import { SetViewContext } from "../../PortalContext";
import {
  getDefaultIcon,
  htmlToPlainText,
  makeProper,
  platformToIcon,
} from "../../../../../utils/functions";

import Gallery from "../ProductDetail/Gallery";
import Icon from "../../../../../global/images/icons/Icon";
import Loading from "../../../../../global/components/loading/Loading";

import "./QuestDetail.scss";

const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" };

const QuestDetail = (props) => {
  const {
    currentQuest,
    brandUser: {
      quests: {
        data: { quests },
        isLoading,
      },
    },
    // tab,
    token,
    getBrandQuest,
  } = props;
  const { t } = useTranslation("portalLeft_questDetail");

  const [quest, setQuest] = useState({});

  useEffect(() => {
    if (currentQuest) {
      const foundQuest = quests?.find((q) => q.id === currentQuest);

      if (foundQuest) {
        setQuest(foundQuest);
      } else {
        getBrandQuest({ questId: currentQuest }, token);
      }
    }
  }, []);

  useEffect(() => {
    if (currentQuest) {
      const foundQuest = quests?.find((q) => q.id === currentQuest);

      if (foundQuest) {
        setQuest(foundQuest);
      } else {
        getBrandQuest({ questId: currentQuest }, token);
      }
    }
  }, [currentQuest]);

  useEffect(() => {
    if (!!quest) {
      const foundQuest = quests?.find((q) => q.id === currentQuest);

      if (foundQuest) {
        setQuest(foundQuest);
      }
    }
  }, [quests]);

  const {
    countries,
    description,
    endDateTime,
    faqs,
    game,
    id,
    languages,
    overlayImage,
    platforms,
    requiredBanner,
    requiredStreamTimeMinutes,
    requirements,
    startDateTime,
    title,
    twitchAccountRequired,
    viewerTiers,
  } = quest;

  // console.log("quest", quest);

  const minimumPaymentViewerTier = (viewerTiers || []).reduce((acc, tier) => {
    if (!acc.paymentAmount) return tier;
    if (tier.paymentAmount < acc.paymentAmount) return tier;
    return acc;
  }, {});

  const paymentAmountFormatted = !minimumPaymentViewerTier.paymentAmount
    ? "----"
    : minimumPaymentViewerTier.paymentAmount === -1
    ? t("freeKey")
    : `$${Number.parseFloat(minimumPaymentViewerTier.paymentAmount).toFixed(
        2
      )}`;

  const {
    beforeStream = [],
    duringStream = [],
    afterStream = [],
  } = requirements || {};

  const questRequirements = [...beforeStream, ...duringStream, ...afterStream]
    .map((r) => {
      const { type, subtype, title: reqTitle } = r;
      const icon =
        getDefaultIcon(
          type,
          subtype,
          { overlayImage, requiredBanner },
          { logo: game?.logo }
        ) || "";

      return {
        text: reqTitle,
        icon,
        applyDarken: type !== "display-download",
      };
    })
    .filter(({ text, icon }) => !!text && !!icon);

  const countryFlag =
    countries?.[0] && findFlagUrlByCountryName(countries?.[0]);

  const QuestDetailMasthead = () => (
    <div className="QuestDetailMasthead">
      <img
        className="QuestDetailMasthead__Cover"
        src={game?.cover}
        alt={title}
      />
      <SetViewContext.Consumer>
        {({ setView, view }) => (
          <div
            className="QuestDetailMasthead__Edit"
            onClick={() =>
              setView({
                tab: view.tab,
                currentQuest: id,
                component: "QuestEdit",
              })
            }
          >
            <Icon name="edit" />
            <div className="QuestDetailMasthead__Edit__Text">
              {t("editQuest")}
            </div>
          </div>
        )}
      </SetViewContext.Consumer>
      <div className="QuestDetailMasthead__Fade" />
      {game && game.log && (
        <img
          className="QuestDetailMasthead__GameLogo"
          src={game?.logo}
          alt={game?.name}
        />
      )}
      <div className="QuestDetailMasthead__Title">
        <div className="QuestDetailMasthead__Title__Text">
          {t("questDetails")}
        </div>
      </div>
    </div>
  );

  const QuestDetailsIcons = () => (
    <div className="QuestDetailsIcons">
      {twitchAccountRequired && <Icon name="twitch" />}
      {platforms?.map(
        ({ platform }) => platform && <Icon name={platformToIcon(platform)} />
      )}
    </div>
  );

  const QuestDetailDetails = () => (
    <div className="QuestDetailDetails">
      <div className="QuestDetailDetails__Title">{title}</div>
      <div className="QuestDetailDetails__Date">
        {new Date(startDateTime).toLocaleDateString("en-US", dateOptions)} -{" "}
        {new Date(endDateTime).toLocaleDateString("en-US", dateOptions)}
      </div>
      <div className="QuestDetailDetails__Reward">
        {t("reward")}: {paymentAmountFormatted}
      </div>
      <div className="QuestDetailDetails__RequiredStreamTime">
        {t("duration")}: {requiredStreamTimeMinutes} {t("minutes")}
      </div>
      <QuestDetailsIcons />
      <div className="QuestDetailDetails__Description">
        {htmlToPlainText(description)}
      </div>
    </div>
  );

  const DeliverableCard = ({
    text,
    icon,
    streamTimeReq = false,
    applyDarken = true,
  }) => (
    <div className="DeliverableCard">
      <div className="DeliverableCard__Icon">
        {streamTimeReq ? (
          <Icon name="clock" color="#242529" />
        ) : (
          <img
            className={classnames(
              "DeliverableCard__Icon__Img",
              applyDarken && "Darken"
            )}
            src={icon}
            alt={text}
          />
        )}
      </div>
      <div className="DeliverableCard__Text">{text}</div>
    </div>
  );

  const QuestDetailDeliverables = () => (
    <div className="QuestDetailDeliverables">
      <div className="QuestDetailDeliverables__Title">{t("deliverables")}</div>
      <div className="QuestDetailDeliverables__List">
        {questRequirements.map((qr) => (
          <DeliverableCard {...qr} />
        ))}
        {requiredStreamTimeMinutes > 0 && (
          <DeliverableCard
            text={`${t("streamFor")} ${requiredStreamTimeMinutes} ${t(
              "minutes"
            )}`}
            streamTimeReq
          />
        )}
      </div>
    </div>
  );

  const QuestDetailEligibility = () => (
    <div className="QuestDetailEligibility">
      <div className="Eligibility__Title">{t("eligibilityTitle")}</div>
      <div className="Eligibility__Items">
        <div className="Eligibility__Items__Item">
          <div className="Eligibility__Item__Title">
            {minimumPaymentViewerTier?.minimumAverageViewers || "----"}
          </div>
          <div className="Eligibility__Item__Subtitle">{t("minimumACCV")}</div>
        </div>
        {countryFlag && (
          <div className="Eligibility__Items__Item">
            <img
              className="Eligibility__Item__Flag"
              src={countryFlag}
              alt={countries[0]}
            />
            <div className="Eligibility__Item__Subtitle">{t("location")}</div>
          </div>
        )}
        {!!languages?.length && (
          <div className="Eligibility__Items__Item">
            <div className="Eligibility__Item__Title">
              {makeProper(languages?.[0])}
            </div>
            <div className="Eligibility__Item__Subtitle">{t("language")}</div>
          </div>
        )}
      </div>
    </div>
  );

  const QuestDetailDeveloper = () => {
    return (
      <div className="QuestDetailDeveloper">
        <div className="Developer__Header">
          {game?.developerLogo && (
            <div className="Developer__Header__Logo">
              <img
                className="Developer__Header__Logo__Img"
                src={game.developerLogo}
                alt={game?.developer}
              />
            </div>
          )}
          <div className="Developer__Header__Title">{game?.developer}</div>
        </div>
        <div className="Developer__Description">
          {htmlToPlainText(game?.description)}
        </div>
        <Tooltip title={game?.name} placement="top">
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <div
                className="Developer__LearnMore"
                onClick={() =>
                  setView({
                    ...view,
                    currentProduct: game?.id,
                    component: "ProductDetail",
                  })
                }
              >
                {t("learnMore")}
              </div>
            )}
          </SetViewContext.Consumer>
        </Tooltip>
      </div>
    );
  };

  const FAQCard = ({ question, answer }) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <div className="FAQCard" onClick={() => setExpanded(!expanded)}>
        <div className="FAQCard__Question">
          <div className="FAQCard__Question__Text">{question}</div>
          <Icon name={expanded ? "caretup" : "caretdown"} />
        </div>
        <div
          className={classnames(
            "FAQCard__Answer",
            expanded && "AnswerExpanded"
          )}
        >
          {htmlToPlainText(answer)}
        </div>
      </div>
    );
  };

  const QuestDetailFAQs = () => (
    <div className="QuestDetailFAQs">
      <div className="FAQ__Title">{t("FAQs")}</div>
      {faqs?.map((f, index) => (
        <FAQCard
          key={`faq-${index}`}
          question={f.title}
          answer={f.description}
        />
      ))}
    </div>
  );

  const styles = {
    header: {
      backgroundImage: `url(${game?.cover})`,
      height: "100%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },

    content: {
      height: "100%",
      width: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.85)",
    },
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="QuestDetail" style={styles.header}>
      <div style={styles.content}>
        <QuestDetailMasthead />
        <QuestDetailDetails />
        <QuestDetailEligibility />
        <QuestDetailDeliverables />
        <QuestDetailDeveloper />
        {!!game?.gallery?.length && (
          <div className="QuestDetail__Padding__Wrapper">
            <Gallery gallery={game.gallery.filter((g) => g.type === "image")} />
          </div>
        )}
        {!!faqs?.length && (
          <div className="QuestDetail__Padding__Wrapper">
            <QuestDetailFAQs />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestDetail;
