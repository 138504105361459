import React from 'react';
import classnames from 'classnames';
import { useTranslation} from 'react-i18next'

import Icon from '../../../../../global/images/icons/Icon';
import PixelToad from '../../../../../components/images/client-portal/8-bit-toad-bw@3x.png';

import { SetViewContext } from '../../PortalContext';

import './ViewAll.scss';

export const NoItemCard = ({ type = 'Quest' }) => {
  const { t} = useTranslation('portalLeft');

  return (
    <div className="NoQuestsCard mt-10">
      <div className="no-quest-image">
        <img src={PixelToad} alt={t("noQuests")} />
      </div>
      <div>
        {t("no")} {type}s
      </div>
    </div>
  );
}

export const ItemCard = (props) => {
  const {
    display,
    id,
    image,
    title,
    type,
  } = props;

  const { t } = useTranslation("portalLeft");

  return (
    <div className={classnames("ItemCard", display)}>
      <div className={classnames("ItemCard__Img", display)}>
        <img
          className={classnames("ItemCard__Img__Img", display)}
          src={image}
          alt={title}
        />
        {display === "grid" && (
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <div
                className="ItemCard__Img__Icon"
                onClick={() =>
                  setView({
                    ...view,
                    component: `${type}Edit`,
                    [`current${type}`]: id,
                  })
                }
              >
                <Icon name="edit" />
              </div>
            )}
          </SetViewContext.Consumer>
        )}
      </div>
      <div className={classnames("ItemCard__Info", display)}>
        <div className="ItemCard__Info__Title">{title}</div>
        <SetViewContext.Consumer>
          {({ setView, view }) => (
            <div className="ItemCard__Info__Buttons">
              <div
                className="ItemCard__Info__Buttons__Button"
                onClick={() =>
                  setView({
                    ...view,
                    component: `${type}Detail`,
                    [`current${type}`]: id,
                  })
                }
              >
                {t("details")}
              </div>
              {display !== "grid" && (
                <div
                  className="ItemCard__Info__Buttons__Button"
                  onClick={() =>
                    setView({
                      ...view,
                      tab: view.tab,
                      component: `${type}Edit`,
                      [`current${type}`]: id,
                    })
                  }
                >
                  { t('edit')}
                </div>
              )}
            </div>
          )}
        </SetViewContext.Consumer>
      </div>
    </div>
  );
};
