/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { Field, reduxForm } from "redux-form";
import CustomInput from "../../utils/redux-forms-custom-fields/CustomInput";
import { dayjs } from "../../utils/dateTime";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

class SignInForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit = (userFormInfo) => {
    const { role, onSubmit } = this.props;

    onSubmit({
      browserTimezone: dayjs.tz.guess(),
      identity: userFormInfo.identity.toLowerCase(),
      password: userFormInfo.password,
      ...(role ? { role } : {}),
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const isClientPortal = !!window.location.pathname.includes("portal");
    const { t } = this.props;

    if (isClientPortal) {
      return (
        <form
          className="form-container signup client-portal"
          autoComplete="off"
          onSubmit={handleSubmit(this.submit.bind(this))}
        >
          <span
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              margin: "0 0 10px 0",
            }}
          >
            {" "}
            {t("portalEmailOrUsername")}{" "}
          </span>
          <Field
            type="text"
            name="identity"
            id="identity"
            component={CustomInput}
          />
          <span
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              margin: "0 0 10px 0",
            }}
          >
            {" "}
            {t("portalPassword")}{" "}
          </span>
          <Field
            type="password"
            name="password"
            id="password"
            component={CustomInput}
          />
          <div
            className="form__footer form__footer__2"
            style={{ margin: "40px 0 0 0" }}
          >
            {this.props.auth.isLoading && (
              <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
            )}
            {!this.props.auth.isLoading && (
              <div className="form__footer__left">
                <button
                  style={{ width: "120px", borderRadius: "25px" }}
                  className="CTAButton__purple"
                >
                  {t("login")}
                </button>
                <span style={{ margin: "0 0 0 20px" }}>
                  {t("forgotYourPassword")}
                  <Link
                    className="Modal__tab"
                    to="/recover"
                    href="/recover"
                    role="button"
                    aria-hidden
                  >
                    {t("clickHere")}
                  </Link>
                </span>
              </div>
            )}
          </div>
        </form>
      );
    }
    return (
      <form
        className="form-container signup"
        autoComplete="off"
        onSubmit={handleSubmit(this.submit.bind(this))}
      >
        <Field
          type="text"
          name="identity"
          id="identity"
          placeholder={t("signIn.emailPlaceholder")}
          customLabel2={
            <label
              data-label={t("signIn.emailPlaceholder")}
              data-label-small={t("signIn.emailLabel")}
              htmlFor="identity"
            />
          }
          component={CustomInput}
        />
        <Field
          type="password"
          name="password"
          id="password"
          placeholder={t("signIn.passwordPlaceholder")}
          customLabel2={
            <label
              data-label={t("signIn.passwordPlaceholder")}
              data-label-small={t("signIn.passwordLabel")}
            />
          }
          component={CustomInput}
        />
        <div className="form__footer form__footer__2">
          {this.props.auth.isLoading && (
            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          )}
          {!this.props.auth.isLoading && (
            <div className="form__footer__left">
              <button className="cta-button">{t("continue")}</button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.identity || values.identity.trim() === "") {
    errors.identity = t("sign:validate.required");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = t("sign:validate.emailOrNameInvalid");
  }
  if (!values.password || values.password.trim() === "") {
    errors.password = t("sign:validate.required");
  }

  return errors;
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation("sign")(
  reduxForm({
    form: "signInForm",
    validate,
  })(SignInForm)
);
