import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router";

import Icon from "../../global/images/icons/Icon";
import { localizeIncorrectDate } from "../../utils/dateTime";
import { getPaymentAmount, rounding } from "../../utils/functions";

import "./QuestItem.scss";
import { useTranslation } from "react-i18next";

function QuestItem({ isBrand, quest, user, userQuest }) {
  const {
    endDateTime,
    id = "",
    memberTiers = [],
    platforms = [],
    slug = "",
    startDateTime,
    title = "",
    viewerTiers = [],
  } = quest;
  const { status } = userQuest;

  const { t } = useTranslation("questItem");

  const { paymentAmount } = getPaymentAmount(
    memberTiers,
    user,
    viewerTiers,
    userQuest
  );
  const ineligible =
    ["declined", "leftQuest", "rejected"].includes(status) ||
    paymentAmount === Number.MIN_SAFE_INTEGER;
  const useGrey = ineligible || status;
  const iconColor = useGrey ? "#83858c" : "white";
  const platformIcon =
    (platforms && platforms[0] && platforms[0].baseService) || "Other";
  const freeKey = paymentAmount === -1;

  let paymentText = `$${rounding(paymentAmount)}`;

  if (paymentAmount === Number.MIN_SAFE_INTEGER || ineligible) {
    paymentText = "$--";
  } else if (freeKey) {
    paymentText = t("freeKey");
  }

  return (
    <li className={`quest-item ${useGrey ? "grey" : ""}`}>
      <Tooltip title={title || ""}>
        <span className="title">{title}</span>
      </Tooltip>
      {/* <div className="Quest__ManagedBy">
        {!isBrand ? (
          <>
            <Icon name='handgamecontroller' color='black' />
            <div className='Quest__ManagedBy__Label'>Actively Managed</div>
          </>
        ) : (
          <>
            <Icon name='lightningbolt' color='black' />
            <div className='Quest__ManagedBy__Label'>Community Quest</div>
          </>
        )}
      </div> */}
      <span className={`payment-amount ${freeKey ? "free-key" : ""}`}>
        {paymentText}
      </span>
      <div className="platform-icons">
        <div>
          <Icon color={iconColor} name="Twitch" />
        </div>
        <div className="right">
          <Icon color={iconColor} name={platformIcon} />
        </div>
      </div>
      <span className="date-range">
        {`${localizeIncorrectDate(startDateTime).format(
          "MMM D"
        )} - ${localizeIncorrectDate(endDateTime).format("MMM D, YYYY")}`}
      </span>
      <Link
        className={`button ${
          ineligible ? "ineligible" : status ? "applied" : "view-details"
        }`}
        to={`/quests/${slug || id}`}
      >
        <span className="text">
          {`${
            ineligible
              ? t("ineligible")
              : status
              ? t("applied")
              : t("viewDetails")
          }`}
        </span>
      </Link>
    </li>
  );
}

export default QuestItem;
