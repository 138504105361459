import React from "react";
import { getLanguageInfo } from "languages";

import languageIcon from "../../global/images/icons/language-icon.svg";
import { useTranslation } from "react-i18next";

const Languages = (props) => {
  const { languages = ["en"] } = props;

  const { t } = useTranslation("quest_Languages");

  const languageRequirements = (languages.length &&
    languages.map((l) => {
      if (l === "any") return "Any Language";
      return getLanguageInfo(l).name;
    })) || ["Any Language"];

  return (
    <div className="eligibilityItem">
      <img src={languageIcon} alt="eligibility item" />
      <span className="label">
        {t("speak")} {languageRequirements.slice(0, -1).join(", ")}
        {languageRequirements.length > 2 && ","}
        {languageRequirements.length > 1 && " or "}
        {languageRequirements.slice(-1)}
      </span>
    </div>
  );
};

export default Languages;
