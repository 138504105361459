import Axios from "axios";

import { simpleNotification } from "./notifications";
import { queryString } from "./functions";
import { multiLanguage } from "./util";

const endpoints = {
  //brand user endpoints
  connectUserBrandPaymentOption: () => "/api/v1/user-brand/payment/connect",
  deleteUserBrandPaymentMethod: () =>
    "/api/v1/user-brand/payment/delete-payment-method",
  enterArbitrationBrand: () => "/api/v1/user-brand/arbitration",
  getBrandQuestSummary: (questId) =>
    `/api/v1/user-brand/quest/get-summary/${questId}`,
  getQuestInvites: (query) =>
    `/api/v1/user-brand/quest/invite/${queryString(query)}`,
  getUserBrandPaymentMethods: () =>
    "/api/v1/user-brand/payment/get-payment-methods",
  getArbitrationStatusUserQuest: (userQuestId) =>
    `/api/v1/user-brand/arbitration/${userQuestId}`,
  makePaymentForQuest: () => "/api/v1/user-brand/payment/make-a-payment",
  postQuestInvite: () => "/api/v1/user-brand/quest/invite",
  putAgreement: () => "/api/v1/user/agreement/accept",

  // streamer endpoints
  calculatePaymentInfoForStreamer: (streamerId, questId) =>
    `/api/v2/user-brand/streamer/get-payment-info/${streamerId}/${questId}`,
  checkQuestCompletionStreamer: (userQuestId) =>
    `/api/v1/user-brand/streamer/check-quest-completion/${userQuestId}`,
  connectStreamerBankAccount: (region) =>
    `/api/v1/user/payment/connect/${region}`,
  createProductUserBrand: () => "/api/v1/user-brand/game/create",
  discoverStreamers: (pagination) =>
    `/api/v1/user-brand/streamer/discover${queryString(pagination)}`,
  deleteStreamerStripeAccount: () =>
    "/api/v1/user/payment/delete-stripe-account",
  getAllAgreements: () => "/api/v1/user/agreement/get-all",
  getListOfCountries: () => "/api/v1/stripe/countries",
  getStreamerBankAccounts: () => "/api/v1/user/payment/list-bank-accounts",
  loginToStripe: () => "/api/v1/user/payment/login-to-stripe",
  payStreamer: () => "/api/v1/user-brand/payment/pay-streamer",
  // admin endpoints
  adminGetArbitration: (status, pageSize, numPage, sort, search) =>
    `/api/v1/admin/arbitration${queryString({
      status,
      pageSize,
      numPage,
      sort,
      search,
    })}`,
  pingTwitchGames: (searchValue) =>
    `/api/v1/admin/game/search/${searchValue.trim()}`,
  analyticsGetSiteWide: () => "/api/v1/analytics/get-site-wide",
  getCampaignExtraTime: (id) => `/api/v1/admin/campaign/extra-time/${id}`,
  updateUserQuest: () => `/api/v1/quest/userquest`,
  deleteQuestById: (id) => `/api/v1/admin/quest/${id}`,
  questTimed: () => "/api/v1/quest/questtimed",
  updateAvatarUrl: () => "/api/v1/user/request-update-avatar-url",
  postBroadcasts: () => "/api/v1/user/broadcasts",
  intercomPostLead: () => "/api/v1/intercom/post-lead",
  getBroadcasts: (broadcastString) =>
    `/api/v1/user/broadcasts/${broadcastString}`,
  stripePayment: () => "/api/v1/stripe/payment/post",
  deleteProduct: (currentProduct) =>
    `/api/v1/user-brand/game/${currentProduct}`,
  getReportsTable: (campaign, preppedQuests) =>
    `/api/v1/user-brand/reports/table?campaign=${campaign}&quest=${preppedQuests}`,
  getCompleteUsers: () => "/api/admin/insights/get-complete-users",
  getInitialData: () => "/api/admin/insights/get-initial-data",
  getInsights: () => "/api/admin/insights/get-insights",
  getTwitchStreamLanguage: () => "/api/v1/account/twitch-stream-language",
  getReadyPayment: (pageNo, paid) =>
    `/api/v1/admin/quest/completed?pageNo=${pageNo}&paid=${paid}`,
  // `/api/v1/admin/payments/${pageNum}/${filter}/${sorting}`,
  getBankCard: (userId) =>
    `/api/v1/user-brand/payment/get-user-payment-methods?userId=${userId}`,
  completeQuestPay: () => `/api/v1/admin/quest/completed-quest-pay`,
};

const multiLanguageJson = {
  en: {
    errorFailover: "Error failover, no error on response",
    simpleNotification_1: {
      title: "Success",
      message: "It worked!",
    },
    simpleNotification_2: {
      title: "Oops, there was a problem",
    },
    simpleNotification_3: {
      title: "Could Not Redirect",
    },
  },
  "pt-BR": {
    errorFailover: "Failover de erro, sem erro na resposta",
    simpleNotification_1: {
      title: "Sucesso",
      message: "Funcionou!",
    },
    simpleNotification_2: {
      title: "Ops, ocorreu um problema",
    },
    simpleNotification_3: {
      title: "Não foi possível redirecionar",
    },
  },
};

const axiosWrapper = async ({
  url,
  method,
  data = {},
  notifyUser = false,
  headers,
  notifyError = true,
}) => {
  try {
    const res = await Axios({
      method,
      url,
      data,
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Accept-Language": multiLanguage,
        ...headers,
      },
    });
    if (notifyUser) {
      simpleNotification({
        level: "success",
        title: `${multiLanguageJson[multiLanguage]?.simpleNotification_1?.title}`,
        message:
          res?.data?.message ||
          `${multiLanguageJson[multiLanguage]?.simpleNotification_1?.message}`,
      });
    }
    return res;
  } catch (err) {
    const message =
      err?.response?.data?.message ||
      `${multiLanguageJson[multiLanguage]?.errorFailover}`;
    if (message === "You are not brand or noiz") {
      window.location.href = "/logout";
    }
    if (notifyError) {
      simpleNotification({
        level: "error",
        title: `${multiLanguageJson[multiLanguage]?.simpleNotification_2?.title}`,
        message,
      });
    }
  }
};

const axiosWrapperRedirect = async (url, method) => {
  let redirectUrl = "";
  let newWindow = window.open(window.location.origin + "/stripe-loading");
  try {
    const toRedirect = await Axios({
      method,
      url,
    });
    redirectUrl = toRedirect.data.data;
    newWindow.location.href = redirectUrl;
  } catch (err) {
    simpleNotification({
      level: "error",
      title: `${multiLanguageJson[multiLanguage].simpleNotification_3.title}`,
      message:
        err?.response?.data?.message ||
        `${multiLanguageJson[multiLanguage]?.errorFailover}`,
    });
    newWindow.close();
  }
};

export const connectUserBrandPaymentOption = async () =>
  await axiosWrapperRedirect(endpoints.connectUserBrandPaymentOption(), "GET");

export const connectStreamerBankAccount = async (region) =>
  await axiosWrapperRedirect(
    endpoints.connectStreamerBankAccount(region),
    "GET"
  );

export const loginToStripe = async () =>
  await axiosWrapperRedirect(endpoints.loginToStripe(), "GET");

export const calculatePaymentInfoForStreamer = async (streamerId, questId) =>
  await axiosWrapper({
    url: endpoints.calculatePaymentInfoForStreamer(streamerId, questId),
    method: "GET",
  });

export const checkQuestCompletionStreamer = async (userQuestId) =>
  await axiosWrapper({
    url: endpoints.checkQuestCompletionStreamer(userQuestId),
    method: "GET",
  });

export const createProductUserBrand = async (data) =>
  await axiosWrapper({
    url: endpoints.createProductUserBrand(),
    method: "POST",
    data,
    notifyUser: true,
  });

export const deleteStreamerStripeAccount = async () =>
  await axiosWrapper({
    url: endpoints.deleteStreamerStripeAccount(),
    method: "DELETE",
  });

export const deleteUserBrandPaymentMethod = async (paymentId) =>
  await axiosWrapper({
    url: endpoints.deleteUserBrandPaymentMethod(),
    method: "DELETE",
    data: {
      paymentId,
    },
  });

export const discoverStreamers = async (pagination) =>
  await axiosWrapper({
    url: endpoints.discoverStreamers(pagination),
    method: "GET",
  });

export const enterArbitrationBrand = async (data) =>
  await axiosWrapper({
    url: endpoints.enterArbitrationBrand(),
    method: "POST",
    data,
    notifyUser: true,
  });

export const getAllAgreements = async () =>
  await axiosWrapper({ url: endpoints.getAllAgreements(), method: "GET" });

export const getArbitrationStatusUserQuest = async (userQuestId) =>
  await axiosWrapper({
    url: endpoints.getArbitrationStatusUserQuest(userQuestId),
    method: "GET",
  });

export const getBrandQuestSummary = async (questId) =>
  await axiosWrapper({
    url: endpoints.getBrandQuestSummary(questId),
    method: "GET",
  });

export const getQuestInvites = async (query) =>
  await axiosWrapper({ url: endpoints.getQuestInvites(query), method: "GET" });

export const getStreamerBankAccounts = async () =>
  await axiosWrapper({
    url: endpoints.getStreamerBankAccounts(),
    method: "GET",
  });

export const getStripeCountries = async () =>
  await axiosWrapper({ url: endpoints.getListOfCountries(), method: "GET" });

export const getUserBrandPaymentMethods = async () =>
  await axiosWrapper({
    url: endpoints.getUserBrandPaymentMethods(),
    method: "GET",
  });

export const makePaymentForQuest = async (questId) =>
  await axiosWrapper({
    url: endpoints.makePaymentForQuest(),
    method: "POST",
    data: { questId },
  });

export const payStreamer = async (userQuestId) =>
  await axiosWrapper({
    url: endpoints.payStreamer(),
    method: "POST",
    data: { userQuestId },
    notifyUser: true,
  });

export const postQuestInvite = async (body) =>
  await axiosWrapper({
    url: endpoints.postQuestInvite(),
    method: "POST",
    data: body,
    notifyUser: true,
  });

export const putAgreement = async (type, version) =>
  await axiosWrapper({
    url: endpoints.putAgreement(),
    method: "PUT",
    data: { type, version },
    notifyUser: true,
  });

export const pingTwitchGames = async (searchValue) =>
  await axiosWrapper({
    url: endpoints.pingTwitchGames(searchValue),
    method: "GET",
  });

export const analyticsGetSiteWide = async () =>
  await axiosWrapper({
    url: endpoints.analyticsGetSiteWide(),
    method: "GET",
    headers: {
      responseType: "blob",
    },
  });

export const getCampaignExtraTime = async (id) =>
  await axiosWrapper({
    url: endpoints.getCampaignExtraTime(id),
    method: "GET",
    headers: {
      responseType: "blob",
    },
  });

export const updateUserQuest = async (body) =>
  await axiosWrapper({
    url: endpoints.updateUserQuest(),
    method: "POST",
    data: body,
  });

export const deleteQuestById = async (id) =>
  await axiosWrapper({
    url: endpoints.updateUserQuest(id),
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

export const questTimed = async (body) =>
  await axiosWrapper({
    url: endpoints.questTimed(),
    method: "POST",
    data: body,
  });

export const updateAvatarUrl = async (body) =>
  await axiosWrapper({
    url: endpoints.updateAvatarUrl(),
    method: "POST",
    data: body,
    headers: {
      "Content-Type": "application/json",
    },
  });

export const postBroadcasts = async (body) =>
  await axiosWrapper({
    url: endpoints.postBroadcasts(),
    method: "POST",
    data: body,
    headers: {
      "Content-Type": "application/json",
    },
  });
export const intercomPostLead = async (body) =>
  await axiosWrapper({
    url: endpoints.intercomPostLead(),
    method: "POST",
    data: body,
    notifyError: false,
  });

export const getBroadcasts = async (broadcastString) =>
  await axiosWrapper({
    url: endpoints.intercomPostLead(broadcastString),
    method: "GET",
  });

export const stripePayment = async (data) =>
  await axiosWrapper({
    url: endpoints.stripePayment(),
    method: "POST",
    data,
  });

export const deleteProduct = async (data) =>
  await axiosWrapper({
    url: endpoints.deleteProduct(),
    method: "DELETE",
    data,
    notifyError: false,
  });

export const getReportsTable = async (data) =>
  await axiosWrapper({
    url: endpoints.getCompleteUsers(),
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });

export const getCompleteUsersApi = async (data) =>
  await axiosWrapper({
    url: endpoints.getCompleteUsers(),
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
export const getInitialDataApi = async () =>
  await axiosWrapper({
    url: endpoints.getInitialData(),
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
export const getInsightsApi = async (data) =>
  await axiosWrapper({
    url: endpoints.getInsights(),
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
export const getTwitchStreamLanguage = async () =>
  await axiosWrapper({
    url: endpoints.getTwitchStreamLanguage(),
    method: "GET",
  });

export const getReadyPayment = async (pageNo, paid) =>
  await axiosWrapper({
    url: endpoints.getReadyPayment(pageNo, paid),
    method: "GET",
    params: {
      pageNo,
      paid,
    },
  });

//
//
// admin endpoints
//
export const adminGetArbitration = async (
  status = "pending",
  pageSize = 15,
  numPage = 1,
  sort = "desc",
  search = ""
) =>
  await axiosWrapper({
    url: endpoints.adminGetArbitration(status, pageSize, numPage, sort, search),
    method: "GET",
  });

export const getBankCardApi = async (userId) =>
  await axiosWrapper({
    url: endpoints.getBankCard(userId),
    method: "GET",
  });

  export const completeQuestPayApi = async (data) =>
    await axiosWrapper({
      url: endpoints.completeQuestPay(),
      method: "POST",
      data
    });
