import api from "../utils/api";
import { getErrorResponse } from "../utils/apiErrorHandler";

/* eslint-disable import/prefer-default-export */
export const uploadCSV = (action$) =>
  action$.ofType("UPLOAD_CSV").mergeMap((action) =>
    api
      .uploadCSV(action.payload.multipartFormData, action.payload.token)
      .map((response) => ({ type: "UPLOAD_CSV_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("UPLOAD_CSV_ABORTED"))
      .defaultIfEmpty({ type: "UPLOAD_CSV_CANCELED" })
      .catch((error) => getErrorResponse({ type: "UPLOAD_CSV_ERROR", error }))
      .startWith({ type: "UPLOAD_CSV_PENDING", payload: action.payload })
  );

export const getGameBountiesData = (action$) =>
  action$.ofType("GET_GAME_BOUNTIES_DATA").mergeMap((action) =>
    api
      .getGameBountiesData(action.payload.gameId, action.payload.token)
      .map((response) => ({
        type: "GET_GAME_BOUNTIES_DATA_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_GAME_BOUNTIES_DATA_ABORTED"))
      .defaultIfEmpty({ type: "GET_GAME_BOUNTIES_DATA_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_GAME_BOUNTIES_DATA_ERROR", error })
      )
      .startWith({
        type: "GET_GAME_BOUNTIES_DATA_PENDING",
        payload: action.payload,
      })
  );

export const getGameLiveStreams = (action$) =>
  action$.ofType("GET_GAMES_LIVE_STREAMS").mergeMap((action) =>
    api
      .getGameLiveStreams(action.payload.gameId, action.payload.token)
      .map((response) => ({
        type: "GET_GAMES_LIVE_STREAMS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_GAMES_LIVE_STREAMS_ABORTED"))
      .defaultIfEmpty({ type: "GET_GAMES_LIVE_STREAMS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_GAMES_LIVE_STREAMS_ERROR", error })
      )
      .startWith({
        type: "GET_GAMES_LIVE_STREAMS_PENDING",
        payload: action.payload,
      })
  );

export const getAUserList = (action$) =>
  action$.ofType("GET_A_USER_LIST").mergeMap((action) =>
    api
      .getAUserList(
        action.payload.pageNum,
        action.payload.filter,
        action.payload.sorting,
        action.payload.exactMatch,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_A_USER_LIST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_USER_LIST_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_USER_LIST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_USER_LIST_ERROR", error })
      )
      .startWith({ type: "GET_A_USER_LIST_PENDING", payload: action.payload })
  );

export const getAUserListCsv = (action$) =>
  action$.ofType("GET_A_USER_LIST_CSV").mergeMap((action) =>
    api
      .getAUserListCsv(
        action.payload.pageNum,
        action.payload.filter,
        action.payload.sorting,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_A_USER_LIST_CSV_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_USER_LIST_CSV_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_USER_LIST_CSV_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_USER_LIST_CSV_ERROR", error })
      )
      .startWith({
        type: "GET_A_USER_LIST_CSV_PENDING",
        payload: action.payload,
      })
  );

export const getAUser = (action$) =>
  action$.ofType("GET_A_USER").mergeMap((action) =>
    api
      .getAUser(action.payload.userId, action.payload.token)
      .map((response) => ({ type: "GET_A_USER_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("GET_A_USER_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_USER_CANCELED" })
      .catch((error) => getErrorResponse({ type: "GET_A_USER_ERROR", error }))
      .startWith({ type: "GET_A_USER_PENDING", payload: action.payload })
  );

export const createAUser = (action$) =>
  action$.ofType("CREATE_A_USER").mergeMap((action) =>
    api
      .createAUser(action.payload.user, action.payload.token)
      .map((response) => ({ type: "CREATE_A_USER_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("CREATE_A_USER_ABORTED"))
      .defaultIfEmpty({ type: "CREATE_A_USER_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "CREATE_A_USER_ERROR", error })
      )
      .startWith({ type: "CREATE_A_USER_PENDING", payload: action.payload })
  );

export const createAUserBrand = (action$) =>
  action$.ofType("CREATE_A_USER_BRAND").mergeMap((action) =>
    api
      .createAUserBrand(action.payload.user, action.payload.token)
      .map((response) => ({
        type: "CREATE_A_USER_BRAND_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("CREATE_A_USER_BRAND_ABORTED"))
      .defaultIfEmpty({ type: "CREATE_A_USER_BRAND_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "CREATE_A_USER_BRAND_ERROR", error })
      )
      .startWith({
        type: "CREATE_A_USER_BRAND_PENDING",
        payload: action.payload,
      })
  );

export const updateAUser = (action$) =>
  action$.ofType("UPDATE_A_USER").mergeMap((action) =>
    api
      .updateAUser(
        action.payload.userId,
        action.payload.user,
        action.payload.token
      )
      .map((response) => ({ type: "UPDATE_A_USER_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("UPDATE_A_USER_ABORTED"))
      .defaultIfEmpty({ type: "UPDATE_A_USER_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "UPDATE_A_USER_ERROR", error })
      )
      .startWith({ type: "UPDATE_A_USER_PENDING", payload: action.payload })
  );

export const getAConfigList = (action$) =>
  action$.ofType("GET_A_CONFIG_LIST").mergeMap((action) =>
    api
      .getAConfigList(action.payload.token)
      .map((response) => ({
        type: "GET_A_CONFIG_LIST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_CONFIG_LIST_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_CONFIG_LIST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_CONFIG_LIST_ERROR", error })
      )
      .startWith({ type: "GET_A_CONFIG_LIST_PENDING", payload: action.payload })
  );

export const getAConfig = (action$) =>
  action$.ofType("GET_A_CONFIG").mergeMap((action) =>
    api
      .getAConfig(action.payload.configId, action.payload.token)
      .map((response) => ({ type: "GET_A_CONFIG_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("GET_A_CONFIG_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_CONFIG_CANCELED" })
      .catch((error) => getErrorResponse({ type: "GET_A_CONFIG_ERROR", error }))
      .startWith({ type: "GET_A_CONFIG_PENDING", payload: action.payload })
  );

export const createAConfig = (action$) =>
  action$.ofType("CREATE_A_CONFIG").mergeMap((action) =>
    api
      .createAConfig(action.payload.config, action.payload.token)
      .map((response) => ({
        type: "CREATE_A_CONFIG_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("CREATE_A_CONFIG_ABORTED"))
      .defaultIfEmpty({ type: "CREATE_A_CONFIG_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "CREATE_A_CONFIG_ERROR", error })
      )
      .startWith({ type: "CREATE_A_CONFIG_PENDING", payload: action.payload })
  );

export const updateAConfig = (action$) =>
  action$.ofType("UPDATE_A_CONFIG").mergeMap((action) =>
    api
      .updateAConfig(action.payload.config, action.payload.token)
      .map((response) => ({
        type: "UPDATE_A_CONFIG_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("UPDATE_A_CONFIG_ABORTED"))
      .defaultIfEmpty({ type: "UPDATE_A_CONFIG_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "UPDATE_A_CONFIG_ERROR", error })
      )
      .startWith({ type: "UPDATE_A_CONFIG_PENDING", payload: action.payload })
  );

export const getAdminQuestListByGame = (action$) =>
  action$.ofType("GET_ADMIN_QUEST_LIST_BY_GAME").mergeMap((action) =>
    api
      .getAdminQuestListByGame(action.payload.gameId, action.payload.token)
      .map((response) => ({
        type: "GET_ADMIN_QUEST_LIST_BY_GAME_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_ADMIN_QUEST_LIST_BY_GAME_ABORTED"))
      .defaultIfEmpty({ type: "GET_ADMIN_QUEST_LIST_BY_GAME_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_ADMIN_QUEST_LIST_BY_GAME_ERROR", error })
      )
      .startWith({
        type: "GET_ADMIN_QUEST_LIST_BY_GAME_PENDING",
        payload: action.payload,
      })
  );

export const getAGameList = (action$) =>
  action$.ofType("GET_A_GAME_LIST").mergeMap((action) =>
    api
      .getAGameList(
        action.payload.pageNum,
        action.payload.filter,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_A_GAME_LIST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_GAME_LIST_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_GAME_LIST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_GAME_LIST_ERROR", error })
      )
      .startWith({ type: "GET_A_GAME_LIST_PENDING", payload: action.payload })
  );

export const getAGame = (action$) =>
  action$.ofType("GET_A_GAME").mergeMap((action) =>
    api
      .getAGame(action.payload.gameId, action.payload.token)
      .map((response) => ({ type: "GET_A_GAME_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("GET_A_GAME_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_GAME_CANCELED" })
      .catch((error) => getErrorResponse({ type: "GET_A_GAME_ERROR", error }))
      .startWith({ type: "GET_A_GAME_PENDING", payload: action.payload })
  );

export const createAGame = (action$) =>
  action$.ofType("CREATE_A_GAME").mergeMap((action) =>
    api
      .createAGame(action.payload.game, action.payload.token)
      .map((response) => ({ type: "CREATE_A_GAME_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("CREATE_A_GAME_ABORTED"))
      .defaultIfEmpty({ type: "CREATE_A_GAME_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "CREATE_A_GAME_ERROR", error })
      )
      .startWith({ type: "CREATE_A_GAME_PENDING", payload: action.payload })
  );

export const updateAGame = (action$) =>
  action$.ofType("UPDATE_A_GAME").mergeMap((action) =>
    api
      .updateAGame(
        action.payload.gameId,
        action.payload.game,
        action.payload.token
      )
      .map((response) => ({ type: "UPDATE_A_GAME_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("UPDATE_A_GAME_ABORTED"))
      .defaultIfEmpty({ type: "UPDATE_A_GAME_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "UPDATE_A_GAME_ERROR", error })
      )
      .startWith({ type: "UPDATE_A_GAME_PENDING", payload: action.payload })
  );

export const createAPlatform = (action$) =>
  action$.ofType("CREATE_A_PLATFORM").mergeMap((action) =>
    api
      .createAPlatform(action.payload.platform, action.payload.token)
      .map((response) => ({
        type: "CREATE_A_PLATFORM_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("CREATE_A_PLATFORM_ABORTED"))
      .defaultIfEmpty({ type: "CREATE_A_PLATFORM_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "CREATE_A_PLATFORM_ERROR", error })
      )
      .startWith({ type: "CREATE_A_PLATFORM_PENDING", payload: action.payload })
  );

export const getAllPlatforms = (action$) =>
  action$.ofType("GET_ALL_PLATFORMS").mergeMap((action) =>
    api
      .getAllPlatforms(action.payload.token)
      .map((response) => ({
        type: "GET_ALL_PLATFORMS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_ALL_PLATFORMS_ABORTED"))
      .defaultIfEmpty({ type: "GET_ALL_PLATFORMS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_ALL_PLATFORMS_ERROR", error })
      )
      .startWith({ type: "GET_ALL_PLATFORMS_PENDING", payload: action.payload })
  );

export const updateAFeatured = (action$) =>
  action$.ofType("UPDATE_A_FEATURED").mergeMap((action) =>
    api
      .updateAFeatured(action.payload.featuredGames, action.payload.token)
      .map((response) => ({
        type: "UPDATE_A_FEATURED_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("UPDATE_A_FEATURED_ABORTED"))
      .defaultIfEmpty({ type: "UPDATE_A_FEATURED_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "UPDATE_A_FEATURED_ERROR", error })
      )
      .startWith({ type: "UPDATE_A_FEATURED_PENDING", payload: action.payload })
  );

export const requestUploadImageUrl = (action$) =>
  action$.ofType("REQUEST_UPLOAD_IMAGE_URL").mergeMap((action) =>
    api
      .requestUploadImageUrl(
        action.payload.slug,
        action.payload.format,
        action.payload.token
      )
      .map((response) => ({
        type: "REQUEST_UPLOAD_IMAGE_URL_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("REQUEST_UPLOAD_IMAGE_URL_ABORTED"))
      .defaultIfEmpty({ type: "REQUEST_UPLOAD_IMAGE_URL_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "REQUEST_UPLOAD_IMAGE_URL_ERROR", error })
      )
      .startWith({
        type: "REQUEST_UPLOAD_IMAGE_URL_PENDING",
        payload: action.payload,
      })
  );

export const getATrackedGames = (action$) =>
  action$.ofType("GET_A_TRACKED_GAMES").mergeMap((action) =>
    api
      .getATrackedGames(action.payload.token)
      .map((response) => ({
        type: "GET_A_TRACKED_GAMES_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_TRACKED_GAMES_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_TRACKED_GAMES_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_TRACKED_GAMES_ERROR", error })
      )
      .startWith({
        type: "GET_A_TRACKED_GAMES_PENDING",
        payload: action.payload,
      })
  );

export const getACampaignList = (action$) =>
  action$.ofType("GET_A_CAMPAIGN_LIST").mergeMap((action) =>
    api
      .getACampaignList(action.payload.token)
      .map((response) => ({
        type: "GET_A_CAMPAIGN_LIST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_CAMPAIGN_LIST_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_CAMPAIGN_LIST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_CAMPAIGN_LIST_ERROR", error })
      )
      .startWith({
        type: "GET_A_CAMPAIGN_LIST_PENDING",
        payload: action.payload,
      })
  );

export const getACampaign = (action$) =>
  action$.ofType("GET_A_CAMPAIGN").mergeMap((action) =>
    api
      .getACampaign(action.payload.campaignId, action.payload.token)
      .map((response) => ({
        type: "GET_A_CAMPAIGN_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_CAMPAIGN_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_CAMPAIGN_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_CAMPAIGN_ERROR", error })
      )
      .startWith({ type: "GET_A_CAMPAIGN_PENDING", payload: action.payload })
  );

export const getACampaignQuestsReport = (action$) =>
  action$.ofType("GET_A_CAMPAIGN_QUESTS_REPORT").mergeMap((action) =>
    api
      .getACampaignQuestsReport(action.payload.campaignId, action.payload.token)
      .map((response) => ({
        type: "GET_A_CAMPAIGN_QUESTS_REPORT_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_CAMPAIGN_QUESTS_REPORT_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_CAMPAIGN_QUESTS_REPORT_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_CAMPAIGN_QUESTS_REPORT_ERROR", error })
      )
      .startWith({
        type: "GET_A_CAMPAIGN_QUESTS_REPORT_PENDING",
        payload: action.payload,
      })
  );

export const getAPaymentList = (action$) =>
  action$.ofType("GET_A_PAYMENT_LIST").mergeMap((action) =>
    api
      .getAPaymentList(
        action.payload.pageNum,
        action.payload.filter,
        action.payload.sorting,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_A_PAYMENT_LIST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_PAYMENT_LIST_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_PAYMENT_LIST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_PAYMENT_LIST_ERROR", error })
      )
      .startWith({
        type: "GET_A_PAYMENT_LIST_PENDING",
        payload: action.payload,
      })
  );

export const getAPaymentListCsv = (action$) =>
  action$.ofType("GET_A_PAYMENT_LIST_CSV").mergeMap((action) =>
    api
      .getAPaymentList(
        action.payload.pageNum,
        action.payload.filter,
        action.payload.sorting,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_A_PAYMENT_LIST_CSV_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_PAYMENT_LIST_CSV_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_PAYMENT_LIST_CSV_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_PAYMENT_LIST_CSV_ERROR", error })
      )
      .startWith({
        type: "GET_A_PAYMENT_LIST_CSV_PENDING",
        payload: action.payload,
      })
  );

export const getPaypalPayments = (action$) =>
  action$.ofType("GET_PAYPAL_PAYMENTS").mergeMap((action) =>
    api
      .getPaypalPayments(action.payload.token)
      .map((response) => ({
        type: "GET_PAYPAL_PAYMENTS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_PAYPAL_PAYMENTS_ABORTED"))
      .defaultIfEmpty({ type: "GET_PAYPAL_PAYMENTS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_PAYPAL_PAYMENTS_ERROR", error })
      )
      .startWith({
        type: "GET_PAYPAL_PAYMENTS_PENDING",
        payload: action.payload,
      })
  );

export const getAQuestList = (action$) =>
  action$.ofType("GET_A_QUEST_LIST").mergeMap((action) =>
    api
      .getAQuestList(action.payload.token)
      .map((response) => ({
        type: "GET_A_QUEST_LIST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_A_QUEST_LIST_ABORTED"))
      .defaultIfEmpty({ type: "GET_A_QUEST_LIST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_A_QUEST_LIST_ERROR", error })
      )
      .startWith({ type: "GET_A_QUEST_LIST_PENDING", payload: action.payload })
  );

export const getInsightsData = (action$) =>
  action$.ofType("GET_INSIGHTS_DATA").mergeMap((action) =>
    api
      .getInsightsData(
        action.payload.filters,
        action.payload.timeData,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_INSIGHTS_DATA_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_INSIGHTS_DATA_ABORTED"))
      .defaultIfEmpty({ type: "GET_INSIGHTS_DATA_CANCELLED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_INSIGHTS_DATA_ERROR", error })
      )
      .startWith({ type: "GET_INSIGHTS_DATA_PENDING", payload: action.payload })
  );

export const getInsightsOptions = (action$) =>
  action$.ofType("GET_INSIGHTS_OPTIONS").mergeMap((action) =>
    api
      .getInsightsOptions(action.payload.token)
      .map((response) => ({
        type: "GET_INSIGHTS_OPTIONS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_INSIGHTS_OPTIONS_ABORTED"))
      .defaultIfEmpty({ type: "GET_INSIGHTS_OPTIONS_CANCELLED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_INSIGHTS_OPTIONS_ERROR", error })
      )
      .startWith({
        type: "GET_INSIGHTS_OPTIONS_PENDING",
        payload: action.payload,
      })
  );

export const createOffPlatformUser = (action$) =>
  action$.ofType("CREATE_OFF_PLATFORM").mergeMap((action) =>
    api
      .createOffPlatformUser(
        action.payload.twitchLogin,
        action.payload.email,
        action.payload.paymentEmail,
        action.payload.first,
        action.payload.last,
        action.payload.token
      )
      .map((response) => ({
        type: "CREATE_OFF_PLATFORM_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("CREATE_OFF_PLATFORM_ABORTED"))
      .defaultIfEmpty({ type: "CREATE_OFF_PLATFORM_CANCELLED" })
      .catch((error) =>
        getErrorResponse({ type: "CREATE_OFF_PLATFORM_ERROR", error })
      )
      .startWith({
        type: "CREATE_OFF_PLATFORM_PENDING",
        payload: action.payload,
      })
  );
