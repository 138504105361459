import React from "react";
import { Parallax } from "../../custom_modules/react-spring/src/renderprops/addons/index";
import {
  brandServices,
  mobilePages,
  desktopPages,
  tabletPages,
} from "./slides/slideIndex";
import "./BrandService.scss";
import anime from "animejs";
import { useTranslation } from "react-i18next";

let timeout = false;
// props:
// pOffset

const ContactUs = (props) => {
  const { t } = useTranslation("brandServices");
  const { navigate = () => null, final = 0, screenHeight = 800 } = props;

  props.handleLoad(final);

  const finalOffset = screenHeight < 780 ? final - 1.8 : final;
  return (
    <div className="fixed-contact-dealy">
      <div>{t("contactUs.title")}</div>
      <button onClick={() => navigate(finalOffset)} className="inner-button">
        {t("contactUs.text")}
      </button>
    </div>
  );
};

class BrandService extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.para = React.createRef();
    this.state = {
      offsetAdjustments: [],
      windowWidth: 500,
      windowHeight: 500,
      debounce: 1000,
    };

    this.handleContactUsLoad = this.handleContactUsLoad.bind(this);
  }

  componentDidMount() {
    window.Intercom("boot", {
      app_id: "ito0ceux",
      hide_default_launcher: false,
    });
    window.addEventListener("resize", this.getDimensions.bind(this));
    this.getDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getDimensions.bind(this));
    anime({
      targets: ".Main__Header__wrapper",
      opacity: 1,
      duration: 0,
      easing: "linear",
    });
    window.Intercom("shutdown");
  }

  navToOffset = (offset) => {
    if (this.para && this.para.current) {
      this.para.current.scrollTo(offset - 0.5);
    }
  };

  getDimensions = () => {
    clearTimeout(timeout);
    timeout = setTimeout(
      () =>
        this.setState({
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        }),
      this.state.debounce
    );
  };

  handleContactUsLoad(scrollLoc) {
    let finalOffset = scrollLoc;
    if (this.state.windowHeight < 780) {
      finalOffset -= 1.8;
    }
    // Nav directly to form if contact-us in URL
    if (window.location.hash && window.location.hash.includes("contact-us")) {
      this.navToOffset(finalOffset);
    }
  }

  render() {
    const currOffset =
      this.parallax && this.parallax.offset ? this.parallax.offset : 0;
    const mobileBreakPoint = this.state.windowWidth < 700;
    const medBreakPoint =
      this.state.windowWidth < 1025 && this.state.windowWidth >= 700;
    const medAndUp = this.state.windowWidth >= 700;
    const deskBreakPoint = this.state.windowWidth >= 1025;
    const maxHeightPointer = 790;
    const tallEnough = this.state.windowHeight >= maxHeightPointer;

    const pageCount = mobileBreakPoint
      ? mobilePages
      : medBreakPoint
      ? tabletPages
      : desktopPages;
    const maxHeightOverride = mobileBreakPoint
      ? 0
      : medBreakPoint
      ? 0
      : deskBreakPoint && tallEnough
      ? maxHeightPointer
      : 0;
    const heightIsGreater = !!(
      this.state.windowHeight >= maxHeightPointer && deskBreakPoint
    );
    const pagesMaxHeightOverride =
      12.5 / (this.state.windowHeight / maxHeightPointer) + 0.6;
    const thePages = heightIsGreater ? pagesMaxHeightOverride : pageCount;
    const firstPageTallestOffset = heightIsGreater
      ? this.state.windowHeight / (2.5 * maxHeightPointer)
      : 0;

    return (
      <div className="brand-service-master-flextainer">
        <div className="brand-service-master">
          {/* { !mobileBreakPoint && <ContactUs navigate={this.navToOffset} final={thePages} /> } */}
          <ContactUs
            navigate={this.navToOffset}
            final={thePages}
            screenHeight={this.state.windowHeight}
            handleLoad={this.handleContactUsLoad.bind(this)}
          />

          <Parallax
            maxHeight={maxHeightOverride}
            onWheel={this.onMouseWheel}
            ref={this.para}
            pages={heightIsGreater ? pagesMaxHeightOverride : thePages}
          >
            {brandServices.map((slide, index) => {
              const {
                Slide,
                mobileOffset,
                tabletOffset,
                desktopOffset,
                mobileFactor,
                tabletFactor,
                desktopFactor,
              } = slide;

              const finalDeskAdjOffset =
                index !== 0
                  ? desktopOffset + firstPageTallestOffset
                  : desktopOffset;
              const offsetAdj = mobileBreakPoint
                ? mobileOffset
                : medBreakPoint
                ? tabletOffset
                : finalDeskAdjOffset;
              const slideFactor = mobileBreakPoint
                ? mobileFactor
                : medBreakPoint
                ? tabletFactor
                : desktopFactor;
              return (
                <Slide
                  firstPageTallestOffset={firstPageTallestOffset}
                  contactNavIndex={pageCount}
                  navToOffset={this.navToOffset.bind(this)}
                  medAndUp={medAndUp}
                  isMobile={mobileBreakPoint}
                  isTablet={medBreakPoint}
                  isDesktop={deskBreakPoint}
                  key={index}
                  pOffset={index}
                  pLax={this.parallax}
                  currOffset={currOffset}
                  layoutFactor={slideFactor}
                  offsetAdjust={offsetAdj}
                  // style={{overflow: 'hidden'}}
                />
              );
            })}
          </Parallax>
        </div>
      </div>
    );
  }
}

export default BrandService;
