import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Dropzone from "react-dropzone";

import { simpleNotification } from "../../../../utils/notifications";
import { uploadToS3 } from "../../../../utils/util";

import Icon from "../../../../global/images/icons/Icon";

import "./AddImage.scss";
import "./CustomInputs.scss";
import { useTranslation } from "react-i18next";

const AddImage = (props) => {
  const {
    brandUserUploadImageUrl,
    error,
    gallery = [],
    header,
    name,
    onChange,
    preSignedUrl: {
      data: { preSignedUrl, url: signedUrl },
    },
    token,
    value,
  } = props;

  const { t } = useTranslation("portal_addImage");

  useEffect(() => {
    if (pendingFile && preSignedUrl) {
      uploadToS3(pendingFile, preSignedUrl, handleLoad);
    }
  }, [preSignedUrl]);

  const [pendingFile, setPendingFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [modal, setModal] = useState({
    [name]: false,
  });

  const handleLoad = (e) => {
    if (e.target.status !== 200) {
      simpleNotification({
        level: "error",
        title: t("error"),
        message: t("uploadErrorMessage"),
      });
    } else {
      onChange(name, signedUrl);
    }

    setUploading(false);
    setPendingFile(null);
  };

  const handleFileUpload = (files, e) => {
    setUploading(true);

    try {
      const file = files?.length ? files[0] : e[0];
      setPendingFile(file);
      const fileNameSplit = file.name.split(".");
      const format = fileNameSplit[fileNameSplit.length - 1];
      brandUserUploadImageUrl(format, token);
    } catch (e) {
      setUploading(false);
      simpleNotification({
        level: "error",
        title: t("error"),
        message: t("uploadErrorMessage2"),
      });
    }
  };

  const GalleryCards = ({ handleImgChange }) => (
    <div className="GalleryCards">
      {gallery.map(({ url }, index) => (
        <img
          alt={url}
          className="GalleryCards__Img"
          key={`${url}-${index}`}
          onClick={() => handleImgChange(url)}
          src={url}
        />
      ))}
    </div>
  );

  const EmptyCard = () => (
    <>
      <div className="EmptyCard">
        <Icon name="upload" color="white" />
        <div className="Assets_Card_Text">{t(name)}</div>
        {!uploading && gallery.length > 0 && (
          <div
            className="Assets_Card_Select"
            onClick={() =>
              setModal((prevState) => ({
                ...prevState,
                [name]: !prevState[name],
              }))
            }
          >
            {t("selectFromProduct")}
          </div>
        )}
        {!uploading && (
          <Dropzone
            className="Assets_Card_Upload"
            onDrop={(e, event) => handleFileUpload(event, e, name)}
            disablePreview={false}
            multiple={false}
            accept={[".jpg", ".png"]}
            inputProps={{ id: "file" }}
          >
            {t("uploadImage")}
          </Dropzone>
        )}
        {!!uploading && <i className="fa fa-spinner fa-spin fa-3x fa-fw" />}
      </div>
      <div
        className={classnames(
          "Assets_Card_Modal",
          modal[name] ? "Active" : "Inactive"
        )}
      >
        <GalleryCards
          handleImgChange={(url) => {
            setModal((prevState) => ({
              ...prevState,
              [name]: false,
            }));
            onChange(name, url);
          }}
        />
      </div>
    </>
  );

  const PopulatedContent = () => (
    <>
      <img className="Assets_Card_Img" src={value} alt={t(name)} />
      <div className="Assets_Card_Text">{t(name)}</div>
      <div className="Assets_Card_Delete" onClick={() => onChange(name, "")}>
        <Icon name="trash" color="white" />
      </div>
    </>
  );

  return (
    <div className="CustomInput">
      {!!header && <div className="CustomInput__Header">{header}</div>}
      <div className="Assets_Card">
        <div
          className={`Assets_Card_Content ${!!value ? "Populated" : "Empty"} ${
            !!error && "Error"
          }`}
        >
          {!!value ? <PopulatedContent /> : <EmptyCard />}
        </div>
      </div>
    </div>
  );
};

export default AddImage;
