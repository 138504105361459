import React, { useState } from "react";
import { CountryRegionData } from "react-country-region-selector";
import CustomDropdown from "../../../components/brand/portal/CustomInputs/CustomDropdown";

import {
  connectUserBrandPaymentOption,
  connectStreamerBankAccount,
  loginToStripe,
} from "../../../utils/apiv2";

import ConfirmModal from "../../../global/components/confirm-modal/ConfirmModal";
import Loading from "../loading/Loading";

import "./AddPaymentOption.scss";
import { useTranslation } from "react-i18next";

const CountrySelector = ({ country, setCountry, countries = [] }) => {
  const { t } = useTranslation("addPaymentOption");
  // const CRegionsWithNull = [['Select A Banking Region', '' , ''], ...CountryRegionData]
  const CRegionsWithNull = [
    [t("selectCountry")],
    ...CountryRegionData.filter((c) => countries.includes(c[1])),
  ];

  return (
    <div className="mb-10">
      <CustomDropdown
        header={t("selectBankingRegion")}
        name="country"
        onChange={(_, value) => {
          setCountry(value);
        }}
        options={CRegionsWithNull.map(([name, code]) => ({
          optionName: name,
          optionValue: code,
        }))}
        value={country}
      />
      {/* <select
        value={country}
        onChange={e => {
          setCountry(e.target.value);
        }}>
        {CRegionsWithNull.map(([name, code]) =>
          <option key={code} value={code}>{name}</option>
        )}
      </select> */}
    </div>
  );
};

export const AddPayButton = ({
  disabled = true,
  streamer = false,
  countries = [],
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState("");

  const { t } = useTranslation("addPaymentOption");

  const callPaymentAPI = async () => {
    setIsLoading(true);
    try {
      if (streamer) {
        await connectStreamerBankAccount(country);
      } else {
        await connectUserBrandPaymentOption();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const title = !streamer ? t("addPaymentMethod") : t("addBankAccount");
  const isStreamerAndNoCountry = streamer && !country;
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {streamer && !disabled && (
        <CountrySelector
          countries={countries}
          country={country}
          setCountry={setCountry}
        />
      )}
      <div
        disabled
        className={`AddPayButton ${
          disabled || isStreamerAndNoCountry ? "disabled" : ""
        }`}
      >
        <div
          disabled={disabled || isStreamerAndNoCountry}
          className="add-payment-option__button"
          onClick={() =>
            disabled || isStreamerAndNoCountry ? null : callPaymentAPI()
          }
        >
          <i className="fa fa-credit-card" />
          <span className="PaymentText">{title}</span>
        </div>
      </div>
    </>
  );
};

export const PaymentOptionSaved = ({
  type = "",
  last4 = "",
  exp_month = "",
  exp_year = "",
  brand = "",
  paymentId = "",
  callback = (a) => console.log(a),
  streamer = false,
  default_currency = "",
  payouts_enabled = false,
  details_submitted = false,
  country = "",
  loading = false,
}) => {
  const { t } = useTranslation("addPaymentOption");

  const theType =
    type === "card"
      ? "fa-credit-card"
      : type === "bank"
      ? "fa-building-columns"
      : "fa-credit-card";
  const expires = `${exp_month}/${exp_year}`;
  const [modal, setOpenModal] = useState(false);
  const brandNullState =
    !streamer &&
    type === "" &&
    last4 === "" &&
    exp_month === "" &&
    exp_year === "" &&
    brand === "";
  if (brandNullState) return null;

  const BankAccountLinked = () => {
    const goodToGo = payouts_enabled && details_submitted;
    return (
      <div className="StripeCluster">
        <div className={`StripeCluster__Status ${goodToGo ? "go" : "stop"}`}>
          <span className={`${goodToGo ? "go" : "stop"}`}>
            {goodToGo ? t("accountReady") : t("needsInformation")}{" "}
          </span>
          <button className="stripe_button" onClick={() => loginToStripe()}>
            {t("logInTo")} <strong>{t("stripe")} </strong>
          </button>
        </div>
        <div className="StripeInfo">
          <div className="ItemMarker">
            <div>{t("country")}:</div>
            <div>{country}</div>
          </div>
          <div className="ItemMarker">
            <div>{t("defaultCurrency")}:</div>
            <div>{default_currency.toLocaleUpperCase()} </div>
          </div>
          <div className="ItemMarker">
            <div>{t("payoutsEnabled")}:</div>
            <div className={`${goodToGo ? "go" : "stop"}`}>
              {`${payouts_enabled}`.toLocaleUpperCase()}{" "}
            </div>
          </div>
          <div className="ItemMarker">
            <div>{t("detailsSubmitted")}:</div>
            <div className={`${goodToGo ? "go" : "stop"}`}>
              {`${details_submitted}`.toLocaleUpperCase()}{" "}
            </div>
          </div>
          {last4 ? (
            <div className="ItemMarker">
              <div>{t("cardNo")}:</div>
              <div>**** **** **** {last4}</div>
            </div>
          ) : null}
        </div>
        <div className="TrashThis" onClick={(a) => setOpenModal(!modal)}>
          <i className={`fa fa-trash`} />
        </div>
      </div>
    );
  };

  const PaymentOptionLinked = () => {
    return (
      <>
        <div className={`PaymentCluster ${!streamer ? "IsBrand" : null}`}>
          <div className="IconType">
            <div>
              <i className={`fa ${theType}`} />
            </div>
          </div>
          <div>
            <div>{brand}</div>
            <div className="PaymentNumber">**** **** **** {last4}</div>
            {expires && <div className="PaymentExpires">exp: {expires}</div>}
          </div>
        </div>
        <div className="TrashThis" onClick={(a) => setOpenModal(!modal)}>
          <i className={`fa fa-trash`} />
        </div>
      </>
    );
  };

  const brandConfirmText = {
    title: t("brandConfirmTitle"),
    content: `${t("brandConfirmContent")} ${last4}?`,
    confirmText: t("yes"),
  };

  const streamerConfirmText = {
    title: t("streamerConfirmTitle"),
    content: t("streamerConfirmContent"),
    confirmText: t("yes"),
  };

  const modalText = streamer ? streamerConfirmText : brandConfirmText;

  return (
    <div className="SavedPaymentOption">
      {streamer ? <BankAccountLinked /> : <PaymentOptionLinked />}
      <ConfirmModal
        open={modal}
        setOpen={setOpenModal}
        callback={() => callback(paymentId)}
        loading={loading}
        {...modalText}
      />
    </div>
  );
};
