/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import classNames from "classnames";
import moment from "moment";
import { CSVLink } from "react-csv";
// import makeAnimated from 'react-select/lib/animated';
import AdminTabs from "../../util/tabs/AdminTabs";
import { withTranslation } from "react-i18next";

// Styles
import "./PaymentList.scss";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// Utils
import { stableSort, getSorting } from "../../../../utils/functions";
import ReadyPayment from "./ReadyPayment";

const TableHeaderCell = withStyles((theme) => ({
  head: {
    color: "#9b9b9b",
    fontWeight: "400",
    letterSpacing: "1.2px",
    fontSize: "14px",
  },
}))(TableCell);

const paypalStatusKey = [
  { label: "Success", value: "S" },
  { label: "Denied", value: "D" },
  { label: "Pending", value: "P" },
  { label: "Refunded", value: "V" },
  { label: "Partially Refunded", value: "F" },
];

class PaymentList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      orderBy: false,
      order: "desc",
      numPage: 1,
      questTab: 1,
      filtersModal: false,
      daysFilter: 1,
      questsOptions: [],
      paypalLoaded: false,
      filters: {
        searchAll: true,
        searchSubject: false,
        searchNoizEmail: false,
        searchNoiz: false,
        // Payout Status (Noiz)
        // paymentStatusAll: true,
        // paymentStatusSent: false,
        // paymentStatusFailed: false,
        // paymentStatusNotSent: false,
        // Payout Status (PayPal)
        paypalStatusAll: true,
        paypalStatusSuccess: false,
        paypalStatusDenied: false,
        paypalStatusPending: false,
        paypalStatusRefunded: false,
        paypalStatusPartiallyRefunded: false,
        searchValue: "",
        // quests: [],
        paymentFromDate: "",
        paymentToDate: "",
      },

      filtersClear: {
        searchAll: true,
        searchSubject: false,
        searchNoizEmail: false,
        searchNoiz: false,
        paypalStatusAll: true,
        paypalStatusSuccess: false,
        paypalStatusDenied: false,
        paypalStatusPending: false,
        paypalStatusRefunded: false,
        paypalStatusPartiallyRefunded: false,
        searchValue: "",
        paymentFromDate: "",
        paymentToDate: "",
      },
      filtersBackup: {},
      sorting: false,
      showReadyPayment: false,
    };
  }

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    const desc = this.state.order === "desc" && this.state.orderBy === property;
    this.setState({
      order: desc ? "asc" : "desc",
      orderBy: property,
    });
  };

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    if (!this.props.admin.payments.isLoading) {
      this.props.getAPaymentList(
        this.state.numPage,
        JSON.stringify(this.state.filters),
        this.state.sorting,
        token
      );
      this.props.getAQuestList(token);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { token } = this.props.auth;
    if (this.state.sorting !== prevState.sorting) {
      this.setState({
        numPage: 1,
      });

      this.props.getAPaymentList(
        1,
        JSON.stringify(this.state.filters),
        this.state.sorting,
        token
      );
      this.props.getAQuestList(token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { token } = this.props.auth;

    if (
      this.props.admin.quests.isLoading === true &&
      nextProps.admin.quests.isLoading === false
    ) {
      const { data: quests } = nextProps.admin.quests;

      if (quests) {
        const questsOptions = [];
        for (let i = 0; i < quests.length; i++) {
          questsOptions.push({ value: quests[i].id, label: quests[i].title });
        }

        this.setState({
          questsOptions,
        });
      }
    }

    // If paypal just refreshed, get payments again
    if (
      this.props.admin.paypalPayments.isLoading === true &&
      nextProps.admin.paypalPayments.isLoading === false
    ) {
      this.setState({
        paypalLoaded: true,
      });
      this.props.getAPaymentList(
        this.state.numPage,
        JSON.stringify(this.state.filters),
        this.state.sorting,
        token
      );
    }
  }

  paginationRight = () => {
    const { token } = this.props.auth;
    const { pagination } = this.props.admin.payments;
    if (pagination.totalPages < this.state.numPage + 1) {
      return;
    }
    if (!this.props.admin.payments.isLoading) {
      this.props.getAPaymentList(
        this.state.numPage + 1,
        JSON.stringify(this.state.filters),
        this.state.sorting,
        token
      );
    }
    this.setState({
      numPage: this.state.numPage + 1,
    });
  };

  paginationLeft = () => {
    const { token } = this.props.auth;
    if (this.state.numPage === 1) {
      return;
    }
    if (!this.props.admin.payments.isLoading) {
      this.props.getAPaymentList(
        this.state.numPage - 1,
        JSON.stringify(this.state.filters),
        this.state.sorting,
        token
      );
    }
    this.setState({
      numPage: this.state.numPage - 1,
    });
  };

  handleSearchValueChange(event) {
    this.setState({
      filters: {
        ...this.state.filters,
        searchValue: event.target.value,
      },
    });
  }

  openFiltersModal = () => {
    this.setState({
      filtersModal: true,
      filtersBackup: { ...this.state.filters },
    });
  };

  closeFiltersModal = () => {
    this.setState({
      filtersModal: false,
      filters: { ...this.state.filtersBackup },
    });
  };

  clearFiltersModal = () => {
    this.setState({
      filters: { ...this.state.filtersClear },
    });
  };

  applyFilters = () => {
    const { token } = this.props.auth;

    this.setState({
      filtersModal: false,
    });

    this.props.getAPaymentList(
      1,
      JSON.stringify(this.state.filters),
      this.state.sorting,
      token
    );
  };

  changeFilterSearch(name) {
    switch (name) {
      case "searchAll":
        this.setState({
          filters: {
            ...this.state.filters,
            searchAll: true,
            searchSubject: false,
            searchNoiz: false,
            searchNoizEmail: false,
          },
        });
        break;
      case "searchSubject":
        this.setState({
          filters: {
            ...this.state.filters,
            searchSubject: !this.state.filters.searchSubject,
            searchAll: false,
          },
        });
        break;
      case "searchNoizEmail":
        this.setState({
          filters: {
            ...this.state.filters,
            searchNoizEmail: !this.state.filters.searchNoizEmail,
            searchAll: false,
          },
        });
        break;
      case "searchNoiz":
        this.setState({
          filters: {
            ...this.state.filters,
            searchNoiz: !this.state.filters.searchNoiz,
            searchAll: false,
          },
        });
        break;
      default:
        this.setState({});
    }
  }

  // changePayoutFilter(name) {
  //   switch (name) {
  //     case 'paymentStatusAll':
  //       this.setState({
  //         filters: {
  //           ...this.state.filters,
  //           paymentStatusAll: !this.state.filters.paymentStatusAll,
  //           paymentStatusSent: false,
  //           paymentStatusFailed: false,
  //           paymentStatusNotSent: false,
  //         },
  //       });
  //       break;
  //     case 'paymentStatusSent':
  //       this.setState({
  //         filters: {
  //           ...this.state.filters,
  //           paymentStatusSent: !this.state.filters.paymentStatusSent,
  //           paymentStatusAll: false,
  //         },
  //       });
  //       break;
  //     case 'paymentStatusFailed':
  //       this.setState({
  //         filters: {
  //           ...this.state.filters,
  //           paymentStatusFailed: !this.state.filters.paymentStatusFailed,
  //           paymentStatusAll: false,
  //         },
  //       });
  //       break;
  //     case 'paymentStatusNotSent':
  //       this.setState({
  //         filters: {
  //           ...this.state.filters,
  //           paymentStatusNotSent: !this.state.filters.paymentStatusNotSent,
  //           paymentStatusAll: false,
  //         },
  //       });
  //       break;
  //     default:
  //       this.setState({});
  //   }
  // }

  changePayPalFilter(name) {
    switch (name) {
      case "paypalStatusAll":
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusAll: !this.state.filters.paypalStatusAll,
            paypalStatusSuccess: false,
            paypalStatusDenied: false,
            paypalStatusPending: false,
            paypalStatusRefunded: false,
            paypalStatusPartiallyRefunded: false,
          },
        });
        break;
      case "paypalStatusSuccess":
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusSuccess: !this.state.filters.paypalStatusSuccess,
            paypalStatusAll: false,
          },
        });
        break;
      case "paypalStatusDenied":
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusDenied: !this.state.filters.paypalStatusDenied,
            paypalStatusAll: false,
          },
        });
        break;
      case "paypalStatusPending":
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusPending: !this.state.filters.paypalStatusPending,
            paypalStatusAll: false,
          },
        });
        break;
      case "paypalStatusRefunded":
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusRefunded: !this.state.filters.paypalStatusRefunded,
            paypalStatusAll: false,
          },
        });
        break;
      case "paypalStatusPartiallyRefunded":
        this.setState({
          filters: {
            ...this.state.filters,
            paypalStatusPartiallyRefunded:
              !this.state.filters.paypalStatusPartiallyRefunded,
            paypalStatusAll: false,
          },
        });
        break;
      default:
        this.setState({});
    }
  }

  setPaymentFromDate = (event) => {
    const {
      target: { value },
    } = event;
    if (value) {
      const paymentFromDate = new Date(value).toISOString().slice(0, 10);
      this.setState({
        filters: {
          ...this.state.filters,
          paymentFromDate,
        },
      });
    } else if (value === "") {
      this.setState({
        filters: {
          ...this.state.filters,
          paymentFromDate: null,
        },
      });
    }
  };

  setPaymentToDate = (event) => {
    const {
      target: { value },
    } = event;
    if (value) {
      const paymentToDate = new Date(value).toISOString().slice(0, 10);
      this.setState({
        filters: {
          ...this.state.filters,
          paymentToDate,
        },
      });
    } else if (value === "") {
      this.setState({
        filters: {
          ...this.state.filters,
          paymentToDate: null,
        },
      });
    }
  };

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    const desc = this.state.order === "desc" && this.state.orderBy === property;
    this.setState({
      order: desc ? "asc" : "desc",
      orderBy: property,
      sorting: property + (desc ? "Asc" : "Desc"),
    });
  };

  handleQuestFilterChange(selectedOptions) {
    this.setState({
      filters: {
        ...this.state.filters,
        quests: selectedOptions,
      },
    });
  }

  renderFiltersModal() {
    const { t } = this.props;
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button
            onClick={this.closeFiltersModal.bind(this)}
            className="Modal__layer"
            aria-hidden
          />
          <section className="Modal__wrapper Filters">
            <div className="Filters__title">{t("selectFilters")}</div>

            <div className="Filters__title left">{t("reportUser")}:</div>

            <div className="Filters__section">
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  className="Filters__field__label"
                  style={{ marginRight: "10px" }}
                >
                  {t("searchBy")}:
                </div>
                <div
                  className="Filters__fieldGroup"
                  style={{ marginTop: "0px", marginBottom: "0px" }}
                >
                  <button
                    onClick={this.changeFilterSearch.bind(
                      this,
                      "searchAll",
                      false
                    )}
                    className={classNames("", {
                      active: this.state.filters.searchAll,
                    })}
                  >
                    {t("all")}
                  </button>
                  <button
                    onClick={this.changeFilterSearch.bind(
                      this,
                      "searchSubject",
                      false
                    )}
                    className={classNames("", {
                      active: this.state.filters.searchSubject,
                    })}
                  >
                    {t("subject")}
                  </button>
                  <button
                    onClick={this.changeFilterSearch.bind(
                      this,
                      "searchNoizEmail"
                    )}
                    className={classNames("", {
                      active: this.state.filters.searchNoizEmail,
                    })}
                  >
                    {t("noizEmail")}
                  </button>
                  <button
                    onClick={this.changeFilterSearch.bind(this, "searchNoiz")}
                    className={classNames("", {
                      active: this.state.filters.searchNoiz,
                    })}
                  >
                    {t("noizUsername")}
                  </button>
                </div>
              </div>
              <div className="Filters__fieldGroup" style={{ marginTop: "0px" }}>
                <input
                  type="text"
                  value={this.state.filters.searchValue}
                  onChange={this.handleSearchValueChange.bind(this)}
                  style={{ display: "flex" }}
                />
              </div>
            </div>

            <div className="Filters__title left">{t("filterOptions")}:</div>

            <div className="Filters__section">
              {/* <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div className="Filters__field__label" style={{ marginRight: '10px' }}>
                  Payout Status:
                </div>
                <div className="Filters__fieldGroup" style={{ marginTop: '0px', marginBottom: '0px' }}>
                  <button
                    onClick={this.changePayoutFilter.bind(this, 'paymentStatusAll')}
                    className={classNames('', { active: this.state.filters.paymentStatusAll })}
                  >
                    All
                  </button>
                  <button
                    onClick={this.changePayoutFilter.bind(this, 'paymentStatusSent')}
                    className={classNames('', { active: this.state.filters.paymentStatusSent })}
                  >
                    Sent
                  </button>
                  <button
                    onClick={this.changePayoutFilter.bind(this, 'paymentStatusFailed')}
                    className={classNames('', { active: this.state.filters.paymentStatusFailed })}
                  >
                    Failed
                  </button>
                  <button
                    onClick={this.changePayoutFilter.bind(this, 'paymentStatusNotSent')}
                    className={classNames('', { active: this.state.filters.paymentStatusNotSent })}
                  >
                    Not Sent
                  </button>
                </div>
              </div> */}
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  className="Filters__field__label"
                  style={{ marginRight: "10px", marginTop: "20px" }}
                >
                  {t("payPalStatus")}:
                </div>
                <div
                  className="Filters__fieldGroup"
                  style={{ marginTop: "0px", marginBottom: "0px" }}
                >
                  <button
                    onClick={this.changePayPalFilter.bind(
                      this,
                      "paypalStatusAll"
                    )}
                    className={classNames("", {
                      active: this.state.filters.paypalStatusAll,
                    })}
                  >
                    {t("all")}
                  </button>
                  <button
                    onClick={this.changePayPalFilter.bind(
                      this,
                      "paypalStatusSuccess"
                    )}
                    className={classNames("", {
                      active: this.state.filters.paypalStatusSuccess,
                    })}
                  >
                    {t("success")}
                  </button>
                  <button
                    onClick={this.changePayPalFilter.bind(
                      this,
                      "paypalStatusDenied"
                    )}
                    className={classNames("", {
                      active: this.state.filters.paypalStatusDenied,
                    })}
                  >
                    {t("denied")}
                  </button>
                  <button
                    onClick={this.changePayPalFilter.bind(
                      this,
                      "paypalStatusPending"
                    )}
                    className={classNames("", {
                      active: this.state.filters.paypalStatusPending,
                    })}
                  >
                    {t("pending")}
                  </button>
                  <button
                    onClick={this.changePayPalFilter.bind(
                      this,
                      "paypalStatusRefunded"
                    )}
                    className={classNames("", {
                      active: this.state.filters.paypalStatusRefunded,
                    })}
                  >
                    {t("refunded")}
                  </button>
                  <button
                    onClick={this.changePayPalFilter.bind(
                      this,
                      "paypalStatusPartiallyRefunded"
                    )}
                    className={classNames("", {
                      active: this.state.filters.paypalStatusPartiallyRefunded,
                    })}
                  >
                    {t("partiallyRefunded")}
                  </button>
                </div>
              </div>
            </div>

            <div
              className="Filters__section"
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="Filters__field__label"
                style={{
                  marginRight: "10px",
                  marginTop: "20px",
                  float: "left",
                }}
              >
                {t("transactionDate")}:
              </div>
              <div className="date__section">
                {t("fromDate")}
                <input
                  type="date"
                  value={this.state.filters.paymentFromDate}
                  onChange={this.setPaymentFromDate.bind(this)}
                  className="date__picker"
                />
              </div>
              <span style={{ margin: "0px 10px" }}> - </span>
              <div className="date__section">
                {t("toDate")}
                <input
                  type="date"
                  value={this.state.filters.paymentToDate}
                  onChange={this.setPaymentToDate.bind(this)}
                  className="date__picker"
                />
              </div>
            </div>
            <div className="Filters__section" style={{ marginTop: "20px" }}>
              {/* }  <div className="Filters__fieldGroup">
                <div className="Filters__field__label">
                  Quests:
                </div>
                <Select
                  value={this.state.filters.quests}
                  closeMenuOnSelect={false}
                  components={makeAnimated()}
                  isMulti
                  onChange={(...args) => this.handleQuestFilterChange(...args)}
                  options={this.state.questsOptions}
                  styles={colourStyles}
                />
              </div> */}
            </div>

            <div className="Filters__close" style={{ paddingTop: "20px" }}>
              <button onClick={this.closeFiltersModal.bind(this)}>
                <i className="fa fa-times" /> {t("cancel")}
              </button>
              <button onClick={this.clearFiltersModal.bind(this)}>
                <i className="fa fa-eraser" /> {t("clear")}
              </button>
              <button onClick={this.applyFilters.bind(this)}>
                <i className="fa fa-check" /> {t("apply")}
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  renderFiltersString = (forFileName) => {
    const { filters } = this.state;
    const filterArray = [];
    const { t } = this.props;
    if (filters.searchAll && filters.searchValue) {
      filterArray.push(`${t("subject")}: ${filters.searchValue}`);
      filterArray.push(`${t("noizEmail")}: ${filters.searchValue}`);
      filterArray.push(`${t("noizUsername")}: ${filters.searchValue}`);
    } else if (filters.searchsearchSubject) {
      filterArray.push(`${t("subject")}: ${filters.searchValue}`);
    } else if (filters.searchNoizEmail) {
      filterArray.push(`${t("noizEmail")}: ${filters.searchValue}`);
    } else if (filters.searchNoiz) {
      filterArray.push(`${t("noizUsername")}: ${filters.searchValue}`);
    }

    // if (filters.paymentStatusAll) {
    //   filterArray.push('Payout Status: All');
    // } else {
    //   let paymentFilters = [];
    //   if (filters.paymentStatusSent) {
    //     paymentFilters.push('Sent');
    //   }
    //   if (filters.paymentStatusFailed) {
    //     paymentFilters.push('Failed');
    //   }
    //   if (filters.paymentStatusNotSent) {
    //     paymentFilters.push('Not Sent');
    //   }
    //   if (paymentFilters.length) {
    //     filterArray.push('Payment Status: ' + paymentFilters.join(", "));
    //   }
    // }

    if (filters.paypalStatusAll) {
      filterArray.push(t("paypalStatusAll"));
    } else {
      const paypalFilters = [];
      if (filters.paypalStatusPending) {
        paypalFilters.push(t("pending"));
      }
      if (filters.paypalStatusSuccess) {
        paypalFilters.push(t("success"));
      }
      if (filters.paypalStatusDenied) {
        paypalFilters.push(t("denied"));
      }
      if (filters.paypalStatusRefunded) {
        paypalFilters.push(t("refunded"));
      }
      if (filters.paypalStatusPartiallyRefunded) {
        paypalFilters.push(t("partiallyRefunded"));
      }

      if (paypalFilters.length) {
        filterArray.push(`${t("payPalStatus")}: ${paypalFilters.join(", ")}`);
      }
    }

    // if (filters.quests) {
    //   filterArray.push('Quests: ' + filters.quests.map((quest) => quest.label).join(", "));
    // }

    if (filters.paymentFromDate && filters.paymentToDate) {
      filterArray.push(
        `${t("transactionDate")}: ${moment(filters.paymentFromDate).format(
          "MMMM DD, YYYY"
        )} - ${moment(filters.paymentToDate).format("MMMM DD, YYYY")}`
      );
    } else if (filters.paymentFromDate) {
      filterArray.push(
        `${t("transactionDate")}: ${t("after")} ${moment(
          filters.paymentFromDate
        ).format("MMMM DD, YYYY")}`
      );
    } else if (filters.paymentToDate) {
      filterArray.push(
        `${t("transactionDate")}: ${t("before")} ${moment(
          filters.paymentToDate
        ).format("MMMM DD, YYYY")}`
      );
    }

    if (filterArray.length === 0) {
      return "None";
    }

    // Determine delimiter
    if (forFileName) {
      const filterStrings = filterArray.map((filter) =>
        filter.replace(/[^a-z0-9+]+/gi, "-")
      );
      return filterStrings.join("_");
    }
    return filterArray.join(", ");
  };

  // Sort & define payment rows; return elements to display
  getPaymentRows = (payments) => {
    if (payments.length) {
      const sortedPayments = stableSort(
        payments,
        getSorting(this.state.order, this.state.orderBy)
      );
      return sortedPayments.map((payment) => (
        <TableRow hover tabIndex={-1} key={payment.id}>
          <TableCell align="center">
            {payment.username ? payment.username : "-"}
          </TableCell>
          <TableCell align="center">
            {payment.userEmail ? payment.userEmail : "-"}
          </TableCell>
          <TableCell align="center">
            {payment.subject ? payment.subject : "-"}
          </TableCell>
          {/* <TableCell align="center">{payment.questTitle ? payment.questTitle : '-'}</TableCell> */}
          <TableCell align="center">
            {payment.amount ? `$${Math.abs(payment.amount).toFixed(2)}` : "-"}
          </TableCell>
          <TableCell align="center">
            {payment.processingFee
              ? `$${Math.abs(payment.processingFee).toFixed(2)}`
              : "-"}
          </TableCell>
          {/* <TableCell align="center">{payment.expectedPayoutDate ? moment(payment.expectedPayoutDate).format('MMMM DD, YYYY') : '-'}</TableCell> */}
          {/* <TableCell align="center">{payment.paymentStatus ? payment.paymentStatus : '-'}</TableCell> */}
          <TableCell align="center">
            {payment.paypalStatus
              ? paypalStatusKey.find((s) => s.value === payment.paypalStatus)
                  .label
              : "-"}
          </TableCell>
          <TableCell align="center">
            {payment.transactionDate
              ? moment(payment.transactionDate).format("MMMM DD, YYYY")
              : "-"}
          </TableCell>
        </TableRow>
      ));
    }
  };

  // Define table headers; return array of headers
  getTableHeaders = () => {
    const { t } = this.props;
    const tableHeaders = [
      {
        id: "username",
        numeric: false,
        disablePadding: true,
        label: t("noizUsername"),
      },
      {
        id: "userEmail",
        numeric: false,
        disablePadding: true,
        label: t("userEmail"),
      },
      {
        id: "subject",
        numeric: false,
        disablePadding: true,
        label: t("subject"),
      },
      // { id: 'questTitle', numeric: true, disablePadding: true, label: 'Quest' },
      {
        id: "amount",
        numeric: true,
        disablePadding: true,
        label: t("amount"),
      },
      {
        id: "processingFee",
        numeric: true,
        disablePadding: true,
        label: t("processingFee"),
      },
      // { id: 'expectedPayoutDate', numeric: true, disablePadding: true, label: 'Expected Payout Date' },
      // { id: 'paymentStatus', numeric: false, disablePadding: true, label: 'Payment Status' },
      {
        id: "paypalStatus",
        numeric: false,
        disablePadding: true,
        label: t("payPalStatus"),
      },
      {
        id: "transactionDate",
        numeric: false,
        disablePadding: true,
        label: t("transactionDate"),
      },
    ];
    return tableHeaders;
  };

  // Define data for csv download; return object with headers and data
  getCSV = (payments) => {
    const data = [];
    const { t } = this.props;
    for (let i = 0; i < payments.length; i += 1) {
      const payment = payments[i];

      // Standard CSV rows
      const dataRow = {
        username: payment.username,
        userEmail: payment.userEmail,
        paypalEmail: payment.paypalEmail,
        subject: payment.subject,
        amount: payment.amount ? Math.abs(payment.amount).toFixed(2) : "-",
        processingFee: payment.processingFee
          ? Math.abs(payment.processingFee).toFixed(2)
          : "-",
        // questTitle: payment.questTitle,
        // expectedPayoutDate: payment.expectedPayoutDate ? moment(payment.expectedPayoutDate).format('MMMM DD, YYYY') : '',
        // paymentStatus: payment.paymentStatus,
        paypalStatus: payment.paypalStatus
          ? paypalStatusKey.find((s) => s.value === payment.paypalStatus).label
          : "",
        transactionDate: payment.transactionDate
          ? moment(payment.transactionDate).format("MMMM DD, YYYY")
          : "",
      };

      data.push(dataRow);
    }

    // Standard CSV headers
    const headers = [
      { label: t("noizUserName"), key: "username" },
      { label: t("userEmail"), key: "userEmail" },
      { label: t("paypalEmail"), key: "paypalEmail" },
      { label: t("subject"), key: "subject" },
      { label: t("amount"), key: "amount" },
      { label: t("processingFee"), key: "processingFee" },
      // { label: 'Quest Name', key: 'questTitle' },
      // { label: 'Expected Payout Date', key: 'expectedPayoutDate' },
      // { label: 'Payment Status', key: 'paymentStatus' },
      { label: t("payPalStatus"), key: "paypalStatus" },
      { label: t("transactionDate"), key: "transactionDate" },
    ];

    return { headers, data };
  };

  // Call api to pull down paypal payments.
  refreshPayments() {
    const { token } = this.props.auth;
    this.props.getPaypalPayments(token);
    this.setState({
      paypalLoading: true,
    });
  }

  openDownloadModal = () => {
    const { token, user } = this.props.auth;
    const isAdmin = user.role === "admin";
    const isHelper = user.role === "helper";

    if (isAdmin || isHelper) {
      this.props.getAPaymentListCsv(
        0,
        JSON.stringify(this.state.filters),
        this.state.sorting,
        token
      );
      this.setState({
        downloadModal: true,
      });
    }
  };

  closeDownloadModal = () => {
    this.setState({
      downloadModal: false,
    });
  };

  renderDownloadModal() {
    const { isLoading, data: payments } = this.props.admin.paymentsCsv;
    const csv = this.getCSV(payments);
    const { t } = this.props;
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button
            onClick={this.closeDownloadModal.bind(this)}
            className="Modal__layer"
            aria-hidden
          />
          <section className="Modal__wrapper Modal__download">
            {isLoading && (
              <div className="Modal__download--spinner">
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
              </div>
            )}
            {!isLoading && (
              <div className="Modal__download--content">
                <div className="Modal__download--header">{t("download")}</div>
                <div className="Modal__download--body">
                  <div className="Modal__download--row">
                    <CSVLink
                      data={csv.data}
                      headers={csv.headers}
                      filename={`Payments-${this.renderFiltersString(
                        true
                      )}.csv`}
                    >
                      <i className="fa fa-download" />
                      &nbsp;{`${t("searchResults")} (${payments.length})`}
                    </CSVLink>
                  </div>
                </div>
                <div className="Modal__download--footer">
                  <button
                    onClick={this.closeDownloadModal.bind(this)}
                    className="Modal__download--cta"
                  >
                    <i className="fa fa-times" />
                    &nbsp;{t("close")}
                  </button>
                </div>
              </div>
            )}
          </section>
        </section>
      </section>
    );
  }

  getReadyPayment() {
    this.setState({
      showReadyPayment: !this.state.showReadyPayment,
    });
  }

  render() {
    const { data: payments, isLoading, pagination } = this.props.admin.payments;

    const { isLoading: paypalLoading, errors: paypalError } =
      this.props.admin.paypalPayments;

    const { paypalLoaded, showReadyPayment } = this.state;
    const { role } = this.props.auth.user;
    const isAdmin = role === "admin";
    const isHelper = role === "helper";

    const createSortHandler = (property) => (event) => {
      this.handleRequestSort(event, property);
    };
    const { t } = this.props;

    const readyToPayButton = () => {
      return (
        <button onClick={this.getReadyPayment.bind(this)}>
          {t("readyToPay")}
          {showReadyPayment ? (
            <i style={{ marginLeft: "10px" }} className="fa fa-check green" />
          ) : paypalError.hasError ? (
            <i style={{ marginLeft: "10px" }} className="fa fa-times red" />
          ) : null}
        </button>
      );
    };

    const readyPaymentRender = () => {
      return (
        <ReadyPayment
          {...this.props}
          readyToPayButton={readyToPayButton}
        ></ReadyPayment>
      );
    };
    const paymentListRender = () => {
      return (
        <>
          <h2 className="AUserList__title">
            {" "}
            {t("payments")}:{" "}
            <span>
              {pagination.totalPayments} ({t("page")} {pagination.pageNumber}{" "}
              {t("of")} {pagination.totalPages})
            </span>
          </h2>
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <div className="ToggleTable__header__left">
                {(isAdmin || isHelper) && (
                  <button
                    className={classNames("")}
                    onClick={this.openDownloadModal.bind(this)}
                  >
                    {t("download")}
                  </button>
                )}

                <button onClick={this.refreshPayments.bind(this)}>
                  {t("refreshPayments")}
                  {!paypalLoading && !paypalError.hasError && paypalLoaded ? (
                    <i
                      style={{ marginLeft: "10px" }}
                      className="fa fa-check green"
                    />
                  ) : paypalError.hasError ? (
                    <i
                      style={{ marginLeft: "10px" }}
                      className="fa fa-times red"
                    />
                  ) : null}
                </button>

                {readyToPayButton()}
              </div>
              <div className="ToggleTable__header__right">
                <button
                  className={classNames("")}
                  onClick={this.openFiltersModal.bind(this)}
                >
                  {t("filters")}
                </button>
                <button
                  className="regular"
                  onClick={this.paginationLeft.bind(this)}
                >
                  <i className="fa fa-arrow-left" />
                </button>
                <button
                  className="regular"
                  onClick={this.paginationRight.bind(this)}
                >
                  <i className="fa fa-arrow-right" />
                </button>
              </div>
            </section>

            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">
                    {t("filters")}: {this.renderFiltersString(false)}
                  </div>
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      {this.getTableHeaders().map((headCell) => (
                        <TableHeaderCell
                          key={headCell.id}
                          align="center"
                          padding={headCell.disablePadding ? "none" : "default"}
                          sortDirection="asc"
                        >
                          <TableSortLabel
                            active={this.state.orderBy === headCell.id}
                            direction={this.state.order}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={ArrowDropDownIcon}
                            style={{ marginLeft: "30px" }}
                          >
                            {headCell.label}
                            {this.state.orderBy === headCell.id ? (
                              <span style={{ display: "none" }}>
                                {this.state.order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableHeaderCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isLoading &&
                      !paypalLoading &&
                      payments &&
                      this.getPaymentRows(payments)}
                  </TableBody>
                </Table>
                <div className="NoPayments">
                  {(isLoading || paypalLoading) && (
                    <div className="PaymentList__loading">
                      <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                  )}
                  {!isLoading && !payments.length && (
                    <span>{t("noPaymentsFound")}</span>
                  )}
                </div>
              </section>
            </section>
            <section className="ToggleTable__footer">
              <section className="ToggleTable__footer__left">
                <button onClick={this.paginationLeft.bind(this)}>
                  <i className="fa fa-caret-left" />
                </button>
              </section>
              <section className="ToggleTable__footer__right">
                <button onClick={this.paginationRight.bind(this)}>
                  <i className="fa fa-caret-right" />
                </button>
              </section>
            </section>
          </section>
        </>
      );
    };
    return (
      <section className="AUserList">
        <section className="AUserList__wrapper">
          <AdminTabs {...this.props} />
          {showReadyPayment ? readyPaymentRender() : paymentListRender()}
        </section>
        {this.state.filtersModal && this.renderFiltersModal()}
        {this.state.downloadModal && this.renderDownloadModal()}
      </section>
    );
  }
}

PaymentList.propTypes = {};

export default withTranslation("paymentList")(PaymentList);
