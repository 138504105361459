import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import './BrandServiceSlides.scss';
import globe from '../assetsV2/global-platform-background.jpg';
import { fadeUp, fadeIn } from './animations/allTransitions';
import CountUp from './animations/CountUp';
import PulseCircle from './animations/PulseCircle';
import { useTranslation } from 'react-i18next';
// Little helpers ...

const DNoizNetwork = (props) => {
  const { t } = useTranslation("brandServices");
  const {
    pOffset, layoutFactor = 1, offsetAdjust = 0, isMobile = false, isTablet = false,
  } = props;
  const ref = React.createRef();

  const [holdIt, setHoldIt] = React.useState(false);

  const onScreen = useOnScreen(ref, '100px', 0.5);
  useEffect(() => {
    if (onScreen && !holdIt) {
      fadeUp('brand-d-writing-pod');
      fadeIn('brand-d-noiz-title');
      setTimeout(setHoldIt(true), 1400);
    }
  },[onScreen, holdIt]);
  const desktopMap = [{ l: 240, t: 567 }, { l: 786, t: 1023 }, { l: 1667, t: 469 }];
  const tabletMap = [{ l: 420, t: 570 }, { l: 817, t: 409 }, { l: 746, t: 1303 }, { l: 1167, t: 61 }];
  // const tabletMap = [{ l: 180, t: 567 }, { l: 726, t: 1023 }, { l: 1167, t: 469 }];
  const mobileMap = [{ l: 482, t: 449 }, { l: 235, t: 336 }, { l: 480, t: 806 }];
  const coordMaps = isMobile ? mobileMap : isTablet ? tabletMap : desktopMap;

  const showIt = onScreen || holdIt;

  return (
    <>
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        style={{ backgroundImage: `url(${globe})` }}
        className="global-platform-background"
      >
        {/* <ParallaxLayer offset={pOffset} className="global-platform-background"> */}
        {/* <img src={globe} /> */}
        {showIt &&
          coordMaps.map((o, i) => (
            <PulseCircle key={i} startLeft={o.l} startTop={o.t} zLevel={i} />
          ))}
      </ParallaxLayer>

      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        speed={0}
        className="brand-d-noiz-vcontainer"
      >
        {showIt && (
          <div className="brand-d-noiz-title fade-up-prep">
            {t("brandDNoizTitle")}
          </div>
        )}
        <div ref={ref} />
        <div className="brand-d-noiz-horiz-container">
          {showIt && (
            <div className="brand-d-writing-pod">
              <div className="brand-d-subtitle">
                {t("liveStreamReach")}
              </div>
              <div className="brand-d-purple-counter">
                <CountUp
                  staticText={t("million")}
                  countNum={132}
                  textPlacement="right"
                />
              </div>
            </div>
          )}

          {showIt && (
            <div className="brand-d-writing-pod">
              <div className="brand-d-subtitle">
                {t("influencers")}
              </div>
              <div className="brand-d-purple-counter">
                <CountUp
                  countNum={30000}
                  textPlacement="right"
                  addPlus={false}
                />
              </div>
            </div>
          )}

          {showIt && (
            <div className="brand-d-writing-pod">
              <div className="brand-d-subtitle">
                {t("countries")}
              </div>
              <div className="brand-d-purple-counter">
                <CountUp countNum={150} textPlacement="right" />
              </div>
            </div>
          )}

          {showIt && (
            <div className="brand-d-writing-pod">
              <div className="brand-d-subtitle">
                {t("socialReach")}
              </div>
              <div className="brand-d-purple-counter">
                <CountUp
                  staticText={t("million")}
                  countNum={60}
                  textPlacement="right"
                />
              </div>
            </div>
          )}
        </div>
      </ParallaxLayer>
    </>
  );
};


export default DNoizNetwork;

