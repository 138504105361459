/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router';
import './Careers.scss';
import IconParticles from '../icon-particles/IconParticles';
import { withTranslation } from "react-i18next";

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
    };
  }

  UNSAFE_componentWillMount() {
  }

  componentDidMount() {
  }


  render() {
    const { t } = this.props;
    return (
      <section className="Careers">
        <section className="Careers__container">
          <section className="Hero">
            {/* <section className="Hero__particles" /> */}
            <IconParticles />
            <section className="Hero__content">
              <h2 className="Hero__title">{t("pageName")}</h2>
            </section>
          </section>

          <section className="MainCareers">
            <section className="MainCareers__content">
              <section className="CareersListing">
                <section className="PostingGroup">
                  <section className="PostingCategory">
                    <h3 className="PostingCategory__title">
                      {t("openPositions")}
                    </h3>
                    <p
                      style={{ display: "none" }}
                      className="PostingCategory__label"
                    >
                      {t("advertising")}
                    </p>
                    <span className="PostingCategory__spacer" />
                  </section>
                  <section className="PostingItem">
                    <Link
                      className="PostingItem__wrapper"
                      to="/careers/brand-sales-manager"
                      href="/careers/brand-sales-manager"
                    >
                      <Link
                        className="PostingItem__apply-btn"
                        to="/careers/brand-sales-manager"
                        href="/careers/brand-sales-manager"
                      >
                        {t("apply")}
                      </Link>
                      <h4 className="PostingItem__title">
                        {t("module_1.title")}
                      </h4>
                      <section className="PostingItem__categories">
                        <span className="PostingItem__categories--location">
                          {t("module_1.describe")}
                        </span>
                      </section>
                    </Link>
                  </section>

                  <section className="PostingItem">
                    <Link
                      className="PostingItem__wrapper"
                      to="/careers/ux-ev"
                      href="/careers/ux-ev"
                    >
                      <Link
                        className="PostingItem__apply-btn"
                        to="/careers/ux-ev"
                        href="/careers/ux-ev"
                      >
                        {t("apply")}
                      </Link>
                      <h4 className="PostingItem__title">
                        {t("module_2.title")}
                      </h4>
                      <section className="PostingItem__categories">
                        <span className="PostingItem__categories--location">
                          {t("module_2.describe")}
                        </span>
                      </section>
                    </Link>
                  </section>

                  <section className="PostingItem">
                    <Link
                      className="PostingItem__wrapper"
                      to="/careers/biz-dev-director"
                      href="/careers/biz-dev-director"
                    >
                      <Link
                        className="PostingItem__apply-btn"
                        to="/careers/biz-dev-director"
                        href="/careers/biz-dev-director"
                      >
                        {t("apply")}
                      </Link>
                      <h4 className="PostingItem__title">
                        {t("module_3.title")}
                      </h4>
                      <section className="PostingItem__categories">
                        <span className="PostingItem__categories--location">
                          {t("module_3.describe")}
                        </span>
                      </section>
                    </Link>
                  </section>

                  <section className="PostingItem">
                    <Link
                      className="PostingItem__wrapper"
                      to="/careers/campaign-manager"
                      href="/careers/campaign-manager"
                    >
                      <Link
                        className="PostingItem__apply-btn"
                        to="/careers/campaign-manager"
                        href="/careers/campaign-manager"
                      >
                        {t("apply")}
                      </Link>
                      <h4 className="PostingItem__title">
                        {t("module_4.title")}
                      </h4>
                      <section className="PostingItem__categories">
                        <span className="PostingItem__categories--location">
                          {t("module_4.describe")}
                        </span>
                      </section>
                    </Link>
                  </section>

                  <section className="PostingItem">
                    <Link
                      className="PostingItem__wrapper"
                      to="/careers/community-manager"
                      href="/careers/community-manager"
                    >
                      <Link
                        className="PostingItem__apply-btn"
                        to="/careers/community-manager"
                        href="/careers/community-manager"
                      >
                        {t("apply")}
                      </Link>
                      <h4 className="PostingItem__title">
                        {t("module_5.title")}
                      </h4>
                      <section className="PostingItem__categories">
                        <span className="PostingItem__categories--location">
                          {t("module_5.describe")}
                        </span>
                      </section>
                    </Link>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation("careers")(Careers);
