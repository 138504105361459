import React from "react";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { Link } from "react-router";
import YouTube from "react-youtube";
import { withTranslation, Trans } from "react-i18next";
import $ from "jquery";
import { TimelineMax, Power3 } from "gsap";
import ScrollMagic from "scrollmagic";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"; // Ignore the error

import { simpleNotification } from "../../utils/notifications";
import { isAuthenticated } from "../../utils/authChecker";
import "./Home.scss";
import brandList from "../brand-service/assetsV2/brandsIndex";

import scrollMouse from "./assets/v4/scroll.svg";
import moneyBag from "./assets/v4/money-bag.svg";
import gameController from "./assets/v4/game-controller.svg";
import community from "./assets/v4/community.svg";
import playBtn from "./assets/v4/play-button.png";
import playerPreview from "./assets/v4/player-preview.jpg";
import myQuests from "./assets/v4/my-quests.png";
import twitchIcon from "./assets/v4/twitch.svg";
import youtubeIcon from "./assets/v4/yt_logo_mono_dark.png";
import supportMonitor from "./assets/v4/support-monitor.png";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      playingVideo: false,
    };
    this.myRef = null;
    this.player = null;
  }

  UNSAFE_componentWillMount() {
    if (this.props.router.location.query.ref) {
      localStorage.setItem(
        "referredSignUp",
        this.props.router.location.query.ref
      );
      this.props.router.push("/");
    }
    if (
      this.props.router.location.pathname === "/login" &&
      !isEmpty(this.props.router.location.query.code) &&
      !isEmpty(this.props.router.location.query.scope)
    ) {
      this.props.loginWithTwitchOAuthCode(
        this.props.router.location.query.code,
        this.props.router.location.query.scope
      );
    }

    if (isAuthenticated()) {
      const { user } = this.props.auth;
      if (user.role === "brand") {
        this.props.router.replace("/portal");
      } else {
        this.props.router.replace("/quests");
      }
    }
  }

  componentDidMount() {
    const controller = new ScrollMagic.Controller();
    const heroTimeLine = new TimelineMax();
    heroTimeLine.from($(".Hero__scroll-cta > img"), 0.5, {
      y: -15,
      ease: Power3.easeInOut,
      repeat: 8,
      yoyo: true,
    });

    const MasterTimeLine = new TimelineMax();
    const OurBenefitsTimeLine1 = new TimelineMax();
    const IconOne = new TimelineMax();
    const OurBenefitsTimeLine2 = new TimelineMax();
    const IconTwo = new TimelineMax();
    const OurBenefitsTimeLine3 = new TimelineMax();
    const IconThree = new TimelineMax();

    IconOne.to(
      $(".OurBenefits .BenefitsItem:nth-child(1) .d1"),
      1.5,
      {
        y: -5,
        x: 95,
        ease: Power3.easeInOut,
      },
      0.2
    );

    IconOne.to(
      $(".OurBenefits .BenefitsItem:nth-child(1) .d2"),
      1.5,
      {
        y: -15,
        x: -80,
        ease: Power3.easeInOut,
      },
      0.1
    );

    IconOne.to(
      $(".OurBenefits .BenefitsItem:nth-child(1) .d3"),
      1.5,
      {
        y: 15,
        x: -85,
        ease: Power3.easeInOut,
      },
      0.3
    );

    IconOne.to(
      $(".OurBenefits .BenefitsItem:nth-child(1) .d4"),
      1.5,
      {
        y: 5,
        x: 25,
        ease: Power3.easeInOut,
      },
      0.2
    );

    OurBenefitsTimeLine1.from(
      $(".OurBenefits .BenefitsItem:nth-child(1) .BenefitsItem__icon-wrapper"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );

    OurBenefitsTimeLine1.add(IconOne, 0.2);

    OurBenefitsTimeLine1.from(
      $(".OurBenefits .BenefitsItem:nth-child(1) .BenefitsItem__title"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0.3
    );

    OurBenefitsTimeLine1.from(
      $(".OurBenefits .BenefitsItem:nth-child(1) .BenefitsItem__description"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0.6
    );

    IconTwo.to(
      $(".OurBenefits .BenefitsItem:nth-child(2) .d1"),
      1.5,
      {
        y: 10,
        x: -10,
        ease: Power3.easeInOut,
      },
      0.4
    );

    IconTwo.to(
      $(".OurBenefits .BenefitsItem:nth-child(2) .d2"),
      1.5,
      {
        y: 77,
        x: 10,
        ease: Power3.easeInOut,
      },
      0.3
    );

    IconTwo.to(
      $(".OurBenefits .BenefitsItem:nth-child(2) .d3"),
      1.5,
      {
        y: -20,
        x: 40,
        ease: Power3.easeInOut,
      },
      0.5
    );

    IconTwo.to(
      $(".OurBenefits .BenefitsItem:nth-child(2) .d4"),
      1.5,
      {
        y: 10,
        x: -85,
        ease: Power3.easeInOut,
      },
      0.4
    );

    OurBenefitsTimeLine2.from(
      $(".OurBenefits .BenefitsItem:nth-child(2) .BenefitsItem__icon-wrapper"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );

    OurBenefitsTimeLine2.add(IconTwo, 0.2);

    OurBenefitsTimeLine2.from(
      $(".OurBenefits .BenefitsItem:nth-child(2) .BenefitsItem__title"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0.3
    );

    OurBenefitsTimeLine2.from(
      $(".OurBenefits .BenefitsItem:nth-child(2) .BenefitsItem__description"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0.6
    );

    IconThree.to(
      $(".OurBenefits .BenefitsItem:nth-child(3) .d1"),
      1.5,
      {
        y: -90,
        x: 5,
        ease: Power3.easeInOut,
      },
      0.4
    );

    IconThree.to(
      $(".OurBenefits .BenefitsItem:nth-child(3) .d2"),
      1.5,
      {
        y: 40,
        x: 40,
        ease: Power3.easeInOut,
      },
      0.3
    );

    IconThree.to(
      $(".OurBenefits .BenefitsItem:nth-child(3) .d3"),
      1.5,
      {
        y: -10,
        x: -100,
        ease: Power3.easeInOut,
      },
      0.5
    );

    IconThree.to(
      $(".OurBenefits .BenefitsItem:nth-child(3) .d4"),
      1.5,
      {
        y: 10,
        x: -65,
        ease: Power3.easeInOut,
      },
      0.4
    );

    OurBenefitsTimeLine3.from(
      $(".OurBenefits .BenefitsItem:nth-child(3) .BenefitsItem__icon-wrapper"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );

    OurBenefitsTimeLine3.add(IconThree, 0.2);

    OurBenefitsTimeLine3.from(
      $(".OurBenefits .BenefitsItem:nth-child(3) .BenefitsItem__title"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0.3
    );

    OurBenefitsTimeLine3.from(
      $(".OurBenefits .BenefitsItem:nth-child(3) .BenefitsItem__description"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0.6
    );

    OurBenefitsTimeLine3.from(
      $(".OurBenefits .OurBenefits__cta"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      1
    );

    MasterTimeLine.from($(".OurBenefits .OurBenefits__title"), 1, {
      opacity: 0,
      y: 30,
      z: 0.8,
      ease: Power3.easeInOut,
    });

    if ($(window).width() >= 1024) {
      MasterTimeLine.add(OurBenefitsTimeLine1, 0.4);
      MasterTimeLine.add(OurBenefitsTimeLine2, 0.4);
      MasterTimeLine.add(OurBenefitsTimeLine3, 0.4);
    } else {
      new ScrollMagic.Scene({
        triggerElement: ".OurBenefits .BenefitsItem:nth-child(1)",
        triggerHook: 0.8,
      })
        .setTween(OurBenefitsTimeLine1)
        .addTo(controller);

      new ScrollMagic.Scene({
        triggerElement: ".OurBenefits .BenefitsItem:nth-child(2)",
        triggerHook: 0.8,
      })
        .setTween(OurBenefitsTimeLine2)
        .addTo(controller);

      new ScrollMagic.Scene({
        triggerElement: ".OurBenefits .BenefitsItem:nth-child(3)",
        triggerHook: 0.8,
      })
        .setTween(OurBenefitsTimeLine3)
        .addTo(controller);
    }

    // This should be at the end of the block
    new ScrollMagic.Scene({
      triggerElement: ".OurBenefits",
      triggerHook: 0.8,
    })
      .setTween(MasterTimeLine)
      .addTo(controller);

    const BrandsTimeLine = new TimelineMax();

    BrandsTimeLine.from(
      $(".Brands__content--title"),
      0.5,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );

    BrandsTimeLine.from(
      $(".Brands__logo--first"),
      0.8,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0.3
    );

    BrandsTimeLine.from(
      $(".Brands__logo--second"),
      0.8,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0.6
    );

    new ScrollMagic.Scene({
      triggerElement: ".Brands",
      triggerHook: 0.5,
    })
      .setTween(BrandsTimeLine)
      .addTo(controller);

    const HowItWorksTimeLine = new TimelineMax();

    HowItWorksTimeLine.from(
      $(".SeeHowItWorks__title"),
      0.5,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );

    HowItWorksTimeLine.from(
      $(".SeeHowItWorks__player"),
      0.8,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0.3
    );

    new ScrollMagic.Scene({
      triggerElement: ".SeeHowItWorks",
      triggerHook: 0.5,
    })
      .setTween(HowItWorksTimeLine)
      .addTo(controller);

    const TrackingAndPaymentsTimeLine = new TimelineMax();
    TrackingAndPaymentsTimeLine.from(
      $(".TrackingAndPayments__media--image"),
      1.5,
      {
        opacity: 0,
        x: "-100%",
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );
    TrackingAndPaymentsTimeLine.from(
      $(".TrackingAndPayments__media--decoration"),
      1.5,
      {
        opacity: 0,
        x: "-100%",
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );
    TrackingAndPaymentsTimeLine.from(
      $(".TrackingAndPayments__title"),
      1.5,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );
    TrackingAndPaymentsTimeLine.from(
      $(".TrackingAndPayments__description"),
      2,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );
    TrackingAndPaymentsTimeLine.from(
      $(".TrackingAndPayments__cta"),
      2.5,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );
    new ScrollMagic.Scene({
      triggerElement: ".TrackingAndPayments",
      triggerHook: 0.5,
    })
      .setTween(TrackingAndPaymentsTimeLine)
      .addTo(controller);

    const PlatformSupportTimeLine = new TimelineMax();
    PlatformSupportTimeLine.from(
      $(".PlatformSupport__media"),
      1.5,
      {
        opacity: 0,
        x: "100%",
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );
    PlatformSupportTimeLine.from(
      $(".PlatformSupport__media--decoration"),
      1.5,
      {
        opacity: 0,
        x: "-100%",
        z: 0.8,
        ease: Power3.easeInOut,
      },
      0
    );
    PlatformSupportTimeLine.from(
      $(".PlatformSupport__title"),
      1.5,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      "-=1"
    );
    PlatformSupportTimeLine.from(
      $(".PlatformSupport__description"),
      1,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      "-=1"
    );
    PlatformSupportTimeLine.from(
      $(".PlatformSupport .PlatformItem:nth-child(1)"),
      1.2,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      1
    );
    PlatformSupportTimeLine.from(
      $(".PlatformSupport .PlatformItem:nth-child(2)"),
      1.2,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      1
    );
    PlatformSupportTimeLine.from(
      $(".PlatformSupport .PlatformItem:nth-child(3)"),
      1.2,
      {
        opacity: 0,
        y: 30,
        z: 0.8,
        ease: Power3.easeInOut,
      },
      1
    );
    new ScrollMagic.Scene({
      triggerElement: ".PlatformSupport",
      triggerHook: 0.5,
      // duration: '50%',
    })
      .setTween(PlatformSupportTimeLine)
      .addTo(controller);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Updating things when twitch finish the request loading.
    if (
      this.props.auth.twitch.isLoading === true &&
      nextProps.auth.twitch.isLoading === false
    ) {
      // When user successfully access.
      if (this.props.auth.isAuthenticated !== nextProps.auth.isAuthenticated) {
        const { user } = nextProps.auth;
        const isBasicInfoCompleted =
          user.firstName && user.lastName && user.country;

        simpleNotification({
          level: "success",
          title: `Welcome ${nextProps.auth.user.username}`,
          message: isBasicInfoCompleted
            ? "Good to see you again!"
            : "You are just one step away from finishing your registration.",
        });

        if (!isBasicInfoCompleted) {
          // Redirecting user when isAuthenticated changes to true.
          this.props.router.replace("/complete-registration");
        } else if (!user.prevLogin) {
          // Getting the initial info for users already registered
          const { token } = nextProps.auth;
          this.props.getInitialInfo(token);
          this.props.router.replace("/whats-noiz");
        } else {
          // Getting the initial info for users already registered
          const { token, user: user2 } = nextProps.auth;
          this.props.getInitialInfo(token);
          if (user2.role === "brand") {
            this.props.router.replace("/portal");
          } else {
            this.props.router.replace("/quests");
          }
        }
      }

      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.auth.twitch.errors.hasError) {
        // Removing the query strings code and scope from url.
        this.props.router.replace("/access-denied");
      }
    }
  }

  setPlayer = (event) => {
    this.player = event.target;
  };

  playVideo = () => {
    this.setState({
      playingVideo: true,
    });

    this.player.playVideo();
  };

  scrollDown = () => {
    $("html, body").animate(
      {
        scrollTop: $(".OurBenefits").offset().top,
      },
      1500
    );
  };

  render() {
    const { t } = this.props;

    const opts = {
      playerVars: {
        rel: 0,
        autoplay: 0,
        controls: 0,
      },
    };

    return (
      <section className="HomeV4">
        <section className="HomeV4__container">
          <section className="Hero">
            <section className="Hero__content">
              <h2 className="Hero__title">
                <Trans
                  t={t}
                  i18nKey="hero.title"
                  components={[<br key="1" />, <br key="2" />]}
                />
              </h2>
              <p className="Hero__description">
                <Trans
                  t={t}
                  i18nKey="hero.description"
                  components={[<div key="1" />]}
                />
              </p>
              <Link
                className="Hero__cta bg-button"
                to="sign-up"
                activeClassName="active"
                href="sign-up"
              >
                {t("signUp", { ns: "translation" })}
              </Link>
              <button
                className="Hero__scroll-cta"
                onClick={this.scrollDown.bind(this)}
              >
                <span>{t("scrollForMore")}</span>
                <img src={scrollMouse} alt="scroll mouse" />
              </button>
            </section>
          </section>

          <section className="OurBenefits">
            <section className="OurBenefits__content">
              <h2 className="OurBenefits__title">
                <Trans
                  t={t}
                  i18nKey="ourBenefits.title"
                  components={[<div key="1" />]}
                />
              </h2>
              <section className="OurBenefits__items">
                <section className="OurBenefits__items--item BenefitsItem">
                  <div className="BenefitsItem__icon-wrapper">
                    <div className="BenefitsItem__decoration">
                      <div className="d1" />
                      <div className="d2" />
                      <div className="d3" />
                      <div className="d4" />
                    </div>
                    <div className="BenefitsItem__background-layer" />
                    <img
                      className="BenefitsItem__icon"
                      src={moneyBag}
                      alt="money"
                    />
                  </div>
                  <h4 className="BenefitsItem__title">
                    <Trans
                      t={t}
                      i18nKey="benefitsItem.title"
                      components={[<br key="1" />, <br key="2" />]}
                    />
                  </h4>
                  <p className="BenefitsItem__description">
                    <Trans
                      t={t}
                      i18nKey="benefitsItem.description"
                      components={[<div key="1" />]}
                    />
                  </p>
                </section>
                <section className="OurBenefits__items--item BenefitsItem">
                  <div className="BenefitsItem__icon-wrapper">
                    <div className="BenefitsItem__decoration">
                      <div className="d1" />
                      <div className="d2" />
                      <div className="d3" />
                      <div className="d4" />
                    </div>
                    <div className="BenefitsItem__background-layer" />
                    <img
                      className="BenefitsItem__icon"
                      src={gameController}
                      alt="controller"
                    />
                  </div>
                  <h4 className="BenefitsItem__title">
                    <Trans
                      t={t}
                      i18nKey="benefitsItem_2.title"
                      components={[<br key="1" />, <br key="2" />]}
                    />
                  </h4>
                  <p className="BenefitsItem__description">
                    <Trans
                      t={t}
                      i18nKey="benefitsItem_2.description"
                      components={[<br key="1" />, <br key="2" />]}
                    />
                  </p>
                </section>
                <section className="OurBenefits__items--item BenefitsItem">
                  <div className="BenefitsItem__icon-wrapper">
                    <div className="BenefitsItem__decoration">
                      <div className="d1" />
                      <div className="d2" />
                      <div className="d3" />
                      <div className="d4" />
                    </div>
                    <div className="BenefitsItem__background-layer" />
                    <img
                      className="BenefitsItem__icon"
                      src={community}
                      alt="community"
                    />
                  </div>
                  <h4 className="BenefitsItem__title">
                    <Trans
                      t={t}
                      i18nKey="benefitsItem_3.title"
                      components={[<br key="1" />, <br key="2" />]}
                    />
                  </h4>
                  <p className="BenefitsItem__description">
                    <Trans
                      t={t}
                      i18nKey="benefitsItem_3.description"
                      components={[<br key="1" />, <br key="2" />]}
                    />
                  </p>
                </section>
              </section>
              <Link
                className="OurBenefits__cta bg-button"
                to="sign-up"
                activeClassName="active"
                href="sign-up"
              >
                {t("signUpNow", { ns: "translation" })}
              </Link>
            </section>
          </section>
        </section>

        <section className="Brands">
          <section className="Brands__content">
            <h3 className="Brands__content--title">
              {t("brandsContentTitle", { ns: "translation" })}
            </h3>
            <section className="Brands__logos-wrapper">
              {brandList.map((logo, i) => (
                <section className="Brands__logo" key={i}>
                  <img alt="brand logo" src={logo} />
                </section>
              ))}
            </section>
          </section>
        </section>

        <section className="SeeHowItWorks">
          <section className="SeeHowItWorks__content">
            <h2 className="SeeHowItWorks__title">{t("seeHowItWorksTitle")}</h2>
            <section
              className="SeeHowItWorks__player"
              ref={(elem) => {
                this.myRef = elem;
              }}
            >
              <div className="SeeHowItWorks__player--decoration">
                <div className="d1" />
                <div className="d2" />
                <div className="d3" />
                <div className="d4" />
              </div>
              <YouTube
                className="SeeHowItWorks__player--video"
                videoId="eZIJMssEiQU"
                onReady={this.setPlayer}
                opts={opts}
              />
              <div
                className={classNames("SeeHowItWorks__player--preview-image", {
                  hidden: this.state.playingVideo,
                })}
                style={{ backgroundImage: `url(${playerPreview})` }}
              />
              <div
                className={classNames(
                  "SeeHowItWorks__player--preview-overlay",
                  { hidden: this.state.playingVideo }
                )}
              />
              <button
                className={classNames("SeeHowItWorks__player--play-btn", {
                  hidden: this.state.playingVideo,
                })}
                type="button"
                onClick={this.playVideo.bind(this)}
              >
                <img src={playBtn} alt="play button" />
              </button>
            </section>
          </section>
        </section>

        <section className="TrackingAndPayments">
          <section className="TrackingAndPayments__content">
            <section className="TrackingAndPayments__media">
              <div className="TrackingAndPayments__media--decoration">
                <div className="d1" />
                <div className="d2" />
                <div className="d3">
                  <span />
                  <span />
                  <span />
                </div>
              </div>
              <img
                className="TrackingAndPayments__media--image"
                src={myQuests}
                alt="My Quests"
              />
            </section>
            <section className="TrackingAndPayments__details">
              <h2 className="TrackingAndPayments__title">
                <Trans
                  t={t}
                  i18nKey="trackingAndPayments.title"
                  components={[<br key="1" />, <br key="2" />]}
                />
              </h2>
              <h2 className="TrackingAndPayments__title">
                <Trans
                  t={t}
                  i18nKey="trackingAndPayments.title_2"
                  components={[<br key="1" />, <br key="2" />]}
                />
              </h2>
              <p className="TrackingAndPayments__description">
                {t("trackingAndPayments.description")}
              </p>
              <Link
                className="TrackingAndPayments__cta bg-button"
                to="sign-up"
                activeClassName="active"
                href="sign-up"
              >
                {t("signUpNow", { ns: "translation" })}
              </Link>
            </section>
          </section>
        </section>

        <section className="PlatformSupport">
          <section className="PlatformSupport__content">
            <section className="PlatformSupport__content-left">
              <h2 className="PlatformSupport__title">
                {t("platformSupport.title")}
              </h2>
              <p className="PlatformSupport__description">
                {t("platformSupport.description")}
              </p>
              <section className="PlatformSupport__platforms">
                <section className="PlatformSupport__platforms-item PlatformItem">
                  <img
                    src={twitchIcon}
                    className="PlatformItem__icon"
                    alt="twitch icon"
                  />
                </section>
                <section className="PlatformSupport__platforms-item PlatformItem">
                  <img
                    src={youtubeIcon}
                    className="PlatformItem__icon PlatformItem__icon--youtube"
                    alt="youtube icon"
                  />
                </section>
              </section>
            </section>
            <section className="PlatformSupport__content-right">
              <img
                className="PlatformSupport__media"
                src={supportMonitor}
                alt="Noiz Support Mockup"
              />
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation(["home", "translation"])(Home);
