import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import {useTranslation} from 'react-i18next'

import Icon from '../../../../../global/images/icons/Icon';
import Loading from '../../../../../global/components/loading/Loading';

import './DeleteModal.scss';

const DeleteModal = ({ deleteFn, loading, open, secondaryStatement, setOpen }) => {
  const handleClose = () => setOpen(false);

  const [checkmark, setCheckmark] = useState(false);
  const [secondaryStatementChecked, setSecondaryStatementChecked] = useState(false);
  const { t} = useTranslation('portalLeft')

  return (
    <Modal
      open={open}
      // onClose={handleClose}
    >
      <Box className="DeleteModal__Box">
        <div className="DeleteModal">
          <div className="DeleteModal__Close" onClick={handleClose}>
            <Icon name="xmark" />
          </div>
          <div className="DeleteModal__Title">{t("deleteModal.title")}</div>
          <div className="DeleteModal__Content">
            <p>
              {t("deleteModal.content1")}
              {secondaryStatement && (
                <>
                  {t("deleteModal.content2")}{" "}
                  <b style={{ color: "#e03959" }}>
                    <u>{t("deleteModal.content3")}</u>
                  </b>
                  <Checkbox
                    checked={secondaryStatementChecked}
                    onChange={() =>
                      setSecondaryStatementChecked(!secondaryStatementChecked)
                    }
                  />
                  &nbsp; &nbsp;{t("deleteModal.content4")}
                </>
              )}
              <br />
              <br />
              {t("deleteModal.content5")}{" "}
              <b style={{ color: "#e03959" }}>
                <u>{t("deleteModal.content6")}</u>
              </b>{" "}
              {t("deleteModal.content7")}
            </p>
          </div>
          <div>
            <Checkbox
              checked={checkmark}
              onChange={() => setCheckmark(!checkmark)}
            />
            &nbsp;&nbsp;{t("deleteModal.checkboxLabel")}
          </div>
          {loading && (
            <div className="DeleteModal__Button Active" disabled>
              <Loading />
            </div>
          )}
          {!loading && (
            <div
              className={`DeleteModal__Button ${
                checkmark &&
                (secondaryStatementChecked || !secondaryStatement) &&
                "Active"
              }`}
              disabled={!checkmark}
              onClick={(e) => {
                if (checkmark) deleteFn(e);
              }}
            >
              {t("deleteModal.btnDelete")}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default DeleteModal;
