import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {useTranslation } from 'react-i18next'

import Icon from '../../../../../global/images/icons/Icon';

import './Gallery.scss';

const imageModalStyle = {
  height: '50%',
  width: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  focus: 'none',
  cursor: 'pointer',
  borderRadius: '8px',
};

const GalleryImage = ({ image }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <img className="GalleryImage" src={image} alt={image} onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <img className='GalleryImage__Modal' src={image} alt={image} style={imageModalStyle} onClick={handleClose} />
      </Modal>
    </>
  );
}

const GalleryPage = ({ page, index, images }) => (
  <div className={`GalleryPage ${page === index && 'Active'}`}>
    {images.map(({ url }) => <GalleryImage image={url} key={url} />)}
  </div>
);

const Gallery = ({ gallery }) => {
  const { t}  = useTranslation('portalLeft')
  const [page, setPage] = useState(0);

  const pages = Math.ceil(gallery.length / 2);
  const left = page > 0;
  const right = page < pages - 1;

  return (
    <div className="Gallery">
      <div className="Gallery__Header">
        <div className="Gallery__Title">{t("gallery")}</div>
        <div className="Gallery__Nav">
          <div
            className={`Gallery__Nav__Button Left ${left && "Clickable"}`}
            onClick={() => {
              if (left) {
                setPage(page - 1);
              }
            }}
          >
            <Icon name="angleleft" />
          </div>
          <div
            className={`Gallery__Nav__Button Right ${right && "Clickable"}`}
            onClick={() => {
              if (right) {
                setPage(page + 1);
              }
            }}
          >
            <Icon name="angleright" />
          </div>
        </div>
      </div>
      <div className="Gallery__Pages">
        {gallery.map((image, index) => (
          <GalleryPage
            key={image}
            page={page}
            index={index}
            images={gallery.slice(index * 2, index * 2 + 2)}
          />
        ))}
      </div>
    </div>
  );
};

export default Gallery;
