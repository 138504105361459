/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */
import React from "react";
// import PropTypes from 'prop-types';
import classNames from "classnames";
import { Field, reduxForm, FieldArray, change } from "redux-form";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import { omit, isEqual, keys, forEach, orderBy, pick, filter } from "lodash";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/acai.css";
import { differenceObject, textTruncate } from "../../utils/functions";
import CustomInput from "../../utils/redux-forms-custom-fields/CustomInput";
import ArrayDropDown from "../../utils/redux-forms-custom-fields/ArrayDropDown";
import CustomDateTimePicker from "../../utils/redux-forms-custom-fields/CustomDateTimePicker";
import CustomMentionField from "../../utils/redux-forms-custom-fields/CustomMentionField";
import CustomInputCheckbox from "../../utils/redux-forms-custom-fields/CustomInputCheckbox";
import { arrayDropDownColors } from "../../utils/constants";
import RichText from "../../utils/RichText";
import "./skin.min.css";

import remove from "./img/remove.svg";
import Loading from "../../global/components/loading/Loading";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const dateStoreFormat = "YYYY-MM-DD HH:mm";
const dateDisplayFormat = "MM-DD-YYYY HH:mm";

class CampaignForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.rte_summary = "";
    this.notes = [];
    this.state = {
      tab: "details",
      logsModal: 0,
      modalType: "",
      olds: [],
      news: [],
    };
    this.rte_api_key = "wulnzhippckjtehbc6kaq1irabfkdvhgkgjezk8i24a9c653";
    this.rte_default_config = {
      skin: "dark",
      menubar: false,
      plugins: "link lists advlist",
      toolbar1:
        "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent  | removeformat",
      content_style:
        "#tinymce{background-color:#282830 !important;color:#fff !important;}",
    };

    this.rte_default_config2 = {
      skin: "dark",
      // menubar: false,
      plugins: "link lists advlist image",
      toolbar1:
        "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent  | removeformat",
      content_style:
        "#tinymce{background-color:#282830 !important;color:#fff !important;}",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.campaign.isCreating === true &&
      nextProps.campaign.isCreating === false
    ) {
      if (nextProps.campaign.errors.hasError) {
        if (nextProps.campaign.errors.code === "E_CAMPAIGN_SLUG_DUPLICATED") {
          this.setState({ tab: "details" });
          this.scrollToSlug();
        }
      }
    }
    if (
      this.props.campaign.isUpdating === true &&
      nextProps.campaign.isUpdating === false
    ) {
      if (nextProps.campaign.errors.hasError) {
        if (nextProps.campaign.errors.code === "E_CAMPAIGN_SLUG_DUPLICATED") {
          this.setState({ tab: "details" });
          this.scrollToSlug();
        }
      }
    }
  }

  scrollToSlug = () => {
    this.slugE.scrollIntoView({ behavior: "smooth" });
  };

  submit = (campaignFormInformation) => {
    const campaignFormInfo = campaignFormInformation;

    // for (var key2 in this.notes) {
    //   var index2 = parseInt(key2.split('_')[1],10);
    //   campaignFormInfo.notes[index2].text = this.notes[key2].editor.getContent();
    // }

    if (campaignFormInfo.quests && campaignFormInfo.quests.length > 0) {
      campaignFormInfo.quests = campaignFormInfo.quests.map((q) => q.value);
    }

    if (campaignFormInfo.brandUsers && campaignFormInfo.brandUsers.length > 0) {
      campaignFormInfo.brandUsers = campaignFormInfo.brandUsers.map(
        (q) => q.value
      );
    }

    // campaignFormInfo.summary = this.rte_summary.editor.getContent();

    this.props.onSubmit(campaignFormInfo);
  };

  handleContentChangeNotes = (content, index) => {
    this.props.dispatch(
      change("campaignForm", `notes[${index}].text`, content)
    );
  };

  handleContentChangeSummary = (content) => {
    this.props.dispatch(change("campaignForm", "summary", content));
  };

  questsOptions = () => {
    let { data: quests } = this.props.quests;

    let campaignStartDateTime = new Date();
    let campaignEndDateTime = new Date();
    if (this.props.campaignForm?.values) {
      campaignStartDateTime = new Date(
        this.props.campaignForm.values.startDateTime
      );
      campaignEndDateTime = new Date(
        this.props.campaignForm.values.endDateTime
      );
    }
    const options = [];
    const today = moment().tz("America/Los_Angeles");
    const hoursOffset = -today.utcOffset() / 60;
    quests = filter(quests, (q) => {
      // const questEndDateTime = moment(q.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').toDate();
      const questStartDateTime = moment(q.startDateTime)
        .tz("America/Los_Angeles")
        .add(hoursOffset, "hours")
        .toDate();
      if (
        campaignStartDateTime <= questStartDateTime &&
        campaignEndDateTime >= questStartDateTime
      ) {
        return q;
      }
    });
    quests = orderBy(quests, ["startDateTime"], ["desc"]);
    forEach(quests, (obj) => {
      options.push({
        value: obj.id,
        label: (
          <React.Fragment>
            <span data-tip={obj.title} style={{ zIndex: "1000" }}>
              {textTruncate(obj.title, 25, "...")}{" "}
              {moment(obj.startDateTime).format("MM/DD")} -{" "}
              {moment(obj.endDateTime).format("MM/DD")}
            </span>
            <ReactTooltip
              className="yellowTip"
              effect="solid"
              place="top"
              multiline
              html
            />
          </React.Fragment>
        ),
      });
    });
    return options;
  };

  usersBrandOptions = () => {
    let { data: brandUsers } = this.props.brandUsers;
    const options = [];
    brandUsers = orderBy(brandUsers, ["email"], ["asc"]);
    forEach(brandUsers, (obj) => {
      options.push({
        value: obj.id,
        label: `${obj.email} (${obj.firstName} ${obj.lastName} - ${obj.company})`,
      });
    });
    return options;
  };

  renderNotes = ({ fields, meta: { error } }) => {
    const { t } = this.props;
    return (
      <section className="CampaignForm__notes">
        {fields.map((note, index) => {
          const curr = fields.get(index);
          return (
            <section className="CampaignForm__note" key={index}>
              <div className="CampaignForm__note--fields">
                <h3 className="CampaignForm__notes--title">
                  {t("note")} {index + 1}
                </h3>
                <div className="input-container">
                  <RichText
                    initialValue={curr.text}
                    callback={(content) =>
                      this.handleContentChangeNotes(content, index)
                    }
                  />
                </div>
              </div>
              <div className="CampaignForm__note--side">
                <button
                  className="CampaignForm__note--remove"
                  type="button"
                  onClick={() => {
                    if (window.confirm(t("removeNoteConfirm"))) {
                      fields.remove(index);
                    }
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              </div>
            </section>
          );
        })}
        {error && <span className="CampaignForm__notes--error">{error}</span>}
        <button
          className="CampaignForm__note--add"
          type="button"
          onClick={() => fields.push({})}
        >
          {t("addNote")}
        </button>
      </section>
    );
  };

  renderLogs = () => {
    const { t } = this.props;
    const { data: adminActivityLogs } = this.props.adminActivityLogs;
    if (!adminActivityLogs || adminActivityLogs.length <= 0) {
      return <React.Fragment>{t("noLogs")}</React.Fragment>;
    }
    return adminActivityLogs.map((log) => {
      let differences = differenceObject(log.newDataRef, log.oldDataRef);
      // < Quests -
      const newQuests = [];
      const oldQuests = [];
      if (log.newDataRef && log.newDataRef.quests) {
        log.newDataRef.quests.forEach((st) => {
          newQuests.push(pick(st, ["title"]));
        });
      }

      if (log.oldDataRef && log.oldDataRef.quests) {
        log.oldDataRef.quests.forEach((st) => {
          oldQuests.push(pick(st, ["title"]));
        });
      }
      const questsChanged = !isEqual(newQuests, oldQuests);

      // - Quests >
      // < Brand Users -
      const newBrandUsers = [];
      const oldBrandUsers = [];
      if (log.newDataRef && log.newDataRef.brandUsers) {
        log.newDataRef.brandUsers.forEach((st) => {
          newBrandUsers.push(pick(st, ["username", "email"]));
        });
      }
      if (log.oldDataRef && log.oldDataRef.brandUsers) {
        log.oldDataRef.brandUsers.forEach((st) => {
          oldBrandUsers.push(pick(st, ["username", "email"]));
        });
      }

      const brandUsersChanged = !isEqual(newBrandUsers, oldBrandUsers);
      // - Quests >

      differences = omit(differences, [
        "updatedAt",
        "createdAt",
        "brandUsers",
        "quests",
        "notes",
        "goals",
      ]);
      const objKeys = keys(differences);

      return (
        /* QFLog -> Campaign Form Log */
        <div className="QFLog">
          <div className="QFLog_top">
            {log.user.username} -{" "}
            {moment(log.createdAt).format("MMMM DD, YYYY - hh:mm a")}
          </div>
          <div className="QFLog_bot">
            {objKeys.map((oKey) => {
              let oKeyOld = <i>Empty</i>;
              if (log.oldDataRef[oKey]) {
                oKeyOld = log.oldDataRef[oKey];
              } else if (log.oldDataRef[oKey] === "") {
                oKeyOld = "";
              } else if (log.oldDataRef[oKey] === false) {
                oKeyOld = false;
              } else if (log.oldDataRef[oKey] === false) {
                oKeyOld = false;
              } else if (typeof log.oldDataRef[oKey] === "undefined") {
                oKeyOld = <i>Undefined</i>;
              }

              let oKeyNew = <i>Empty</i>;
              if (log.newDataRef[oKey]) {
                oKeyNew = log.newDataRef[oKey];
              } else if (log.newDataRef[oKey] === "") {
                oKeyNew = "";
              } else if (log.newDataRef[oKey] === false) {
                oKeyNew = false;
              } else if (typeof log.newDataRef[oKey] === "undefined") {
                oKeyNew = <i>Undefined</i>;
              }

              return (
                <div className="QFLog_bot_key">
                  <React.Fragment>
                    {oKey}: &nbsp;
                    <span className="old">&nbsp;{oKeyOld}&nbsp;</span>
                    &nbsp;-&nbsp;
                    <span className="new">&nbsp;{oKeyNew}&nbsp;</span>
                  </React.Fragment>
                </div>
              );
            })}
            {questsChanged && (
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: "quests",
                      olds: oldQuests,
                      news: newQuests,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> {t("questsChanged")}
                </div>
              </React.Fragment>
            )}
            {questsChanged && (
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: "quests",
                      olds: oldQuests,
                      news: newQuests,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> {t("questsChanged")}
                </div>
              </React.Fragment>
            )}
            {brandUsersChanged && (
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: "brandUsers",
                      olds: oldBrandUsers,
                      news: newBrandUsers,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" />
                  {t("brandUsersChanged")}
                </div>
              </React.Fragment>
            )}
            {objKeys.length === 0 && !questsChanged && !brandUsersChanged && (
              <div className="QFLog_bot_key">{t("noChanges")}</div>
            )}
            {this.state.logsModal === 1 && this.renderLogsModal()}
          </div>
        </div>
      );
    });
  };

  renderLogsModal = () => {
    const { modalType, olds, news } = this.state;

    const { t } = this.props;

    let typeTitle = "";
    if (modalType === "quests") {
      typeTitle = t("typeTitle.quests");
    } else if (modalType === "brandUsers") {
      typeTitle = t("typeTitle.brandUsers");
    }
    return (
      <section className="ModalCampaignLogs">
        <section className="ModalMaster">
          <section className="Modal Modal__ModalCampaignLogs active">
            <div
              className="Modal__layer"
              onClick={() => {
                this.setState({ logsModal: 0 });
              }}
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div
                className="Modal__close-btn"
                onClick={() => {
                  this.setState({ logsModal: 0 });
                }}
                aria-hidden
              />
              <div className="ModalCampaignLogs__body">
                <div className="ModalCampaignLogs__olds">
                  <div className="ModalCampaignLogs__olds__title">
                    {t("old")} {typeTitle}
                  </div>
                  <div className="ModalCampaignLogs__olds__row">
                    <JSONPretty data={olds} />
                  </div>
                </div>
                <div className="ModalCampaignLogs__news">
                  <div className="ModalCampaignLogs__news__title">
                    {t("new")} {typeTitle}
                  </div>
                  <div className="ModalCampaignLogs__news__row">
                    <JSONPretty data={news} />
                  </div>
                </div>
              </div>
              <div className="ModalCampaignLogs__footer">
                <div className="ModalCampaignLogs__footer__button">
                  <div
                    className="ModalCampaignLogs__footer__buttonA"
                    onClick={() => {
                      this.setState({ logsModal: 0 });
                    }}
                    aria-hidden
                  >
                    {t("close")}
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  };

  // swapTweets = (index, direction) => {
  //   const swapIndex = (direction === 'up') ? index - 1 : index + 1;
  //   const { campaignForm } = this.props;

  //   let arr;

  //   arr = campaignForm.values.featuredTweets;
  //   const contentA = this.blockDescriptions[index].editor.getContent();
  //   const contentB = this.blockDescriptions[swapIndex].editor.getContent();
  //   this.blockDescriptions[swapIndex].editor.setContent(contentA);
  //   this.blockDescriptions[index].editor.setContent(contentB);

  //   [arr[index], arr[swapIndex]] = [arr[swapIndex], arr[index]];
  //   this.props.swapCampaignTweets(arr);
  // }

  renderFeaturedTweets = ({ fields, meta: { error } }) => {
    const { t } = this.props;
    return (
      <section className="FeaturedTweetsWrapper">
        <ul>
          {fields.map((item, index) => (
            <li key={index}>
              <section className="RepeaterWrapper__fields">
                <Field
                  name={`${item}.label`}
                  id={`${item}.label`}
                  type="text"
                  component={CustomInput}
                  placeholder={t("enterLinkToTweet")}
                />
              </section>
              <span className="buttons">
                {/* <button className={classNames("move", index === 0 ? "disabled" : "")} onClick={this.swapTweets.bind(this, index, 'up')}>
                    <i className="fa fa-chevron-up" />
                  </button>
                  <button className={classNames("move", index === 1 ? "disabled" : "")} onClick={this.swapTweets.bind(this, index, 'down')}>
                    <i className="fa fa-chevron-down" />
                  </button>
                */}
                <div
                  className="button"
                  onClick={() => {
                    fields.remove(index);
                  }}
                >
                  <img alt="remove" src={remove} />
                </div>
              </span>
            </li>
          ))}
        </ul>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        {fields.length < 2 ? (
          <button
            className="RepeaterWrapper__add-item"
            type="button"
            onClick={() => fields.push({})}
          >
            + {t("addNewTweet")}
          </button>
        ) : null}
      </section>
    );
  };

  render() {
    const {
      campaignForm: { values = {} } = { values: {} },
      campaign = {},
      quests: { data: quests = [] } = { data: { quests: [] } },
      handleSubmit,
      t,
    } = this.props;

    if (!values) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    const { tab = "" } = this.state;

    const today = new Date();

    const questOptions = this.questsOptions();
    const questsDifference = quests.length - (questOptions?.length || 0);

    return (
      <div>
        <h2>
          {t("campaignName")}: {values?.name || ""}
        </h2>
        <div className="Global__tabs">
          <div
            className={classNames("Global__tab", { active: tab === "details" })}
            onClick={() => this.setState({ tab: "details" })}
            aria-hidden
          >
            {t("details")}
          </div>
          <div
            className={classNames("Global__tab", {
              active: tab === "brand-dashboard",
            })}
            onClick={() => this.setState({ tab: "brand-dashboard" })}
            aria-hidden
          >
            {t("brandDashboard")}
          </div>
          <div
            className={classNames("Global__tab", { active: tab === "logs" })}
            onClick={() => this.setState({ tab: "logs" })}
            aria-hidden
          >
            {t("logs")}
          </div>
        </div>
        <form
          className="form-container campaigns-edit"
          autoComplete="off"
          onSubmit={handleSubmit(this.submit.bind(this))}
        >
          <div
            className="info-tab-wrap"
            style={
              tab === "details" ? { display: "block" } : { display: "none" }
            }
          >
            <div className="Form__row">
              <Field
                type="text"
                name="name"
                id="name"
                placeholder={t("name")}
                customLabel={t("name")}
                component={CustomInput}
              />
            </div>
            <div className="Form__row">
              <div className="Form__row--3">
                <Field
                  type="text"
                  name="startDateTime"
                  id="startDateTime"
                  placeholder={t("startDate")}
                  dateStoreFormat={dateStoreFormat}
                  dateDisplayFormat={dateDisplayFormat}
                  customLabel={t("startDate")}
                  component={CustomDateTimePicker}
                />
              </div>
              <div className="Form__row--3">
                <Field
                  type="text"
                  name="endDateTime"
                  id="endDateTime"
                  placeholder={t("endDate")}
                  dateStoreFormat={dateStoreFormat}
                  dateDisplayFormat={dateDisplayFormat}
                  customLabel={t("endDate")}
                  component={CustomDateTimePicker}
                />
              </div>
              <div className="Form__row--3">
                <Field
                  type="text"
                  name="budget"
                  id="budget"
                  placeholder="Budget"
                  customLabel={
                    <span className="tooltip">
                      {t("budget")}&nbsp;
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("budgetTip")}</div>
                    </span>
                  }
                  component={CustomInput}
                  onlyNumbers="true"
                />
              </div>
            </div>
            <div className="input-container summary-container">
              <label>{t("summary")}</label>
              <RichText
                initialValue={values?.summary || ""}
                callback={this.handleContentChangeSummary}
              />
            </div>
            <div className="Form__row">
              <div className="Form__row--2">
                <Field
                  type="text"
                  name="quests"
                  id="quests"
                  placeholder={t("quests")}
                  customLabel={
                    <span className="tooltip">
                      {t("quests")}&nbsp;
                      {questsDifference > 0 && (
                        <>
                          <i className="fa fa-info-circle" />
                          <div className="tooltiptext">
                            {t("there")} {questsDifference !== 1 ? "are" : "is"}{" "}
                            {questsDifference} {t("quest")}
                            {questsDifference !== 1 && "s"} {t("outside")}
                          </div>
                        </>
                      )}
                    </span>
                  }
                  component={ArrayDropDown}
                  styles={arrayDropDownColors}
                  isMulti
                  isClearable={false}
                  optionsToRender={questOptions}
                />
              </div>
              <div className="Form__row--2">
                <Field
                  type="text"
                  name="brandUsers"
                  id="brandUsers"
                  placeholder={t("brandUsers")}
                  customLabel={
                    <span className="tooltip">
                      {t("brandUsers")}&nbsp;
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("brandUsersTip")}</div>
                    </span>
                  }
                  component={ArrayDropDown}
                  styles={arrayDropDownColors}
                  isMulti
                  isClearable={false}
                  optionsToRender={this.usersBrandOptions()}
                />
              </div>
            </div>

            <div className="input-container">
              <label>{t("goals")}</label>
              <div className="Form__row">
                <div className="Form__row--2">
                  <Field
                    type="number"
                    name="goals.uniqueStreams"
                    id="goals.uniqueStreams"
                    placeholder="0"
                    customLabel={t("uniqueStreamsGoal")}
                    component={CustomInput}
                  />
                </div>
                <div className="Form__row--2">
                  <Field
                    type="number"
                    name="goals.uniqueStreamers"
                    id="goals.uniqueStreamers"
                    placeholder="0"
                    customLabel={t("uniqueStreamersGoal")}
                    component={CustomInput}
                  />
                </div>
                <div className="Form__row--2">
                  <Field
                    type="number"
                    name="goals.viewerMinutesWatched"
                    id="goals.viewerMinutesWatched"
                    placeholder="0"
                    customLabel={t("totalViewerMinutesWatchedGoal")}
                    component={CustomInput}
                  />
                </div>
              </div>
            </div>

            <div>
              <FieldArray
                name="notes"
                id="notes"
                component={this.renderNotes}
              />
            </div>
          </div>

          <div
            className="info-tab-wrap"
            style={tab === "logs" ? { display: "block" } : { display: "none" }}
          >
            {this.renderLogs()}
          </div>

          <div
            className="info-tab-wrap"
            style={
              tab === "brand-dashboard"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <h2> {t("overview")} </h2>
            <div className="Form__row" style={{ margin: "0 0 0 20px" }}>
              <div className="Form__row--3">
                <Field
                  type="text"
                  name="dashboardBudget"
                  id="dashboardBudget"
                  placeholder="$0"
                  component={CustomInput}
                  onlyNumbers="true"
                  customLabel={
                    <span className="tooltip">
                      {t("budget")}&nbsp;
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">
                        {t("dashboardBudgetTip")}
                      </div>
                    </span>
                  }
                />
              </div>
            </div>
            <div className="Form__row" style={{ margin: "0 0 0 20px" }}>
              <Field
                name="displayUniqueStreamers"
                id="displayUniqueStreamers"
                type="checkbox"
                component={CustomInputCheckbox}
                customLabel={
                  <span className="tooltip">
                    {t("displayUniqueStreamersCount")} &nbsp;
                    <i className="fa fa-info-circle" />
                    <div className="tooltiptext">
                      {t("displayUniqueStreamersTip")}
                    </div>
                  </span>
                }
              />
            </div>
            <div className="Form__row" style={{ margin: "0 0 0 20px" }}>
              <Field
                name="displayUniqueStreams"
                id="displayUniqueStreams"
                type="checkbox"
                component={CustomInputCheckbox}
                customLabel={
                  <span className="tooltip">
                    {t("displayUniqueStreams")}&nbsp;
                    <i className="fa fa-info-circle" />
                    <div className="tooltiptext">
                      {t("displayUniqueStreamsTip")}
                    </div>
                  </span>
                }
              />
            </div>

            <h2> {t("chatAnalytics")} </h2>
            <div style={{ margin: "0 0 0 20px" }}>
              <h3> {t("chatMentions")} </h3>
              <div className="Form__row">
                <Field
                  name="chatMentions"
                  id="chatMentions"
                  type="text"
                  placeholder={t("selectValue")}
                  component={CustomMentionField}
                  props={{
                    disabled: today >= new Date(campaign.data.startDateTime),
                  }}
                />
              </div>

              <div style={{ flexFlow: "row" }}>
                <Field
                  name="trackLinks"
                  id="trackLinks"
                  type="checkbox"
                  component={CustomInputCheckbox}
                  customLabel={<label>{t("includeTrackingLink")}</label>}
                />
              </div>
              <div className="Form__row">
                <Field
                  name="displayTopTopics"
                  id="displayTopTopics"
                  type="checkbox"
                  component={CustomInputCheckbox}
                  customLabel={
                    <span className="tooltip">{t("displayTopTopics")}</span>
                  }
                />
              </div>
            </div>

            <h2> {t("twitterAnalytics")}</h2>
            <div className="Form__row" style={{ margin: "0 0 0 20px" }}>
              <h3> {t("featuredTweets")}</h3>
            </div>
            <FieldArray
              name="featuredTweets"
              id="featuredTweets"
              component={this.renderFeaturedTweets}
            />
          </div>

          {tab !== "logs" && (
            <div className="form__footer">
              <input
                type="submit"
                className="CCCampaigns__save--btn"
                value={t("save")}
              />
            </div>
          )}
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.name || values.name.trim() === "") {
    errors.name = t("validate.fieldRequired");
  }

  const isValidStartDate = moment(
    values.startDateTime,
    dateStoreFormat,
    true
  ).isValid();
  const isValidEndDate = moment(
    values.endDateTime,
    dateStoreFormat,
    true
  ).isValid();

  if (!values.startDateTime) {
    errors.startDateTime = t("validate.fieldRequired");
  } else if (!isValidStartDate) {
    errors.startDateTime = `${t(
      "validate.campaignForm.dateRule"
    )} ${dateStoreFormat}`;
  }

  if (!values.endDateTime) {
    errors.endDateTime = t("validate.fieldRequired");
  } else if (!isValidEndDate) {
    errors.endDateTime = `${t(
      "validate.campaignForm.dateRule"
    )} ${dateStoreFormat}`;
  }

  if (!values.summary || values.summary.trim() === "") {
    errors.summary = t("validate.fieldRequired");
  }

  if (
    !values.budget ||
    values.budget.trim() === "" ||
    parseInt(values.budget, 10) <= 0
  ) {
    errors.budget = t("validate.fieldRequired");
  }

  if (!values.quests || values.quests.length <= 0) {
    errors.quests = t("validate.campaignForm.questsRule");
  }

  return errors;
};

CampaignForm.propTypes = {};

export default withTranslation("campaignForm")(
  reduxForm({
    form: "campaignForm",
    validate,
  })(CampaignForm)
);
