import * as React from "react";
import { useTranslation } from 'react-i18next'

import './QuestCampaignToggle.scss';

export default function QuestCampaignToggle({ isCampaigns = true, toReturn = () => null }) {
  const {t} = useTranslation('portalLeft')

  return (
    <div className="QuestCampaignToggle_Wrapper">
      <label>{t("campaigns")}</label>
      <input
        type="radio"
        name="campaignOrQuest"
        value="campaign"
        onClick={() => toReturn(true)}
        defaultChecked={isCampaigns}
      />
      <label>{t("quests")}</label>
      <input
        type="radio"
        label={t("quests")}
        name="campaignOrQuest"
        value="quest"
        onClick={() => toReturn(false)}
        defaultChecked={!isCampaigns}
      />
    </div>
  );
};
