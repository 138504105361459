import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { filter, find } from "lodash";
// import TablePagination from '@material-ui/core/TablePagination';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
import {
  Checkbox,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Paper,
  Typography,
  Toolbar,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import Button from '@material-ui/core/Button';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import CancelIcon from '@material-ui/icons/Cancel';
// import { stableSort, getSorting } from '../../../../utils/functions';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontSize: "20px",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.primary.dark,
          fontSize: "20px",
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
          fontSize: "20px",
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
    fontSize: "20px",
  },
  mainTitle: {
    fontSize: "20px",
    fontWeight: 600,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  form: {
    marginBottom: "10px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  tableRow: {
    height: "48px",
  },
  button: {
    marginLeft: "5px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const generateGamesMenuItems = (games) => {
  if (games && games.length > 0) {
    return games.map((x) => <MenuItem value={x.id}>{x.name}</MenuItem>);
  }
  return <React.Fragment />;
};

/*
const generateTiersMenuItems = (tiers) => {
  if (tiers && tiers.length > 0) {
    return tiers.map(x => <MenuItem value={x.minimumAverageViewers}>{x.minimumAverageViewers}</MenuItem>);
  }
  return <React.Fragment />;
};
*/

export default function ActiveQuestsTable(props) {
  const { t } = useTranslation("activeQuestsTable");

  const { gameId, questId } = props.router.params;
  const { data: activeQuests } = props.commandCenter.activeQuests;
  const { data: activeGames } = props.commandCenter.activeGames;
  const { data: activeUserQuests } = props.commandCenter.activeUserQuests;
  const {
    tab,
    currentUser,
    handleChangeCheckboxRemove,
    handleChangeCheckboxMove,
    moveCheckbox,
    removeCheckbox,
  } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("minimumAverageViewers");

  const headCells = [
    {
      id: "select",
      align: "left",
      disablePadding: false,
      label: t("select"),
    },
    {
      id: "title",
      align: "left",
      disablePadding: false,
      label: t("title"),
    },
  ];
  function EnhancedTableHead(props) {
    const {
      // classes,
      order,
      orderBy,
      // onRequestSort,
    } = props;

    /* const createSortHandler = property => (event) => {
      onRequestSort(event, property);
    }; */

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align || "left"}
              padding="default"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const EnhancedTableToolbar = () => {
    const classes = useToolbarStyles();
    return (
      <Toolbar
        className={classnames(classes.root, {
          [classes.highlight]: false,
        })}
      >
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            {t("quests")}
          </Typography>
        </div>
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {};

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const [values, setValues] = React.useState({
    game: gameId,
  });

  // const inputLabel = React.useRef(null);
  // const [labelWidth, setLabelWidth] = React.useState(0);
  // React.useEffect(() => {
  //   setLabelWidth(inputLabel.current.offsetWidth);
  // }, []);

  const handleChange = (event) => {
    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  };

  let activeQuestsFiltered = [];
  if (tab === 0) {
    activeQuestsFiltered = filter(activeQuests, (x) => x.game === gameId);
  } else {
    activeQuestsFiltered = filter(activeQuests, (x) => x.game === values.game);
  }

  if (!activeQuestsFiltered || activeQuestsFiltered.length <= 0) {
    return (
      <div className={classes.root}>
        <form className={classes.form} autoComplete="off">
          {tab === 1 && (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="game">{t("game")}</InputLabel>
              <Select
                value={values.game}
                onChange={handleChange}
                inputProps={{
                  name: "game",
                  id: "game",
                }}
              >
                {generateGamesMenuItems(activeGames)}
              </Select>
            </FormControl>
          )}
        </form>
        <React.Fragment>{t("noQuests")}</React.Fragment>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <form className={classes.form} autoComplete="off">
        {tab === 1 && (
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="game">{t("game")}</InputLabel>
            <Select
              value={values.game}
              onChange={handleChange}
              inputProps={{
                name: "game",
                id: "game",
              }}
            >
              {generateGamesMenuItems(activeGames)}
            </Select>
          </FormControl>
        )}
      </form>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={activeQuestsFiltered.length}
            />
            <TableBody>
              {activeQuestsFiltered.map((row) => {
                const currentQuest = row.id === questId;
                const alreadyIn = find(
                  activeUserQuests,
                  (x) => x.user === currentUser.id && x.quest === row.id
                );
                let highlight = <React.Fragment />;
                if (currentQuest && alreadyIn) {
                  highlight = (
                    <i style={{ color: "#47b8d4" }}>
                      &nbsp;{t("currentQuestAndJoined")}
                    </i>
                  );
                } else if (currentQuest) {
                  highlight = (
                    <i style={{ color: "#47b8d4" }}>&nbsp;{t("currentQuest")}</i>
                  );
                } else if (alreadyIn) {
                  highlight = <i style={{ color: "#47b8d4" }}>&nbsp;{t("joined")}</i>;
                }
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    className={classes.tableRow}
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    // key={row.paymentAmount}
                    // selected={isItemSelected}
                  >
                    <TableCell align="left">
                      {alreadyIn && (
                        <Checkbox
                          checked={removeCheckbox === row.id}
                          onChange={() =>
                            handleChangeCheckboxRemove(
                              currentUser.id,
                              row.id,
                              row.title
                            )
                          }
                          value={row.id}
                          // disabled={!allowRemove}
                        />
                      )}
                      {!alreadyIn && !currentQuest && (
                        <Checkbox
                          checked={moveCheckbox === row.id}
                          onChange={() =>
                            handleChangeCheckboxMove(
                              currentUser.id,
                              row.id,
                              row.title
                            )
                          }
                          value={row.id}
                          // disabled={!allowMove}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {row.title}
                      {highlight}
                    </TableCell>
                    {/* <TableCell align="center">
                        <Select>
                          { generateTiersMenuItems(row.viewerTiers) }
                        </Select>
                        </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  );
}
