import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 

import { getPaymentAmount } from '../../utils/functions';
import { addRedirectFromLogin } from '../../utils/util';

const defaultButtonBehavior = () => console.log('Default Button Behavior');

const toMyAccount = () => window.location.assign('/my-account');
const toMyQuest = questId => window.location.assign(`/my-quests/${questId}`);

const StatusButton = (props) => {
  const {
    onApply = null,
    buttonStylings = {},
    quest = {},
    user = {},
    userQuest = {},
    userQuestIsLoading = false,
    location = {},
    router = {},
  } = props;

  const { t } = useTranslation("quest_StatusButton");
  const paymentMethods = [];

  useEffect(() => {
    // getPaymentMethods();
  }, []);

  const {
    closeRegistrationDate = null,
    id: questId = '',
    memberTiers,
    openRegistrationDate = null,
    platforms = [],
    status = 'disabled',
    viewerTiers,
  } = quest;

  const {
    connectedAccounts: {
      twitch: { username },
    },
  } = user;

  const {
    status: userQuestStatus = null,
  } = userQuest;

  const brandQuest = !!platforms.length && platforms[0].platform === 'Brand';
  const stripeLinked = !!paymentMethods?.length && paymentMethods[0]?.details_submitted && paymentMethods[0]?.payouts_enabled;
  let buttonClass = 'CTAButton__red disabled';
  let buttonDisabled = true;
  let buttonFunction = onApply || defaultButtonBehavior;
  let buttonText = t("buttonText_1") ;


  if (status !== 'active') { // Check if Quest is Currently Active
    buttonText = `${t("buttonText_2")} ${status}`;
  } else if (closeRegistrationDate && openRegistrationDate) { // Check if Quest Dates are there
    const today = new Date();
    const dateBegin = new Date(openRegistrationDate);
    const dateEnd = new Date(closeRegistrationDate);

    if (today < dateBegin || today > dateEnd) {
      // Check if Quest is Currently Active
      buttonClass = 'CTAButton__lightGrey disabled';
      buttonText =
        today < dateBegin ? t("buttonText_3.0") : t("buttonText_3.1");
    } else {
      buttonClass = 'CTAButton__purple';
      buttonDisabled = false;
      if (!username) {
        // Check if User is Logged In
        buttonText = t("buttonText_SignIn");
        buttonFunction = ()=> addRedirectFromLogin(router, location.pathname);
      } else if (!userQuestStatus) {
        // Check if User has Applied for Quest
        const { paymentAmount } = getPaymentAmount(memberTiers, user, viewerTiers, userQuest);

        if ((!brandQuest || (brandQuest && stripeLinked)) && paymentAmount !== Number.MIN_SAFE_INTEGER) {
          buttonText = t("buttonText_applyNow");
          buttonFunction = onApply || defaultButtonBehavior;
        } else if (brandQuest && !stripeLinked) {
          // Check if Brand Quest And No Stripe Account
          buttonClass = 'CTAButton__cyan';
          buttonFunction = toMyAccount;
          buttonText =
            !!paymentMethods.length &&
            (!paymentMethods[0].stripeAccountRaw?.details_submitted ||
              !paymentMethods[0].stripeAccountRaw?.payouts_enabled)
              ? t("buttonText_4.0")
              : t("buttonText_4.1");
        } else {
          buttonClass = 'CTAButton__red ineligible';
          buttonDisabled = true;
          buttonFunction = defaultButtonBehavior;
          buttonText = t("buttonText_Ineligible");
        }
      } else if (userQuestStatus) {
        // Check Status of Application
        switch (userQuestStatus) {
          case 'approved':
          case 'normal':
            buttonFunction = () => toMyQuest(questId);
            buttonText = t("buttonText_SeeMyQuest");
            break;
          case 'pending':
            buttonClass = 'CTAButton__lightGrey disabled';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = t("buttonText_Pending");
            break;
          case 'declined':
          case 'rejected':
            buttonClass = 'CTAButton__red ineligible';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = t("buttonText_Ineligible");
            break;
          case 'leftQuest':
            buttonClass = 'CTAButton__red ineligible';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = t("buttonText_LeftQuest");
            break;
          default:
            buttonClass = 'CTAButton__red ineligible';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = t("buttonText_Error");
            break;
        }
      }
    }
  } else {
    buttonText = t("buttonText_Error");
  }

  const spinner = <i className="fa fa-spinner fa-spin fa-3x fa-fw" />;

  return (
    <button
      className={buttonClass}
      disabled={buttonDisabled}
      onClick={buttonFunction}
      styles={buttonStylings}
    >
      { userQuestIsLoading ? spinner : buttonText }
    </button>
  );
};

export default StatusButton;
