import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SetViewContext } from "../../PortalContext";
import {
  getDateFormattedString,
  getQuestRequirements,
  getUserAvatar,
  htmlToPlainText,
  timeAgoCalc,
} from "../../../../../utils/functions";
import { simpleNotification } from "../../../../../utils/notifications";
import { getRandomAvatar } from "../../../../../global/images/icons/Avatar";
import {
  payStreamer,
  checkQuestCompletionStreamer,
  getArbitrationStatusUserQuest,
} from "../../../../../utils/apiv2";

import Arbitration from "./Arbitration";
import ArbitrationStatus from "./ArbitrationStatus";
import ConfirmModal from "../../../../../global/components/confirm-modal/ConfirmModal";
import DeliverableModal from "./DeliverableModal";
import Icon from "../../../../../global/images/icons/Icon";
import Loading from "../../../../../global/components/loading/Loading";
import ProgressRing from "../../../../../global/components/ProgressRing/ProgressRing";
import StreamerDeliverablesCard from "./StreamerDeliverablesCard";
import StreamerNames from "../../../../StreamerNames";

import "./ReviewDeliverable.scss";

const ReviewDeliverable = (props) => {
  const {
    brandUser: {
      deliverable: {
        data: userQuest,
        isLoading: isLoadingUserQuest,
        isUpdating: isUpdatingUserQuest,
        errors: { hasErrors, message },
      },
    },
    currentUserQuest,
    getBrandUserQuest,
    postBrandRequirement,
    token,
  } = props;

  const { t } = useTranslation(["portalLeft_reviewDeliverable", "functions"]);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [rehydrate, setRehydrate] = useState(1);
  const [openRelease, setOpenRelease] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [arbitrationModal, setArbitrationModal] = useState(false);
  const [arbitrationTicket, setArbitrationTicket] = useState({});
  const isInArbitration = arbitrationTicket?.id;

  const rehydrateUserQuest = () => setRehydrate(Math.random());

  const setArbitration = async () => {
    const api = await getArbitrationStatusUserQuest(currentUserQuest);
    const arbitrationInfo = api?.data?.data;
    if (arbitrationInfo) {
      setArbitrationTicket(arbitrationInfo);
    }
  };

  const checkQuestComplete = async () => {
    const api = await checkQuestCompletionStreamer(currentUserQuest);
    const questInfo = api?.data?.data;
    if (questInfo) {
      setPaymentInfo(questInfo);
    }
  };

  const releasePayment = async () => {
    setIsLoading(true);
    const response = await payStreamer(userQuest?.id);
    console.log("releasePayment----response", response);
    const checkQuest = checkQuestComplete;
    if (response) {
      setOpenRelease(false);
      checkQuest();
    }
    rehydrateUserQuest();
    setIsLoading(false);
  };

  useEffect(() => {
    if (currentUserQuest) {
      getBrandUserQuest(currentUserQuest, token);
    }
  }, [currentUserQuest, rehydrate]); // having isLoading here is overkill, revistit

  useEffect(() => {
    const setArb = setArbitration;

    if (currentUserQuest) {
      setArb();
      checkQuestComplete();
    }
  }, [currentUserQuest]);

  useEffect(() => {
    if (hasErrors) {
      simpleNotification({
        level: "error",
        title: t("error"),
        message,
      });
    }
  }, [hasErrors]);

  if (isLoadingUserQuest && !isUpdatingUserQuest) {
    return <Loading isBrand />;
  }

  const handlePostRequirementSubmit = (requirement) => {
    if (!!requirement) {
      const data = {
        requirementId: requirement.id,
        status: requirement.status,
        userQuestId: currentUserQuest,
      };

      if (requirement.note) {
        data.note = requirement.note;
      }

      postBrandRequirement(data, token);
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setModal(false);
    setModalContent({});
  };

  const {
    requirements = [],
    user = {},
    quest = {},
    questTimedActivity = {},
  } = userQuest;

  const { displayName, username } = user;
  const { timePlaying = 0 } = questTimedActivity;
  const {
    description: questDescription,
    id: questId,
    title: questTitle,
    startDateTime,
    endDateTime,
  } = quest;
  const { status: questStatus } = userQuest;
  const today = new Date();
  const questActive =
    new Date(startDateTime) <= today && today <= new Date(endDateTime);
  const isQuestCompleted = questStatus === 'completed'

  const questRequirements = getQuestRequirements(
    { requirements: quest?.requirements },
    true
  );

  if (quest?.requiredStreamTimeMinutes) {
    questRequirements.push({
      timeRequired: quest.requiredStreamTimeMinutes,
      title: `${t("streamFor.0")} ${quest.requiredStreamTimeMinutes} ${t(
        "streamFor.1"
      )}`,
    });
  }

  let streamTimeDone = false;

  const questRequirementsWithUserSubmissions = questRequirements.reduce(
    (acc, qr) => {
      const foundUserRequirement = qr.timeRequired
        ? {
            status: timePlaying >= qr.timeRequired ? "completed" : "to-review",
            timePlaying,
            id: qr.id,
          }
        : requirements.find((r) => r.questReqId === qr.id) || {};

      if (timePlaying >= qr.timeRequired) streamTimeDone = true;

      acc.push({
        ...qr,
        ...foundUserRequirement,
      });

      return acc;
    },
    []
  );

  const completedCount = questRequirementsWithUserSubmissions.filter(
    (qr) => qr.status === "completed"
  ).length;

  const submittedCount = questRequirementsWithUserSubmissions.filter(
    (qr) => qr.status === "completed" || qr.status === "to-review"
  ).length;

  const completed = completedCount >= questRequirements.length;
  const allsubmitted =
    submittedCount >= questRequirements.length && streamTimeDone;
  const { completed: questComplete, payment } = paymentInfo || {};
  const earnedAmount = questComplete ? payment : 0;
  // const promisedAmount = payment || 0;

  // const paidOrCompletedCheck = questIsPaid || !completed // disable if quest has been paid or if requirements are undone
  // add tooltips to explain why buttons are disabled

  const paidOrSubmittedCheck = isQuestCompleted || !allsubmitted; // disable if quest has been paid or if requirements are not all submitted / completed

  const StreamerHead = () => (
    <div className="StreamerHead">
      {questActive && (
        <div className="StreamerHead__TimeRemaining">
          <div className="StreamerHead__TimeRemaining__Content">
            <div className="StreamerHead__TimeRemaining__Time">
              <Icon name="clock" /> &nbsp;
              {timeAgoCalc(today, endDateTime, t("left"))?.toUpperCase()}
            </div>
            <div className="StreamerHead__TimeRemaining__Backdrop" />
          </div>
        </div>
      )}
      <div className="StreamerHead__Avatar">
        <div className="StreamerHead__Avatar__ProgressRing">
          <ProgressRing
            radius="105"
            strokeWidth="10"
            strokeColor={completed ? "#62e841" : "#b7bbfd"}
            progress={
              (completedCount /
                (questRequirementsWithUserSubmissions.length || 1)) *
              100
            }
          />
        </div>
        <img
          src={getUserAvatar(user)}
          alt={username}
          className="StreamerHead__Avatar__Img"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = getRandomAvatar();
          }}
        />
      </div>
      <div
        className={`StreamerHead__QuestProgress ${
          completed ? "Completed" : "Incomplete"
        }`}
      >
        <Icon name="checklist" color={completed ? "#62e841" : "#b7bbfd"} />
        &nbsp;
        {completed
          ? t("allDeliverablesCompleted")
          : `${Math.floor(
              (completedCount / questRequirements.length) * 100
            )}% ${t("completed")}`}
      </div>
      {!paidOrSubmittedCheck && !isInArbitration && (
        <div className="StreamerHead__SubmittedDisclaimer">
          <>
            {t("paymentTip")} <strong>{t("submittedOrApproved")}</strong>
          </>
        </div>
      )}
    </div>
  );

  const StreamerPayments = () => (
    <div className="StreamerPayments">
      <div className="StreamerPayments__Username">
        {displayName} {`${isQuestCompleted ? t("wasPaid") : t("hasEarned")}`}
      </div>
      {/* <div className='StreamerPayments__Username'>{displayName}</div> */}

      <div
        className={`StreamerPayments__Amount ${completed ? "green" : "yellow"}`}
      >
        ${completed || isQuestCompleted ? earnedAmount : "0"} of ${payment}
      </div>

      <div className="StreamerPayments__Buttons">
        <ConfirmModal
          setOpen={setOpenRelease}
          loading={isLoading}
          open={openRelease}
          callback={releasePayment}
          useConfirmCheckbox
          title={t("confirmModal.title")}
          confirmText={t("confirmModal.confirmText")}
          isGreen={true}
          content={`${t("confirmModal.content.0")} ${username}`}
        />
        <button
          className={`StreamerPayments__Button Release ${
            isQuestCompleted ? "disabled" : null
          }`}
          disabled={isQuestCompleted}
          onClick={() => setOpenRelease(true)}
        >
          {t("confirmModal.button_1")}
        </button>
        <button
          onClick={() => setArbitrationModal(!arbitrationModal)}
          className={`StreamerPayments__Button Reject ${
            paidOrSubmittedCheck ? "disabled" : null
          } `}
          disabled={paidOrSubmittedCheck}
        >
          {t("confirmModal.button_2")}
        </button>
      </div>
    </div>
  );

  const StreamerDeliverables = () => (
    <div className="StreamerDeliverables">
      <div className="StreamerDeliverables__Title">
        {t("questDeliverables")}
      </div>
      <div className="StreamerDeliverables__List">
        {questRequirementsWithUserSubmissions.map((qr) => {
          return (
            <StreamerDeliverablesCard
              key={qr.id}
              quest={quest}
              setModal={setModal}
              setModalContent={setModalContent}
              {...qr}
            />
          );
        })}
      </div>
    </div>
  );

  const QuestInformation = () => (
    <div className="QuestInformation">
      <div className="QuestInformation__Title">{questTitle}</div>
      <div className="QuestInformation__Start InfoHeader">
        {t("started")}: {getDateFormattedString(startDateTime, "MMMM DD, YYYY")}
      </div>
      <div className="QuestInformation__End InfoHeader">
        {t("ended")}: {getDateFormattedString(endDateTime, "MMMM DD, YYYY")}
      </div>
      <div className="QuestInformation__Description__Header InfoHeader">
        {t("description")}:
      </div>
      <div className="QuestInformation__Description">
        {questDescription && htmlToPlainText(questDescription)}
      </div>
      <div className="QuestInformation__Buttons">
        <SetViewContext.Consumer>
          {({ setView, view }) => (
            <div
              className="QuestInformation__Button"
              onClick={() => {
                setView({
                  tab: view.tab,
                  component: "QuestDetail",
                  currentQuest: questId,
                });
                window.scrollTo({ top: 0 });
              }}
            >
              {t("viewQuestDetails")}
            </div>
          )}
        </SetViewContext.Consumer>
      </div>
    </div>
  );

  const RenderModal = () => (
    <DeliverableModal
      handleCloseModal={handleCloseModal}
      handlePostRequirementSubmit={handlePostRequirementSubmit}
      modal={modal}
      modalContent={modalContent}
    />
  );

  return (
    <div className="ReviewDeliverable">
      {modal && <RenderModal />}
      <StreamerHead />
      {!isInArbitration && (
        <>
          <StreamerPayments />
          <StreamerDeliverables />
        </>
      )}
      {isInArbitration && (
        <ArbitrationStatus {...arbitrationTicket} questTitle={questTitle} />
      )}
      <QuestInformation />
      <Arbitration
        open={arbitrationModal}
        setOpen={setArbitrationModal}
        useConfirmCheckbox={true}
        requirements={questRequirementsWithUserSubmissions}
        userQuest={userQuest}
        setArbitration={setArbitration}
      />
      <div style={{ left: 0, position: "absolute", right: 0 }}>
        <StreamerNames text={username} />
      </div>
    </div>
  );
};

export default ReviewDeliverable;
