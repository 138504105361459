import React from "react";
import {
  Paper,
  Modal,
  Backdrop,
  Fade,
  Button,
  Grid,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { simpleNotification } from "../../../../utils/notifications";
import "./QuestDetailsTable.scss";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    boxShadow: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  paper: {
    backgroundColor: "#212227",
    boxShadow: theme.shadows[5],
    color: "white",
    width: "480px",
    maxWidth: "90%",
  },
  topMast: {
    backgroundColor: "#313337",
    padding: "20px 40px",
  },
  mainContainer: {
    width: "100%",
    height: "100%",
    padding: "40px 40px",
  },
  textField: {
    boxShadow: "none !important",
    "-webkit-appearance": "none",
  },
  errorButton: {},
  confirmButton: {
    backgroundColor: "green",
  },
  buttonGrid: {
    width: "100%",
    marginTop: "30px",
  },
  inputBox: {
    lineHeight: 2,
  },
  warning: {
    color: "red",
    marginTop: "20px",
  },
  openButton: {
    color: "white",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    padding: "5px 10px",
  },
}));

export default function EditModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [link, setLink] = React.useState("");
  const [changed, setChanged] = React.useState(false);

  const { t } = useTranslation("customLinkModal");

  const {
    completeSelected,
    selectedCheckboxesLength,
    useMaterialUI,
    existingLink,
  } = props;

  const handleOpen = () => {
    if (selectedCheckboxesLength <= 0) {
      simpleNotification({
        level: "warning",
        title: t("warning"),
        message: t("warningMessage"),
      });
      return;
    }
    setOpen(true);
    setLink("");
  };

  const handleClose = () => {
    setOpen(false);
    setLink("");
  };

  const btn = useMaterialUI ? (
    <Button color="primary" {...useMaterialUI} onClick={handleOpen}>
      {t("submitCustomResponse")}
    </Button>
  ) : (
    <Tooltip title={t("enterLinkTip")} placement="top">
      <button type="button" className="small-btn" onClick={handleOpen}>
        <i className="fa fa-edit" />
      </button>
    </Tooltip>
  );

  if (existingLink && !changed && link === "") setLink(existingLink);

  return (
    <div className="CustomLinkModal">
      {btn}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Grid
              xs={12}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid
                container
                xs={12}
                justify="space-around"
                align="flex-start"
                direction="column"
                className={classes.mainContainer}
              >
                <Grid className="CustomLink--input">
                  <TextField
                    id="link"
                    label={t("content")}
                    type="text"
                    onChange={(e) => {
                      setLink(e.target.value);
                      setChanged(true);
                    }}
                    boxShadow={0}
                    className={`${classes.textField}`}
                    value={link}
                  />
                </Grid>
                <Grid
                  container
                  className={classes.buttonGrid}
                  xs={12}
                  direction="row"
                  width="100%"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        completeSelected(link);
                        handleClose();
                      }}
                      disabled={!link}
                    >
                      {t("submitCustomResponse")}
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      onClick={handleClose}
                      className={classes.errorButton}
                    >
                      {t("cancel")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}
