/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router";
import AdminTabs from "../../util/tabs/AdminTabs";
import AdminUserForm from "../../../forms/AdminUserForm";
import { simpleNotification } from "../../../../utils/notifications";
import "./UserCreate.scss";
import { withTranslation } from "react-i18next";

class UserCreate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  UNSAFE_componentWillMount() {}

  componentDidUpdate(prevProps) {
    const { t } = this.props;
    if (prevProps.admin.user.isLoading === true) {
      simpleNotification({
        level: "success",
        title: t("userCreateTitle"),
        message: t("userCreateMessage"),
      });
      this.props.router.replace("/admin/users");
    }
  }

  onSubmit = (data) => {
    const { token } = this.props.auth;
    this.props.createAUser(data, token);
  };

  render() {
    const { t } = this.props;
    return (
      <section className="AUserCreate">
        <section className="AUserCreate__wrapper">
          <AdminTabs {...this.props} />
          <section className="AUserCreate__content">
            <h2 className="AUserCreate__title">{t("createUser")}</h2>
            <div className="AUserCreate__actions">
              <Link
                to="/admin/users"
                activeClassName="active"
                href="/admin/users"
              >
                <button>{t("back")}</button>
              </Link>
            </div>
            <AdminUserForm
              onSubmit={this.onSubmit.bind(this)}
              adminUserForm={this.props.form.adminUserForm}
            />
          </section>
        </section>
      </section>
    );
  }
}

UserCreate.propTypes = {};

export default withTranslation("userCreate")(UserCreate);
