import React from "react";
import { useTranslation } from "react-i18next";

const styles = {
  background: "grey",
  color: "white",
  padding: "10px 25px",
  margin: "20px 0px",
  borderRadius: "10px",
};

const Instructions = () => {
  const { t } = useTranslation("instructions");
  return (
    <section style={styles}>
      <p>{t("p1")}</p>
      <p>
        {t("p2")} <strong>{t("userList")}</strong> {t("tabOfThe")}{" "}
        <strong>{t("usersSection")} </strong>
        {t("ofThe")} <strong> {t("commandCenter")} </strong>
      </p>
      <p>
        {t("p3")} <strong> {t("userList")} </strong>
      </p>
      <p>{t("p4")}</p>
      <p>
        {t("p5")} <strong>https://noiz.gg/quests/(QUEST_SLUG)/(USER_ID)</strong>
        .
      </p>
      <p> {t("p6")}</p>
      <p>
        <strong> {t("p7")}</strong>. {t("p8")}
      </p>
    </section>
  );
};

export default Instructions;
