import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import RegionRow from "./RegionRow";
import "./RegionModule.scss";
import { useTranslation } from "react-i18next";

function RegionModule({ regionData }) {
  const { t } = useTranslation("regionModule");
  return (
    <div className="region-module-container">
      <div className="region-module">
        <h2>{t("region")}</h2>
        <TableContainer style={{ maxHeight: "500px" }}>
          <Table stickyHeader className="regionTable">
            <TableHead style={{ border: "none" }}>
              <TableRow style={{ border: "none" }}>
                <TableCell
                  className="regionHeader"
                  style={{ width: "300px", paddingLeft: "32px" }}
                >
                  {t("country")}
                </TableCell>
                <TableCell colSpan={9} className="regionHeader">
                  <span style={{ margin: "0 0 0 10px" }}> {t("reach")} </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {regionData &&
                regionData.map((region, index) => (
                  <RegionRow key={region.id} region={region} index={index} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default RegionModule;
