import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import DevConsole from "../dev-console/DevConsole";
import { konami } from "../../../../utils/util";
import { useTranslation } from "react-i18next";

import "./AdminTabs.scss";

function AdminTabs(props) {
  const [keypresses, setKeypresses] = useState([]);
  const [modal, setModal] = useState(false);
  const { t } = useTranslation("adminTabs");

  const onKeyPress = (e) => {
    const newPresses = [...keypresses, e.key];
    if (newPresses.length > konami.length) {
      newPresses.shift();
    }

    if (newPresses.length === konami.length) {
      const code = newPresses.reduce((acc, curr, index) => {
        if (!acc) {
          return acc;
        }
        return curr === konami[index];
      }, true);

      if (code) {
        setModal(true);
        document.removeEventListener("keydown", onKeyPress, false);
      }
    }

    setKeypresses(newPresses);
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress, false);
    return () => document.removeEventListener("keydown", onKeyPress, false);
  }, []);

  return (
    <section className="ADMIN_tabs">
      {modal && (
        <DevConsole
          modal={modal}
          toggleModal={(val) => setModal(val)}
          {...props}
        />
      )}
      <ul>
        <li>
          <Link
            to="/admin/command-center"
            activeClassName="active"
            href="/admin/command-center"
          >
            {t("commandCenter")}
          </Link>
        </li>
        <li>
          <Link to="/admin/users" activeClassName="active" href="/admin/users">
            {t("users")}
          </Link>
        </li>
        <li>
          <Link to="/admin/games" activeClassName="active" href="/admin/games">
            {t("games")}
          </Link>
        </li>
        <li>
          <Link
            to="/admin/payments"
            activeClassName="active"
            href="/admin/payments"
          >
            {t("payments")}
          </Link>
        </li>
        <li>
          <Link
            to="/admin/insights"
            activeClassName="active"
            href="/admin/insights"
          >
            {t("insights")}
          </Link>
        </li>
        {/* <li>
          <Link
            to="/admin/arbitration"
            activeClassName="active"
            href="/admin/arbitration"> 
           Arbitration
          </Link>
        </li> */}
      </ul>
    </section>
  );
}

export default AdminTabs;
