import React, { useState } from 'react';

import { SearchBarOnly } from "../../../../../global/components/filters/SearchBarAndFilters";
import { AllUsers, LiveUsers, SearchUsers } from './DiscoverStreamers';
import {useTranslation } from 'react-i18next'

import './Discover.scss';

const Discover = () => {
  const [isLive, setIsLive] = useState(false);
  const [search, setSearch] = useState('');
  const [searchResultDisplay, setSearchResultDisplay] = useState(false);
  const { t } = useTranslation('portalLeft')

  const handleSearch = (value) => {
    setSearch(value);

    if (value.length === 0) {
      setSearchResultDisplay(false);
    }
  };

  return (
    <div className="DiscoverStreamers">
      <SearchBarOnly
        disabled={searchResultDisplay}
        search={search}
        setSearch={handleSearch}
        searchButtonFn={() => {
          // Require at least 2 characters before pinging backend
          if (search?.trim().length > 1) {
            setSearchResultDisplay(true);
          }
        }}
      />
      <div className="DiscoverStreamers__Title">{t("discover")}</div>
      {!searchResultDisplay && (
        <div
          className={`DiscoverStreamers__IsLiveFilter ${
            isLive && "LiveEnabled"
          }`}
          onClick={() => setIsLive(!isLive)}
        >
          {t("isLive")}
        </div>
      )}
      {!searchResultDisplay && isLive && <LiveUsers />}
      {!searchResultDisplay && !isLive && <AllUsers />}
      {searchResultDisplay && <SearchUsers search={search} />}
    </div>
  );
};

export default Discover;
