import React from "react";
import { find } from "lodash";
import {
  TableRow,
  TableCell,
  Button,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { LinkTabs } from "../../../tab-table/tableView";
import LinkTable from "../../../tab-table/table";
import RequirementModal from "./RequirementModal";
import { simpleNotification } from "../../../../utils/notifications";
import {
  getQuestRequirements,
  linkify,
  parseLinks,
} from "../../../../utils/functions";
// import { tableMap } from '../../../tab-table/tableHeader';
import "./QuestRequirementsTable.scss";
import moment from "moment-timezone";
import { withTranslation } from "react-i18next";

class QuestRequirementsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      checkboxes: new Array(
        (props.quest.requirements
          ? getQuestRequirements(props.quest, true).length
          : 0) * props.userQuests.length
      ).fill(false),
      currentView: [],
      selected: [],
    };

    // this.tabList = [];

    this.tabSections = ["warning", "completed", "error"];
  }

  notificationModule = (type) => {
    const { t } = this.props;
    simpleNotification({
      level: "warning",
      title: t("warning"),
      message: `${t("warningMessage")} ${type}.`,
    });
  };

  onCheck = (event, value, data) => {
    const { checkboxes } = this.state;
    let { selected } = this.state;

    if (value) selected.push(data);
    else selected = selected.filter((o) => o.rowId !== data.rowId);

    checkboxes[data.rowId] = !checkboxes[data.rowId];

    this.setState({
      selected,
      checkboxes,
    });
  };

  setTab = (n) => {
    this.resetCheckBoxes();

    this.setState({
      tab: n,
      selected: [],
    });
  };

  resetCheckBoxes = () => {
    const { selected, checkboxes } = this.state;

    if (selected.length > 0) {
      selected.forEach((row) => {
        checkboxes[row.rowId] = false;
      });
    }

    this.setState({ selected: [], checkboxes });
  };

  selectAll = () => {
    const { currentView, checkboxes } = this.state;

    this.resetCheckBoxes();
    currentView.forEach((row) => {
      checkboxes[row.rowId] = true;
    });

    this.setState({ selected: currentView, checkboxes });
  };

  currentView = (rows) => {
    const { currentView } = this.state;

    if (currentView.length !== rows.length) {
      this.setState({ currentView: rows });
    } else {
      let same = true;

      const ids = rows.map((r) => r.rowId);
      const currentIds = currentView.map((v) => v.rowId);

      for (let i = 0; i < rows.length && same; i += 1) {
        if (!currentIds.includes(ids[i])) same = false;
      }

      if (!same) this.setState({ currentView: rows });
    }
  };

  isVOD = (requirement) => {
    const isVOD = !!(
      requirement["raw.type"] === "submit-link" &&
      requirement["raw.subtype"] === "vod"
    );
    return isVOD;
  };

  rowMap = (tab, rows) => {
    const { selected } = this.state;
    const selectedUsernames =
      selected.length > 0 ? selected.map((s) => s.username) : [];

    const newRows = rows.map((data) => {
      // Disable multi select across more than 1 user/1 VOD
      const notSelectedUser = !!(
        selectedUsernames.length > 0 && selectedUsernames[0] !== data.username
      );
      const anotherVODSelected = !!(
        this.isVOD(data) &&
        selected &&
        selected.filter((s) => this.isVOD(s) && s["raw.id"] !== data["raw.id"])
          .length > 0
      );

      const disabled = anotherVODSelected || notSelectedUser;

      const checkbox = (
        <TableCell align="left">
          <Checkbox
            align="left"
            checked={this.state.checkboxes[data.rowId]}
            onChange={(e, value) => this.onCheck(e, value, data)}
            disabled={disabled}
          />
        </TableCell>
      );

      let display = null;
      const type = data["raw.type"];
      const { content } = data;

      if (content && (type === "submit-tweet" || type === "submit-link")) {
        display = linkify(content);
      } else if (content && type === "submit-text") {
        display = parseLinks(content);
      } else if (content) {
        display = content;
      }

      const reason = <TableCell align="left">{data.reason}</TableCell>;

      let submissionDate = "";
      if (data.submissionDate) {
        submissionDate = moment(data.submissionDate)
          .tz("America/Los_Angeles")
          .format("MMMM DD, YYYY - hh:mm a");
      }

      let rejectedDate = "";
      if (data.rejectedDate) {
        rejectedDate = moment(data.rejectedDate)
          .tz("America/Los_Angeles")
          .format("MMMM DD, YYYY - hh:mm a");
      }

      let completedDate = "";
      if (data.completedDate) {
        completedDate = moment(data.completedDate)
          .tz("America/Los_Angeles")
          .format("MMMM DD, YYYY - hh:mm a");
      }

      const rejectedDateCell = (
        <TableCell align="left">{rejectedDate}</TableCell>
      );
      const completedDateCell = (
        <TableCell align="left">{completedDate}</TableCell>
      );

      return (
        <TableRow key={data.rowId}>
          {tab === 0 ? checkbox : null}
          <TableCell align="left">{data.username}</TableCell>
          <TableCell align="left">{data.platformName}</TableCell>
          <TableCell align="left">{data.title}</TableCell>
          <TableCell align="left">{display}</TableCell>
          <TableCell align="left">{submissionDate}</TableCell>
          {tab === 1 ? completedDateCell : null}
          {tab === 2 ? rejectedDateCell : null}
          {tab === 2 ? reason : null}
        </TableRow>
      );
    });

    return newRows;
  };

  completeSelected = (reason) => {
    if (this.state.selected.length <= 0) this.notificationModule("approve");
    else this.processRequirements(reason, "completed");
  };

  rejectSelected = (reason, link = null) => {
    if (this.state.selected.length <= 0) this.notificationModule("reject");
    else this.processRequirements(reason, "rejected");
  };

  processRequirements = (reason, status) => {
    const {
      auth: { token },
      postCCRequirementMultiple,
      quest: { id },
    } = this.props;
    const { selected } = this.state;

    const arrayToSend = [];

    for (let i = 0; i < selected.length; i += 1) {
      const select = selected[i];

      const newObj = {};

      newObj.userId = select.userId;
      newObj.content = select.content;
      newObj.id = select["raw.id"];
      newObj.note = reason || "";
      newObj.status = status;
      newObj.type = select["raw.type"];

      if (!newObj.status) {
        newObj.status = "to-review";
      }

      const sendingObj = {
        ...newObj,
        resolution: !reason,
        ...(reason ? { reason } : {}),
      };

      arrayToSend.push(sendingObj);
    }

    const uploadData = JSON.stringify(arrayToSend);

    postCCRequirementMultiple(uploadData, id, token);

    this.resetCheckBoxes();
  };

  render() {
    const { selected, tab, currentView } = this.state;
    const { userQuests, quest, t } = this.props;

    const requirements = getQuestRequirements(quest, true);

    const allUserReqs = [];
    let rowId = -1;

    for (let i = 0; i < userQuests.length; i += 1) {
      const uq = userQuests[i];

      if (uq.status === "approved" || uq.status === "normal") {
        const {
          user: { username, connectedAccounts, id },
        } = uq;
        const platformName = quest.twitchAccountRequired
          ? connectedAccounts.twitch.username
          : "";

        if (
          requirements &&
          requirements.length > 0 &&
          uq.requirements &&
          uq.requirements.length > 0
        ) {
          const userQuestRequirements =
            uq && uq.requirements ? uq.requirements : [];
          // eslint-disable-next-line no-loop-func
          requirements.forEach((r) => {
            rowId += 1;
            let status = null;

            const ur = find(
              userQuestRequirements,
              (o) => o.questReqId === r.id
            );

            if (ur) {
              if (ur.status === "completed") status = "completed";
              else if (ur.status === "rejected") status = "error";
              else status = "warning";

              const { submissionDate, rejectedDate, completedDate, content } =
                ur;

              let title = t("errorFindingTitle");

              if (r.title) title = r.title;
              else if (r.type === "submit-tweet") title = t("twitterLink");
              else if (r.type === "submit-link" && r.subtype === "vod")
                title = t("vodLink");
              else if (r.type === "submit-link" && r.subtype === "clip")
                title = t("clipLink");

              if (status === null) status = "warning";

              const newRow = {
                rowId,
                username,
                platformName,
                title,
                status,
                content: content || "",
                submissionDate,
                rejectedDate,
                completedDate,
                reason: ur && ur.note ? ur.note : null,
                raw: r,
                userId: id,
              };

              allUserReqs.push(newRow);
            }
          });
        }
      }
    }

    const tabList = [
      { name: t("pending"), data: "warning" },
      { name: t("approved"), data: "completed" },
      { name: t("rejected"), data: "error" },
    ];

    const tabData = {
      warning: allUserReqs.filter((req) => req.status === "warning"),
      completed: allUserReqs.filter((req) => req.status === "completed"),
      error: allUserReqs.filter((req) => req.status === "error"),
    };

    const headers = [
      {
        id: "username",
        numeric: false,
        label: "NOIZ",
      },
      {
        id: "platformName",
        numeric: false,
        label: quest.twitchAccountRequired ? "Twitch" : "NA",
      },
      {
        id: "title",
        numeric: false,
        label: t("requirement"),
      },
      {
        id: "content",
        numeric: false,
        label: t("content"),
      },
      {
        id: "submissionDate",
        numeric: false,
        label: t("submissionDate"),
      },
    ];

    const blank = [
      {
        id: "checkbox",
        numeric: false,
        label: "",
      },
    ];

    const tabHeaders = {
      warning: blank.concat(headers),
      completed: headers.concat([
        { id: "completedDate", numeric: false, label: t("approvedDate") },
      ]),
      error: headers.concat([
        { id: "rejectedDate", numeric: false, label: t("rejectedDate") },
        { id: "reason", numeric: false, label: t("reason") },
      ]),
    };

    const tabRows = {
      warning: (rows) => this.rowMap(0, rows),
      completed: (rows) => this.rowMap(1, rows),
      error: (rows) => this.rowMap(2, rows),
    };

    const buttonProps = {
      variant: "contained",
      size: "large",
      ...(selected.length > 0 ? {} : { disabled: true }),
      ...(tab !== 0 ? { disabled: true } : {}),
    };

    return (
      <div>
        <div className="SubmissionButtons">
          <RequirementModal
            title={t("approve")}
            handleSubmit={this.completeSelected}
            selectedCheckboxesLength={selected.length}
            useMaterialUI={Object.assign(
              {},
              buttonProps,
              tab === 2 ? { disabled: true } : {}
            )}
          />
          <RequirementModal
            title={t("reject")}
            handleSubmit={this.rejectSelected}
            selectedCheckboxesLength={selected.length}
            useMaterialUI={Object.assign(
              {},
              buttonProps,
              tab === 2 ? { disabled: true } : {}
            )}
          />
          <Tooltip title={t("unSelectTip")} placement="top">
            <Button {...buttonProps} onClick={this.resetCheckBoxes}>
              {t("clearSelected")}
            </Button>
          </Tooltip>
          <Tooltip title={t("selectAllTip")} placement="top">
            <Button
              {...buttonProps}
              disabled={tab !== 0 || currentView.length <= 0}
              onClick={this.selectAll}
            >
              {t("selectAll")}
            </Button>
          </Tooltip>
        </div>
        <LinkTabs
          setTab={this.setTab}
          tabList={tabList}
          tableData={tabData}
          tableHeaders={tabList}
          headerTemplates={tabHeaders}
          rowTemplates={tabRows}
          Table={LinkTable}
          passUp={this.currentView}
        />
      </div>
    );
  }
}

export default withTranslation("questRequirementsTable")(
  QuestRequirementsTable
);
