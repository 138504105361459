import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { CheckBox } from "./LinkViews";
import { t } from "i18next";

export const waitlistHeaders = [
  {
    id: "username",
    numeric: false,
    label: t("trackedLinks:noiz"),
  },
  {
    id: "email",
    numeric: false,
    label: t("trackedLinks:email"),
  },
  {
    id: "connectedAccounts.twitch.username",
    numeric: false,
    label: t("trackedLinks:twitchUser"),
  },
];

export const usersHeaders = [
  {
    id: "username",
    numeric: false,
    label: t("trackedLinks:noiz"),
  },
  {
    id: "email",
    numeric: false,
    label: t("trackedLinks:email"),
  },
  {
    id: "connectedAccounts.twitch.username",
    numeric: false,
    label: t("trackedLinks:twitchUser"),
  },
];

export const availableHeaders = [
  {
    id: "link",
    numeric: false,
    label: t("trackedLinks:link"),
  },
  {
    id: "createdAt",
    numeric: false,
    label: t("trackedLinks:createdAt"),
  },
  {
    id: "selected",
    numeric: false,
    label: t("trackedLinks:select"),
  },
];

export const distributedHeaders = [
  {
    id: "username",
    numeric: false,
    label: t("trackedLinks:noiz"),
  },
  {
    id: "link",
    numeric: false,
    label: t("trackedLinks:link"),
  },
  {
    id: "maskedLink",
    numeric: false,
    label: t("trackedLinks:maskedLink"),
  },
  {
    id: "clicks",
    numeric: false,
    label: t("trackedLinks:clicks"),
  },
];
// clicks, link, apiResponse.destination, user.username

export const usersMap = (users) =>
  users.map((user) => {
    const { username, email } = user;
    const twitchUser = user["connectedAccounts.twitch.username"]
      ? user["connectedAccounts.twitch.username"]
      : t("trackedLinks:noTwitchAccount");
    return (
      <TableRow key={user.username}>
        <TableCell component="th" scope="row">
          {username}
        </TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">{twitchUser}</TableCell>
      </TableRow>
    );
  });

export const waitlistMap = (users) =>
  users.map((user) => {
    const { username, email } = user;
    const twitchUser = user["connectedAccounts.twitch.username"]
      ? user["connectedAccounts.twitch.username"]
      : t("trackedLinks:noTwitchAccount");
    return (
      <TableRow key={user.username}>
        <TableCell component="th" scope="row">
          {username}
        </TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">{twitchUser}</TableCell>
      </TableRow>
    );
  });

export const availableMap = (links, props) =>
  links.map((l) => {
    const { link, createdAt } = l;
    return (
      <TableRow key={link}>
        <TableCell component="th" scope="row">
          {link}
        </TableCell>
        <TableCell align="left">{createdAt}</TableCell>
        <TableCell align="left">
          <CheckBox item={l} {...props} />
        </TableCell>
      </TableRow>
    );
  });

export const distributedMap = (links, props) =>
  links.map((l) => {
    const { link, clicks, maskedLink, username } = l;
    return (
      <TableRow key={link}>
        <TableCell component="th" scope="row">
          {username}
        </TableCell>
        <TableCell component="th" scope="row">
          {link}
        </TableCell>
        <TableCell component="th" scope="row">
          {maskedLink}
        </TableCell>
        <TableCell component="th" scope="row">
          {clicks}
        </TableCell>
        <TableCell align="left">
          <CheckBox item={l} {...props} />
        </TableCell>
      </TableRow>
    );
  });
