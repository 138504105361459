import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import './BrandServiceSlides.scss';
import anime from 'animejs';
import loserFruitImage from '../assetsV2/featured/thumbnail.png';
import pixArkImage from '../assetsV2/featured/thumbnail-2.png';
import valorantImage from '../assetsV2/featured/thumbnail-3.png';
import likeIcon from '../assetsV2/featured/like-icon.svg';
import viewIcon from '../assetsV2/featured/view-icon.svg';
import LightBox from './LightBox';
import { useTranslation } from "react-i18next";

// import { fadeInTop, fadeInLeft, rotateIn } from './animations/allTransitions';
const testArr = [{
  image: loserFruitImage, title: 'Ark: Survival Evolved', name: 'Loserfruit', views1: '181k', views2: '10k',
}, {
  image: pixArkImage, title: 'PixARK', name: 'MrMEOLA', views1: '56k', views2: '1.7k',
}, {
  image: valorantImage, title: 'TikTok', name: 'itpedia', views1: '1.8 million', views2: '159k',
}];

const KSponsoredContent = (props) => {
  const { t } = useTranslation("brandServices");
  const { pOffset, layoutFactor = 1, offsetAdjust = 0 } = props;
  const ref = React.createRef();
  const onScreen = useOnScreen(ref, '100px', 0.5);

  const [open, setOpen] = React.useState(false);
  const [videoCode, setCode] = React.useState('');

  const [debounce, setDebounce] = React.useState(true);

  const urlCodes = ['QqeWKhjvx6E', 'hpVs_Nokruo', 'vfPCDUuwkcc'];

  useEffect(() => {
    if (onScreen && debounce) {
      anime({
        targets: '.cascade-fade-k',
        opacity: [1, 0],
        translateY: 100,
        duration: 800,
        direction: 'reverse',
        easing: 'linear',
        delay: anime.stagger(200, { direction: 'reverse' }),
      });
      setDebounce(false);
    }
  }, [onScreen, debounce]);

  const handleOpen = (index) => {
    setCode(urlCodes[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        speed={0}
        className="slide-k-container hide-brand-scroll"
      >
        <div ref={ref} />
        <div className="brand-k-title cascade-fade-k fade-up-prep">
          {t("featuredSponsoredContent")}
        </div>

        <div className="brand-k-row-flex">
          {testArr.map((content, i) => (
            <div
              key={i}
              className="featured-content-box-k cascade-fade-k fade-up-prep"
            >
              <div className="featured-content-view">
                <div onClick={() => handleOpen(i)} className="image">
                  <img src={content.image} alt="alt" />
                </div>
                <div className="vert-text">
                  <div className="title">{content.title}</div>
                  <div className="name">{content.name} </div>
                  <div className="stats">
                    <div>
                      <img src={viewIcon} alt="view icon" />
                      &nbsp;&nbsp;{content.views1}
                    </div>
                    <div>
                      <img src={likeIcon} alt="like icon" />
                      &nbsp;&nbsp;{content.views2}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <LightBox
            open={open}
            src={`https://www.youtube.com/embed/${videoCode}`}
            handleClose={handleClose}
          />
        </div>
      </ParallaxLayer>
    </>
  );
};


export default KSponsoredContent;

