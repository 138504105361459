import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Icon from "../../../global/images/icons/Icon";
import { throttle, validateSignForm } from "../../../utils/functions";
import "./PortalSignForm.scss";

const PortalSignForm = (props) => {
  const {
    signIn: signInAction,
    signUp: signUpAction,
    router,
    signInPage,
    auth: {
      errors: { hasError, message },
      isAuthenticated,
      isLoading,
      isUpdating,
      recoverSuccess,
    },
    recover: recoverFn,
  } = props;

  const { t } = useTranslation("portalSignForm");

  const [signIn, setSignIn] = useState(true);
  const [recover, setRecover] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setSignIn(!!signInPage);
  }, []);

  useEffect(() => {
    console.log(hasError, message, "error and message");
  }, [hasError, message]);

  // This section of code in theory shouldn't need to exist but index.js isn't handling the redirect properly
  useEffect(() => {
    if (isAuthenticated) {
      router.push("/portal/");
    }
  }, [isAuthenticated]);
  // End section

  const handleSubmit = (e) => {
    e.preventDefault();

    const throttledFn = throttle(signIn ? signInAction : signUpAction, 1000);
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    const validated = validateSignForm(formProps, signIn);

    if (validated.length === 0) {
      console.log(formProps, "formprops");
      throttledFn({ ...formProps, role: "brand" });
    } else {
      setErrors(validated);
    }
  };

  const handleRecover = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    recoverFn(formProps);
  };

  const SignForm = () => (
    <>
      <div className="PortalSignForm__Header"> {t("welcome")} </div>
      <div className="PortalSignForm__Buttons">
        <div
          className={classnames(
            "SignFormButtons",
            "SignInButton",
            signIn ? "Active-Login" : "Inactive-Login"
          )}
          onClick={() => {
            router.replace("/portal/sign-in");
            setSignIn(true);
            setErrors([]);
          }}
        >
          {t("signIn")}
        </div>
        <div
          className={classnames(
            "SignFormButtons",
            "SignUpButton",
            signIn ? "Inactive-Login" : "Active-Login"
          )}
          onClick={() => {
            router.replace("/portal/sign-up");
            setSignIn(false);
            setErrors([]);
          }}
        >
          {t("signUp")}
        </div>
      </div>
      <form
        className="PortalSignForm__Form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {signIn && (
          <>
            {" "}
            <div className="PortalSignForm__Form_Headers">
              {t("accountName")}
            </div>
            <input
              className={classnames(
                "PortalSignForm__Form_Input",
                errors.includes("identity") ? "Error" : ""
              )}
              type="text"
              name="identity"
              id="identity"
              placeholder={t("placeholder.accountName")}
            />
          </>
        )}
        {!signIn && (
          <>
            {" "}
            <div className="PortalSignForm__Form_Headers">{t("email")}</div>
            <input
              className={classnames(
                "PortalSignForm__Form_Input",
                errors.includes("email") ? "Error" : ""
              )}
              type="text"
              name="email"
              id="email"
              placeholder={t("placeholder.email")}
            />
          </>
        )}
        {!signIn && (
          <>
            {" "}
            <div className="PortalSignForm__Form_Headers">{t("userName")}</div>
            <input
              className={classnames(
                "PortalSignForm__Form_Input",
                errors.includes("username") ? "Error" : ""
              )}
              type="text"
              name="username"
              id="username"
              placeholder={t("placeholder.username")}
            />
          </>
        )}

        <div className="PortalSignForm__Form_Headers">{t("password")}</div>
        <input
          className={classnames(
            "PortalSignForm__Form_Input",
            errors.includes("password") || errors.includes("confirmPassword")
              ? "Error"
              : ""
          )}
          type="password"
          name="password"
          id="password"
          placeholder={t("placeholder.password")}
        />
        {!signIn && (
          <>
            <div className="PortalSignForm__Form_Sub_Headers">
              {t("passwordResult")}
            </div>
            <div className="PortalSignForm__Form_Headers">
              {t("confirmPassword")}
            </div>
            <input
              className={classnames(
                "PortalSignForm__Form_Input",
                errors.includes("confirmPassword") ? "Error" : ""
              )}
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder={t("placeholder.confirmPassword")}
            />
          </>
        )}
        {hasError && message && (
          <div className="PortalSignForm__Form_Error">{message}</div>
        )}
        {!isLoading && !isUpdating && !isAuthenticated && (
          <button className="PortalSignForm__Form_Button" type="submit">
            {signIn ? t("signIn") : t("signUp")}
            {/* SIGN {signIn ? "IN" : "UP"} */}
          </button>
        )}
        {(isLoading || isUpdating) && (
          <div className="PortalSignForm__Form_Button_Spinner">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          </div>
        )}
      </form>
      {signIn && (
        <div
          className="PortalSignForm__Forgot"
          onClick={() => setRecover(true)}
        >
          {t("forgotPassword")}?
        </div>
      )}
    </>
  );

  const RecoverForm = () => (
    <>
      <div className="PortalSignForm__Back" onClick={() => setRecover(false)}>
        {Icon({ color: "#4be0ca", name: "angleleft" })} {t("back")}
      </div>
      <div className="PortalSignForm__Header"> {t("recoverYourAccount")} </div>
      {!recoverSuccess && (
        <form
          className="PortalSignForm__Form"
          onSubmit={handleRecover}
          autoComplete="off"
        >
          <div className="PortalSignForm__Form_Headers">{t("accountName")}</div>
          <input
            className={classnames(
              "PortalSignForm__Form_Input",
              errors.includes("identity") ? "Error" : ""
            )}
            type="text"
            name="identity"
            id="identity"
            placeholder={t("placeholder.accountName")}
          />
          {hasError && message && (
            <div className="PortalSignForm__Form_Error">{message}</div>
          )}
          {(isLoading || isUpdating) && (
            <div className="PortalSignForm__Form_Button_Spinner">
              <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
            </div>
          )}
          {!isLoading && !isUpdating && !recoverSuccess && (
            <button className="PortalSignForm__Form_Button" type="submit">
              {t("send")}
            </button>
          )}
        </form>
      )}
      {recoverSuccess && (
        <div className="PortalSignForm__Form_Success">
          {Icon({ color: "#4be0ca", name: "check" })}
          {t("emailSendingReminder")}
        </div>
      )}
    </>
  );

  return (
    <div className="PortalSignForm__Wrapper">
      <div className="PortalSignForm">
        <div className={classnames("PortalSignForm__Bar", "TopPosition")} />
        <div className={classnames("PortalSignForm__Bar", "BottomPosition")} />
        {!recover && <SignForm />}
        {recover && <RecoverForm />}
      </div>
    </div>
  );
};

export default PortalSignForm;
