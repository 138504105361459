/* eslint-disable import/prefer-default-export */
export const uploadKeys = (multipartFormData, gameId, token) => ({
  type: "UPLOAD_KEYS",
  payload: {
    multipartFormData,
    gameId,
    token,
  },
});

export const getGameKeys = (gameId, token) => ({
  type: "GET_GAME_KEYS",
  payload: {
    gameId,
    token,
  },
});

export const getBountyPredictions = (bountyId, token) => ({
  type: "GET_BOUNTY_PREDICTIONS",
  payload: {
    bountyId,
    token,
  },
});

export const deleteGameKeys = (gameKeyIds, token) => ({
  type: "DELETE_GAME_KEYS",
  payload: {
    gameKeyIds,
    token,
  },
});

export const deleteQuest = (questId, token) => ({
  type: "DELETE_QUEST",
  payload: {
    questId,
    token,
  },
});

export const getWhiteList = (gameId, token) => ({
  type: "GET_WHITE_LIST",
  payload: {
    gameId,
    token,
  },
});

export const toggleUserInWhiteList = (gameId, userId, token) => ({
  type: "TOGGLE_USER_IN_WHITE_LIST",
  payload: {
    gameId,
    userId,
    token,
  },
});

export const createUserAndWhiteListIt = (gameId, username, email, token) => ({
  type: "CREATE_USER_AND_WHITE_LIST_IT",
  payload: {
    gameId,
    username,
    email,
    token,
  },
});

export const findUsers = (query, token) => ({
  type: "FIND_USERS",
  payload: {
    query,
    token,
  },
});

export const getGameActivity = (startDate, endDate, gameName, token) => ({
  type: "GET_GAME_ACTIVITY",
  payload: {
    startDate,
    endDate,
    gameName,
    token,
  },
});

export const getCCGameList = (
  values = { owner: null, skip: null, limit: null, brand: false },
  token
) => ({
  type: "GET_CC_GAME_LIST",
  payload: {
    values,
    token,
  },
});

export const distributeGameKeys = (gameId, token) => ({
  type: "DISTRIBUTE_GAME_KEYS",
  payload: {
    gameId,
    token,
  },
});

export const assignNewKeyPlatforms = (gameId, platformId, keyIds, token) => ({
  type: "ASSIGN_NEW_KEY_PLATFORMS",
  payload: {
    gameId,
    platformId,
    keyIds,
    token,
  },
});

export const getCCQuestList = (token) => ({
  type: "GET_CC_QUEST_LIST",
  payload: {
    token,
  },
});

export const getCCQuest = (questId, token) => ({
  type: "GET_CC_QUEST",
  payload: {
    questId,
    token,
  },
});

export const copyCCQuest = (questId, gameId, token) => ({
  type: "COPY_CC_QUEST",
  payload: {
    questId,
    gameId,
    token,
  },
});

export const createCCQuest = (quest, token) => ({
  type: "CREATE_CC_QUEST",
  payload: {
    quest,
    token,
  },
});

export const updateCCQuest = (questId, quest, token) => ({
  type: "UPDATE_CC_QUEST",
  payload: {
    questId,
    quest,
    token,
  },
});

export const getCCQuestActivity = (questId, token) => ({
  type: "GET_CC_QUEST_ACTIVITY",
  payload: {
    questId,
    token,
  },
});

export const getCCQuestSales = (questId, token) => ({
  type: "GET_CC_QUEST_SALES",
  payload: {
    questId,
    token,
  },
});

export const getCCQuestTimed = (questId, token) => ({
  type: "GET_CC_QUEST_TIMED",
  payload: {
    questId,
    token,
  },
});

export const getCCQuestGeneralCsv = (
  numPage,
  pageSize,
  tab,
  questId,
  filtering,
  type,
  token
) => ({
  type: "GET_CC_QUEST_GENERAL_CSV",
  payload: {
    numPage,
    pageSize,
    tab,
    questId,
    filtering,
    type,
    token,
  },
});

export const getCCQuestGeneral = (
  numPage,
  pageSize,
  tab,
  questId,
  filtering,
  type,
  token
) => ({
  type: "GET_CC_QUEST_GENERAL",
  payload: {
    numPage,
    pageSize,
    tab,
    questId,
    filtering,
    type,
    token,
  },
});

export const getCCQuestAffiliate = (questId, token) => ({
  type: "GET_CC_QUEST_AFFILIATE",
  payload: {
    questId,
    token,
  },
});

export const postCCPreapproval = (
  questId,
  userId,
  status,
  tab,
  numPage,
  pageSize,
  token
) => ({
  type: "POST_CC_PREAPPROVAL",
  payload: {
    questId,
    userId,
    status,
    tab,
    numPage,
    pageSize,
    token,
  },
});

export const postCCRequirement = (data, token) => ({
  type: "POST_CC_REQUIREMENT",
  payload: {
    data,
    token,
  },
});

export const postCCRequirementMultiple = (data, questId, token) => ({
  type: "POST_CC_REQUIREMENT_MULTIPLE",
  payload: {
    data,
    questId,
    token,
  },
});

export const postCCAffiliateResolution = (data, token) => ({
  type: "POST_CC_AFFILIATE_RESOLUTION",
  payload: {
    data,
    token,
  },
});

export const getCCQuestTiered = (questId, token) => ({
  type: "GET_CC_QUEST_TIERED",
  payload: {
    questId,
    token,
  },
});

export const getCCQuestTieredMultipleDays = (questId, token) => ({
  type: "GET_CC_QUEST_TIERED_MULTIPLE_DAYS",
  payload: {
    questId,
    token,
  },
});

export const requestUploadCCImageUrl = (slug, type, token) => ({
  type: "REQUEST_UPLOAD_CC_IMAGE_URL",
  payload: {
    slug,
    type,
    token,
  },
});

export const getCCCampaignListByGame = (gameId, token) => ({
  type: "GET_CC_CAMPAIGN_LIST_BY_GAME",
  payload: {
    gameId,
    token,
  },
});

export const getCCCampaign = (gameId, campaignId, token) => ({
  type: "GET_CC_CAMPAIGN",
  payload: {
    gameId,
    campaignId,
    token,
  },
});

export const createCCCampaign = (campaignData, token) => ({
  type: "CREATE_CC_CAMPAIGN",
  payload: {
    campaignData,
    token,
  },
});

export const updateCCCampaign = (campaignId, campaignData, token) => ({
  type: "UPDATE_CC_CAMPAIGN",
  payload: {
    campaignId,
    campaignData,
    token,
  },
});

export const updateCCGamesPlatform = (gameIds, platformIds, token) => ({
  type: "UPDATE_CC_GAMES_PLATFORM",
  payload: {
    gameIds,
    platformIds,
    token,
  },
});

export const updateCCQuestsPlatform = (questIds, platformIds, token) => ({
  type: "UPDATE_CC_QUESTS_PLATFORM",
  payload: {
    questIds,
    platformIds,
    token,
  },
});

export const addCCUserQuest = (userId, questId, status, token) => ({
  type: "ADD_CC_USER_QUEST",
  payload: {
    userId,
    questId,
    status,
    token,
  },
});

export const removeCCUserQuest = (userId, questId, token) => ({
  type: "REMOVE_CC_USER_QUEST",
  payload: {
    userId,
    questId,
    token,
  },
});

export const moveCCUserQuest = (
  userId,
  fromQuestId,
  toQuestId,
  status,
  token
) => ({
  type: "MOVE_CC_USER_QUEST",
  payload: {
    userId,
    fromQuestId,
    toQuestId,
    status,
    token,
  },
});

export const getCCActiveQuestsByGame = (gameId, token) => ({
  type: "GET_CC_ACTIVE_QUESTS_BY_GAME",
  payload: {
    gameId,
    token,
  },
});

export const getLinksDashboard = (campaignId, token) => ({
  type: "GET_LINKS_DASHBOARD",
  payload: {
    campaignId,
    token,
  },
});

export const uploadLinks = (multipartFormData, campaignId, token) => ({
  type: "UPLOAD_LINKS",
  payload: {
    multipartFormData,
    campaignId,
    token,
  },
});

export const assignMasterLinks = (campaignId, token) => ({
  type: "ASSIGN_MASTER_LINKS",
  payload: {
    campaignId,
    token,
  },
});

export const assignCustomLinks = (campaignId, token) => ({
  type: "ASSIGN_CUSTOM_LINKS",
  payload: {
    campaignId,
    token,
  },
});

export const deleteLinks = (assignedLinks, unassignedLinks, token) => ({
  type: "DELETE_LINKS",
  payload: {
    assignedLinks,
    unassignedLinks,
    token,
  },
});

export const postReputationRating = (
  questId,
  userId,
  reputationObj,
  token
) => ({
  type: "POST_REPUTATION_RATING",
  payload: {
    questId,
    userId,
    reputationObj,
    token,
  },
});
