/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router";
import AdminTabs from "../../util/tabs/AdminTabs";
import AdminGameForm from "../../../forms/AdminGameForm";
import { simpleNotification } from "../../../../utils/notifications";
import "./GameEdit.scss";
import { withTranslation } from "react-i18next";

class GameEdit extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;

    if (gameId) {
      this.props.getAllPlatforms(token);
      this.props.getAGame(gameId, token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    if (
      this.props.admin.game.isLoading === true &&
      nextProps.admin.game.isLoading === false &&
      this.props.admin.game.isUpdating === false &&
      nextProps.admin.game.isUpdating === false &&
      this.props.admin.game.isCreating === false &&
      nextProps.admin.game.isCreating === false
    ) {
      if (!nextProps.admin.game.errors.hasError) {
        this.props.assignGameInfo(nextProps.admin.game.data);
      }
    }

    if (
      this.props.admin.game.isUpdating === true &&
      nextProps.admin.game.isUpdating === false
    ) {
      if (nextProps.admin.game.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("title.errorUpdating"),
          message: nextProps.admin.game.errors.message,
        });
        this.props.assignGameSlugError(nextProps.admin.game.errors.message);
      } else {
        simpleNotification({
          level: "success",
          title: t("title.gameUpdated"),
          message: t("message.gameUpdateSuccess"),
        });
        this.props.router.push("/admin/games");
      }
    }

    if (
      this.props.admin.game.isCreating === true &&
      nextProps.admin.game.isCreating === false
    ) {
      if (nextProps.admin.game.errors.hasError) {
        simpleNotification({
          level: "error",
          title: "Error Creating.",
          message: nextProps.admin.game.errors.message,
        });
        this.props.assignGameSlugError(nextProps.admin.game.errors.message);
      } else {
        simpleNotification({
          level: "success",
          title: t("title.gameCreated"),
          message: t("message.gameCreateSuccess"),
        });
        this.props.router.push("/admin/games");
      }
    }
  }

  onSubmit = (data) => {
    const { gameId } = this.props.router.params;
    const { token } = this.props.auth;

    if (!gameId) {
      this.props.createAGame(data, token);
    } else {
      this.props.updateAGame(gameId, data, token);
    }
  };

  render() {
    const {
      admin: {
        game,
        game: { isLoading },
        platforms: { data: platforms = [] },
        preSignedUrl,
      },
      auth,
      form: { adminGameForm },
      router: {
        params: { gameId },
      },
      t,
      createAPlatform,
      requestUploadImageUrl,
      swapGameGallery,
      updateAdminGameForm,
    } = this.props;

    return (
      <section className="AGameEdit">
        <section className="AGameEdit__wrapper">
          <AdminTabs {...this.props} />
          <section className="AGameEdit__content">
            {gameId ? (
              <h2 className="AGameEdit__title">{t("editGame")}</h2>
            ) : (
              <h2 className="AGameEdit__title">{t("createGame")}</h2>
            )}
            <div className="AGameEdit__actions">
              <Link
                to="/admin/games"
                activeClassName="active"
                href="/admin/games"
              >
                <button>{t("back")}</button>
              </Link>
            </div>
            {isLoading && (
              <section className="AGameEdit__loading">
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
              </section>
            )}
            {!isLoading && (
              <AdminGameForm
                onSubmit={this.onSubmit.bind(this)}
                adminGameForm={adminGameForm}
                platforms={platforms}
                game={game}
                auth={auth}
                createAPlatform={createAPlatform}
                requestUploadImageUrl={requestUploadImageUrl}
                preSignedUrl={preSignedUrl}
                updateAdminGameForm={updateAdminGameForm}
                swapGameGallery={swapGameGallery}
              />
            )}
          </section>
        </section>
      </section>
    );
  }
}

GameEdit.propTypes = {};

export default withTranslation("gameEdit")(GameEdit);
