import React from 'react';
import Home from './home/Home';

const getSubdomain = (host) => {
  const hostWithoutPort = host.split(':')[0];
  return hostWithoutPort.split('.').slice(0, -2).join('.');
};

class DinamicComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  UNSAFE_componentWillMount() {
    const subdomain = getSubdomain(window.location.host);
    if (subdomain === 'brands' || subdomain === 'brands.staging') {
      this.props.router.replace('/brand-services');
    }
  }

  render() {
    return <Home {...this.props} />;
  }
}

export default DinamicComponent;
