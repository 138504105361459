/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router";
import classNames from "classnames";
import CustomInput from "../../utils/redux-forms-custom-fields/CustomInput";
import { throttle } from "../../utils/functions";
import { withTranslation } from "react-i18next";
import { t} from 'i18next'

// const normalize = (phone) => {
//   // normalize string and remove all unnecessary characters
//   const newPhone = phone.replace(/[^\d]/g, '');

//   // check if number length equals to 10
//   if (newPhone.length == 10) {
//     // reformat and return phone number
//     return newPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
//   }

//   return null;
// };

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.throttledSubmit = throttle(this.props.onSubmit, 3000);
    this.classCountry = "firstOptionSelected";
    this.countryError = "";
    this.countryOk = false;
    this.iAgree = false;
    this.state = {
      iAgreeError: false,
      loading: false,
    };
  }

  checkIAgree = () => {
    if (!this.iAgree) {
      this.setState({
        iAgreeError: true,
      });
    } else {
      this.setState({
        iAgreeError: false,
      });
    }
  };

  submit = (userFormInfo) => {
    // this.props.onSubmit(userFormInfo);
    const { role } = this.props;
    this.throttledSubmit({ ...userFormInfo, ...(role ? { role } : {}) });
  };

  render() {
    const { t, handleSubmit } = this.props;

    return (
      <form
        className="form-container signup"
        autoComplete="off"
        onSubmit={handleSubmit(this.submit.bind(this))}
      >
        <Field
          type="text"
          name="email"
          id="email"
          placeholder={t("email")}
          customLabel2={
            <label
              data-label={t("email")}
              data-label-small={t("emailInputPrompt")}
            />
          }
          component={CustomInput}
        />
        <Field
          type="text"
          name="username"
          id="username"
          placeholder={t("username")}
          customLabel2={
            <label
              data-label={t("username")}
              data-label-small={t("usernameInputPrompt")}
            />
          }
          component={CustomInput}
        />
        {this.props.manualTwitch && (
          <Field
            type="text"
            name="twitch"
            id="twitch"
            placeholder={t("twitchUsername")}
            customLabel2={
              <label
                data-label={t("twitchUsername")}
                data-label-small={t("twitchUsernameInputPrompt")}
              />
            }
            component={CustomInput}
          />
        )}
        {this.props.manualTwitch && (
          <Field
            type="text"
            name="phone"
            id="phone"
            placeholder={t("mobileNumber")}
            customLabel2={
              <label
                data-label={t("mobileNumber")}
                data-label-small={t("mobileNumberInputPrompt")}
              />
            }
            component={CustomInput}
          />
        )}
        <Field
          type="password"
          name="password"
          id="password"
          placeholder={t("password")}
          customLabel2={
            <label
              data-label={t("password")}
              data-label-small={t("passwordInputPrompt")}
            />
          }
          component={CustomInput}
        />

        <div className="form__footer grid-x">
          <div className="form__footer__left large-5">
            {this.props.auth && this.props.auth.isLoading && (
              <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
            )}

            {this.props.auth && !this.props.auth.isLoading && (
              <button
                className="cta-button"
                onClick={this.checkIAgree.bind(this)}
              >
                {t("continue")}
              </button>
            )}
          </div>
          <div
            style={{
              marginLeft: "20px",
              textAlign: "left",
              border: "none",
            }}
            className={classNames(
              "form__footer__right",
              "large-7",
              "i_agree_wrap",
              "agree-mobile-margin",
              { i_agree_error: this.state.iAgreeError }
            )}
          >
            <Field
              name="iAgree"
              id="iAgree"
              className="i_agree"
              component={CustomInput}
              type="checkbox"
              onChange={(evt) => {
                this.iAgree = evt.target.checked;
              }}
            />
            {t("text_1")} &nbsp;
            {/* <br /> */}
            <Link
              href="/terms-of-use"
              to="/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("text_2")}
            </Link>{" "}
            {t("text_3")}{" "}
            <Link
              href="/privacy-policy"
              to="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("text_4")}
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const passCheck = (string) => {
    const checkSpecial = /[*@!#%&()^~{}$]+/.test(string);

    const checkUpper = /[A-Z]+/.test(string);

    const checkLower = /[a-z]+/.test(string);

    let r = false;

    if (checkUpper && checkLower && checkSpecial) {
      r = true;
    }

    return r;
  };
  const errors = {};

  if (!values.email || values.email.trim() === "") {
    errors.email = t("sign:validate.required");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = t("sign:validate.emailInvalid");
  }

  if (!values.username || values.username.trim() === "") {
    errors.username = t("sign:validate.required");
  } else if (!values.username.match("^[a-zA-Z0-9_]*$")) {
    errors.username = t("sign:validate.userNameError");
  } else if (values.username.length > 25) {
    errors.username = t("sign:validate.userNameLengthError");
  }

  if (!values.password || values.password.trim() === "") {
    errors.password = t("sign:validate.required");
  } else if (!values.password || values.password.length < 8) {
    errors.password = t("sign:validate.passwordLeast");
  } else if (!values.password || values.password.length > 30) {
    errors.password = t("sign:validate.passwordMax");
  } else if (!values.password || !passCheck(values.password)) {
    errors.password = t("sign:validate.passwordRegExp");
  }

  if (!values.iAgree) {
    errors.iAgree = "!";
  }

  if (!values.twitch) {
    errors.twitch = t("sign:validate.twitch");
  }

  return errors;
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation("signUpForm")(
  reduxForm({
    form: "signUpForm",
    validate,
  })(SignUpForm)
);
