import React from "react";
import { isEmpty } from "lodash";
import "./AdminDashboard.scss";
import { withTranslation } from "react-i18next";

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      gameId: props.params.gameId,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    this.props.getGameBountiesData(gameId, token);
    this.props.getGameLiveStreams(gameId, token);
  }

  generateBountiesHTML() {
    const { bounties } = this.props.admin.reports;
    const { t } = this.props;

    if (bounties.data.length > 0) {
      return bounties.data.map((userBounty) => {
        let grandTotalEstimatedLiveViewers = 0;
        let securedStreamersTotalEstimatedLiveViewers = 0;
        let nonSecuredStreamersTotalEstimatedLiveViewers = 0;

        // Getting the sumatory of secured and non-secured avg_viewers
        userBounty.broadcastersIn.map((item) => {
          const avgViewers =
            item.user && item.user.average_viewers
              ? item.user.average_viewers
              : 0;
          grandTotalEstimatedLiveViewers += avgViewers;
          if (item.secured) {
            securedStreamersTotalEstimatedLiveViewers += avgViewers;
          } else {
            nonSecuredStreamersTotalEstimatedLiveViewers += avgViewers;
          }
          return item;
        });

        const securedStreamers = userBounty.broadcastersIn
          .filter((item) => item.secured)
          .map((item) => {
            const username =
              item.user && item.user.username ? item.user.username : "- - -";
            const followers =
              item.user && item.user.channel && item.user.channel.followers
                ? item.user.channel.followers
                : t("noData");
            const avgViewers =
              item.user && item.user.average_viewers
                ? Math.ceil(item.user.average_viewers)
                : t("noData");
            return (
              <div className="row">
                <div className="table-cell">{username}</div>
                <div className="table-cell">{followers}</div>
                <div className="table-cell">{avgViewers}</div>
              </div>
            );
          });

        const nonSecuredStreamers = userBounty.broadcastersIn
          .filter((item) => !item.secured)
          .map((item) => {
            const username =
              item.user && item.user.username ? item.user.username : "- - -";
            const followers =
              item.user && item.user.channel && item.user.channel.followers
                ? item.user.channel.followers
                : t("noData");
            const avgViewers =
              item.user && item.user.average_viewers
                ? Math.ceil(item.user.average_viewers)
                : t("noData");
            return (
              <div className="row">
                <div className="table-cell">{username}</div>
                <div className="table-cell">{followers}</div>
                <div className="table-cell">{avgViewers}</div>
              </div>
            );
          });
        // const grandTotalEstimatedLiveViewers = userBounty.broadcastersIn.reduce((total, item) => {
        //   const averageViewers = (item.user && item.user.average_viewers) ? item.user.average_viewers : 0;
        //   return total + averageViewers;
        // }, 0);
        // userBounty.broadcastersIn.map((item) => {
        //   const secured = (item.user && item.user.average_viewers) ? item.user.average_viewers : 0;
        //   return total + averageViewers;
        // });
        return (
          <section
            key={userBounty.bounty.id}
            className="AdminDashboard__estimated"
          >
            <h1 className="content-block-header ">
              <label>Raid Day Prediction</label>
              Grand Total Estimated Live Viewers:{" "}
              <span>{Math.ceil(grandTotalEstimatedLiveViewers)}</span>
            </h1>
            <section className="content-block">
              <h4>
                Total <strong>SECURE STREAMER</strong> Estimated Viewers:{" "}
                {Math.ceil(securedStreamersTotalEstimatedLiveViewers)}
              </h4>
              <section className="AdminDashboard__estimated__table secure flex-table">
                <div className="row">
                  <div className="table-cell headline">Username</div>
                  <div className="table-cell headline">Followers</div>
                  <div className="table-cell headline">Avg Viewers</div>
                </div>

                {securedStreamers}
                {!securedStreamers && (
                  <div className="row">
                    <div className="table-cell">
                      There is no secured streamers for this bounty.
                    </div>
                  </div>
                )}
              </section>

              <h4>
                Total <strong>NON-SECURE STREAMER</strong> Estimated Viewers:{" "}
                {Math.ceil(nonSecuredStreamersTotalEstimatedLiveViewers)}
              </h4>
              <section className="AdminDashboard__estimated__table flex-table">
                <div className="row">
                  <div className="table-cell headline">Username</div>
                  <div className="table-cell headline">Followers</div>
                  <div className="table-cell headline">Avg Viewers</div>
                </div>

                {nonSecuredStreamers}
              </section>
            </section>
          </section>
        );
      });
    }
    // If no active games show this message
    return <div className="NoBounties">{t("noBounties")}</div>;
  }

  render() {
    // const { user } = this.props.auth;
    const { gameId } = this.state;
    const { game, data: liveStreaming } = this.props.admin.reports.liveStreams;
    const totalLiveViewers = liveStreaming.reduce((total, stream) => {
      const currentViewers = stream && stream.viewers ? stream.viewers : 0;
      return total + currentViewers;
    }, 0);
    const { t } = this.props;
    return (
      <section className="AdminDashboard">
        {gameId && !isEmpty(game) && (
          <section className="AdminDashboard__current">
            <h2 className="content-block-header">{t("commandCenter")}</h2>
            <section className="content-block">
              <h1>
                {t("currentLiveViewersFor")} {game.name}: {totalLiveViewers}
              </h1>
              <label>
                ({liveStreaming.length} Noiz{" "}
                {liveStreaming.length === 1 ? t("user") : t("users")})
              </label>
            </section>
          </section>
        )}

        {this.generateBountiesHTML()}
      </section>
    );
  }
}

AdminDashboard.propTypes = {};

export default withTranslation("adminDashboard")(AdminDashboard);
