export const instagramUrlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(www\.)?instagram\.com(\/.*)?$/i;

export const youtubeUrlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(www\.)?youtube\.com(\/.*)?$/i;

export const facebookUrlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(www\.)?facebook\.com(\/.*)?$/i;

export const twitterUrlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(www\.)?twitter\.com(\/.*)?$/i;

export const genericUrlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
