import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { MenuItem, Select, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import PopOver from "./PopOver";
import StatusButton from "./StatusButton";

import { localizeIncorrectDate } from "../../utils/dateTime";
import {
  getPaymentAmount,
  rounding,
  textTruncate,
} from "../../utils/functions";

import "./QuestSelector.scss";

const format = "MMM. DD, YYYY";
const dateRange = (start, end) =>
  `${localizeIncorrectDate(start).format(format)} – ${localizeIncorrectDate(
    end
  ).format(format)}`;

const QuestSelector = (props) => {
  const {
    router,
    location,
    currentQuest = {},
    handleApply,
    navStickyBottomPos = false,
    questDates,
    status,
    sticky,
    title,
    user,
    userQuest = {},
    errors: { message = "" },
    userQuestIsLoading = false,
  } = props;

  const { t } = useTranslation("quest_QuestSelector");

  const {
    id: questId = "",
    memberTiers = [],
    platforms = [],
    slug = "",
    viewerTiers = [],
  } = currentQuest;

  const getSlug = (id) => {
    const q = questDates.find((qu) => qu.id === id);
    return q.slug ? q.slug : slug;
  };

  const [dates, setDates] = useState("");
  const [questPlatform, setQuestPlatform] = useState("");

  useEffect(() => {
    setDates(questId.toString());
    setQuestPlatform(platforms);
  }, [currentQuest]);

  const handleDateEvent = (e) => {
    const id = e.target.value;
    if (id !== questId.toString()) {
      const newSlug = getSlug(id);
      window.location.assign(`/quests/${newSlug}`);
    }
  };

  const classes = classNames({
    QuestSelector: true,
    status: !!status,
    sticky,
    stop: !!navStickyBottomPos,
  });

  // const questDateOptions = questDates.map(toMenuItem);
  const ineligibleStatuses = ["accv-min", "no-twitch"];
  const {
    paymentAmount = 0,
    goal = "",
    goalEnabled = false,
  } = getPaymentAmount(
    memberTiers || [],
    user,
    viewerTiers || [],
    userQuest || {}
  );
  const eligible = status
    ? !ineligibleStatuses.includes(status)
    : paymentAmount !== -2;

  return (
    <React.Fragment>
      <div className={classes} style={{ top: navStickyBottomPos || false }}>
        <Tooltip title={title} placement="top">
          <div className="title-quest-text">
            {textTruncate(title, 33, "...")}
          </div>
        </Tooltip>
        <div className="paymentBar">
          <span>
            {eligible && paymentAmount > 0
              ? `$${rounding(paymentAmount)}`
              : paymentAmount === -1
              ? t("freeKey")
              : "--"}
          </span>
        </div>
        {goalEnabled && goal && (
          <div className="quest-goal-message">{goal}</div>
        )}

        <div className="selections">
          <div className="questDates">
            <h3>{t("questDates")}</h3>

            <Select
              className="input-select-corrections"
              id="quest-dates"
              value={dates}
              MenuProps={{
                disableScrollLock: true,
              }}
              placeholder={t("questDates")}
              onChange={handleDateEvent}
            >
              {questDates?.map(
                ({ endDateTime, id, platforms, startDateTime }) => (
                  <MenuItem
                    key={id.toString()}
                    name={startDateTime}
                    value={id.toString()}
                  >
                    {`${dateRange(startDateTime, endDateTime)} (${
                      (!!platforms.length && platforms[0].baseService) ||
                      t("all")
                    })`}
                  </MenuItem>
                )
              )}
            </Select>
          </div>
          {dates && (
            <div className="gamingPlatform">
              <h3>{t("platformOrService")}</h3>
              <h2>
                {(!!questPlatform.length && questPlatform[0].platform) ||
                  t("all")}
              </h2>
            </div>
          )}
        </div>
        {!!message && <div className="quest-rejection-message">{message}</div>}
        <div className="buttonsBarQS">
          <StatusButton
            quest={currentQuest}
            user={user}
            userQuest={userQuest}
            onApply={handleApply}
            userQuestIsLoading={userQuestIsLoading}
            router={router}
            location={location}
          />
          <PopOver />
        </div>
        <a
          className="help-button"
          href="https://noiz.freshdesk.com/support/home"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="fa fa-question-circle" />
          {t("needHelp")}
        </a>
      </div>
    </React.Fragment>
  );
};

export default QuestSelector;
