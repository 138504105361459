import React from "react";

// Styles
import "./RangeFilter.scss";

// Components (external)
import Slider from "@material-ui/core/Slider";

import { numberWithCommas } from "../../../../utils/functions";
import { withTranslation } from "react-i18next";

class RangeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      rangeValue: [0, 100],
    };
  }

  // Set range value when passed down from props
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selected } = nextProps;
    this.setState({
      rangeValue: [selected.min, selected.max],
    });
  }

  // Save to state on change
  handleChange = (event, value) => {
    this.setState({
      rangeValue: value,
    });
  };

  // Pass new range to props on mouse up
  setSelectedRange = () => {
    const { rangeValue } = this.state;
    const minMaxValue = { min: rangeValue[0], max: rangeValue[1] };
    if (this.props.setSelectedRange) this.props.setSelectedRange(minMaxValue);
  };

  render() {
    const { display, selected, range, t } = this.props;
    const { rangeValue } = this.state;

    const rangeMin = range.min ? numberWithCommas(range.min) : 0;
    const rangeMax = range.max ? numberWithCommas(range.max) : 0;

    const selectedMin = selected.min ? numberWithCommas(selected.min) : 0;
    const selectedMax = selected.max ? numberWithCommas(selected.max) : 0;

    return (
      <div
        style={{ display: display ? "block" : "none" }}
        className="RangeFilter"
      >
        <div className="selectedBar">
          {" "}
          {t("rangeSelected")}: {selectedMin} - {selectedMax}{" "}
        </div>
        <div className="selector">
          <div className="selectedNumbers">
            <span>{rangeMin}</span>
            <span>{rangeMax}</span>
          </div>
          <Slider
            min={range.min}
            max={range.max}
            value={rangeValue}
            onChange={this.handleChange}
            onChangeCommitted={this.setSelectedRange}
          />
        </div>
      </div>
    );
  }
}

RangeFilter.propTypes = {};

export default withTranslation("rangeFilter")(RangeFilter);
