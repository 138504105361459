import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import Language from "../language";
import "./MobileMenu.scss";

function MobileMenu({
  authenticated,
  closeMenu,
  fadingOut,
  isBrands,
  isBrandUser,
  isAdmin,
  isClientPortal,
  visible,
}) {
  const Item = ({ linkTo, onlyActiveOnIndex, text }) => (
    <li>
      <Link
        activeClassName="active"
        onClick={closeMenu}
        onlyActiveOnIndex={onlyActiveOnIndex}
        to={linkTo}
      >
        {text}
      </Link>
    </li>
  );
  const { t } = useTranslation("mobileMenu");
  const AboutUs = <Item linkTo="/about-us" text={t("aboutUs")} />;
  const BrandServices = (
    <Item linkTo="/brand-services" text={t("brandServices")} />
  );
  const Contact = (
    <Item linkTo="/brand-services#contact-us" text={t("contact")} />
  );
  const Dashboard = (
    <Item linkTo="/portal?component=default&tab=home" text={t("dashboard")} />
  );
  const FAQ = <Item linkTo="/faqs" text="FAQ" />;
  const Home = <Item linkTo="/" onlyActiveOnIndex text={t("home")} />;
  const Login = <Item linkTo="/sign-in" text={t("login")} />;
  const Logout = <Item linkTo="/logout" text={t("logout")} />;
  const MyAccount = <Item linkTo="/my-account" text={t("myAccount")} />;
  const MyQuests = (
    <Item
      linkTo="portal/?component=default&tab=quests"
      text={t("mySponsorships")}
    />
  );

  const Quests = <Item linkTo="/quests" text={t("noizQuests")} />;
  const Register = <Item linkTo="/sign-up" text={t("register")} />;
  const withFadingOut = (className) =>
    `${className} ${fadingOut ? "fading-out" : ""}`;
  const mainClasses = withFadingOut("main");
  const isBrandOrAdmin = isBrands || isAdmin;
  const NoAuth = (
    <>
      <ul className={mainClasses}>
        {!isClientPortal && Home}
        {!isClientPortal && !isBrands && (
          <>
            {AboutUs}
            {BrandServices}
            {Contact}
          </>
        )}
        <li className="li-language">
          <Language></Language>
        </li>
      </ul>
      <ul className={withFadingOut("bottom")}>
        {!isClientPortal && !isBrands && (
          <>
            {Login}
            {Register}
          </>
        )}
      </ul>
    </>
  );

  const Auth = (
    <>
      <ul className={mainClasses}>
        {isBrandOrAdmin && Dashboard}
        {isBrandOrAdmin && MyQuests}
        {!isBrandUser && Quests}
        {Contact}
        <li className="li-language">
          <Language></Language>
        </li>
      </ul>
      <ul className={withFadingOut("bottom auth")}>
        {!isBrandUser && MyAccount}
        {!isBrandUser && FAQ}
        {Logout}
      </ul>
    </>
  );

  if (!visible) return null;

  return (
    <nav className={withFadingOut("mobile-menu")}>
      {authenticated ? Auth : NoAuth}
    </nav>
  );
}

export default MobileMenu;
