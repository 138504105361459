import React,{useEffect} from 'react'
import './index.scss'

const AppReturn = ({ router}) => { 

  useEffect(() => { 
    if (!/Mobi|Android|iphone/i.test(navigator.userAgent)) {
      router.replace('/404')
    }
  },[])

  return (
    <section className="AppReturn">
      <a href="noiz://">
        <button className="AppReturn_button">Back to App</button>
      </a>
    </section>
  );
}

export default AppReturn