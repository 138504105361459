import React from "react";
import { withTranslation } from "react-i18next";

import "./Faq.scss";
import IconParticles from "../icon-particles/IconParticles";

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <section className="Faq">
        <section className="Faq__container">
          <section className="Hero">
            <IconParticles />
            <section className="Hero__content">
              <h2 className="Hero__title">{t("title")}</h2>
            </section>
          </section>
          <section className="Questions">
            <section className="Questions__content">
              <section className="QuestionsContainer">
                {Array.from({ length: 24 }).map((item, index) => (
                  <section className="QuestionItem">
                    <section className="QuestionItem__icon">
                      <div className="QuestionItem__icon-wrapper">
                        <div className="QuestionItem__decoration">
                          <div className="d1" />
                        </div>
                        <div className="QuestionItem__background-layer" />
                        <div className="QuestionItem__number">
                          {index > 9 ? index + 1 : `0${index + 1}`}
                        </div>
                      </div>
                    </section>
                    <section className="QuestionItem--right">
                      <section className="QuestionItem__question">
                        {t(`question_${index + 1}.title`)}
                      </section>
                      <section className="QuestionItem__answer">
                        {t(`question_${index + 1}.content`)}
                      </section>
                    </section>
                    <div className="QuestionItem--border" />
                  </section>
                ))}
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation("faqs")(FAQ);
