import React, { useEffect } from "react";

import ReactGA from "react-ga";
import { AsYouType } from "libphonenumber-js";
import { useTranslation } from "react-i18next";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { countryNames } from "../../../utils/countryNames";
import { simpleNotification } from "../../../utils/notifications";
import { isProduction } from "../../../utils/functions";
import "./BrandServiceSlides.scss";
import { intercomPostLead } from "../../../utils/apiv2";

const alphaCountries = Object.entries(countryNames).sort((a, b) => {
  const nameA = a[1];
  const nameB = b[1];
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
});

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(email).toLowerCase())) {
    return false;
  }

  if (
    email.includes("@gmail.com") ||
    email.includes("@hotmail.com") ||
    email.includes("@yahoo.com") ||
    email.includes("@ymail.com")
  ) {
    return false;
  }

  return true;
};
const isMobile = window.innerWidth < 700;
const whatAreYouAdvertising = [
  "Game Publisher",
  "Indie Developer",
  "Auto",
  "CPG/Snack & Beverage",
  "CPG Health and Wellness",
  "Retail",
  "Apparel",
  "Consumer Electronics",
  "Peripheral/Gaming Accessories",
  "QSR",
  "Other",
];
const budgetList = [
  "$10,000 - $20,000",
  "$20,000 - $40,000",
  "$40,000 - $100,000",
  "$100,000+",
  "Other",
];
const CustomInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      color: "white !important",
      fontWeight: 400,
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    border: 0,
    borderRadius: 0,
    "& .MuiTextField-root": {
      borderRadius: 0,
      fontSize: 16,
      color: "white !important",
      width: isMobile ? "80vw !important" : "360px !important",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      maxWidth: "80vw",

      marginTop: isMobile ? theme.spacing(1.5) : theme.spacing(3),
      marginBottom: isMobile ? theme.spacing(3) : theme.spacing(3),

      //   paddingTop: 0,
      //   paddingBottom: 0,
    },
    "& .MuiInputBase-input": {
      borderRadius: 0,
      border: 0,
      padding: 10,
      fontSize: 16,
    },
    "& .MuiSelect-root": {
      maxHeight: 58,
      border: 0,
      color: "white !important",
    },
  },
}));

export default function CustomizedInputs() {
  const { t } = useTranslation("brandServices");

  useEffect(() => {
    ReactGA.initialize("UA-116227928-1");
  }, []);

  const classes = useStyles({
    formLabel: {
      color: "#000",
      "&.Mui-focused": {
        color: "#000",
      },
    },
  });
  const [advert, setAdvertising] = React.useState("");
  const [budget, setBudget] = React.useState("");
  const [country, setCountry] = React.useState("US");
  const [company, setCompany] = React.useState("");
  const [product, setProduct] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [hasInteracted, setHasInteracted] = React.useState(false);

  // const isProduction = () => {
  //   return window.location.hostname === 'noiz.gg'
  // }

  const clearForm = () => {
    setAdvertising("");
    setBudget("");
    setCountry("US");
    setCompany("");
    setProduct("");
    setName("");
    setEmail("");
    setPhone("");
  };

  const sendInteractionToGoogle = () => {
    if (!hasInteracted && isProduction()) {
      ReactGA.event({
        category: "form interactions",
        action: "touched_form",
        label: "touched form",
      });
      setHasInteracted(true);
    }
  };

  const sendConversionToGoogle = () => {
    if (isProduction()) {
      ReactGA.event({
        category: "form interactions",
        action: "form_submitted",
        label: "form submitted",
      });
    }
  };

  const numberMask = (number) => {
    if (country) {
      return new AsYouType(country).input(number);
    }
    return new AsYouType("").input(number);
  };

  const submit = async () => {
    if (email && !validateEmail(email)) {
      simpleNotification({
        level: "error",
        title: t("emailRequired"),
        autoDismiss: 5,
        position: "bl",
        message: t("emailRequiredMessage"),
      });
      return null;
    }

    if (name && email) {
      // const formFields = [advert, budget, countryMap[country], company, product, name, email, phone];
      let response;
      try {
        response = await intercomPostLead({
          advert,
          budget,
          country,
          company,
          product,
          name,
          email,
          phone,
        });
      } catch (err) {
        simpleNotification({
          level: "error",
          title: t("somethingWentWrong"),
          autoDismiss: 10,
          position: "bl",
          message: t("somethingWentWrongMessage"),
        });
      }
      sendConversionToGoogle();
      if (response && response.status === 200) {
        simpleNotification({
          level: "success",
          title: t("gotIt"),
          position: "bl",
          autoDismiss: 10,
          message: t("contactSuccessMessage"),
        });
        clearForm();
      }
    } else {
      simpleNotification({
        level: "error",
        title: t("nameAndEmailRequired"),
        autoDismiss: 5,
        position: "bl",
        message: t("nameAndEmailRequiredMessage"),
      });
    }
  };

  const handleChangePhone = (event) => {
    sendInteractionToGoogle();
    setPhone(numberMask(event.target.value));
  };

  const handleChangeEmail = (event) => {
    sendInteractionToGoogle();
    setEmail(event.target.value);
  };

  const handleChangeProduct = (event) => {
    sendInteractionToGoogle();
    setProduct(event.target.value);
  };
  const handleChangeCompany = (event) => {
    sendInteractionToGoogle();
    setCompany(event.target.value);
  };

  const handleChangeName = (event) => {
    sendInteractionToGoogle();
    setName(event.target.value);
  };

  const handleChange = (event) => {
    sendInteractionToGoogle();
    setAdvertising(event.target.value);
  };

  const handleChangeBudget = (event) => {
    sendInteractionToGoogle();
    setBudget(event.target.value);
  };

  const handleChangeCountry = (event) => {
    sendInteractionToGoogle();
    setCountry(event.target.value);
  };

  return (
    <div className="brand-form-control">
      <form
        className={classes.root}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <FormControl className={classes.margin}>
          <div className="contact-form-label">
            {t("contactForm.companyName")}
          </div>
          <CustomInput
            value={company}
            onChange={handleChangeCompany}
            InputProps={{ disableUnderline: true }}
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">
            {t("contactForm.productName")}
          </div>
          <CustomInput
            value={product}
            onChange={handleChangeProduct}
            InputProps={{ disableUnderline: true }}
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">
            {t("contactForm.advertising")}
          </div>
          <TextField
            select
            value={advert}
            onChange={handleChange}
            // variant="outlined"
            InputProps={{ disableUnderline: true }}
          >
            {whatAreYouAdvertising.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">{t("contactForm.budget")}</div>
          <TextField
            select
            value={budget}
            onChange={handleChangeBudget}
            // variant="outlined"
            InputProps={{ disableUnderline: true }}
          >
            {budgetList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">{t("contactForm.name")}</div>
          <CustomInput
            value={name}
            required
            onChange={handleChangeName}
            InputProps={{ disableUnderline: true }}
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">{t("contactForm.email")}</div>
          <CustomInput
            required
            error={!email}
            value={email}
            onChange={handleChangeEmail}
            InputProps={{ disableUnderline: true }}
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">{t("contactForm.country")}</div>
          <TextField
            select
            value={country}
            onChange={handleChangeCountry}
            InputProps={{ disableUnderline: true }}
          >
            {alphaCountries.map((option) => {
              const [code, name] = option;
              return (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">{t("contactForm.phone")}</div>
          <CustomInput
            onChange={handleChangePhone}
            value={phone}
            // mask={numberMask}
            InputProps={{ disableUnderline: true }}
          />
        </FormControl>
      </form>
      <div>
        <button onClick={submit} className="brand-rec-button">
          {t("submit")}
        </button>
      </div>
    </div>
  );
}
