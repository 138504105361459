/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */
import React from "react";
// import { Link } from 'react-router';
import classNames from "classnames";
import { Field, FieldArray, reduxForm, change } from "redux-form";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  find,
  isBoolean,
  isEqual,
  isObject,
  isNumber,
  keys,
  omit,
  sortBy,
  toNumber,
  // forEach,
} from "lodash";
import { CountryRegionData } from "react-country-region-selector";
import languages from "languages";
import JSONPretty from "react-json-pretty";
import Dropzone from "react-dropzone";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";

import "react-json-pretty/themes/acai.css";
import RichText from "../../utils/RichText";
import {
  differenceObject,
  // dynamicSort,
  friendlyURICharacters,
  getDefaultIcon,
  getQuestDurationHours,
  preloadRequirement,
  replaceSpaces,
} from "../../utils/functions";
import { simpleNotification } from "../../utils/notifications";

import ArrayDropDown from "../../utils/redux-forms-custom-fields/ArrayDropDown";
import CustomDateTimePicker from "../../utils/redux-forms-custom-fields/CustomDateTimePicker";
import CustomDropDownArray from "../../utils/redux-forms-custom-fields/CustomDropDownArray";
import CustomDropDownGeneric from "../../utils/redux-forms-custom-fields/CustomDropDownGeneric";
import CustomInput from "../../utils/redux-forms-custom-fields/CustomInput";
import CustomInputCheckbox from "../../utils/redux-forms-custom-fields/CustomInputCheckbox";
import CustomTagField from "../../utils/redux-forms-custom-fields/CustomTagField";
import TiersWhitelist from "./QuestForm/TiersWhitelist";
import { arrayDropDownColors } from "../../utils/constants";
import "./skin.min.css";
import { ReactComponent as AdminBannerIcon } from "../../components/images/requirement-icons/admin/banner-icon.svg";
import { ReactComponent as AdminBuildStreamIcon } from "../../components/images/requirement-icons/admin/build-stream.svg";
import { ReactComponent as AdminGameDirectoryIcon } from "../../components/images/requirement-icons/admin/game-directory-icon.svg";
import { ReactComponent as AdminGameKeyIcon } from "../../components/images/requirement-icons/admin/game-key-icon.svg";
import { ReactComponent as AdminHashtagIcon } from "../../components/images/requirement-icons/admin/hashtag-icon.svg";
import { ReactComponent as AdminCheckboxIcon } from "../../components/images/requirement-icons/admin/checkbox-icon.svg";
import { ReactComponent as AdminAchievementIcon } from "../../components/images/requirement-icons/admin/achievement-icon.svg";
import { ReactComponent as AdminClipIcon } from "../../components/images/requirement-icons/admin/clip-icon.svg";
import { ReactComponent as AdminTextIcon } from "../../components/images/requirement-icons/admin/text-icon.svg";
import { ReactComponent as AdminLinkIcon } from "../../components/images/requirement-icons/admin/link-icon.svg";
import { ReactComponent as AdminStreamIcon } from "../../components/images/requirement-icons/admin/stream-icon.svg";
import { ReactComponent as AdminTwitterIcon } from "../../components/images/requirement-icons/admin/twitter-icon.svg";
import { ReactComponent as AdminVerificationIcon } from "../../components/images/requirement-icons/admin/verification-icon.svg";
import closeButton from "../../components/images/close-button.svg";
import RequiredGames from "./QuestForm/RequiredGames";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const dateStoreFormat = "YYYY-MM-DD HH:mm";
const dateDisplayFormat = "MM-DD-YYYY HH:mm";

class QuestForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.faqs = [];
    this.state = {
      activeModal: 0,
      addRequirementModal: false,
      dropzoneActive: false,
      file: "",
      image: "",
      indexIcon: false,
      logsModal: 0,
      modalType: "",
      news: [],
      olds: [],
      parametersSubTab: 0,
      pointInQuest: false,
      pointInQuestIcon: false,
      prevGamesPlayed: [],
      subTab: "landing",
      tab: "details",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.preSignedUrl.isLoading === true &&
      nextProps.preSignedUrl.isLoading === false
    ) {
      const { data: signedUrl } = nextProps.preSignedUrl;
      this.uploadToS3(this.state.file, signedUrl.preSignedUrl);
    }

    if (
      this.props.quest &&
      this.props.quest.isCreating === true &&
      nextProps.quest.isCreating === false
    ) {
      if (nextProps.quest.errors.hasError) {
        if (nextProps.quest.errors.code === "E_QUEST_SLUG_DUPLICATED") {
          this.setState({ tab: "user-info" });
          this.scrollToSlug();
        }
      }
    }

    if (
      this.props.quest &&
      this.props.quest.isUpdating === true &&
      nextProps.quest.isUpdating === false
    ) {
      if (nextProps.quest.errors.hasError) {
        if (nextProps.quest.errors.code === "E_QUEST_SLUG_DUPLICATED") {
          this.setState({ tab: "user-info" });
          this.scrollToSlug();
        }
      }
    }

    if (this.props.isLoading && !nextProps.isLoading && nextProps.quest) {
      this.setState({ prevGamesPlayed: nextProps.quest.prevGamesPlayed || [] });
    }
  }

  getQuest = (quest) => {
    if (quest.comingSoon) return;

    const {
      auth: { token },
      getQuest,
    } = this.props;

    getQuest(quest.id, true, token);
  };

  onDragEnter() {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave() {
    this.setState({ dropzoneActive: false });
  }

  onDrop(files, e) {
    const file = files && files.length ? files[0] : e[0];
    this.setState({
      dropzoneActive: false,
      file,
    });
    const { image } = this.state;
    const format = file.name.split(".")[1];
    const {
      auth: { token },
      requestUploadCCImageUrl,
    } = this.props;

    if (image === "banner") {
      requestUploadCCImageUrl("banner", format, token);
    } else if (image === "regular") {
      requestUploadCCImageUrl("regular", format, token);
    } else if (image === "overlay") {
      requestUploadCCImageUrl("overlay", format, token);
    } else if (image === "icon") {
      requestUploadCCImageUrl("icon", format, token);
    }

    this.setState({ activeModal: 2 });
  }

  getStepsTypes = (questIsAffiliate, requiredPreapproval) => {
    const { t } = this.props;
    let stepTypeOptions = [
      {
        label: t("banner"),
        value: "display-download-banner",
        type: "display-download",
        subtype: "banner",
      },
      {
        label: t("buildStream"),
        value: "display-text-build-stream",
        type: "display-text",
        subtype: "build-stream",
      },
      {
        label: t("clip"),
        value: "submit-link-clip",
        type: "submit-link",
        subtype: "clip",
      },
      {
        label: t("checkbox"),
        value: "display-text-checkbox",
        type: "display-text",
        subtype: "checkbox",
      },
      {
        label: t("gameDirectory"),
        value: "display-text-game-name",
        type: "display-text",
        subtype: "game-name",
      },
      {
        label: t("gameKey"),
        value: "distribute-key",
        type: "distribute-key",
        subtype: false,
      },
      {
        label: t("hashtagQuest"),
        value: "display-hashtags-quest",
        type: "display-hashtags",
        subtype: "quest",
      },
      {
        label: t("hashtagTracking"),
        value: "display-hashtags-tracking",
        type: "display-hashtags",
        subtype: "tracking",
      },
      {
        label: t("inGameAchievement"),
        value: "display-text-achievement",
        type: "display-text",
        subtype: "achievement",
      },
      {
        label: t("linkAffiliate"),
        value: "distribute-link-affiliate",
        type: "distribute-link",
        subtype: "affiliate",
      },
      {
        label: t("linkCampaign"),
        value: "distribute-link-campaign",
        type: "distribute-link",
        subtype: "campaign",
      },
      {
        label: t("overlay"),
        value: "display-download-overlay",
        type: "display-download",
        subtype: "overlay",
      },
      {
        label: t("streamTracking"),
        value: "display-stream-time",
        type: "display-stream-time",
        subtype: false,
      },
      {
        label: t("submitStreamSchedule"),
        value: "submit-schedule",
        type: "submit-text",
        subtype: "schedule",
      },
      {
        label: t("textGeneral"),
        value: "display-text",
        type: "display-text",
        subtype: false,
      },
      {
        label: t("tweet"),
        value: "submit-tweet",
        type: "submit-tweet",
        subtype: false,
      },
      {
        label: t("verification"),
        value: "submit-text",
        type: "submit-text",
        subtype: false,
      },
      {
        label: t("vod"),
        value: "submit-link-vod",
        type: "submit-link",
        subtype: "vod",
      },
      {
        label: t("submitLink"),
        value: "submit-link",
        type: "submit-link",
        subtype: false,
      },
    ];
    /* START - Only Affiliate quest steps */
    if (questIsAffiliate) {
      const affiliateStepTypeOptions = [
        { label: "Instagram", value: "instagram" },
        { label: "Facebook", value: "facebook" },
        { label: "Twitter", value: "twitter" },
        { label: "Youtube", value: "youtube" },
      ];
      stepTypeOptions = [...stepTypeOptions, ...affiliateStepTypeOptions];
    }
    stepTypeOptions = sortBy(stepTypeOptions, ["label"]);
    /* END - Only Affiliate quest steps */
    return stepTypeOptions;
  };

  scrollToSlug = () => {
    this.slugE.scrollIntoView({ behavior: "smooth" });
  };

  changeModalState = (value) => {
    this.setState({ activeModal: value });
  };

  submit = (questFormInformation) => {
    const { game, onSubmit } = this.props;
    const { prevGamesPlayed } = this.state;
    const questFormInfo = { ...questFormInformation };
    const requiredDateTimeStart = moment(
      questFormInfo.requiredStartDateTime,
      "YYYY-MM-DD"
    );
    const requiredDateTimeEnd = moment(
      questFormInfo.requiredEndDateTime,
      "YYYY-MM-DD"
    );
    const range = requiredDateTimeEnd.diff(requiredDateTimeStart, "minutes");
    questFormInfo.requiredStreamRangeTime = range;
    questFormInfo.requiredStreamTimeHours = 24;
    if (questFormInfo.requiredStreamTimeMinutes) {
      questFormInfo.requiredStreamTimeMinutes = parseInt(
        questFormInfo.requiredStreamTimeMinutes,
        10
      );
    }

    if (questFormInfo.paymentAmount) {
      questFormInfo.paymentAmount = parseInt(questFormInfo.paymentAmount, 10);
    }

    if (questFormInfo.dailyEarning) {
      questFormInfo.dailyEarning = parseInt(questFormInfo.dailyEarning, 10);
    }

    if (questFormInfo.viewerTiers && questFormInfo.viewerTiers.length > 0) {
      for (let i = 0; i < questFormInfo.viewerTiers.length; i += 1) {
        questFormInfo.viewerTiers[i].spotsAvailable = parseInt(
          questFormInfo.viewerTiers[i].spotsAvailable,
          10
        );
        questFormInfo.viewerTiers[i].minimumAverageViewers = parseInt(
          questFormInfo.viewerTiers[i].minimumAverageViewers,
          10
        );
        questFormInfo.viewerTiers[i].paymentAmount = parseFloat(
          questFormInfo.viewerTiers[i].paymentAmount,
          10
        );
      }
    }

    if (questFormInfo.memberTiers && questFormInfo.memberTiers.length > 0) {
      for (let k = 0; k < questFormInfo.memberTiers.length; k += 1) {
        const { identity, paymentAmount } = questFormInfo.memberTiers[k];
        questFormInfo.memberTiers[k] = {
          identity: identity.trim(),
          paymentAmount: parseFloat(paymentAmount, 10),
        };
      }
    }

    if (
      questFormInfo.type === "tiered-one-time" ||
      questFormInfo.type === "timed"
    ) {
      questFormInfo.frequency = "one-time";
    }

    if (
      questFormInfo.type === "tiered-multi-days" ||
      questFormInfo.type === "paid bounty"
    ) {
      questFormInfo.frequency = "daily";
    }

    questFormInfo.group = questFormInfo.group ? questFormInfo.group : "";

    if (questFormInfo.campaigns && questFormInfo.campaigns.length > 0) {
      questFormInfo.campaigns = questFormInfo.campaigns.map((q) => q.value);
    }

    if (questFormInfo.requirements) {
      if (questFormInfo.requirements.beforeStream) {
        for (
          let i = 0;
          i < questFormInfo.requirements.beforeStream.length;
          i += 1
        ) {
          const req = questFormInfo.requirements.beforeStream[i];
          delete questFormInfo.requirements.beforeStream[i].value;
          if (
            !req.customIcon ||
            req.icon ===
              getDefaultIcon(req.type, req.subtype, questFormInformation, game)
          ) {
            questFormInfo.requirements.beforeStream[i].icon = false;
          }

          if (
            req.type === "display-hashtags" ||
            req.type === "display-stream-time" ||
            (req.type === "display-text" && req.subtype === "game-name")
          ) {
            questFormInfo.requirements.beforeStream[i].title = "";
          }
        }
      }

      if (questFormInfo.requirements.duringStream) {
        for (
          let i = 0;
          i < questFormInfo.requirements.duringStream.length;
          i += 1
        ) {
          const req = questFormInfo.requirements.duringStream[i];
          delete questFormInfo.requirements.duringStream[i].value;
          if (
            req.icon ===
            getDefaultIcon(req.type, req.subtype, questFormInformation, game)
          ) {
            questFormInfo.requirements.duringStream[i].icon = false;
          }

          if (
            req.type === "display-hashtags" ||
            req.type === "display-stream-time" ||
            (req.type === "display-text" && req.subtype === "game-name")
          ) {
            questFormInfo.requirements.duringStream[i].title = "";
          }
        }
      }

      if (questFormInfo.requirements.afterStream) {
        for (
          let i = 0;
          i < questFormInfo.requirements.afterStream.length;
          i += 1
        ) {
          const req = questFormInfo.requirements.afterStream[i];
          delete questFormInfo.requirements.afterStream[i].value;
          if (
            req.icon ===
            getDefaultIcon(req.type, req.subtype, questFormInformation, game)
          ) {
            questFormInfo.requirements.afterStream[i].icon = false;
          }

          if (
            req.type === "display-hashtags" ||
            req.type === "display-stream-time" ||
            (req.type === "display-text" && req.subtype === "game-name")
          ) {
            questFormInfo.requirements.afterStream[i].title = "";
          }
        }
      }
    }

    if (questFormInfo.countries && questFormInfo.countries.length > 0) {
      questFormInfo.countries = questFormInfo.countries.map((c) => c.value);
    }

    if (questFormInfo.languages && questFormInfo.languages.length > 0) {
      questFormInfo.languages = questFormInfo.languages.map((l) => l.value);
    }

    if (questFormInfo.platforms) {
      questFormInfo.platforms = [questFormInfo.platforms.value];
    }

    questFormInfo.prevGamesPlayed = prevGamesPlayed.sort();

    onSubmit(questFormInfo);
  };

  swapStep = (kind, index, direction) => {
    const swapIndex = direction === "up" ? index - 1 : index + 1;
    const { questForm, swapQuestStep } = this.props;
    let arr;
    if (kind === "additional-questions") {
      arr = questForm.values.stepsPage1;
    }

    [arr[index], arr[swapIndex]] = [arr[swapIndex], arr[index]];
    swapQuestStep(kind, arr);
  };

  swapRequirement = (pointInQuest, index, direction) => {
    const swapIndex = direction === "up" ? index - 1 : index + 1;
    const { questForm, swapRequirements } = this.props;
    const arr = questForm.values.requirements[pointInQuest];
    [arr[index], arr[swapIndex]] = [arr[swapIndex], arr[index]];
    const newRequirements = questForm.values.requirements;
    newRequirements[pointInQuest] = arr;
    swapRequirements(newRequirements);
  };

  uploadBanner = () => {
    this.setState({
      activeModal: 1,
      image: "banner",
    });
  };

  uploadRegularImage = () => {
    this.setState({
      activeModal: 1,
      image: "regular",
    });
  };

  uploadOverlayImage = () => {
    this.setState({
      activeModal: 1,
      image: "overlay",
    });
  };

  uploadIcon = (pointInQuest, index) => {
    this.setState({
      activeModal: 1,
      image: "icon",
      pointInQuestIcon: pointInQuest,
      indexIcon: index,
    });
  };

  removeIcon = (pointInQuest, index) => {
    this.props.removeIcon({ pointInQuest, index });
  };

  uploadToS3 = (file, preSignedUrl) => {
    const { t } = this.props;
    const { image } = this.state;
    if (
      (image === "banner" || image === "regular" || image === "overlay") &&
      file.size > 500000
    ) {
      if (file.size > 500000) {
        simpleNotification({
          level: "warning",
          title: t("warning"),
          message: t("imageSize"),
        });
        this.setState({
          activeModal: 1,
        });
        return;
      }
    }
    this.setState({ activeModal: 2 });
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", preSignedUrl);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.addEventListener("load", this.handleLoad);
    xhr.send(file);
  };

  getTabClasses = (tab) => {
    const { questForm } = this.props;
    if (!questForm) return "";

    const { syncErrors, submitFailed } = questForm;
    if (!syncErrors || !submitFailed) return "";

    const errorClass = "fa fa-times-circle red";

    if (tab === "details") {
      if (
        syncErrors.type ||
        syncErrors.title ||
        syncErrors.status ||
        syncErrors.isPrivate ||
        syncErrors.openSpots ||
        syncErrors.startDateTime ||
        syncErrors.endDateTime ||
        syncErrors.openRegistrationDate ||
        syncErrors.closeRegistrationDate ||
        syncErrors.expectedPayoutDate ||
        syncErrors.group ||
        syncErrors.minimumAverageViewers ||
        syncErrors.requiredBanner ||
        syncErrors.bannerLink
      ) {
        return errorClass;
      }
    }

    if (tab === "parameters") {
      if (
        syncErrors.isReferral ||
        syncErrors.referralBonus ||
        syncErrors.salesForBonus ||
        syncErrors.bonusValue ||
        syncErrors.referralBonusType ||
        syncErrors.referralBonusTimes ||
        syncErrors.comingSoon ||
        syncErrors.keyRequired ||
        syncErrors.userCanleave ||
        syncErrors.plus18 ||
        syncErrors.tags ||
        syncErrors.paymentMade ||
        syncErrors.hideOpenSpots ||
        syncErrors.requirePreapproval ||
        syncErrors.twitterAccountRequired ||
        syncErrors.joinButtonText ||
        syncErrors.linkout ||
        syncErrors.referralPaymentType ||
        syncErrors.commissionAmount ||
        syncErrors.commissionPercentage ||
        syncErrors.minimumAverageViewersReferral ||
        syncErrors.requiredStreamTimeMinutes ||
        syncErrors.questTags ||
        syncErrors.dailyEarning ||
        syncErrors.paymentAmount ||
        syncErrors.memberTiers ||
        syncErrors.viewerTiers ||
        syncErrors.whitelist ||
        syncErrors.platforms ||
        syncErrors.countries ||
        syncErrors.languages
      ) {
        return errorClass;
      }
    }

    if (tab === "user-info") {
      if (syncErrors.slug || syncErrors.stepsPage1 || syncErrors.requirements) {
        return errorClass;
      }
    }

    if (tab === "faq") {
      if (syncErrors.faqs) {
        return errorClass;
      }
    }

    // Sub-tabs
    if (
      (tab === "viewerTiers" ||
        tab === "memberTiers" ||
        tab === "whitelist" ||
        tab === "slug" ||
        tab === "stepsPage1" ||
        tab === "requirements") &&
      syncErrors[tab]
    ) {
      return errorClass;
    }

    return "";
  };

  campaignsOptions = () =>
    this.props.campaigns.map(({ id, name }) => ({
      value: id,
      label: <span style={{ zIndex: "1000" }}>{name}</span>,
    }));

  countriesOptions = () => {
    const { t } = this.props;
    return [
      {
        value: "Anywhere",
        label: <span style={{ zIndex: "1000" }}>{t("anywhere")}</span>,
      },
      ...CountryRegionData.map((c) => ({
        value: c[0],
        label: <span style={{ zIndex: "1000" }}>{c[0]}</span>,
      })),
    ];
  };

  languagesOptions = () => {
    const { t } = this.props;
    const langs = languages.getAllLanguageCode().reduce((acc, langCode) => {
      const landDesc = languages.getLanguageInfo(langCode);
      acc[landDesc.name] = langCode;
      return acc;
    }, {});

    const final = Object.keys(langs)
      .sort()
      .map((name) => ({
        value: langs[name],
        label: <span style={{ zIndex: "1000" }}>{name}</span>,
      }));

    return [
      {
        value: "any",
        label: <span style={{ zIndex: "1000" }}>{t("anyLanguage")}</span>,
      },
      ...final,
    ];
  };

  handleLoad = (event) => {
    const {
      preSignedUrl: { data: signedUrl },
      updateCCQuestForm,
    } = this.props;
    const { image, pointInQuestIcon, indexIcon } = this.state;

    const { t } = this.props;

    if (event.target.status !== 200) {
      this.setState({
        activeModal: 0,
      });
      simpleNotification({
        level: "error",
        title: t("error"),
        message: t("errorMessage"),
      });
      return;
    }

    simpleNotification({
      level: "success",
      title: t("success"),
      message: t("successMessage"),
    });

    if (image === "banner") {
      updateCCQuestForm({ requiredBanner: signedUrl.url });
    } else if (image === "regular") {
      updateCCQuestForm({ regularImage: signedUrl.url });
    } else if (image === "overlay") {
      updateCCQuestForm({ overlayImage: signedUrl.url });
    } else if (image === "icon") {
      updateCCQuestForm({
        icon: signedUrl.url,
        pointInQuest: pointInQuestIcon,
        index: indexIcon,
      });
    }

    this.setState({ activeModal: 0 });
  };

  addRequirement(fields, req) {
    this.toggleAddRequirementModal();
    const { questForm, game, quest = {} } = this.props;

    // Get preload requirement stuff

    const preloadedReq = preloadRequirement(
      req.type,
      req.subtype,
      questForm.values,
      game,
      quest
    );

    fields.push({
      type: req.type,
      subtype: req.subtype,
      title: preloadedReq.title,
      description: preloadedReq.description || "",
      label: preloadedReq.label,
      placeholder: preloadedReq.placeholder,
      tooltip: preloadedReq.tooltip,
      approvalRequired: preloadedReq.approvalRequired,
      id: uuidv4().replace(/-/g, ""),
      icon: preloadedReq.icon,
      customIcon: false,
      value: req.value,
    });

    return fields;
  }

  getStepReadOnly = (req) => {
    if (["display-hashtags", "display-stream-time"].includes(req.type)) {
      return true;
    } else if (req.type === "display-text" && req.subtype === "game-name") {
      return true;
    }
    return false;
  };

  renderStepsGeneric = ({ fields, meta: { error, submitFailed }, kind }) => {
    const { t } = this.props;
    return (
      <section className="Step">
        <ul>
          {fields.map((step, index) => (
            <li key={index}>
              <section className="Step__header">
                <button
                  className="Step__item-remove"
                  type="button"
                  onClick={() => {
                    if (window.confirm(t("removingField"))) {
                      fields.remove(index);
                    }
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              </section>
              <section className="Step__body">
                <section className="Step__fields">
                  <div className="Step__row Step__row--2">
                    <Field
                      name={`${step}.title`}
                      id={`${step}.title`}
                      type="text"
                      component={CustomInput}
                      label={t("title")}
                      placeholder={t("title")}
                      customLabel={t("title")}
                    />
                    <Field
                      name={`${step}.isRequired`}
                      id={`${step}.isRequired`}
                      type="checkbox"
                      placeholder={t("isRequired")}
                      customLabel={t("isRequired")}
                      component={CustomInputCheckbox}
                      value
                    />
                  </div>
                  <Field
                    type="text"
                    name={`${step}.description`}
                    id={`${step}.description`}
                    placeholder={t("descriptionPlaceholder")}
                    customLabel={t("description")}
                    component={CustomInput}
                    // handleChange={content => this.handleRichTextUpdates(content, `${step}.description`)}
                  />
                  {/* <RichText
                    initialValue={fieldValues[index].description}
                    callback={content => this.handleRichTextUpdates(content, textEditorQueryString)}
                  /> */}
                </section>
                <section className="Step__arrows">
                  <div
                    className="Step__arrow--up"
                    style={{ display: index !== 0 ? "block" : "none" }}
                    onClick={this.swapStep.bind(this, kind, index, "up")}
                    aria-hidden
                  >
                    <i className="fa fa-arrow-up" />
                  </div>
                  <div
                    className="Step__arrow--down"
                    style={{
                      display: fields.length - 1 !== index ? "block" : "none",
                    }}
                    onClick={this.swapStep.bind(this, kind, index, "down")}
                    aria-hidden
                  >
                    <i className="fa fa-arrow-down" />
                  </div>
                </section>
              </section>
            </li>
          ))}
        </ul>
        {submitFailed && error && <span className="Step__error">{error}</span>}
        <button
          className="Step__add-item"
          type="button"
          onClick={() =>
            fields.push({
              description: null,
              isRequired: true,
              title: null,
              type: "collect-user-information",
            })
          }
        >
          {t("addAdditionalInformation")}
        </button>
      </section>
    );
  };

  /* START REQUIREMENTS */
  renderRequirements = ({
    fields,
    meta: { error, submitFailed },
    stepTypeOptions,
    pointInQuest,
  }) => {
    const fieldValues = fields.getAll();
    const { game, questForm } = this.props;
    console.log(fieldValues, "fieldvalues");
    const { t } = this.props;
    return (
      <section className="Step">
        <ul>
          {fields.map((step, index) => (
            <li key={index}>
              <section className="Step__header">
                <button
                  className="Step__item-remove"
                  type="button"
                  id={pointInQuest}
                  onClick={() => {
                    fields.remove(index);
                  }}
                >
                  {t("removeRequirement")}
                </button>
                <h4 className="Step__title">
                  {t("requirement")} #{index + 1}
                </h4>
              </section>
              <section className="Step__body">
                <section className="Step__fields">
                  <div className="Step__row">
                    <div className="input-container" style={{ width: "20%" }}>
                      <label>{t("requirementType")}</label>
                      <span style={{ fontSize: "16px", marginTop: "8px" }}>
                        {
                          stepTypeOptions.find(
                            (o) =>
                              o.type === fieldValues[index].type &&
                              o.subtype === fieldValues[index].subtype
                          )?.label
                        }
                      </span>
                    </div>
                    <div className="input-container" style={{ width: "75%" }}>
                      <Field
                        name={`${step}.title`}
                        id={`${step}.title`}
                        type="text"
                        component={CustomInput}
                        label={t("title")}
                        placeholder={t("title")}
                        readOnly={this.getStepReadOnly(fields.get(index))}
                        customLabel={t("title")}
                      />
                    </div>
                    <div
                      style={{
                        display:
                          questForm.values &&
                          questForm.values.requirementError === true
                            ? "block"
                            : "none",
                      }}
                    />
                  </div>
                  <div className="Step__row">
                    <div className="input-container" style={{ width: "25%" }}>
                      <label>{t("icon")}</label>
                      {["display-download"].includes(
                        fieldValues[index].type
                      ) ? (
                        <img
                          alt={t("iconAlt")}
                          style={{
                            objectFit: "cover",
                            width: "80px",
                            height: "80px",
                          }}
                          src={getDefaultIcon(
                            fieldValues[index].type,
                            fieldValues[index].subtype,
                            questForm.values,
                            game
                          )}
                        />
                      ) : (
                        <div
                          className="QuestEdit__upload"
                          onClick={this.uploadIcon.bind(
                            this,
                            pointInQuest,
                            index
                          )}
                          style={{ cursor: "pointer" }}
                          aria-hidden
                        >
                          <i
                            className="fa fa-close"
                            style={{
                              color: "white",
                              position: "absolute",
                              marginLeft: "70px",
                            }}
                            aria-hidden
                          />
                          <img
                            alt="requirement-icon"
                            style={{
                              objectFit: "contain",
                              width: "80px",
                              height: "80px",
                            }}
                            src={
                              fieldValues[index].icon &&
                              fieldValues[index].customIcon
                                ? fieldValues[index].icon
                                : getDefaultIcon(
                                    fieldValues[index].type,
                                    fieldValues[index].subtype,
                                    questForm.values,
                                    game
                                  )
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className="input-container" style={{ width: "75%" }}>
                      <label>{t("description")}</label>
                      <RichText
                        callback={(content) =>
                          this.handleRichTextUpdates(
                            content,
                            `requirements.${pointInQuest}[${index}].description`
                          )
                        }
                        initialValue={fieldValues[index].description}
                      />
                    </div>
                  </div>
                  {fieldValues[index].type.includes("submit") ? (
                    <div
                      className="Step__row"
                      style={{ width: "75%", marginLeft: "25%" }}
                    >
                      <div className="input-container" style={{ width: "45%" }}>
                        <Field
                          name={`${step}.label`}
                          id={`${step}.label`}
                          type="text"
                          component={CustomInput}
                          label={t("label")}
                          placeholder={t("label")}
                          customLabel={t("label")}
                        />
                      </div>
                      <div className="input-container" style={{ width: "45%" }}>
                        <Field
                          name={`${step}.placeholder`}
                          id={`${step}.placeholder`}
                          type="text"
                          component={CustomInput}
                          label={t("placeholder")}
                          placeholder={t("placeholder")}
                          customLabel={t("placeholder")}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div
                    className="Step__row"
                    style={{
                      width: "75%",
                      marginLeft: "25%",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="input-container">
                      <label>{t("tooltip")}</label>
                      <RichText
                        callback={(content) =>
                          this.handleRichTextUpdates(
                            content,
                            `requirements.${pointInQuest}[${index}].tooltip`
                          )
                        }
                        initialValue={fieldValues[index].tooltip}
                      />
                    </div>
                  </div>
                  <div
                    className="Step__row"
                    style={{ width: "75%", marginLeft: "25%" }}
                  >
                    <div className="input-container" style={{ width: "45%" }}>
                      <Field
                        name={`${step}.showOnApp`}
                        id={`${step}.showOnApp`}
                        component={CustomInputCheckbox}
                        customLabel={t("showOnApplicationPage")}
                      />
                    </div>
                    <div
                      className="input-container"
                      style={{
                        width: "45%",
                        display:
                          questForm.values &&
                          questForm.values.requirements[pointInQuest][index]
                            .showOnApp
                            ? "block"
                            : "none",
                      }}
                    >
                      <Field
                        name={`${step}.appPageTitle`}
                        id={`${step}.appPageTitle`}
                        component={CustomInput}
                        type="text"
                        label={t("applicationPageTitle")}
                        customLabel={t("applicationPageTitle")}
                        placeholder={t("applicationPageTitle")}
                      />
                    </div>
                  </div>
                </section>
                <section className="Step__arrows">
                  <div
                    className="Step__arrow--up"
                    style={{ display: index !== 0 ? "block" : "none" }}
                    onClick={this.swapRequirement.bind(
                      this,
                      pointInQuest,
                      index,
                      "up"
                    )}
                    aria-hidden
                  >
                    <i className="fa fa-arrow-up" />
                  </div>
                  <div
                    className="Step__arrow--down"
                    style={{
                      display: fields.length - 1 !== index ? "block" : "none",
                    }}
                    onClick={this.swapRequirement.bind(
                      this,
                      pointInQuest,
                      index,
                      "down"
                    )}
                    aria-hidden
                  >
                    <i className="fa fa-arrow-down" />
                  </div>
                </section>
              </section>
            </li>
          ))}
        </ul>
        {submitFailed && error && <span className="Step__error">{error}</span>}
        <button
          className="Step__add-item"
          type="button"
          onClick={this.toggleAddRequirementModal.bind(this, pointInQuest)}
        >
          {t("addRequirement")}
        </button>
        {this.state.addRequirementModal &&
        pointInQuest === this.state.pointInQuest ? (
          <section className="ModalMaster_requirements">
            <div
              onClick={this.toggleAddRequirementModal.bind(this, false)}
              className="Modal__layer"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <img
                alt="close-button"
                className="close"
                src={closeButton}
                onClick={this.toggleAddRequirementModal.bind(this, false)}
              />
              <h2> {t("addNewRequirement")} </h2>
              <div className="reqContainer">
                {stepTypeOptions.map((req) => {
                  let inactive = false;
                  let tooltip = false;
                  if (req.type === "display-download") {
                    if (
                      req.subtype === "banner" &&
                      !questForm.values.requiredBanner
                    ) {
                      inactive = true;
                      tooltip = t("tooltip_1");
                    } else if (
                      req.subtype === "overlay" &&
                      !questForm.values.overlayImage
                    ) {
                      inactive = true;
                      tooltip = t("tooltip_2");
                    }
                  } else if (req.type === "display-hashtags") {
                    if (
                      req.subtype === "tracking" &&
                      (!questForm.values.trackingTags ||
                        questForm.values.trackingTags.length === 0)
                    ) {
                      inactive = true;
                      tooltip = t("tooltip_3");
                    } else if (
                      req.subtype === "quest" &&
                      (!questForm.values.questTags ||
                        questForm.values.questTags.length === 0)
                    ) {
                      inactive = true;
                      tooltip = t("tooltip_4");
                    }
                  }

                  const inactiveClass = classNames({ inactive });
                  if (tooltip) {
                    return (
                      <Tooltip title={tooltip} placement="top">
                        <div
                          className="reqSelect"
                          onClick={
                            !inactive
                              ? () => {
                                  fields = this.addRequirement(fields, req);
                                }
                              : null
                          }
                        >
                          <div className="icon">
                            {this.getRequirementComponent(req, inactiveClass)}
                          </div>
                          <span className={inactiveClass}>{req.label}</span>
                        </div>
                      </Tooltip>
                    );
                  }
                  return (
                    <div
                      className="reqSelect"
                      onClick={
                        !inactive
                          ? () => (fields = this.addRequirement(fields, req))
                          : null
                      }
                    >
                      <div className="icon">
                        {this.getRequirementComponent(req, inactiveClass)}
                      </div>
                      <span className={inactiveClass}>{req.label}</span>
                    </div>
                  );
                })}
              </div>
            </section>
          </section>
        ) : null}
      </section>
    );
  };

  getRequirementComponent = (req, inactiveClass) => {
    switch (req.value) {
      case "display-download-banner":
        return <AdminBannerIcon className={inactiveClass} />;
      case "display-text-checkbox":
        return <AdminCheckboxIcon className={inactiveClass} />;
      case "display-text-build-stream":
        return <AdminBuildStreamIcon className={inactiveClass} />;
      case "display-text-game-name":
        return <AdminGameDirectoryIcon className={inactiveClass} />;
      case "distribute-key":
        return <AdminGameKeyIcon className={inactiveClass} />;
      case "display-hashtags-quest":
        return <AdminHashtagIcon className={inactiveClass} />;
      case "display-hashtags-tracking":
        return <AdminHashtagIcon className={inactiveClass} />;
      case "submit-link-clip":
        return <AdminClipIcon className={inactiveClass} />;
      case "submit-link-vod":
        return <AdminClipIcon className={inactiveClass} />;
      case "display-download-overlay":
        return <AdminBannerIcon className={inactiveClass} />;
      case "display-text-achievement":
        return <AdminAchievementIcon className={inactiveClass} />;
      case "submit-tweet":
        return <AdminTwitterIcon className={inactiveClass} />;
      case "distribute-link-campaign":
        return <AdminLinkIcon className={inactiveClass} />;
      case "distribute-link-affiliate":
        return <AdminLinkIcon className={inactiveClass} />;
      case "display-stream-time":
        return <AdminStreamIcon className={inactiveClass} />;
      case "display-text":
        return <AdminTextIcon className={inactiveClass} />;
      case "submit-text":
        return <AdminVerificationIcon className={inactiveClass} />;
      default:
        return <AdminVerificationIcon className={inactiveClass} />;
    }
  };
  /* END REQUIREMENTS */

  handleRichTextUpdates = (content, formValueString) => {
    this.props.dispatch(change("questForm", formValueString, content));
  };

  renderLogs = () => {
    const { t } = this.props;
    const { data: adminActivityLogs } = this.props.adminActivityLogs;
    if (!adminActivityLogs || adminActivityLogs.length <= 0) {
      return <React.Fragment>{t("noLogs")}.</React.Fragment>;
    }

    return adminActivityLogs.map((log) => {
      let differences = differenceObject(log.newDataRef, log.oldDataRef);

      /* START - STEPS */
      const newSteps = [];
      const oldSteps = [];
      if (log.newDataRef && log.newDataRef.steps) {
        log.newDataRef.steps.forEach((st) => {
          newSteps.push(omit(st, ["id", "createdAt", "updatedAt", "quest"]));
        });
      }
      if (log.oldDataRef && log.oldDataRef.steps) {
        log.oldDataRef.steps.forEach((st) => {
          oldSteps.push(omit(st, ["id", "createdAt", "updatedAt", "quest"]));
        });
      }
      const stepsChanged = !isEqual(newSteps, oldSteps);
      /* END - STEPS */
      // < Blocks -
      const newBlocks = [];
      const oldBlocks = [];
      if (log.newDataRef && log.newDataRef.blocks) {
        log.newDataRef.blocks.forEach((st) => {
          newBlocks.push(st);
        });
      }
      if (log.oldDataRef && log.oldDataRef.blocks) {
        log.oldDataRef.blocks.forEach((st) => {
          oldBlocks.push(st);
        });
      }
      const blocksChanged = !isEqual(newBlocks, oldBlocks);
      // - Blocks >
      // < Steps Page1 -
      const newStepsPage1 = [];
      const oldStepsPage1 = [];
      if (log.newDataRef && log.newDataRef.stepsPage1) {
        log.newDataRef.stepsPage1.forEach((st) => {
          newStepsPage1.push(st);
        });
      }
      if (log.oldDataRef && log.oldDataRef.stepsPage1) {
        log.oldDataRef.stepsPage1.forEach((st) => {
          oldStepsPage1.push(st);
        });
      }
      const stepsPage1Changed = !isEqual(newStepsPage1, oldStepsPage1);
      // - Steps Page1 >
      // < Steps Page2 -
      const newStepsPage2 = [];
      const oldStepsPage2 = [];
      if (log.newDataRef && log.newDataRef.stepsPage2) {
        log.newDataRef.stepsPage2.forEach((st) => {
          newStepsPage2.push(st);
        });
      }
      if (log.oldDataRef && log.oldDataRef.stepsPage2) {
        log.oldDataRef.stepsPage2.forEach((st) => {
          oldStepsPage2.push(st);
        });
      }
      const stepsPage2Changed = !isEqual(newStepsPage2, oldStepsPage2);
      // - Steps Page2 >
      // < Steps Page3 -
      const newStepsPage3 = [];
      const oldStepsPage3 = [];
      if (log.newDataRef && log.newDataRef.stepsPage3) {
        log.newDataRef.stepsPage3.forEach((st) => {
          newStepsPage3.push(
            omit(st, ["id", "createdAt", "updatedAt", "quest"])
          );
        });
      }
      if (log.oldDataRef && log.oldDataRef.stepsPage3) {
        log.oldDataRef.stepsPage3.forEach((st) => {
          oldStepsPage3.push(
            omit(st, ["id", "createdAt", "updatedAt", "quest"])
          );
        });
      }
      const stepsPage3Changed = !isEqual(newStepsPage3, oldStepsPage3);
      // - Steps Page3 >

      // < Requirements -
      const newRequirements = {
        beforeStream: [],
        duringStream: [],
        afterStream: [],
      };
      const oldRequirements = {
        beforeStream: [],
        duringStream: [],
        afterStream: [],
      };
      if (log.newDataRef && log.newDataRef.requirements) {
        let reqs = log.newDataRef.requirements.beforeStream;
        reqs &&
          reqs.forEach((st) => {
            newRequirements.beforeStream.push(
              omit(st, ["id", "createdAt", "updatedAt"])
            );
          });

        reqs = log.newDataRef.requirements.duringStream;
        reqs &&
          reqs.forEach((st) => {
            newRequirements.duringStream.push(
              omit(st, ["id", "createdAt", "updatedAt"])
            );
          });

        reqs = log.newDataRef.requirements.afterStream;
        reqs &&
          reqs.forEach((st) => {
            newRequirements.afterStream.push(
              omit(st, ["id", "createdAt", "updatedAt"])
            );
          });
      }

      if (log.oldDataRef && log.oldDataRef.requirements) {
        let reqs = log.oldDataRef.requirements.beforeStream;
        reqs &&
          reqs.forEach((st) => {
            oldRequirements.beforeStream.push(
              omit(st, ["id", "createdAt", "updatedAt"])
            );
          });

        reqs = log.oldDataRef.requirements.duringStream;
        reqs &&
          reqs.forEach((st) => {
            oldRequirements.duringStream.push(
              omit(st, ["id", "createdAt", "updatedAt"])
            );
          });

        reqs = log.oldDataRef.requirements.afterStream;
        reqs &&
          reqs.forEach((st) => {
            oldRequirements.afterStream.push(
              omit(st, ["id", "createdAt", "updatedAt"])
            );
          });
      }

      const requirementsChanged = !isEqual(newRequirements, oldRequirements);
      // - Requirements >
      /* START - WHITELIST */
      const newWhitelist = [];
      const oldWhitelist = [];
      if (log.newDataRef && log.newDataRef.whitelist) {
        log.newDataRef.whitelist.forEach((wl) => {
          newWhitelist.push(
            omit(wl, ["id", "createdAt", "updatedAt", "quest"])
          );
        });
      }
      if (log.oldDataRef && log.oldDataRef.whitelist) {
        log.oldDataRef.whitelist.forEach((wl) => {
          oldWhitelist.push(
            omit(wl, ["id", "createdAt", "updatedAt", "quest"])
          );
        });
      }
      const whitelistChanged = !isEqual(newWhitelist, oldWhitelist);
      /* END - WHITELIST */
      /* START - FAQS */
      const newFaqs = [];
      const oldFaqs = [];
      if (log.newDataRef && log.newDataRef.faqs) {
        log.newDataRef.faqs.forEach((fa) => {
          newFaqs.push(omit(fa, ["question", "answer"]));
        });
      }
      if (log.oldDataRef && log.oldDataRef.faqs) {
        log.oldDataRef.faqs.forEach((fa) => {
          oldFaqs.push(omit(fa, ["question", "answer"]));
        });
      }
      const faqChanged = !isEqual(newFaqs, oldFaqs);
      /* END - FAQS */
      // < Tags
      const newTags = [];
      const oldTags = [];
      if (log.newDataRef && log.newDataRef.tags) {
        log.newDataRef.tags.forEach((tg) => {
          newTags.push(tg);
        });
      }
      if (log.oldDataRef && log.oldDataRef.tags) {
        log.oldDataRef.tags.forEach((tg) => {
          oldTags.push(tg);
        });
      }
      const tagsChanged = !isEqual(newTags, oldTags);
      // Tags >
      // < trackingTags
      const newTrackingTags = [];
      const oldTrackingTags = [];
      if (log.newDataRef && log.newDataRef.trackingTags) {
        log.newDataRef.trackingTags.forEach((tg) => {
          newTrackingTags.push(tg);
        });
      }
      if (log.oldDataRef && log.oldDataRef.trackingTags) {
        log.oldDataRef.trackingTags.forEach((tg) => {
          oldTrackingTags.push(tg);
        });
      }
      const trackingTagsChanged = !isEqual(newTrackingTags, oldTrackingTags);
      // trackingTags >
      // < Viewer Tiers
      const newViewerTiers = [];
      const oldViewerTiers = [];
      if (log.newDataRef && log.newDataRef.viewerTiers) {
        log.newDataRef.viewerTiers.forEach((vt) => {
          newViewerTiers.push(vt);
        });
      }
      if (log.oldDataRef && log.oldDataRef.viewerTiers) {
        log.oldDataRef.viewerTiers.forEach((vt) => {
          oldViewerTiers.push(vt);
        });
      }
      const viewerTiersChanged = !isEqual(newViewerTiers, oldViewerTiers);
      // Viewer Tiers >
      // < Member Tiers
      const newMemberTiers = [];
      const oldMemberTiers = [];
      if (log.newDataRef && log.newDataRef.memberTiers) {
        log.newDataRef.memberTiers.forEach((mt) => {
          newMemberTiers.push(mt);
        });
      }
      if (log.oldDataRef && log.oldDataRef.memberTiers) {
        log.oldDataRef.memberTiers.forEach((mt) => {
          oldMemberTiers.push(mt);
        });
      }
      const memberTiersChanged = !isEqual(newMemberTiers, oldMemberTiers);
      // Member Tiers >
      // < Quest Tags -
      const newQuestTags = [];
      const oldQuestTags = [];
      if (log.newDataRef && log.newDataRef.questTags) {
        log.newDataRef.questTags.forEach((tg) => {
          newQuestTags.push(tg);
        });
      }
      if (log.oldDataRef && log.oldDataRef.questTags) {
        log.oldDataRef.questTags.forEach((tg) => {
          oldQuestTags.push(tg);
        });
      }

      const questTagsChanged = !isEqual(newQuestTags, oldQuestTags);
      // - Quest Tags >
      differences = omit(differences, [
        "updatedAt",
        "steps",
        "faqs",
        "whitelist",
        "tags",
        "viewerTiers",
        "memberTiers",
        "stepsLanding",
        "stepsPage1",
        "stepsPage2",
        "stepsPage3",
        "blocks",
        "questTags",
        "trackingTags",
        "promoLinks",
        "completionRequirements",
        "requirements",
      ]);
      const objKeys = keys(differences);

      const { t } = this.props;

      return (
        /* QFLog -> Quest Form Log */
        <div className="QFLog">
          <div className="QFLog_top">
            {log.user?.username} -{" "}
            {moment(log.createdAt).format("MMMM DD, YYYY - hh:mm a")}
          </div>
          <div className="QFLog_bot">
            {objKeys.map((oKey) => {
              let oKeyOld = <i>Empty</i>;
              if (log.oldDataRef[oKey]) {
                oKeyOld = log.oldDataRef[oKey];
              } else if (log.oldDataRef[oKey] === "") {
                oKeyOld = "";
              } else if (log.oldDataRef[oKey] === false) {
                oKeyOld = false;
              } else if (log.oldDataRef[oKey] === false) {
                oKeyOld = false;
              } else if (typeof log.oldDataRef[oKey] === "undefined") {
                oKeyOld = <i>Undefined</i>;
              }

              let oKeyNew = <i>Empty</i>;
              if (log.newDataRef[oKey]) {
                oKeyNew = log.newDataRef[oKey];
              } else if (log.newDataRef[oKey] === "") {
                oKeyNew = "";
              } else if (log.newDataRef[oKey] === false) {
                oKeyNew = false;
              } else if (typeof log.newDataRef[oKey] === "undefined") {
                oKeyNew = <i>Undefined</i>;
              }

              if (oKey === "emailFailure") {
                if (
                  log.newDataRef[oKey] &&
                  log.newDataRef[oKey].shouldSend !== undefined
                ) {
                  oKeyNew = `"Send Failure Email" set to ${log.newDataRef[oKey].shouldSend}`;
                }

                if (
                  log.oldDataRef[oKey] &&
                  log.oldDataRef[oKey].shouldSend !== undefined
                ) {
                  oKeyOld = `"Send Failure Email" set to ${log.oldDataRef[oKey].shouldSend}`;
                }
              }

              if (oKey === "emailReminder") {
                if (
                  log.newDataRef[oKey] &&
                  log.newDataRef[oKey].shouldSend !== undefined
                ) {
                  oKeyNew = `"Send Reminder Email" set to ${log.newDataRef[oKey].shouldSend}`;
                }

                if (
                  log.oldDataRef[oKey] &&
                  log.oldDataRef[oKey].shouldSend !== undefined
                ) {
                  oKeyOld = `"Send Reminder Email" set to ${log.oldDataRef[oKey].shouldSend}`;
                }
              }

              return (
                <div className="QFLog_bot_key">
                  <React.Fragment>
                    {oKey}: &nbsp;
                    <span className="old">
                      &nbsp;{oKeyOld?.toString() || oKeyOld}&nbsp;
                    </span>
                    &nbsp;-&nbsp;
                    <span className="new">
                      &nbsp;{oKeyNew?.toString() || oKeyNew}&nbsp;
                    </span>
                  </React.Fragment>
                </div>
              );
            })}
            {stepsChanged && (
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: "steps",
                      olds: oldSteps,
                      news: newSteps,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> {t("stepsChanged")}
                </div>
                <br />
              </React.Fragment>
            )}
            {faqChanged && (
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: "faqs",
                      olds: oldFaqs,
                      news: newFaqs,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> {t("FAQsChanged")}
                </div>
                <br />
              </React.Fragment>
            )}
            {whitelistChanged && (
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: "whitelist",
                      olds: oldWhitelist,
                      news: newWhitelist,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> {t("whitelistChanged")}
                </div>
                <br />
              </React.Fragment>
            )}
            {tagsChanged && (
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: "tags",
                      olds: oldTags,
                      news: newTags,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> {t("tagsChanged")}
                </div>
                <br />
              </React.Fragment>
            )}
            {trackingTagsChanged && (
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: "trackingTags",
                      olds: oldTrackingTags,
                      news: newTrackingTags,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> {t("trackingTagsChanged")}
                </div>
                <br />
              </React.Fragment>
            )}
            {viewerTiersChanged && (
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: "viewerTiers",
                      olds: oldViewerTiers,
                      news: newViewerTiers,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" />
                  {t("viewerTiersChanged")}
                </div>
                <br />
              </React.Fragment>
            )}
            {memberTiersChanged && (
              <React.Fragment>
                <div
                  className="QFLog_bot_key QFLog_bot_key_button"
                  onClick={() => {
                    this.setState({
                      logsModal: 1,
                      modalType: "memberTiers",
                      olds: oldMemberTiers,
                      news: newMemberTiers,
                    });
                  }}
                  aria-hidden
                >
                  <i className="fa fa-list" /> {t("memberTiersChanged")}
                </div>
                <br />
              </React.Fragment>
            )}
            {blocksChanged && (
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: "blocks",
                    olds: oldBlocks,
                    news: newBlocks,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> {t("blocksChanged")}
              </div>
            )}
            {stepsPage1Changed && (
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: "stepsPage1",
                    olds: oldStepsPage1,
                    news: newStepsPage1,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> {t("userInformationPage_1")}
              </div>
            )}
            {stepsPage2Changed && (
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: "stepsPage2",
                    olds: oldStepsPage2,
                    news: newStepsPage2,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> {t("userInformationPage_2")}
              </div>
            )}
            {stepsPage3Changed && (
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: "stepsPage3",
                    olds: oldStepsPage3,
                    news: newStepsPage3,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> {t("userInformationPage_3")}
              </div>
            )}
            {questTagsChanged && (
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: "questTags",
                    olds: oldQuestTags,
                    news: newQuestTags,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> {t("questTagsChanged")}
              </div>
            )}
            {requirementsChanged && (
              <div
                className="QFLog_bot_key QFLog_bot_key_button"
                onClick={() => {
                  this.setState({
                    logsModal: 1,
                    modalType: "requirements",
                    olds: oldRequirements,
                    news: newRequirements,
                  });
                }}
                aria-hidden
              >
                <i className="fa fa-list" /> {t("requirementsChanged")}
              </div>
            )}
            {objKeys.length === 0 &&
              !stepsChanged &&
              !faqChanged &&
              !whitelistChanged &&
              !tagsChanged &&
              !viewerTiersChanged &&
              !memberTiersChanged &&
              !blocksChanged &&
              !stepsPage1Changed &&
              !stepsPage2Changed &&
              !stepsPage3Changed &&
              !questTagsChanged &&
              !trackingTagsChanged &&
              !requirementsChanged && (
                <div className="QFLog_bot_key">{t("noChange")}</div>
              )}
            {this.state.logsModal === 1 && this.renderLogsModal()}
          </div>
        </div>
      );
    });
  };

  renderLogsModal = () => {
    const { modalType, olds, news } = this.state;
    const { t } = this.props;
    let typeTitle = "";
    if (modalType === "steps") {
      typeTitle = t("typeTitle.steps");
    } else if (modalType === "faqs") {
      typeTitle = t("typeTitle.faqs");
    } else if (modalType === "whitelist") {
      typeTitle = t("typeTitle.whitelist");
    } else if (modalType === "tags") {
      typeTitle = t("typeTitle.tags");
    } else if (modalType === "viewerTiers") {
      typeTitle = t("typeTitle.viewerTiers");
    } else if (modalType === "memberTiers") {
      typeTitle = t("typeTitle.memberTiers");
    } else if (modalType === "blocks") {
      typeTitle = t("typeTitle.blocks");
    } else if (modalType === "stepsPage1") {
      typeTitle = t("typeTitle.stepsPage1");
    } else if (modalType === "stepsPage2") {
      typeTitle = t("typeTitle.stepsPage2");
    } else if (modalType === "stepsPage3") {
      typeTitle = t("typeTitle.stepsPage3");
    } else if (modalType === "questTags") {
      typeTitle = t("typeTitle.questTags");
    } else if (modalType === "requirements") {
      typeTitle = t("typeTitle.requirements");
    }

    return (
      <section className="ModalQuestLogs">
        <section className="ModalMaster">
          <section className="Modal Modal__ModalQuestLogs active">
            <div
              className="Modal__layer"
              onClick={() => {
                this.setState({ logsModal: 0 });
              }}
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div
                className="Modal__close-btn"
                onClick={() => {
                  this.setState({ logsModal: 0 });
                }}
                aria-hidden
              />
              <div className="ModalQuestLogs__body">
                <div className="ModalQuestLogs__olds">
                  <div className="ModalQuestLogs__olds__title">
                    {t("old")} {typeTitle}
                  </div>
                  <div className="ModalQuestLogs__olds__row">
                    <JSONPretty data={olds} />
                  </div>
                </div>
                <div className="ModalQuestLogs__news">
                  <div className="ModalQuestLogs__news__title">
                    {t("new")} {typeTitle}
                  </div>
                  <div className="ModalQuestLogs__news__row">
                    <JSONPretty data={news} />
                  </div>
                </div>
              </div>
              <div className="ModalQuestLogs__footer">
                <div className="ModalQuestLogs__footer__button">
                  <div
                    className="ModalQuestLogs__footer__buttonA"
                    onClick={() => {
                      this.setState({ logsModal: 0 });
                    }}
                    aria-hidden
                  >
                    {t("close")}
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  };

  renderCopyUrl = (index) => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Field
          type="text"
          copyUrl
          readOnly
          name="regularImage"
          id="regularImage"
          placeholder={t("imageURL")}
          customLabel={
            <label>
              {t("generateImageURL")}&nbsp;&nbsp;
              <div
                className="QuestEdit__upload"
                onClick={this.uploadRegularImage.bind(this)}
                aria-hidden
              >
                ({t("upload")})
              </div>
              &nbsp;&nbsp;
              <div
                className="QuestEdit__upload"
                onClick={() => {
                  document.getElementsByClassName("copyUrl")[index].select();
                  document.execCommand("copy");
                }}
                aria-hidden
              >
                {t("copy")}
              </div>
              &nbsp;&nbsp;
              <i className="fa fa-info-circle" data-tip={t("dataTip")} />
            </label>
          }
          component={CustomInput}
        />
        {/* <ReactTooltip effect="solid" place="top" multiline html /> */}
      </React.Fragment>
    );
  };

  sendToRequirementsPreview = (quest) => {
    const host = window.location.hostname;
    const isLocal = host === "localhost";
    const finalHost = isLocal ? `${host}:3000` : host;
    const protocol = isLocal ? "http://" : "https://";
    const { id } = quest;
    window.open(`${protocol}${finalHost}/my-quests/preview/${id}`, "_blank");
  };

  toggleAddRequirementModal(pointInQuest) {
    this.setState({
      addRequirementModal: !this.state.addRequirementModal,
      pointInQuest,
    });
  }

  renderWhiteList = ({ fields, meta: { error }, ...custom }) => {
    const { t } = this.props;
    const whiteListTypeOptions = [
      { label: t("twitchUsername"), value: "twitch-username" },
    ];
    const { questForm } = this.props;
    const { parametersSubTab } = this.state;

    return (
      <section
        className="WhiteListWrapper"
        style={parametersSubTab !== 2 ? { display: "none" } : {}}
      >
        {/* <section className="WhiteListWrapper__header">
          <h3 className="WhiteListWrapper__header--title">Whitelisted Users (Must use Twitch Username)</h3>
        </section> */}
        <ul>
          {fields.map((whiteListItem, index) => (
            <li key={index}>
              <section className="RepeaterWrapper__fields">
                <div className="item-number">{index}</div>
                <Field
                  name={`${whiteListItem}.type`}
                  id={`${whiteListItem}.type`}
                  placeholder={t("selectType")}
                  customLabel={t("typeOfWhitelisting")}
                  component={CustomDropDownArray}
                  options={whiteListTypeOptions}
                />
                <Field
                  name={`${whiteListItem}.identity`}
                  id={`${whiteListItem}.identity`}
                  type="text"
                  component={CustomInput}
                  label={t("username")}
                  placeholder={t("username")}
                  customLabel={t("username")}
                />
                <button
                  className="RepeaterWrapper__item-remove"
                  type="button"
                  onClick={() => {
                    if (
                      window.confirm(
                        `${t("identityTip1")} ${
                          questForm.values.whitelist[index].identity
                        } ${t("identityTip2")}?`
                      )
                    ) {
                      fields.remove(index);
                    }
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              </section>
            </li>
          ))}
        </ul>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        <button
          className="RepeaterWrapper__add-item"
          type="button"
          onClick={() => fields.push({})}
        >
          {t("addUserWhitelist")}
        </button>
      </section>
    );
  };

  renderViewerTiers = ({ fields, meta: { error } }) => {
    const { questForm = {}, t } = this.props;
    const { parametersSubTab } = this.state;

    let questStarted = false;
    if (questForm.values && questForm.values.startDateTime) {
      questStarted =
        moment(questForm.values.startDateTime).diff(moment(Date.now())) < 0;
    }

    return (
      <section
        className="WhiteListWrapper"
        style={parametersSubTab !== 0 ? { display: "none" } : {}}
      >
        <TableContainer>
          <Table>
            {questForm.values &&
              questForm.values.viewerTiers &&
              questForm.values.viewerTiers.length > 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("spotsAvailable")}</TableCell>
                    <TableCell align="center">{t("minAvgViewers")}</TableCell>
                    <TableCell align="center">
                      $ {t("amount")}{" "}
                      <Tooltip title={t("amountToolTip")} placement="top">
                        <i className="fa fa-info-circle" />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{t("tierGoal")}</TableCell>
                    <TableCell align="center">{t("enableGoal")}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
              )}
            <TableBody>
              {fields.map((viewerTierItem, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Field
                      name={`${viewerTierItem}.spotsAvailable`}
                      id={`${viewerTierItem}.spotsAvailable`}
                      type="text"
                      component={CustomInput}
                      placeholder={t("spotsAvailable")}
                      onlyNumbers="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Field
                      name={`${viewerTierItem}.minimumAverageViewers`}
                      id={`${viewerTierItem}.minimumAverageViewers`}
                      type="text"
                      component={CustomInput}
                      placeholder={t("minimumAverageViewers")}
                      onlyNumbers="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Field
                      name={`${viewerTierItem}.paymentAmount`}
                      id={`${viewerTierItem}.paymentAmount`}
                      type="text"
                      component={CustomInput}
                      placeholder={t("paymentAmount")}
                    />
                  </TableCell>
                  <TableCell>
                    <Field
                      name={`${viewerTierItem}.goal`}
                      id={`${viewerTierItem}.goal`}
                      type="text"
                      component={CustomInput}
                      placeholder={t("goalDescription")}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Field
                      name={`${viewerTierItem}.goalEnabled`}
                      id={`${viewerTierItem}.goalEnabled`}
                      type="checkbox"
                      component={CustomInputCheckbox}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {!questStarted && (
                      <button
                        className="RepeaterWrapper__item-remove"
                        type="button"
                        onClick={() => {
                          if (window.confirm(t("removeTier"))) {
                            fields.remove(index);
                          }
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        <button
          className="RepeaterWrapper__add-item"
          type="button"
          onClick={() => fields.push({})}
        >
          {t("addAnotherViewerTier")}
        </button>
      </section>
    );
  };

  memberTierRemoveRender = (mt, fn) => {
    const { t } = this.props;
    if (mt.status) {
      return (
        <Tooltip title={`${t("statusToolTip")} ${mt.status}`} placement="top">
          <div>
            <IconButton disabled>
              <i className="fa fa-trash" />
            </IconButton>
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={t("removeThisMemberTier")} placement="top">
        <IconButton onClick={fn}>
          <i className="fa fa-trash" />
        </IconButton>
      </Tooltip>
    );
  };

  renderMemberTiers = ({ fields, meta: { error } }) => {
    const { questForm = {}, t } = this.props;
    const { parametersSubTab } = this.state;

    return (
      <section
        className="WhiteListWrapper"
        style={parametersSubTab !== 1 ? { display: "none" } : {}}
      >
        <TableContainer>
          <Table>
            {questForm.values &&
              questForm.values.memberTiers &&
              questForm.values.memberTiers.length > 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("twitchUsername")}</TableCell>
                    <TableCell align="center">{t("paymentAmount")}</TableCell>
                    <TableCell align="center">{t("goalDescription")}</TableCell>
                    <TableCell align="center">{t("enableGoal")}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
              )}
            <TableBody>
              {fields.map((memberTierItem, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Field
                      name={`${memberTierItem}.identity`}
                      id={`${memberTierItem}.identity`}
                      type="text"
                      component={CustomInput}
                      placeholder={t("twitchUsername")}
                    />
                  </TableCell>
                  <TableCell>
                    <Field
                      name={`${memberTierItem}.paymentAmount`}
                      id={`${memberTierItem}.paymentAmount`}
                      type="text"
                      component={CustomInput}
                      placeholder={t("paymentAmount")}
                    />
                  </TableCell>
                  <TableCell>
                    <Field
                      name={`${memberTierItem}.goal`}
                      id={`${memberTierItem}.goal`}
                      type="text"
                      component={CustomInput}
                      placeholder={t("goalDescription")}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Field
                      name={`${memberTierItem}.goalEnabled`}
                      id={`${memberTierItem}.goalEnabled`}
                      type="checkbox"
                      component={CustomInputCheckbox}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {this.memberTierRemoveRender(fields.get(index), () => {
                      if (window.confirm(t("confirm_text"))) {
                        fields.remove(index);
                      }
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        <button
          className="RepeaterWrapper__add-item"
          type="button"
          onClick={() => fields.push({})}
        >
          {t("addAnotherMemberTier")}
        </button>
      </section>
    );
  };

  renderRules = ({ fields, meta: { error }, ...custom }) => {
    const { configRules, questForm, t } = this.props;

    const ruleOptions = configRules.map((rule) => ({
      label: rule.name,
      value: rule.id,
    }));

    return (
      <section className="RepeaterWrapper Rules">
        <ul>
          {fields.map((rule, index) => {
            let conditioningFactorAllowed = false;
            if (
              questForm.values &&
              questForm.values.steps &&
              questForm.values.steps.length > 0 &&
              questForm.values.steps[custom.stepIndex].rules &&
              questForm.values.steps[custom.stepIndex].rules.length > 0
            ) {
              const theRule = find(
                configRules,
                (myRule) =>
                  myRule.id ===
                  questForm.values.steps[custom.stepIndex].rules[index].id
              );

              conditioningFactorAllowed = isObject(theRule)
                ? theRule.conditioningFactorAllowed
                : false;
            } else {
              conditioningFactorAllowed = false;
            }

            return (
              <li key={index}>
                <section className="RepeaterWrapper__header">
                  <button
                    className="RepeaterWrapper__item-remove"
                    type="button"
                    onClick={() => fields.remove(index)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                  <h4 className="RepeaterWrapper__title">
                    {t("rule")} #{index + 1}
                  </h4>
                </section>
                <section className="RepeaterWrapper__fields">
                  <Field
                    name={`${rule}.id`}
                    id={`${rule}.id`}
                    placeholder={t("selectType")}
                    customLabel={t("type")}
                    component={CustomDropDownArray}
                    options={ruleOptions}
                  />

                  {conditioningFactorAllowed && (
                    <Field
                      name={`${rule}.conditioningFactor`}
                      id={`${rule}.conditioningFactor`}
                      type="number"
                      component={CustomInput}
                      placeholder={t("conditioningFactor")}
                      label={t("conditioningFactor")}
                      customLabel={t("conditioningFactor")}
                    />
                  )}
                </section>
              </li>
            );
          })}
        </ul>
        {error && <span className="RepeaterWrapper__error">{error}</span>}
        <button
          className="RepeaterWrapper__add-item"
          type="button"
          onClick={() => fields.push({})}
        >
          {t("addRule")}
        </button>
      </section>
    );
  };

  renderFAQs = ({ fields, meta: { error, submitFailed } }) => {
    const fieldValues = fields.getAll();
    const { t } = this.props;
    return (
      <div>
        {fields.map((step, index) => (
          <div className="faqs_wrapper">
            <button
              className="remove_faq"
              type="button"
              onClick={() => {
                fields.remove(index);
                this.faqs = this.faqs.splice(index, 1);
              }}
            >
              {t("removeFAQ")}
            </button>
            <Field
              name={`${step}.title`}
              id={`${step}.title`}
              type="text"
              component={CustomInput}
              label={t("question")}
              placeholder={t("question")}
              customLabel={t("question")}
            />
            <div className="input-container">
              <label>{t("answer")}</label>
              <RichText
                initialValue={fieldValues[index].description}
                callback={(content) =>
                  this.handleRichTextUpdates(
                    content,
                    `faqs[${index}].description`
                  )
                }
              />
            </div>
          </div>
        ))}
        <button
          className="add_faq"
          type="button"
          onClick={() => fields.push({ question: null, answer: null })}
        >
          {t("addFAQ")}
        </button>
      </div>
    );
  };

  titleSlug = (e, title) => {
    const {
      questForm: {
        fields: { slug },
      },
      titleSlugApply,
    } = this.props;

    if (!slug || !slug.visited) {
      titleSlugApply(friendlyURICharacters(replaceSpaces(title)));
    }
  };

  platformOptions = () => {
    const {
      game: { platforms = [] },
    } = this.props;

    const grouped = platforms.reduce((acc, pl) => {
      if (!acc[pl.baseService]) {
        acc[pl.baseService] = [];
      }
      acc[pl.baseService].push(pl);
      return acc;
    }, {});

    return Object.keys(grouped).map((group) => ({
      label: group,
      options: grouped[group].map(({ id, platform }) => ({
        value: id,
        label: <span style={{ zIndex: "1000" }}>{platform}</span>,
      })),
    }));
  };

  render() {
    const {
      auth: { token },
      // game: { id: gameId = '' },
      handleSubmit,
      pathname,
      preSignedUrl: { isLoading },
      quest = {},
      questForm = {},
      t,
    } = this.props;

    const pathSegs = pathname.split("/");
    const action = pathSegs[pathSegs.length - 1];

    const questTypeOptions = [
      // { label: 'General', value: 'default' },
      { label: t("oneTime"), value: "tiered-one-time" },
      // { label: 'Multi Day', value: 'tiered-multi-days' },
    ];

    const {
      activeModal,
      disableClick,
      dropzoneActive,
      parametersSubTab,
      prevGamesPlayed,
      subTab,
      tab,
    } = this.state;

    const questIsAffiliate = questForm.values
      ? questForm.values.type === "affiliate"
      : false;
    const questRequirePreapproval = questForm.values
      ? questForm.values.requirePreapproval
      : false;
    const questStepsTypesOptions = this.getStepsTypes(
      questIsAffiliate,
      questRequirePreapproval
    );

    // Check for "Send failure email" functionality
    const emailFailureSentDate =
      questForm.values &&
      questForm.values.emailFailure &&
      questForm.values.emailFailure.sentDate
        ? moment(questForm.values.emailFailure.sentDate).format(
            "MMMM DD, YYYY - hh:mm a"
          )
        : false;

    // Check for "Send reminder email" functionality
    const emailReminderSentDate =
      questForm.values &&
      questForm.values.emailReminder &&
      questForm.values.emailReminder.sentDate
        ? moment(questForm.values.emailReminder.sentDate).format(
            "MMMM DD, YYYY - hh:mm a"
          )
        : false;

    let questMeetsReminderMin = true;
    if (
      questForm.values &&
      questForm.values.startDateTime &&
      questForm.values.endDateTime
    ) {
      questMeetsReminderMin = !(getQuestDurationHours(questForm.values) <= 48);
    }

    const detailsTabClasses = this.getTabClasses("details");
    const parametersTabClasses = this.getTabClasses("parameters");
    const userInfoTabClasses = this.getTabClasses("user-info");
    const faqsTabClasses = this.getTabClasses("faqs");
    const viewerTiersTabClasses = this.getTabClasses("viewerTiers");
    const memberTiersTabClasses = this.getTabClasses("memberTiers");
    const whitelistTabClasses = this.getTabClasses("whitelist");
    const slugTabClasses = this.getTabClasses("slug");
    const stepsPage1TabClasses = this.getTabClasses("stepsPage1");
    const requirementsTabClasses = this.getTabClasses("requirements");

    return (
      <div>
        <div className="Global__tabs">
          <div
            className={classNames("Global__tab", { active: tab === "details" })}
            onClick={() => this.setState({ tab: "details" })}
            aria-hidden
          >
            {t("details")}&nbsp;
            <i className={detailsTabClasses} />
          </div>
          <div
            className={classNames("Global__tab", {
              active: tab === "parameters",
            })}
            onClick={() => this.setState({ tab: "parameters" })}
            aria-hidden
          >
            {t("parameters")}&nbsp;
            <i className={parametersTabClasses} />
          </div>
          <div
            className={classNames("Global__tab", {
              active: tab === "user-info",
            })}
            onClick={() => this.setState({ tab: "user-info" })}
            style={{ width: "180px" }}
            aria-hidden
          >
            {t("userInformation")}&nbsp;
            <i className={userInfoTabClasses} />
          </div>
          <div
            className={classNames("Global__tab", { active: tab === "faq" })}
            onClick={() => this.setState({ tab: "faq" })}
            aria-hidden
          >
            {t("faq")}&nbsp;
            <i className={faqsTabClasses} />
          </div>
          <div
            className={classNames("Global__tab", { active: tab === "logs" })}
            onClick={() => this.setState({ tab: "logs" })}
            aria-hidden
          >
            {t("logs")}
          </div>
        </div>
        <form
          className="form-container quests-edit"
          autoComplete="off"
          onSubmit={handleSubmit(this.submit.bind(this))}
        >
          <div
            className="info-tab-wrap"
            style={
              tab === "details" ? { display: "block" } : { display: "none" }
            }
          >
            <div className="form--row">
              <Field
                name="type"
                id="type"
                placeholder={t("selectType")}
                customLabel={t("type")}
                component={CustomDropDownArray}
                options={questTypeOptions}
              />
              <Field
                type="text"
                name="campaigns"
                id="campaigns"
                placeholder={t("campaigns")}
                customLabel={t("campaigns")}
                component={ArrayDropDown}
                styles={arrayDropDownColors}
                isMulti
                isClearable={false}
                optionsToRender={this.campaignsOptions()}
              />
            </div>
            <Field
              type="text"
              name="title"
              id="title"
              placeholder={t("questTitle")}
              customLabel={
                <label>
                  {t("questTitle")}
                  <span className="tooltip">
                    <i className="tooltip-icon fa fa-info-circle" />
                    <div className="tooltip-content">
                      {t("questTitleDetail")}
                    </div>
                  </span>
                </label>
              }
              onChange={this.titleSlug}
              component={CustomInput}
            />
            <Field
              name="status"
              id="status"
              placeholder={t("selectStatus")}
              customLabel={t("status")}
              component={CustomDropDownGeneric}
              options={["active", "archived", "disabled"]}
            />
            <Field
              name="isPrivate"
              id="isPrivate"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("isPrivate")}
              component={CustomInputCheckbox}
            />
            {/* <Field
              name="preview"
              id="preview"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel="Enable Preview"
              component={CustomInputCheckbox}
            /> */}
            {questForm.values &&
              questForm.values.type !== "tiered" &&
              questForm.values.type !== "tiered-one-time" &&
              questForm.values.type !== "tiered-multi-days" && (
                <Field
                  type="text"
                  name="openSpots"
                  id="openSpots"
                  placeholder="0"
                  customLabel={t("openSpots")}
                  component={CustomInput}
                  onlyNumbers="true"
                />
              )}
            <Field
              type="text"
              name="startDateTime"
              id="startDateTime"
              placeholder={t("startDate_tracking")}
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              customLabel={t("startDate_tracking")}
              component={CustomDateTimePicker}
            />
            <Field
              type="text"
              name="endDateTime"
              id="endDateTime"
              placeholder={t("endDate_tracking")}
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              customLabel={t("endDate_tracking")}
              component={CustomDateTimePicker}
            />
            <Field
              type="text"
              name="openRegistrationDate"
              id="openRegistrationDate"
              placeholder={t("openRegistrationDate")}
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              customLabel={t("openRegistrationDate")}
              component={CustomDateTimePicker}
            />
            <Field
              type="text"
              name="closeRegistrationDate"
              id="closeRegistrationDate"
              placeholder={t("closeRegistrationDate")}
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              customLabel={t("closeRegistrationDate")}
              component={CustomDateTimePicker}
            />
            <Field
              type="text"
              name="expectedPayoutDate"
              id="expectedPayoutDate"
              placeholder={t("expectedPayoutDate")}
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              customLabel={t("expectedPayoutDate")}
              component={CustomDateTimePicker}
            />
            <Field
              type="text"
              name="group"
              id="group"
              placeholder={t("group")}
              customLabel={t("group")}
              component={CustomInput}
            />
            {questForm.values &&
              questForm.values.type !== "tiered" &&
              questForm.values.type !== "tiered-one-time" &&
              questForm.values.type !== "tiered-multi-days" && (
                <Field
                  type="text"
                  name="minimumAverageViewers"
                  id="minimumAverageViewers"
                  placeholder={t("minimumAverageViewers")}
                  customLabel={t("minimumAverageViewers")}
                  component={CustomInput}
                  onlyNumbers="true"
                />
              )}
            <Field
              type="text"
              name="requiredBanner"
              id="requiredBanner"
              placeholder={t("requiredBanner")}
              customLabel={
                <label>
                  {t("requiredBanner")}&nbsp;&nbsp;
                  <div
                    className="QuestEdit__upload"
                    onClick={this.uploadBanner.bind(this)}
                    aria-hidden
                  >
                    ({t("uploadBanner")})
                  </div>
                </label>
              }
              component={CustomInput}
            />
            <Field
              type="text"
              name="bannerLink"
              id="bannerLink"
              placeholder={t("bannerLink")}
              customLabel={t("bannerLink")}
              component={CustomInput}
            />
            <Field
              type="text"
              name="overlayImage"
              id="overlayImage"
              placeholder={t("requiredOverlayImage")}
              customLabel={
                <label>
                  {t("requiredOverlayImage")}&nbsp;&nbsp;
                  <div
                    className="QuestEdit__upload"
                    onClick={this.uploadOverlayImage.bind(this)}
                    aria-hidden
                  >
                    ({t("uploadOverlayImage")})
                  </div>
                </label>
              }
              component={CustomInput}
            />
          </div>
          <div
            className="info-tab-wrap"
            style={
              tab === "parameters" ? { display: "block" } : { display: "none" }
            }
          >
            <Field
              name="isReferral"
              id="isReferral"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("isReferral")}
              component={CustomInputCheckbox}
            />
            <div
              className={classNames("QuestEdit__section", {
                active:
                  questForm.values &&
                  (questForm.values.type === "referral" ||
                    questForm.values.isReferral === true),
              })}
            >
              {questForm.values &&
                (questForm.values.type === "referral" ||
                  questForm.values.isReferral === true) && (
                  <Field
                    type="text"
                    name="minimumAverageViewersReferral"
                    id="minimumAverageViewersReferral"
                    placeholder={t("selectValue")}
                    customLabel={
                      <label>
                        {t("minimumAverageViewers")}
                        <span className="tooltip">
                          <i className="tooltip-icon fa fa-info-circle" />
                          <div className="tooltip-content">
                            {t("appliesOnly")}
                          </div>
                        </span>
                      </label>
                    }
                    component={CustomInput}
                    onlyNumbers="true"
                  />
                )}
              {questForm.values &&
                (questForm.values.type === "referral" ||
                  questForm.values.isReferral === true) && (
                  <div className="input-container input-radio">
                    {questForm.syncErrors &&
                      questForm.syncErrors.referralPaymentType !== "" && (
                        <div className="error-message">
                          {questForm.syncErrors.referralPaymentType}
                        </div>
                      )}
                    <div className="frequency-wrap">
                      <Field
                        name="referralPaymentType"
                        component="input"
                        type="radio"
                        value="commission-percentage"
                      />
                      &nbsp; % &nbsp;&nbsp;&nbsp;
                      <Field
                        name="referralPaymentType"
                        component="input"
                        type="radio"
                        value="commission-amount"
                      />
                      &nbsp; $:
                      <span className="tooltip">
                        <i className="tooltip-icon fa fa-info-circle" />
                        <div className="tooltip-content">{t("howMuch")}</div>
                      </span>
                      &nbsp;&nbsp;
                      {questForm.values &&
                        (questForm.values.type === "referral" ||
                          questForm.values.isReferral === true) &&
                        questForm.values.referralPaymentType ===
                          "commission-amount" && (
                          <Field
                            type="text"
                            name="commissionAmount"
                            id="commissionAmount"
                            placeholder={t("selectValue")}
                            component={CustomInput}
                          />
                        )}
                      {questForm.values &&
                        (questForm.values.type === "referral" ||
                          questForm.values.isReferral === true) &&
                        questForm.values.referralPaymentType ===
                          "commission-percentage" && (
                          <Field
                            type="text"
                            name="commissionPercentage"
                            id="commissionPercentage"
                            placeholder={t("selectValue")}
                            component={CustomInput}
                          />
                        )}
                    </div>
                  </div>
                )}
              <div
                style={{
                  display:
                    questForm.values && questForm.values.isReferral === true
                      ? "block"
                      : "none",
                }}
              >
                <Field
                  name="referralBonus"
                  id="referralBonus"
                  type="checkbox"
                  placeholder={t("selectValue")}
                  customLabel={
                    <label>
                      {t("bonus")}
                      <span className="tooltip">
                        <i className="tooltip-icon fa fa-info-circle" />
                        <div className="tooltip-content">
                          {t("afterQualifying")}
                        </div>
                      </span>
                    </label>
                  }
                  component={CustomInputCheckbox}
                />
              </div>
              <div
                style={{
                  display:
                    questForm.values &&
                    questForm.values.isReferral === true &&
                    questForm.values.referralBonus === true
                      ? "block"
                      : "none",
                }}
              >
                <div className="QuestEdit__row">
                  <span className="stream-label">{t("forEvery")}</span>
                  &nbsp; &nbsp;
                  <label className="QuestEdit--smallLabel">
                    <Field
                      type="text"
                      name="salesForBonus"
                      id="salesForBonus"
                      component={CustomInput}
                      placeholder="00"
                      onlyNumbers="true"
                    />
                  </label>
                  &nbsp; &nbsp;
                  <span className="stream-label">{t("sales")}</span>
                  &nbsp; &nbsp;
                  <label className="QuestEdit--smallLabel">
                    <Field
                      type="text"
                      name="bonusValue"
                      id="bonusValue"
                      component={CustomInput}
                      placeholder="00"
                      onlyNumbers="true"
                    />
                  </label>
                  &nbsp; &nbsp;
                  <span className="stream-label">{t("bonus")}</span>
                  &nbsp; &nbsp;
                  <Field
                    name="referralBonusType"
                    component="input"
                    type="radio"
                    value="bonus-in-percentage"
                  />
                  &nbsp;
                  <span className="stream-label">%</span>
                  &nbsp; &nbsp;
                  <Field
                    name="referralBonusType"
                    component="input"
                    type="radio"
                    value="bonus-in-amount"
                  />
                  &nbsp;
                  <span className="stream-label">$</span>&nbsp; &nbsp;
                  <Field
                    name="referralBonusTimes"
                    component="input"
                    type="radio"
                    value="once"
                  />
                  &nbsp;
                  <span className="stream-label">{t("once")}</span>
                  &nbsp; &nbsp;
                  <Field
                    name="referralBonusTimes"
                    component="input"
                    type="radio"
                    value="every-sale"
                  />
                  &nbsp;
                  <span className="stream-label">{t("everySale")}</span>
                </div>
              </div>
            </div>
            <Field
              name="comingSoon"
              id="comingSoon"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("comingSoon")}
              component={CustomInputCheckbox}
            />
            <Field
              name="platforms"
              id="platforms"
              component={ArrayDropDown}
              styles={arrayDropDownColors}
              customLabel={<label>{t("platforms")}</label>}
              optionsToRender={this.platformOptions()}
            />
            <Field
              type="text"
              name="countries"
              id="countries"
              placeholder={t("countries")}
              customLabel={t("countries")}
              component={ArrayDropDown}
              styles={arrayDropDownColors}
              isMulti
              isClearable={false}
              optionsToRender={this.countriesOptions()}
            />
            <Field
              type="text"
              name="languages"
              id="languages"
              placeholder={t("languages")}
              customLabel={t("languages")}
              component={ArrayDropDown}
              styles={arrayDropDownColors}
              isMulti
              isClearable={false}
              optionsToRender={this.languagesOptions()}
            />
            <RequiredGames
              token={token}
              values={prevGamesPlayed}
              onChange={(arr) => this.setState({ prevGamesPlayed: arr })}
            />
            <Field
              name="keyRequired"
              id="keyRequired"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("isKeyRequired")}
              component={CustomInputCheckbox}
            />
            <Field
              name="userCanleave"
              id="userCanLeave"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("userCanLeave")}
              component={CustomInputCheckbox}
            />
            <Field
              name="plus18"
              id="plus18"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("userParticipate")}
              component={CustomInputCheckbox}
            />

            <Field
              name="paymentMade"
              id="paymentMade"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("paymentMade")}
              component={CustomInputCheckbox}
            />
            <Field
              name="hideOpenSpots"
              id="hideOpenSpots"
              type="checkbox"
              defaultChecked
              placeholder={t("selectValue")}
              customLabel={t("hideOpenSpots")}
              component={CustomInputCheckbox}
            />
            <Field
              name="requirePreapproval"
              id="requirePreapproval"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("requirePreapproval")}
              component={CustomInputCheckbox}
            />
            <Field
              name="twitchAccountRequired"
              id="twitchAccountRequired"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("thisTwitchQuest")}
              component={CustomInputCheckbox}
              handleChange={() => {
                if (questForm?.values?.youtubeAccountRequired)
                  questForm.values.youtubeAccountRequired = false;
              }}
            />
            <Field
              name="youtubeAccountRequired"
              id="youtubeAccountRequired"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("thisYoutubeQuest")}
              component={CustomInputCheckbox}
              handleChange={() => {
                if (questForm?.values?.twitchAccountRequired)
                  questForm.values.twitchAccountRequired = false;
              }}
            />
            <div
              style={{
                display:
                  questForm.values &&
                  questForm.values.youtubeAccountRequired === true
                    ? "block"
                    : "none",
              }}
            >
              <Field
                name="tags"
                id="tags"
                type="text"
                placeholder={t("selectValue")}
                customLabel={
                  <label>
                    {t("tags")}
                    <span className="tooltip">
                      <i className="tooltip-icon fa fa-info-circle" />
                      <div className="tooltip-content">
                        {t("accountResult")}
                      </div>
                    </span>
                  </label>
                }
                component={CustomTagField}
              />
            </div>
            <Field
              name="twitterAccountRequired"
              id="twitterAccountRequired"
              type="checkbox"
              placeholder={t("selectValue")}
              customLabel={t("twitterAccount")}
              component={CustomInputCheckbox}
            />

            {questForm.values && questForm.values.type === "link out" && (
              <Field
                type="text"
                name="joinButtonText"
                id="joinButtonText"
                placeholder={t("joinButtonText")}
                customLabel={t("joinButtonText")}
                component={CustomInput}
              />
            )}
            {questForm.values && questForm.values.type === "link out" && (
              <Field
                type="text"
                name="linkout"
                id="linkout"
                placeholder={t("joinButtonLink")}
                customLabel={t("joinButtonLink")}
                component={CustomInput}
              />
            )}
            {questForm.values &&
              !["referral", "affiliate", "default", "link out"].includes(
                questForm.values.type
              ) && (
                <React.Fragment>
                  <div className="input-container stream-time-wrap">
                    <label>
                      <b>{t("requiredStreamTime")}</b>
                    </label>
                    <div className="stream-time-inner-wrap">
                      <label>
                        <Field
                          type="text"
                          name="requiredStreamTimeMinutes"
                          id="requiredStreamTimeMinutes"
                          component={CustomInput}
                          placeholder="00"
                          onlyNumbers="true"
                        />
                      </label>
                      <span className="stream-label">
                        {t("minutes")}
                        <span
                          className="tooltip"
                          style={{
                            display:
                              questForm.values &&
                              questForm.values.type === "tiered-multi-days"
                                ? "inline-block"
                                : "none",
                          }}
                        >
                          <i className="tooltip-icon fa fa-info-circle" />
                          <div className="tooltip-content">
                            {t("minutesTip")}
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              )}
            <Field
              name="questTags"
              id="questTags"
              type="text"
              placeholder={t("selectValue")}
              customLabel={
                <label>
                  {t("questTags")}
                  <span className="tooltip">
                    <i className="tooltip-icon fa fa-info-circle" />
                    <div className="tooltip-content">{t("questTagsTip")}</div>
                  </span>
                </label>
              }
              component={CustomTagField}
            />
            <Field
              name="trackingTags"
              id="trackingTags"
              type="text"
              tracking
              placeholder={t("selectValue")}
              customLabel={
                <label>
                  {t("trackingQuestTags")}
                  <span className="tooltip">
                    <i className="tooltip-icon fa fa-info-circle" />
                    <div className="tooltip-content">
                      {t("trackingQuestTagsTip")}
                    </div>
                  </span>
                </label>
              }
              component={CustomTagField}
            />
            {questForm.values && questForm.values.type === "paid bounty" && (
              <React.Fragment>
                <Field
                  type="text"
                  name="dailyEarning"
                  id="dailyEarning"
                  placeholder={t("dailyEarning")}
                  customLabel={t("dailyEarningDailyPayment")}
                  component={CustomInput}
                  onlyNumbers="true"
                />
              </React.Fragment>
            )}
            {questForm.values && questForm.values.type === "timed" && (
              <Field
                type="text"
                name="paymentAmount"
                id="paymentAmount"
                placeholder={t("paymentAmount")}
                customLabel={t("paymentAmount")}
                component={CustomInput}
                onlyNumbers="true"
              />
            )}
            <div
              style={{
                display:
                  questForm.values &&
                  (questForm.values.type === "tiered-one-time" ||
                    questForm.values.type === "tiered-multi-days")
                    ? "block"
                    : "none",
              }}
            >
              <TiersWhitelist
                viewerTiers={() => (
                  <FieldArray
                    style={{
                      ...(parametersSubTab !== 0 ? { display: "none" } : {}),
                    }}
                    name="viewerTiers"
                    component={this.renderViewerTiers}
                  />
                )}
                viewerTiersTabClasses={viewerTiersTabClasses}
                memberTiers={() => (
                  <FieldArray
                    style={{
                      ...(parametersSubTab !== 1 ? { display: "none" } : {}),
                    }}
                    name="memberTiers"
                    component={this.renderMemberTiers}
                  />
                )}
                memberTiersTabClasses={memberTiersTabClasses}
                whitelist={() => (
                  <FieldArray
                    style={{
                      ...(parametersSubTab !== 2 ? { display: "none" } : {}),
                    }}
                    name="whitelist"
                    component={this.renderWhiteList}
                  />
                )}
                whitelistTabClasses={whitelistTabClasses}
                subTab={parametersSubTab}
                setSubTab={(index) =>
                  this.setState({ parametersSubTab: index })
                }
              />
            </div>
          </div>
          {/* <div className="info-tab-wrap" style={(tab === 'steps') ? { display: 'block' } : { display: 'none' }}>
            <FieldArray
              name="steps"
              questType={(questForm.values) ? questForm.values.type : ''}
              requiredPreapproval={(questForm.values) ? questForm.values.requirePreapproval : false}
              component={this.renderSteps}
            />
          </div> */}
          <div
            className="info-tab-wrap"
            style={
              tab === "user-info" ? { display: "block" } : { display: "none" }
            }
          >
            <div className="Global__subTabs">
              <div
                className={classNames("Global__subTab", {
                  active: subTab === "landing",
                })}
                onClick={() => this.setState({ subTab: "landing" })}
                aria-hidden
              >
                {t("landing")}&nbsp;
                <i className={slugTabClasses} />
              </div>
              <div
                className={classNames("Global__subTab", {
                  active: subTab === "emails",
                })}
                onClick={() => this.setState({ subTab: "emails" })}
                aria-hidden
              >
                {t("emails")}
              </div>
              <div
                className={classNames("Global__subTab", {
                  active: subTab === "additional-questions",
                })}
                onClick={() =>
                  this.setState({ subTab: "additional-questions" })
                }
                aria-hidden
              >
                {t("additionalQuestions")}&nbsp;
                <i className={stepsPage1TabClasses} />
              </div>
              <div
                className={classNames("Global__subTab", {
                  active: subTab === "requirements",
                })}
                onClick={() => this.setState({ subTab: "requirements" })}
                aria-hidden
              >
                {t("requirements")}&nbsp;
                <i className={requirementsTabClasses} />
              </div>
            </div>
            <div
              className={classNames("QuestForm__userInfo", {
                active: subTab === "landing",
              })}
            >
              <div
                ref={(el) => {
                  this.slugE = el;
                }}
              />
              <Field
                type="text"
                name="slug"
                id="slug"
                placeholder={t("slug")}
                customLabel={t("slug")}
                component={CustomInput}
                normalize={(slug) => friendlyURICharacters(replaceSpaces(slug))}
              />
              {questForm.values && (
                <label className="Label__URL">
                  URL: {window.location.origin}/quests/{questForm.values.slug}
                </label>
              )}
              <div className="input-container main_desc">
                <label>{t("description")}</label>
                {this.props.questForm && this.props.questForm.values && (
                  <RichText
                    initialValue={this.props.questForm.values.description}
                    callback={(content) =>
                      this.handleRichTextUpdates(content, "description")
                    }
                    config={this.rte_default_config_landing}
                  />
                )}
              </div>
            </div>
            <div
              className={classNames("QuestForm__userInfo", {
                active: subTab === "emails",
              })}
            >
              <section className="WhiteListWrapper__header">
                <h3 className="WhiteListWrapper__header--title">
                  {t("approvalEmail")}
                </h3>
              </section>
              <div className="input-container main_desc">
                <label>
                  {t("remindersOtherInfo")}
                  <span className="tooltip">
                    <i className="tooltip-icon fa fa-info-circle" />
                    <div className="tooltip-content">
                      {t("remindersOtherInfoTip")}
                    </div>
                  </span>
                </label>
                {questForm.values && (
                  <RichText
                    callback={(content) =>
                      this.handleRichTextUpdates(content, "emailAdditionalInfo")
                    }
                    initialValue={questForm.values.emailAdditionalInfo}
                  />
                )}
              </div>
              <section className="WhiteListWrapper__header">
                <h3 className="WhiteListWrapper__header--title">
                  {t("failureEmail")}
                </h3>
              </section>
              <div className="input-container main_desc">
                <label>
                  {t("sendFailureEmail")}
                  <span className="tooltip">
                    <i className="tooltip-icon fa fa-info-circle" />
                    <div className="tooltip-content">
                      {t("sendFailureEmailTip")}
                    </div>
                  </span>
                </label>
                {emailFailureSentDate ? (
                  <span style={{ fontStyle: "italic" }}>
                    {" "}
                    {t("AutomatedFailureEmails")} {emailFailureSentDate}{" "}
                  </span>
                ) : (
                  <Field
                    name="emailFailure.shouldSend"
                    id="emailFailure.shouldSend"
                    type="checkbox"
                    placeholder={t("selectValue")}
                    component={CustomInputCheckbox}
                  />
                )}
              </div>
              <div className="input-container main_desc">
                <label>
                  {t("sendReminderEmail")}
                  <span className="tooltip">
                    <i className="tooltip-icon fa fa-info-circle" />
                    <div className="tooltip-content">
                      {t("sendReminderEmailTip")}
                    </div>
                  </span>
                </label>
                {emailReminderSentDate ? (
                  <span style={{ fontStyle: "italic" }}>
                    {" "}
                    {t("automatedReminderEmails")} {emailReminderSentDate}{" "}
                  </span>
                ) : !questMeetsReminderMin ? (
                  <span style={{ fontStyle: "italic" }}>
                    {" "}
                    {t("reminderEmailsNotSend")}
                  </span>
                ) : (
                  <Field
                    name="emailReminder.shouldSend"
                    id="emailReminder.shouldSend"
                    type="checkbox"
                    placeholder={t("selectValue")}
                    component={CustomInputCheckbox}
                  />
                )}
              </div>
            </div>
            <div
              className={classNames("QuestForm__userInfo", {
                active: subTab === "additional-questions",
              })}
            >
              <FieldArray
                name="stepsPage1"
                stepTypeOptions={questStepsTypesOptions}
                component={this.renderStepsGeneric}
                kind="additional-questions"
              />
              {/* {this.renderCopyUrl(1)} */}
            </div>
            <div
              className={classNames("QuestForm__userInfo", {
                active: subTab === "requirements",
              })}
            >
              <h2> {t("beforeTheStream")} </h2>
              <FieldArray
                name="requirements.beforeStream"
                stepTypeOptions={questStepsTypesOptions}
                component={this.renderRequirements}
                pointInQuest="beforeStream"
              />
              <h2> {t("duringTheStream")} </h2>
              <FieldArray
                name="requirements.duringStream"
                stepTypeOptions={questStepsTypesOptions}
                component={this.renderRequirements}
                pointInQuest="duringStream"
              />
              <h2> {t("afterTheStream")}</h2>
              <FieldArray
                name="requirements.afterStream"
                stepTypeOptions={questStepsTypesOptions}
                component={this.renderRequirements}
                pointInQuest="afterStream"
              />
            </div>
          </div>
          <div
            className="info-tab-wrap"
            style={tab === "faq" ? { display: "block" } : { display: "none" }}
          >
            <FieldArray name="faqs" component={this.renderFAQs} />
          </div>
          <div
            className="info-tab-wrap"
            style={tab === "logs" ? { display: "block" } : { display: "none" }}
          >
            {this.renderLogs()}
          </div>
          {tab !== "logs" && (
            <div className="form__footer">
              <input
                type="submit"
                className="CCQuests__save--btn"
                value={t("save")}
              />
              {action !== "create" && (
                <>
                  {/* <input type="button" onClick={() => this.getQuest(quest)} className="CCQuests__preview--btn" value="Preview Popup Modal" /> */}
                  <input
                    type="button"
                    onClick={() => {
                      const win = window.open(
                        `/quests/${questForm.values.slug}`,
                        "_blank"
                      );
                      win.focus();
                    }}
                    className="CCQuests__preview-slug--btn"
                    value={t("previewQuestPage")}
                  />
                  <input
                    data-tip={t("visualReference")}
                    type="button"
                    onClick={() => this.sendToRequirementsPreview(quest)}
                    className="CCQuests__preview-req--btn"
                    value={t("previewRequirementsPage")}
                  />
                </>
              )}
            </div>
          )}
        </form>
        <section className="ModalMaster">
          <section
            className={classNames("Modal Modal__upload Modal__upload__one", {
              active: activeModal === 1,
            })}
          >
            <div
              onClick={this.changeModalState.bind(this, 0)}
              className="Modal__layer"
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              {isLoading && <i className="fa fa-spinner fa-spin fa-3x fa-fw" />}
              {!isLoading && (
                <React.Fragment>
                  <div
                    className={classNames("Modal__upload__border", {
                      active: dropzoneActive,
                    })}
                  />
                  <div
                    className="Modal__close-btn"
                    style={{ display: dropzoneActive ? "none" : "block" }}
                    onClick={this.changeModalState.bind(this, 0)}
                    role="button"
                    aria-hidden
                  />
                  <Dropzone
                    ref={(node) => {
                      this.dropzoneRef = node;
                    }}
                    disableClick={disableClick}
                    className="Modal__upload__content"
                    onDrop={(e, event) => this.onDrop(event, e)}
                    onDragEnter={this.onDragEnter.bind(this)}
                    onDragLeave={this.onDragLeave.bind(this)}
                    disablePreview={false}
                    multiple={false}
                    accept={[".jpg", ".png"]}
                    inputProps={{ id: "file" }}
                  >
                    <div className="Modal__upload__image" />
                    <div className="Modal__upload__title">
                      {t("dragAndDrop")}
                    </div>
                    <div className="Modal__upload__or">Or</div>
                    <div className="Modal__upload__action">
                      <input
                        type="file"
                        name="file"
                        id="file"
                        className="inputFile"
                        onChange={this.handleSelectedFile}
                      />
                      <button className="cta__button">{t("chooseFile")}</button>
                    </div>
                    <div className="Modal__upload__or">
                      {t("fileSizeLimit")} <br />
                      {t("fileSizeRecommended")}
                    </div>
                  </Dropzone>
                </React.Fragment>
              )}
            </section>
          </section>
          <section
            className={classNames("Modal Modal__upload Modal__upload__two", {
              active: activeModal === 2,
            })}
          >
            <div
              onClick={this.changeModalState.bind(this, 0)}
              className="Modal__layer"
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div className="Modal__upload__border" />
              <div
                onClick={this.changeModalState.bind(this, 0)}
                className="Modal__close-btn"
                role="button"
                aria-hidden
              />
              <section className="Modal__upload__content">
                <div className="Modal__upload__image" />
                <div className="Modal__upload__title">
                  {t("uploadingTheImage")}
                </div>
                <div className="Modal__upload__loading">
                  <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                </div>
              </section>
            </section>
          </section>
          <section
            className={classNames("Modal Modal__upload Modal__upload__three", {
              active: activeModal === 3,
            })}
          >
            <div
              onClick={this.changeModalState.bind(this, 0)}
              className="Modal__layer"
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div
                onClick={this.changeModalState.bind(this, 0)}
                className="Modal__close-btn"
                role="button"
                aria-hidden
              />
              <section className="Modal__upload__content">
                <section className="Modal__upload__avatar">
                  <div
                    className="Modal__upload__avatar--container"
                    aria-hidden
                  />
                </section>
                <div className="Modal__upload__title2">
                  {t("uploadCompleted")}
                </div>
                <div
                  onClick={this.changeModalState.bind(this, 0)}
                  className="Modal__upload__button"
                  aria-hidden
                >
                  {t("changeImage")}
                </div>
              </section>
            </section>
          </section>
        </section>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  // if (!isBoolean(values.twitchAccountRequired)) {
  //   errors.twitchAccountRequired = t("validate.fieldRequired");
  // }

  // if (!values.twitchAccountRequired && values.requirePreapproval) {
  //   errors.twitchAccountRequired = t(
  //     "validate.questForm.twitchAccountRequired"
  //   );
  // }

  if (!values.type || values.type.trim() === "") {
    errors.type = t("validate.fieldRequired");
  }

  if (!values.title || values.title.trim() === "") {
    errors.title = t("validate.fieldRequired");
  }

  if (!values.status || values.status.trim() === "") {
    errors.status = t("validate.fieldRequired");
  }

  if (!isBoolean(values.isPrivate)) {
    errors.isPrivate = t("validate.fieldRequired");
  }

  if (
    values.type !== "tiered" &&
    values.type !== "tiered-one-time" &&
    values.type !== "tiered-multi-days" &&
    (typeof values.openSpots === "undefined" || values.openSpots === "")
  ) {
    errors.openSpots = t("validate.fieldRequired");
  }

  if (
    values.type !== "tiered" &&
    values.type !== "tiered-one-time" &&
    values.type !== "tiered-multi-days" &&
    (typeof values.minimumAverageViewers === "undefined" ||
      values.minimumAverageViewers === "")
  ) {
    errors.minimumAverageViewers = t("validate.fieldRequired");
  }

  const isValidStartDate = moment(
    values.startDateTime,
    dateStoreFormat,
    true
  ).isValid();
  const isValidEndDate = moment(
    values.endDateTime,
    dateStoreFormat,
    true
  ).isValid();

  if (!values.startDateTime) {
    errors.startDateTime = t("validate.fieldRequired");
  } else if (!isValidStartDate) {
    errors.startDateTime = `${t(
      "validate.questForm.dateRule"
    )} ${dateStoreFormat}`;
  }

  if (!values.endDateTime) {
    errors.endDateTime = t("validate.fieldRequired");
  } else if (!isValidEndDate) {
    errors.endDateTime = `${t(
      "validate.questForm.dateRule"
    )} ${dateStoreFormat}`;
  }

  const isValidOpenRegistrationDate = moment(
    values.openRegistrationDate,
    dateStoreFormat,
    true
  ).isValid();
  const isValidCloseRegistrationDate = moment(
    values.closeRegistrationDate,
    dateStoreFormat,
    true
  ).isValid();

  if (!values.openRegistrationDate) {
    errors.openRegistrationDate = t("validate.fieldRequired");
  } else if (!isValidOpenRegistrationDate) {
    errors.openRegistrationDate = `${t(
      "validate.questForm.dateRule"
    )} ${dateStoreFormat}`;
  }

  if (!values.closeRegistrationDate) {
    errors.closeRegistrationDate = t("validate.fieldRequired");
  } else if (!isValidCloseRegistrationDate) {
    errors.closeRegistrationDate = `${t(
      "validate.questForm.dateRule"
    )} ${dateStoreFormat}`;
  }

  if (values.type === "referral" && !values.referralPaymentType) {
    errors.referralPaymentType = t("validate.fieldRequired");
  }

  if (
    values.type === "referral" &&
    values.referralPaymentType &&
    values.referralPaymentType === "commission-amount" &&
    !values.commissionAmount
  ) {
    errors.commissionAmount = t("validate.fieldRequired");
  }

  if (
    values.type === "referral" &&
    values.referralPaymentType &&
    values.referralPaymentType === "commission-percentage" &&
    !values.commissionPercentage
  ) {
    errors.commissionPercentage = t("validate.fieldRequired");
  }

  if (
    values.type === "referral" &&
    values.referralPaymentType &&
    values.referralPaymentType === "commission-amount" &&
    !/^\d+(.\d{1,2})?$/i.test(values.commissionAmount)
  ) {
    errors.commissionAmount = t("validate.incorrectFormat");
  }

  if (
    values.type === "referral" &&
    values.referralPaymentType &&
    values.referralPaymentType === "commission-percentage" &&
    !/^[1-9][0-9]?$|^100$/i.test(values.commissionPercentage)
  ) {
    errors.commissionPercentage = t("validate.incorrectFormat");
  }

  if (
    values.type === "timed" &&
    (!values.requiredStreamTimeMinutes ||
      !isNumber(toNumber(values.requiredStreamTimeMinutes)) ||
      values.requiredStreamTimeMinutes <= 0)
  ) {
    errors.requiredStreamTimeMinutes = t("validate.required");
  }

  if (values.type === "timed" && values.requiredStreamTimeMinutes <= 0) {
    errors.requiredStreamTimeMinutes = t(
      "validate.questForm.requiredStreamTimeMinutes"
    );
  }

  if (
    values.isReferral === true &&
    values.referralBonus === true &&
    !values.salesForBonus > 0
  ) {
    errors.salesForBonus = t("validate.required");
  }

  if (
    values.isReferral === true &&
    values.referralBonus === true &&
    !values.bonusValue > 0
  ) {
    errors.bonusValue = t("validate.required");
  }

  if (
    values.isReferral === true &&
    values.referralBonus === true &&
    values.referralBonusType !== "bonus-in-percentage" &&
    values.referralBonusType !== "bonus-in-amount"
  ) {
    errors.referralBonusType = t("validate.required");
  }

  if (
    values.isReferral === true &&
    values.referralBonus === true &&
    values.referralBonusTimes !== "once" &&
    values.referralBonusTimes !== "every-sale"
  ) {
    errors.referralBonusTimes = t("validate.required");
  }

  if (!values.slug || values.slug.trim() === "") {
    errors.slug = t("validate.fieldRequired");
  }

  if (
    values.type === "timed" &&
    (values.paymentAmount < 0 || !isNumber(toNumber(values.paymentAmount)))
  ) {
    errors.paymentAmount = t("validate.fieldRequired");
  }

  if (
    values.type === "paid bounty" &&
    (values.dailyEarning < 0 || !isNumber(toNumber(values.dailyEarning)))
  ) {
    errors.dailyEarning = t("validate.fieldRequired");
  }

  if (
    values.type === "tiered-one-time" ||
    values.type === "tiered-multi-days" ||
    values.type === "paid bounty" ||
    values.type === "timed"
  ) {
    const requiredStreamTimeMinutes = toNumber(
      values.requiredStreamTimeMinutes
    );
    if (Number.isNaN(requiredStreamTimeMinutes)) {
      errors.requiredStreamTimeMinutes = t("validate.questForm.notNumber");
    }
    if (isNumber(requiredStreamTimeMinutes) && requiredStreamTimeMinutes <= 0) {
      errors.requiredStreamTimeMinutes = t("validate.questForm.gteThan0");
    }
  }

  if (values.viewerTiers && values.viewerTiers.length > 0) {
    const viewerTiersArrayErrors = [];
    values.viewerTiers.forEach((viewerTier, index) => {
      const viewerTierErrors = {};

      let isErroring = false;

      if (
        (!viewerTier.paymentAmount && viewerTier.paymentAmount !== 0) ||
        viewerTier.paymentAmount === ""
      ) {
        viewerTierErrors.paymentAmount = t("validate.fieldRequired");
        isErroring = true;
      } else {
        const paymentAmount = toNumber(viewerTier.paymentAmount);
        if (Number.isNaN(paymentAmount)) {
          viewerTierErrors.paymentAmount = t("validate.questForm.notNumber");
          isErroring = true;
        }
      }

      if (
        (!viewerTier.minimumAverageViewers &&
          viewerTier.minimumAverageViewers !== 0) ||
        viewerTier.minimumAverageViewers === ""
      ) {
        viewerTierErrors.minimumAverageViewers = t("validate.fieldRequired");
        isErroring = true;
      } else {
        const minimumAverageViewers = toNumber(
          viewerTier.minimumAverageViewers
        );
        if (Number.isNaN(minimumAverageViewers)) {
          viewerTierErrors.minimumAverageViewers = t(
            "validate.questForm.notNumber"
          );
          isErroring = true;
        }
      }

      if (!viewerTier.spotsAvailable || viewerTier.spotsAvailable === "") {
        viewerTierErrors.spotsAvailable = t("validate.fieldRequired");
        isErroring = true;
      } else {
        const spotsAvailable = toNumber(viewerTier.spotsAvailable);
        if (Number.isNaN(spotsAvailable)) {
          viewerTierErrors.spotsAvailable = t("validate.questForm.notNumber");
          isErroring = true;
        }
      }

      if (isErroring) viewerTiersArrayErrors[index] = viewerTierErrors;
    });

    if (viewerTiersArrayErrors.length) {
      errors.viewerTiers = viewerTiersArrayErrors;
    }
  }

  if (values.memberTiers && values.memberTiers.length > 0) {
    const memberTiersArrayErrors = [];
    values.memberTiers.forEach(({ paymentAmount, identity }, index) => {
      const memberTierErrors = {};
      const mtPaymentAmount = toNumber(paymentAmount);
      let isErroring = false;

      if (
        Number.isNaN(mtPaymentAmount) ||
        mtPaymentAmount === "" ||
        !mtPaymentAmount
      ) {
        memberTierErrors.paymentAmount = t("validate.questForm.notNumber");
        isErroring = true;
      }

      if (isNumber(mtPaymentAmount) && mtPaymentAmount <= 0) {
        memberTierErrors.paymentAmount = t("validate.questForm.greaterThan0");
        isErroring = true;
      }

      if (!identity || identity.trim() === "") {
        memberTierErrors.identity = t("validate.fieldRequired");
        isErroring = true;
      }

      if (isErroring) memberTiersArrayErrors[index] = memberTierErrors;
    });

    if (memberTiersArrayErrors.length) {
      errors.memberTiers = memberTiersArrayErrors;
    }
  }

  if (values.stepsPage1 && values.stepsPage1.length > 0) {
    const stepsPage1ArrayErrors = [];
    values.stepsPage1.forEach(({ title }, index) => {
      const stepsPage1Errors = {};
      let isErroring = false;

      if (!title || title.trim() === "") {
        stepsPage1Errors.title = t("validate.fieldRequired");
        isErroring = true;
      }

      // if (!description || description.trim() === '') {
      //   stepsPage1Errors.description = 'This field is required';
      //   isErroring = true;
      // }

      if (isErroring) stepsPage1ArrayErrors[index] = stepsPage1Errors;
    });

    if (stepsPage1ArrayErrors.length) {
      errors.stepsPage1 = stepsPage1ArrayErrors;
    }
  }

  if (values.whitelist && values.whitelist.length > 0) {
    const whitelistArrayErrors = [];

    values.whitelist.forEach(({ type, identity }, index) => {
      const whitelistErrors = {};
      let isErroring = false;

      if (!type) {
        whitelistErrors.type = t("validate.fieldRequired");
        isErroring = true;
      }

      if (!identity || identity === "") {
        whitelistErrors.identity = t("validate.fieldRequired");
        isErroring = true;
      }

      if (isErroring) whitelistArrayErrors[index] = whitelistErrors;
    });

    if (whitelistArrayErrors.length) {
      errors.whitelist = whitelistArrayErrors;
    }
  }

  const requiredBanner =
    values.requiredBanner === undefined || values.requiredBanner.trim() === "";
  const bannerLink =
    values.bannerLink === undefined || values.bannerLink.trim() === "";
  if (requiredBanner || bannerLink) {
    let displayBannerReq = false;
    const questSteps = [].concat(values.steps, values.stepsPage1);
    questSteps.forEach((step) => {
      if (
        step &&
        step.type === "display-download" &&
        step.subtype === "banner"
      ) {
        displayBannerReq = true;
      }
    });

    if (displayBannerReq) {
      if (requiredBanner)
        errors.requiredBanner = t("validate.questForm.requiredBanner");
      if (bannerLink)
        errors.bannerLink = errors.requiredBanner = t(
          "validate.questForm.requiredBanner"
        );
    }
  }

  if (!values.platforms) {
    errors.platforms = t("validate.fieldRequired");
  }

  if (!values.countries || values.countries.length <= 0) {
    errors.countries = t("validate.fieldRequired");
  }

  if (!values.languages || values.languages.length <= 0) {
    errors.languages = t("validate.fieldRequired");
  }

  if (values.requirements) {
    const steps = ["beforeStream", "duringStream", "afterStream"];
    const requirementErrors = {};
    let isHighErroring = false;

    steps.forEach((step) => {
      if (values.requirements[step] && values.requirements[step].length) {
        const currentStepErrors = [];

        values.requirements[step].forEach(({ title }, index) => {
          const reqErrors = {};
          let isErroring = false;

          if (!title || title.trim() === "") {
            reqErrors.title = t("validate.fieldRequired");
            isErroring = true;
          }

          // if (showOnApp && (!appPageTitle || appPageTitle.trim() === '')) {
          //   reqErrors.appPageTitle = '"Show on Application Page" is checked.';
          //   isErroring = true;
          // }

          if (isErroring) {
            currentStepErrors[index] = reqErrors;
          }
        });

        if (currentStepErrors.length) {
          isHighErroring = true;
          requirementErrors[step] = currentStepErrors;
        }
      }
    });

    if (isHighErroring) {
      errors.requirements = requirementErrors;
    }
  }

  return errors;
};

QuestForm.propTypes = {
  //  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation("questForm")(
  reduxForm({
    form: "questForm",
    validate,
  })(QuestForm)
);
