import { createStore, applyMiddleware } from "redux";
import { syncHistoryWithStore } from "react-router-redux";
import { browserHistory } from "react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import * as Sentry from "@sentry/react";
import timeago from "timeago.js";
import timeagoES from "timeago.js/locales/es";
import jwtDecode from "jwt-decode";
import rootReducer from "./reducers/index";
import rootEpic from "./epics/index";

const epicMiddleware = createEpicMiddleware(rootEpic);
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

timeago.register("es", timeagoES);

const token = localStorage.getItem("token");
const decoded = token ? jwtDecode(token) : null;
const auth = decoded
  ? {
      user: decoded.user,
      token,
      isAuthenticated: true,
    }
  : {};

const genericStore = {
  isLoading: false,
  errors: {
    hasError: false,
    code: "",
    message: "",
    all: {},
  },
};

// create an object for the default data
const defaultState = {
  home: {
    quests: {
      guest: {
        ...genericStore,
        data: [],
        limit: 10,
        total: 0,
        page: 0,
      },
      all: {
        ...genericStore,
        data: [],
        limit: 10,
        total: 0,
        page: 0, // To identify hydtrated navigationss
      },
      ended: {
        ...genericStore,
        data: [],
        limit: 10,
        total: 0,
        page: 0, // To identify hydtrated navigationss
      },
    },
  },
  auth: {
    twitch: {
      ...genericStore,
    },
    twitter: {
      ...genericStore,
    },
    youtube: {
      isDisconnecting: false,
      ...genericStore,
    },
    isAuthenticated: false,
    rehydrated: false,
    token: "",
    user: {
      connectedAccounts: {
        twitch: {
          id: null,
          username: null,
        },
        youtube: {
          id: null,
          username: null,
        },
        huya: {
          id: null,
          username: null,
        },
        twitter: {
          id: null,
          username: null,
        },
      },
    },
    isUpdating: false,
    ...genericStore,
    ...auth, // This tries to update the token info if token exists in sessionStorage
  },
  config: {
    myPreApprovedGameKeys: {
      ...genericStore,
      isRequestingAGameKey: false,
      data: [],
    },
    myGameKeys: {
      ...genericStore,
      data: [],
    },
    featuredGames: {
      ...genericStore,
      data: [],
    },
    game: {
      ...genericStore,
      data: {},
    },
    games: {
      ...genericStore,
      data: [],
    },
    quest: {
      ...genericStore,
      isJoiningToAQuest: false,
      isLeavingAQuest: false,
      isCreating: false,
      isShowing: false,
      isVerifyingYoutube: false,
      data: {},
    },
    quests: {
      ...genericStore,
      isJoiningToAQuest: false,
      isLeavingAQuest: false,
      isCreating: false,
      data: [],
    },
    sfgame: {
      ...genericStore,
      data: [],
    },
    stripe: {
      publicKey: process.env.STRIPE_KEY || "pk_test_tIqWqsm5WFH2RzOKWk5xlowZ",
    },
    rules: {
      ...genericStore,
      data: [],
    },
    userQuestReferral: {
      ...genericStore,
      data: [],
    },
    storeGames: {
      ...genericStore,
      data: [],
    },
  },
  user: {
    myPreApprovedGameKeys: {
      ...genericStore,
      isRequestingAGameKey: false,
      data: [],
    },
    broadcasts: {
      ...genericStore,
      data: [],
    },
    myBounties: {
      ...genericStore,
      isJoiningToABounty: false,
      isLeavingABounty: false,
      data: [],
    },
    myQuests: {
      ...genericStore,
      isJoiningToAQuest: false,
      isLeavingAQuest: false,
      data: [],
    },
    myUserQuests: {
      ...genericStore,
      data: [],
    },
    myPayments: {
      ...genericStore,
      data: [],
    },
    todayActivity: {
      ...genericStore,
      data: [],
    },
    referralsActivity: {
      ...genericStore,
      data: [],
    },
    timedActivity: {
      ...genericStore,
      data: [],
    },
    allUserPayments: {
      ...genericStore,
      data: {},
    },
    tieredActivity: {
      ...genericStore,
      data: [],
    },
    tieredMultipleDaysActivity: {
      ...genericStore,
      data: [],
    },
    link: {
      ...genericStore,
      data: [],
    },
    preSignedUrl: {
      ...genericStore,
      data: [],
    },
  },
  admin: {
    offPlatform: {
      ...genericStore,
      data: [],
      user: {},
    },
    csvUpload: {
      ...genericStore,
      data: {
        processed: {},
        errors: {},
        creations: {},
      },
    },
    reports: {
      liveStreams: {
        ...genericStore,
        game: {},
        data: [],
      },
      bounties: {
        ...genericStore,
        data: [],
      },
    },
    users: {
      ...genericStore,
      data: [],
      pagination: {},
    },
    usersCsv: {
      ...genericStore,
      data: [],
    },
    user: {
      ...genericStore,
      data: {},
    },
    configs: {
      ...genericStore,
      data: [],
    },
    config: {
      ...genericStore,
      data: [],
    },
    games: {
      ...genericStore,
      data: [],
    },
    game: {
      ...genericStore,
      isCreating: false,
      isUpdating: false,
      data: {},
    },
    platforms: {
      ...genericStore,
      data: [],
    },
    payments: {
      ...genericStore,
      data: {},
      pagination: {},
    },
    paymentsCsv: {
      ...genericStore,
      data: {},
      pagination: {},
    },
    paypalPayments: {
      ...genericStore,
      data: {},
    },
    quests: {
      ...genericStore,
      data: [],
    },
    preSignedUrl: {
      ...genericStore,
      data: {},
    },
    trackedGames: {
      ...genericStore,
      data: [],
    },
    tieredReports: {
      ...genericStore,
      data: [],
    },
    referralReports: {
      ...genericStore,
      data: [],
    },
  },
  commandCenter: {
    games: {
      ...genericStore,
      data: [],
    },
    gameKeys: {
      ...genericStore,
      data: [],
      usersWaitingForKeys: [],
    },
    bountyPredictions: {
      ...genericStore,
      data: [],
    },
    uploadKeys: {
      ...genericStore,
      data: {
        createdTotal: 0,
        repeatedTotal: 0,
        createdKeys: [],
        repeatedKeys: [],
      },
    },
    uploadLinks: {
      ...genericStore,
      data: {
        createdTotal: 0,
        repeatedTotal: 0,
        createdLinks: [],
        repeatedLinks: [],
      },
    },
    linkAssignment: {
      ...genericStore,
      data: {},
    },
    deletedLinks: {
      ...genericStore,
      data: {},
    },
    whiteList: {
      ...genericStore,
      data: [],
    },
    findUsers: {
      ...genericStore,
      data: [],
      total: 0,
      showing: 0,
    },
    gameActivity: {
      ...genericStore,
      data: [],
      total: 0,
    },
    quests: {
      ...genericStore,
      data: [],
      isCreating: false,
    },
    quest: {
      ...genericStore,
      isCreating: false,
      isUpdating: false,
      data: {},
    },
    questActivity: {
      ...genericStore,
      data: [],
    },
    questSales: {
      ...genericStore,
      data: [],
    },
    questTimed: {
      ...genericStore,
      data: [],
    },
    questGeneral: {
      ...genericStore,
      data: [],
      successCount: 0,
      batchErrorMessage: null,
      pagination: {},
    },
    questGeneralCsv: {
      ...genericStore,
      data: [],
      pagination: {},
    },
    questAffiliate: {
      ...genericStore,
      data: [],
    },
    reputationSystem: {
      ...genericStore,
      data: [],
    },
    adminActivityLogs: {
      ...genericStore,
      data: [],
    },
    questTiered: {
      ...genericStore,
      data: [],
      dataReferral: [],
      dataKeysAvailables: 0,
      questSummary: {},
    },
    questTieredMultipleDays: {
      ...genericStore,
      data: [],
    },
    preSignedUrl: {
      ...genericStore,
      data: [],
    },
    campaigns: {
      ...genericStore,
      data: [],
    },
    campaign: {
      ...genericStore,
      isCreating: false,
      isUpdating: false,
      data: {},
    },
    brandUsers: {
      ...genericStore,
      data: [],
    },
    userQuests: {
      ...genericStore,
      data: [],
    },
    activeQuests: {
      ...genericStore,
      data: [],
    },
    activeGames: {
      ...genericStore,
      data: [],
    },
    activeUserQuests: {
      ...genericStore,
      isLoadingSpecial: false,
      data: [],
    },
    linksDashboard: {
      ...genericStore,
      data: [],
    },
  },
  brandUser: {
    campaign: {
      ...genericStore,
      data: {},
    },
    campaigns: {
      ...genericStore,
      data: [],
    },
    deliverable: {
      ...genericStore,
      data: {},
    },
    games: {
      ...genericStore,
      data: [],
    },
    preSignedUrl: {
      ...genericStore,
      data: {},
    },
    quests: {
      ...genericStore,
      data: [],
    },
    streamers: {
      ...genericStore,
      data: [],
    },
  },
};

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options if needed
});

const shouldLog = process.env.LOG_STATE === true;
const logger = (store) => (next) => (action) => {
  if (shouldLog) console.log("dispatching", action);
  const result = next(action);
  if (shouldLog) console.log("next state", store.getState());
  return result;
};

const store = createStore(
  rootReducer,
  defaultState,
  composeEnhancers(
    applyMiddleware(epicMiddleware),
    applyMiddleware(thunk),
    applyMiddleware(promise()),
    sentryReduxEnhancer,
    applyMiddleware(logger)
    // other store enhancers if any
  )
);

export const history = syncHistoryWithStore(browserHistory, store);

export default store;
