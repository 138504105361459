/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import { Field, FieldArray, reduxForm } from "redux-form";
import classNames from "classnames";
import { findIndex, isBoolean } from "lodash";
import Dropzone from "react-dropzone";
import { Button, TextField } from "@material-ui/core";

import CustomDateTimePicker from "../../utils/redux-forms-custom-fields/CustomDateTimePicker";
import CustomInput from "../../utils/redux-forms-custom-fields/CustomInput";
import CustomInputCheckbox from "../../utils/redux-forms-custom-fields/CustomInputCheckbox";
import CustomInputYesNoRadio from "../../utils/redux-forms-custom-fields/CustomInputYesNoRadio";
import CustomDropDownGeneric from "../../utils/redux-forms-custom-fields/CustomDropDownGeneric";
import RichText from "../../utils/RichText";
import { simpleNotification } from "../../utils/notifications";
import "./skin.min.css";
import ArrayDropDown from "../../utils/redux-forms-custom-fields/ArrayDropDown";
import { arrayDropDownColors } from "../../utils/constants";

import Platform from "../platform/Platform";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const dateStoreFormat = "YYYY-MM-DD";
const dateDisplayFormat = "MM-DD-YYYY";

const dateStoreFormat2 = "YYYY-MM-DD HH:mm";
const dateDisplayFormat2 = "MM-DD-YYYY HH:mm";
class AdminGameForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.rte_description = "";
    this.rte_saleDescription = "";
    this.rte_storeDescription = "";
    this.rte_soldOutMessage = "";
    this.rte_actionMessage = "";
    this.rte_api_key = "wulnzhippckjtehbc6kaq1irabfkdvhgkgjezk8i24a9c653";
    this.rte_default_config = {
      skin: "dark",
      menubar: false,
      plugins: "link, lists, advlist",
      toolbar1:
        "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent  | removeformat",
      content_style:
        "#tinymce{background-color:#282830 !important;color:#fff !important;}",
    };
    this.state = {
      infoTab: "general",
      activeModal: 0,
      categories: [],
      gallery: [],
      image: "",
      file: "",
      videoUrl: "",
      platformModal: false,
    };
    this.galleryId = 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = nextProps;

    this.setState({
      categories: [
        {
          name: t("categoriesOption.action"),
          selected: false,
        },
        {
          name: t("categoriesOption.adventure"),
          selected: false,
        },
        {
          name: t("categoriesOption.shooter"),
          selected: false,
        },
        {
          name: t("categoriesOption.Survival"),
          selected: false,
        },
        {
          name: t("categoriesOption.openWorld"),
          selected: false,
        },
      ],
    });

    if (
      this.props.preSignedUrl.isLoading === true &&
      nextProps.preSignedUrl.isLoading === false
    ) {
      const { data: signedUrl } = nextProps.preSignedUrl;
      this.uploadToS3(this.state.file, signedUrl.preSignedUrl);
    }
    if (
      this.props.game.isCreating === true &&
      nextProps.game.isCreating === false
    ) {
      if (nextProps.game.errors.hasError) {
        if (nextProps.game.errors.code === "E_GAME_SLUG_DUPLICATED") {
          this.setState({ infoTab: "sales" });
          // this.scrollToSlug();
        }
      }
    }
    if (
      this.props.game.isUpdating === true &&
      nextProps.game.isUpdating === false
    ) {
      if (nextProps.game.errors.hasError) {
        if (nextProps.game.errors.code === "E_GAME_SLUG_DUPLICATED") {
          this.setState({ infoTab: "sales" });
          // this.scrollToSlug();
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.adminGameForm &&
      prevProps.adminGameForm.values === undefined &&
      this.props.adminGameForm.values
    ) {
      const { categories, gallery } = this.props.adminGameForm.values;
      // Setting up cateogries
      for (let i = 0; i < this.state.categories.length; i += 1) {
        if (
          findIndex(categories, { name: this.state.categories[i].name }) >= 0
        ) {
          const tempCategories = [...this.state.categories];
          tempCategories[i].selected = true;
          this.setState({
            categories: [...tempCategories],
          });
        }
      }
      // Setting up gallery
      for (let i = 0; i < gallery.length; i += 1) {
        this.galleryId += 1;
        this.state.gallery.push({
          id: this.galleryId,
        });
      }
    }
  }

  onDragEnter() {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave() {
    this.setState({ dropzoneActive: false });
  }

  onDrop(files, e) {
    const file = files && files.length ? files[0] : e[0];

    this.setState({ dropzoneActive: false });
    const { image } = this.state;
    /* if (image === 'cover' && file.size > 700000) {
      if (file.size > 700000) {
        simpleNotification({
          level: 'warning',
          title: 'Warning',
          message: 'Cover image has a limit of 700kb.',
        });
      }
    } else if ((image === 'developer-logo' || image === 'logo') && file.size > 400000) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Logo image has a limit of 400kb.',
      });
    } else if ((image === 'gallery-image') && file.size > 500000) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Gallery image has a limit of 500kb.',
      });
    } */
    this.setState({
      file,
    });
    const format = file.name.split(".")[1];
    const slug = this.getSlug();
    const { token } = this.props.auth;
    if (
      image === "cover" ||
      image === "coverPortrait" ||
      image === "logo" ||
      image === "developer-logo" ||
      image === "gallery-image"
    ) {
      this.props.requestUploadImageUrl(slug, format, token);
    }

    this.setState({
      activeModal: 2,
    });
    // this.uploadToS3(file);
  }

  handleVideoUrl = (event) => {
    this.setState({
      videoUrl: event.target.value,
    });
  };

  getSlug = () => {
    const { adminGameForm } = this.props;
    const { name } = adminGameForm.values;
    if (!name) {
      return "";
    }
    let slug = name.replace(/[^0-9a-z ]/gi, "");
    slug = slug.split("  ").join(" ");
    slug = slug.split(" ").join("-");
    slug = slug.toLowerCase();
    return slug;
  };

  changeCategory = (index) => {
    const tempCategories = [...this.state.categories];
    tempCategories[index].selected = !this.state.categories[index].selected;
    this.setState({
      categories: [...tempCategories],
    });
  };

  uploadToS3 = (file, preSignedUrl) => {
    const { t } = this.props;
    const { image } = this.state;
    if (
      (image === "cover" || image === "coverPortrait") &&
      file.size > 700000
    ) {
      if (file.size > 700000) {
        simpleNotification({
          level: "warning",
          title: t("warning"),
          message: t("coverImageMessage"),
        });
        this.setState({
          activeModal: 1,
        });
        return;
      }
    } else if (
      (image === "developer-logo" || image === "logo") &&
      file.size > 400000
    ) {
      simpleNotification({
        level: "warning",
        title: t("warning"),
        message: t("logoImageMessage"),
      });
      this.setState({
        activeModal: 1,
      });
      return;
    } else if (image === "gallery-image" && file.size > 500000) {
      simpleNotification({
        level: "warning",
        title: t("warning"),
        message: t("galleryImageMessage"),
      });
      this.setState({
        activeModal: 1,
      });
      return;
    }
    this.setState({
      activeModal: 2,
    });
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", preSignedUrl);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.addEventListener("load", this.handleLoad);
    xhr.send(file);
  };

  handleLoad = (event) => {
    const { t } = this.props;
    const { data: signedUrl } = this.props.preSignedUrl;
    const { image, videoUrl } = this.state;

    if (image !== "video" && event.target.status !== 200) {
      this.setState({
        activeModal: 0,
      });
      simpleNotification({
        level: "error",
        title: t("error"),
        message: t("errorUploadImageMessage"),
      });
      return;
    } else if (image === "video" && !videoUrl) {
      this.setState({
        activeModal: 0,
      });
      simpleNotification({
        level: "error",
        title: t("error"),
        message: t("errorUploadYoutubeMessage"),
      });
      return;
    }

    if (image === "video" && videoUrl) {
      simpleNotification({
        level: "success",
        title: t("success"),
        message: t("successUploadYoutubeMessage"),
      });
    } else {
      simpleNotification({
        level: "success",
        title: t("success"),
        message: t("successUploadImageMessage"),
      });
    }

    if (image === "cover") {
      this.props.updateAdminGameForm({ cover: signedUrl.url });
    } else if (image === "coverPortrait") {
      this.props.updateAdminGameForm({ coverPortrait: signedUrl.url });
    } else if (image === "logo") {
      this.props.updateAdminGameForm({ logo: signedUrl.url });
    } else if (image === "developer-logo") {
      this.props.updateAdminGameForm({ developerLogo: signedUrl.url });
    } else if (image === "gallery-image") {
      const { adminGameForm } = this.props;
      const { gallery } = adminGameForm.values;
      gallery.push({
        type: "image",
        url: signedUrl.url,
        thumb: signedUrl.url,
      });
      this.props.updateAdminGameForm({ gallery });
    } else if (image === "video") {
      const { adminGameForm } = this.props;
      const { gallery } = adminGameForm.values;
      gallery.push({
        type: "video",
        url: `https://www.youtube.com/embed/${videoUrl}?autoplay=1`,
        thumb: `https://img.youtube.com/vi/${videoUrl}/hqdefault.jpg`,
      });
      this.props.updateAdminGameForm({ gallery });
    }

    this.setState({
      activeModal: 0,
      videoUrl: "",
    });
  };

  generateCategoryInputs() {
    return this.state.categories.map((category, index) => (
      <span
        className={classNames("", { checked: category.selected })}
        aria-hidden
        onClick={this.changeCategory.bind(this, index)}
      >
        {category.selected ? (
          <i className="fa fa-check" aria-hidden />
        ) : (
          <i className="fa fa-times" aria-hidden />
        )}
        &nbsp;
        {category.name}
      </span>
    ));
  }

  generateGalleryInputs() {
    const { gallery } = this.state;
    const { t } = this.props;
    return gallery.map((gal, index) => (
      <div className="gallery__item" key={index}>
        <div
          onClick={() => {
            this.state.gallery.splice(index, 1);
            this.setState({});
          }}
          className="Modal__close-btn"
          aria-hidden
        />
        <Field
          type="text"
          name={`galleryType${gal.id}`}
          id={`galleryType${gal.id}`}
          placeholder={t("type")}
          customLabel={<label>{t("type")}</label>}
          component={CustomInput}
        />
        <Field
          type="text"
          name={`galleryUrl${gal.id}`}
          id={`galleryUrl${gal.id}`}
          placeholder={t("url")}
          customLabel={<label>{t("url")}</label>}
          component={CustomInput}
        />
        <Field
          type="text"
          name={`galleryThumb${gal.id}`}
          id={`galleryThumb${gal.id}`}
          placeholder={t("thumb")}
          customLabel={<label>{t("thumb")}</label>}
          component={CustomInput}
        />
      </div>
    ));
  }

  addToGallery() {
    this.galleryId += 1;
    this.setState((prevState) => ({
      gallery: [...prevState.gallery, { id: this.galleryId }],
    }));
  }

  submit = (info) => {
    const { adminGameForm = {} } = this.props;
    const adminGameEditInfo = {
      name: info.name,
      slug: info.slug || "",
      nameInTwitch: info.nameInTwitch,
      nameInYoutube: info.nameInYoutube,
      price: info.price ? parseFloat(info.price) : 0,
      salePrice: info.salePrice ? parseFloat(info.salePrice) : 0,
      saleEndDate: info.saleEndDate,
      status: info.status,
      sponsorType: info.sponsorType,
      developer: info.developer,
      publisher: info.publisher || "",
      releaseDate: info.releaseDate,
      isPrivate: info.isPrivate,
      isOnSale: info.isOnSale,
      displayOnNoizStore: info.displayOnNoizStore,
      storeDescription: info.storeDescription,
      gallery: info.gallery,
      cover: info.cover || "",
      coverPortrait: info.coverPortrait || "",
      logo: info.logo || "",
      developerLogo: info.developerLogo || "",
      video: info.video || "",
      actionButtonText: info.actionButtonText || "",
      actionButtonLink: info.actionButtonLink || "",
      soldOutMessage: adminGameForm.values
        ? adminGameForm.values.soldOutMessage
        : "",
      description: adminGameForm.values ? adminGameForm.values.description : "",
      actionMessage: adminGameForm.values
        ? adminGameForm.values.actionMessage
        : "",
      saleDescription: adminGameForm.values
        ? adminGameForm.values.saleDescription
        : "",
      headlineVideo: info.headlineVideo || "",
      headline: info.headline || "",
      gamePageURL: info.gamePageURL || "",
      platforms: info.platforms || [],
    };

    // adminGameEditInfo.description = (adminGameForm && adminGameForm.values) ? adminGameForm.values.description : '';
    // adminGameEditInfo.saleDescription = (adminGameForm && adminGameForm.values) ? adminGameForm.values.saleDescription : '';
    // adminGameEditInfo.storeDescription = (adminGameForm && adminGameForm.values) ? adminGameForm.values.storeDescription : '';
    // adminGameEditInfo.soldOutMessage = (adminGameForm && adminGameForm.values) ? adminGameForm.values.soldOutMessage : '';
    // adminGameEditInfo.actionMessage = (adminGameForm && adminGameForm.values) ? adminGameForm.values.actionMessage : '';

    const socialNetworks = [];

    if (info.twitter && info.twitter.trim() !== "") {
      socialNetworks.push({
        name: "twitter",
        url: info.twitter,
      });
    }
    if (info.facebook && info.facebook.trim() !== "") {
      socialNetworks.push({
        name: "facebook",
        url: info.facebook,
      });
    }
    if (info.instagram && info.instagram.trim() !== "") {
      socialNetworks.push({
        name: "instagram",
        url: info.instagram,
      });
    }
    if (info.twitch && info.twitch.trim() !== "") {
      socialNetworks.push({
        name: "twitch",
        url: info.twitch,
      });
    }
    if (info.youtube && info.youtube.trim() !== "") {
      socialNetworks.push({
        name: "youtube",
        url: info.youtube,
      });
    }

    adminGameEditInfo.socialNetworks = socialNetworks;

    const categories = [];
    for (let i = 0; i < this.state.categories.length; i += 1) {
      if (this.state.categories[i].selected) {
        categories.push({
          name: this.state.categories[i].name,
          url: this.state.categories[i].name,
        });
      }
    }

    adminGameEditInfo.categories = categories;

    if (
      !adminGameEditInfo.description ||
      adminGameEditInfo.description.trim() === ""
    ) {
      return;
    }

    Object.keys(adminGameEditInfo).forEach((key) => {
      const value = adminGameEditInfo[key];
      if (typeof value === "string") {
        adminGameEditInfo[key] = value.trim();
      }
    });

    if (adminGameEditInfo.platforms && adminGameEditInfo.platforms.length > 0) {
      adminGameEditInfo.platforms = adminGameEditInfo.platforms.map(
        (p) => p.value
      );
    }

    this.props.onSubmit(adminGameEditInfo);
  };

  changeStep = (value) => {
    this.setState({
      activeModal: value,
    });
  };

  orderGallery = (index, direction) => {
    const swapIndex = direction === "up" ? index - 1 : index + 1;
    const { adminGameForm } = this.props;
    const arr = adminGameForm.values.gallery;
    [arr[index], arr[swapIndex]] = [arr[swapIndex], arr[index]];
    this.props.swapGameGallery(arr);
  };

  uploadCover = () => {
    const { t } = this.props;
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: "warning",
        title: t("warning"),
        message: t("fillNameFieldMessage"),
      });
      return;
    }
    this.setState({
      activeModal: 1,
      image: "cover",
    });
  };

  uploadCoverPortrait = () => {
    const { t } = this.props;
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: "warning",
        title: t("warning"),
        message: t("fillNameFieldMessage"),
      });
      return;
    }
    this.setState({
      activeModal: 1,
      image: "coverPortrait",
    });
  };

  uploadLogo = () => {
    const { t } = this.props;
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: "warning",
        title: t("warning"),
        message: t("fillNameFieldMessage"),
      });
      return;
    }
    // const { token } = this.props.auth;
    // this.props.requestUploadImageUrl(slug, 'logo', token);
    this.setState({
      activeModal: 1,
      image: "logo",
    });
  };

  uploadDeveloperLogo = () => {
    const { t } = this.props;
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: "warning",
        title: t("warning"),
        message: t("fillNameFieldMessage"),
      });
      return;
    }
    // const { token } = this.props.auth;
    // this.props.requestUploadImageUrl(slug, 'developer-logo', token);
    this.setState({
      activeModal: 1,
      image: "developer-logo",
    });
  };

  uploadGalleryImage = () => {
    const { t } = this.props;
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: "warning",
        title: t("warning"),
        message: t("fillNameFieldMessage"),
      });
      return;
    }
    // const { token } = this.props.auth;
    // this.props.requestUploadImageUrl(slug, 'gallery-image', token);
    this.setState({
      activeModal: 1,
      image: "gallery-image",
    });
  };

  uploadGalleryVideo = () => {
    const { t } = this.props;
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: "warning",
        title: t("warning"),
        message: t("fillNameFieldMessage"),
      });
      return;
    }

    this.setState({
      activeModal: 4,
      image: "video",
    });
  };

  handleEditorChange = (content, section) => {
    switch (section) {
      case "description":
        this.props.updateAdminGameForm({ description: content });
        break;
      case "saleDescription":
        this.props.updateAdminGameForm({ saleDescription: content });
        break;
      case "soldOutMessage":
        this.props.updateAdminGameForm({ soldOutMessage: content });
        break;
      case "actionMessage":
        this.props.updateAdminGameForm({ actionMessage: content });
        break;
      default:
        break;
    }
  };

  renderGallery = ({
    fields,
    meta: { touched, error },
    uploadGalleryImage,
  }) => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div
          className="gallery__add"
          style={{ display: fields.length > 0 ? "inline-block" : "none" }}
          onClick={uploadGalleryImage}
          aria-hidden
        >
          <i className="fa fa-upload" aria-hidden />
          &nbsp;{t("uploadImage")}
        </div>
        <div
          className="gallery__add"
          style={{ display: fields.length > 0 ? "inline-block" : "none" }}
          onClick={this.uploadGalleryVideo}
          aria-hidden
        >
          <i className="fa fa-upload" aria-hidden />
          &nbsp;{t("uploadYouTubeLink")}
        </div>
        {fields.map((member, index) => (
          <div className="gallery__item" key={index}>
            <div
              onClick={() => fields.remove(index)}
              className="Modal__close-btn"
              aria-hidden
            />
            <div
              className="GameForm__gallery--up"
              onClick={this.orderGallery.bind(this, index, "up")}
              style={{ display: index !== 0 ? "block" : "none" }}
              aria-hidden
            >
              <i className="fa fa-arrow-up" aria-hidden />
            </div>
            <div
              className="GameForm__gallery--down"
              onClick={this.orderGallery.bind(this, index, "down")}
              style={{
                display: index !== fields.length - 1 ? "block" : "none",
              }}
              aria-hidden
            >
              <i className="fa fa-arrow-down" aria-hidden />
            </div>
            <Field
              name={`${member}.type`}
              placeholder={t("typePlaceHolder")}
              customLabel={<label>{t("type")}</label>}
              component={CustomDropDownGeneric}
              options={[t("typeOptions.0"), t("typeOptions.1")]}
            />
            <Field
              name={`${member}.url`}
              type="text"
              component={CustomInput}
              customLabel={<label>{t("url")}</label>}
              placeholder={t("url")}
            />
            <Field
              name={`${member}.thumb`}
              type="text"
              component={CustomInput}
              customLabel={<label>{t("thumb")}</label>}
              placeholder={t("thumbPlaceHolder")}
            />
          </div>
        ))}
        <div className="gallery__add" onClick={uploadGalleryImage} aria-hidden>
          <i className="fa fa-upload" aria-hidden />
          &nbsp;{t("uploadImage")}
        </div>
        <div
          className="gallery__add"
          onClick={this.uploadGalleryVideo}
          aria-hidden
        >
          <i className="fa fa-upload" aria-hidden />
          &nbsp;{t("uploadYouTubeLink")}
        </div>
      </React.Fragment>
    );
  };

  platformOptions = () => {
    const { platforms } = this.props;

    const grouped = platforms.reduce((acc, pl) => {
      if (!acc[pl.baseService]) {
        acc[pl.baseService] = [];
      }
      acc[pl.baseService].push(pl);
      return acc;
    }, {});

    return Object.keys(grouped).map((group) => ({
      label: group,
      options: grouped[group].map(({ id, platform }) => ({
        value: id,
        label: <span style={{ zIndex: "1000" }}>{platform}</span>,
      })),
    }));
  };

  togglePlatformModal = () => {
    this.setState({ platformModal: !this.state.platformModal });
  };

  render() {
    const {
      adminGameForm = {},
      auth: { token },
      createAPlatform,
      handleSubmit,
      t,
    } = this.props;
    const { activeModal, image, infoTab, platformModal, videoUrl } = this.state;

    let imageText = "";
    let lessThan = 0;
    let recommendedDimensions = "";

    if (image === "cover") {
      imageText = t("cover");
      lessThan = 700;
      recommendedDimensions = "1700px x 956px (16:9)";
    } else if (image === "logo") {
      imageText = t("imageText.logo");
      lessThan = 400;
      recommendedDimensions = "150px x 150px";
    } else if (image === "developer-logo") {
      imageText = t("imageText.developerLogo");
      lessThan = 400;
      recommendedDimensions = "150px x 150px";
    } else if (image === "gallery-image") {
      imageText = t("imageText.galleryImage");
      lessThan = 500;
      recommendedDimensions = "1400px x 788px (16:9)";
    }

    return (
      <React.Fragment>
        {platformModal && (
          <Platform
            modal={platformModal}
            toggleModal={this.togglePlatformModal}
            createAPlatform={createAPlatform}
            token={token}
          />
        )}
        <div className="Global__tabs">
          <div
            className={classNames("Global__tab", {
              active: infoTab === "general",
            })}
            onClick={() => this.setState({ infoTab: "general" })}
            aria-hidden
          >
            {t("general")}
          </div>
          <div
            className={classNames("Global__tab", {
              active: infoTab === "assets",
            })}
            onClick={() => this.setState({ infoTab: "assets" })}
            aria-hidden
          >
            {t("assets")}
          </div>
          <div
            className={classNames("Global__tab", {
              active: infoTab === "sales",
            })}
            onClick={() => this.setState({ infoTab: "sales" })}
            aria-hidden
          >
            {t("sales")}
          </div>
          <div
            className={classNames("Global__tab", {
              active: infoTab === "store",
            })}
            onClick={() => this.setState({ infoTab: "store" })}
            aria-hidden
          >
            {t("store")}
          </div>
          <div
            className={classNames("Global__tab", {
              active: infoTab === "gallery",
            })}
            onClick={() => this.setState({ infoTab: "gallery" })}
            aria-hidden
          >
            {t("gallery")}
          </div>
          <div
            className={classNames("Global__tab", {
              active: infoTab === "categories",
            })}
            onClick={() => this.setState({ infoTab: "categories" })}
            aria-hidden
          >
            {t("categories")}
          </div>
          <div
            className={classNames("Global__tab", {
              active: infoTab === "social",
            })}
            onClick={() => this.setState({ infoTab: "social" })}
            aria-hidden
          >
            {t("social")}
          </div>
        </div>
        <form
          className="form-container admin-game-form"
          autoComplete="off"
          onSubmit={handleSubmit(this.submit.bind(this))}
        >
          <div
            className="info-tab-wrap"
            style={
              infoTab === "general" ? { display: "block" } : { display: "none" }
            }
          >
            <Field
              type="text"
              name="name"
              id="name"
              placeholder={t("name")}
              customLabel={<label>{t("name")}</label>}
              component={CustomInput}
            />
            <div className="input-container main_desc">
              <label> {t("description")}</label>
              <div className="input-wrapper">
                <RichText
                  initialValue={
                    adminGameForm && adminGameForm.values
                      ? adminGameForm.values.description
                      : ""
                  }
                  callback={(content) =>
                    this.handleEditorChange(content, "description")
                  }
                />
                {((adminGameForm &&
                  adminGameForm.syncErrors &&
                  adminGameForm.syncErrors.description &&
                  adminGameForm.submitFailed) ||
                  (adminGameForm &&
                    adminGameForm.submitSucceeded &&
                    (!adminGameForm.description ||
                      adminGameForm.description.trim() === ""))) && (
                  <div className="error-message">{t("fieldRequired")}</div>
                )}
              </div>
            </div>
            <br />
            <Field
              type="text"
              name="nameInTwitch"
              id="nameInTwitch"
              placeholder={t("nameInTwitch")}
              customLabel={<label>{t("nameInTwitch")}</label>}
              component={CustomInput}
            />
            <Field
              type="text"
              name="nameInYoutube"
              id="nameInYoutube"
              placeholder={t("nameInYoutube")}
              customLabel={
                <label>
                  {t("nameInYoutube")}: <br />
                  <span style={{ fontSize: "12px", color: "#CCC" }}>
                    {t("nameInYoutubeMessage")}
                  </span>
                </label>
              }
              component={CustomInput}
            />
            <Field
              type="text"
              name="sponsorType"
              id="sponsorType"
              placeholder={t("sponsorType")}
              customLabel={
                <label>
                  {t("sponsorType")} <br />
                  <span style={{ fontSize: "12px", color: "#CCC" }}>
                    {t("sponsorTypeMessage")}
                  </span>
                </label>
              }
              component={CustomDropDownGeneric}
              options={[t("sponsorTypeOptions.0"), t("sponsorTypeOptions.1")]}
            />
            <Field
              name="status"
              id="status"
              placeholder={t("statusPlaceHolder")}
              customLabel={<label>{t("status")}</label>}
              component={CustomDropDownGeneric}
              options={[
                t("statusOptions.0"),
                t("statusOptions.1"),
                t("statusOptions.2"),
              ]}
            />

            <Field
              type="text"
              name="developer"
              id="developer"
              placeholder={t("developer")}
              customLabel={<label>{t("developer")}</label>}
              component={CustomInput}
            />
            <Field
              type="text"
              name="publisher"
              id="publisher"
              placeholder={t("publisher")}
              customLabel={<label>{t("publisher")}</label>}
              component={CustomInput}
            />
            <Field
              name="platforms"
              id="platforms"
              isMulti
              component={ArrayDropDown}
              styles={arrayDropDownColors}
              customLabel={<label>{t("platform")}</label>}
              optionsToRender={this.platformOptions()}
            />
            <Field
              type="text"
              name="releaseDate"
              id="releaseDate"
              placeholder={t("releaseDate")}
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              enableClearDate
              customLabel={<label>{t("releaseDate")} </label>}
              component={CustomDateTimePicker}
            />
            <Field
              name="isPrivate"
              id="isPrivate"
              customLabel={t("isPrivate")}
              component={CustomInputYesNoRadio}
            />
            <Field
              name="headline"
              id="headline"
              customLabel={t("headline")}
              component={CustomInput}
              type="text"
            />
            <Field
              name="headlineVideo"
              id="headlineVideo"
              customLabel={t("headlineVideo")}
              component={CustomInput}
              type="text"
            />
            <Field
              name="gamePageURL"
              id="gamePageURL"
              customLabel={t("gamePageURL")}
              component={CustomInput}
              type="text"
            />
          </div>
          <div
            className="info-tab-wrap"
            style={
              infoTab === "assets" ? { display: "block" } : { display: "none" }
            }
          >
            <Field
              type="text"
              name="cover"
              id="cover"
              placeholder={t("placeholder.cover")}
              customLabel={
                <label>
                  {t("cover")}&nbsp;&nbsp;
                  <div
                    className="GameEdit__upload"
                    onClick={this.uploadCover.bind(this)}
                    aria-hidden
                  >
                    ({t("uploadCover")})
                  </div>
                </label>
              }
              component={CustomInput}
            />

            <Field
              type="text"
              name="coverPortrait"
              id="coverPortrait"
              placeholder={t("placeholder.coverPortrait")}
              customLabel={
                <label>
                  {t("coverPortrait")}&nbsp;&nbsp;
                  <div
                    className="GameEdit__upload"
                    onClick={this.uploadCoverPortrait.bind(this)}
                    aria-hidden
                  >
                    ( {t("uploadCoverPortrait")})
                  </div>
                </label>
              }
              component={CustomInput}
            />
            <Field
              type="text"
              name="logo"
              id="logo"
              placeholder="Logo Image URL"
              customLabel={
                <label>
                  {t("logo")}&nbsp;&nbsp;
                  <div
                    className="GameEdit__upload"
                    onClick={this.uploadLogo.bind(this)}
                    aria-hidden
                  >
                    ({t("uploadLogo")})
                  </div>
                </label>
              }
              component={CustomInput}
            />
            <Field
              type="text"
              name="developerLogo"
              id="developerLogo"
              placeholder="Developer Logo Image URL"
              customLabel={
                <label>
                  {t("developerLogo")}&nbsp;&nbsp;
                  <div
                    className="GameEdit__upload"
                    onClick={this.uploadDeveloperLogo.bind(this)}
                    aria-hidden
                  >
                    ({t("uploadDeveloperLogo")})
                  </div>
                </label>
              }
              component={CustomInput}
            />
          </div>
          <div
            className="info-tab-wrap"
            style={
              infoTab === "sales" ? { display: "block" } : { display: "none" }
            }
          >
            <Field
              type="checkbox"
              name="isOnSale"
              id="isOnSale"
              placeholder={t("isOnSale")}
              customLabel={t("isOnSale")}
              component={CustomInputYesNoRadio}
            />
            <Field
              type="text"
              name="slug"
              id="slug"
              placeholder={t("slug")}
              customLabel={<label>{t("slug")}</label>}
              component={CustomInput}
            />
            {adminGameForm && adminGameForm.values && (
              <label className="Label__URL">
                {t("url")}: {window.location.origin}/buy/
                {adminGameForm.values.slug}
              </label>
            )}
            <Field
              type="text"
              name="price"
              id="price"
              placeholder={t("placeholder.price")}
              customLabel={t("retailPrice")}
              component={CustomInput}
            />
            <Field
              type="text"
              name="salePrice"
              id="salePrice"
              placeholder={t("placeholder.salePrice")}
              customLabel={t("salePrice")}
              component={CustomInput}
            />
            <Field
              type="text"
              name="saleEndDate"
              id="saleEndDate"
              placeholder={t("placeholder.saleEndDate")}
              dateStoreFormat={dateStoreFormat2}
              dateDisplayFormat={dateDisplayFormat2}
              customLabel={t("saleEndDate")}
              component={CustomDateTimePicker}
            />
            <div className="input-container main_desc">
              <label>{t("saleDescription")}</label>
              <RichText
                initialValue={
                  adminGameForm && adminGameForm.values
                    ? adminGameForm.values.saleDescription
                    : ""
                }
                callback={(content) =>
                  this.handleEditorChange(content, "saleDescription")
                }
              />
            </div>
            <br />
            <div className="input-container main_desc">
              <label>{t("soldOutMessage")}</label>
              <RichText
                initialValue={
                  adminGameForm && adminGameForm.values
                    ? adminGameForm.values.soldOutMessage
                    : ""
                }
                callback={(content) =>
                  this.handleEditorChange(content, "soldOutMessage")
                }
              />
            </div>
            <br />
            <div className="input-container main_desc">
              <label>{t("actionMessage")}</label>
              <RichText
                initialValue={
                  adminGameForm && adminGameForm.values
                    ? adminGameForm.values.actionMessage
                    : ""
                }
                callback={(content) =>
                  this.handleEditorChange(content, "actionMessage")
                }
              />
            </div>
            <br />
            <Field
              type="text"
              name="actionButtonText"
              id="actionButtonText"
              placeholder={t("actionButtonText")}
              customLabel={<label>{t("actionButtonText")}</label>}
              component={CustomInput}
            />
            <Field
              type="text"
              name="actionButtonLink"
              id="actionButtonLink"
              placeholder={t("actionButtonLink")}
              customLabel={<label>{t("actionButtonLink")}</label>}
              component={CustomInput}
            />
          </div>
          <div
            className="info-tab-wrap"
            style={
              infoTab === "store" ? { display: "block" } : { display: "none" }
            }
          >
            <Field
              type="checkbox"
              name="displayOnNoizStore"
              id="displayOnNoizStore"
              customLabel={t("displayOnNoizStore")}
              component={CustomInputCheckbox}
            />
            <Field
              type="textarea"
              name="storeDescription"
              id="storeDescription"
              rows="5"
              placeholder={t("storeDescription")}
              customLabel={t("storeDescription")}
              component={CustomInput}
            />
          </div>
          <div
            className="info-tab-wrap"
            style={
              infoTab === "gallery" ? { display: "block" } : { display: "none" }
            }
          >
            <FieldArray
              name="gallery"
              component={this.renderGallery}
              uploadGalleryImage={this.uploadGalleryImage.bind(this)}
            />
          </div>
          <div
            className="info-tab-wrap"
            style={
              infoTab === "categories"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className="category__field">
              {this.generateCategoryInputs()}
            </div>
          </div>
          <div
            className="info-tab-wrap"
            style={
              infoTab === "social" ? { display: "block" } : { display: "none" }
            }
          >
            <Field
              type="text"
              name="twitter"
              id="twitter"
              placeholder={t("placeholder.twitter")}
              customLabel={<label>{t("twitter")}</label>}
              component={CustomInput}
            />
            <Field
              type="text"
              name="facebook"
              id="facebook"
              placeholder={t("placeholder.twitter")}
              customLabel={<label>{t("facebook")}</label>}
              component={CustomInput}
            />
            <Field
              type="text"
              name="instagram"
              placeholder={t("placeholder.instagram")}
              customLabel={<label>{t("instagram")}</label>}
              component={CustomInput}
            />
            <Field
              type="text"
              name="twitch"
              id="twitch"
              placeholder={t("placeholder.twitch")}
              customLabel={<label>{t("twitch")}</label>}
              component={CustomInput}
            />
            <Field
              type="text"
              name="youtube"
              id="youtube"
              placeholder={t("placeholder.youtube")}
              customLabel={<label>{t("youtube")}</label>}
              component={CustomInput}
            />
          </div>
          <div className="form__footer">
            <button className="AGameForm__save--btn">{t("save")}</button>
          </div>
        </form>
        <section className="ModalMaster">
          <section
            className={classNames("Modal Modal__upload Modal__upload__one", {
              active: activeModal === 1,
            })}
          >
            <div
              onClick={this.changeStep.bind(this, 0)}
              className="Modal__layer"
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              {this.props.preSignedUrl && this.props.preSignedUrl.isLoading && (
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
              )}
              {(!this.props.preSignedUrl ||
                !this.props.preSignedUrl.isLoading) && (
                <React.Fragment>
                  <div
                    className={classNames("Modal__upload__border", {
                      active: this.state.dropzoneActive,
                    })}
                  />
                  <div
                    className="Modal__close-btn"
                    style={{
                      display: this.state.dropzoneActive ? "none" : "block",
                    }}
                    onClick={this.changeStep.bind(this, 0)}
                    role="button"
                    aria-hidden
                  />
                  <Dropzone
                    ref={(node) => {
                      this.dropzoneRef = node;
                    }}
                    disableClick={this.state.disableClick}
                    className="Modal__upload__content"
                    onDrop={(e, event) => this.onDrop(event, e)}
                    onDragEnter={this.onDragEnter.bind(this)}
                    onDragLeave={this.onDragLeave.bind(this)}
                    disablePreview={false}
                    multiple={false}
                    accept={[".jpg", ".png"]}
                    inputProps={{ id: "file" }}
                  >
                    <div className="Modal__upload__image" />
                    <div className="Modal__upload__title">
                      {t("DragAndDrop")} {imageText} {t("here")}
                    </div>
                    <div className="Modal__upload__or">{t("or")}</div>
                    <div className="Modal__upload__action">
                      <input
                        type="file"
                        name="file"
                        id="file"
                        className="inputFile"
                        onChange={this.handleSelectedFile}
                      />
                      <button className="cta__button">
                        {t("chooseAFile")}
                      </button>
                    </div>
                    <div className="Modal__upload__or">
                      {t("mandatorySize")} {lessThan} {t("kb")}
                      {recommendedDimensions && (
                        <React.Fragment>
                          <br />
                          {t("recommendedDimensions")}: {recommendedDimensions}
                        </React.Fragment>
                      )}
                    </div>
                  </Dropzone>
                </React.Fragment>
              )}
            </section>
          </section>
          <section
            className={classNames("Modal Modal__upload Modal__upload__two", {
              active: activeModal === 2,
            })}
          >
            <div
              onClick={this.changeStep.bind(this, 0)}
              className="Modal__layer"
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div className="Modal__upload__border" />
              <div
                onClick={this.changeStep.bind(this, 0)}
                className="Modal__close-btn"
                role="button"
                aria-hidden
              />
              <section className="Modal__upload__content">
                <div className="Modal__upload__image" />
                <div className="Modal__upload__title">
                  {t("uploadingTheImage")}
                </div>
                <div className="Modal__upload__loading">
                  <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                </div>
              </section>
            </section>
          </section>
          <section
            className={classNames("Modal Modal__upload Modal__upload__three", {
              active: activeModal === 3,
            })}
          >
            <div
              onClick={this.changeStep.bind(this, 0)}
              className="Modal__layer"
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div
                onClick={this.changeStep.bind(this, 0)}
                className="Modal__close-btn"
                role="button"
                aria-hidden
              />
              <section className="Modal__upload__content">
                <section className="Modal__upload__avatar">
                  <div
                    className="Modal__upload__avatar--container"
                    aria-hidden
                  />
                </section>
                <div className="Modal__upload__title2">
                  {t("uploadCompleted")}
                </div>
                <div
                  onClick={this.changeStep.bind(this, 0)}
                  className="Modal__upload__button"
                  aria-hidden
                >
                  {t("changeImage")}
                </div>
              </section>
            </section>
          </section>
          <section
            className={classNames("Modal Modal__upload Modal__upload__four", {
              active: activeModal === 4,
            })}
          >
            <div
              onClick={this.changeStep.bind(this, 0)}
              className="Modal__layer"
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div
                onClick={this.changeStep.bind(this, 0)}
                className="Modal__close-btn"
                role="button"
                aria-hidden
              />
              <section className="Modal__upload__content">
                <div className="Modal__upload__title">{t("youTubeID")}</div>
                <br />
                {t("youTubeIDRequired")}
                <br />
                <br />
                <TextField
                  type="text"
                  name="videoUrl"
                  id="videoUrl"
                  placeholder={t("placeholder.videoUrl")}
                  customLabel={<label>{t("youTubeID")}</label>}
                  value={videoUrl}
                  onChange={this.handleVideoUrl}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleLoad}
                >
                  {t("submit")}
                </Button>
              </section>
            </section>
          </section>
        </section>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.trim() === "") {
    errors.name = t("validate.fieldRequired");
  }
  if (!values.description || values.description.trim() === "") {
    errors.description = t("validate.fieldRequired");
  }
  if (!isBoolean(values.isPrivate)) {
    errors.isPrivate = t("validate.fieldRequired");
  }
  if (!isBoolean(values.isOnSale)) {
    errors.isOnSale = t("validate.fieldRequired");
  }
  if (values.displayOnNoizStore && !isBoolean(values.displayOnNoizStore)) {
    errors.displayOnNoizStore = t("validate.fieldRequired");
  }
  if (
    values.displayOnNoizStore &&
    (!values.storeDescription || values.storeDescription.trim() === "")
  ) {
    errors.storeDescription = t("validate.fieldRequired");
  }
  if (values.isOnSale && (!values.slug || values.slug.trim() === "")) {
    errors.slug = t("validate.fieldRequired");
  }
  if (!values.nameInTwitch || values.nameInTwitch.trim() === "") {
    errors.nameInTwitch = t("validate.fieldRequired");
  }
  if (!values.nameInYoutube || values.nameInYoutube.trim() === "") {
    errors.nameInYoutube = t("validate.fieldRequired");
  }
  if (
    values.isOnSale &&
    (!values.price ||
      typeof values.price === "undefined" ||
      values.price === "")
  ) {
    errors.price = t("validate.fieldRequired");
  }
  if (!values.status || values.status.trim() === "") {
    errors.status = t("validate.fieldRequired");
  }
  if (!values.developer || values.developer.trim() === "") {
    errors.developer = t("validate.fieldRequired");
  }
  /*
  if (!values.releaseDate) {
    errors.releaseDate = 'This field is required';
  }
  */
  if (!values.releaseDate || values.releaseDate === "") {
    values.releaseDate = "-";
  }
  if (values.isOnSale && !values.price) {
    errors.price = t("validate.fieldRequired");
  }
  if (values.price && !/^\d+(.\d{1,2})?$/i.test(values.price)) {
    errors.price = t("validate.incorrectFormat");
  } else if (values.price === "") {
    values.price = "0";
  }
  if (values.platforms && values.platforms.length <= 0) {
    errors.platforms = t("validate.fieldRequired");
  }

  return errors;
};

AdminGameForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation("adminGameForm")(
  reduxForm({
    form: "adminGameForm",
    validate,
    destroyOnUnmount: false,
  })(AdminGameForm)
);
