import React from "react";

import { SetViewContext } from "../PortalContext";
import { htmlToPlainText, textTruncate } from "../../../../utils/functions";

import Icon from "../../../../global/images/icons/Icon";
import IntercomPortal from "../mvp/IntercomPortal";
import Loading from "../../../../global/components/loading/Loading";
import { useTranslation } from 'react-i18next'


import "./Masthead.scss";

const MastheadBlank = () => {
  const { t } = useTranslation('portalLeft')
  
  return <div className="Masthead__Blank">
    <div className="Masthead__Blank_Title">{t("blankTitle")}</div>
    <div className="Masthead__Blank_Description">
      {t("blankDescription")}
    </div>
    <IntercomPortal
      children={
        <div
          className="Masthead__Blank_Create_Quest"
          onClick={() => console.log("Create Quest!")}
        >
          {t("createQuest")}
        </div>
      }
    />
  </div>
}

const QuestActive = () => {
  const { t } = useTranslation('portalLeft')

  return <div className = "Masthead__QuestActive" > { t("questActive") }</div>
}

const StreamerIcon = (user, index) => (
  <div
    className='Masthead__StreamerIcons_Icon'
    style={{
      zIndex: 100 - index,
      transform: `translateX(${index * -5}px)`,
    }}>
    <a href={`https://twitch.tv/${user.username}`} target='_blank' rel='noreferrer'>
      <img
        className='Masthead__StreamerIcons_Icon_Img'
        src={user.profile_img_url}
        alt={user.displayName}
      />
    </a>
  </div>
);

const CurrentlyStreaming = ({ users }) => {
  const { t } = useTranslation("portalLeft");

  if (users.length === 0) {
    return null;
  }

  const streamerIcons = [];

  const remainingStreamers = [...users];

  for (let i = 0; i < 3; i++) {
    if (remainingStreamers[0]) {
      streamerIcons.push(StreamerIcon(remainingStreamers.shift(), i));
    }
  }

  const plural = users.length > 1;

  return (
    <div className="Masthead__CurrentlyStreaming">
      {streamerIcons?.length > 0 && (
        <div className="Masthead__StreamerIcons">{streamerIcons}</div>
      )}{" "}
      &nbsp;
      {remainingStreamers.length > 0 &&
        `+ ${remainingStreamers.length} ${t('more')}`}{" "}
      {plural ? t("are") : t("is")}{" "}
      {t("streaming")}
    </div>
  );
};

const QuestDetails = ({ title, description, keyRequired }) => (
  <div className='Masthead__QuestDetails'>
    <div className='Masthead__QuestDetails_TitleBar'>
      <span className='Masthead__QuestDetails_Title'>{textTruncate(title, 40)}</span>
      {/* {keyRequired && (
        <div className='Masthead__KeyIcon'>
          <Icon color='black' name='key' />
        </div>
      )} */}
      <div className='Masthead__QuestIcon'>
        <Icon color='black' name='loudspeaker' />
      </div>
    </div>
    <div className='Masthead__QuestDetails_Description'>
      {textTruncate(htmlToPlainText(description), 200)}
    </div>
  </div>
);

const QuestLinkButton = ({ icon, text, fn }) => (
  <div className='Masthead__Links_Link' onClick={fn}>
    <div className='Masthead__Links_Link_Icon'>
      <Icon name={icon} />
    </div>
    <span className='Masthead__Links_Link_Text'>&nbsp;{text}</span>
  </div>
);

const QuestLinks = ({ questId }) => {
  const { t } = useTranslation("portalLeft");

  return <SetViewContext.Consumer>
    {({ setView, view }) => (
      <div className="Masthead__Links">
        {/* <ComingSoon children={<QuestLinkButton icon='graph' text='Performance' />} /> */}
        <QuestLinkButton
          icon="addperson"
          text={t("reviewApplicants")}
          fn={() =>
            setView({
              tab: view.tab,
              component: "ViewAllApplicants",
              currentQuest: questId,
            })
          }
        />
        <QuestLinkButton
          icon="edit"
          text={t("editQuest")}
          fn={() =>
            setView({
              tab: view.tab,
              component: "QuestEdit",
              currentQuest: questId,
            })
          }
        />
      </div>
    )}
  </SetViewContext.Consumer>
}

const Masthead = props => {

  if (props.questsLoading)
    return (
      <div className='Masthead__Loading'>
        <Loading isBrand />
      </div>
    );

  if (!props?.quest?.id) return <MastheadBlank />;

  const {
    quest: {
      // gameId,
      id: questId,
      cover,
      coverPortrait,
      game: { cover: gameCover, coverPortrait: gameCoverPortrait },
      title,
      currentlyStreaming = [],
      startDateTime: questStartDate,
      endDateTime: questEndDate,
      description,
      keyRequired,
    },
  } = props;

  const today = new Date();
  const isQuestActive = today >= new Date(questStartDate) && today <= new Date(questEndDate);

  const img = cover || coverPortrait || gameCover || gameCoverPortrait;

  return (
    <div className="Masthead">
      <img className="Masthead__Background" src={img} alt={title} />
      <div className="Masthead__Title">
        {isQuestActive && <QuestActive/>}
        {!!currentlyStreaming?.length && (
          <CurrentlyStreaming users={currentlyStreaming} />
        )}
      </div>
      <div className="Masthead__Bottom">
        <QuestDetails
          title={title}
          description={description}
          keyRequired={keyRequired}
        />
        <QuestLinks questId={questId} />
      </div>
    </div>
  );
};

export default Masthead;
