import React, { useEffect } from 'react';
import defaultImage from '../../assetsV2/twitch.png';


import anime from 'animejs';

const allPaths = ['M 7 0 L 13 6 L 7 11 L 1 6 L 7 0',
  'M 15, 30 a 15,15 0 1,1 30,0 a 15,15 0 1,1 -30,0'];

const FloatAround = (props) => {
  const {
    image = defaultImage, isMobile = false, cssClass = 'default-float-around', cssStyle = {}, startLeft = 0, startTop = 20, zLevel = 0, onScreen = false,
    // isMobile = false, onScreen = false,
  } = props;

  let path;
  const constantSelect = zLevel % 2 === 0 ? 0 : 1;
  const selectedPath = allPaths[constantSelect];

  const coin = Math.random() >= 0.5 ? 'reverse' : '';
  const [debounce, setDebounce] = React.useState(true);

  const svgSize = isMobile ? '20' : '1';


  useEffect(() => {
    if (onScreen && debounce) {
      anime({
        targets: `.${cssClass}`,
        opacity: [0, 1],
        duration: 3000,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      path = anime.path('.motion-path-demo path');
      anime({
        targets: `.${cssClass}`,
        translateX: path('x'),
        translateY: path('y'),
        easing: 'linear',
        direction: coin,
        duration: 2500 + Math.floor((Math.random() * 5000)),
        // delay: Math.floor(2000 * Math.random() + 400),
        loop: true,
      });
      setDebounce(false);
    }
  }, [onScreen, debounce]);


  return (
    <>
      <div
        style={{
      ...cssStyle, position: 'relative', left: startLeft, top: startTop, opacity: 0, zIndex: zLevel, overflow: 'show',
     }}
        className={cssClass}
      >
        <svg className="motion-path-demo" width={svgSize} height={svgSize} viewBox={`0 0 ${svgSize} ${svgSize}`}>
          <path
            fill="none"
            stroke="none"
            strokeWidth="1"
            d={`${selectedPath}`}
          />
        </svg>
        <img
          src={image}
          width={isMobile ? '30px' : '3%'}
          // width="3.5%"
          alt="floating"
        />
      </div>
    </>


  );
};


export default FloatAround;
