import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import "./Back.scss";

const Back = () => {
  const { t } = useTranslation("back");
  return (
    <Link className="back" to="/quests">
      ← {t("backToBrowser")}
    </Link>
  );
};
export default Back;
