/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router";
import AdminTabs from "../../util/tabs/AdminTabs";
import AdminUserForm from "../../../forms/AdminUserForm";
import { simpleNotification } from "../../../../utils/notifications";
import "./UserEdit.scss";
import { withTranslation } from "react-i18next";

class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
    this.userLoaded = false;
    this.oldFreezeBool = false;
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { userId } = this.props.router.params;
    this.props.getAUser(userId, token);
  }

  componentDidUpdate(prevProps) {
    const { t } = this.props;
    if (prevProps.admin.user.isLoading === true && !this.userLoaded) {
      const { data: user } = this.props.admin.user;
      // check to update date/time and user for manual freeze
      if (user.freezeTwitchViewers) {
        this.oldFreezeBool = user.freezeTwitchViewers.isFrozen;
      }

      let editUser = {};
      // if (user.connectedAccounts && user.connectedAccounts.huya.profileRoom) {
      //   editUser = {
      //     ...user,
      //     profileRoom: user.connectedAccounts.huya.profileRoom,
      //     huyaUsername: user.connectedAccounts.huya.username,
      //     huya: 'True',
      //   };
      // } else {
      editUser = { ...user };
      // }
      this.userLoaded = true;
      this.props.assignEditUserInfo(editUser);
    } else if (prevProps.admin.user.isLoading === true && this.userLoaded) {
      if (this.props.admin.user.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("userUpdatedTitle"),
          message: this.props.admin.user.errors.message,
        });
      } else { 
        simpleNotification({
          level: "success",
          title: t("userUpdatedTitle"),
          message: t("userUpdatedMessage"),
        });
      }
     
      this.props.router.replace("/admin/users");
    }
  }

  onSubmit = (data, twitchConnected, twitterConnected) => {
    const { userId } = this.props.router.params;
    const { token } = this.props.auth;

    let freezeObject = { isFrozen: false };
    if (data.freezeTwitchViewers) {
      freezeObject = { ...data.freezeTwitchViewers };
      if (this.oldFreezeBool !== data.freezeTwitchViewers.isFrozen) {
        freezeObject = {
          isFrozen: data.freezeTwitchViewers.isFrozen,
          dateFrozen: new Date(),
          whoFroze: this.props.auth.user.username,
        };
      }
    }

    let submitData = {
      ...data,
      freezeTwitchViewers: freezeObject,
    };

    if (!twitchConnected) {
      submitData = {
        ...data,
        connectedAccounts: {
          ...data.connectedAccounts,
          twitch: {
            id: null,
            username: null,
          },
        },
        freezeTwitchViewers: freezeObject,
      };
    }

    if (!twitterConnected) {
      submitData = {
        ...submitData,
        connectedAccounts: {
          ...submitData.connectedAccounts,
          twitter: {
            id: null,
            username: null,
          },
        },
      };
    }

    if (
      twitchConnected &&
      submitData.connectedAccounts &&
      submitData.connectedAccounts.twitch &&
      submitData.connectedAccounts.twitch.AVGViewers
    ) {
      submitData = {
        ...submitData,
        connectedAccounts: {
          ...submitData.connectedAccounts,
          twitch: {
            ...submitData.connectedAccounts.twitch,
            AVGViewers: parseInt(
              submitData.connectedAccounts.twitch.AVGViewers,
              10
            ),
          },
        },
        freezeTwitchViewers: freezeObject,
      };
    }

    this.props.updateAUser(userId, submitData, token);
  };

  render() {
    const { userId } = this.props.router.params;
    const { t } = this.props;
    return (
      <section className="AUserEdit">
        <section className="AUserEdit__wrapper">
          <AdminTabs {...this.props} />
          <section className="AUserEdit__content">
            <h2 className="AUserEdit__title">{t("editUser")}</h2>
            <div className="AUserEdit__actions">
              <Link
                to="/admin/users"
                activeClassName="active"
                href="/admin/users"
              >
                <button>{t("back")}</button>
              </Link>
            </div>
            <AdminUserForm
              onSubmit={this.onSubmit.bind(this)}
              adminUserForm={this.props.form.adminUserForm}
              auth={this.props.auth}
              currUserId={userId}
            />
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation("userEdit")(UserEdit);
