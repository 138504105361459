/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import CustomInput from "../../utils/redux-forms-custom-fields/CustomInput";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

class AdminUserBrandForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit = (info) => {
    this.props.onSubmit(info);
  };

  render() {
    const { handleSubmit, t } = this.props;
    return (
      <form
        className="form-container admin-user-create"
        autoComplete="off"
        onSubmit={handleSubmit(this.submit.bind(this))}
      >
        <Field
          type="text"
          name="firstName"
          id="firstName"
          placeholder={t("firstName")}
          customLabel={<label>{t("firstName")}</label>}
          component={CustomInput}
        />
        <Field
          type="text"
          name="lastName"
          id="lastName"
          placeholder={t("lastName")}
          customLabel={<label>{t("lastName")}</label>}
          component={CustomInput}
        />
        <Field
          type="text"
          name="company"
          id="company"
          placeholder={t("company")}
          customLabel={<label>{t("company")}</label>}
          component={CustomInput}
        />
        <Field
          type="text"
          name="email"
          id="email"
          placeholder={t("email")}
          customLabel={<label>{t("email")}</label>}
          component={CustomInput}
        />
        <Field
          type="text"
          name="username"
          id="username"
          placeholder={t("username")}
          customLabel={<label>{t("username")}</label>}
          component={CustomInput}
        />
        <div className="form__footer">
          <button className="AUserForm__save--btn">{t("save")}</button>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.firstName || values.firstName.trim() === "") {
    errors.firstName = t("validate.fieldRequired");
  }
  if (!values.lastName || values.lastName.trim() === "") {
    errors.lastName = t("validate.fieldRequired");
  }
  if (!values.username || values.username.trim() === "") {
    errors.username = t("validate.fieldRequired");
  }
  if (!values.email || values.email.trim() === "") {
    errors.email = t("validate.fieldRequired");
  }
  if (!values.company || values.company.trim() === "") {
    errors.company = t("validate.fieldRequired");
  }
  return errors;
};

AdminUserBrandForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation("adminUserBrandForm")(
  reduxForm({
    form: "adminUserBrandForm",
    validate,
  })(AdminUserBrandForm)
);
