import React from "react";
import "./QuickUpdate.scss";

import { withTranslation } from "react-i18next";

import { postBroadcasts } from "../.././utils/apiv2";

class QuickUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showingErrors: false,
      errors: {},
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;
    const { twitter, discord, streamingConsoles } = user;

    this.props.assignLoginUpdateForm({ twitter, discord, streamingConsoles });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.auth.isLoading === true &&
      nextProps.auth.isLoading === false
    ) {
      if (nextProps.auth.errors.hasError) {
        this.setState({
          showingErrors: true,
          errors: nextProps.auth.errors,
        });
      }
    }
  }

  onSubmit = async (arg) => {
    const { token, user } = this.props.auth;
    const { firstName, lastName, birthDate, timezone } = user;
    const preppedArg = {
      ...arg,
      firstName,
      lastName,
      birthDate,
      timezone,
    };
    await postBroadcasts({ broadcast: this.props.broadcast });
    await this.props.submitCallback(preppedArg, token);
    this.props.togglePopup();
  };

  dismissErrors = () => {
    this.setState({
      showingErrors: false,
      errors: {
        hasError: false,
        code: "",
        message: "",
        all: {},
      },
    });
  };

  quitBuggingMe = async () => {
    // Posts to DON'T SHOW AGAIN on close or on blur
    await postBroadcasts({ broadcast: this.props.broadcast });
    this.props.togglePopup();
  };

  render() {
    const { hidden, Form, t } = this.props;
    if (hidden) return null;

    return (
      <div className="flex-parent-popup">
        <div onClick={this.props.togglePopup} className="popup" />
        <div className="popup_inner">
          <span className="closeSignup" onClick={this.quitBuggingMe}>
            &times;
          </span>

          <div className="signupBody">
            <p className="popupHeader">{this.props.header || t("header")}</p>
            <p className="subHeader">
              {this.props.subHeader || t("subHeader")}
            </p>
            {Form ? (
              <Form auth={this.props.auth} onSubmit={this.onSubmit} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("quickUpdate")(QuickUpdate);
