/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { DatetimePickerTrigger } from "rc-datetime-picker";
// import moment from 'moment';
import classNames from "classnames";
import moment from "moment-timezone";
import { withTranslation } from "react-i18next";

class CustomDateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.date = {};
  }

  render() {
    const {
      input,
      inputDisplay,
      placeholder,
      meta: { touched, error },
      t,
      ...custom
    } = this.props;

    const hasError = touched && error !== undefined;

    let showHour = true;
    if (custom.hideHour) {
      showHour = false;
    }

    const isValidDate = moment(
      input.value,
      custom.dateStoreFormat,
      true
    ).isValid();

    return (
      <div className="input-container">
        <label htmlFor={input.label}>{custom.customLabel}</label>
        <div className="input-wrapper">
          <DatetimePickerTrigger
            moment={
              isValidDate
                ? moment(input.value, custom.dateStoreFormat)
                : moment().tz("America/Los_Angeles")
            }
            onChange={(value) =>
              input.onChange(value.format(custom.dateStoreFormat))
            }
            showTimePicker={showHour}
            closeOnSelectDay
          >
            {custom.customLabel2}
            <input
              ref={this.date}
              type="text"
              {...inputDisplay}
              readOnly
              style={{ cursor: "inherit" }}
              placeholder={t("selectDate")}
              value={
                isValidDate
                  ? moment(input.value).format(custom.dateDisplayFormat)
                  : ""
              }
              className={classNames("date", {
                touched,
                error: hasError,
                ok: !hasError,
                empty: !input.value,
                "not-empty": input.value,
              })}
            />
            <input type="hidden" {...input} readOnly />
            {custom.customLabel2}
          </DatetimePickerTrigger>
          {hasError && touched && (
            <div className="error-icon">
              <i className="fa fa-exclamation-circle" />
            </div>
          )}
          {!hasError && touched && (
            <div className="ok-icon">
              <i className="fa fa-check" />
            </div>
          )}
          {hasError && <div className="error-message">{error}</div>}
        </div>
        {this.props.enableClearDate && (
          <button
            type="button"
            style={{
              color: "#fff",
              border: "1px solid #fff",
              marginBottom: "10px",
              padding: "5px",
            }}
            onClick={() => {
              input.onChange("");
              input.value = "";
              this.date.current.value = "";
            }}
          >
            {t("clearDate")}
          </button>
        )}
      </div>
    );
  }
}

CustomDateTimePicker.propTypes = {
  /* input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired, */
  // meta: PropTypes.shape({
  //   touched: PropTypes.bool,
  //   error: PropTypes.bool,
  // }),
  placeholder: PropTypes.string,
};

CustomDateTimePicker.defaultProps = {
  placeholder: "",
  // meta: {
  //   error: false,
  //   touched: false,
  // },
};

export default withTranslation("customDateTimePicker")(CustomDateTimePicker);
