import React, { useState } from "react";
import ReportMetric from "./ReportMetric";
import { useTranslation } from "react-i18next";

import "./MetricCluster.scss";
import Icon from "../../../../../global/images/icons/Icon";

const MetricRow = ({ metrics, color, textColor, isLoading }) => {
  return (
    <div className="MetricCluster_Row">
      {metrics.map((metric) => {
        return (
          <ReportMetric
            isLoading={isLoading}
            margin="8px 8px 8px 8px"
            key={metric.metric}
            value={metric.value}
            metric={metric.metric}
            color={color}
            textColor={textColor}
          />
        );
      })}
    </div>
  );
};

const DefaultExtra = () => {
  const { t } = useTranslation("portalFull_metrics");
  return (
    <div className="MetricCluster_ExtraContent_Container">
      {t("extraContent")}
    </div>
  );
};

const MetricCluster = ({
  title = "",
  color,
  metrics = [],
  textColor = "#1f2227",
  hasExtraContent = false,
  children = <DefaultExtra />,
  isLoading = false,
}) => {
  const topRow = metrics.slice(0, 2);
  const bottomRow = metrics.slice(2);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = (exists = false) =>
    exists ? (
      <div className="Metric_ShowMore_Container">
        <div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="MetricCluster_ShowMore"
          >
            {Icon({ name: "expand", color: "white" })}
          </button>
        </div>
      </div>
    ) : null;

  return (
    <div className="MetricCluster_Container">
      {toggleExpand(hasExtraContent)}
      <div className="MetricCluster_Title">{title}</div>
      {[topRow, bottomRow].map((row, index) => {
        return (
          <MetricRow
            isLoading={isLoading}
            key={index}
            metrics={row}
            color={color}
            textColor={textColor}
          />
        );
      })}
      <div className={`MetricCluster_Extra ${isExpanded ? "Show_Extra" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default MetricCluster;
