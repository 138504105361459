import React from 'react';
import { useTranslation } from 'react-i18next';

import SocialIcons from '../../../../global/components/SocialIcons/SocialIcons';
import { makeProper, validateUrl } from '../../../../utils/functions';
import { socialNetworks } from '../../../../utils/util';

import './SocialNetworks.scss';

const platformNames = [
  'Twitch',
  'Youtube',
];

const SocialNetworks = (props) => {
  const {
    value,
    handleFormChange,
    form,
    enableNameInCards = true,
  } = props;
  const {t}=useTranslation("socialNetworks")

  const handleSocialNetwork = (network, url) => {
    let newValue = [...value];
    const trimmedUrl = url.trim();

    if (trimmedUrl) {
      const index = newValue.findIndex(item => item.name === network);
      if (index !== -1) {
        newValue[index].url = trimmedUrl;
      } else {
        newValue.push({ name: network, url: trimmedUrl });
      }
    } else {
      newValue = newValue.filter(item => item.name !== network);
    }

    handleFormChange('socialNetworks', newValue);
  };

  const handleNameChange = (platform, name) => {
    handleFormChange(`nameIn${platform}`, name);
  };

  const socialNetworkCards = socialNetworks.map(network => {
    const currentNetwork = value.find(item => item.name === network);
    const validatedUrl = currentNetwork ? validateUrl(currentNetwork?.url) : false;

    return (
      <div className="SocialNetworks_Card" key={network}>
        <SocialIcons network={network} tooltip={network === 'youtube' ? 'YouTube' : makeProper(network)} url={validatedUrl ? currentNetwork.url : ''} />
        <input
          className="SocialNetworks_Card_Input"
          name={network}
          onChange={e => handleSocialNetwork(network, e.target.value)}
          value={currentNetwork?.url || ''}
          placeholder={`${network[0].toUpperCase() + network.slice(1)} URL`}
        />
      </div>
    );
  });

  const nameInCards = platformNames.map(name => {
    const field = form[`nameIn${name}`];
    const regexURL = field ? field.replace(/\s/g, name === 'Twitch' ? '%20' : '+') : '';
    const url = name === 'Twitch' ? `https://www.twitch.tv/directory/game/${regexURL}` : `https://www.youtube.com/results?search_query=${regexURL.toLowerCase()}`;

    return (
      <div className="SocialNetworks_Card" key={`nameIn${name}`}>
        <SocialIcons network={name} tooltip={!!field ? url : ''} url={!!field ? url : ''} />
        <input
          className="SocialNetworks_Card_Input"
          name={name}
          onChange={e => handleNameChange(name, e.target.value)}
          value={field || ''}
          placeholder={`${t("nameIn")} ${name}`}
        />
      </div>
    );
  });

  return (
    <div className="SocialNetworks">
      <div className="SocialNetworks_Header">{t("linkYourSocialNetworks")}</div>
      <div className="SocialNetworks_List">
        {socialNetworkCards}
        {enableNameInCards && nameInCards}
      </div>
    </div>
  );
};

export default SocialNetworks;
