import React, { useState } from 'react';
import { useTranslation} from 'react-i18next'

import Icon from '../../../../../global/images/icons/Icon';

import './TrackingTags.scss';

const removeSpaceAndHashTag = text => text.replace(/\s/g, '').replace(/#/g, '');

const TrackingTags = (props) => {
  const {
    header = '',
    value: trackingTags = [],
    onChange,
  } = props;

  const { t } = useTranslation('portalLeft')
  const [textField, setTextField] = useState('');

  const handleRemove = (value) => {
    onChange('trackingTags', trackingTags.filter(t => t.value !== value));
  };

  const handleAdd = () => {
    if (textField.length > 0) {
      const newValue = removeSpaceAndHashTag(textField);

      if (trackingTags?.findIndex(t => t.value === newValue) === -1) {
        onChange('trackingTags', [...trackingTags, { value: newValue, label: `#${newValue}` }]);
        setTextField('');
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAdd();
    }
  };

  const displayTags = trackingTags.map(t => (
    <div className="TrackingTags_Tag" key={t.value} onClick={() => handleRemove(t.value)}>
      {t.label}
      <Icon name="xmark" color="#16181c" />
    </div>
  ));

  return (
    <div className="TrackingTags">
      {!!header && <div className="TrackingTags_Header">{header}</div>}
      <div className="TrackingTags_Tags">{displayTags}</div>
      <div className="TrackingTags_Add">
        <input
          className="TrackingTags_Input"
          type="text"
          placeholder={t("addTag")}
          value={textField}
          onChange={(e) => setTextField(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <button className="TrackingTags_Button" onClick={handleAdd}>
          <Icon name="plus" color="black" />
        </button>
      </div>
    </div>
  );
};

export default TrackingTags;
