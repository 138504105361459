import React from "react";
import { Link } from "react-router";
import "./static.css";

class QuestParticipationAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }
  render() {
    const drlUrl =
      "https://www.ftc.gov/tips-advice/business-center/guidance/ftcs-endorsement-guides-what-people-are-asking";
    const comUrl =
      "https://www.ftc.gov/sites/default/files/attachments/press-releases/ftc-staff-revises-online-advertising-disclosure-guidelines/130312dotcomdisclosures.pdf";
    return (
      <section className="QuestParticipationAgreement static">
        <h1>NOIZ</h1>
        <h1>QUEST PARTICIPATION AGREEMENT</h1>
        <br />
        <br />
        <p>
          This Quest Participation Agreement (the “Agreement”) is between
          Eminence Corporation dba “NOIZ” headquartered at 9665 Wilshire
          Boulevard, Suite #100, Beverly Hills, CA 90212, (“NOIZ”) and you
          (“You”).
        </p>
        <p>
          This Agreement governs your access and use of the NOIZ platform (the
          “Platform”), including the NOIZ website located at www.noiz.gg (the
          “Website”). The Platform allows you to participate in certain content
          creation tasks, known as Quests, as we describe more further below.
        </p>
        <p>
          You should read the entire Agreement before signing up and
          participating in Quests!
        </p>
        <p>
          This Agreement is effective as of the date that you click the “Accept”
          button below, or access or use the Platform. By agreeing, you agree to
          the NOIZ{" "}
          <Link to="/terms-of-use" href="/terms-of-use" target="_blank">
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link to="privacy-policy" href="privacy-policy" target="_blank">
            Privacy Policy
          </Link>
          , as well.
        </p>
        <ol>
          <li>
            <strong>Quests.</strong> The Platform contains a number of specific
            services, deliverables, and other information required (each, a
            “Quest”). Each Quest may consist of information, such as:
            <ol type="a">
              <li>
                The name and appropriate contact information for principal
                contact within NOIZ
              </li>
              <li>
                A general description of the services to be rendered by you for
                NOIZ or their clients
              </li>
              <li>
                NOIZ’s desired outcome and specific deliverables Quest, in
                general, or specific terms, such as specific games to play,
                length of play, social media postings, etc. (the “Deliverables”)
              </li>
              <li>
                Any qualifications that are required of you for that Quest,
                which may be different than or in addition to those in Section 2
                or elsewhere in this Agreement
              </li>
              <li>
                The compensation schedule, including any milestones and
                milestone payments if applicable, for the particular
                Deliverables
              </li>
              <li>
                Any materials NOIZ or their Client will provide for inclusion in
                the particular Deliverables or use in the Quest
              </li>
              <li>
                The deadlines for providing the Deliverables for that Quest
              </li>
              <li>
                A unique identification number for the Quest and other
                references
              </li>
              <li>
                The specific streaming platforms on which you are able to or
                required to complete the Quest and on which you need to have an
                existing streaming channel (your “Channel”), further described
                in Section 2 below
              </li>
            </ol>
            You agree to perform the services related to any Quest that you sign
            up for in a prompt manner and have all Deliverables submitted to
            NOIZ (or otherwise completed, if submission to NOIZ is not required)
            by the due date specified in the Quest. NOIZ will assist you by
            responding to any information requests relevant to the services in a
            timely manner.
          </li>
          <li>
            <strong>Eligibility.</strong> In order to be eligible for Quests,
            the following must be true:
            <ol type="a">
              <li>
                You must meet all requirements listed in the Quest. If there is
                a discrepancy between these requirements and the ones listed in
                the Quest, the terms of the Quest will control
              </li>
              <li>
                You must be at least 18 years of age (or at least the age of
                majority in your jurisdiction, whichever is older)
              </li>
              <li>
                Your Channel must have been active for at least 90 days, have
                the minimum number of average viewers/followers listed in the
                Quest, and there must not be a gap in newly posted content of
                more than 90 days during the last 12 months
              </li>
              <li>
                Your Channel must be in English (unless otherwise specified in
                the Quest) and must contain a majority of the content that is
                original to you
              </li>
              <li>
                Your Channel’s content{" "}
                <strong>cannot contain any material</strong> that is unlawful,
                defamatory, libelous, threatening, pornographic, harassing,
                hateful, racially or ethnically offensive, or encourages conduct
                that would be considered a criminal offense, gives rise to civil
                liability, violates any law, or is otherwise inappropriate, at
                NOIZ’s sole discretion (the “Inappropriate Content”)
              </li>
              <li>
                Your Channel must be open for viewing by anyone in the public
              </li>
            </ol>
            If you make any material misrepresentation as to the content or
            other aspects of your Channel to NOIZ, or if NOIZ believes (in their
            sole discretion) that you are ineligible for participation in
            Quests, NOIZ may revoke your participation in this Agreement and any
            Quest, to delete your account on the Platform, and to withhold
            payment of Fees (as defined below). NOIZ may periodically review
            your Channel and your participation in Quests for adherence to these
            requirements.
          </li>
          <li>
            <strong>Your Performance.</strong> Your performance of the services
            and the Deliverables under the Quests is subject to the following
            additional terms:
            <ol type="a">
              <li>
                <strong>Deliverables.</strong> The Deliverables must be (a) in
                accordance with the parameters set forth in the Quest, (b)
                submitted or performed by the due date in the Quest, (c) in
                English unless other languages are allowed in the Quest, (d)
                original, (e) ad-free, (f) free of Inappropriate Content, (g) in
                compliance with the terms of the Platform and the streaming
                platform it is hosted on, and (h) posted and made available to
                the public for <strong>at least</strong> 60 consecutive days
                following the initial posting/streaming/delivery date, unless
                otherwise stated in the Quest.
              </li>
              <strong>Streaming Experience.</strong> If the Product is a game
              and Influencer shall be playing the Product as part of the
              Services, Influencer shall practice playing the Product a minimum
              of 20 minutes prior to engaging in a streaming session in order
              for the streaming session to provide a fluid gaming experience for
              viewers. If the product is a mobile game, unless otherwise
              indicated in the QUEST, Influencer shall play the Product on a PC
              (personal computer), not on a phone, for each Streaming Session
              and use an emulator and test the emulator prior to engaging in a
              Streaming Session. When creating YouTube Videos, Influencer shall
              not use an emulator unless otherwise directed by NOIZ.
              <li>
                <strong>Federal Endorsement Guidelines.</strong> You are
                responsible for adhering to any federal, state, or local laws
                regarding your participation in the Platform and any Quests,
                including sponsorship by NOIZ or NOIZ’s clients, the display of
                any affiliate links, and any online advertising of your Channel.
                For information on the FTC’s rules, see their online resources
                here: &nbsp;
                <Link to={drlUrl} href={drlUrl} target="_blank">
                  Disclosure Rules and Endorsement Guides
                </Link>
                &nbsp; and &nbsp;
                <Link to={comUrl} href={comUrl} target="_blank">
                  .com Disclosures.
                </Link>
                &nbsp; You agree that NOIZ has no obligation regarding your
                adherence to such rules and regulations, and you agree to hold
                NOIZ and their clients harmless and indemnify NOIZ and their
                clients from all claims, suits, threats, demands, liabilities,
                actions, causes of action related in any way to your Channel and
                business.
              </li>
              <li>
                <strong>Non-Disparagement.</strong> In performing a Quest, you
                agree not to create or share any content or act in any way that
                is damaging, defamatory, disparaging, derogatory, or negative to
                NOIZ or their clients or that paints NOIZ or their clients in a
                false or negative light.
              </li>
              <li>
                <strong>Removal/Failure to Perform.</strong> If NOIZ or their
                clients deem the Deliverables to be in violation of any term of
                this Agreement, you agree to immediately remove them from public
                availability upon notice from NOIZ or their client. If you fail
                to fully perform the required Deliverables and Quest
                obligations, you agree that you will not earn the Fees (defined
                below) and that you must immediately return or destroy/delete,
                and cease usage of, the Quest Materials provided to you (defined
                below).
              </li>
            </ol>
          </li>
          <li>
            <strong>Payments.</strong> For the performance of the services and
            the Deliverables, NOIZ will pay you all fees due under the
            applicable Quest (the “Fees”) or the amount indicated in Exhibit B.
            Payment of Fees is conditioned on your complete fulfillment of the
            Quest and Deliverables, within the deadlines and terms detailed in
            the Quest.
            <ol type="a">
              <li>
                <strong>Payment Timing.</strong> 60 days after the end date
                listed on a Quest, and subject to your successful completion of
                a Quest and verification by NOIZ that all Deliverables have been
                completed successfully, NOIZ will transfer the Fees to your
                connected Payment Account, as described in Section 4 below. No
                invoice to NOIZ is required.
              </li>
              <li>
                <strong>Expenses.</strong> You are responsible for all expenses
                (Internet connection, equipment, content creation on your
                Channel, modifications, and improvements to your Channel, etc.)
                related to the Quest, unless reimbursement of an expense is
                specifically described in the Quest.
              </li>
              <li>
                <strong>Independent Contractor.</strong> The Influencer is an
                independent contractor. Nothing in this Agreement creates a
                partnership, joint venture, employer/employee,
                principal-and-agent, or any similar relationship between the
                parties and it is the express intention of the parties that
                Influencer performs his or her obligations hereunder as an
                independent contractor. Nothing in this Agreement will in any
                way be construed to constitute Influencer as an agent, employee
                or representative of NOIZ. Influencers will indemnify and hold
                NOIZ harmless to the extent of any obligation imposed on NOIZ
                (a) to pay withholding taxes or similar items or (b) resulting
                from a determination that Influencer is not an independent
                contractor. If the amount paid to you is over $600 in any tax
                year, Influencer agrees to complete, execute and deliver to NOIZ
                a W-9 (or if Influencer is a foreign individual or entity, a
                W-8BEN/W-8BEN-E) NOIZ prior to receiving payment for
                Influencer’s services so that NOIZ may comply with its federal
                tax reporting (Form 1099) and withholding requirements.
              </li>
              <li>
                <strong>Disputed Payments.</strong> If there is a good faith
                dispute over a payment amount under this Agreement, NOIZ may
                only withhold the portion of the payment in dispute until the
                dispute is resolved. You and NOIZ agree to work in good faith to
                resolve any disputes. NOIZ’s withholding of payment will not
                constitute a breach or default by NOIZ.
              </li>
            </ol>
          </li>
          <li>
            <strong>Your Payment Account</strong>
            <ol type="a">
              <li>
                <strong>PayPal Account Required.</strong> In order to get paid
                the Fees for your successful Quests, you must connect a valid
                and operational PayPal account (your “Payment Account”) by going
                to your account dashboard on the Platform and following the
                instructions to connect your Payment Account. If you don’t have
                a PayPal account, you can create one for free by signing up at
                www.paypal.com. All payment of Fees is subject to PayPal’s terms
                of use and privacy policies.
                <br />
                <br />
                YOU ARE REQUIRED TO HAVE A VALID PAYPAL ACCOUNT IN ORDER TO GET
                PAID YOUR FEES. YOU ACKNOWLEDGE AND AGREE THAT THERE IS NO OTHER
                WAY TO HAVE YOUR FEES PAID AND THAT FAILURE TO CONNECT A VALID
                PAYMENT ACCOUNT MAY RESULT IN ANY FEES BEING FORFEITED.
              </li>
              <li>
                <strong>Failure to Connect Payment Account.</strong> If you fail
                to connect a Payment Account or you enter invalid or incorrect
                Payment Account information in your account dashboard, NOIZ will
                not be able to pay your Fees. NOIZ does not have a way to
                redirect incorrectly transferred funds once they have been sent
                to the PayPal address you indicated within your account
                dashboard. If you have not set up a valid and operational
                Payment Account within 60 days of the date that Fees are owed to
                you, you agree that you forfeit those Fees and that NOIZ is no
                longer responsible for payment of such Fees.
              </li>
              <li>
                <strong>Not Responsible for Errors.</strong> NOIZ{" "}
                <strong>is not responsible</strong> for any errors on your part
                in entering information about your Payment Account, or any other
                error on the part of you, PayPal, or any third party that
                results in the loss of Fees.
              </li>
            </ol>
          </li>
          <li>
            <strong>Liability and Indemnity.</strong> NOIZ is not responsible
            for any matter regarding your performance of any Quests, your
            Channel or the content included on your Channel, or your adherence
            to applicable law, including the FTC endorsement guidelines. In no
            case will NOIZ or their clients be liable to you for any amount
            greater than the amount paid under the applicable Quest.
            <br />
            <br />
            TO THE FULLEST EXTENT PERMISSIBLE BY LAW, NOIZ IS NOT LIABLE FOR ANY
            DIRECT, INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR INCIDENTAL
            DAMAGES OF ANY KIND, OR FOR ANY LOSS OF DATA, REVENUE, PROFITS OR
            REPUTATION, ARISING UNDER THIS AGREEMENT OR ANY QUEST, EVEN IF NOIZ
            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES. Some
            jurisdictions do not allow the limitation or exclusion of liability
            for incidental or consequential damages, so the above limitations
            may not apply to you.
            <br />
            <br />
            EXCEPT FOR THE WARRANTIES IN THIS AGREEMENT, EACH PARTY EXPRESSLY
            DISCLAIMS ANY AND ALL OTHER WARRANTIES OF ANY KIND OR NATURE,
            WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED
            WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
          </li>
          <li>
            <strong>Representations.</strong> You represent that any materials
            created by you or included in the Deliverables will not (a) infringe
            on the intellectual property rights of any third party or any rights
            of publicity or privacy or (b) violate any applicable law, rule,
            statute, ordinance or regulation. You represent that your
            performance under this Agreement and any Quest will not be in
            violation of any existing agreement or applicable law. You represent
            that you are at least 18 years old or the age of majority in your
            jurisdiction.
          </li>
          <li>
            <strong>Restrictions on Direct Engagement with Client.</strong> For
            a period of one (1) year from the Effective Date, Influencer will
            not directly or indirectly solicit engagement by or be engaged by
            Client, or any affiliate of Client, or any third party on behalf of
            Client to provide any form of promotional services, including
            without limitation any promotional services for the Product, or to
            otherwise perform services similar to those being provided by
            Influencer hereunder, other than through an engagement with NOIZ.
          </li>
          <li>
            <strong>Term and Termination.</strong> This Agreement starts on the
            date that you agree and will remain in effect until terminated. You
            may terminate by requesting the deletion of your account on the
            Platform. NOIZ may terminate this Agreement at any time by notifying
            you, at their sole discretion, subject to payment of any outstanding
            Fees already earned by you. Obligations concerning outstanding
            contracts, warranties, support, intellectual property, limitations
            of liability and remedies, confidentiality, and termination will
            survive termination of this Agreement.
          </li>
          <li>
            <strong>Content Ownership and Licenses; Confidentiality.</strong>
            <ol type="a">
              <li>
                <strong>Grant of License.</strong> You have a non-exclusive,
                limited-term license to use the trademarks, logos, and
                copyrighted material that NOIZ or their clients provide to you
                for use solely on the Channel in connection with the Quest (the
                “Quest Materials”). You may not distribute, reproduce, modify,
                or amend the Quest Materials provided in any way, except as
                directed in the Quest. You may use these Quest Materials only
                for the purposes of the Quest on your Channel in compliance with
                the terms of this Agreement. The license so granted is subject
                to complete compliance with all terms and conditions of this
                Agreement and any policies NOIZ may create and amend from time
                to time regarding the Platform.
              </li>
              <li>
                <strong>License Conditions.</strong> This license immediately
                terminates upon your termination from the Platform. NOIZ may
                also terminate this license upon notice to you if your use of
                these items is contrary to or does not conform with NOIZ’s
                standards or the standards of NOIZ’s clients, such standards to
                be determined in NOIZ’s sole and absolute discretion. You agree
                that NOIZ or its clients retain all rights in and to all Quest
                Materials. NOIZ and their clients will retain all goodwill and
                other value associated with any of these Quest Materials. You
                will not gain any trademark, copyright or other proprietary
                rights to such Quest Materials. You agree not to take any action
                that is contrary to or inconsistent with NOIZ’s or their
                clients’ rights to these Quest Materials. You will not use these
                materials in any way that is damaging, defamatory, disparaging,
                derogatory, or negative to NOIZ or their clients or that paints
                NOIZ or their clients in a false or negative light.
              </li>
              <li>
                <strong>Other Proprietary Materials.</strong> You are not
                permitted to use any other NOIZ proprietary materials, including
                trademarks, copyrights, logos, text, and any other materials
                that belong to NOIZ or to any other party and which may appear
                on the Platform.
              </li>
              <li>
                <strong>Ownership of Deliverables.</strong> Subject to NOIZ’s or
                their clients’ ownership rights in the Quest Materials, you will
                own all rights to your contributions to the Deliverables unless
                otherwise noted in the Quest (the “Creator Materials”). You
                hereby grant NOIZ and their clients a worldwide, perpetual,
                fully paid-up, royalty-free, irrevocable, and fully
                sublicensable right and license to share, use, copy, process,
                adapt, translate, display, perform, reproduce, republish, and
                distribute the Creator Materials or any portion of them in all
                forms of media, including Internet and other electronic
                communications, television, radio, and print media.
              </li>
              <li>
                <strong>Confidential Information.</strong> NOIZ and Influencer
                agree to keep the terms and conditions of this Agreement and all
                information delivered in connection herewith (the “Confidential
                Information”) confidential and shall not disclose the
                Confidential Information to any other person or party without
                the prior written consent of the other party. Notwithstanding
                the foregoing (i) NOIZ and Influencer may disclose Confidential
                Information to their affiliates (if any) and to their and their
                affiliates’ officers, directors, employees, lawyers,
                consultants, potential investors and advisors (collectively,
                “¨Representatives”) to the extent such Representatives need to
                know such Confidential Information in connection with the
                performance of this Agreement, (ii) Confidential Information
                shall not include any information that becomes public knowledge
                other than as a result of a breach of this Agreement, and (iii)
                disclosures of Confidential Information that are required by
                law, pursuant to legal proceedings or otherwise, shall be
                permitted. Each receiving party shall be liable to the
                disclosing party for any disclosure by the receiving party’s
                Representatives that would violate this Agreement had such
                disclosure been made by the receiving party. For the avoidance
                of doubt, disclosure of any facts related to compensation and
                any travel expense arrangements shall not be permitted under
                this Agreement.
              </li>
            </ol>
          </li>
          <li>
            <strong>The Legal Stuff (don’t stop reading yet).</strong>
            <ol type="a">
              <li>
                <strong>Notices.</strong> Notices sent under this Agreement will
                be by email to the address indicated in your account. Any
                notices to you via email at that address are deemed to be
                effective notice to you for all purposes.
              </li>
              <li>
                <strong>Assignment.</strong> You can’t assign this Agreement to
                another party without our written consent.
              </li>
              <li>
                <strong>Indemnity.</strong> You will indemnify NOIZ and its
                directors, officers, managers, employees, clients, and agents
                from and against any and all losses, liabilities, expenses,
                judgments, settlements reasonably approved by the indemnified
                party, costs, or damages (including reasonable attorneys’ fees)
                resulting from claims asserted by third parties arising out of
                or in connection with this agreement, to the extent that they
                were caused by the actual or alleged breach (by act or omission)
                of your representations, warranties or obligations under this
                Agreement. The obligations under this section will survive the
                termination of this Agreement.
              </li>
              <li>
                <strong>Power to Contract.</strong> You hereby represent and
                warrant to NOIZ that you have the complete power and authority
                to enter into this Agreement and that this Agreement constitutes
                a valid and legally enforceable agreement.
              </li>
              <li>
                <strong>Governing Law.</strong> This Agreement is governed by
                California law, and any disputes will be held in the courts of
                Los Angeles, California (subject to the arbitration provision
                below).
              </li>
              <li>
                <strong>Arbitration.</strong> Any claim arising out of this
                Agreement or the dealings of you and NOIZ will be settled by
                arbitration. The arbitration will use the Streamlined
                Arbitration Rules and Procedures of JAMS. The arbitrator’s
                decision will be final and binding on both you and NOIZ.
                Whichever party prevails in the arbitration will be entitled to
                attorneys’ fees, travel expenses, and any other expenses awarded
                at the arbitrator’s discretion.
              </li>
              <li>
                <strong>Class Action Waiver.</strong> You and NOIZ agree that
                any arbitration and any other litigation must be limited to the
                claim between you and NOIZ individually. This means that neither
                of us have the right to arbitrate or litigate any dispute on a
                class action basis or use class action procedures. Neither of us
                can bring a dispute as a private attorney general. Neither of us
                is allowed to join any arbitration or litigation with any other
                arbitration or litigation.
                <br />
                <br />
                EACH PARTY HEREBY WAIVES ANY RIGHTS THEY MAY HAVE TO TRIAL BY
                JURY IN REGARD TO ARBITRABLE CLAIMS.
              </li>
              <li>
                <strong>No Partnership.</strong> You and NOIZ are not partners,
                joint venturers, a single business entity, or any other type of
                partnership. You are not NOIZ’s agent and are not authorized to
                act as NOIZ’s agent.
              </li>
              <li>
                <strong>Entire Agreement.</strong> This Agreement, including the
                incorporated NOIZ Terms of Use and Privacy Policy, is the entire
                agreement of you and NOIZ. This Agreement can’t be modified by
                you, except by a written amendment signed by both of us. We can
                modify this Agreement by sending you notice of the changes to
                the email address on your account.
              </li>
              <li>
                <strong>Severability.</strong> If any of these provisions is
                found by a court to be unenforceable, that provision will be
                voided, and the rest of the Agreement will remain in effect.
              </li>
              <li>
                <strong>Force Majeure.</strong> Neither Party will be liable for
                performance delays or for nonperformance due to causes beyond
                its reasonable control.
              </li>
              <li>
                <strong>Time is of the Essence.</strong> Each Party hereby
                agrees that time is of the essence with respect to your
                performance under this Agreement, unless the time schedule in
                the applicable Quest clearly states otherwise.
              </li>
            </ol>
          </li>
        </ol>
      </section>
    );
  }
}

export default QuestParticipationAgreement;
