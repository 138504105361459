import { findIndex } from "lodash";
import moment from "moment-timezone";
import {
  getCorrectTwitchAverages,
  rounding,
  getReferralBonus,
  getTwitchAverageViewers,
  getCompletionStatus,
  calculatePaymentAmount,
  getQuestRequirements,
  getNumApprovedRequirements,
  getApprovedRequirements,
  escapeSpecialCharacters,
  capitalizeFirstLetter,
  formatImageForCSV,
} from "./functions";

import { t } from "i18next";

import { paymentVendorProcessingFee } from "./functions";

export const portalDetails = (campaign) => {
  const headersCSV = [
    { label: "Total Reserved", key: "totalReserved" },
    { label: "Total Payable", key: "totalPayable" },
    { label: "Applied", key: "applied" },
    { label: "Approved", key: "approved" },
    { label: "Completed", key: "completed" },
    { label: "Failed", key: "failed" },
    { label: "Average Viewers", key: "averageViewers" },
    { label: "Hours Played", key: "hoursPlayed" },
    { label: "Total ACCV", key: "totalACCV" },
    { label: "Participant Count", key: "participantCount" },
    { label: "Keys Sold", key: "keysSold" },
    { label: "Total Sales", key: "totalSales" },
    { label: "Total Commissions", key: "totalCommissions" },
    { label: "Bonus", key: "bonus" },
  ];

  const dataCSV = [];

  if (campaign && campaign.quests && campaign.quests.length > 0) {
    const { quests } = campaign;
    quests.forEach((q) => {
      dataCSV.push({
        totalReserved: q.reserved,
        totalPayable: q.payable,
        applied: q.spotsApplied,
        approved: q.spotsFilled,
        completed: q.completed,
        failed: q.failed,
        averageViewers: q.averageViewers ? Math.round(q.averageViewers) : 0,
        hoursPlayed: q.hoursStreamed,
        totalACCV: q.totalAverageViewers
          ? Math.round(q.totalAverageViewers)
          : 0,
      });
    });
  }

  if (
    campaign &&
    campaign.referralQuests &&
    campaign.referralQuests.length > 0
  ) {
    const { referralQuests } = campaign;
    referralQuests.forEach((q) => {
      dataCSV.push({
        participantCount: q.participantCount,
        keysSold: q.keysSold,
        totalSales: q.totalSales,
        totalCommissions: q.totalCommissions,
        bonus: q.bonus,
      });
    });
  }

  return {
    headersCSV,
    dataCSV,
  };
};

export const campaignTieredQuestsUsers = (tqArg, rqArg) => {
  const tq = [...tqArg];
  const rq = [...rqArg];
  const headersCSV = [
    { label: "Noiz Username", key: "noizUsername" },
    { label: "Twitch Username", key: "twitchUsername" },
    { label: "Twitch Average Viewers", key: "twitchAverageViewers" },
    { label: "Account Email", key: "accountEmail" },
    { label: "Quest Title", key: "questTitle" },
    { label: "Passed/Failed", key: "passed" },
    { label: "Date Stream Completed", key: "endDateTime" },
    { label: "Average Viewers At Join", key: "averageViewersAtJoin" },
    { label: "Quest Average Viewers", key: "questAverageViewers" },
    { label: "Time Playing", key: "timePlaying" },
    { label: "Quest Start Date Time", key: "startDateTime" },
    { label: "Platform", key: "platform" },
    { label: "Application Status", key: "applicationStatus" },
    { label: "Application Date", key: "applicationDate" },
    { label: "Application Approval/Declined Date", key: "approvalDeclineTime" },
    { label: "Keys Sold", key: "keysSold" },
    { label: "Earnings", key: "earnings" },
    { label: "Total Sold", key: "totalSold" },
    { label: "Bonus", key: "bonus" },
    { label: "Profile Image URL", key: "profileImageURL" },
    { label: "Profile Image", key: "profileImageFunction" },
  ];

  for (let k = 0; k < rq.length; k += 1) {
    rq[k].bonus = getReferralBonus(rq[k], rq[k].quest);
    if (rq[k] && rq[k].user && rq[k].quest) {
      const index = findIndex(
        tq,
        (x) =>
          x &&
          x.user &&
          x.quest &&
          x.user._id === rq[k].user._id &&
          x.quest._id === rq[k].quest._id
      );
      if (index >= 0) {
        tq[index].numberOfReferrals = rq[k].numberOfReferrals;
        tq[index].totalSelled = rq[k].totalSelled;
        tq[index].totalEarnings = rq[k].totalEarnings;
        tq[index].bonus = rq[k].bonus;
      } else {
        tq.push(rq[k]);
      }
    }
  }

  const dataCSV = [];

  for (let i = 0; i < tq.length; i += 1) {
    const { correctAverage } = getCorrectTwitchAverages(tq[i].user);
    const profileImageURL =
      tq[i]?.user?.connectedAccounts?.twitch?.profile_image_url || "";

    dataCSV.push({
      noizUsername: tq[i].user.username,
      twitchUsername: tq[i].user.connectedAccounts.twitch.username || "-",
      twitchAverageViewers: correctAverage ? Math.round(correctAverage) : "-",
      accountEmail: tq[i].user.email,
      questTitle: tq[i].quest.title,
      passed:
        tq[i].quest.requiredStreamTimeMinutes <= tq[i].timePlaying
          ? "Passed"
          : "Failed",
      averageViewersAtJoin: tq[i].averageViewersAtJoin
        ? Math.round(tq[i].averageViewersAtJoin)
        : "-",
      questAverageViewers: tq[i].averageViewers
        ? Math.round(tq[i].averageViewers)
        : "-",
      timePlaying: tq[i].timePlaying ? Math.round(tq[i].timePlaying) : "-",
      endDateTime: tq[i].endDateTime
        ? moment(tq[i].endDateTime)
            .tz("America/Los_Angeles")
            .format("MMMM DD, YYYY -  - hh:mm a")
            .toString()
        : "",
      startDateTime: tq[i].quest.startDateTime,
      platform: tq[i].quest.platform,
      applicationStatus: tq[i].status,
      applicationDate: tq[i].createdAt,
      approvalDeclineTime: tq[i].approvalDeclineTime,
      keysSold: tq[i].numberOfReferrals || "-",
      earnings: tq[i].totalEarnings ? rounding(tq[i].totalEarnings / 100) : "-",
      totalSold: tq[i].totalSelled ? rounding(tq[i].totalSelled / 100) : "-",
      bonus: tq[i].bonus ? rounding(tq[i].bonus) : "-",
      profileImageURL,
      profileImageFunction: formatImageForCSV(profileImageURL),
    });
  }

  return {
    headersCSV,
    dataCSV,
  };
};

export const campaignDetailedQuestsUsers = (
  userQuests,
  reqHeaders = [],
  chatMentions = []
) => {
  const headers = [
    { label: t("downloadReports.questName"), key: "questName" },
    { label: t("downloadReports.noizUsername"), key: "noizUsername" },
    { label: t("downloadReports.streamingUsername"), key: "streamingUsername" },
    { label: t("downloadReports.twitter"), key: "twitter" },
    { label: t("downloadReports.twitterFollowers"), key: "twitterFollowers" },
    {
      label: t("downloadReports.siteAverageViewers"),
      key: "siteAverageViewers",
    },
    { label: t("downloadReports.accountEmail"), key: "accountEmail" },
    { label: t("downloadReports.paymentEmail"), key: "paymentEmail" },
    { label: t("downloadReports.country"), key: "country" },
    { label: t("downloadReports.language"), key: "language" },
    {
      label: t("downloadReports.questAverageViewers"),
      key: "questAverageViewers",
    },
    { label: t("downloadReports.peakViewers"), key: "peakViewers" },
    { label: t("downloadReports.hoursWatched"), key: "hoursWatched" },
    { label: t("downloadReports.timePlaying"), key: "timePlaying" },
    { label: t("downloadReports.completed"), key: "completed" },
    { label: t("downloadReports.earnings"), key: "earnings" },
    { label: t("downloadReports.processingFee"), key: "processingFee" },
    { label: t("downloadReports.toPay"), key: "toPay" },
    { label: t("downloadReports.gameKey"), key: "gameKey" },
    { label: t("downloadReports.tier"), key: "tier" },
    { label: t("downloadReports.tierViewers"), key: "tierViewers" },
    { label: t("downloadReports.tierPayments"), key: "tierPayments" },
    {
      label: t("downloadReports.averageViewersAtJoin"),
      key: "averageViewersAtJoin",
    },
    {
      label: t("downloadReports.expectedPayoutDate"),
      key: "expectedPayoutDate",
    },
    { label: t("downloadReports.link"), key: "link" },
    { label: t("downloadReports.endDateTime"), key: "endDateTime" },
    { label: t("downloadReports.dateAccepted"), key: "dateAccepted" },
    { label: t("downloadReports.requirements"), key: "requirements" },
    { label: t("downloadReports.profileImageURL"), key: "profileImageURL" },
    {
      label: t("downloadReports.profileImageFunction"),
      key: "profileImageFunction",
    },
  ];

  const dataCSV = [];

  const filteredUQ = userQuests.filter((uq) => uq.status !== "leftQuest");

  if (reqHeaders.length > 0) {
    headers.push(...reqHeaders);
  } else {
    const reqs = filteredUQ.reduce((acc, { requirements }) => {
      requirements.forEach((r) => {
        if (!acc[r.questReqId]) {
          acc[r.questReqId] = r.title;
        }
      });

      return acc;
    }, {});

    Object.keys(reqs).forEach((id) =>
      headers.push({ label: reqs[id], key: id })
    );
  }

  let maxTweetData = 0;
  const tweetData = {};

  for (let i = 0; i < filteredUQ.length; i += 1) {
    const uq = filteredUQ[i];
    const {
      quest,
      user,
      dateAccepted,
      dateJoined,
      timePlaying,
      username,
      twitchUsername,
      youtubeUsername,
      averageViewers,
      peakViewers,
      paymentAmount,
      key,
      tier,
      link,
      endDateTime,
      chatDetails,
    } = uq;

    const averageViewersAtJoin = uq.averageViewersAtJoin
      ? Math.round(uq.averageViewersAtJoin)
      : 0;
    let siteAverageViewers = 0;
    if (quest.twitchAccountRequired) {
      siteAverageViewers = getTwitchAverageViewers(user);
    }

    const acceptedDate = dateAccepted || dateJoined;

    const completionStatus = getCompletionStatus(quest, uq, timePlaying);
    const getsPaid = completionStatus && completionStatus.status === "C";

    const viewerTiers = quest.viewerTiers ? quest.viewerTiers : [];
    const memberTiers = quest.memberTiers ? quest.memberTiers : [];
    const allTheTierCalcs = calculatePaymentAmount(
      uq,
      averageViewersAtJoin,
      viewerTiers,
      memberTiers
    );
    const bracket = allTheTierCalcs.isMemberTier
      ? t("downloadReports.memberTier")
      : allTheTierCalcs.minViewers;

    let platform = null;
    if (quest.twitchAccountRequired) platform = "Twitch";
    else if (quest.youtubeAccountRequired) platform = "YouTube";

    let totalChatters = "- - -";
    let totalMessages = "- - -";
    let avgMessagesPerMinute = "- - -";
    let topWords = [];
    let topEmotes = [];

    if (chatDetails) {
      totalChatters = chatDetails.totalChatters;
      totalMessages = chatDetails.totalMessages;
      avgMessagesPerMinute = chatDetails.avgMessagesPerMinute;
      topWords = chatDetails.topWords;
      topEmotes = chatDetails.topEmotes;
    }

    const profileImageURL =
      user?.connectedAccounts?.twitch?.profile_image_url || "";

    const dataRow = {
      questName: quest.title,
      noizUsername: username,
      ...(platform === "Twitch"
        ? { streamingUsername: `${twitchUsername}` }
        : {}),
      ...(platform === "YouTube"
        ? { streamingUsername: `${youtubeUsername}` }
        : {}),
      accountEmail: user ? user.email : "- - -",
      paymentEmail: user ? user.paymentEmail : "- - -",
      country: user ? user.country : "",
      language: user ? user.language : "",
      siteAverageViewers,
      questAverageViewers: averageViewers ? parseInt(averageViewers, 10) : "0",
      peakViewers: peakViewers ? parseInt(peakViewers, 10) : "0",
      hoursWatched:
        averageViewers && timePlaying
          ? parseFloat((averageViewers * timePlaying) / 60).toFixed(2)
          : "0",
      timePlaying,
      completed: completionStatus ? completionStatus.status : "- - -",
      earnings:
        paymentAmount && getsPaid && paymentAmount !== -1
          ? rounding(paymentAmount)
          : 0,
      processingFee:
        paymentAmount && getsPaid && paymentAmount !== -1
          ? rounding(paymentVendorProcessingFee(paymentAmount))
          : "0",
      toPay:
        timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10) &&
        paymentAmount > 0 &&
        getsPaid
          ? rounding(paymentAmount - paymentVendorProcessingFee(paymentAmount))
          : "0",
      gameKey: key,
      tier,
      tierViewers: uq ? bracket : t("downloadReports.noTierViewers"),
      tierPayments:
        uq && paymentAmount
          ? paymentAmount
          : t("downloadReports.noTierPayments"),
      averageViewersAtJoin,
      expectedPayoutDate:
        quest && quest.expectedPayoutDate
          ? moment(quest.expectedPayoutDate, "YYYY-MM-DD")
              .format("MMMM DD, YYYY")
              .toString()
          : "",
      link: link || "",
      endDateTime: endDateTime
        ? moment(endDateTime)
            .tz("America/Los_Angeles")
            .format("MMMM DD, YYYY -  - hh:mm a")
            .toString()
        : "",
      dateAccepted: acceptedDate
        ? moment(acceptedDate)
            .tz("America/Los_Angeles")
            .format("MMMM DD, YYYY -  - hh:mm a")
            .toString()
        : "",
      twitter:
        user.connectedAccounts.twitter &&
        user.connectedAccounts.twitter.username
          ? user.connectedAccounts.twitter.username
          : "- - -",
      twitterFollowers: user.connectedAccounts.twitter
        ? user.connectedAccounts.twitter.followers ||
          user.connectedAccounts.twitter.followers_count ||
          "- - -"
        : "- - -",
      profileImageURL,
      profileImageFunction: formatImageForCSV(profileImageURL),
    };

    if (quest.requirements) {
      const reqs = getQuestRequirements(quest, true);
      const completedReqs = getNumApprovedRequirements(quest, uq);
      const approvedReqs = getApprovedRequirements(quest, uq);

      approvedReqs.forEach((req) => {
        dataRow[req.questReqId] = req.content;

        // Type-specific additions
        if (req.type === "submit-link" && req.subtype === "vod") {
          dataRow[`${req.questReqId}-views`] = req.views ? req.views : "-";
          dataRow[`${req.questReqId}-lastPulled`] = req.lastPulled
            ? moment(req.lastPulled)
                .tz("America/Los_Angeles")
                .format("MMMM DD, YYYY - hh:mm a")
                .toString()
            : "-";
        } else if (req.type === "submit-tweet" && req.tweetData) {
          const { fullTweet, likes, quotes, retweets } = req.tweetData;

          if (!tweetData[i]) {
            tweetData[i] = [];
          }

          tweetData[i].push({
            link: fullTweet,
            likes,
            quotes,
            retweets,
          });
        }
      });

      if (tweetData[i] && tweetData[i].length > maxTweetData) {
        maxTweetData = tweetData[i].length;
      }

      dataRow.requirements = `${completedReqs} ${t("downloadReports.outOf")} ${
        reqs.length
      }`;
    } else {
      dataRow.requirements = "- - -";
    }

    dataRow.totalChatters = totalChatters;
    dataRow.totalMessages = totalMessages;
    dataRow.avgMessagesPerMinute = avgMessagesPerMinute;

    for (let j = 0; j < 5; j += 1) {
      // Handle Words
      const word = topWords[j];

      let wordEntry = "- - -";

      if (word) {
        wordEntry = `${word.word} [${word.count}]`;
      }

      dataRow[`topWord${j + 1}`] = wordEntry;

      // Handle Emotes
      const emote = topEmotes[j];

      let emoteEntry = "- - -";

      if (emote) {
        emoteEntry = `${emote.emote} [${emote.count}]`;
      }

      dataRow[`topEmote${j + 1}`] = emoteEntry;
    }

    for (let j = 0; j < chatMentions.length; j += 1) {
      const { value } = chatMentions[j];
      const findKeyword =
        chatDetails &&
        chatDetails.topKeywords.find((kw) => kw.keyword === value);

      let count = 0;

      if (findKeyword) {
        count = findKeyword.count;
      }

      // headers.push({ label: value, key: `keyword${j}` });
      dataRow[escapeSpecialCharacters(value)] = count;
    }

    dataCSV.push(dataRow);
  }

  if (maxTweetData > 0) {
    for (let i = 1; i <= maxTweetData; i += 1) {
      headers.push({
        label: `${t("downloadReports.tweet")} #${i}`,
        key: `tweet${i}_link`,
      });
      headers.push({
        label: `${t("downloadReports.tweet")} #${i} ${t(
          "downloadReports.likes"
        )}`,
        key: `tweet${i}_likes`,
      });
      headers.push({
        label: `${t("downloadReports.tweet")} #${i} Quotes`,
        key: `tweet${i}_quotes`,
      });
      headers.push({
        label: `${t("downloadReports.tweet")} #${i} ${t(
          "downloadReports.retweets"
        )}`,
        key: `tweet${i}_retweets`,
      });
    }

    const tweetKeys = Object.keys(tweetData);
    for (let i = 0; i < tweetKeys.length; i += 1) {
      const rowTweets = tweetData[tweetKeys[i]];

      for (let j = 0; j < rowTweets.length; j += 1) {
        const currentTweetData = rowTweets[j];
        const { link, likes, quotes, retweets } = currentTweetData;

        dataCSV[tweetKeys[i]][`tweet${j + 1}_link`] = link;
        dataCSV[tweetKeys[i]][`tweet${j + 1}_likes`] = likes;
        dataCSV[tweetKeys[i]][`tweet${j + 1}_quotes`] = quotes;
        dataCSV[tweetKeys[i]][`tweet${j + 1}_retweets`] = retweets;
      }
    }
  }

  headers.push({
    label: t("downloadReports.totalChatters"),
    key: "totalChatters",
  });
  headers.push({
    label: t("downloadReports.totalMessages"),
    key: "totalMessages",
  });
  headers.push({
    label: t("downloadReports.averageMessagesPerMinute"),
    key: "avgMessagesPerMinute",
  });

  for (let i = 0; i < 5; i += 1) {
    headers.push({
      label: `${t("downloadReports.topWord")} ${i + 1}`,
      key: `topWord${i + 1}`,
    });
  }

  for (let i = 0; i < 5; i += 1) {
    headers.push({
      label: `${t("downloadReports.topEmote")} ${i + 1}`,
      key: `topEmote${i + 1}`,
    });
  }

  for (let i = 0; i < chatMentions.length; i += 1) {
    const { value } = chatMentions[i];
    headers.push({ label: value, key: escapeSpecialCharacters(value) });
  }

  return {
    headers,
    data: dataCSV,
  };
};

export const gameKeysReport = (k) => {
  const dataCSV = [];
  const headersCSV = [
    { label: t("downloadReports.key"), key: "key" },
    { label: t("downloadReports.status"), key: "status" },
    { label: t("downloadReports.owner"), key: "owner" },
    { label: t("downloadReports.dateAssigned"), key: "dateAssigned" },
    { label: t("downloadReports.questTitle"), key: "questTitle" },
  ];

  for (let i = 0; i < k.length; i += 1) {
    let status = "Unassigned";
    if (k[i].owner || k[i].ownerEmail !== "") {
      if (k[i].onlyStore) {
        status = "Sold";
      } else {
        status = "Assigned";
      }
    }

    let owner = "Unassigned";
    if (status !== "Unassigned") {
      if (k[i].onlyStore && k[i].ownerEmail) {
        owner = k[i].ownerEmail;
      } else if (k[i].owner) {
        owner = k[i].owner.username;
      }
    }

    let dateAssigned = "-";
    if (status !== "Unassigned") {
      if (k[i].assignationDate) {
        dateAssigned = moment(k[i].assignationDate).format("YYYY-MM-DD h:mm a");
      } else if (k[i].purchaseData) {
        dateAssigned = moment(k[i].purchaseData.soldDate).format(
          "YYYY-MM-DD h:mm a"
        );
      } else if (k[i].updatedAt) {
        dateAssigned = moment(k[i].updatedAt).format("YYYY-MM-DD h:mm a");
      }
    }

    dataCSV.push({
      key: k[i].key,
      status,
      owner,
      dateAssigned,
      questTitle: k[i].purchaseData ? k[i].purchaseData.questTitle : "-",
    });
  }

  return {
    headersCSV,
    dataCSV,
  };
};

const mrConstantTimeCheck = (toObj, key, value) => {
  const quickChecker = {};
  toObj.forEach((item) => {
    quickChecker[item[key]] = item[value];
  });
  return quickChecker;
};

export const campaignLinksReport = (k, users = [], tab) => {
  // controlled via tab 0 = custom links, 1 = distributed links
  const tabIsCustom = tab === 0;
  const giveMeTheirUsername = mrConstantTimeCheck(users, "userId", "username");
  const dataCSV = [];
  const headersCSV = tabIsCustom
    ? [
        { label: t("downloadReports.link"), key: "link" },
        { label: t("downloadReports.campaign"), key: "campaign" },
        { label: t("downloadReports.isAssigned"), key: "isAssigned" },
        { label: t("downloadReports.user"), key: "user" },
        { lable: t("downloadReports.assignationDate"), key: "assignationDate" },
      ]
    : [
        { label: t("downloadReports.link"), key: "link" },
        { label: t("downloadReports.campaign"), key: "campaign" },
        { label: t("downloadReports.maskedLink"), key: "maskedLink" },
        { label: t("downloadReports.isRebrandly"), key: "isRebrandly" },
        { label: t("downloadReports.username"), key: "username" },
        { lable: t("downloadReports.clicks"), key: "clicks" },
      ];

  if (tabIsCustom) {
    for (let i = 0; i < k.length; i += 1) {
      const userKey = k[i].user;
      const username = giveMeTheirUsername[userKey];
      dataCSV.push({
        link: k[i].link,
        campaign: k[i].campaign,
        isAssigned: k[i].isAssigned,
        user: username || k[i].user,
        assignationDate: k[i].assignationDate,
      });
    }
  } else {
    for (let i = 0; i < users.length; i += 1) {
      dataCSV.push({
        link: users[i].link,
        campaign: users[i].campaign,
        maskedLink: users[i].maskedLink,
        isRebrandly: users[i].isRebrandly,
        username: users[i].username,
        clicks: users[i].clicks,
      });
    }
  }

  return {
    headersCSV,
    dataCSV,
  };
};

export const test = () => {
  const testing = 4;
  return {
    testing,
  };
};

export const getCSVQuest = (users, platform, quest, userQuests) => {
  const dataCSV = [];
  const approvableRequirements = getQuestRequirements(quest, true);
  let maxTweetData = 0;
  const tweetData = {};

  for (let i = 0; i < users.length; i += 1) {
    const currentUser = users[i];
    const {
      user,
      username,
      dateAccepted,
      completionStatus,
      twitchUsername,
      averageViewers,
      peakViewers,
      timePlaying,
      paymentAmount,
      key,
      tier,
      link,
      endDateTime,
      chatDetails,
    } = currentUser;

    const currentUserId = currentUser?.id || currentUser?._id;

    const averageViewersAtJoin = currentUser.averageViewersAtJoin
      ? Math.round(currentUser.averageViewersAtJoin)
      : 0;
    const userQuest = userQuests.find((uq) => uq.user.id === currentUserId);
    const userQuestRequirements = userQuest?.requirements || [];

    const profileImageURL =
      currentUser?.user?.connectedAccounts?.twitch?.profile_image_url || "";

    let siteAverageViewers = 0;
    if (platform === "twitch") {
      siteAverageViewers = getTwitchAverageViewers(user);
    }

    let acceptedDate = null;
    if (dateAccepted) {
      acceptedDate = dateAccepted;
    }

    const viewerTiers = quest.viewerTiers ? quest.viewerTiers : [];
    const memberTiers = quest.memberTiers ? quest.memberTiers : [];
    const getsPaid = completionStatus && completionStatus.status === "C";
    const allTheTierCalcs = calculatePaymentAmount(
      currentUser,
      averageViewersAtJoin,
      viewerTiers,
      memberTiers
    );
    const bracket = allTheTierCalcs.isMemberTier
      ? t("downloadReports.memberTier")
      : allTheTierCalcs.minViewers;

    // Standard CSV rows
    const dataRow = {
      questName: quest.title,
      noizUsername: username,
      ...(platform === "twitch" ? { twitchUsername } : {}),
      accountEmail: user ? user.email : "- - -",
      paymentEmail: user ? user.paymentEmail : "- - -",
      country: user.country,
      siteAverageViewers,
      questAverageViewers: averageViewers ? parseInt(averageViewers, 10) : "0",
      peakViewers: peakViewers ? parseInt(peakViewers, 10) : "0",
      hoursWatched:
        averageViewers && timePlaying
          ? parseFloat((averageViewers * timePlaying) / 60).toFixed(2)
          : "0",
      timePlaying,
      completed: completionStatus.status || "- - -",
      earnings:
        paymentAmount && getsPaid && paymentAmount !== -1
          ? rounding(paymentAmount)
          : 0,
      processingFee:
        paymentAmount && paymentAmount !== -1 && getsPaid
          ? rounding(paymentVendorProcessingFee(paymentAmount))
          : "0",
      toPay:
        timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10) &&
        paymentAmount > 0 &&
        getsPaid
          ? rounding(paymentAmount - paymentVendorProcessingFee(paymentAmount))
          : "0",
      gameKey: key,
      tier,
      tierViewers: currentUser ? bracket : t("downloadReports.noTierViewers"),
      tierPayments:
        currentUser && paymentAmount
          ? paymentAmount
          : t("downloadReports.noTierPayments"),
      averageViewersAtJoin,
      expectedPayoutDate:
        quest && quest.expectedPayoutDate
          ? moment(quest.expectedPayoutDate, "YYYY-MM-DD")
              .format("MMMM DD, YYYY")
              .toString()
          : "",
      link: link || "",
      endDateTime: endDateTime
        ? moment(endDateTime)
            .tz("America/Los_Angeles")
            .format("MMMM DD, YYYY - hh:mm a")
            .toString()
        : "",
      dateAccepted: acceptedDate
        ? moment(acceptedDate)
            .tz("America/Los_Angeles")
            .format("MMMM DD, YYYY - hh:mm a")
            .toString()
        : "",
      twitter:
        user.connectedAccounts.twitter &&
        user.connectedAccounts.twitter.username
          ? user.connectedAccounts.twitter.username
          : "- - -",
      twitterFollowers: user.connectedAccounts.twitter
        ? user.connectedAccounts.twitter.followers ||
          user.connectedAccounts.twitter.followers_count ||
          "- - -"
        : "- - -",
      totalChatters: (chatDetails && chatDetails.totalChatters) || "- - -",
      totalMessages: (chatDetails && chatDetails.totalMessages) || "- - -",
      avgMessagesPerMinute:
        (chatDetails && chatDetails.avgMessagesPerMinute) || "- - -",
      profileImageURL,
      profileImageFunction: formatImageForCSV(profileImageURL),
    };

    // Requirements CSV rows
    if (approvableRequirements) {
      for (let j = 0; j < approvableRequirements.length; j += 1) {
        const r = approvableRequirements[j];
        const ur = userQuestRequirements.find((uqr) => uqr.questReqId === r.id);

        let content = "---";
        let views = "---";
        let lastPulled = "---";
        let status = "Pending";

        if (ur?.content) {
          if (ur.type === "submit-tweet" || ur.type === "submit-vod") {
            content = ur.content.toLowerCase();
          } else {
            content = ur.content;
          }

          if (ur.status) {
            if (ur.status === "to-review") status = "Needs Review";
            else status = capitalizeFirstLetter(ur.status);
          }

          if (ur.views) views = ur.views;
          if (ur.lastPulled)
            lastPulled = moment(ur.lastPulled)
              .tz("America/Los_Angeles")
              .format("MMMM DD, YYYY - hh:mm a")
              .toString();
        }

        if (ur && ur.type === "submit-tweet" && ur.tweetData) {
          const { fullTweet, likes, quotes, retweets } = ur.tweetData;

          if (!tweetData[i]) {
            tweetData[i] = [];
          }

          tweetData[i].push({
            link: fullTweet,
            likes,
            quotes,
            retweets,
            status: ur.status,
          });

          if (tweetData[i].length > maxTweetData) {
            maxTweetData = tweetData[i].length;
          }
        }

        dataRow[`req_${j}`] = content;
        dataRow[`req_${j}_status`] = status;
        dataRow[`req_${j}_views`] = views;
        dataRow[`req_${j}_lastPulled`] = lastPulled;
      }
    }

    dataCSV.push(dataRow);
  }

  // Standard CSV headers
  const headers = [
    { label: t("downloadReports.questName"), key: "questName" },
    { label: t("downloadReports.noizUsername"), key: "noizUsername" },
    ...(platform === "twitch"
      ? [{ label: t("downloadReports.twitchUsername"), key: "twitchUsername" }]
      : []),
    { label: t("downloadReports.twitter"), key: "twitter" },
    { label: t("downloadReports.twitterFollowers"), key: "twitterFollowers" },
    {
      label: t("downloadReports.siteAverageViewers"),
      key: "siteAverageViewers",
    },
    { label: t("downloadReports.accountEmail"), key: "accountEmail" },
    { label: t("downloadReports.paymentEmail"), key: "paymentEmail" },
    { label: t("downloadReports.country"), key: "country" },
    { label: t("downloadReports.language"), key: "language" },
    {
      label: t("downloadReports.questAverageViewers"),
      key: "questAverageViewers",
    },
    { label: t("downloadReports.peakViewers"), key: "peakViewers" },
    { label: t("downloadReports.hoursWatched"), key: "hoursWatched" },
    { label: t("downloadReports.timePlaying"), key: "timePlaying" },
    { label: t("downloadReports.completed"), key: "completed" },
    { label: t("downloadReports.earnings"), key: "earnings" },
    { label: t("downloadReports.processingFee"), key: "processingFee" },
    { label: t("downloadReports.toPay"), key: "toPay" },
    { label: t("downloadReports.gameKey"), key: "gameKey" },
    { label: t("downloadReports.tier"), key: "tier" },
    { label: t("downloadReports.tierViewers"), key: "tierViewers" },
    { label: t("downloadReports.tierPayments"), key: "tierPayments" },
    {
      label: t("downloadReports.averageViewersAtJoin"),
      key: "averageViewersAtJoin",
    },
    {
      label: t("downloadReports.expectedPayoutDate"),
      key: "expectedPayoutDate",
    },
    { label: t("downloadReports.link"), key: "link" },
    { label: t("downloadReports.endDateTime"), key: "endDateTime" },
    { label: t("downloadReports.dateAccepted"), key: "dateAccepted" },
    { label: t("downloadReports.requirements"), key: "requirements" },
    { label: t("downloadReports.profileImageURL"), key: "profileImageURL" },
    {
      label: t("downloadReports.profileImageFunction"),
      key: "profileImageFunction",
    },
  ];

  // Append requirements columns
  if (approvableRequirements) {
    for (let i = 0; i < approvableRequirements.length; i++) {
      headers.push({ label: approvableRequirements[i].title, key: `req_${i}` });
      headers.push({
        label: `${t("downloadReports.statusOf")} ${
          approvableRequirements[i].title
        }`,
        key: `req_${i}_status`,
      });

      if (
        approvableRequirements[i].type === "submit-link" &&
        approvableRequirements[i].subtype === "vod"
      ) {
        headers.push({
          label: t("downloadReports.VodViews"),
          key: `req_${i}_views`,
        });
        headers.push({
          label: t("downloadReports.vodViewsLastPulled"),
          key: `req_${i}_lastPulled`,
        });
      }
    }
  }

  if (maxTweetData > 0) {
    for (let i = 1; i <= maxTweetData; i += 1) {
      headers.push({
        label: `${t("downloadReports.tweet")} #${i}`,
        key: `tweet${i}_link`,
      });
      headers.push({
        label: `${t("downloadReports.tweet")} #${i} ${t(
          "downloadReports.likes"
        )}`,
        key: `tweet${i}_likes`,
      });
      headers.push({
        label: `${t("downloadReports.tweet")} #${i} Quotes`,
        key: `tweet${i}_quotes`,
      });
      headers.push({
        label: `${t("downloadReports.tweet")} #${i} ${t(
          "downloadReports.retweets"
        )}`,
        key: `tweet${i}_retweets`,
      });
      headers.push({
        label: `${t("downloadReports.tweet")} #${i} Status`,
        key: `tweet${i}_status`,
      });
    }

    const tweetKeys = Object.keys(tweetData);
    for (let i = 0; i < tweetKeys.length; i += 1) {
      const rowTweets = tweetData[tweetKeys[i]];

      for (let j = 0; j < rowTweets.length; j += 1) {
        const currentTweetData = rowTweets[j];
        const { link, likes, quotes, retweets, status } = currentTweetData;

        dataCSV[tweetKeys[i]][`tweet${j + 1}_link`] = link;
        dataCSV[tweetKeys[i]][`tweet${j + 1}_likes`] = likes;
        dataCSV[tweetKeys[i]][`tweet${j + 1}_quotes`] = quotes;
        dataCSV[tweetKeys[i]][`tweet${j + 1}_retweets`] = retweets;
        dataCSV[tweetKeys[i]][`tweet${j + 1}_status`] = status;
      }
    }
  }

  return { headers, dataCSV };
};

export const getKeys = (users, platform, quest) => {
  users = users.filter((u) => u.key);
  const dataCSV = [];

  users.forEach((u) => {
    const dataRow = {
      questName: quest.title,
      noizUsername: u.username,
      ...(platform === "twitch" ? { twitchUsername: u.twitchUsername } : {}),
      accountEmail: u.email,
      key: u.key,
    };

    dataCSV.push(dataRow);
  });

  const headers = [
    { label: t("downloadReports.questName"), key: "questName" },
    { label: t("downloadReports.noizUsername"), key: "noizUsername" },
    ...(platform === "twitch"
      ? [{ label: t("downloadReports.twitchUsername"), key: "twitchUsername" }]
      : []),
    { label: t("downloadReports.accountEmail"), key: "accountEmail" },
    { label: t("downloadReports.key"), key: "key" },
  ];

  return {
    headers,
    dataCSV,
  };
};
