import React from 'react'
import './Toggle.scss';

function Toggle({callback = (v)=> console.log(v), value = false}) {
  return (
    <label className='noiz-switch '>
    <input checked={value} onChange={(e)=> callback(e.currentTarget.checked)} type='checkbox'/> 
    <span className='noiz-slider round'></span>
  </label>
  )
}

export default Toggle