import React from "react";
import { useTranslation } from "react-i18next";

import locationIcon from "../../global/images/icons/location-icon.svg";

const Countries = (props) => {
  const { t } = useTranslation("quest_Countries");
  const { countries = [t("unitedStates")] } = props;

  return (
    <div className="eligibilityItem">
      <img src={locationIcon} alt="feedback" />
      <span className="label">
        {t("streamFrom")} {countries.slice(0, -1).join(", ")}
        {countries.length > 2 && ","}
        {countries.length > 1 && " or "}
        {countries.slice(-1)}
      </span>
    </div>
  );
};

export default Countries;
