import React from "react";
import "./ReportMetric.scss";

import { numberWithCommas } from "../../../../../utils/functions";
import LoadingGif from "../../assets/LoadingGif";
import { useTranslation } from "react-i18next";

const getBackgroundImage = (color = "blue-gradient") => {
  const configObj = {
    "teal-gradient": "linear-gradient(to bottom, #4be0ca, #b7bbfd)",
    "purple-gradient": "linear-gradient(to bottom, #e8cdfe, #b7bbfd)",
    "pink-gradient": "linear-gradient(to bottom, #fd8d82, #a142ff)",
    "blue-gradient": "linear-gradient(to bottom, #0075e4, #a142ff)",
  };
  return { backgroundImage: configObj[color] };
};

const makeStyleObj = (...theArgs) => {
  return theArgs.reduce((acc, curr) => {
    acc = { ...acc, ...curr };
    return acc;
  }, {});
};

const ReportMetric = ({
  value,
  metric,
  extraClass = "",
  color,
  isLoading = false,
  textColor = "#1f2227",
  margin = "0px 0px 0px 0px",
  error = false,
  errorMessage,
}) => {
  const { t } = useTranslation("reportMetric");
  const styles = makeStyleObj(getBackgroundImage(color), {
    color: textColor,
    margin: margin,
  });
  const falseyButNotZero = value !== 0 && !value;
  const renderLoading = falseyButNotZero || isLoading;
  const renderError = error;
  const finalValue =
    value < 1 && value > 0
      ? Math.round(value * 100) / 100
      : numberWithCommas(Math.round(value) || 0);

  return (
    <div className={`Portal_ReportMetric_Wrapper ${extraClass}`} style={styles}>
      {!renderError && renderLoading && <LoadingGif />}
      <div className="Portal_ReportMetric_Number">
        {renderError && <i className="fa fa-exclamation-circle" />}
        {!renderError && !renderLoading && finalValue}
      </div>
      <div className="Portal_ReportMetric_Title">
        {renderError && (errorMessage || t("errorMessage"))}
        {!renderError && metric}
      </div>
    </div>
  );
};

export default ReportMetric;
