import React from "react";
import { useTranslation } from "react-i18next";

import { getQuestRequirements } from "../../utils/functions";

import "./ArbitrationStatusStreamer.scss";

const ArbitrationStatusStreamer = (props) => {
  const { t } = useTranslation("arbitrationStatusStreamer");

  const decisionSwitch = {
    pending: t("pending"),
    brand: t("brand"),
    streamer: t("streamer"),
    other: t("other"),
  };

  const {
    ruledInFavorOf = "pending",
    // questTitle = "Quest",
    // disputeReason,
    cover,
    disputeResponse,
    quest,
  } = props;
  console.log(props, "props in arb stat");
  const message =
    ruledInFavorOf === "pending" ? t("isUnderReview") : t("reviewConcluded");
  const { title, arbitration, cover: questCover } = quest;
  const requirements = getQuestRequirements(quest);
  const disputedRequirements = requirements.filter((req) =>
    arbitration?.disputedRequirements?.includes(req.id)
  );
  console.log(disputedRequirements, "disputed");
  console.log(quest, "quest");
  return (
    <>
      <div className="ArbitrationStatusStreamer_Container">
        <div className="ArbitrationStatusStreamer_TitleBox">
          <div>
            <img
              className="quest-image"
              src={cover || questCover}
              alt="cover"
            />
          </div>
        </div>
        <div className="ArbitrationStatusStreamer_Decision">
          <div className="title">
            {title}
            <span className="reason">
              <strong>{message}</strong>
            </span>
          </div>
          <div className="decision">
            <div>{decisionSwitch[ruledInFavorOf]}</div>
          </div>

          {!disputeResponse?.length ? (
            <div className="reason">{t("reason")} </div>
          ) : null}

          {!!disputeResponse?.length ? (
            <div className="ArbitrationStatusStreamer_Comments">
              <div>
                <strong>{t("noizComments")}</strong>
              </div>
              <div className="comments">{disputeResponse}</div>
            </div>
          ) : null}

          <div className="reason">{disputedRequirements} </div>
        </div>
      </div>
    </>
  );
};

export default ArbitrationStatusStreamer;
// brandUser: "5d55959c723dec098b915603";
// cmNotes: "";
// createdAt: "2022-10-11T23:21:14.427Z";
// disputeReason: "asdfasdfa";
// disputeResponse: "";
// disputedRequirements: (2)[("854ade2266164383acae123cbb144845", "requiredStreamTimeMinutes")];
// fundingId: "";
// id: "6345fa6ac930b23e6d9e7326";
// quest: "6344a0d946f0a71e47fafd02";
// refundId: "";
// ruledInFavorOf: "pending";
// streamer: "5eb94c54482bcc4ccb665ab1";
// transferId: "";
// updatedAt: "2022-10-11T23:21:14.427Z";
