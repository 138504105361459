import qs from "qs";
import { Observable } from "rxjs/Observable";
import { queryString } from "../utils/functions";
import { multiLanguage } from "./util";

function request({ url, method, body, headers }) {
  const token = localStorage.getItem("token");
  const commonHeader = {
    "Accept-Language": multiLanguage,
  };
  if (token) {
    commonHeader.Authorization = `JWT ${token}`;
  }
  const ajax = Observable.ajax({
    url,
    method,
    body,
    headers: {
      ...commonHeader,
      ...headers,
    },
  });
  return ajax;
}

const api = {
  // START NOIZ V2 API CALLS
  getInitialInfo: () =>
    request({
      url: "/api/v1/account/get-initial-info",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getTwitchLanguage: () =>
    request({
      url: "/api/v1/account/twitch-stream-language",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getBroadcast: (broadcast) =>
    request({
      url: `/api/v1/user/broadcasts/${broadcast}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateBroadcast: () =>
    request({
      url: "/api/v1/user/broadcasts",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getGameList: () =>
    request({
      url: "/api/v1/game",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getGame: (gameId) =>
    request({
      url: `/api/v1/game/${gameId}`,
      method: "GET",
    }),
  requestGameKey: (gameId) =>
    request({
      url: `/api/v1/game/${gameId}/request-game-key`,
      method: "POST",
    }),
  joinToBounty: (bountyId, paymentEmail) =>
    request({
      url: `/api/v1/bounty/${bountyId}/join`,
      method: "POST",
      body: {
        paymentEmail,
      },
    }),
  leaveBounty: (bountyId) =>
    request({
      url: `/api/v1/bounty/${bountyId}/leave`,
      method: "POST",
    }),
  getGameLiveStreams: (gameId) =>
    request({
      url: `/api/v1/admin/get-game-live-streams/${gameId}`,
      method: "GET",
    }),
  getGameBountiesData: (gameId) =>
    request({
      url: `/api/v1/admin/get-game-bounties-data/${gameId}`,
      method: "GET",
    }),
  getBountyPredictions: (bountyId) =>
    request({
      url: `/api/v1/admin/bounty/${bountyId}/predictions`,
      method: "GET",
    }),
  uploadKeys: (multipartFormData, gameId) =>
    request({
      url: `/api/v1/admin/game-key/import/${gameId}`,
      method: "POST",
      body: multipartFormData,
    }),
  getGameKeys: (gameId) =>
    request({
      url: `/api/v1/admin/game/${gameId}/keys`,
      method: "GET",
    }),
  deleteGameKeys: (gameKeyIds) =>
    request({
      url: "/api/v1/admin/game-key/",
      method: "DELETE",
      body: {
        gameKeyIds,
      },
    }),
  getCCGameList: ({ owner = null, skip = null, limit = null, brand = false }) =>
    request({
      url: `/api/v1/admin/game${queryString({ owner, skip, limit, brand })}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getWhiteList: (gameId) =>
    request({
      url: `/api/v1/admin/game/${gameId}/white-list`,
      method: "GET",
    }),
  toggleUserInWhiteList: (gameId, userId) =>
    request({
      url: `/api/v1/admin/game/${gameId}/white-list/${userId}`,
      method: "PUT",
    }),
  createUserAndWhiteListIt: (gameId, username, email) =>
    request({
      url: `/api/v1/admin/game/${gameId}/white-list`,
      method: "POST",
    }),
  findUsers: (query) =>
    request({
      url: `/api/v1/admin/user/${query}/find`,
      method: "GET",
    }),
  getGameActivity: (startDate, endDate, gameName) =>
    request({
      url: "/api/v1/admin/game/get-game-activity",
      method: "PUT",
      body: {
        start: startDate,
        end: endDate,
        gameName,
      },
    }),
  distributeGameKeys: (gameId) =>
    request({
      url: `/api/v1/admin/game-key/distribute/${gameId}`,
      method: "POST",
    }),
  assignNewKeyPlatforms: (gameId, platformId, keyIds) =>
    request({
      url: `/api/v1/admin/game-key/assign-new-platform/${gameId}`,
      method: "PUT",
      body: {
        platformId,
        keyIds,
      },
    }),

  // END OF NOIZ V2 API CALLS

  uploadCSV: (multipartFormData) =>
    request({
      url: "/api/v1/admin/import/users",
      method: "POST",
      body: multipartFormData,
    }),
  // scheduleABroadcast: (scheduledBroadcastInfo, agreementId, token) =>request({
  //   url: `/api/v1/user/agreement/${agreementId}/schedule`,
  //   method: 'POST',
  //   body: scheduledBroadcastInfo,
  //   headers: {
  //
  //     'Content-Type': 'application/json',
  //   },
  // }),
  // deleteScheduledBroadcast: (scheduledBroadcastId, token) =>request({
  //   url: `/api/v1/user/scheduled-broadcast/${scheduledBroadcastId}`,
  //   method: 'DELETE',
  //   headers: {
  //
  //     'Content-Type': 'application/json',
  //   },
  // }),
  // getLastAgreement: token =>request({
  //   url: '/api/v1/user/agreement',
  //   method: 'GET',
  //   headers: {
  //
  //   },
  // }),
  loginWithTwitchOAuthCode: (code, scope) =>
    request({
      url: `/api/v1/auth/twitch/callback?${qs.stringify({ code, scope })}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  loginWithTwitterOAuthCode: (oauth_token, oauth_verifier) => {
    console.log(oauth_verifier, "verifier");
    return request({
      url: `/api/v1/auth/twitter/callback?${qs.stringify({
        oauth_token,
        oauth_verifier,
      })}`,
      method: "POST",
      body: { oauth_token, oauth_verifier },
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  loginWithYoutubeOAuthCode: (code, scope) =>
    request({
      url: `/api/v1/auth/youtube/callback?${qs.stringify({ code, scope })}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateUserInfo: (userInfo) =>
    request({
      url: "/api/v1/account/basic-info",
      method: "PUT",
      body: userInfo,
      headers: {
        "Content-Type": "application/json",
      },
    }),
  // <----- Noiz V3 ------>
  signIn: (payload) =>
    request({
      url: "/api/v1/auth/sign-in",
      method: "POST",
      body: payload,
    }),
  signUp: (userInfo) =>
    request({
      url: "/api/v1/auth/sign-up",
      method: "POST",
      body: userInfo,
    }),
  recover: (identity) =>
    request({
      url: "/api/v1/auth/recover",
      method: "POST",
      body: {
        identity,
      },
    }),
  recoverValidate: (recoveryToken) =>
    request({
      url: "/api/v1/auth/recover/validate",
      method: "POST",
      body: {
        recoveryToken,
      },
    }),
  recoverChangePassword: (recoveryToken, password, rePassword) =>
    request({
      url: "/api/v1/auth/recover/change-password",
      method: "POST",
      body: {
        recoveryToken,
        password,
        rePassword,
      },
    }),
  getQuest: (questId, openModal) =>
    request({
      url: `/api/v1/quest/${questId}/${openModal}`,
      method: "GET",
    }),
  joinQuest: (questId, data) =>
    request({
      url: `/api/v1/quest/${questId}/join`,
      method: "POST",
      body: {
        data,
      },
    }),
  joinQuestOffplatform: (questId, userId) =>
    request({
      url: `/api/v1/guest/quest/${questId}/join/${userId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  leaveQuest: (questId, reason) =>
    request({
      url: `/api/v1/quest/${questId}/leave`,
      method: "POST",
      body: { reason },
    }),
  deleteQuest: (questId) =>
    request({
      url: `/api/v1/admin/quest/${questId}`,
      method: "DELETE",
    }),

  requestGameKeyByQuest: (questId) =>
    request({
      url: `/api/v1/quest/${questId}/request-game-key`,
      method: "POST",
    }),

  getAdminQuestListByGame: (gameId) =>
    request({
      url: `/api/v1/admin/quest-by-game/${gameId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  getCCQuestList: () =>
    request({
      url: "/api/v1/admin/quests",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  copyCCQuest: (questId, gameId) =>
    request({
      url: `/api/v1/admin/quest/${questId}/copy`,
      method: "POST",
      body: {
        gameId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),

  getCCQuest: (questId) =>
    request({
      url: `/api/v1/admin/quest/${questId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  createCCQuest: (quest) =>
    request({
      url: "/api/v1/admin/quest",
      method: "POST",
      body: {
        ...quest,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateCCQuest: (questId, quest) =>
    request({
      url: `/api/v1/admin/quest/${questId}`,
      method: "PUT",
      body: {
        ...quest,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAUserList: (pageNum, filter, sorting, exactMatch) =>
    request({
      url: `/api/v1/admin/users/${pageNum}/${filter}/${sorting}/${exactMatch}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAUserListCsv: (pageNum, filter, sorting) =>
    request({
      url: `/api/v1/admin/users/${pageNum}/${filter}/${sorting}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAUser: (userId) =>
    request({
      url: `/api/v1/admin/user/${userId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  createAUser: (user) =>
    request({
      url: "/api/v1/admin/user",
      method: "POST",
      body: {
        user,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  createAUserBrand: (user) =>
    request({
      url: "/api/v1/admin/user-brand",
      method: "POST",
      body: {
        user,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateAUser: (userId, user) =>
    request({
      url: `/api/v1/admin/user/${userId}`,
      method: "PUT",
      body: {
        user,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAConfigList: () =>
    request({
      url: "/api/v1/admin/config",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAConfig: (configId) =>
    request({
      url: `/api/v1/admin/config/${configId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  createAConfig: (config) =>
    request({
      url: "/api/v1/admin/config",
      method: "POST",
      body: {
        config,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateAConfig: (config) =>
    request({
      url: "/api/v1/admin/config",
      method: "PUT",
      body: {
        config,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getSFGame: (gameId) =>
    request({
      url: `/api/v1/storefront/game/${gameId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  disconnectTwitch: () =>
    request({
      url: "/api/v1/auth/twitch/disconnect",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  disconnectYoutube: () =>
    request({
      url: "/api/v1/auth/youtube/disconnect",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getSFGameBySlug: (slug) =>
    request({
      url: `/api/v1/storefront/game-by-slug/${slug}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  postGamePayment: (description, source, currency, amount) =>
    request({
      url: "/api/v1/stripe/payment/post",
      method: "POST",
      body: {
        description,
        source,
        currency,
        amount,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getUserPayments: () =>
    request({
      url: "/api/v1/user/payments/get",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateProfileInfo: (profileInfo) =>
    request({
      url: "/api/v1/user/profile",
      method: "PUT",
      body: {
        ...profileInfo,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  validateUserHandshake: (handshake) =>
    request({
      url: `/api/v1/user/profile/validate-handshake/${handshake}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getUserTodayActivity: () =>
    request({
      url: "/api/v1/user/activity/get",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCQuestActivity: (questId) =>
    request({
      url: `/api/v1/admin/quest/${questId}/activity`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCQuestSales: (questId) =>
    request({
      url: `/api/v1/admin/quest/${questId}/sales`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getQuestByIdentifier: (identifier, type = "slug") =>
    request({
      url: `/api/v1/quest-by-identifier/${identifier}/${type}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getQuestBySlugGuest: (slug, userId) =>
    request({
      url: `/api/v1/quest-by-slug-guest/${slug}/${userId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getUserReferralsActivity: () =>
    request({
      url: "/api/v1/user/activity/referrals",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  requestReferralCodeByQuest: (questId) =>
    request({
      url: `/api/v1/quest/${questId}/request-referral-code`,
      method: "POST",
    }),
  getCCQuestTimed: (questId) =>
    request({
      url: `/api/v1/admin/quest/${questId}/timed`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAGameList: (pageNum, filter) =>
    request({
      url: `/api/v1/admin/games/${pageNum}/${filter}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAGame: (gameId) =>
    request({
      url: `/api/v3/admin/game/${gameId}`, // v3 to avoid conflict
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  createAGame: (game) =>
    request({
      url: "/api/v1/admin/game",
      method: "POST",
      body: {
        ...game,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateAGame: (gameId, game) =>
    request({
      url: `/api/v1/admin/game/${gameId}`,
      method: "PUT",
      body: {
        ...game,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  createAPlatform: (platform) =>
    request({
      url: "/api/v1/admin/platform",
      method: "POST",
      body: {
        ...platform,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAllPlatforms: () =>
    request({
      url: "/api/v1/admin/platform/get-all",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateAFeatured: (featuredGames) =>
    request({
      url: "/api/v1/admin/game-featured",
      method: "PUT",
      body: {
        featuredGames,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCQuestGeneral: (numPage, pageSize, tab, questId, filtering, type) =>
    request({
      url: `/api/v1/admin/quest/${questId}/general/${type}/${numPage}/${pageSize}/${filtering}/${tab}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCQuestGeneralCsv: (numPage, pageSize, tab, questId, filtering, type) =>
    request({
      url: `/api/v1/admin/quest/${questId}/general/${type}/${numPage}/${pageSize}/${filtering}/${tab}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCQuestAffiliate: (questId) =>
    request({
      url: `/api/v1/admin/quest/${questId}/affiliate`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getUserTimedActivity: () =>
    request({
      url: "/api/v1/user/activity/timed",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  submitLink: (link, questId) =>
    request({
      url: "/api/v1/user/submit-link",
      method: "POST",
      body: {
        link,
        questId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  submitAffiliateData: (data, stepType, stepTitle, questId, kind) =>
    request({
      url: "/api/v1/user/submit-affiliate-data",
      method: "POST",
      body: {
        data,
        stepType,
        stepTitle,
        questId,
        kind,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  submitRequirementData: (data) =>
    request({
      url: "/api/v1/user/submit-requirement-data",
      method: "POST",
      body: {
        data,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  checkRequirementData: (
    reqId,
    checked,
    type,
    title,
    questId,
    approvalRequired
  ) =>
    request({
      url: "/api/v1/user/check-requirement-data",
      method: "POST",
      body: {
        reqId,
        checked,
        type,
        title,
        questId,
        approvalRequired,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),

  postCCPreapproval: (questId, userId, status, tab, numPage, pageSize) =>
    request({
      url: "/api/v1/quest/preapproval",
      method: "POST",
      body: {
        questId,
        userId,
        status,
        tab,
        numPage,
        pageSize,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),

  postCCRequirement: (data) =>
    request({
      url: "/api/v1/quest/requirement",
      method: "POST",
      body: {
        data,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  postCCRequirementMultiple: (data, questId) =>
    request({
      url: "/api/v1/quest/requirement-multiple",
      method: "POST",
      body: {
        data,
        questId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getMyQuestsPageData: () =>
    request({
      url: "/api/v1/user/my-quests",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  postCCAffiliateResolution: (data) =>
    request({
      url: "/api/v1/quest/affiliate-resolution",
      method: "POST",
      body: {
        data,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  verifyYoutubeChannelStreamStatus: (questId) =>
    request({
      url: `/api/v1/streaming/youtube/status/${questId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  // START NOIZ V4 API CALLS
  getOptimizedQuests: (filterType, page) =>
    request({
      url: `/api/v4/quests?page=${page}&filterType=${filterType}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getGuestQuests: () =>
    request({
      url: "/api/v4/guest/quests",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCQuestTiered: (questId) =>
    request({
      url: `/api/v1/admin/quest/${questId}/tiered`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCQuestTieredMultipleDays: (questId) =>
    request({
      url: `/api/v1/admin/quest/${questId}/tiered-multiple-days`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  requestUpdateAvatarUrl: () =>
    request({
      url: `/api/v1/user/request-update-avatar-url/`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  requestUploadImageUrl: (slug, format) =>
    request({
      url: `/api/v1/admin/request-upload-image-url/${slug}/${format}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  requestUploadCCImageUrl: (slug, type) =>
    request({
      url: `/api/v1/admin/request-upload-image-url/${slug}/${type}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getATrackedGames: () =>
    request({
      url: "/api/v1/admin/game-tracked",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCCampaignListByGame: (gameId) =>
    request({
      url: `/api/v1/admin/campaign-by-game/${gameId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCCampaign: (gameId, campaignId) =>
    request({
      url: `/api/v1/admin/campaign/${gameId}/${campaignId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateCCCampaign: (campaignId, campaignData) =>
    request({
      url: `/api/v1/admin/campaign/${campaignId}`,
      method: "PUT",
      body: campaignData,
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateCCGamesPlatform: (gameIds, platformIds) =>
    request({
      url: "/api/v2/admin/game/update-platforms",
      method: "PUT",
      body: {
        gameIds,
        platformIds,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  updateCCQuestsPlatform: (questIds, platformIds) =>
    request({
      url: "/api/v2/admin/quest/update-platforms",
      method: "PUT",
      body: {
        questIds,
        platformIds,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  createCCCampaign: (campaignData) =>
    request({
      url: "/api/v1/admin/campaign",
      method: "POST",
      body: campaignData,
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getBUCampaignList: () =>
    request({
      url: "/api/v1/user-brand/campaigns",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getBUCampaign: (campaignId, startDate, endDate) =>
    request({
      url: "/api/v1/user-brand/campaign",
      method: "PUT",
      body: {
        campaignId,
        startDate,
        endDate,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getACampaignList: () =>
    request({
      url: "/api/v1/admin/brand-campaigns",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getACampaign: (campaignId) =>
    request({
      url: `/api/v1/admin/brand-campaign/${campaignId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getACampaignQuestsReport: (campaignId) =>
    request({
      url: `/api/v1/admin/brand-campaign/${campaignId}/quests-report`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getStoreGames: () =>
    request({
      url: "/api/v1/store/games",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  addCCUserQuest: (userId, questId, status) =>
    request({
      url: "/api/v1/admin/quest/add-user-quest",
      method: "POST",
      body: {
        userId,
        questId,
        status,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),

  removeCCUserQuest: (userId, questId) =>
    request({
      url: "/api/v1/admin/quest/remove-user-quest",
      method: "POST",
      body: {
        userId,
        questId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  moveCCUserQuest: (userId, fromQuestId, toQuestId, status) =>
    request({
      url: "/api/v1/admin/quest/move-user-quest",
      method: "POST",
      body: {
        userId,
        fromQuestId,
        toQuestId,
        status,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getCCActiveQuestsByGame: (gameId) =>
    request({
      url: `/api/v1/admin/game/${gameId}/get-active-quests`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAQuestList: () =>
    request({
      url: "/api/v1/admin/quests",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAPaymentList: (pageNum, filter, sorting) =>
    request({
      url: `/api/v1/admin/payments/${pageNum}/${filter}/${sorting}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getAPaymentListCsv: (pageNum, filter, sorting) =>
    request({
      url: `/api/v1/admin/payments/${pageNum}/${filter}/${sorting}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getPaypalPayments: () =>
    request({
      url: "/api/v1/paypal/payments",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  createPromoLinks: (
    users,
    campaign,
    destinationUrl,
    domain,
    slashtagOverride,
    preassignedLinks
  ) =>
    request({
      url: "/api/v1/link/links",
      method: "POST",
      body: {
        users,
        campaign,
        destinationUrl,
        domain,
        slashtagOverride,
        preassignedLinks,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  deleteLinks: (assignedLinks, unassignedLinks) =>
    request({
      url: "/api/v1/link/links",
      method: "DELETE",
      body: {
        assignedLinks,
        unassignedLinks,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  uploadLinks: (multipartFormData, campaignId) =>
    request({
      url: `/api/v1/link/links/upload-csv/${campaignId}`,
      method: "POST",
      body: multipartFormData,
    }),
  assignCustomLinks: (campaignId) =>
    request({
      url: "/api/v1/link/links/assign-custom",
      method: "POST",
      body: {
        campaignId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  assignMasterLinks: (campaignId) =>
    request({
      url: "/api/v1/link/links/assign-master",
      method: "POST",
      body: {
        campaignId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  getLinksDashboard: (campaignId) =>
    request({
      url: `/api/v1/admin/campaign/links/${campaignId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  postReputationRating: (questId, userId, reputationObj) =>
    request({
      url: "/api/v1/admin/reputation-system/update",
      method: "POST",
      body: { questId, userId, reputationObj },
      headers: {
        "Content-Type": "application/json",
      },
    }),
  createOffPlatformUser: (twitchLogin, email, paymentEmail, first, last) =>
    request({
      url: "/api/v1/admin/user/off-platform",
      method: "POST",
      body: { twitchLogin, email, paymentEmail, first, last },
      headers: {
        "Content-Type": "application/json",
      },
    }),

  getBrandGame: ({
    gameId = "",
    gameQuery = null,
    numPage = 1,
    pageSize = 10,
    sort = "desc",
    statusGame = "",
  }) =>
    request({
      url: `/api/v1/user-brand/game/get${queryString({
        gameId,
        gameQuery,
        numPage,
        pageSize,
        sort,
        statusGame,
      })}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  getBrandQuest: ({
    questId = null,
    gameQuery = null,
    questQuery = null,
    statusQuest = "all",
    startDate,
    endDate,
    sort = "desc",
    numPage = 1,
    pageSize = 10,
    statusGame = "",
  }) =>
    request({
      url: `/api/v1/user-brand/quest/get${queryString({
        sort,
        questId,
        numPage,
        pageSize,
        statusGame,
        gameQuery,
        questQuery,
        statusQuest,
        startDate,
        endDate,
      })}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  getBrandStreamer: (username) =>
    request({
      url: `/api/v1/user-brand/streamer/get/${username}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  getBrandUserQuest: (userQuestId) =>
    request({
      url: `/api/v1/user-brand/quest/requirement/${userQuestId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  brandUserUploadImageUrl: (format) =>
    request({
      url: `/api/v1/user-brand/request-upload-image-url/${format}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  postBrandGame: (game) =>
    request({
      url: "/api/v1/user-brand/game",
      method: "POST",
      body: game,
      headers: {
        "Content-Type": "application/json",
      },
    }),

  postBrandPreapproval: (userQuestIds, status) =>
    request({
      url: "/api/v1/user-brand/quest/preapproval",
      method: "POST",
      body: {
        userQuestIds,
        status,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }),

  postBrandQuest: (quest) =>
    request({
      url: "/api/v1/user-brand/quest/",
      method: "POST",
      body: quest,
      headers: {
        "Content-Type": "application/json",
      },
    }),

  postBrandRequirement: (requirement) =>
    request({
      url: "/api/v1/user-brand/quest/requirement",
      method: "POST",
      body: requirement,
      headers: {
        "Content-Type": "application/json",
      },
    }),

  putBrandGame: (game) =>
    request({
      url: `/api/v1/user-brand/game/${game.id}`,
      method: "PUT",
      body: game,
      headers: {
        "Content-Type": "application/json",
      },
    }),

  putBrandQuest: (quest) =>
    request({
      url: `/api/v1/user-brand/quest/${quest.id}`,
      method: "PUT",
      body: { ...quest },
      headers: {
        "Content-Type": "application/json",
      },
    }),
};

export default api;

// const brandGame = (req) => {
//   queryString: 'Super Mario Bros',
//   sort: 'createdAt DESC' || 'createdAt ASC',
//   numPage: 1,
//   page_size: 10,
//   statusGame = 'active' || 'in progress' || 'archived' || 'disabled'
// }

// 'GET /api/v1/user-brand/game/get': 'user-brand/game/get',
// 'GET /api/v1/user-brand/quest/get': 'user-brand/quest/get',

// const brandQuest = (req) => {
//   questString: 'Super Mario Bros Super Quest',
//   sort: 'createdAt DESC' || 'createdAt ASC',
//   numPage: 1,
//   page_size: 10,
//   statusQuest = 'active' || 'in progress' || 'archived' || 'disabled',
//   startDate: '2020-01-01' // iso date,
//   endDate: '2020-02-01' // iso date
// }
