/* eslint-disable */
import React from "react";
import { withTranslation } from "react-i18next";

import "./static.css";

class DnlReferralSalesTermsConditions extends React.Component {
  render() {
    const { t} = this.props
    return (
      <section className="PrivacyPolicy static">
        <h1>{t("title")}</h1>
        <h1>{t("sub_title")}</h1>

        <p>
          {t("section_1.title")}
          <ul>
            {Array.from({ length: 8 }).map((item, index) => (
              <li>{t(`section_1.content.${index}`)}</li>
            ))}
          </ul>
        </p>

        <p>{t("section_2")}</p>

        <h2>{t("section_3")}</h2>
        <p>{t("section_3.content")}</p>

        <h2>{t("section_4.title")}</h2>
        <p>{t("section_4.content")}</p>

        <h2>{t("section_5")}</h2>

        <a
          href="http://www.fppc.ca.gov/learn/campaign-rules/campaign-advertising-requirements-restrictions.html"
          rel="nofollow noopener noreferrer"
        >
          http://www.fppc.ca.gov/learn/campaign-rules/campaign-advertising-requirements-restrictions.html
        </a>

        <h2>{t("section_6.title")}</h2>
        <p>{t("section_6.content")}</p>

        <h2>{t("section_7.title")}</h2>
        <ul>
          <li>{t("section_7.content")}</li>
        </ul>

        <h2>{t("section_8.title")}</h2>

        <ul>
          <li>{t("section_8.content")}</li>
        </ul>

        <h2>{t("section_9.title")}</h2>
        <ul>
          <li>{t("section_9.content.0")}</li>
          <li>{t("section_9.content.1")}</li>
        </ul>
        <p>{t("lastUpdated")}</p>
      </section>
    );
  }
}

export default withTranslation("dnlReferralSalesTermsConditions")(
  DnlReferralSalesTermsConditions
);
