import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { filter } from "lodash";
import { Link } from "react-router";
import "./CommandCenter.scss";
import AdminTabs from "../util/tabs/AdminTabs";
import AnalyticsPull from "./AnalyticsPull";
import Toggle from "../../../global/components/toggle/Toggle";

import { withTranslation } from "react-i18next";

class CommandCenter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      inRoot: this.props.router.location.pathname === "/admin/command-center",
      showArchivedGames: false,
      showDisabledGames: false,
      isBrandGamesList: localStorage.getItem("isBrandGamesList") === "true",
    };
  }

  UNSAFE_componentWillMount() {
    // TODO: Get list of Games
    const { token } = this.props.auth;
    const isBrand = localStorage.getItem("isBrandGamesList") === "true";
    this.props.getCCGameList({ brand: isBrand }, token);
  }

  onChangeBrandGamesList = (checkedBool) => {
    this.setState({ isBrandGamesList: checkedBool });
    localStorage.setItem("isBrandGamesList", checkedBool);
    this.props.getCCGameList({ brand: checkedBool }, this.props.auth.token);
  };

  render() {
    const { t } = this.props;
    const { showArchivedGames, showDisabledGames } = this.state;
    const { data: games = [], isLoading } = this.props.commandCenter.games;
    const { gameId } = this.props.router.params;
    const activeGames = filter(games, (x) => x.status === "active");
    const archivedGames = filter(games, (x) => x.status === "archived");
    const disabledGames = filter(games, (x) => x.status === "disabled");

    return (
      <section
        className={classNames("CommandCenter", { inRoot: this.state.inRoot })}
      >
        <section className="CommandCenter__wrapper">
          <AdminTabs {...this.props} />
          <h2 className="CommandCenter__title">{t("commandCenter")}</h2>
          <section className="CommandCenter__container">
            <section className="GameList">
              <h3 className="GameList__title">
                {t("games")}
                <div className="brand-toggle mt-5">
                  <div className="mr-8">
                    <Toggle
                      value={this.state.isBrandGamesList}
                      callback={(a) => this.onChangeBrandGamesList(a)}
                      title="Toggle Brand Games"
                    />
                  </div>
                  <div>{t("brandGames")} </div>
                </div>
              </h3>

              <section className="GameList__wrapper">
                {activeGames && activeGames.length > 0 && !isLoading && (
                  <ul className="GameList__items">
                    {activeGames.map((game) => (
                      <li
                        key={game.id}
                        className={classNames("GameList__GameItem", {
                          active: gameId === game.id,
                        })}
                      >
                        <Link
                          to={`/admin/command-center/${game.id}/quests`}
                          activeClassName="active"
                          href={`/admin/command-center/${game.id}/quests`}
                        >
                          {game.name}
                        </Link>
                        <Link
                          className="link-out"
                          to={`/admin/games/edit/${game.id}`}
                          href={`/admin/command-center/${game.id}/quests`}
                          target="_blank"
                        >
                          <i className="fa fa-external-link" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
                {archivedGames && archivedGames.length > 0 && !isLoading && (
                  <ul className="GameList__items">
                    <li
                      className="GameList__label"
                      onClick={() => {
                        this.setState({
                          showArchivedGames: !showArchivedGames,
                        });
                      }}
                      aria-hidden
                    >
                      {t("archived")} ({archivedGames.length})
                      <i
                        className={classNames("fa", {
                          "fa-plus": !showArchivedGames,
                          "fa-minus": showArchivedGames,
                        })}
                      />
                    </li>
                    {showArchivedGames &&
                      archivedGames.map((game) => (
                        <li
                          key={game.id}
                          className={classNames("GameList__GameItem", {
                            active: gameId === game.id,
                          })}
                        >
                          <Link
                            to={`/admin/command-center/${game.id}/quests`}
                            activeClassName="active"
                            href={`/admin/command-center/${game.id}/quests`}
                          >
                            {game.name}
                          </Link>
                          <Link
                            className="link-out"
                            to={`/admin/games/edit/${game.id}`}
                            href={`/admin/command-center/${game.id}/quests`}
                            target="_blank"
                          >
                            <i className="fa fa-external-link" />
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
                {disabledGames && disabledGames.length > 0 && !isLoading && (
                  <ul className="GameList__items">
                    <li
                      className="GameList__label"
                      onClick={() => {
                        this.setState({
                          showDisabledGames: !showDisabledGames,
                        });
                      }}
                      aria-hidden
                    >
                      {t("disabled")} ({disabledGames.length})
                      <i
                        className={classNames("fa", {
                          "fa-plus": !showDisabledGames,
                          "fa-minus": showDisabledGames,
                        })}
                      />
                    </li>
                    {showDisabledGames &&
                      disabledGames.map((game) => (
                        <li
                          key={game.id}
                          className={classNames("GameList__GameItem", {
                            active: gameId === game.id,
                          })}
                        >
                          <Link
                            to={`/admin/command-center/${game.id}/quests`}
                            activeClassName="active"
                            href={`/admin/command-center/${game.id}/quests`}
                          >
                            {game.name}
                          </Link>
                          <Link
                            className="link-out"
                            to={`/admin/games/edit/${game.id}`}
                            href={`/admin/command-center/${game.id}/quests`}
                            target="_blank"
                          >
                            <i className="fa fa-external-link" />
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}

                {!isLoading && <AnalyticsPull auth={this.props.auth} />}

                {isLoading && (
                  <section className="loading">
                    <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                  </section>
                )}
                {!games.length && !isLoading && (
                  <section className="no-game">{t("noGame")}</section>
                )}
              </section>
            </section>
            <section className="CommandCenter__child">
              {gameId && (
                <section className="CommandCenter__navigation">
                  <Link
                    to={`/admin/command-center/${gameId}/white-list`}
                    activeClassName="active"
                    href={`/admin/command-center/${gameId}/white-list`}
                  >
                    {t("whiteList")}
                  </Link>

                  <Link
                    to={`/admin/command-center/${gameId}/keys`}
                    activeClassName="active"
                    href={`/admin/command-center/${gameId}/keys`}
                  >
                    {t("keys")}
                  </Link>

                  <span
                    className={classNames({
                      active: window.location.pathname.includes("quests"),
                    })}
                  >
                    <Link
                      to={`/admin/command-center/${gameId}/quests`}
                      activeClassName="active"
                      href={`/admin/command-center/${gameId}/quests`}
                    >
                      {t("quests")}
                    </Link>
                  </span>

                  <span
                    className={classNames({
                      active: window.location.pathname.includes("campaigns"),
                    })}
                  >
                    <Link
                      to={`/admin/command-center/${gameId}/campaigns`}
                      activeClassName="active"
                      href={`/admin/command-center/${gameId}/campaigns`}
                    >
                      {t("campaigns")}
                    </Link>
                  </span>

                  <Link
                    to={`/admin/command-center/${gameId}/links`}
                    activeClassName="active"
                    href={`/admin/command-center/${gameId}/links`}
                  >
                    {t("links")}
                  </Link>
                </section>
              )}
              {this.props.children && this.props.children.props.children
                ? React.cloneElement(
                    this.props.children.props.children,
                    this.props
                  )
                : t("selectTip")}
            </section>
          </section>
        </section>
      </section>
    );
  }
}

CommandCenter.propTypes = {
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withTranslation("commandCenter")(CommandCenter);
