import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "@material-ui/core";

import { dynamicSort } from "../../../../utils/functions";

import Assignment from "./Assignment";
import SelectPlatform from "./SelectPlatform";
import { useTranslation } from "react-i18next";

const availableGameItems = (games, platformsSelected) =>
  games.reduce((acc, curr) => {
    const currPlatforms = curr.platforms.map((p) => p.id);
    let same = 0;

    for (let i = 0; i < platformsSelected.length; i += 1) {
      if (currPlatforms.includes(platformsSelected[i].id)) {
        same += 1;
      }
    }

    if (same < platformsSelected.length) {
      acc.push(curr);
    }

    return acc;
  }, []);

const availableQuestItems = (quests, platformsSelected) =>
  quests.reduce((acc, curr) => {
    const gamePlatforms = curr.game.platforms.map((p) => p.id);

    if (
      !gamePlatforms.length ||
      !gamePlatforms.includes(platformsSelected[0].id)
    ) {
      return acc;
    }

    const currPlatforms = curr.platforms.map((p) => p.id);

    if (!currPlatforms.includes(platformsSelected[0].id)) {
      acc.push(curr);
    }

    return acc;
  }, []);

const MassPlatform = (props) => {
  const {
    setMenuOption,
    getAllPlatforms,
    getCCQuestList,
    getCCGameList,
    updateCCGamesPlatform,
    updateCCQuestsPlatform,
    admin: {
      platforms: { data: platforms = [], isLoading: platformsLoading = true },
    },
    commandCenter: {
      games: { data: games = [], isLoading: gamesLoading = true },
      quests: { data: quests = [], isLoading: questsLoading = true },
    },
    auth: { token },
  } = props;

  const { t } = useTranslation("massPlatform");

  const [selection, setSelection] = useState(null);
  const [platformsSelected, setPlatformsSelected] = useState([]);
  const [next, setNext] = useState(false);

  useEffect(() => {
    getAllPlatforms(token);
  }, []);

  const handleSubmit = (listIds) => {
    if (selection === "game") {
      updateCCGamesPlatform(
        listIds,
        platformsSelected.map((p) => p.id),
        token
      );
    } else {
      updateCCQuestsPlatform(
        listIds,
        platformsSelected.map((p) => p.id),
        token
      );
    }

    // window.location.assign('/admin/command-center/');
    setMenuOption(0);
  };

  return (
    <div>
      <Tooltip title="Back" placement="top" style={{ marginBottom: 15 }}>
        <Button variant="contained" onClick={() => setMenuOption(0)}>
          <i className="fa fa-arrow-circle-left" />
        </Button>
      </Tooltip>
      {!next && (
        <SelectPlatform
          isLoading={platformsLoading}
          platforms={platforms}
          platformsSelected={platformsSelected}
          setNext={setNext}
          setPlatformsSelected={setPlatformsSelected}
        />
      )}
      {next && !selection && (
        <div>
          <Button
            variant="contained"
            onClick={() => setSelection("game")}
            style={{ marginRight: 20 }}
          >
            {t("games")}
          </Button>
          {platformsSelected.length === 1 && (
            <Button variant="contained" onClick={() => setSelection("quest")}>
              {t("quests")}
            </Button>
          )}
        </div>
      )}
      {next && selection && (
        <Assignment
          getList={selection === "game" ? getCCGameList : getCCQuestList}
          handleSubmit={handleSubmit}
          list={
            selection === "game"
              ? availableGameItems(
                  games.sort(dynamicSort("name", "asc")),
                  platformsSelected
                )
              : availableQuestItems(
                  quests.sort(dynamicSort("title", "asc")),
                  platformsSelected
                )
          }
          platformsSelected={platformsSelected}
          token={token}
          selection={selection}
          isLoading={selection === "game" ? gamesLoading : questsLoading}
        />
      )}
    </div>
  );
};

export default MassPlatform;
