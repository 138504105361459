import React from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import FloatAround from './animations/FloatAround';
import useOnScreen from '../useOnScreen';
import './BrandServiceSlides.scss';
import { standardRezList } from '../assetsV2/logoIndex';
import { useTranslation } from "react-i18next";


// Little helpers ...

const BPartnerShips = (props) => {
  const { t } = useTranslation("brandServices");
  const {
    pOffset, isMobile = false, layoutFactor = 1, offsetAdjust = 0,
  } = props;
  const ref = React.createRef("brandServices");
  const breakPoint = window.innerWidth < 700;
  const medBreakPoint = window.innerWidth < 1025 && window.innerWidth >= 700;
  const onScreen = useOnScreen(ref);
  const largeTop = ['75%', '45%', '15%', '15%', '45%', '75%'];
  const largeLeft = ['10%', '5%', '10%', '85%', '90%', '85%'];
  const medTop = ['20%', '15%', '20%', '70%', '75%', '70%'];
  const medLeft = ['15%', '45%', '75%', '15%', '45%', '75%'];
  const smallTop = ['25%', '20%', '25%', '72%', '77%', '72%'];
  const smallLeft = ['12%', '42%', '72%', '12%', '42%', '72%'];
  const topOffsets = breakPoint ? smallTop : medBreakPoint ? medTop : largeTop;
  const leftOffsets = breakPoint ? smallLeft : medBreakPoint ? medLeft : largeLeft;


  return (
    <>
      {standardRezList.map((logo, i) => (
        <ParallaxLayer
          offset={pOffset + offsetAdjust}
          key={i}
          factor={layoutFactor}
          speed={0.3}
          style={{ position: "absolute" }}
        >
          <FloatAround
            isMobile={isMobile}
            startLeft={leftOffsets[i]}
            startTop={topOffsets[i]}
            image={logo}
            cssClass={`brand-float-around-${i}`}
            onScreen={onScreen}
            zLevel={i}
          />
        </ParallaxLayer>
      ))}
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        speed={0.3}
        className="brand-service-slide-a-text"
      >
        <div ref={ref} style={{ maxWidth: "70%" }} />

        <div className="brandVision" ref={ref} style={{ maxWidth: "80%" }}>
          {t("brandVision")}
        </div>
      </ParallaxLayer>
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        speed={0.3}
      >
        <div ref={ref} style={{ maxWidth: "70%" }} />
      </ParallaxLayer>
    </>
  );
};


export default BPartnerShips;

