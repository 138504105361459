/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
import './AboutUs.scss';
import IconParticles from '../icon-particles/IconParticles';
import brandList from '../brand-service/assetsV2/brandsIndex';
import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <section className="AboutUs">
      <section className="AboutUs__container">
        <section className="Hero">
          <IconParticles />
          <section className="Hero__content">
            <h2 className="Hero__title">{t("aboutUsPage.heroTitle")}</h2>
          </section>
        </section>
        <section className="Intro">
          <section className="Intro__content">
            <p className="Intro__content--details">
              {t("aboutUsPage.introContentDetails.row_1")}
              <br />
              <br />
              {t("aboutUsPage.introContentDetails.row_2")}
              <br />
              <br />
              {t("aboutUsPage.introContentDetails.row_3")}
            </p>
          </section>
        </section>
        <section className="Brands">
          <section className="Brands__content">
            <h3 className="Brands__content--title">
              {t("brandsContentTitle")}
            </h3>
            <section className="Brands__logos-wrapper">
              {brandList.map((logo, i) => (
                <section className="Brands__logos-wrapper--logo" key={i}>
                  <img alt="brand logo" src={logo} />
                </section>
              ))}
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}

export default AboutUs;