import React from "react";
import classNames from "classnames";
import { filter, map, find, forEach, findIndex } from "lodash";
import { Link } from "react-router";
import Countdown from "react-countdown-now";
import { withTranslation } from "react-i18next";

import {
  removeCommission,
  getQuestRequirements,
  paymentVendorProcessingFee,
  rounding,
  dynamicSort,
} from "../../utils/functions";
import { dayjs, localizeIncorrectDate } from "../../utils/dateTime";

import ArbitrationStatusStreamer from "./ArbitrationStatusStreamer";
import Loading from "../../global/components/loading/Loading";
import no_quests from "../../images/my-quests-page/noquests.png";

import "./MyQuestsPage.scss";

class MyQuestsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      tab: "active",
      expandedStatus: "",
      expandedIndex: "",
      referralBonuses: [],
    };
  }

  componentDidMount() {
    const { token } = this.props.auth;
    this.props.getUserTodayActivity(token);
    this.props.getUserReferralsActivity(token);
    this.props.getMyQuestsPageData(token);
  }

  changeTab = (param) => {
    this.setState({
      tab: param,
    });
  };

  addZeroes = (param) => {
    const num = param.toString();
    let value = Number(num);
    const res = num.split(".");
    if (res.length === 1 || res[1].length < 3) {
      value = value.toFixed(2);
    }
    return value;
  };

  padWithZeroes = (number, length) => {
    let myString = number.toString();
    while (myString.length < length) {
      myString = `0${myString}`;
    }
    return myString;
  };

  expandToggle(status, index) {
    this.setState({
      expandedStatus: status,
      expandedIndex: index,
    });
  }

  generateActiveQuests(
    todayActivity,
    timedActivity,
    referralsActivity,
    myQuests,
    myUserQuests,
    tieredActivity,
    tieredMultipleDaysActivity,
    paymentStatusList
  ) {
    const today = dayjs();

    // START - Adding general quests
    const myGeneralQuests = filter(
      myQuests,
      (o) =>
        o.type === "default" && localizeIncorrectDate(o.endDateTime) >= today
    );
    const reportGeneralActive = [];
    for (let i = 0; i < myGeneralQuests.length; i += 1) {
      reportGeneralActive.push({
        _id: `${i}-reportGeneralActive`,
        AVGViewers: 0,
        timePlaying: 0,
        quest: myGeneralQuests[i],
        game: myGeneralQuests[i].game,
      });
    }
    // END - Adding general quests

    // START - Adding affiliate quests
    const myAffiliateQuests = filter(
      myQuests,
      (o) =>
        o.type === "affiliate" && localizeIncorrectDate(o.endDateTime) >= today
    );
    const reportAffiliateActive = [];
    forEach(myAffiliateQuests, (quest, questIndex) => {
      let affiliateQuestCompleted = true;
      const contentLinks = [];
      forEach(quest.steps, (step) => {
        if (
          step.type === "instagram" ||
          step.type === "youtube" ||
          step.type === "facebook" ||
          step.type === "twitter"
        ) {
          const userQuest = find(myUserQuests, { quest: quest.id });
          const item = find(userQuest.affiliateLinks, {
            title: step.title,
            type: step.type,
          });
          contentLinks.push({
            title: step.title,
            content: item ? item.content : "- - -",
            link: item ? item.link : "- - -",
            status: item ? item.status : "- - -",
          });
          if (!item || (item && item.status !== "completed")) {
            affiliateQuestCompleted = false;
          }
        }
      });
      reportAffiliateActive.push({
        _id: `${questIndex}-reportAffiliateActive`,
        quest,
        game: quest.game,
        contentLinks,
        completed: affiliateQuestCompleted,
      });
    });
    // END - Adding affiliate quests

    // START - Adding timedQuests without report
    let myTimedQuests = filter(
      myQuests,
      (o) => o.type === "timed" && localizeIncorrectDate(o.endDateTime) >= today
    );
    const removeItem = map(timedActivity, "quest._id");
    myTimedQuests = myTimedQuests.filter(
      (item) => removeItem.indexOf(item.id) === -1
    );
    const noReportTimedActive = [];
    for (let i = 0; i < myTimedQuests.length; i += 1) {
      noReportTimedActive.push({
        _id: `${i}noReportTimedPast`,
        AVGViewers: 0,
        timePlaying: 0,
        quest: myTimedQuests[i],
        game: myTimedQuests[i].game,
      });
    }
    // END - Adding timedQuests without report

    // START - Adding tieredOneTimeQuests
    let myTieredOneTimeQuests = filter(
      myQuests,
      (o) =>
        o.type === "tiered-one-time" &&
        localizeIncorrectDate(o.endDateTime) >= today
    );
    const removeTieredOneTimeItem = map(timedActivity, "quest._id");
    myTieredOneTimeQuests = myTieredOneTimeQuests.filter(
      (item) => removeTieredOneTimeItem.indexOf(item.id) === -1
    );
    const noReportTieredOneTimeActive = [];
    for (let i = 0; i < myTieredOneTimeQuests.length; i += 1) {
      noReportTieredOneTimeActive.push({
        _id: `${i}noReportTieredOneTimePast`,
        AVGViewers: 0,
        timePlaying: 0,
        quest: myTieredOneTimeQuests[i],
        game: myTieredOneTimeQuests[i].game,
      });
    }
    // END - Adding tieredOneTimeQuests

    const endDateTime = (o) => localizeIncorrectDate(o.quest.endDateTime);
    const referralsActive = filter(
      referralsActivity,
      (o) => endDateTime(o) >= today
    );
    const todaysActive = filter(todayActivity, (o) => endDateTime(o) >= today);
    const timedActive = filter(
      timedActivity,
      (o) =>
        endDateTime(o) >= today &&
        (o.quest.type === "timed" || o.quest.type === "tiered-one-time")
    );
    const tieredActive = filter(tieredActivity, (o) => endDateTime(o) >= today);
    const tieredMultipleDaysActive = filter(
      tieredMultipleDaysActivity,
      (o) => endDateTime(o) >= today
    );

    const quests = [
      ...referralsActive,
      ...todaysActive,
      ...timedActive,
      ...noReportTimedActive,
      ...reportGeneralActive,
      ...reportAffiliateActive,
      ...tieredActive,
      ...tieredMultipleDaysActive,
      ...noReportTieredOneTimeActive,
    ].sort(dynamicSort("createdAt", "desc"));

    if (!!quests?.length) {
      return this.generateQuest(quests, "active", paymentStatusList);
    }

    const { t } = this.props;

    return (
      <div className="MyQuestsPage__NoQuests">
        <div className="MyQuestsPage__NoQuests__image">
          <img src={no_quests} alt="No Quests" />
        </div>
        <div className="MyQuestsPage__NoQuests__title">
          {t("noQuestsTitle")}
        </div>
        <div className="MyQuestsPage__NoQuests__message">
          {t("noQuestsMessage")}
        </div>
        <div className="MyQuestsPage__NoQuests__action">
          <Link to="/quests" href="/quests">
            {t("noQuestsAction")}
          </Link>
        </div>
      </div>
    );
  }

  generatePastQuests(
    todayActivity,
    timedActivity,
    referralsActivity,
    myQuests,
    myUserQuests,
    tieredActivity,
    tieredMultipleDaysActivity,
    paymentStatusList
  ) {
    const today = dayjs();
    const endDateTime = (o) => localizeIncorrectDate(o.endDateTime);

    // START - Adding general quests
    const myGeneralQuests = filter(
      myQuests,
      (o) => o.type === "default" && endDateTime(o) < today
    );
    const reportGeneralPast = [];
    for (let i = 0; i < myGeneralQuests.length; i += 1) {
      reportGeneralPast.push({
        _id: `${i}-reportGeneralPast`,
        AVGViewers: 0,
        timePlaying: 0,
        quest: myGeneralQuests[i],
        game: myGeneralQuests[i].game,
      });
    }
    // END - Adding general quests

    // START - Adding affiliate quests
    const myAffiliateQuests = filter(
      myQuests,
      (o) => o.type === "affiliate" && endDateTime(o) < today
    );
    const reportAffiliatePast = [];
    forEach(myAffiliateQuests, (quest, questIndex) => {
      let affiliateQuestCompleted = true;
      const contentLinks = [];
      forEach(quest.steps, (step) => {
        if (
          step.type === "instagram" ||
          step.type === "youtube" ||
          step.type === "facebook" ||
          step.type === "twitter"
        ) {
          const userQuest = find(myUserQuests, { quest: quest.id });
          const item = find(userQuest.affiliateLinks, {
            title: step.title,
            type: step.type,
          });
          contentLinks.push({
            title: step.title,
            content: item ? item.content : "- - -",
            link: item ? item.link : "- - -",
            status: item ? item.status : "- - -",
          });
          if (!item || (item && item.status !== "completed")) {
            affiliateQuestCompleted = false;
          }
        }
      });
      reportAffiliatePast.push({
        _id: `${questIndex}-reportAffiliatePast`,
        quest,
        game: quest.game,
        contentLinks,
        completed: affiliateQuestCompleted,
      });
    });
    // END - Adding affiliate quests

    // START - Adding timedQuests without report
    let myTimedQuests = filter(
      myQuests,
      (o) => o.type === "timed" && endDateTime(o) < today
    );
    const removeItem = map(timedActivity, "quest._id");
    myTimedQuests = myTimedQuests.filter(
      (item) => removeItem.indexOf(item.id) === -1
    );
    const noReportTimedPast = [];
    for (let i = 0; i < myTimedQuests.length; i += 1) {
      noReportTimedPast.push({
        _id: `${i}noReportTimedPast`,
        AVGViewers: 0,
        timePlaying: 0,
        quest: myTimedQuests[i],
        game: myTimedQuests[i].game,
      });
    }
    // END - Adding timedQuests without report

    const referralsPast = filter(
      referralsActivity,
      (o) => endDateTime(o.quest) < today
    );
    const todaysPast = filter(
      todayActivity,
      (o) => endDateTime(o.quest) < today
    );
    const timedPast = filter(
      timedActivity,
      (o) => endDateTime(o.quest) && o.quest.type === "timed"
    );
    const tieredPast = filter(
      tieredActivity,
      (o) => endDateTime(o.quest) < today
    );
    const tieredMultipleDaysPast = filter(
      tieredMultipleDaysActivity,
      (o) => endDateTime(o.quest) < today
    );

    const quests = [
      ...referralsPast,
      ...todaysPast,
      ...timedPast,
      ...noReportTimedPast,
      ...reportGeneralPast,
      ...reportAffiliatePast,
      ...tieredPast,
      ...tieredMultipleDaysPast,
    ].sort(dynamicSort("createdAt", "desc"));

    if (!!quests?.length) {
      return this.generateQuest(quests, "past", paymentStatusList);
    }

    const { t } = this.props;
    return (
      <div className="MyQuestsPage__NoQuests">
        <div className="MyQuestsPage__NoQuests__image">
          <img src={no_quests} alt="No Quests" />
        </div>
        <div className="MyQuestsPage__NoQuests__title">
          {t("noQuestsTitle")}
        </div>
        <div className="MyQuestsPage__NoQuests__message">
          {t("noQuestsMessage")}
        </div>
        <div className="MyQuestsPage__NoQuests__action">
          <Link to="/quests" href="/quests">
            {t("noQuestsAction")}
          </Link>
        </div>
      </div>
    );
  }

  openQuest(id) {
    window.location = `/my-quests/${id}`;
  }

  generateQuest(quests, status, paymentStatusList = []) {
    const { t } = this.props;
    const today = dayjs();
    const { data: userQuests } = this.props.user.myUserQuests;
    if (quests.length > 0) {
      return quests.map((activity, index) => {
        let dateInterval;
        const uq = userQuests.find(
          (uq) => uq.quest === (activity.quest.id || activity.quest._id)
        );
        // start new payment fetch
        const paymentSummary = paymentStatusList.find(
          (p) => p.quest === (activity.quest.id || activity.quest._id)
        );
        const {
          isEarned,
          payment: paymentObject = {},
          isPaid,
        } = paymentSummary || {};
        const {
          processingFee: PF = 0,
          bonus = 0,
          totalPayment = 0,
        } = paymentObject;
        const dynamicPaymentNumber = rounding(
          isPaid || isEarned ? totalPayment : 0
        );
        const dynamicProcessingFee = rounding(PF);
        const dynamicPaymentOffer = rounding(totalPayment);
        const dynamicCompletedQuest = isPaid || isEarned;

        const arbitration = uq?.arbitration;
        const arbitrationCheck =
          uq?.arbitration?.ruledInFavorOf !== "streamer" &&
          uq?.arbitration?.fundingId;

        if (activity.quest.startDateTime && activity.quest.endDateTime) {
          const questStart = activity.quest.startDateTime
            .substring(5, 10)
            .replace("-", "/");
          const questEnd = activity.quest.endDateTime
            .substring(5, 10)
            .replace("-", "/");
          dateInterval = `${questStart} - ${questEnd}`;
        }

        const startDateTime = localizeIncorrectDate(
          activity.quest.startDateTime
        );
        const notYetStarted = startDateTime > today;
        const untilDateTime = localizeIncorrectDate(
          activity.quest.endDateTime
        ).toDate();

        if (activity.purchases) {
          const bonusObj = find(
            this.state.referralBonuses,
            (o) => o.id === activity.questId
          );
          let totalEarnedQuest =
            (activity.totalEarnings -
              paymentVendorProcessingFee(activity.totalEarnings)) /
            100;
          if (bonusObj && bonusObj.bonus > 0) {
            totalEarnedQuest += removeCommission(bonusObj.bonus);
          }

          let processingFee = "";
          if (bonusObj && bonusObj.bonus > 0) {
            processingFee =
              paymentVendorProcessingFee(activity.totalEarnings) / 100 +
              paymentVendorProcessingFee(bonusObj.bonus);
          } else {
            processingFee =
              paymentVendorProcessingFee(activity.totalEarnings) / 100;
          }
          return (
            <div className="MyQuestPage__quest MyQuestPage__quest__referral">
              <div className="MyQuestPage__quest__expand">
                <span
                  onClick={this.expandToggle.bind(this, status, index)}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "none"
                        : "block",
                  }}
                >
                  <i className="fa fa-plus-square-o" />
                </span>
                <span
                  onClick={this.expandToggle.bind(this, "", "")}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "block"
                        : "none",
                  }}
                >
                  <i className="fa fa-minus-square-o" />
                </span>
              </div>
              <div
                className="MyQuestPage__quest__referral__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1000ms ease all"
                      : "none",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1"
                      : "0",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "visible"
                      : "hidden",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "auto"
                      : "0",
                }}
              >
                <div className="MyQuestPage__quest__first">
                  <Link
                    to={
                      status === "active"
                        ? `/my-quests/${
                            activity.quest?.id || activity.quest?._id
                          }`
                        : ""
                    }
                  >
                    <div
                      className="MyQuestPage__quest__first_image"
                      style={{
                        backgroundImage: `url(${activity?.game?.cover})`,
                        cursor: "pointer",
                      }}
                    >
                      <div className="MyQuestPage__cover__corner__1" />
                      <div className="MyQuestPage__cover__corner__2" />
                    </div>
                  </Link>
                </div>
                <div className="MyQuestPage__quest__second">
                  <div className="MyQuestPage__quest__top">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest__topLeft"
                      >
                        {activity?.quest?.title}
                        <div className="MyQuestPage_quest_topLeft-headline">
                          {activity?.game?.name} <br />
                        </div>
                        <div className="MyQuestPage_quest_topLeft-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              {t("expectedPayoutDate")}:{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest__topRight">
                      <span
                        onClick={this.expandToggle.bind(this, "", "")}
                        aria-hidden
                      >
                        {t("expandDetails")}&nbsp;&nbsp;
                        <i className="fa fa-minus-square-o" />
                      </span>
                    </div>
                  </div>
                  <div className="MyQuestPage__quest__bottom">
                    <div className="MyQuestPage__quest__bottomLeft">
                      <div className="MyQuestPage__quest__headRow">
                        <div className="MyQuestPage__quest__headRow-1">
                          {t("date")}
                        </div>
                        <div className="MyQuestPage__quest__headRow-2">
                          {t("earned")}
                        </div>
                        <div className="MyQuestPage__quest__headRow-3" />
                      </div>
                      {activity.purchases.map((act) => (
                        <div className="MyQuestPage__quest__row">
                          <div className="MyQuestPage__quest__row-1">
                            {`${act.month}/${act.dayOfMonth}`}
                          </div>
                          <div className="MyQuestPage__quest__row-2">
                            {/* ${rounding((act.earning - (paymentVendorProcessingFee(act.earning))) / 100)} */}
                            {dynamicPaymentOffer}
                          </div>
                          <div className="MyQuestPage__quest__row-3">
                            <i
                              className="fa fa-check-square-o"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      ))}
                      {bonusObj && bonusObj.bonus > 0 && (
                        <div className="MyQuestPage__quest__row">
                          <div className="MyQuestPage__quest__row-1">
                            {/* Bonus Earned: ${removeCommissionRounding(bonusObj.bonus)} */}
                            {t("bonusEarned")}: ${bonus}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="MyQuestPage__quest__bottomRight">
                      <div className="MyQuestPage__quest__bottomRight__wrapper">
                        <div className="MyQuestPage__quest__bottomRight--totalEarned">
                          <div className="MyQuestPage__quest__bottomRight--first">
                            <div className="MyQuestPage__quest__bottomRight--firstTop">
                              <i className="fa fa-usd" aria-hidden="true" />
                            </div>
                            <div className="MyQuestPage__quest__bottomRight--firstBottom">
                              {t("totalEarned")}
                            </div>
                          </div>
                          <div className="MyQuestPage__quest__bottomRight--second">
                            ${dynamicPaymentNumber}
                          </div>
                        </div>
                        <div className="MyQuestPage__quest__bottomRight--details">
                          <div className="MyQuestPage__quest__bottomRight--first">
                            <div className="MyQuestPage__quest__bottomRight--firstTop">
                              <i className="fa fa-link" aria-hidden="true" />
                            </div>
                            <div className="MyQuestPage__quest__bottomRight--firstBottom">
                              {t("successfulReferrals")}
                            </div>
                          </div>
                          <div className="MyQuestPage__quest__bottomRight--second">
                            {activity.numberOfReferrals}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="MyQuestPage__quest2__referral__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "none"
                      : "1000ms ease all",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "1",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "hidden"
                      : "visible",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "auto",
                }}
              >
                <div className="MyQuestPage__quest2__first">
                  <div
                    className="MyQuestPage__quest2__first_image"
                    style={{ backgroundImage: `url(${activity?.game?.logo})` }}
                  />
                </div>
                <div className="MyQuestPage__quest2__second">
                  <div className="MyQuestPage__quest2__">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest2__Left"
                      >
                        {activity?.quest?.title}
                        <div className="MyQuestPage_quest2_Left-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              {t("expectedPayoutDate")}:{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest2__Right">
                      <div className="MyQuestPage__quest2__RightTop">
                        <span
                          onClick={this.expandToggle.bind(this, status, index)}
                          aria-hidden
                        >
                          {t("expandDetails")}&nbsp;&nbsp;
                          <i className="fa fa-plus-square-o" />
                        </span>
                      </div>
                      <div className="MyQuestPage__quest2__RightBottom">
                        ${rounding(totalEarnedQuest)}
                        {/* earnings for game sales */}
                        {activity.quest.paymentMade &&
                          activity.quest.paymentAmount && (
                            <span className="MyQuestPage__quest__paymentMade">
                              &nbsp;{t("paid")}
                            </span>
                          )}
                        {activity.totalEarnings > 0 && (
                          <React.Fragment>
                            <br />
                            <span className="MyQuestPage__quest_processingFee">
                              {t("processingFee")}: ${rounding(processingFee)}
                            </span>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else if (activity?.quest?.type === "paid bounty") {
          return (
            <div className="MyQuestPage__quest MyQuestPage__quest__paidBounty">
              <div className="MyQuestPage__quest__expand">
                <span
                  onClick={this.expandToggle.bind(this, status, index)}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "none"
                        : "block",
                  }}
                >
                  <i className="fa fa-plus-square-o" />
                </span>
                <span
                  onClick={this.expandToggle.bind(this, "", "")}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "block"
                        : "none",
                  }}
                >
                  <i className="fa fa-minus-square-o" />
                </span>
              </div>
              <div
                className="MyQuestPage__quest__paidBounty__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1000ms ease all"
                      : "none",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1"
                      : "0",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "visible"
                      : "hidden",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "auto"
                      : "0",
                }}
              >
                <div className="MyQuestPage__quest__first">
                  <div
                    className="MyQuestPage__quest__first_image"
                    style={{
                      backgroundImage: `url(${activity.quest.game.cover})`,
                    }}
                  >
                    <div className="MyQuestPage__cover__corner__1" />
                    <div className="MyQuestPage__cover__corner__2" />
                  </div>
                </div>
                <div className="MyQuestPage__quest__second">
                  <div className="MyQuestPage__quest__top">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest__topLeft"
                      >
                        {activity?.quest?.title}
                        <div className="MyQuestPage_quest_topLeft-headline">
                          {activity.quest.game.name} <br />
                        </div>
                        <div className="MyQuestPage_quest_topLeft-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              {t("expectedPayoutDate")}:{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest__topRight" aria-hidden>
                      <span
                        onClick={this.expandToggle.bind(this, "", "")}
                        aria-hidden
                      >
                        {t("expandDetails")}&nbsp;&nbsp;
                        <i className="fa fa-minus-square-o" />
                      </span>
                    </div>
                  </div>
                  {notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                          {t("questNotStarted")}
                        </div>
                      </div>
                    </div>
                  )}
                  {!notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                          <div className="MyQuestPage__quest__headRow-1">
                            {t("date")}
                          </div>
                          <div className="MyQuestPage__quest__headRow-2">
                            {t("streamTime")}
                          </div>
                          <div className="MyQuestPage__quest__headRow-3">
                            {t("earned")}
                          </div>
                          <div className="MyQuestPage__quest__headRow-4" />
                        </div>
                        {activity.activity &&
                          activity.activity.map((activity2) => {
                            const activityClass =
                              activity.quest.requiredStreamTimeMinutes &&
                              activity2.timePlaying >=
                                activity.quest.requiredStreamTimeMinutes
                                ? "success"
                                : "fail";
                            return (
                              <div
                                className={`MyQuestPage__quest__row ${activityClass}`}
                              >
                                <div className="MyQuestPage__quest__row-1">
                                  {`${activity2.month}/${activity2.day}`}
                                </div>
                                <div className="MyQuestPage__quest__row-2">
                                  {activity2.timePlaying} min
                                </div>
                                <div className="MyQuestPage__quest__row-3">
                                  {activityClass === "success" && (
                                    <React.Fragment>
                                      $
                                      {rounding(
                                        activity.quest.dailyEarning -
                                          paymentVendorProcessingFee(
                                            activity.quest.dailyEarning
                                          )
                                      )}
                                    </React.Fragment>
                                  )}
                                  {activityClass === "fail" && (
                                    <React.Fragment>$0.00</React.Fragment>
                                  )}
                                </div>
                                <div className="MyQuestPage__quest__row-4">
                                  <i
                                    className="fa fa-check-square-o"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="MyQuestPage__quest__bottomRight">
                        <div className="MyQuestPage__quest__bottomRight__wrapper">
                          <div className="MyQuestPage__quest__bottomRight--totalEarned">
                            <div className="MyQuestPage__quest__bottomRight--first">
                              <div className="MyQuestPage__quest__bottomRight--firstTop">
                                <i className="fa fa-usd" aria-hidden="true" />
                              </div>
                              <div className="MyQuestPage__quest__bottomRight--firstBottom">
                                {t("totalEarned")}
                              </div>
                            </div>
                            <div className="MyQuestPage__quest__bottomRight--second">
                              $
                              {rounding(
                                activity.totalEarnings -
                                  paymentVendorProcessingFee(
                                    activity.totalEarnings
                                  )
                              )}
                            </div>
                          </div>
                          <div className="MyQuestPage__quest__bottomRight--details">
                            <div className="MyQuestPage__quest__bottomRight--first">
                              <div className="MyQuestPage__quest__bottomRight--firstTop">
                                <i
                                  className="fa fa-calendar-o"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="MyQuestPage__quest__bottomRight--firstBottom">
                                {t("completedDays")}
                              </div>
                            </div>
                            <div className="MyQuestPage__quest__bottomRight--second">
                              {activity.totalEarnings /
                                activity.quest.dailyEarning}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="MyQuestPage__quest2__paidBounty__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "none"
                      : "1000ms ease all",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "1",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "hidden"
                      : "visible",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "auto",
                }}
              >
                <div className="MyQuestPage__quest2__first">
                  <div
                    className="MyQuestPage__quest2__first_image"
                    style={{
                      backgroundImage: `url(${activity.quest.game.logo})`,
                    }}
                  />
                </div>
                <div className="MyQuestPage__quest2__second">
                  <div className="MyQuestPage__quest2__">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest2__Left"
                      >
                        {activity?.quest?.title}
                        <div className="MyQuestPage_quest2_Left-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              t("expectedPayoutDate"):{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest2__Right" aria-hidden>
                      <div className="MyQuestPage__quest2__RightTop">
                        <span
                          onClick={this.expandToggle.bind(this, status, index)}
                          aria-hidden
                        >
                          {t("expandDetails")}&nbsp;&nbsp;
                          <i className="fa fa-plus-square-o" />
                        </span>
                      </div>
                      <div className="MyQuestPage__quest2__RightBottom">
                        {/* ${rounding(activity.totalEarnings - (paymentVendorProcessingFee(activity.totalEarnings)))} */}
                        ${dynamicPaymentNumber}
                        {activity.quest.paymentMade &&
                          activity.quest.paymentAmount && (
                            <span className="MyQuestPage__quest__paymentMade">
                              &nbsp;{t("paid")}
                            </span>
                          )}
                        {activity.totalEarnings > 0 && (
                          <React.Fragment>
                            <br />
                            <span className="MyQuestPage__quest_processingFee">
                              {/* Processing Fee: ${rounding(paymentVendorProcessingFee(activity.totalEarnings))} */}
                              {t("processingFee")}: ${dynamicProcessingFee}
                            </span>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else if (activity?.quest?.type === "timed") {
          return (
            <div className="MyQuestPage__quest MyQuestPage__quest__timed">
              <div className="MyQuestPage__quest__expand">
                <span
                  onClick={this.expandToggle.bind(this, status, index)}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "none"
                        : "block",
                  }}
                >
                  <i className="fa fa-plus-square-o" />
                </span>
                <span
                  onClick={this.expandToggle.bind(this, "", "")}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "block"
                        : "none",
                  }}
                >
                  <i className="fa fa-minus-square-o" />
                </span>
              </div>
              <div
                className="MyQuestPage__quest__timed__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1000ms ease all"
                      : "none",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1"
                      : "0",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "visible"
                      : "hidden",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "auto"
                      : "0",
                }}
              >
                <div className="MyQuestPage__quest__first">
                  <div
                    className="MyQuestPage__quest__first_image"
                    style={{ backgroundImage: `url(${activity?.game?.cover})` }}
                  >
                    <div className="MyQuestPage__cover__corner__1" />
                    <div className="MyQuestPage__cover__corner__2" />
                  </div>
                </div>
                <div className="MyQuestPage__quest__second">
                  <div className="MyQuestPage__quest__top">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest__topLeft"
                      >
                        {activity?.quest?.title}
                        <div className="MyQuestPage_quest_topLeft-headline">
                          {activity?.game?.name} <br />
                        </div>
                        <div className="MyQuestPage_quest_topLeft-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              t("expectedPayoutDate"):{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest__topRight" aria-hidden>
                      <span
                        onClick={this.expandToggle.bind(this, "", "")}
                        aria-hidden
                      >
                        {t("expandDetails")}&nbsp;&nbsp;
                        <i className="fa fa-minus-square-o" />
                      </span>
                    </div>
                  </div>
                  {notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                          {t("questNotStarted")}
                        </div>
                      </div>
                    </div>
                  )}
                  {!notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                          <div className="MyQuestPage__quest__headRow-1">
                            {t("streamTime")}
                          </div>
                        </div>
                        <div className="MyQuestPage__quest__row">
                          {activity.quest.id === "5c70828319ce42510deb34cd" && (
                            <section className="MyQuestPage__quest__row-1">
                              <div
                                className="Quest_no-live-reporting"
                                style={{ color: "#F44336" }}
                              >
                                {t("liveReporting")} <br />
                                {t("verifyParticipation")} <br />
                                {t("pleaseWatch")}{" "}
                                <a
                                  style={{ color: "#83858c" }}
                                  rel="nofollow noopener noreferrer"
                                  href="https://discordapp.com/invite/N2PrJxK"
                                >
                                  {t("discord")}
                                </a>{" "}
                               {t("forUpdates")}
                              </div>
                            </section>
                          )}
                          {activity.quest.id !== "5c70828319ce42510deb34cd" && (
                            <div className="MyQuestPage__quest__row-1">
                              {activity.timePlaying} {t("minutesStreamedOut ")}{" "}
                              {activity.quest.requiredStreamTimeMinutes} {t("minutes")}
                            </div>
                          )}
                        </div>
                        <div className="MyQuestPage__quest__row">
                          <div className="MyQuestPage__quest__row-1">
                            {activity.timePlaying >=
                              parseInt(
                                activity.quest.requiredStreamTimeMinutes,
                                10
                              ) && (
                              <React.Fragment>
                                <span className="quest-status completed">
                                  {t("completed")}
                                </span>
                              </React.Fragment>
                            )}
                            {activity.timePlaying <
                              parseInt(
                                activity.quest.requiredStreamTimeMinutes,
                                10
                              ) && (
                              <Countdown
                                date={untilDateTime}
                                daysInHours
                                renderer={({
                                  hours,
                                  minutes,
                                  seconds,
                                  completed,
                                }) => {
                                  if (completed) {
                                    return (
                                      <React.Fragment>
                                        {activity.quest.id !==
                                          "5c70828319ce42510deb34cd" && (
                                          <span className="quest-status failed">
                                            {t("failed")}
                                          </span>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                  return (
                                    <section>
                                      {hours}:{minutes}:{seconds} {t("leftToStream")}
                                    </section>
                                  );
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="MyQuestPage__quest__bottomRight">
                        <div className="MyQuestPage__quest__bottomRight__wrapper">
                          <div className="MyQuestPage__quest__bottomRight--totalEarned">
                            <div className="MyQuestPage__quest__bottomRight--first">
                              <div className="MyQuestPage__quest__bottomRight--firstTop">
                                <i className="fa fa-usd" aria-hidden="true" />
                              </div>
                              <div className="MyQuestPage__quest__bottomRight--firstBottom">
                               {t("totalEarned")}
                              </div>
                            </div>
                            <div className="MyQuestPage__quest__bottomRight--second">
                              {/* {(activity.timePlaying >= parseInt(activity.quest.requiredStreamTimeMinutes, 10)) && <React.Fragment>${rounding(activity.quest.paymentAmount - (paymentVendorProcessingFee(activity.quest.paymentAmount)))}</React.Fragment>}
                              {(activity.timePlaying < parseInt(activity.quest.requiredStreamTimeMinutes, 10)) && <React.Fragment>$0.00</React.Fragment>} */}
                              {dynamicPaymentNumber}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="MyQuestPage__quest2__timed__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "none"
                      : "1000ms ease all",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "1",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "hidden"
                      : "visible",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "auto",
                }}
              >
                <div className="MyQuestPage__quest2__first">
                  <div
                    className="MyQuestPage__quest2__first_image"
                    style={{ backgroundImage: `url(${activity?.game?.logo})` }}
                  />
                </div>
                <div className="MyQuestPage__quest2__second">
                  <div className="MyQuestPage__quest2__">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest2__Left"
                      >
                        {activity?.quest?.title}
                        <div className="MyQuestPage_quest2_Left-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              {t("expectedPayoutDate")}:{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest2__Right" aria-hidden>
                      <div className="MyQuestPage__quest2__RightTop">
                        <span
                          onClick={this.expandToggle.bind(this, status, index)}
                          aria-hidden
                        >
                          {t("expandDetails")}&nbsp;&nbsp;
                          <i className="fa fa-plus-square-o" />
                        </span>
                      </div>
                      <div className="MyQuestPage__quest2__RightBottom">
                        {/* {(activity.timePlaying >= parseInt(activity.quest.requiredStreamTimeMinutes, 10)) && <React.Fragment>${rounding(activity.quest.paymentAmount - (paymentVendorProcessingFee(activity.quest.paymentAmount)))}</React.Fragment>}
                        {(activity.timePlaying < parseInt(activity.quest.requiredStreamTimeMinutes, 10)) && <React.Fragment>$0.00</React.Fragment>} */}
                        {dynamicPaymentNumber}
                        {activity.quest.paymentMade &&
                          !!activity.quest.paymentAmount && (
                            <span className="MyQuestPage__quest__paymentMade">
                              &nbsp;{t("paid")}
                            </span>
                          )}
                        {/* {(activity.timePlaying >= parseInt(activity.quest.requiredStreamTimeMinutes, 10)) &&
                          <React.Fragment>
                            <br />
                            <span className="MyQuestPage__quest_processingFee">
                              Processing Fee: ${rounding(paymentVendorProcessingFee(activity.quest.paymentAmount))}
                            </span>
                          </React.Fragment>
                        } */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else if (
          activity.quest &&
          activity?.quest?.type &&
          activity?.quest?.type === "default"
        ) {
          return (
            <div className="MyQuestPage__quest MyQuestPage__quest__paidBounty">
              <div className="MyQuestPage__quest__expand">
                <span
                  onClick={this.expandToggle.bind(this, status, index)}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "none"
                        : "block",
                  }}
                >
                  <i className="fa fa-plus-square-o" />
                </span>
                <span
                  onClick={this.expandToggle.bind(this, "", "")}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "block"
                        : "none",
                  }}
                >
                  <i className="fa fa-minus-square-o" />
                </span>
              </div>
              <div
                className="MyQuestPage__quest__paidBounty__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1000ms ease all"
                      : "none",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1"
                      : "0",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "visible"
                      : "hidden",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "auto"
                      : "0",
                }}
              >
                <div className="MyQuestPage__quest__first">
                  <div
                    className="MyQuestPage__quest__first_image"
                    style={{
                      backgroundImage: `url(${activity.quest.game.cover})`,
                    }}
                  >
                    <div className="MyQuestPage__cover__corner__1" />
                    <div className="MyQuestPage__cover__corner__2" />
                  </div>
                </div>
                <div className="MyQuestPage__quest__second">
                  <div className="MyQuestPage__quest__top">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest__topLeft"
                      >
                        {activity?.quest?.title}
                        <div className="MyQuestPage_quest_topLeft-headline">
                          {activity.quest.game.name} <br />
                        </div>
                        <div className="MyQuestPage_quest_topLeft-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                             {t("expectedPayoutDate")}:{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest__topRight" aria-hidden>
                      <span
                        onClick={this.expandToggle.bind(this, "", "")}
                        aria-hidden
                      >
                        {t("expandDetails")}&nbsp;&nbsp;
                        <i className="fa fa-minus-square-o" />
                      </span>
                    </div>
                  </div>
                  {notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                         {t("questNotStarted")}
                        </div>
                      </div>
                    </div>
                  )}
                  {!notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        {/* <div className="MyQuestPage__quest__headRow">
                          <div className="MyQuestPage__quest__headRow-1">
                            Date
                          </div>
                          <div className="MyQuestPage__quest__headRow-2">
                            Stream Time
                          </div>
                          <div className="MyQuestPage__quest__headRow-3">
                            Earned
                          </div>
                          <div className="MyQuestPage__quest__headRow-4" />
                        </div> */}
                        {activity.activity &&
                          activity.activity.map((activity2) => {
                            const activityClass =
                              activity.quest.requiredStreamTimeMinutes &&
                              activity2.timePlaying >=
                                activity.quest.requiredStreamTimeMinutes
                                ? "success"
                                : "fail";
                            return (
                              <div
                                className={`MyQuestPage__quest__row ${activityClass}`}
                              >
                                <div className="MyQuestPage__quest__row-1">
                                  {`${activity2.month}/${activity2.day}`}
                                </div>
                                <div className="MyQuestPage__quest__row-2">
                                  {activity2.timePlaying} min
                                </div>
                                <div className="MyQuestPage__quest__row-3">
                                  {/* (activityClass === 'success') &&
                                  <React.Fragment>
                                    ${ rounding(activity.quest.dailyEarning - (paymentVendorProcessingFee(activity.quest.dailyEarning))) }
                                  </React.Fragment> }
                                { (activityClass === 'fail') &&
                                  <React.Fragment>
                                    $0.00
                                  </React.Fragment> */}
                                </div>
                                <div className="MyQuestPage__quest__row-4">
                                  <i
                                    className="fa fa-check-square-o"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="MyQuestPage__quest__bottomRight" />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="MyQuestPage__quest2__paidBounty__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "none"
                      : "1000ms ease all",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "1",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "hidden"
                      : "visible",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "auto",
                }}
              >
                <div className="MyQuestPage__quest2__first">
                  <div
                    className="MyQuestPage__quest2__first_image"
                    style={{
                      backgroundImage: `url(${activity.quest.game.logo})`,
                    }}
                  />
                </div>
                <div className="MyQuestPage__quest2__second">
                  <div className="MyQuestPage__quest2__">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest2__Left"
                      >
                        {activity?.quest?.title}
                        <div className="MyQuestPage_quest2_Left-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              {t("expectedPayoutDate")}:{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest2__Right" aria-hidden>
                      <div className="MyQuestPage__quest2__RightTop">
                        <span
                          onClick={this.expandToggle.bind(this, status, index)}
                          aria-hidden
                        >
                          {t("expandDetails")}&nbsp;&nbsp;
                          <i className="fa fa-plus-square-o" />
                        </span>
                      </div>
                      <div className="MyQuestPage__quest2__RightBottom" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else if (
          activity.quest &&
          activity?.quest?.type &&
          activity?.quest?.type === "affiliate"
        ) {
          return (
            <div className="MyQuestPage__quest MyQuestPage__quest__paidBounty">
              <div className="MyQuestPage__quest__expand">
                <span
                  onClick={this.expandToggle.bind(this, status, index)}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "none"
                        : "block",
                  }}
                >
                  <i className="fa fa-plus-square-o" />
                </span>
                <span
                  onClick={this.expandToggle.bind(this, "", "")}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "block"
                        : "none",
                  }}
                >
                  <i className="fa fa-minus-square-o" />
                </span>
              </div>
              <div
                className="MyQuestPage__quest__paidBounty__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1000ms ease all"
                      : "none",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1"
                      : "0",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "visible"
                      : "hidden",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "auto"
                      : "0",
                }}
              >
                <div className="MyQuestPage__quest__first">
                  <div
                    className="MyQuestPage__quest__first_image"
                    style={{
                      backgroundImage: `url(${activity.quest.game.cover})`,
                    }}
                  >
                    <div className="MyQuestPage__cover__corner__1" />
                    <div className="MyQuestPage__cover__corner__2" />
                  </div>
                </div>
                <div className="MyQuestPage__quest__second">
                  <div className="MyQuestPage__quest__top">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest__topLeft"
                      >
                        {activity?.quest?.title}
                        <div className="MyQuestPage_quest_topLeft-headline">
                          {activity.quest.game.name} <br />
                        </div>
                        <div className="MyQuestPage_quest_topLeft-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              {t("expectedPayoutDate")}:{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest__topRight" aria-hidden>
                      <span
                        onClick={this.expandToggle.bind(this, "", "")}
                        aria-hidden
                      >
                        {t("expandDetails")}&nbsp;&nbsp;
                        <i className="fa fa-minus-square-o" />
                      </span>
                    </div>
                  </div>
                  {notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                         {t("questNotStarted")}
                        </div>
                      </div>
                    </div>
                  )}
                  {!notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                          {t("details")}
                        </div>
                        {activity.contentLinks &&
                          activity.contentLinks.map((contentLink) => (
                            <div className="MyQuestPage__quest__row affiliate_row">
                              <div className="affiliate_row_first">
                                <div className="affiliate_row_first_left">
                                  <strong>{contentLink.title}</strong>
                                </div>
                                <div className="affiliate_row_first_right tooltip">
                                  {contentLink.status === "completed" && (
                                    <React.Fragment>
                                      <i className="fa fa-check success" />
                                      <div className="tooltiptext">
                                        {t("approved")}.
                                      </div>
                                    </React.Fragment>
                                  )}
                                  {contentLink.status ===
                                    "content-rejected" && (
                                    <React.Fragment>
                                      <i className="fa fa-times error" />
                                      <div className="tooltiptext">
                                        {t("approvedTips")}
                                      </div>
                                    </React.Fragment>
                                  )}
                                  {contentLink.status === "link-rejected" && (
                                    <React.Fragment>
                                      <i className="fa fa-times error" />
                                      <div className="tooltiptext">
                                        {t("linkRejected")}.
                                      </div>
                                    </React.Fragment>
                                  )}
                                  {contentLink.status ===
                                    "content-toReview" && (
                                    <React.Fragment>
                                      <i className="fa fa-eye warning" />
                                      <div className="tooltiptext">
                                       {t("contentUnderReview")}.
                                      </div>
                                    </React.Fragment>
                                  )}
                                  {contentLink.status === "link-toReview" && (
                                    <React.Fragment>
                                      <i className="fa fa-eye warning" />
                                      <div className="tooltiptext">
                                       {t("linkUnderReview")}.
                                      </div>
                                    </React.Fragment>
                                  )}
                                  {contentLink.status ===
                                    "content-approved" && (
                                    <React.Fragment>
                                      <i className="fa fa-clock-o warning" />
                                      <div className="tooltiptext">
                                        {t("linkTips")}.
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                              <strong>Content:</strong>{" "}
                              {contentLink.content
                                ? contentLink.content
                                : "- - -"}
                              <br />
                              <strong>{t("link")}:</strong>{" "}
                              {contentLink.link ? contentLink.link : "- - -"}
                            </div>
                          ))}
                      </div>
                      <div className="MyQuestPage__quest__bottomRight">
                        <div className="MyQuestPage__quest__bottomRight__wrapper">
                          <div className="MyQuestPage__quest__bottomRight--details">
                            <div className="MyQuestPage__quest__bottomRight--first">
                              <div className="MyQuestPage__quest__bottomRight--firstTop">
                                <i
                                  className="fa fa-calendar-o"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="MyQuestPage__quest__bottomRight--firstBottom">
                               {t("completedLinks")}
                              </div>
                            </div>
                            <div className="MyQuestPage__quest__bottomRight--second">
                              {
                                filter(activity.contentLinks, {
                                  status: "completed",
                                }).length
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="MyQuestPage__quest2__paidBounty__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "none"
                      : "1000ms ease all",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "1",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "hidden"
                      : "visible",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "auto",
                }}
              >
                <div className="MyQuestPage__quest2__first">
                  <div
                    className="MyQuestPage__quest2__first_image"
                    style={{
                      backgroundImage: `url(${activity.quest.game.logo})`,
                    }}
                  />
                </div>
                <div className="MyQuestPage__quest2__second">
                  <div className="MyQuestPage__quest2__">
                    <div className="MyQuestPage__quest2__Left">
                      {activity?.quest?.title}
                      <div className="MyQuestPage_quest2_Left-date">
                        {t("questDate")}: {dateInterval}
                        {activity.quest.expectedPayoutDate && (
                          <React.Fragment>
                            <br />
                            {t("expectedPayoutDate")}:{" "}
                            {localizeIncorrectDate(
                              activity.quest.expectedPayoutDate
                            ).format("MMMM DD, YYYY")}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <div className="MyQuestPage__quest2__Right" aria-hidden>
                      <div className="MyQuestPage__quest2__RightTop">
                        <span
                          onClick={this.expandToggle.bind(this, status, index)}
                          aria-hidden
                        >
                          {t("expandDetails")}&nbsp;&nbsp;
                          <i className="fa fa-plus-square-o" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else if (activity?.quest?.type === "tiered-one-time") {
          let questEndedPlusWeek = false;
          if (activity.quest.endDateTime) {
            const endDateTimePlusWeek = localizeIncorrectDate(
              activity.quest.endDateTime
            ).add(7, "days");
            questEndedPlusWeek = endDateTimePlusWeek > today;
          }
          const timeCompleted =
            activity.timePlaying >=
            parseInt(activity.quest.requiredStreamTimeMinutes, 10);
          // let requirementsCompleted = true;
          let completedQuest = false;

          const userQuest = find(
            userQuests,
            (o) => o.quest === activity.quest.id
          );
          const questRequirements = getQuestRequirements(activity.quest, true);

          if (userQuest && questRequirements && questRequirements.length > 0) {
            // const requirementsCompletedCount = getNumApprovedRequirements(activity.quest, userQuest);
            // requirementsCompleted = requirementsCompletedCount >= questRequirements.length;
          }

          completedQuest = dynamicCompletedQuest;

          const hours2 = Math.floor(
            activity.quest.requiredStreamTimeMinutes / 60
          );
          const minutes2 = activity.quest.requiredStreamTimeMinutes % 60;
          const textMinutes = minutes2 !== 0 ? ` ${minutes2} ${t("minutesLow")}` : "";
          let textHours = "";
          const unpaidQuest = activity.quest.paymentAmount < 0;
          if (hours2 === 1) {
            textHours = `1 ${t("hour")}`;
          } else if (hours2 > 1) {
            textHours = `${hours2} ${t("hour")}s`;
          }
          let tieredMessage;
          if (activity.quest.paymentAmount === 0 && !unpaidQuest) {
            tieredMessage = ` - ${t("playFor")} ${textHours}${textMinutes}`;
          } else if (!unpaidQuest) {
            // tieredMessage = ` - Play for ${textHours}${textMinutes} and earn! - $${activity.quest.paymentAmount}`;
            tieredMessage = ` - ${t("playFor")} ${textHours}${textMinutes} ${t("andEarn")}! - $${activity.quest.paymentAmount}`;
          }

          let questStatus = <React.Fragment />;
          if (completedQuest) {
            questStatus = (
              <span className="quest-status completed">{t("completed")}</span>
            );
          } else if (questEndedPlusWeek) {
            questStatus = (
              <span className="quest-status inProgress">{t("inProgress")}</span>
            );
          } else if (!timeCompleted) {
            questStatus = (
              <Countdown
                date={untilDateTime}
                daysInHours
                renderer={({ hours, minutes, seconds, completed }) => {
                  if (completed) {
                    return <span className="quest-status failed">{t("failed")}</span>;
                  }
                  return (
                    <section>
                      {hours}:{minutes}:{seconds} {t("leftToStream")}
                    </section>
                  );
                }}
              />
            );
          } else {
            questStatus = <span className="quest-status failed">{t("failed")}</span>;
          }

          return (
            <div className="MyQuestPage__quest MyQuestPage__quest__tiered">
              <div className="MyQuestPage__quest__expand">
                <span
                  onClick={this.expandToggle.bind(this, status, index)}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "none"
                        : "block",
                  }}
                >
                  <i className="fa fa-plus-square-o" />
                </span>
                <span
                  onClick={this.expandToggle.bind(this, "", "")}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "block"
                        : "none",
                  }}
                >
                  <i className="fa fa-minus-square-o" />
                </span>
              </div>
              <div
                className="MyQuestPage__quest__timed__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1000ms ease all"
                      : "none",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1"
                      : "0",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "visible"
                      : "hidden",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "auto"
                      : "0",
                }}
              >
                <div className="MyQuestPage__quest__first">
                  <div
                    className="MyQuestPage__quest__first_image"
                    style={{
                      backgroundImage: `url(${activity.quest.game.cover})`,
                    }}
                  >
                    <div className="MyQuestPage__cover__corner__1" />
                    <div className="MyQuestPage__cover__corner__2" />
                  </div>
                </div>
                <div className="MyQuestPage__quest__second">
                  <div className="MyQuestPage__quest__top">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest__topLeft"
                      >
                        {activity?.quest?.title} {tieredMessage}
                        <div className="MyQuestPage_quest_topLeft-headline">
                          {activity.quest.game.name} <br />
                        </div>
                        <div className="MyQuestPage_quest_topLeft-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              {t("expectedPayoutDate")}:{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest__topRight" aria-hidden>
                      <span
                        onClick={this.expandToggle.bind(this, "", "")}
                        aria-hidden
                      >
                        {t("expandDetails")}&nbsp;&nbsp;
                        <i className="fa fa-minus-square-o" />
                      </span>
                    </div>
                  </div>
                  {notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                          {t("questNotStarted")}
                        </div>
                      </div>
                    </div>
                  )}
                  {!notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                          <div className="MyQuestPage__quest__headRow-1">
                            {t("streamTime")}
                          </div>
                        </div>
                        <div className="MyQuestPage__quest__row">
                          <div className="MyQuestPage__quest__row-1">
                            {activity.timePlaying || "0"} {t("minutesStreamedOut ")} {activity.quest.requiredStreamTimeMinutes}{" "}
                            {t("minutes")}
                          </div>
                        </div>
                        <div className="MyQuestPage__quest__row">
                          <div className="MyQuestPage__quest__row-1">
                            {questStatus}
                          </div>
                        </div>
                      </div>
                      <div
                        className="MyQuestPage__quest__bottomRight"
                        style={{
                          display:
                            activity.quest.paymentAmount > 0 ? "block" : "none",
                        }}
                      >
                        <div className="MyQuestPage__quest__bottomRight__wrapper">
                          <div className="MyQuestPage__quest__bottomRight--totalEarned">
                            <div className="MyQuestPage__quest__bottomRight--first">
                              <div className="MyQuestPage__quest__bottomRight--firstTop">
                                <i className="fa fa-usd" aria-hidden="true" />
                              </div>
                              <div className="MyQuestPage__quest__bottomRight--firstBottom">
                                {t("totalEarned")}
                              </div>
                            </div>
                            <div className="MyQuestPage__quest__bottomRight--second">
                              {completedQuest && (
                                <React.Fragment>
                                  $
                                  {/* {rounding(
                                    activity.quest.paymentAmount -
                                    paymentVendorProcessingFee(activity.quest.paymentAmount)
                                  )} */}
                                  {dynamicPaymentNumber}
                                </React.Fragment>
                              )}
                              {!completedQuest && (
                                <React.Fragment>$0.00</React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="MyQuestPage__quest2__timed__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "none"
                      : "1000ms ease all",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "1",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "hidden"
                      : "visible",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "auto",
                }}
              >
                {arbitrationCheck && (
                  <ArbitrationStatusStreamer
                    {...arbitration}
                    cover={activity?.game?.cover || ""}
                    quest={activity.quest}
                  />
                )}
                {!arbitrationCheck && (
                  <React.Fragment>
                    <div className="MyQuestPage__quest2__first">
                      <div
                        className="MyQuestPage__quest2__first_image"
                        style={{
                          backgroundImage: `url(${activity.quest.game.logo})`,
                        }}
                      />
                    </div>
                    <div className="MyQuestPage__quest2__second">
                      <div className="MyQuestPage__quest2__">
                        <Link
                          to={
                            status === "active"
                              ? `/my-quests/${
                                  activity.quest?.id || activity.quest?._id
                                }`
                              : ""
                          }
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className="MyQuestPage__quest2__Left"
                          >
                            {activity?.quest?.title} {tieredMessage}
                            <div className="MyQuestPage_quest2_Left-date">
                              {t("questDate")}: {dateInterval}
                              {activity.quest.expectedPayoutDate && (
                                <React.Fragment>
                                  <br />
                                  {t("expectedPayoutDate")}:{" "}
                                  {localizeIncorrectDate(
                                    activity.quest.expectedPayoutDate
                                  ).format("MMMM DD, YYYY")}
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </Link>
                        <div className="MyQuestPage__quest2__Right" aria-hidden>
                          <div className="MyQuestPage__quest2__RightTop">
                            <span
                              onClick={this.expandToggle.bind(
                                this,
                                status,
                                index
                              )}
                              aria-hidden
                            >
                              {t("expandDetails")}&nbsp;&nbsp;
                              <i className="fa fa-plus-square-o" />
                            </span>
                          </div>
                          <div
                            className="MyQuestPage__quest2__RightBottom"
                            style={{
                              display:
                                activity.quest.paymentAmount > 0
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {completedQuest && (
                              <React.Fragment>
                                $
                                {/* {rounding(
                                  activity.quest.paymentAmount -
                                  paymentVendorProcessingFee(activity.quest.paymentAmount)
                                )} */}
                                {dynamicPaymentNumber}
                              </React.Fragment>
                            )}
                            {!completedQuest && (
                              <React.Fragment>$0.00</React.Fragment>
                            )}
                            {(uq?.paid ||
                              (activity.quest.paymentMade &&
                                !!activity.quest.paymentAmount &&
                                completedQuest)) && (
                              <span className="MyQuestPage__quest__paymentMade">
                                &nbsp;{t("paid")}
                              </span>
                            )}
                            {completedQuest && (
                              <React.Fragment>
                                <br />
                                <span className="MyQuestPage__quest_processingFee">
                                  {t("processingFee")}: $
                                  {/* {rounding(
                                    paymentVendorProcessingFee(activity.quest.paymentAmount)
                                  )} */}
                                  {dynamicProcessingFee}
                                </span>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </React.Fragment>
                )}
              </div>
            </div>
          );
        } else if (
          activity.quest &&
          activity?.quest?.type === "tiered-multi-days" &&
          activity.quest.frequency === "daily"
        ) {
          const hours2 = Math.floor(
            activity.quest.requiredStreamTimeMinutes / 60
          );
          const minutes2 = activity.quest.requiredStreamTimeMinutes % 60;
          const textMinutes = minutes2 !== 0 ? ` ${minutes2} minutes` : "";
          let textHours = "";
          if (hours2 === 1) {
            textHours = "1 hour";
          } else if (hours2 > 1) {
            textHours = `${hours2} hours`;
          }
          const tieredMessage = ` - Play for ${textHours}${textMinutes} and earn! - $${activity.quest.paymentAmount}`;
          return (
            <div className="MyQuestPage__quest MyQuestPage__quest__paidBounty">
              <div className="MyQuestPage__quest__expand">
                <span
                  onClick={this.expandToggle.bind(this, status, index)}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "none"
                        : "block",
                  }}
                >
                  <i className="fa fa-plus-square-o" />
                </span>
                <span
                  onClick={this.expandToggle.bind(this, "", "")}
                  aria-hidden
                  style={{
                    display:
                      this.state.expandedStatus === status &&
                      this.state.expandedIndex === index
                        ? "block"
                        : "none",
                  }}
                >
                  <i className="fa fa-minus-square-o" />
                </span>
              </div>
              <div
                className="MyQuestPage__quest__paidBounty__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1000ms ease all"
                      : "none",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "1"
                      : "0",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "visible"
                      : "hidden",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "auto"
                      : "0",
                }}
              >
                <div className="MyQuestPage__quest__first">
                  <div
                    className="MyQuestPage__quest__first_image"
                    style={{
                      backgroundImage: `url(${activity.quest.game.cover})`,
                    }}
                  >
                    <div className="MyQuestPage__cover__corner__1" />
                    <div className="MyQuestPage__cover__corner__2" />
                  </div>
                </div>
                <div className="MyQuestPage__quest__second">
                  <div className="MyQuestPage__quest__top">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest__topLeft"
                      >
                        {activity?.quest?.title} {tieredMessage}
                        <div className="MyQuestPage_quest_topLeft-headline">
                          {activity.quest.game.name} <br />
                        </div>
                        <div className="MyQuestPage_quest_topLeft-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              {t("expectedPayoutDate")}:{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest__topRight" aria-hidden>
                      <span
                        onClick={this.expandToggle.bind(this, "", "")}
                        aria-hidden
                      >
                        {t("expandDetails")}&nbsp;&nbsp;
                        <i className="fa fa-minus-square-o" />
                      </span>
                    </div>
                  </div>
                  {notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                         {t("questNotStarted")}
                        </div>
                      </div>
                    </div>
                  )}
                  {!notYetStarted && (
                    <div className="MyQuestPage__quest__bottom">
                      <div className="MyQuestPage__quest__bottomLeft">
                        <div className="MyQuestPage__quest__headRow">
                          <div className="MyQuestPage__quest__headRow-1">
                            {t("date")}
                          </div>
                          <div className="MyQuestPage__quest__headRow-2">
                           {t("streamTime")}
                          </div>
                          <div className="MyQuestPage__quest__headRow-3">
                            {t("earned")}
                          </div>
                          <div className="MyQuestPage__quest__headRow-4" />
                        </div>
                        {activity.activity &&
                          activity.activity.map((activity2) => {
                            const activityClass =
                              activity.quest.requiredStreamTimeMinutes &&
                              activity2.timePlaying >=
                                activity.quest.requiredStreamTimeMinutes
                                ? "success"
                                : "fail";
                            return (
                              <div
                                className={`MyQuestPage__quest__row ${activityClass}`}
                              >
                                <div className="MyQuestPage__quest__row-1">
                                  {`${activity2.month}/${activity2.day}`}
                                </div>
                                <div className="MyQuestPage__quest__row-2">
                                  {activity2.timePlaying} {t("min")}
                                </div>
                                <div className="MyQuestPage__quest__row-3">
                                  {activityClass === "success" && (
                                    <React.Fragment>
                                      {/* ${rounding(activity.quest.paymentAmount - (paymentVendorProcessingFee(activity.quest.paymentAmount)))} */}
                                      ${dynamicPaymentNumber}
                                    </React.Fragment>
                                  )}
                                  {activityClass === "fail" && (
                                    <React.Fragment>$0.00</React.Fragment>
                                  )}
                                </div>
                                <div className="MyQuestPage__quest__row-4">
                                  <i
                                    className="fa fa-check-square-o"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="MyQuestPage__quest__bottomRight">
                        <div className="MyQuestPage__quest__bottomRight__wrapper">
                          <div className="MyQuestPage__quest__bottomRight--totalEarned">
                            <div className="MyQuestPage__quest__bottomRight--first">
                              <div className="MyQuestPage__quest__bottomRight--firstTop">
                                <i className="fa fa-usd" aria-hidden="true" />
                              </div>
                              <div className="MyQuestPage__quest__bottomRight--firstBottom">
                                {t("totalEarned")}
                              </div>
                            </div>
                            <div className="MyQuestPage__quest__bottomRight--second">
                              $
                              {rounding(
                                activity.totalEarnings -
                                  paymentVendorProcessingFee(
                                    activity.totalEarnings
                                  )
                              )}
                            </div>
                          </div>
                          <div className="MyQuestPage__quest__bottomRight--details">
                            <div className="MyQuestPage__quest__bottomRight--first">
                              <div className="MyQuestPage__quest__bottomRight--firstTop">
                                <i
                                  className="fa fa-calendar-o"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="MyQuestPage__quest__bottomRight--firstBottom">
                               {t("completedDays")}
                              </div>
                            </div>
                            <div className="MyQuestPage__quest__bottomRight--second">
                              {activity.totalEarnings /
                                activity.quest.paymentAmount}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="MyQuestPage__quest2__paidBounty__wrapper"
                style={{
                  transition:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "none"
                      : "1000ms ease all",
                  opacity:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "1",
                  visibility:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "hidden"
                      : "visible",
                  height:
                    this.state.expandedStatus === status &&
                    this.state.expandedIndex === index
                      ? "0"
                      : "auto",
                }}
              >
                <div className="MyQuestPage__quest2__first">
                  <div
                    className="MyQuestPage__quest2__first_image"
                    style={{
                      backgroundImage: `url(${activity.quest.game.logo})`,
                    }}
                  />
                </div>
                <div className="MyQuestPage__quest2__second">
                  <div className="MyQuestPage__quest2__">
                    <Link
                      to={
                        status === "active"
                          ? `/my-quests/${
                              activity.quest?.id || activity.quest?._id
                            }`
                          : ""
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="MyQuestPage__quest2__Left"
                      >
                        {activity?.quest?.title} {tieredMessage}
                        <div className="MyQuestPage_quest2_Left-date">
                          {t("questDate")}: {dateInterval}
                          {activity.quest.expectedPayoutDate && (
                            <React.Fragment>
                              <br />
                              {t("expectedPayoutDate")}:{" "}
                              {localizeIncorrectDate(
                                activity.quest.expectedPayoutDate
                              ).format("MMMM DD, YYYY")}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="MyQuestPage__quest2__Right" aria-hidden>
                      <div className="MyQuestPage__quest2__RightTop">
                        <span
                          onClick={this.expandToggle.bind(this, status, index)}
                          aria-hidden
                        >
                          {t("expandDetails")}&nbsp;&nbsp;
                          <i className="fa fa-plus-square-o" />
                        </span>
                      </div>
                      <div className="MyQuestPage__quest2__RightBottom">
                        $
                        {rounding(
                          activity.totalEarnings -
                            paymentVendorProcessingFee(activity.totalEarnings)
                        )}
                        {activity.quest.paymentMade &&
                          !!activity.quest.paymentAmount && (
                            <span className="MyQuestPage__quest__paymentMade">
                              &nbsp;{t("paid")}
                            </span>
                          )}
                        {activity.totalEarnings > 0 && (
                          <React.Fragment>
                            <br />
                            <span className="MyQuestPage__quest_processingFee">
                              {t("processingFee")}: $
                              {rounding(
                                paymentVendorProcessingFee(
                                  activity.totalEarnings
                                )
                              )}
                            </span>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return <React.Fragment />;
      });
    }
    return <React.Fragment />;
  }

  pushReferralBonus = (questId, bonus) => {
    const { referralBonuses } = this.state;
    const index = findIndex(referralBonuses, (e) => e.id === questId);
    if (index === -1) {
      this.state.referralBonuses.push({ id: questId, bonus });
    } else {
      if (referralBonuses[index].bonus >= bonus) {
        return;
      }
      referralBonuses[index].bonus = bonus;
      this.setState({
        referralBonuses,
      });
    }
  };

  render() {
    const { t } = this.props;

    const { data: referralsActivity = [], isLoading: referralIsLoading } =
      this.props.user.referralsActivity;
    const { data: allUserPayments = {} } = this.props.user.allUserPayments;
    const { data: todayActivity = [], isLoading: todayIsLoading } =
      this.props.user.todayActivity;
    const { data: timedActivity = [], isLoading: timedIsLoading } =
      this.props.user.timedActivity;
    const { data: tieredActivity = [], isLoading: tieredIsLoading } =
      this.props.user.tieredActivity;
    const {
      data: tieredMultipleDaysActivity = [],
      isLoading: tieredMultipleDaysIsLoading,
    } = this.props.user.tieredMultipleDaysActivity;
    const { data: myQuests = [], isLoading: myQuestsIsLoading } =
      this.props.user.myQuests;
    const { data: myUserQuests = [], isLoading: isLoadingMyUserQuests } =
      this.props.user.myUserQuests;

    const {
      totalPaid: TP,
      totalEarned: TE,
      paymentStatusList = [],
    } = allUserPayments;

    const myQuestsFiltered = [];
    const referralsActivityTemp = [];
    const todayActivityTemp = [];
    const timedActivityTemp = [];
    const tat = []; // Tiered activity temporal
    const tmdat = []; // Tiered multiple days activity temporal

    const isLoading =
      referralIsLoading ||
      todayIsLoading ||
      timedIsLoading ||
      tieredIsLoading ||
      tieredMultipleDaysIsLoading ||
      myQuestsIsLoading ||
      isLoadingMyUserQuests;

    if (!isLoading) {
      for (let i = 0; i < myQuests.length; i += 1) {
        const userQuest = find(myUserQuests, (o) => o.quest === myQuests[i].id);
        if (
          !userQuest ||
          !userQuest.status ||
          ["approved", "normal"].includes(userQuest.status)
        ) {
          myQuestsFiltered.push(myQuests[i]);
        }
      }

      for (let i = 0; i < referralsActivity.length; i += 1) {
        const userQuest = find(
          myUserQuests,
          (o) => o.quest === referralsActivity[i].quest._id
        );
        if (
          !userQuest ||
          !userQuest.status ||
          ["approved", "normal"].includes(userQuest.status)
        ) {
          referralsActivityTemp.push(referralsActivity[i]);
        }
      }

      for (let i = 0; i < todayActivity.length; i += 1) {
        const userQuest = find(
          myUserQuests,
          (o) => o.quest === todayActivity[i].quest.id
        );
        if (
          !userQuest ||
          !userQuest.status ||
          ["approved", "normal"].includes(userQuest.status)
        ) {
          todayActivityTemp.push(todayActivity[i]);
        }
      }

      for (let i = 0; i < timedActivity.length; i += 1) {
        if (timedActivity[i] && timedActivity[i].quest) {
          const userQuest = find(
            myUserQuests,
            (o) => o.quest === timedActivity[i].quest._id
          );
          if (
            !userQuest ||
            !userQuest.status ||
            ["approved", "normal"].includes(userQuest.status)
          ) {
            timedActivityTemp.push(timedActivity[i]);
          }
        }
      }

      for (let i = 0; i < tieredActivity.length; i += 1) {
        const userQuest = find(
          myUserQuests,
          (o) => o.quest === tieredActivity[i].quest.id
        );
        if (
          !userQuest ||
          !userQuest.status ||
          ["approved", "normal"].includes(userQuest.status)
        ) {
          tat.push(tieredActivity[i]);
        }
      }

      for (let i = 0; i < tieredMultipleDaysActivity.length; i += 1) {
        const userQuest = find(
          myUserQuests,
          (o) => o.quest === tieredMultipleDaysActivity[i].quest.id
        );
        if (
          !userQuest ||
          !userQuest.status ||
          ["approved", "normal"].includes(userQuest.status)
        ) {
          tmdat.push(tieredMultipleDaysActivity[i]);
        }
      }
    }

    return (
      <section className="MyQuestsPage">
        <section className="MyQuestsPage__container">
          <section className="MyQuestsPage__general">
            <h1>{t("myQuests")}</h1>
            <div className="MyQuestPage__tabs">
              <div
                aria-hidden
                onClick={this.changeTab.bind(this, "active")}
                className={classNames("MyQuestPage__tabs--active", {
                  active: this.state.tab === "active",
                })}
              >
                {t("active")}
              </div>
              <div
                aria-hidden
                onClick={this.changeTab.bind(this, "past")}
                className={classNames("MyQuestPage__tabs--past", {
                  active: this.state.tab === "past",
                })}
              >
                {t("past")}
              </div>
            </div>
            <div
              className="MyQuestPage__quests MyQuestPage__activeQuests"
              style={{
                display: this.state.tab === "active" ? "block" : "none",
              }}
            >
              {isLoading && (
                <div className="MyQuestPage__loading">
                  <Loading />
                </div>
              )}
              {!isLoading &&
                this.generateActiveQuests(
                  todayActivityTemp,
                  timedActivityTemp,
                  referralsActivityTemp,
                  myQuestsFiltered,
                  myUserQuests,
                  tat,
                  tmdat,
                  paymentStatusList
                )}
            </div>
            <div
              className="MyQuestPage__quests MyQuestPage__pastQuests"
              style={{ display: this.state.tab === "past" ? "block" : "none" }}
            >
              {isLoading && (
                <div className="MyQuestPage__loading">
                  <Loading />
                </div>
              )}
              {!isLoading &&
                this.generatePastQuests(
                  todayActivityTemp,
                  timedActivityTemp,
                  referralsActivityTemp,
                  myQuestsFiltered,
                  myUserQuests,
                  tat,
                  tmdat,
                  paymentStatusList
                )}
            </div>
          </section>
          <section className="MyQuestsPage__overview">
            <h1>{t("overview")}</h1>
            <div className="MyQuestPage__totalEarned MyQuestsPage__overview__section">
              <div className="MyQuestPage__totalEarned--first">
                <div className="MyQuestPage__totalEarned--firstTop">
                  <i className="fa fa-usd" aria-hidden="true" />
                </div>
                <div className="MyQuestPage__totalEarned--firstBottom">
                  {t("totalEarned")}
                </div>
              </div>
              <div className="MyQuestPage__totalEarned--second">
                ${rounding((TE || 0) + (TP || 0))}
              </div>
            </div>
            <div className="MyQuestPage__paymentCompleted MyQuestsPage__overview__section">
              <div className="MyQuestPage__paymentCompleted--first">
                <div className="MyQuestPage__paymentCompleted--firstTop">
                  <i className="fa fa-money" aria-hidden="true" />
                </div>
                <div className="MyQuestPage__paymentCompleted--firstBottom">
                  {t("paymentCompleted")}
                </div>
              </div>
              <div className="MyQuestPage__paymentCompleted--second">
                {/* ${rounding(paymentCompleted - (paymentVendorProcessingFee(paymentCompleted)))} */}
                {rounding(TP) || 0}
              </div>
            </div>
            <div className="MyQuestPage__paymentDue MyQuestsPage__overview__section">
              <div className="MyQuestPage__paymentDue--first">
                <div className="MyQuestPage__paymentDue--firstTop">
                  <i className="fa fa-credit-card" aria-hidden="true" />
                </div>
                <div className="MyQuestPage__paymentDue--firstBottom">
                  {t("paymentDue")}
                </div>
              </div>
              <div className="MyQuestPage__paymentDue--second">
                {/* ${rounding(paymentDue - (paymentVendorProcessingFee(paymentDue)))} */}
                {rounding(TE) || 0}
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

MyQuestsPage.propTypes = {};

export default withTranslation("myQuestsPage")(MyQuestsPage);
