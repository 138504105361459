import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";

import { SetViewContext } from "../PortalContext";
import { textTruncate, timeAgoCalc } from "../../../../utils/functions";
import { getRandomAvatar } from "../../../../global/images/icons/Avatar";
import { navigationAssembly } from "../../../../utils/functions";

import Icon from "../../../../global/images/icons/Icon";

import "./ReviewItems.scss";

const filterProcessed = (items) => items.slice(0, 3);

const ReviewItemsBlank = ({ type }) => {
  const { t } = useTranslation("reviewItems");
  return (
    <div className="ReviewItems__Blank">
      {Icon({
        color: "#302f34",
        name: type === "Applicants" ? "users" : "empty",
      })}
      <div className="ReviewItems__Blank_Text">
        {t("yourIncoming")} {type} {t("listIsEmpty")}.
      </div>
      <SetViewContext.Consumer>
        {({ setView, view }) => (
          <div
            className="ActiveItems__View_All"
            onClick={() => setView(navigationAssembly(type, view))}
          >
            {t("viewAll")} {type}
          </div>
        )}
      </SetViewContext.Consumer>
    </div>
  );
};

const ItemCard = ({ type, item, removeItem, accept, reject }) => {
  const componentRef = useRef(null);
  const { t } = useTranslation("reviewItems");
  const [componentWidth, setComponentWidth] = useState(0);

  useEffect(() => {
    setComponentWidth(componentRef?.current?.offsetWidth || 0);
  }, [componentRef.current]);

  const {
    profile_img_url,
    displayName,
    username,
    title,
    createdAt,
    questId,
    userId,
    userQuestId,
    requirementId,
    requirementTitle,
  } = item;

  const timeAgo = timeAgoCalc(createdAt);

  const params = {};
  let itemCardInfo = null;

  const characterCount = title?.length || 0;
  const titleSize = characterCount * 14;
  const truncateTitle = titleSize > componentWidth;

  const displayTitle = title ? (
    truncateTitle ? (
      <Tooltip placement="top" title={title.toUpperCase()}>
        <span className="ReviewItems__Card_Info_Title">
          {textTruncate(title.toUpperCase(), Math.floor(componentWidth / 14))}
        </span>
      </Tooltip>
    ) : (
      <span className="ReviewItems__Card_Info_Title">
        {title.toUpperCase()}
      </span>
    )
  ) : (
    ""
  );

  if (type.toLowerCase() === "applicants") {
    params.questId = questId;
    params.userId = userId;

    itemCardInfo = (
      <div className="ReviewItems__Card_Info">
        <SetViewContext.Consumer>
          {({ setView, view }) => (
            <span
              className="ReviewItems__Card_Info_Name"
              onClick={() =>
                setView({
                  ...view,
                  component: "StreamerProfile",
                  currentUser: username,
                })
              }
            >
              {displayName || username}
            </span>
          )}
        </SetViewContext.Consumer>
        {displayTitle}
        <span className="ReviewItems__Card_Info_Date">{timeAgo}</span>
      </div>
    );
  } else if (type.toLowerCase() === "deliverables") {
    params.userQuestId = userQuestId;
    params.requirementId = requirementId;

    itemCardInfo = (
      <div className="ReviewItems__Card_Info">
        <span className="ReviewItems__Card_Info_Name">
          {displayName || username || title}
        </span>
        {displayTitle}
        <span className="ReviewItems__Card_Info_Requirement">
          {requirementTitle}
        </span>
        <span className="ReviewItems__Card_Info_Date">{timeAgo}</span>
      </div>
    );
  }

  const reviewAction = (type, questId, userId) => {
    return (
      <SetViewContext.Consumer>
        {({ setView, view }) => (
          <div
            className="ReviewItems__Card_Actions_Review"
            onClick={() =>
              setView({
                ...view,
                component: `ViewAll${
                  type === "deliverables" ? "Deliverables" : "Applicants"
                }`,
                currentQuest: questId,
                currentUser: userId,
              })
            }
          >
            {t("review")}
          </div>
        )}
      </SetViewContext.Consumer>
    );
  };

  return (
    <li ref={componentRef} className="ReviewItems__Card">
      <img
        className="ReviewItems__Card_Img"
        src={profile_img_url}
        alt={displayName || username || title}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = getRandomAvatar();
        }}
      />
      {itemCardInfo}
      <div className="ReviewItems__Card_Actions">
        {reviewAction(type.toLowerCase(), questId, userId)}
      </div>
    </li>
  );
};

const ReviewItems = ({ type, items, removeItem, accept, reject }) => {
  const { t } = useTranslation("reviewItems");
  const content =
    items && items.length ? (
      <ul className="ReviewItems__List">
        {filterProcessed(items).map((item) => (
          <ItemCard
            type={type}
            item={item}
            removeItem={removeItem}
            accept={() => {
              if (type.toLowerCase() === "applicants") {
                accept(item.userQuestId);
              } else {
                accept(item.userQuestId, item.requirementId);
              }
            }}
            reject={() => {
              if (type.toLowerCase() === "applicants") {
                reject(item.userQuestId);
              } else {
                reject(item.userQuestId, item.requirementId);
              }
            }}
            key={
              type.toLowerCase() === "applicants"
                ? `${item.userId}-${item.questId}`
                : `${item.userQuestId}-${item.requirementId}`
            }
          />
        ))}
        <SetViewContext.Consumer>
          {({ setView, view }) => (
            <li
              className="ReviewItems__List_View_All"
              onClick={() =>
                setView({
                  ...view,
                  component: `ViewAll${type}`,
                })
              }
            >
              {t("viewAll")} {type}
            </li>
          )}
        </SetViewContext.Consumer>
      </ul>
    ) : (
      <ReviewItemsBlank type={type} />
    );

  return <div className="ReviewItems">{content}</div>;
};

export default ReviewItems;
