/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router";
import { without, reduce, forEach, filter, findIndex } from "lodash";
import { withTranslation } from "react-i18next";

import classNames from "classnames";
import { CSVLink } from "react-csv";
import BarChart from "../../../../utils/charts/BarChart";
import "./QuestActivity.scss";
import StackedChart from "../../../../utils/charts/StackedChart";
import EditModal from "../QuestTimeEditModal/EditModal";
import { paymentVendorProcessingFee } from "../../../../utils/functions";

class QuestActivity extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showingElements: [],
      tab: 1,
      chart: 1,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    if (questId) {
      this.props.getCCQuestActivity(questId, token);
    }
  }

  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [...showingElements, elementId],
      });
    } else {
      this.setState({
        showingElements: [...without(showingElements, elementId)],
      });
    }
  };

  rounding = (number) => parseFloat(number).toFixed(2);

  generateStreamActivityRowsHTML = (streamActivity) => {
    const { t } = this.props;
    const { data: quest } = this.props.commandCenter.quest;
    if (streamActivity.length > 0) {
      return streamActivity.map((streamActivityItem) => (
        <div key={streamActivityItem.activityId} className="row">
          <div className="table-cell">{`${streamActivityItem.month}/${streamActivityItem.day}/${streamActivityItem.year}`}</div>
          <div className="table-cell">
            {Math.round(streamActivityItem.AVGViewers)}
          </div>
          <div className="table-cell">
            {streamActivityItem.timePlaying}
            {t("min")}
          </div>
          {quest.requiredStreamTimeMinutes &&
            streamActivityItem.timePlaying >=
              quest.requiredStreamTimeMinutes && (
              <div className="table-cell">
                ${this.rounding(quest.dailyEarning)} USD
              </div>
            )}
          {quest.requiredStreamTimeMinutes &&
            streamActivityItem.timePlaying <
              quest.requiredStreamTimeMinutes && (
              <div className="table-cell">$0 USD</div>
            )}
        </div>
      ));
    }
    return <div className="NoStreamActivity">{t("noStreamActivity")}</div>;
  };

  generateRowsHTML = (questActivity) => {
    const { showingElements } = this.state;
    const { data: quest } = this.props.commandCenter.quest;
    const { t } = this.props;

    if (questActivity.length > 0) {
      return questActivity.map((questActivityItem, index) => {
        const earnings = reduce(
          questActivityItem.streamActivity,
          (sum, item) => {
            if (quest.requiredStreamTimeMinutes && quest.dailyEarning) {
              return (
                sum +
                (item.timePlaying >= quest.requiredStreamTimeMinutes
                  ? quest.dailyEarning
                  : 0)
              );
            }
            return sum + 0;
          },
          0
        );
        if (questActivityItem.user) {
          return (
            <div
              key={questActivity.userId}
              className={classNames("row toggable", {
                open: showingElements.indexOf(questActivityItem.userId) !== -1,
              })}
            >
              <div className="row-basic">
                <div className="table-cell">{index + 1}</div>
                <div className="table-cell">
                  {questActivityItem.user.username}
                </div>
                <div className="table-cell">{questActivityItem.email}</div>
                <div className="table-cell">${this.rounding(earnings)} USD</div>
                <div className="table-cell">
                  {questActivityItem.totalAVGViewers &&
                    Math.round(questActivityItem.totalAVGViewers)}
                </div>
                <div className="table-cell">
                  {questActivityItem.totalAVGTimePlaying &&
                    Math.round(questActivityItem.totalAVGTimePlaying)}
                </div>
                <div className="table-cell">
                  {questActivityItem.totalTimePlayed &&
                    Math.round(questActivityItem.totalTimePlayed)}{" "}
                  &nbsp;{" "}
                  <EditModal
                    {...this.props}
                    currentUser={questActivityItem}
                    rehydrateCallback={this.props.getCCQuestActivity}
                  />
                </div>
                <div className="table-cell">
                  {showingElements.indexOf(questActivityItem.userId) === -1 && (
                    <button
                      className="small-btn"
                      onClick={this.toggleElementInfo.bind(
                        this,
                        questActivityItem.userId
                      )}
                    >
                      {t("showDetails")}
                    </button>
                  )}
                  {showingElements.indexOf(questActivityItem.userId) !== -1 && (
                    <button
                      className="small-btn open"
                      onClick={this.toggleElementInfo.bind(
                        this,
                        questActivityItem.userId
                      )}
                    >
                      {t("hideDetails")}
                    </button>
                  )}
                </div>
              </div>
              <div className="row-details">
                <section className="StreamActivityTable flex-table">
                  <div className="row">
                    <div className="table-cell headline">{t("date")}</div>
                    <div className="table-cell headline">
                      {t("averageViewers")}
                    </div>
                    <div className="table-cell headline">
                      {t("timePlaying")}
                    </div>
                    <div className="table-cell headline">{t("earnings")}</div>
                  </div>
                  {this.generateStreamActivityRowsHTML(
                    questActivityItem.streamActivity
                  )}
                </section>
              </div>
            </div>
          );
        }
        return <React.Fragment />;
      });
    }
    return <div className="NoQuestActivity">{t("noQuestActivity")}</div>;
  };

  changeTab = (value) => {
    this.setState({
      tab: value,
    });
  };

  changeChart = (value) => {
    this.setState({
      chart: value,
    });
  };

  render() {
    const { t } = this.props
    const { gameId } = this.props.router.params;
    const { data: questActivity } = this.props.commandCenter.questActivity;
    const { data: quest } = this.props.commandCenter.quest;

    const chartMinutesStreamedPerDayData = [];
    const chartTimesCompletedPerDayData = [];
    const chartConcurrentViewersPerDayData = [];
    const chartAverageViewersPerDayData = [];
    const chartPayoutsPerDayData = [];
    const chartProcessingFeePerDayData = [];
    const chartToPayPerDayData = [];

    let tempDate;
    let endDateTime;
    const dates = [];

    if (quest && quest.startDateTime) {
      tempDate = new Date(
        `${quest.startDateTime.substring(0, 10)}T00:00:00+0000`
      );
      endDateTime = new Date(
        `${quest.endDateTime.substring(0, 10)}T00:00:00+0000`
      );
      if (tempDate <= endDateTime) {
        while (tempDate <= endDateTime) {
          const dateString = `${
            tempDate.getUTCMonth() + 1
          }/${tempDate.getUTCDate()}/${tempDate.getUTCFullYear()}`;
          dates.push({
            dateString,
            completedCount: 0,
          });
          chartMinutesStreamedPerDayData.push({
            date: dateString,
            minutesStreamed: 0,
            y: 0,
            x: new Date(dateString),
          });
          chartTimesCompletedPerDayData.push({
            date: dateString,
            y: 0,
            x: new Date(dateString),
          });
          chartConcurrentViewersPerDayData.push({
            concurrentViewers: 0,
            date: dateString,
            y: 0,
            x: new Date(dateString),
            count: 0,
          });
          chartAverageViewersPerDayData.push({
            averageViewers: 0,
            date: dateString,
            y: 0,
            x: new Date(dateString),
          });
          chartPayoutsPerDayData.push({
            date: dateString,
            y: 0,
            x: new Date(dateString),
          });
          chartProcessingFeePerDayData.push({
            date: dateString,
            y: 0,
            x: new Date(dateString),
          });
          chartToPayPerDayData.push({
            date: dateString,
            y: 0,
            x: new Date(dateString),
          });
          const dateDay = tempDate.getUTCDate();
          tempDate.setUTCDate(dateDay + 1);
        }
      }
    }
    let totalPossibleCompleted = 0;
    if (dates.length > 0 && questActivity.length > 0) {
      totalPossibleCompleted = dates.length * questActivity.length;
    }
    let totalCompletedCount = 0;

    let totalMinutesStreamed = 0;
    let totalHoursStreamed = 0;
    let totalAverageViewers = 0;
    let totalConcurrentViewers = 0;
    let totalEarning = 0;
    let totalToPay = 0;
    let totalProcessingFee = 0;
    let totalUsers = 0;

    const headersCSV = [
      { label: t("questName"), key: "questName" },
      { label: t("noizUserName"), key: "noizUsername" },
      { label: t("twitchUserName"), key: "twitchUsername" },
      { label: t("payPalEmail"), key: "paymentEmail" },
      { label: t("country"), key: "country" },
      { label: t("averageViewers"), key: "totalAVGViewers" },
      { label: t("peakViewers"), key: "peakViewers" },
      { label: t("hoursWatched"), key: "totalHoursWatched" },
      { label: t("totalTimePlayed"), key: "totalTimePlayed" },
      { label: t("completed"), key: "completed" },
      { label: t("earnings"), key: "earnings" },
      { label: t("processingFee"), key: "processingFee" },
      { label: t("toPay"), key: "toPay" },
    ];
    const dataCSV = [];
    const totalUsersCount = questActivity.length;
    let totalEarnings =
      quest && questActivity.length > 0
        ? reduce(
            questActivity,
            (globalEarnings, questActivityItem) => {
              if (questActivityItem.streamActivity) {
                const totalUserEarnings = reduce(
                  questActivityItem.streamActivity,
                  (userEarnings, item) => {
                    if (quest.requiredStreamTimeMinutes && quest.dailyEarning) {
                      return (
                        userEarnings +
                        (item.timePlaying >= quest.requiredStreamTimeMinutes
                          ? quest.dailyEarning
                          : 0)
                      );
                    }
                    return userEarnings + 0;
                  },
                  0
                );
                return globalEarnings + totalUserEarnings;
              }
              return globalEarnings + 0;
            },
            0
          )
        : 0;
    forEach(questActivity, (value) => {
      let userEarnings = 0;
      if (value.streamActivity) {
        forEach(value.streamActivity, (value2) => {
          // HERE - Boolean constant with the value of completed
          const completed =
            value2.timePlaying >= quest.requiredStreamTimeMinutes;
          // HERE - Date
          const date = `${value2.month}/${value2.day}/${value2.year}`;
          // HERE - Sum user earnings
          userEarnings += completed ? quest.dailyEarning : 0;
          // HERE - Sum for total concurrent
          totalConcurrentViewers += value2.AVGViewers;
          // START - Total completed count
          if (completed) {
            totalCompletedCount += 1;
          }
          // END - Total completed count
          // START - Completed users per day
          const tempCompletedPerDay = {
            date,
            minutesStreamed: value2.timePlaying,
            y: value2.timePlaying,
          };
          const index2 = findIndex(chartTimesCompletedPerDayData, {
            date: tempCompletedPerDay.date,
          });
          if (index2 >= 0 && completed) {
            chartTimesCompletedPerDayData[index2].completedCount += 1;
            chartTimesCompletedPerDayData[index2].y += 1;
          }
          // END - Completed users per day
          // START - Chart minutes streamed per day
          const tempMinutesStreamedPerDay = {
            date,
            minutesStreamed: value2.timePlaying,
            y: value2.timePlaying,
          };
          const index = findIndex(chartMinutesStreamedPerDayData, {
            date: tempMinutesStreamedPerDay.date,
          });
          if (index >= 0) {
            chartMinutesStreamedPerDayData[index].minutesStreamed +=
              tempMinutesStreamedPerDay.minutesStreamed;
            chartMinutesStreamedPerDayData[index].y +=
              tempMinutesStreamedPerDay.minutesStreamed;
          }
          // END - Chart minutes streamed per day
          // START - Chart concurrent viewers per day
          const tempConcurrentPerDay = {
            date,
            concurrentViewers: value2.AVGViewers,
            y: value2.AVGViewers,
          };
          const index3 = findIndex(chartConcurrentViewersPerDayData, {
            date: tempConcurrentPerDay.date,
          });
          if (index3 >= 0) {
            chartConcurrentViewersPerDayData[index3].concurrentViewers +=
              tempConcurrentPerDay.concurrentViewers;
            chartConcurrentViewersPerDayData[index3].y +=
              tempConcurrentPerDay.concurrentViewers;
            chartConcurrentViewersPerDayData[index3].count += 1;
          }
          // END - Chart concurrent viewers per day
          // START - Chart 'payouts' - 'processing fee' - 'to pay' per day
          const tempMoneyStatsPerDay = {
            date,
            payouts: completed ? quest.dailyEarning : 0,
            processingFee: completed
              ? paymentVendorProcessingFee(quest.dailyEarning)
              : 0,
            toPay: completed
              ? quest.dailyEarning -
                paymentVendorProcessingFee(quest.dailyEarning)
              : 0,
          };
          const index4 = findIndex(chartPayoutsPerDayData, {
            date: tempMoneyStatsPerDay.date,
          });
          if (index4 >= 0) {
            chartPayoutsPerDayData[index4].y += tempMoneyStatsPerDay.payouts;
            chartProcessingFeePerDayData[index4].y +=
              tempMoneyStatsPerDay.processingFee;
            chartToPayPerDayData[index4].y += tempMoneyStatsPerDay.toPay;
          }
          // END - Chart 'payouts' - 'processing fee' - 'to pay' per day
        });
      }
      if (value.user) {
        // User earnings
        const earnings = userEarnings;
        // User processingFee
        const processingFee = paymentVendorProcessingFee(userEarnings);
        // User toPay
        const toPay = userEarnings - paymentVendorProcessingFee(userEarnings);

        dataCSV.push({
          questName: quest.title,
          noizUsername: value.user.username,
          twitchUsername: value.user.connectedAccounts.twitch.username,
          paymentEmail: value.user.paymentEmail,
          country: value.user.country,
          totalAVGViewers: Math.round(value.totalAVGViewers),
          peakViewers: Math.round(value.peakViewers),
          totalHoursWatched: parseFloat(value.totalMinutesWatched / 60).toFixed(
            2
          ),
          totalTimePlayed: Math.round(value.totalTimePlayed),
          completed: value.streamActivity.length,
          earnings,
          processingFee,
          toPay,
        });
        totalMinutesStreamed += value.totalTimePlayed;
        totalAverageViewers += value.totalAVGViewers;
        totalUsers += 1;
        totalEarning += parseFloat(earnings);
        totalProcessingFee += parseFloat(processingFee);
        totalToPay += parseFloat(toPay);
      } else {
        totalEarnings -= userEarnings;
      }
    });
    totalHoursStreamed = this.rounding(totalMinutesStreamed / 60);
    totalAverageViewers = Math.floor(totalAverageViewers / totalUsers);
    totalEarning = parseFloat(totalEarning).toFixed(2);
    totalProcessingFee = parseFloat(totalProcessingFee).toFixed(2);
    totalToPay = parseFloat(totalToPay).toFixed(2);
    totalConcurrentViewers = Math.floor(totalConcurrentViewers);

    const totalNotCompleted = totalPossibleCompleted - totalCompletedCount;
    const percentageCompleted = this.rounding(
      (totalCompletedCount / totalPossibleCompleted) * 100
    );
    const chartHoursStreamedPerDayData = [];
    forEach(chartMinutesStreamedPerDayData, (o) => {
      chartHoursStreamedPerDayData.push({
        x: o.x,
        y: parseFloat(o.y / 60).toFixed(2),
      });
    });
    for (let i = 0; i < dates.length; i += 1) {
      chartConcurrentViewersPerDayData[i].y = Math.round(
        chartConcurrentViewersPerDayData[i].y
      );
      if (chartConcurrentViewersPerDayData[i].count !== 0) {
        chartAverageViewersPerDayData[i].y = this.rounding(
          chartConcurrentViewersPerDayData[i].y /
            chartConcurrentViewersPerDayData[i].count
        );
      }
      chartPayoutsPerDayData[i].y = this.rounding(chartPayoutsPerDayData[i].y);
      chartProcessingFeePerDayData[i].y = this.rounding(
        chartProcessingFeePerDayData[i].y
      );
      chartToPayPerDayData[i].y = this.rounding(chartToPayPerDayData[i].y);
    }
    const timesFailed = [];
    for (let i = 0; i < chartTimesCompletedPerDayData.length; i += 1) {
      const tempFailed = {
        x: chartTimesCompletedPerDayData[i].x,
        y: totalUsersCount - chartTimesCompletedPerDayData[i].y,
        date: chartTimesCompletedPerDayData[i].date,
      };
      timesFailed.push(tempFailed);
    }
    // chartTimesCompletedPerDayData = [...chartTimesCompletedPerDayData, ...timesFailed];
    const stackedData = [
      {
        label: t("successful"),
        data: chartTimesCompletedPerDayData,
        backgroundColor: "#55DD6B",
      },
      {
        label: t("failed"),
        data: timesFailed,
        backgroundColor: "#E23645",
      },
    ];
    
    

    return (
      <section className="CCQuestActivity">
        <Link
          to={`/admin/command-center/${gameId}/quests`}
          activeClassName="active"
          href={`/admin/command-center/${gameId}/quests`}
        >
          <button className="CCQuests__back--btn">{t("back")}</button>
        </Link>
        <h2>
          {t("title")}: {quest.title}
        </h2>
        <h2>
          {t("questUsers")}:{" "}
          <span>{filter(questActivity, (o) => o.user).length}</span>
        </h2>
        <div className="CCQuestActivity__tabs">
          <div
            className={classNames("CCQuestActivity__tab", {
              active: this.state.tab === 1,
            })}
            onClick={this.changeTab.bind(this, 1)}
            aria-hidden
          >
            {t("summary")}
          </div>
          <div
            className={classNames("CCQuestActivity__tab", {
              active: this.state.tab === 2,
            })}
            onClick={this.changeTab.bind(this, 2)}
            aria-hidden
          >
            {t("details")}
          </div>
        </div>
        {this.state.tab === 1 && (
          <section className="ToggleTable">
            <section className="ToggleTable__body-wrapper">
              <section className="ToggleTable__body">
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalHoursStreamed")}{" "}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext tooltipText_1">
                        {t("tooltipText_1")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames(
                          "ToggleTable__body-itemBot_button",
                          { active: this.state.chart === 1 }
                        )}
                        onClick={this.changeChart.bind(this, 1)}
                        aria-hidden
                      >
                        {totalHoursStreamed}
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("averageViewersUsers")}{" "}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext tooltipText_2">
                        {t("tooltipText_2")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames(
                          "ToggleTable__body-itemBot_button",
                          { active: this.state.chart === 2 }
                        )}
                        onClick={this.changeChart.bind(this, 2)}
                        aria-hidden
                      >
                        {totalAverageViewers || "0"}
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalAverageViewers")}{" "}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext tooltipText_3">
                        {t("tooltipText_3")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames(
                          "ToggleTable__body-itemBot_button",
                          { active: this.state.chart === 3 }
                        )}
                        onClick={this.changeChart.bind(this, 3)}
                        aria-hidden
                      >
                        {totalConcurrentViewers}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalSuccessful")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext tooltipText_4">
                        {t("tooltipText_4")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot green">
                      <div
                        className={classNames(
                          "ToggleTable__body-itemBot_button green",
                          { active: this.state.chart === 4 }
                        )}
                        onClick={this.changeChart.bind(this, 4)}
                        aria-hidden
                      >
                        {totalCompletedCount}
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalFails")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext tooltipText_5">
                        {t("tooltipText_5")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot red">
                      <div
                        className={classNames(
                          "ToggleTable__body-itemBot_button red",
                          { active: this.state.chart === 4 }
                        )}
                        onClick={this.changeChart.bind(this, 4)}
                        aria-hidden
                      >
                        {totalNotCompleted}
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      % {t("completedQuest")}{" "}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext tooltipText_6">
                        {t("tooltipText_6")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div>{percentageCompleted || "0"}%</div>
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalPayouts")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext tooltipText_7">
                        {t("tooltipText_7")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames(
                          "ToggleTable__body-itemBot_button",
                          { active: this.state.chart === 5 }
                        )}
                        onClick={this.changeChart.bind(this, 5)}
                        aria-hidden
                      >
                        ${totalEarning}
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalProcessingFee")}{" "}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext tooltipText_8">
                        3% {t("tooltipText_8")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames(
                          "ToggleTable__body-itemBot_button",
                          { active: this.state.chart === 6 }
                        )}
                        onClick={this.changeChart.bind(this, 6)}
                        aria-hidden
                      >
                        ${totalProcessingFee}
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalToPay")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("tooltipText_9")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames(
                          "ToggleTable__body-itemBot_button",
                          { active: this.state.chart === 7 }
                        )}
                        onClick={this.changeChart.bind(this, 7)}
                        aria-hidden
                      >
                        ${totalToPay}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames("QuestActivity__chart", {
                    active: this.state.chart === 1,
                  })}
                >
                  <div className="QuestActivity__chart_title">
                    {t("questActivity__chart_1")}
                  </div>
                  <BarChart
                    data={chartHoursStreamedPerDayData}
                    labelY={t("questActivity__chart_1")}
                  />
                </div>
                <div
                  className={classNames("QuestActivity__chart", {
                    active: this.state.chart === 2,
                  })}
                >
                  <div className="QuestActivity__chart_title">
                    {t("questActivity__chart_2")}
                  </div>
                  <BarChart
                    data={chartAverageViewersPerDayData}
                    labelY={t("questActivity__chart_2")}
                  />
                </div>
                <div
                  className={classNames("QuestActivity__chart", {
                    active: this.state.chart === 3,
                  })}
                >
                  <div className="QuestActivity__chart_title">
                    {t("questActivity__chart_3")}
                  </div>
                  <BarChart
                    data={chartConcurrentViewersPerDayData}
                    labelY={t("questActivity__chart_3")}
                  />
                </div>
                <div
                  className={classNames("QuestActivity__chart", {
                    active: this.state.chart === 4,
                  })}
                >
                  <div className="QuestActivity__chart_title">
                    {t("questActivity__chart_4")}
                  </div>
                  <StackedChart
                    data={stackedData}
                    labelY={t("questActivity__chart_4")}
                  />
                </div>
                <div
                  className={classNames("QuestActivity__chart", {
                    active: this.state.chart === 5,
                  })}
                >
                  <div className="QuestActivity__chart_title">
                    {t("questActivity__chart_5")}
                  </div>
                  <BarChart
                    data={chartPayoutsPerDayData}
                    labelY={t("questActivity__chart_5")}
                  />
                </div>
                <div
                  className={classNames("QuestActivity__chart", {
                    active: this.state.chart === 6,
                  })}
                >
                  <div className="QuestActivity__chart_title">
                    {t("questActivity__chart_6")}
                  </div>
                  <BarChart
                    data={chartProcessingFeePerDayData}
                    labelY={t("questActivity__chart_6")}
                  />
                </div>
                <div
                  className={classNames("QuestActivity__chart", {
                    active: this.state.chart === 7,
                  })}
                >
                  <div className="QuestActivity__chart_title">
                    {t("questActivity__chart_7")}
                  </div>
                  <BarChart
                    data={chartToPayPerDayData}
                    labelY={t("questActivity__chart_7")}
                  />
                </div>
              </section>
            </section>
          </section>
        )}
        {this.state.tab === 2 && (
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">
                Quest Users: <span>{questActivity.length}</span>
              </h3>
              <CSVLink
                data={dataCSV}
                headers={headersCSV}
                filename={
                  quest && quest.game
                    ? `${quest.game.name} - ${quest.title}.csv`
                    : "report.csv"
                }
              >
                <i className="fa fa-download" />
              </CSVLink>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">{t("username")}</div>
                  <div className="table-cell headline">{t("email")}</div>
                  <div className="table-cell headline">{t("earnings")}</div>
                  <div className="table-cell headline">
                    {t("avgViewersOrDay")}
                  </div>
                  <div className="table-cell headline">{t("avgTimeOrDay")}</div>
                  <div className="table-cell headline">
                    {t("totalTimePlayed")}
                  </div>
                  <div className="table-cell headline">{t("actions")}</div>
                </div>
                {this.generateRowsHTML(questActivity)}
                <div className="row">
                  <div className="table-cell">---</div>
                  <div className="table-cell">---</div>
                  <div className="table-cell">{t("total")}: </div>
                  <div className="table-cell">
                    ${this.rounding(totalEarnings)} USD
                  </div>
                  <div className="table-cell">---</div>
                </div>
              </section>
            </section>
          </section>
        )}
      </section>
    );
  }
}

QuestActivity.propTypes = {};

export default withTranslation("questActivity")(QuestActivity);
