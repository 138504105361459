import { Observable } from "rxjs/Observable";
import { simpleNotification } from "./notifications";
import { t } from "i18next";

const alertTypes = {
  E_USER_NO_LONGER_QUALIFIES: t("apiErrorHandler.userNoLongerQualifies"),
  E_EMAIL_NOT_SENT_SUCCESS: t("apiErrorHandler.emailNotSendSuccess"),
};

/* eslint-disable import/prefer-default-export */
export const getErrorResponse = (response) => {
  const apiCode =
    response && response.error && response.error.response
      ? response.error.response.code
      : "";
  const message = alertTypes[apiCode];
  if (message) {
    simpleNotification({
      level: "error",
      title: t("apiErrorHandler.error"),
      message,
    });
  }

  if (
    response.error.xhr.response &&
    response.error.xhr.response.code &&
    response.error.xhr.response.code === "E_FORCED_LOGOUT"
  ) {
    window.location.replace(`${window.location.origin}/logout`);
  }

  if (
    response.error.xhr.response &&
    response.error.xhr.response.code &&
    response.error.xhr.response.message &&
    response.error.xhr.response.code === "E_UNAUTHORIZED" &&
    response.error.xhr.response.message === "jwt expired"
  ) {
    window.location.replace(`${window.location.origin}/logout`);
  }

  const initialPayload = response.initialPayload ? response.initialPayload : {};
  if (response.error.xhr.response) {
    return Observable.of({
      type: response.type,
      payload: {
        initialPayload,
        error: true,
        message: response.error.xhr.response.message,
        code: response.error.xhr.response.code,
        errors: response.error.xhr.response.errors,
      },
    });
  }
  return Observable.of({
    type: response.type,
    payload: {
      initialPayload,
      error: true,
      message: t("apiErrorHandler.genericErrorCode"),
      code: "GENERIC_ERROR_CODE",
      errors: {},
    },
  });
};
