/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */

import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/lib/Creatable";
import { find } from "lodash";
import { Tooltip } from "@material-ui/core";
import { withTranslation } from "react-i18next";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label: `${label}`,
  value: label,
});

class CustomMentionField extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      inputValue: "",
      alreadyInValues: false,
      isValidTag: true,
    };
  }

  render() {
    const {
      t,
      input,
      meta: { touched, error },
      disabled,
      ...custom
    } = this.props;
    const hasError = touched && error !== undefined;
    const { inputValue, alreadyInValues } = this.state;
    const makeDataFunc = createOption;

    return (
      <div className="input-container">
        <label htmlFor={input.label}>
          {custom.customLabel}{" "}
          {disabled ? (
            <Tooltip title={t("mentionToolTip")} placement="top">
              <i className="fa fa-lock" />
            </Tooltip>
          ) : null}
        </label>
        <div className="input-wrapper">
          <CreatableSelect
            {...input}
            components={components}
            inputValue={inputValue}
            isClearable
            isDisabled={disabled}
            isMulti
            menuIsOpen={false}
            onChange={(value) => input.onChange(value)}
            onInputChange={(value) => {
              this.setState({
                inputValue: value,
                alreadyInValues: find(input.value, ["value", value]),
                isValidTag: value !== "",
              });
            }}
            onKeyDown={(event) => {
              if (!inputValue) return;
              switch (event.key) {
                case "Enter":
                case ",":
                case "Tab": {
                  const alreadyIn = find(input.value, ["value", inputValue]);
                  if (!alreadyIn) {
                    this.setState({
                      inputValue: "",
                      isValidTag: true,
                    });
                    input.onChange([...input.value, makeDataFunc(inputValue)]);
                  }
                  event.preventDefault();
                  break;
                }
                default:
                  break;
              }
            }}
            placeholder={t("mentionPlaceholder")}
            className={classNames("react-select-container", {
              touched,
              error: hasError,
              ok: !hasError,
              empty: !input.value,
              "not-empty": input.value,
              ...input.className,
            })}
            classNamePrefix="react-select"
            value={input.value}
            onBlur={() => {
              input.onBlur();
            }}
          />
          {custom.customLabel2}
          {hasError && touched && (
            <div className="error-icon" style={{ right: "35px", top: "14px" }}>
              <i className="fa fa-exclamation" />
            </div>
          )}
          {!hasError && touched && (
            <div className="ok-icon" style={{ right: "35px", top: "14px" }}>
              <i className="fa fa-check" />
            </div>
          )}
          {hasError && <div className="error-message">{error}</div>}
          {alreadyInValues && (
            <div className="error-message">{t("tagAlreadyExists")}</div>
          )}
        </div>
      </div>
    );
  }
}

CustomMentionField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
};

CustomMentionField.defaultProps = {
  placeholder: "",
};

export default withTranslation("customMentionField")(CustomMentionField);
