import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  getUserAvatar,
  nFormatter,
  rounding,
} from "../../../../../utils/functions";
import { calculatePaymentInfoForStreamer } from "../../../../../utils/apiv2";
import { SetViewContext } from "../../PortalContext";

import CustomCheckbox from "../../CustomInputs/CustomCheckbox";
import Loading from "../../../../../global/components/loading/Loading";

import "./FundQuestApplicant.scss";

const FundQuestApplicant = (props) => {
  const {
    checkout,
    // handleFundQuestModalChange,
    // showFundQuestModal,
    rejection = false,
    uq = {},
  } = props;

  const { quest = "", user = {} } = uq;
  const {
    connectedAccounts = {},
    displayName = "",
    freezeTwitchViewers = {},
    id: userId = "",
    statistics = {},
    twitchAVGOverride = 0,
    username = "",
  } = user;

  const { isFrozen = false } = freezeTwitchViewers;
  const { AVG30 = 0 } = statistics;
  const avgViewers = isFrozen ? twitchAVGOverride : AVG30;

  const { twitch = {} } = connectedAccounts;
  const { followers = 0 } = twitch;

  const { t } = useTranslation("portalLeft");
  const [calculatedPaymentInfo, setCalculatedPaymentInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const titleLang = rejection ? t("rejectAndRefund") : t("fundQuest");
  const handleAgreeToTerms = rejection
    ? t("agreeToTerms.reject")
    : t("agreeToTerms.agree");

  useEffect(() => {
    const calculatePaymentInfo = async () => {
      const data = await calculatePaymentInfoForStreamer(userId, quest);
      if (data?.data?.data) {
        setCalculatedPaymentInfo(data.data.data);
      }
    };

    calculatePaymentInfo();
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="FundQuestApplicant">
        <Loading />
      </div>
    );
  }

  const {
    noizCut = 0.1,
    seatPrice = 0,
    // seats = 1,
    stripeFee = 0,
    total: subTotal = 0,
  } = calculatedPaymentInfo;

  const total = rejection ? subTotal - stripeFee : subTotal;
  const totalText = rejection ? `${t("totalToRefund")}:` : `${t("total")}:`;

  const CostBreakdownRender = ({ title = "", cost = 0 }) => (
    <div className="FundQuestApplicant__QuestInfo__CostBreakdown">
      <div className="FundQuestApplicant__QuestInfo__CostBreakdown__Item">
        {title}
      </div>
      <div className="FundQuestApplicant__QuestInfo__CostBreakdown__Cost">
        ${rounding(cost)}
      </div>
    </div>
  );

  const StatRender = ({ title = "", stat = 0 }) => (
    <div className="FundQuestApplicant__ApplicantInfo__Stats__Stat">
      <div className="FundQuestApplicant__ApplicantInfo__Stats__Stat__Stat">
        {stat}
      </div>
      <div className="FundQuestApplicant__ApplicantInfo__Stats__Stat__Title">
        {title}
      </div>
    </div>
  );

  return (
    <div className="FundQuestApplicant">
      <div className="FundQuestApplicant__QuestInfo">
        <div className="FundQuestApplicant__QuestInfo__Title">{titleLang}</div>
        <div className="FundQuestApplicant__QuestInfo__Divider" />
        <CostBreakdownRender title={`${t("streamerFee")}:`} cost={seatPrice} />
        <CostBreakdownRender
          title={`${t("noizFee")}:`}
          cost={noizCut * seatPrice}
        />
        {!rejection && (
          <CostBreakdownRender
            title={`${t("transactionFee")}:`}
            cost={stripeFee}
          />
        )}
        <div className="FundQuestApplicant__QuestInfo__Divider" />
        <div className="FundQuestApplicant__QuestInfo__Total">
          <div className="FundQuestApplicant__QuestInfo__Total__Item">
            {totalText}
          </div>
          <div className="FundQuestApplicant__QuestInfo__Total__Cost">
            ${rounding(total)}
          </div>
        </div>
        <CustomCheckbox
          header={handleAgreeToTerms}
          name="showFundQuestModal"
          value={agreedToTerms}
          onChange={() => setAgreedToTerms(!agreedToTerms)}
        />
        <div
          className={`FundQuestApplicant__QuestInfo__Button ${
            !agreedToTerms ? "disabled" : "null"
          }`}
          disabled={!agreedToTerms}
          onClick={agreedToTerms ? checkout : null}
        >
          {t("checkout")}
        </div>
      </div>
      <div className="FundQuestApplicant__ApplicantInfo">
        <img
          className="FundQuestApplicant__ApplicantInfo__Img"
          src={getUserAvatar(user)}
          alt="avatar"
        />
        <div className="FundQuestApplicant__ApplicantInfo__Name">
          {displayName || username}
        </div>
        <div className="FundQuestApplicant__ApplicantInfo__FeeText">
          STREAMER FEE:
        </div>
        <div className="FundQuestApplicant__ApplicantInfo__FeeAmount">
          ${seatPrice}
        </div>
        <div className="FundQuestApplicant__ApplicantInfo__Stats">
          <StatRender title={t("accv")} stat={nFormatter(avgViewers)} />
          <StatRender title={t("followers")} stat={nFormatter(followers)} />
        </div>
        <SetViewContext.Consumer>
          {({ setView, view }) => (
            <div
              className="FundQuestApplicant__ApplicantInfo__ViewProfile"
              onClick={() =>
                setView({
                  tab: view.tab,
                  component: "StreamerProfile",
                  currentUser: username,
                })
              }
            >
              {t("viewProfile")}
            </div>
          )}
        </SetViewContext.Consumer>
      </div>
    </div>
  );
};

export default FundQuestApplicant;
