import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
// import Tooltip from '@material-ui/core/Tooltip';

import { simpleNotification } from "../../../../../utils/notifications";
import { makeProper, getQueryString } from "../../../../../utils/functions";
import {
  getUserBrandPaymentMethods,
  getAllAgreements,
  putAgreement,
} from "../../../../../utils/apiv2";
import { SetViewContext } from "../../PortalContext";

import Eula from "../../../../../global/text/brand-quest-eula";
import AddImage from "../../CustomInputs/AddImage";
import CustomDate from "../../CustomInputs/CustomDate";
import CustomDropdown from "../../CustomInputs/CustomDropdown";
import CustomModal from "../../CustomInputs/CustomModal";
import CustomNumber from "../../CustomInputs/CustomNumber";
import CustomTextArea from "../../CustomInputs/CustomTextArea";
import CustomTextField from "../../CustomInputs/CustomTextField";
// import Icon from '../../../../../global/images/icons/Icon';
import LargeToggle from "../../CustomInputs/LargeToggle";
import Loading from "../../../../../global/components/loading/Loading";
import TrackingTags from "./TrackingTags";
import moment from "moment";

import "./QuestEdit.scss";

const today = new Date();
const defaultForm = {
  cover: "",
  endDateTime: today,
  description: "",
  game: "",
  minimumAverageViewers: 25,
  openSpots: 10,
  overlayImage: "",
  paymentAmount: 0.5,
  requiredBanner: "",
  requiredStreamTimeMinutes: 60,
  requirements: {
    beforeStream: [],
    duringStream: [],
    afterStream: [],
  },
  startDateTime: today,
  status: "disabled",
  title: "",
  trackingTags: [],
};

const QuestEdit = (props) => {
  const {
    brandUser: {
      games: {
        data: { games: products = [] },
        errors: { hasError: productError, message: productErrorMessage },
        isLoading: productLoading,
      },
      preSignedUrl,
      quests: {
        data: { quests = [] },
        errors: { hasError: questError, message: questErrorMessage },
        isLoading: questLoading,
        isUpdating: questUpdating,
      },
      user: { id: userId },
    },
    brandUserUploadImageUrl,
    // currentProduct,
    currentQuest,
    // getBrandGame,
    // getBrandQuest,
    postBrandQuest,
    putBrandQuest,
    tab,
    token,
  } = props;

  const [brandAgreements, setBrandAgreements] = useState(null);
  const [brandAgreementsLoading, setBrandAgreementsLoading] = useState(false);
  const [brandAgreementsModal, setBrandAgreementsModal] = useState(false);
  const [brandAgreementSubmitLoading, setBrandAgreementSubmitLoading] =
    useState(false);
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [goLive, setGoLive] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [tracking, setTracking] = useState(false);
  const { t } = useTranslation("portalLeft_questEdit");

  const getPaymentMethods = async () => {
    setPaymentLoading(true);
    const {
      data: { data: paymentInfo },
    } = (await getUserBrandPaymentMethods()) || { data: [] };
    if (paymentInfo) setPaymentMethods(paymentInfo);
    setPaymentLoading(false);
  };

  const getAgreements = async () => {
    setBrandAgreementsLoading(true);
    const { data: agreements } = await getAllAgreements();

    if (!!agreements) {
      setBrandAgreements(
        agreements.find(
          (agreement) =>
            agreement.type === "quest" && agreement.version === currentQuest
        )
      );
    }
    setBrandAgreementsLoading(false);
  };

  const isLoading =
    productLoading || questLoading || paymentLoading || brandAgreementsLoading;

  const getProduct = () => {
    const productId = getQueryString("currentProduct");
    if (productId) {
      setForm({ ...defaultForm, game: productId });
    }
    return productId;
  };
  useEffect(() => {
    getAgreements();
    getPaymentMethods();
    getProduct();
  }, []);

  useEffect(() => {
    if (currentQuest) {
      const foundQuest = quests.find((q) => q.id.toString() === currentQuest);
      if (foundQuest) {
        setForm(foundQuest);
        setGoLive(foundQuest.status === "active");
        if (foundQuest?.trackingTags?.length > 0) {
          setTracking(true);
        }
      }
    } else {
      getProduct();
    }
  }, [currentQuest]);

  useEffect(() => {
    if (currentQuest) {
      const foundQuest = quests.find((q) => q.id.toString() === currentQuest);
      if (foundQuest) {
        setForm(foundQuest);
        setGoLive(foundQuest.status === "active");
        if (foundQuest?.trackingTags?.length > 0) {
          setTracking(true);
        }
      }
    }
  }, [quests.length]);

  useEffect(() => {
    if (productError || questError) {
      simpleNotification({
        level: "error",
        title: t("error"),
        message:
          productErrorMessage || questErrorMessage || t("somethingWrong"),
      });
    }
  }, [productError, questError]);

  useEffect(() => {
    if (!questError) {
      const foundQuest = quests.find((q) => q.title === form.title);

      if (foundQuest) {
        simpleNotification({
          level: "success",
          title: t("success"),
          message: `${t("quest")} ${
            currentQuest ? t("updated") : t("created")
          } ${t("successfully")}`,
        });

        if (!currentQuest) {
          window.location.replace(
            `/portal?tab=${tab}&component=QuestEdit&currentQuest=${foundQuest.id}`
          );
        }
      }
    }
  }, [questLoading, questUpdating]);

  const handleFormChange = (name, value) => {
    if (name) {
      let isBefore = false;
      if (name === "startDateTime" && form.endDateTime) {
        isBefore = moment(moment(value, "YYYY-MM-DD")).isBefore(
          moment(form.endDateTime, "YYYY-MM-DD")
        );
        if (!isBefore) {
          setForm((prevState) => ({
            ...prevState,
            startDateTime: new Date(form.endDateTime),
            endDateTime: value,
          }));
          return;
        }
      } else if (name === "endDateTime" && form.startDateTime) {
        isBefore = moment(moment(form.startDateTime, "YYYY-MM-DD")).isBefore(
          moment(value, "YYYY-MM-DD")
        );
        if (!isBefore) {
          setForm((prevState) => ({
            ...prevState,
            startDateTime: value,
            endDateTime: new Date(form.startDateTime),
          }));
          return;
        }
      }

      console.log("isBefore", isBefore, value);
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (errors.includes(name)) {
      setErrors(errors.filter((error) => error !== name));
    }
  };

  const handleImageRequirement = (name, value) => {
    if (name) {
      let reqSubtype = {};

      switch (name) {
        case "overlayImage":
          reqSubtype = {
            helpLink:
              "https://howto.streamlabs.com/streamlabs-obs-19/game-overlay-for-streamlabs-obs-3752",
            type: "overlay",
            tooltipText: t("anOverlay"),
          };
          break;
        case "requiredBanner":
          reqSubtype = {
            helpLink:
              "https://help.twitch.tv/s/article/how-to-edit-info-panels?language=en_US",
            type: "banner",
            tooltipText: t("aPanel"),
          };
          break;
        default:
          break;
      }

      if (reqSubtype) {
        const newRequirements = form.requirements.duringStream?.filter(
          (requirement) => requirement.subtype !== reqSubtype.type
        );

        if (!!value) {
          const newRequirement = {
            approvalRequired: false,
            customIcon: false,
            description: `<p>${t("newRequirement.description1")} ${
              reqSubtype.type
            } ${t("newRequirement.description2")}</p>`,
            icon: false,
            id: uuidv4().replace(/-/g, ""),
            showOnApp: true,
            subtype: reqSubtype.type,
            title: makeProper(reqSubtype.type),
            tooltip: `<p>${t("newRequirement.tooltip1")} <a href="${
              reqSubtype.helpLink
            }" target="_blank" rel="noopener">${t(
              "newRequirement.tooltip2"
            )}</a> ${t("newRequirement.tooltip3")} ${
              reqSubtype.tooltipText
            } ${t("newRequirement.tooltip4")}</p>`,
            type: "display-download",
          };

          newRequirements.push(newRequirement);
        }

        setForm((prevState) => ({
          ...prevState,
          [name]: value,
          requirements: {
            ...prevState.requirements,
            duringStream: newRequirements,
          },
        }));
      }
    }
  };

  const handleAgreementSubmit = async () => {
    setBrandAgreementSubmitLoading(true);
    const { data } = await putAgreement("quest", currentQuest);

    if (data) {
      setBrandAgreementsModal(false);
      setBrandAgreements(data);
      setGoLive(true);
      setForm((prevState) => ({
        ...prevState,
        status: "active",
      }));
    }

    setBrandAgreementSubmitLoading(false);
  };

  const handleGoLive = () => {
    const newLive = !goLive;
    const status = newLive ? "active" : "disabled";

    if (newLive && !brandAgreements) {
      setBrandAgreementsModal(true);
    } else {
      setGoLive(newLive);
      setForm((prevState) => ({
        ...prevState,
        status: status,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateQuestForm(form) || [];

    setErrors(validationErrors);

    if (validationErrors?.length === 0) {
      if (!tracking) {
        form.trackingTags = [];
      }
      if (form?.game?.id) {
        form.game = form.game.id;
      }
      if (
        form?.platforms?.length > 0 &&
        typeof form.platforms[0] !== "string"
      ) {
        form.platforms = form.platforms.map((p) => p.id);
      }

      if (currentQuest) {
        putBrandQuest(form, token);
      } else {
        form.owner = userId;
        postBrandQuest(form, token);
      }
    }
  };

  if (isLoading) {
    return <Loading isBrand />;
  }

  if (!paymentMethods.length) {
    return (
      <div className="QuestEdit">
        <div className="QuestEdit__Title">{t("questEditTitle")}</div>
        <SetViewContext.Consumer>
          {({ setView }) => (
            <div className="QuestEdit__Submit_Area">
              <div
                className="QuestEdit__Button"
                onClick={() =>
                  setView({
                    component: "Profile",
                  })
                }
              >
                {t("updateProfile")}
              </div>
            </div>
          )}
        </SetViewContext.Consumer>
      </div>
    );
  }

  const foundProduct =
    products.find((p) => p.id === (form?.game?.id || form?.game)) || {};
  const gallery = foundProduct.gallery?.filter((i) => i.type === "image") || [];
  if (foundProduct.cover) {
    gallery.push({ type: "image", url: foundProduct.cover });
  }
  if (foundProduct.coverPortrait) {
    gallery.push({ type: "image", url: foundProduct.coverPortrait });
  }
  if (foundProduct.developerLogo) {
    gallery.push({ type: "image", url: foundProduct.developerLogo });
  }
  if (foundProduct.logo) {
    gallery.push({ type: "image", url: foundProduct.logo });
  }

  return (
    <div className="QuestEdit">
      {brandAgreementsModal && (
        <CustomModal
          open={brandAgreementsModal}
          clickOutsideToClose
          content={
            <div className="QuestEdit">
              <div className="QuestEdit__Title">
                {t("endUser")}
                <br /> {t("licenseAgreement")}
              </div>
              <div className="QuestEdit__Agreement">
                <div className="QuestEdit__Agreement__Text">
                  <Eula></Eula>
                </div>
              </div>
              <div className="QuestEdit__Submit_Area">
                <div
                  className="QuestEdit__Button"
                  onClick={handleAgreementSubmit}
                >
                  {brandAgreementSubmitLoading ? (
                    <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                  ) : (
                    t("agree")
                  )}
                </div>
              </div>
            </div>
          }
          onClose={() => setBrandAgreementsModal(false)}
        />
      )}
      <div className="QuestEdit__Title">
        {currentQuest ? t("edit") : t("add")} {t("quest")}
        {currentQuest && (
          <LargeToggle
            header={t("goLive")}
            onChange={handleGoLive}
            value={goLive}
          />
        )}
      </div>
      <CustomDropdown
        error={errors.includes("game")}
        header={t("chooseProduct")}
        onChange={handleFormChange}
        options={products.map((product) => ({
          optionName: product.name,
          optionValue: product.id,
        }))}
        name="game"
        value={form.game}
      />
      {form.game && (
        <>
          <CustomTextField
            error={errors.includes("title")}
            header={t("questTitle")}
            onChange={handleFormChange}
            name="title"
            value={form.title}
          />
          <AddImage
            brandUserUploadImageUrl={brandUserUploadImageUrl}
            error={errors.includes("cover")}
            gallery={gallery}
            header={t("uploadQuestCover")}
            onChange={handleFormChange}
            name="cover"
            preSignedUrl={preSignedUrl}
            token={token}
            value={form.cover}
          />
          <div className="QuestEdit__DateFields">
            <CustomDate
              error={errors.includes("startDateTime")}
              header={t("startDate")}
              onChange={handleFormChange}
              name="startDateTime"
              value={form.startDateTime}
            />
            <CustomDate
              error={errors.includes("endDateTime")}
              header={t("endDate")}
              onChange={handleFormChange}
              name="endDateTime"
              value={form.endDateTime}
            />
          </div>
          <CustomNumber
            error={errors.includes("paymentAmount")}
            header={t("rewardStreamer")}
            onChange={handleFormChange}
            max={Number(99999)}
            min={Number(0.5)}
            name="paymentAmount"
            step={Number(0.01)}
            value={form.paymentAmount}
          />
          <div className="QuestEdit__AverageViewers__Header">
            {t("minimumACCV")} &nbsp;
            {/* <Tooltip placement='top' title='Average Concurrent Viewers'>
              <Icon name='questioncircle' />
            </Tooltip> */}
          </div>
          <CustomNumber
            error={errors.includes("minimumAverageViewers")}
            max={Number(99999)}
            min={Number(0)}
            name="minimumAverageViewers"
            onChange={handleFormChange}
            value={form.minimumAverageViewers}
          />
          <CustomNumber
            error={errors.includes("openSpots")}
            header={t("openSpots")}
            max={Number(99999)}
            min={Number(1)}
            name="openSpots"
            onChange={handleFormChange}
            value={form.openSpots}
          />
          <CustomNumber
            error={errors.includes("requiredStreamTimeMinutes")}
            header={t("streamDuration")}
            max={Number(99999)}
            min={Number(1)}
            name="requiredStreamTimeMinutes"
            onChange={handleFormChange}
            value={form.requiredStreamTimeMinutes}
          />
          <CustomTextArea
            error={errors.includes("description")}
            header={t("questInstructions")}
            onChange={handleFormChange}
            name="description"
            value={form.description}
          />
          <AddImage
            brandUserUploadImageUrl={brandUserUploadImageUrl}
            error={errors.includes("requiredBanner")}
            gallery={gallery}
            header={t("uploadQuestBanner")}
            onChange={handleImageRequirement}
            name="requiredBanner"
            preSignedUrl={preSignedUrl}
            token={token}
            value={form.requiredBanner}
          />
          <AddImage
            brandUserUploadImageUrl={brandUserUploadImageUrl}
            error={errors.includes("overlayImage")}
            gallery={gallery}
            header={t("uploadQuestOverlay")}
            onChange={handleImageRequirement}
            name="overlayImage"
            preSignedUrl={preSignedUrl}
            token={token}
            value={form.overlayImage}
          />
          <div className="QuestEdit__Tracking">
            <div className="QuestEdit__Tracking__Header">{t("tracking")}</div>
            <LargeToggle
              header={t("trackByHashtag")}
              onChange={(name, value) => setTracking(value)}
              value={tracking}
            />
            {tracking && (
              <TrackingTags
                onChange={handleFormChange}
                value={form.trackingTags}
                style={{
                  marginLeft: "-23px",
                  marginRight: "-23px",
                }}
              />
            )}
          </div>
          <div className="QuestEdit__Submit_Area">
            <div className="QuestEdit__Button" onClick={handleSubmit}>
              {t("saveQuest")}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const validateQuestForm = (form) => {
  const errors = [];

  if (
    !form.game ||
    (typeof form.game === "string" && form.game?.trim() === "")
  ) {
    errors.push("game");
  }

  if (!form.title || form.title?.trim() === "") {
    errors.push("title");
  }

  if (!form.startDateTime) {
    errors.push("startDateTime");
  }

  if (!form.endDateTime) {
    errors.push("endDateTime");
  }

  if (!form.paymentAmount) {
    errors.push("paymentAmount");
  }

  // if (!form.minimumAverageViewers || form.minimumAverageViewers?.trim() === '') {
  //   errors.push('minimumAverageViewers');
  // }

  // if (!form.openSpots || form.openSpots?.trim() === '') {
  //   errors.push('openSpots');
  // }

  // if (!form.requiredStreamTimeMinutes || form.requiredStreamTimeMinutes?.trim() === '') {
  //   errors.push('requiredStreamTimeMinutes');
  // }

  if (!form.description || form.description?.trim() === "") {
    errors.push("description");
  }

  return errors;
};

export default QuestEdit;
