/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Icon from "../../images/icons/Icon";

import "./BrowserBack.scss";

const BrowserBack = ({
  color = "white",
  height = "24px",
  hover = true,
  margin = {},
  router,
  text = "",
  refresh = false,
}) => {
  const { t } = useTranslation("components");
  text = t("back");

  return (
    <span
      className={classnames("BrowserBack", hover && "Hover")}
      // eslint-disable-next-line no-restricted-globals
      onClick={() => {
        window.history.back();
      }}
      style={{ height, ...margin }}
    >
      <Icon name="anglesleft" color={color} />
      {text ? <span className="text">&nbsp;{text}</span> : null}
    </span>
  );
};

export default BrowserBack;
