import React, { useEffect, useState } from "react";
import Calendar from "@material-ui/icons/CalendarTodayRounded";
import { useTranslation } from "react-i18next";

import BrowserBack from "../../../../global/components/browser-back/BrowserBack";
import IntercomPortal from "../mvp/IntercomPortal";
import LoadingGif from "../assets/LoadingGif";
import MetricCluster from "./metrics/MetricCluster";
import NothingHere from "../assets/NothingHere";
import ReportMetric from "./metrics/ReportMetric";
import ReportTable from "./report-table/ReportTable";
import StackedChart from "../../../../utils/charts/StackedChart";

import {
  getChatTopLineCampaign,
  getChatDetail,
  getTwitterToplineCampaign,
  getTopTweets,
  getChatGraphData,
} from "./ReportProcessing";
import { customDateAndHour } from "./ReportGraphUtils";
import {
  ExtraChatContent,
  ExtraTwitterContent,
} from "./metrics/DetailContentIndex";
import { isNull, tooManyZerosAndFalsey } from "../../../../utils/functions";
import { getBrandQuestSummary } from "../../../../utils/apiv2";

import "./Report.scss";

const SendHelpNoiz = () => {
  const { t } = useTranslation("portalFull_report");
  return (
    <div className="PortalReport_NeedHelp">
      {" "}
      <i className="fa fa-exclamation-circle" /> {t("portalReport_NeedHelp")}
    </div>
  );
};

const Report = (props) => {
  const [activeQuest, setActiveQuest] = useState(null);
  const [questLoading, setQuestLoading] = useState(null);
  const { t } = useTranslation("portalFull_report");

  const {
    brandUser: { brandUser, query = {} },
    getBUCampaign,
    token,
  } = props;

  const {
    campaign: {
      data: campaignData = {},
      isLoading: campaignIsLoading,
      errors: { hasError, message: errorMessage },
    },
    campaign: cpain,
  } = brandUser;

  const questOrCampaign = (b) => (b ? activeQuest : campaignData || null);

  const {
    chatData = {},
    endDateTime: endDate,
    hoursStreamed,
    name,
    streamActivityCampaign,
    startDateTime: startDate,
    tweetData,
    viewerMinutesWatched,
  } = questOrCampaign(!!activeQuest) || {};

  const { campaignId = "", questId } = query;
  const { hasError: campaignHasError } = cpain;

  const globalLoading = campaignIsLoading || questLoading;

  useEffect(() => {
    const getQuestSummary = async (questId) => {
      setQuestLoading(true);
      const response = await getBrandQuestSummary(questId);
      const quest = response?.data?.data;
      setActiveQuest(quest);
      setQuestLoading(false);
    };

    if (campaignId) {
      getBUCampaign(campaignId, null, null, token);
    }
    if (questId) {
      getQuestSummary(questId);
    }
  }, [campaignId, questId]);

  if (globalLoading) {
    return (
      <section className="PortalReport">
        <LoadingGif />
      </section>
    );
  }

  if (hasError || campaignHasError) {
    return (
      <section className="PortalReport">
        <BrowserBack />
        <NothingHere text={(hasError || campaignHasError) && errorMessage} />
      </section>
    );
  }

  const stackedData = [
    {
      label: t("ccv"),
      data: streamActivityCampaign,
      backgroundColor: "#3e71e1",
    },
    {
      label: t("chatMessages"),
      data: getChatGraphData(chatData),
      backgroundColor: "#a142ff",
    },
  ];

  const chatTopLine = getChatTopLineCampaign(chatData);
  const chatDetail = getChatDetail(chatData);
  const twitterTopLine = getTwitterToplineCampaign(tweetData);

  let renderHelpMe = false;
  if (name) {
    renderHelpMe = tooManyZerosAndFalsey([
      viewerMinutesWatched,
      hoursStreamed,
      ...chatTopLine.map((item) => item.value),
      ...twitterTopLine.map((item) => item.value),
    ]);
  }

  return (
    <section className="PortalReport">
      <BrowserBack text="Back" />
      <section className="PortalReport_HeaderCal">
        <h1>{globalLoading ? "Loading..." : name}</h1>
        <div className="PortalReport_Calendar">
          <div className="PRC_Cal_Dark">
            <div className="PRC_Cal_Item">
              <Calendar />
            </div>
            <div className="PRC_Cal_Item">
              {`${
                customDateAndHour(startDate || new Date(), true).split(",")["0"]
              } - ${customDateAndHour(endDate, true)}`}{" "}
            </div>
          </div>
        </div>
      </section>
      <h2>{t("chatMessages")}</h2>
      {renderHelpMe && <IntercomPortal children={<SendHelpNoiz />} />}
      <section className="PortalReport_OverviewStats">
        <ReportMetric
          isLoading={globalLoading}
          extraClass="Overview_One"
          error={
            campaignHasError || (isNull(viewerMinutesWatched) && !globalLoading)
          }
          value={viewerMinutesWatched}
          metric={t("viewerMinutesWatched")}
          color="teal-gradient"
        />
        <ReportMetric
          isLoading={globalLoading}
          extraClass="Overview_Two"
          error={campaignHasError || (isNull(hoursStreamed) && !globalLoading)}
          value={hoursStreamed * 60}
          metric={t("totalMinutesStreamed")}
          color="purple-gradient"
        />
      </section>
      <section className="PortalReport_GraphContainer">
        <StackedChart
          isLoading={globalLoading}
          style={{ width: "100%", height: "100%" }}
          data={stackedData}
          labelY="CCV"
        />
      </section>
      <section className="PortalReport_MetricClusterGroup">
        <MetricCluster
          isLoading={globalLoading}
          error={campaignHasError}
          title="Chat"
          color="pink-gradient"
          hasExtraContent={true}
          metrics={chatTopLine}
          children={
            <ExtraChatContent data={chatDetail[0]} titles={chatDetail[1]} />
          }
          textColor="white"
        />
        <MetricCluster
          isLoading={globalLoading}
          error={campaignHasError}
          title="Twitter"
          color="blue-gradient"
          hasExtraContent={true}
          children={<ExtraTwitterContent data={getTopTweets(tweetData)} />}
          metrics={twitterTopLine}
          textColor="white"
        />
      </section>
      <ReportTable
        campaign={campaignId}
        token={token}
        quests={questId ? [questId] : []}
      />
    </section>
  );
};

export default Report;
