import React, { useState } from "react";
import classnames from "classnames";
import { useTranslation} from 'react-i18next'

import {
  getDateFormattedString,
  getTwitchAverageViewers,
  getUserAvatar,
  rounding,
} from "../../../../../utils/functions";
import { getRandomAvatar } from "../../../../../global/images/icons/Avatar";
import { SetViewContext } from "../../PortalContext";

import CustomModal from "../../CustomInputs/CustomModal";
import FundQuestApplicant from "./FundQuestApplicant";
import Icon from "../../../../../global/images/icons/Icon";
import Tooltip from "@material-ui/core/Tooltip";

import "./ViewAll.scss";

export const ApplicantCard = props => {
  const {
    display,
    userQuest: {
      id: userQuestId,
      user,
      user: {
        displayName,
        // id: userId,
        userGame30,
        username,
      },
      createdAt,
      status
    },
    postBrandPreapproval,
    token,
    type,
    userQuest = {},
  } = props;

  const { t} = useTranslation('portalLeft')
  const [fundQuestModalOpen, setFundQuestModalOpen] = useState(false);
  const [showFundQuestModal, setShowFundQuestModal] = useState(true);
  const isQuestCompleted = status === 'completed'

  
  const handlePostApproval = (typePassed, userQuest) => {
      if (typePassed === 'approved' && showFundQuestModal) {
        setFundQuestModalOpen(true);
      } else if (typePassed === 'declined' && type === 'approved') {
        setFundQuestModalOpen(true)
      } else if (typePassed === 'declined' && type === 'pending') {
        postBrandPreapproval([userQuest.id], "declined", token);
      }
   };

  const ccv = getTwitchAverageViewers(user);
  const dateString = getDateFormattedString(createdAt);
  const avatarUrl = getUserAvatar(user);
  const gameString = userGame30?.[0]?.gameString;
  const typeSwitch = type === 'approved' ? 'declined' : 'approved';

  return (
    <div className={classnames("ApplicantCard", display)}>
      {fundQuestModalOpen && (
        <CustomModal
          open={fundQuestModalOpen}
          onClose={() => {
            setFundQuestModalOpen(false);
            if (!showFundQuestModal) {
              setShowFundQuestModal(true);
            }
          }}
          content={
            <FundQuestApplicant
              checkout={() =>
                postBrandPreapproval([userQuest.id], typeSwitch, token)
              }
              rejection={type === "approved"}
              handleFundQuestModalChange={() => {
                const newValue = !showFundQuestModal;
                setShowFundQuestModal(newValue);
                localStorage.setItem(
                  `showFundQuestModal-${userQuest.quest}`,
                  newValue
                );
              }}
              showFundQuestModal={showFundQuestModal}
              uq={userQuest}
            />
          }
        />
      )}
      <div className={classnames("ApplicantCard__Left", display)}>
        {display === "grid" && (
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <div
                className="ApplicantCard__Icon"
                onClick={() =>
                  setView({
                    ...view,
                    component: "StreamerProfile",
                    currentUser: username,
                  })
                }
              >
                <Icon name="user" />
              </div>
            )}
          </SetViewContext.Consumer>
        )}
        <img
          className={classnames("ApplicantCard__Img", display)}
          src={avatarUrl}
          alt={displayName}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = getRandomAvatar();
          }}
        />
        <div className={classnames("ApplicantCard__Info", display)}>
          <div className="ApplicantCard__Info__Name">{displayName}</div>
          <div className="ApplicantCard__Info__CCV">
            {rounding(ccv, 1)} {t("ccv")}
          </div>
          <div className="ApplicantCard__Info__Known">
            <div className="ApplicantCard__Info__Known__Text">
              {t("knownFor")}:&nbsp;
            </div>
            <div className="ApplicantCard__Info__Known__Game">{gameString}</div>
          </div>
          <div className="ApplicantCard__Info__Date">{dateString}</div>
        </div>
      </div>
      <div className={classnames("ApplicantCard__Buttons", display)}>
        {display === "list" && type === "approved" && (
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <Tooltip title={t("viewDeliverables")} placement="top">
                <div
                  className="ApplicantCard__Buttons__Deliverable"
                  onClick={() =>
                    setView({
                      ...view,
                      component: "ReviewDeliverable",
                      currentUserQuest: userQuestId,
                    })
                  }
                >
                  <i className="fa fa-list"></i>
                </div>
              </Tooltip>
            )}
          </SetViewContext.Consumer>
        )}
        {display === "list" && (
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <Tooltip title={t("viewProfile")} placement="top">
                <div
                  className="ApplicantCard__Buttons__View"
                  onClick={() =>
                    setView({
                      ...view,
                      component: "StreamerProfile",
                      currentUser: username,
                    })
                  }
                >
                  <i className="fa fa-user"></i>
                </div>
              </Tooltip>
            )}
          </SetViewContext.Consumer>
        )}
        {(type === "pending" || type === "rejected") && (
          <div
            className={`ApplicantCard__Buttons__Button ApproveButton ${display}`}
            onClick={() => handlePostApproval("approved", userQuest)}
          >
            {display === "list" && <Icon name="check" />}
            {display === "grid" && <>{t("approve")}</>}
          </div>
        )}
        {/* {(type === "pending" || type === "approved") && (
          <div
            className={`ApplicantCard__Buttons__Button RejectButton ${display}`}
            onClick={() => handlePostApproval("declined", userQuest)}
          >
            {display === "list" && <Icon name="times" />}
            {display === "grid" && <>{t("reject")}</>}
          </div>
        )} */}
        {(type === "pending") && (
          <div
            className={classnames(
              `ApplicantCard__Buttons__Button RejectButton ${display}`,
              { disabled: isQuestCompleted }
            )}
            onClick={() => !isQuestCompleted && handlePostApproval("declined", userQuest)}
          >
            {display === "list" && <Icon name="times" />}
            {display === "grid" && <>{t("reject")}</>}
          </div>
        )}
      </div>
    </div>
  );
};

export const NoApplicantsCard = () => {
  const { t } = useTranslation('portalLeft')

    return <div className = "NoApplicantsCard" > { t("noApplicants") }</div>
}
