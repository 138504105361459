import React, { useState,useEffect } from "react";

import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { getBankCardApi, completeQuestPayApi } from "../../../../utils/apiv2";
import Loading from "../../../../global/components/loading/Loading";
import classNames from "classnames";


import "./ReadyPayModal.scss";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    boxShadow: "none",
    outline: "none",
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: "33px",
    color: "white",
    width: "658px",
    background: "#384860",
    borderRadius: "8px",
    // maxWidth: '30%',
  },
  amount: {
    padding: "45px 0",
    borderTop: "2px solid #4E6180",
    borderBottom: "2px solid #4E6180",
    fontSize: "48px",
    fontWeight: "bold",
    textAlign: "center",
  },
  mainContainer: {
    width: "100%",
    height: "100%",
    padding: "40px 40px",
  },
  confirmButton: {
    backgroundColor: "green",
    width: "538px",
    height: "76px",
    background: "linear-gradient(180deg, #366DD5 0%, #8158EC 100%);",
    borderRadius: "38px",
    color: "#fff",
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "none",
    "&:hover": {
      opacity: 0.8,
    },
  },
  buttonGrid: {
    width: "100%",
    marginTop: "30px",
    justifyContent: "center",
  },
  title: {
    fontWeight: 600,
    fontSize: "32px",
    textAlign: "center",
    margin: "22px 0",
    position: "relative",
  },
  close: {
    position: "absolute",
    right: 0,
    top: "0px",
    fontSize: "40px",
    cursor: "pointer",
  },
}));
export default function ReadyPayModal(props) {
  const classes = useStyles();

  const { fetchPayment, userName, payment, userId, userQuestId,paid } = props;
  //   const { averageViewersAtJoin, viewerTiers, memberTiers, user } = props;
  const { t } = useTranslation("readyPayModal");
  const [open, setOpen] = React.useState(false);
  const [commitCheck, setCommitCheck] = useState(false);
  const [banCard, setBankCard] = useState([])
  const [loading,setLoading] = useState(true)

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    setCommitCheck(true);
    try {
      await completeQuestPayApi({ userQuestId});
      await fetchPayment();
    } catch (error) {
      console.log("error", error);
    } finally {
      setCommitCheck(false);
    }

    handleClose();
  };

  useEffect(() => {
    if (open) getBankCard();
  }, [open]);
  
  const getBankCard = async () => { 
    try {
      setLoading(true)
      let { data } = await getBankCardApi(userId);
    
      setBankCard(data.data || []);
    } catch (err) {

    } finally { 
      setLoading(false)
    }
  }

  return (
    <div>
      <Tooltip placement="top" title={t("transfer")}>
        <button className={classNames("small-btn", {forbid: paid})} onClick={() => !paid&&handleOpen()}>
          <span style={{ fontSize: "14px" }}>$</span>
        </button>
      </Tooltip>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <Fade in={open}>
            <Paper className={classes.paper}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                align="flex-start"
                spacing={4}
              />
              <Grid className={classes.title}>
                {t("transfer")}
                <CloseIcon
                  className={classes.close}
                  onClick={handleClose}
                ></CloseIcon>
              </Grid>

              <Grid className={classes.amount}>{payment}</Grid>

              <Grid className="item user">
                <div>{t("user")}:</div>
                <div>{userName}</div>
              </Grid>

              <Grid className="item card">
                <div>{t("card")}:</div>
                <div>
                  {"**** ".repeat(3)}
                  {banCard[0]?.last4}
                </div>
              </Grid>

              <Grid
                container
                className={classes.buttonGrid}
                direction="row"
                width="100%"
                alignItems="center"
              >
                <Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.confirmButton}
                    onClick={onSubmit}
                    disabled={commitCheck}
                  >
                    {t("confirm")}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Fade>
        )}
      </Modal>
    </div>
  );
}
