/* eslint max-len: ["error", { "code": 800 }] */
import React from "react";
import "./CareersPost.scss";
import { withTranslation } from "react-i18next";

class CampaignManager extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <section className="CareersPost">
        <section className="CareersPost__container">
          <section className="CareersPostSummary">
            <section className="CareersPostSummary__content">
              <section className="PostingItem">
                <a
                  className="PostingItem__apply-btn"
                  href="mailto:angie@noiz.gg"
                >
                  {t("applyJob")} <i className="fa fa-envelope" />
                </a>
                <section className="PostingItem__wrapper">
                  <h4 className="PostingItem__title">{t("title")}</h4>
                  <section className="PostingItem__categories">
                    <span className="PostingItem__categories--location">
                      {t("sub_title")}
                    </span>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section className="CareersPostDetails">
            <section className="CareersPostDetails__content">
              <div>
                <p>
                  <strong>{t("section_1.title")}</strong>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    {t("section_1.content")}
                  </span>
                </p>

                <p>
                  <strong>{t("section_2.title")}</strong>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    {t("section_2.content")}
                  </span>
                </p>

                <p>
                  <strong>{t("section_3.title")}</strong>
                </p>
                <ul>
                  {Array.from({ length: 5 }).map((item, index) => (
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        {t(`section_3.content.${index}`)}
                      </span>
                    </li>
                  ))}
                </ul>

                <p>
                  <strong>{t("section_4.title")}</strong>
                </p>
                <ul>
                  {Array.from({ length: 12 }).map((item, index) => (
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        {t(`section_4.content.${index}`)}
                      </span>
                    </li>
                  ))}
                </ul>

                <p>
                  <strong>{t("section_5.title")}</strong>
                </p>
                <ul>
                  {Array.from({ length: 10 }).map((item, index) => (
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        {t(`section_4.content.${index}`)}
                      </span>
                    </li>
                  ))}
                </ul>

                <p>
                  <span style={{ fontWeight: 400 }}>{t("section_6")}</span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>{t("section_7")}</span>
                </p>

                <p>
                  <span style={{ fontWeight: 400 }}>{t("section_8")}</span>
                </p>

                <h3>
                  <strong>{t("section_9.title")}</strong>
                </h3>
                <p>
                  <span>{t("section_9.content")}</span>
                </p>
                <h3>
                  <strong>{t("section_10.title")}</strong>
                </h3>
                <ul>
                  {Array.from({ length: 3 }).map((item, index) => (
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        {t(`section_10.content.${index}`)}
                      </span>
                    </li>
                  ))}
                </ul>
                <h3>
                  <strong>{t("section_11.title")}</strong>
                </h3>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    {t("section_11.content")}
                  </span>
                </p>

                <h3>
                  <strong>{t("section_12.title")}</strong>
                </h3>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      {t("section_12.content")}&nbsp;
                    </span>
                  </li>
                </ul>
              </div>

              <p className="CareersPostDetails__content--cta">
                {t("email")}{" "}
                <a href="mailto:angie@noiz.gg">angie@noiz.gg</a>
              </p>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation("campaignManager")(CampaignManager);
