/* eslint-disable react/no-did-update-set-state */
import React from "react";
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { find, isArray } from "lodash";
import classNames from "classnames";
import { Link } from "react-router";
// import {Link} from 'react-router'
import "./SearchGames.scss";
import { isAuthenticated } from "../../utils/authChecker";
// import { simpleNotification } from '../../utils/notifications';
// import { isAuthenticated } from '../../utils/authChecker';

import IconParticles from "../icon-particles/IconParticles";
import { withTranslation } from "react-i18next";

class SearchGames extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      items: [],
      initialItems: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (isAuthenticated()) {
      const { token, user } = this.props.auth;
      const isBasicInfoCompleted =
        user.firstName && user.lastName && user.country;
      if (!isBasicInfoCompleted) {
        this.props.router.replace("/complete-registration");
      } else {
        this.props.getGameList(token);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.config.games.isLoading &&
      !this.props.config.games.isLoading
    ) {
      this.setState({
        initialItems: this.props.config.games.data,
        items: this.props.config.games.data,
      });
    }
  }

  onChangeSearch = (event) => {
    let updatedList = this.state.initialItems;
    updatedList = updatedList.filter(
      (item) =>
        item.name
          .toLowerCase()
          .trim()
          .search(event.target.value.toLowerCase().trim()) !== -1 ||
        item.description
          .toLowerCase()
          .trim()
          .search(event.target.value.toLowerCase().trim()) !== -1 ||
        item.developer
          .toLowerCase()
          .trim()
          .search(event.target.value.toLowerCase().trim()) !== -1
    );
    this.setState({ items: updatedList });
  };

  generateGameHTML() {
    const games = this.state.items;
    const { isLoading } = this.props.config.games;
    const { t } = this.props;
    if (games.length > 0 && !isLoading) {
      return games.map((game, i) => (
        <Link
          key={game.id}
          to={`/game/${game.id}`}
          href={`/game/${game.id}`}
          className="GameListCard"
          aria-hidden
          onClick={this.handleMobileTap}
        >
          <section className="GameListCard__wrapper">
            <section className="GameListCard__art-wrapper">
              <figure
                className="GameListCard__cover"
                style={{ backgroundImage: `url(${game.cover})` }}
                alt="Game Cover"
              />
              <figure
                className="GameListCard__logo"
                style={{ backgroundImage: `url(${game.logo})` }}
                alt="Game Logo"
              />
            </section>
            <div className="GameListCard__available">{t("available")}</div>
            <div className="GameListCard__info">
              <div className="GameListCard__description">
                {t("description")}.
              </div>
              <div className="GameListCard__status">
                <div className="GameListCard__status-label">
                  {t("gameStatus")}:
                </div>
                <ul>
                  {game.quests &&
                    find(
                      game.quests,
                      (questItem) => questItem.type === "early access"
                    ) && (
                      <li>
                        <i className="noiz-game-controller" />{" "}
                        {t("earlyGameAccess")}{" "}
                      </li>
                    )}

                  {game.quests &&
                    find(
                      game.quests,
                      (questItem) =>
                        questItem.type === "influencers early access"
                    ) && (
                      <li>
                        <i className="noiz-partner" /> {t("partnerProgram")}{" "}
                      </li>
                    )}

                  {game.quests &&
                    find(
                      game.quests,
                      (questItem) => questItem.type === "alpha feedback"
                    ) && (
                      <li>
                        <i className="noiz-thumbs-up" /> {t("alphaFeedback")}{" "}
                      </li>
                    )}

                  {game.quests &&
                    find(
                      game.quests,
                      (questItem) => questItem.type === "paid bounty"
                    ) && (
                      <li>
                        <i className="noiz-video-camera" />{" "}
                        {t("liveBountyEvent")}
                        7/28{" "}
                      </li>
                    )}

                  <li>
                    <i className="noiz-scroll" />{" "}
                    {isArray(game.quests) ? game.quests.length : 0}{" "}
                    {t("questsAvailable")}
                  </li>
                </ul>
              </div>
              <div className="GameListCard__footer">
                <span>{t("seeGame")}</span>
              </div>
            </div>
          </section>
        </Link>
      ));
    }
    // If no active games show this message
    return <div className="NoGames">{t("noGames")}</div>;
  }

  render() {
    const { t } = this.props;
    return (
      <section className="SearchGames">
        <section className="SearchGames__container">
          <div className="SearchGames__content__extra" />
          <div className="SearchGames__content">
            <IconParticles />
            <div className="SearchGames__header">{t("header")}</div>
            <div className="SearchGames__fields">
              <div className="input-container active first-input-container">
                <div className="input-wrapper input-wrapper_search">
                  <i className="noiz-search" />
                  <input
                    autoComplete="off"
                    id="search"
                    type="text"
                    placeholder="Search for games"
                    onChange={this.onChangeSearch.bind(this)}
                    className={classNames("input_search", {})}
                  />
                </div>
              </div>

              {/* SHOULD BE REMOVED WHEN FILTERS ARE IMPLEMENTED */}
              <div style={{ flex: "8" }} />
              {/* SHOULD BE REMOVED WHEN FILTERS ARE IMPLEMENTED */}

              {/* <div className="input-container">
                <label>
                  Genre
                </label>
                <div className="input-wrapper">
                  <select
                    className={classNames({})}
                  >
                    <option className="first-value" value="">Show all genres</option>
                  </select>
                  <i className="fa fa-caret-down" />
                </div>
              </div>
              <div className="input-container">
                <label>
                  Features
                </label>
                <div className="input-wrapper">
                  <select
                    className={classNames({})}
                  >
                    <option className="first-value" value="">Show all features</option>
                  </select>
                  <i className="fa fa-caret-down" />
                </div>
              </div>
              <div className="input-container">
                <label>
                  Release
                </label>
                <div className="input-wrapper">
                  <select
                    className={classNames({})}
                  >
                    <option className="first-value" value="">Show all release dates</option>
                  </select>
                  <i className="fa fa-caret-down" />
                </div>
              </div>
              <div className="input-container">
                <label>
                  Company
                </label>
                <div className="input-wrapper">
                  <select
                    className={classNames({})}
                  >
                    <option className="first-value" value="">Show all companies</option>
                  </select>
                  <i className="fa fa-caret-down" />
                </div>
              </div> */}
            </div>
            <div className="SearchGames__games">{this.generateGameHTML()}</div>
            <div className="SearchGames__more">
              <button>{t("loadMoreGames")}</button>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

SearchGames.propTypes = {};

export default withTranslation("searchGames")(SearchGames);
