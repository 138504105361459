import { simpleNotification } from "../../../utils/notifications";
import { buildDashboard, chunk } from "./helpers";

import { t } from "i18next";
import {
  getCompleteUsersApi,
  getInsightsApi,
  getInitialDataApi,
} from "../../../utils/apiv2";

async function getCompleteUsers(payload, callback) {
  const { userIds } = payload;
  try {
    const response = await getCompleteUsersApi({
      userIds,
    });
    if (callback) {
      callback(response.data.completedUsers);
    } else {
      return response.data.completedUsers;
    }
  } catch (error) {
    console.error(error);
    if (callback) {
      callback({});
    } else {
      return false;
    }
  }
}

async function getInitialData(payload, callback) {
  const { isReload } = payload;
  try {
    const response = await getInitialDataApi();
    if (isReload) {
      simpleNotification({
        level: "success",
        title: t("notice.getInitialDataTitle"),
        message: t("notice.getInitialDataMessage"),
      });
    }
    callback(response.data);
  } catch (error) {
    console.error(error);
    callback([]);
  }
}

const CHUNK_SIZE = 1000;

async function getInsights(payload, callback) {
  const { allUsers, dateRange, filtered, selected, users } = payload;

  if (users.length === 0) {
    simpleNotification({
      level: "error",
      title: t("notice.getInsightsTitle"),
      message: t("notice.getInsightsMessage"),
    });
    return false;
  }

  if (!filtered) {
    if (!window.confirm(t("notice.getInsightsConfirm"))) {
      callback({});
      return false;
    }
  }

  // If all users, send empty user chunk
  const chunkedUsers = allUsers
    ? [null]
    : chunk(
        users.map((u) => u.twitchUsername),
        CHUNK_SIZE
      );
  const insightBatches = chunkedUsers.map(async (users) => {
    try {
      const response = await getInsightsApi({
        dateRange,
        games: selected.games,
        users,
      });

      return response.data.insightsData;
    } catch (error) {
      console.error(error);
      return [];
    }
  });

  const responses = await Promise.all(insightBatches);
  callback(buildDashboard(users, [].concat(...responses), selected.accv));
}

export { getCompleteUsers, getInitialData, getInsights };
