/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
// import { Link } from 'react-router';
import Dropzone from "react-dropzone";
import classNames from "classnames";
import { isEmpty, find, without } from "lodash";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import { FormControl, InputLabel, Select } from "@material-ui/core";

import { simpleNotification } from "../../../../utils/notifications";
import "./GameKeys.scss";
import { gameKeysReport } from "../../../../utils/downloadReports";
import { withTranslation } from "react-i18next";
import bounty from "../../../../images/icons/icon5.png";

class GameKeys extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.dropzoneRef = null;
    this.state = {
      dropzoneActive: false,
      disableClick: false,
      file: null,
      showingModal: null,
      tab: "for-sale",
      selectedKeyIds: [],
      selectedPlatform: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    if (!this.props.commandCenter.gameKeys.isLoading) {
      this.props.getAGame(gameId, token);
      this.props.getGameKeys(gameId, token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    // Handle key upload success/error
    if (
      this.props.commandCenter.uploadKeys.isLoading &&
      !nextProps.commandCenter.uploadKeys.isLoading
    ) {
      if (!nextProps.commandCenter.uploadKeys.errors.hasError) {
        simpleNotification({
          level: "success",
          title: t("success"),
          message: t("keyMessage"),
        });
      } else {
        simpleNotification({
          level: "error",
          title: t("error"),
          message: nextProps.commandCenter.uploadKeys.errors.message,
        });
      }
    }

    if (
      this.props.commandCenter.gameKeys.isLoading &&
      !nextProps.commandCenter.gameKeys.isLoading
    ) {
      if (nextProps.commandCenter.gameKeys.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("error"),
          message: nextProps.commandCenter.gameKeys.errors.message,
        });
      }
    }
  }

  onDragEnter() {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave() {
    this.setState({ dropzoneActive: false });
  }

  onDrop(files, e) {
    const csv = files && files.length ? files[0] : e[0];
    this.setState({ dropzoneActive: false, file: csv });
  }

  uploadKeys() {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;

    const formData = new FormData();
    formData.append("file", this.state.file);
    // Upload the file
    this.props.uploadKeys(formData, gameId, token);
  }

  deleteGameKey(gameKeyId) {
    const { token } = this.props.auth;
    // const gameKeyId = '5b33ae157361b2197f2fc473';
    this.props.deleteGameKey(gameKeyId, token);
    this.closeModal();
  }

  deleteGameKeys(gameKeyIds) {
    const { token } = this.props.auth;
    this.props.deleteGameKeys(JSON.stringify(gameKeyIds), token);
    this.closeModal();
    this.setState({
      selectedKeyIds: [],
    });
  }

  distributeGameKeys() {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    this.props.distributeGameKeys(gameId, token);
    this.closeModal();
  }

  assignNewKeyPlatforms = () => {
    const {
      auth: { token },
      router: {
        params: { gameId },
      },
      assignNewKeyPlatforms,
    } = this.props;

    const { selectedPlatform, selectedKeyIds } = this.state;

    assignNewKeyPlatforms(gameId, selectedPlatform, selectedKeyIds, token);
    this.setState({
      selectedKeyIds: [],
    });
    this.closeModal();
  };

  toggleSelectedKeyIds(keyId) {
    const { selectedKeyIds } = this.state;

    if (selectedKeyIds.indexOf(keyId) <= -1) {
      this.setState({
        selectedKeyIds: [...selectedKeyIds, keyId],
      });
    } else {
      this.setState({
        selectedKeyIds: [...without(selectedKeyIds, keyId)],
      });
    }
  }

  selectAllKeys(currentTabKeys) {
    this.setState({
      selectedKeyIds: currentTabKeys.map((obj) => obj.id),
    });
  }

  openModal(modalName) {
    this.setState({
      showingModal: modalName,
    });
  }

  openDeleteConfirmationModal(modalName, gameKeyId) {
    this.setState({
      showingModal: modalName,
      gameKeyId,
    });
  }

  closeModal() {
    this.setState({
      showingModal: null,
      gameKeyId: null,
    });
  }

  changeTabs = (tab) => {
    this.setState({
      tab,
      selectedKeyIds: [],
    });
  };

  platformOptions = () => {
    // const { platforms}
    const {
      admin: {
        game: {
          data: { platforms },
        },
      },
    } = this.props;

    const baseServices = {};

    platforms.forEach((p) => {
      if (!baseServices[p.baseService]) {
        baseServices[p.baseService] = [];
      }

      baseServices[p.baseService].push({ name: p.platform, value: p.id });
    });

    const options = Object.keys(baseServices).map((key) => (
      <optgroup key={key} label={key}>
        {baseServices[key].map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </optgroup>
    ));

    const onPlatformChange = (e) => {
      this.setState({
        selectedPlatform: e.target.value,
      });
    };

    return (
      <Select
        native
        defaultValue=""
        id="grouped-native-select"
        label="Grouping"
        onChange={onPlatformChange}
      >
        <option aria-label="None" value="" />
        {options}
      </Select>
    );
  };

  clearSelection = () => {
    this.setState({
      selectedKeyIds: [],
    });
  };

  render() {
    const {
      admin: {
        game: {
          data: { platforms },
        },
      },
      commandCenter: {
        games: { data: games },
        gameKeys: {
          data: gameKeys,
          usersWaitingForKeys = [],
          isLoading: gameKeysIsLoading,
        },
      },
      router: {
        params: { gameId },
      },
      t,
    } = this.props;

    const { dropzoneActive, selectedKeyIds, tab } = this.state;

    const currentGame = find(games, (game) => game.id === gameId);
    const { dataCSV, headersCSV } = gameKeysReport(gameKeys);

    const availableKeys =
      gameKeys.length > 0
        ? gameKeys.filter((item) => !item.owner && item.ownerEmail === "")
            .length
        : 0;

    const unavailableKeys =
      gameKeys.length > 0
        ? gameKeys.filter((item) => item.owner || item.ownerEmail !== "").length
        : 0;

    const forSaleKeys = availableKeys
      ? gameKeys.filter(
          (item) => !item.owner && item.ownerEmail === "" && item.onlyStore
        )
      : 0;
    const forSaleKeysRows =
      availableKeys > 0 ? (
        forSaleKeys.map((item) => (
          <div className="row" key={item.id}>
            <div className="table-cell">{item.key}</div>
            <div className="table-cell">
              {item.platform && item.platform.length > 0
                ? item.platform[0].platform
                : ""}
            </div>
            <div className="table-cell">{item.note}</div>
            {!item.loading && (
              <div className="table-cell">
                <input
                  type="checkbox"
                  style={{ margin: "0px", padding: "0px" }}
                  onClick={() => {
                    this.toggleSelectedKeyIds(item.id);
                  }}
                  checked={selectedKeyIds.indexOf(item.id) !== -1}
                />
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="">
          <div className="no-results">{t("noKeysForSale")}</div>
        </div>
      );

    const forQuestKeys = availableKeys
      ? gameKeys.filter(
          (item) => !item.owner && item.ownerEmail === "" && !item.onlyStore
        )
      : 0;
    const forQuestKeysRows =
      availableKeys > 0 ? (
        forQuestKeys.map((item) => (
          <div className="row" key={item.id}>
            <div className="table-cell">{item.key}</div>
            <div className="table-cell">
              {item.platform && item.platform.length > 0
                ? item.platform[0].platform
                : ""}
            </div>
            <div className="table-cell">{item.note}</div>
            {!item.loading && (
              <div className="table-cell">
                <input
                  type="checkbox"
                  style={{ margin: "0px", padding: "0px" }}
                  onClick={() => {
                    this.toggleSelectedKeyIds(item.id);
                  }}
                  checked={selectedKeyIds.indexOf(item.id) !== -1}
                />
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="">
          <div className="no-results">{t("noKeysForQuest")}</div>
        </div>
      );

    const soldKeys = unavailableKeys
      ? gameKeys.filter((item) => item.ownerEmail !== "" && item.onlyStore)
      : 0;
    const soldKeysRows =
      unavailableKeys > 0 ? (
        soldKeys.map((item) => {
          let dateAssigned = "-";
          if (item.assignationDate) {
            dateAssigned = moment(item.assignationDate).format(
              "YYYY-MM-DD h:mm a"
            );
          } else if (item.purchaseData) {
            dateAssigned = moment(item.purchaseData.soldDate).format(
              "YYYY-MM-DD h:mm a"
            );
          } else if (item.updatedAt) {
            dateAssigned = moment(item.updatedAt).format("YYYY-MM-DD h:mm a");
          }

          return (
            <div className="row" key={item.id}>
              <div className="table-cell">{item.key}</div>
              <div className="table-cell">
                {item.platform && item.platform.length > 0
                  ? item.platform[0].platform
                  : ""}
              </div>
              <div className="table-cell">{item.note}</div>
              <div className="table-cell">
                {item.purchaseData
                  ? item.purchaseData.soldByUser
                  : t("noizDirect")}
              </div>
              <div className="table-cell">
                {item.ownerEmail !== "" ? item.ownerEmail : "-"}
              </div>
              <div className="table-cell">{dateAssigned}</div>
            </div>
          );
        })
      ) : (
        <div className="">
          <div className="no-results">{t("noSoldKeys")}</div>
        </div>
      );

    const assignedKeys =
      unavailableKeys > 0
        ? gameKeys.filter(
            (item) => (item.owner || item.ownerEmail !== "") && !item.onlyStore
          )
        : 0;
    const assignedKeysRows =
      unavailableKeys > 0 ? (
        assignedKeys.map((item) => {
          const username =
            item.owner && item.owner.username ? item.owner.username : "-";
          let email = "-";
          if (item.owner) {
            email = item.owner.email;
          } else if (item.ownerEmail) {
            email = item.ownerEmail;
          }
          return (
            <div className="row" key={item.id}>
              <div className="table-cell">{item.key}</div>
              <div className="table-cell">
                {item.platform && item.platform.length > 0
                  ? item.platform[0].platform
                  : ""}
              </div>
              <div className="table-cell">{item.note}</div>
              <div className="table-cell">{email}</div>
              <div className="table-cell">{username}</div>
            </div>
          );
        })
      ) : (
        <div className="">
          <div className="no-results">{t("noDistributedKeys")}</div>
        </div>
      );

    const usersWaitingForKeysRows =
      usersWaitingForKeys.length > 0 ? (
        usersWaitingForKeys.map((item) => {
          const username =
            item.user && item.user.username ? item.user.username : "-";
          const since = moment(item.createdAt).format("YYYY-MM-DD h:mm a");
          return (
            <div className="row" key={item.id}>
              <div className="table-cell">{username}</div>
              <div className="table-cell">
                {item.platform ? item.platform.platform : "---"}
              </div>
              {/* <div className="table-cell">{item.quest}</div> */}
              <div className="table-cell">{since}</div>
            </div>
          );
        })
      ) : (
        <div className="">
          <div className="no-results">{t("noRequestingKeys")}</div>
        </div>
      );

    let currentTabKeys = [];
    switch (tab) {
      case "for-sale":
        currentTabKeys = forSaleKeys;
        break;
      case "for-quest":
        currentTabKeys = forQuestKeys;
        break;
      case "sold":
        currentTabKeys = soldKeys;
        break;
      case "distributed":
        currentTabKeys = assignedKeys;
        break;
      case "waitlist":
        currentTabKeys = usersWaitingForKeys;
        break;
      default:
        break;
    }

    const gamePlatforms = platforms || [];

    return (
      <section className="GameKeys">
        <section className="GameKeys__uploader">
          <h3 className="GameKeys__uploader--title">{t("uploaderTitle")}</h3>
          <p className="GameKeys__uploader--instructions">
            {t("instructions1")} {currentGame ? currentGame.name : ""}.{" "}
            {t("instructions2")}
          </p>
          <a
            className="GameKeys__uploader--sample-file"
            target="_blank"
            href="/csv/KEYS_EXAMPLE.csv"
          >
            {t("sampleFile")}
          </a>
          <div>
            <Dropzone
              ref={(node) => {
                this.dropzoneRef = node;
              }}
              disableClick={this.state.disableClick}
              className="Dropzone"
              style={{}}
              onDrop={(e, event) => this.onDrop(e, event)}
              onDragEnter={this.onDragEnter.bind(this)}
              onDragLeave={this.onDragLeave.bind(this)}
              disablePreview={false}
              accept=".csv"
              multiple={false}
              name="file"
              inputProps={{ id: "file" }}
            >
              {dropzoneActive && (
                <div className="Dropzone__DropLayer">{t("dropFile")}</div>
              )}
              <div className="fake-input">
                {this.state.file ? this.state.file.name : ""}
              </div>
              <button
                className="FilePickButton"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.dropzoneRef.open();
                }}
              >
                {t("filePickButton")}
              </button>
            </Dropzone>
          </div>
          <p className="GameKeys__uploader--help">{t("uploaderHelp")}</p>
          {this.state.file ? (
            <button
              className="GameKeys__uploader--btn CTAButton__purple"
              onClick={this.uploadKeys.bind(this)}
            >
              {t("uploadFile")}
            </button>
          ) : (
            <button className="GameKeys__uploader--btn CTAButton__purple disabled">
              {t("uploadFile")}
            </button>
          )}
        </section>

        {gameKeysIsLoading ? (
          <section className="ToggleTable__table-wrapper">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          </section>
        ) : (
          <section className="GameKeys__crud">
            <div className="GameKeys__crud-title-bar">
              <h2 className="GameKeys__crud-title">
                {t("crudTitle")}: <span>{unavailableKeys + availableKeys}</span>
              </h2>
              <CSVLink
                data={dataCSV}
                headers={headersCSV}
                style={{ marginLeft: "auto" }}
                filename={
                  !isEmpty(currentGame)
                    ? `${currentGame.name.split(" ").join("")}-Keys.csv`
                    : "Game-Keys.csv"
                }
              >
                <button>{t("btnDownload")}</button>
              </CSVLink>
            </div>

            <div className="Global__tabs" style={{ marginBottom: "0px" }}>
              <div
                className={classNames("Global__tab", {
                  active: tab === "for-sale",
                })}
                onClick={() => this.changeTabs("for-sale")}
                aria-hidden
              >
                {t("forSale")} ({forSaleKeysRows.length})
              </div>
              <div
                className={classNames("Global__tab", {
                  active: tab === "for-quest",
                })}
                onClick={() => this.changeTabs("for-quest")}
                aria-hidden
              >
                {" "}
                {t("forQuest")} ({forQuestKeysRows.length})
              </div>
              <div
                className={classNames("Global__tab", {
                  active: tab === "sold",
                })}
                onClick={() => this.changeTabs("sold")}
                style={{ width: "180px" }}
                aria-hidden
              >
                {" "}
                {t("sold")} ({soldKeysRows.length})
              </div>
              <div
                className={classNames("Global__tab", {
                  active: tab === "distributed",
                })}
                onClick={() => this.changeTabs("distributed")}
                aria-hidden
              >
                {" "}
                {t("distributed")} ({assignedKeysRows.length})
              </div>
              <div
                className={classNames("Global__tab", {
                  active: tab === "waitlist",
                })}
                onClick={() => this.changeTabs("waitlist")}
                aria-hidden
              >
                {" "}
                {t("waitlist")} ({usersWaitingForKeysRows.length})
              </div>
            </div>

            <section className="GameKeysList">
              <section className="GameKeysList__table-wrapper">
                {tab !== "waitlist" && (
                  <div className="GameKeysList__actionsBar">
                    <button
                      disabled={!currentTabKeys.length}
                      onClick={this.selectAllKeys.bind(this, currentTabKeys)}
                      style={{
                        marginLeft: "0px",
                        ...(!currentTabKeys.length
                          ? { cursor: "not-allowed" }
                          : {}),
                      }}
                    >
                      {t("selectAll")}
                    </button>
                    {selectedKeyIds.length > 0 && (
                      <>
                        <button
                          style={{ marginLeft: "0px" }}
                          onClick={this.openDeleteConfirmationModal.bind(
                            this,
                            "DeleteKeyConfirmation",
                            "balh"
                          )}
                        >
                          {t("delete")} ({selectedKeyIds.length}) {t("keys")}
                        </button>
                        <button
                          style={{ marginLeft: "0px" }}
                          onClick={this.openDeleteConfirmationModal.bind(
                            this,
                            "AssignPlatform",
                            "balh"
                          )}
                        >
                          {t("assignPlatform")} ({selectedKeyIds.length}){" "}
                          {t("keys")}
                        </button>
                        <button
                          style={{ marginLeft: "0px" }}
                          onClick={this.clearSelection}
                        >
                          {t("btnClear")}
                        </button>
                      </>
                    )}
                  </div>
                )}
                {this.state.tab === "for-sale" ? (
                  <section className="GameKeysList__table flex-table">
                    <div className="row">
                      <div className="table-cell headline">{t("key")}</div>
                      <div className="table-cell headline">{t("platform")}</div>
                      <div className="table-cell headline">{t("note")}</div>
                      <div className="table-cell headline">{t("select")}</div>
                    </div>
                    {forSaleKeysRows}
                  </section>
                ) : this.state.tab === "for-quest" ? (
                  <section className="GameKeysList__table flex-table">
                    <div className="row">
                      <div className="table-cell headline">{t("key")}</div>
                      <div className="table-cell headline">{t("platform")}</div>
                      <div className="table-cell headline">{t("note")}</div>
                      <div className="table-cell headline">{t("select")}</div>
                    </div>
                    {forQuestKeysRows}
                  </section>
                ) : this.state.tab === "sold" ? (
                  <section className="GameKeysList__table flex-table">
                    <div className="row">
                      <div className="table-cell headline">{t("key")}</div>
                      <div className="table-cell headline">{t("platform")}</div>
                      <div className="table-cell headline">{t("note")}</div>
                      <div className="table-cell headline">{t("soldBy")}</div>
                      <div className="table-cell headline">{t("soldTo")}</div>
                      <div className="table-cell headline">{t("dateSold")}</div>
                    </div>
                    {soldKeysRows}
                  </section>
                ) : this.state.tab === "distributed" ? (
                  <section className="GameKeysList__table flex-table">
                    <div className="row">
                      <div className="table-cell headline">{t("key")}</div>
                      <div className="table-cell headline">{t("platform")}</div>
                      <div className="table-cell headline">{t("note")}</div>
                      <div className="table-cell headline">
                        {t("accountEmail")}
                      </div>
                      <div className="table-cell headline">
                        {t("noizUsername")}
                      </div>
                    </div>
                    {assignedKeysRows}
                  </section>
                ) : this.state.tab === "waitlist" ? (
                  <section className="GameKeysList__table flex-table">
                    <div className="row">
                      <div className="table-cell headline">{t("user")}</div>
                      <div className="table-cell headline">{t("platform")}</div>
                      <div className="table-cell headline">{t("since")}</div>
                    </div>
                    {usersWaitingForKeysRows}
                    <section className="GameKeys__distribute">
                      <button
                        className={classNames(
                          "GameKeys__distribute--btn",
                          availableKeys === 0 ||
                            usersWaitingForKeys.length === 0
                            ? "disabled"
                            : ""
                        )}
                        disabled={
                          availableKeys === 0 ||
                          usersWaitingForKeys.length === 0
                        }
                        onClick={this.openModal.bind(
                          this,
                          "DistributeKeysConfirmation"
                        )}
                      >
                        {t("distributeKeys")}
                      </button>
                    </section>
                  </section>
                ) : null}
              </section>
            </section>
          </section>
        )}

        <section className="ModalMaster">
          <section
            className={classNames("DeleteKeyConfirmation Modal", {
              active: this.state.showingModal === "DeleteKeyConfirmation",
            })}
          >
            <div
              className="Modal__layer"
              onClick={this.closeModal.bind(this)}
              onKeyDown={this.closeModal.bind(this)}
            />
            <section className="Modal__wrapper">
              <div
                className="Modal__close-btn"
                onClick={this.closeModal.bind(this)}
                onKeyDown={this.closeModal.bind(this)}
              />
              <figure className="icon">
                <img src={bounty} alt="Bounty" />
              </figure>
              <h3 className="Modal__title">{t("deleteTitle")}</h3>
              <p className="instructions">
                {t("deleteInstructions1")} {selectedKeyIds.length}{" "}
                {t("deleteInstructions2")}?
              </p>
              {selectedKeyIds ? (
                <button
                  className="CTAButton__blue"
                  onClick={this.deleteGameKeys.bind(this, selectedKeyIds)}
                >
                  {t("delete")}
                </button>
              ) : (
                <button className="CTAButton__blue disabled">
                  {t("delete")}
                </button>
              )}
            </section>
          </section>
          <section
            className={classNames("AssignPlatform Modal", {
              active: this.state.showingModal === "AssignPlatform",
            })}
          >
            <div
              className="Modal__layer"
              onClick={this.closeModal.bind(this)}
              onKeyDown={this.closeModal.bind(this)}
            />
            <section className="Modal__wrapper">
              <div
                className="Modal__close-btn"
                onClick={this.closeModal.bind(this)}
                onKeyDown={this.closeModal.bind(this)}
              />
              <h3 className="Modal__title">{t("platforms")}</h3>
              {gamePlatforms.length > 0 ? (
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel htmlFor="grouped-native-select">
                    {t("platforms")}
                  </InputLabel>
                  {this.platformOptions()}
                  <button
                    className="CTAButton__blue"
                    onClick={this.assignNewKeyPlatforms}
                  >
                    {t("btnAssign")}
                  </button>
                </FormControl>
              ) : (
                <div>{t("noAssign")}</div>
              )}
            </section>
          </section>
          <section
            className={classNames("DistributeKeysConfirmation Modal", {
              active: this.state.showingModal === "DistributeKeysConfirmation",
            })}
          >
            <div
              className="Modal__layer"
              onClick={this.closeModal.bind(this)}
              onKeyDown={this.closeModal.bind(this)}
            />
            <section className="Modal__wrapper">
              <div
                className="Modal__close-btn"
                onClick={this.closeModal.bind(this)}
                onKeyDown={this.closeModal.bind(this)}
              />
              <figure className="icon">
                <img src={bounty} alt="Bounty" />
              </figure>
              <h3 className="Modal__title">{t("confirmation")}</h3>
              <p className="instructions">
                {t("confirmationInstructions1")} {availableKeys}{" "}
                {t("confirmationInstructions2")} {usersWaitingForKeys.length}{" "}
                {t("confirmationInstructions3")}{" "}
                <span className="game-name">
                  {!isEmpty(currentGame) ? currentGame.name : ""}
                </span>
                .
              </p>
              <button
                className={classNames(
                  "CTAButton__blue",
                  availableKeys === 0 || usersWaitingForKeys.length === 0
                    ? "disabled"
                    : ""
                )}
                disabled={
                  availableKeys === 0 || usersWaitingForKeys.length === 0
                }
                onClick={this.distributeGameKeys.bind(this)}
              >
                {t("distribute")}
              </button>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

GameKeys.propTypes = {};

export default withTranslation("gameKeys")(GameKeys);
