/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */

import React from "react";
// import { split } from 'lodash';
import classNames from "classnames";
import { Link } from "react-router";
// import moment from 'moment';
import ReactHtmlParser from "react-html-parser";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/css/swiper.css";
import "./LandingQuest.scss";
import {
  calculatePaymentAmount,
  getCorrectTwitchAverages,
} from "../../utils/functions";
import { withTranslation } from "react-i18next";

class LandingQuest extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      // faq_open_index: -1,
      questPrivate: false,
      // paymentAmount: null,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      auth: {
        token,
        user: { role },
      },
      router: {
        params: { preview, questSlug },
      },
      getQuestByIdentifier,
      getQuestBySlugGuest,
    } = this.props;

    if (token) {
      getQuestByIdentifier(questSlug);
    } else {
      getQuestBySlugGuest(questSlug);
    }

    if (preview && (role === "admin" || role === "helper")) {
      this.setState({ questPrivate: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.config.quest.isLoading === true &&
      nextProps.config.quest.isLoading === false
    ) {
      if (
        nextProps.config.quest.errors.hasError ||
        !nextProps.config.quest.data
      ) {
        this.props.router.replace("/not-found");
      } else {
        const quest = nextProps.config.quest.data;
        if (quest.isPrivate) {
          this.setState({
            questPrivate: true,
          });
          if (localStorage.getItem("justLoggedIn")) {
            localStorage.removeItem("justLoggedIn");
            this.props.router.push("/quests");
          }
        }
      }
    }
  }

  onSignUp() {
    this.props.router.push("/sign-up");
    localStorage.setItem("questId", this.props.config.quest.data.id);
  }

  generateGameSliderHTML() {
    const { t } = this.props;
    const { data: quest, isLoadingQuest } = this.props.config.quest;

    if (quest && !isLoadingQuest && quest.game && quest.game.gallery) {
      return quest.game.gallery.map((g) => (
        <section className="GameCarousel">
          <section className="GameCarousel__wrapper">
            <div className="GameCarousel__left">
              <figure
                style={{ backgroundImage: `url(${g.url})` }}
                alt={t("gameCover")}
              />
            </div>
          </section>
        </section>
      ));
    }

    // If no active games show this message
    return <React.Fragment />;
  }

  generateStepsHTML() {
    const { data: quest, isLoadingQuest } = this.props.config.quest;

    if (quest.steps && quest.steps.length > 0 && !isLoadingQuest) {
      return quest.steps.map((step) => this.generateActionHTML(step));
    }

    return null;
  }

  generateActionHTML = (step) => {
    if (!(step.type === "normal" || step.type === "only-landing")) {
      return <React.Fragment />;
    }

    return (
      <section
        className={classNames("Quest__step Quest__step--action", {
          "paid-bounty": step.type === "verify-payment-email-action",
          "early-access-key": step.type === "restep-key-action",
          "alpha-feedback": step.type === "alpha-feedback-action",
          referral: step.type === "referral-action",
          normal: step.type === "normal",
          active: step.completed,
        })}
      >
        <i className={classNames("Quest__step--icon", step.icon)} />
        {step.type === "alpha-feedback-action" && (
          <div className="Quest__step--details">
            <div className="Quest__step--details-title">{step.title}</div>
            <div className="Quest__step--details-description">
              {ReactHtmlParser(step.description)}
            </div>
          </div>
        )}
        {step.type === "request-key-action" && (
          <div className="Quest__step--details">
            <div className="Quest__step--details-title">{step.title}</div>
            <div className="Quest__step--details-description">
              {ReactHtmlParser(step.description)}
            </div>
          </div>
        )}
        {/* Only This is kind of quest is being rendered (for now) */}
        {(step.type === "normal" || !step.type) && (
          <div className="Quest__step--details">
            <div className="Quest__step--details-title">{step.title}</div>
            <div className="Quest__step--details-description">
              {ReactHtmlParser(step.description)}
            </div>
          </div>
        )}
        {(step.type === "only-landing" || !step.type) && (
          <div className="Quest__step--details">
            <div className="Quest__step--details-title">{step.title}</div>
            <div className="Quest__step--details-description">
              {ReactHtmlParser(step.description)}
            </div>
          </div>
        )}
      </section>
    );
  };

  generateLandingQuest = () => {
    const {
      auth,
      auth: {
        token,
        user,
        user: { role },
      },
      config: {
        quest: { data: quest, isLoading: isLoadingQuest },
        quests: { isLoading: isLoadingQuests },
      },
    } = this.props;

    const GameCarouselsParams = {
      spaceBetween: 0,
      slidesPerView: "auto",
      centeredSlides: true,
      slideToClickedSlide: true,
      speed: 500,
      loop: false,
      direction: "horizontal",
      // loopedSlides: 3,
      scrollBar: false,
      containerClass: "GameCarouselsSwiper",
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
    };

    const { questPrivate } = this.state;
    const isAdmin = role === "admin";
    const isHelper = role === "helper";

    if (quest && !isLoadingQuest && !isLoadingQuests && quest.game) {
      const gameLogo = quest.game ? quest.game.logo : "";

      const tokenCheck = token;
      let paymentAmount = null;
      let isValidPayment = null;
      if (tokenCheck) {
        const currentUser = auth && user ? user : null;
        const currentViewers = quest.twitchAccountRequired
          ? getCorrectTwitchAverages(currentUser).correctAverage
          : null;
        const { viewerTiers, memberTiers } = quest;
        paymentAmount = calculatePaymentAmount(
          currentUser,
          currentViewers,
          viewerTiers,
          memberTiers
        );
      }

      isValidPayment =
        tokenCheck && paymentAmount.value && paymentAmount.value > 0;

      const { t } = this.props;

      return (
        <React.Fragment>
          <section className="LandingQuest__header">
            <section className="LandingQuest__background">
              <figure style={{ backgroundImage: `url(${quest.game.cover})` }} />
            </section>
            <section className="LandingQuest__header--wrapper">
              <div className="LandingQuest__header--logo">
                <figure
                  className="LandingQuest__header--figure"
                  style={{ backgroundImage: `url(${gameLogo})` }}
                />
              </div>
              <div className="LandingQuest__header--title">
                {isValidPayment
                  ? `${quest.landingPageTitle} - earn $${paymentAmount.value}`
                  : quest.landingPageTitle}
              </div>
            </section>
          </section>
          {(isAdmin || isHelper) && questPrivate && (
            <div className="LandingQuest__header--previewLabel">
              {t("adminPreview")}
            </div>
          )}
          <section className="LandingQuest__middle">
            <section className="LandingQuest__middle--wrapper">
              <div className="LandingQuest__middle--label">
                {t("aboutTheQuest")}
              </div>
              <div className="LandingQuest__middle--description">
                {quest.description && ReactHtmlParser(quest.description)}
                <button
                  className="LandingQuest__middle--cta"
                  onClick={this.onSignUp.bind(this)}
                >
                  {t("apply")}
                </button>
              </div>
            </section>
          </section>
          <section className="LandingQuest__slider">
            <section className="LandingQuest__background opactity50">
              <figure style={{ backgroundImage: `url(${quest.game.cover})` }} />
            </section>
            <section className="LandingQuest__slider--wrapper">
              <div className="LandingQuest__slider--label">
                {t("gameGallery")}
              </div>
              {quest.game.gallery.length > 0 && (
                <Swiper {...GameCarouselsParams}>
                  {this.generateGameSliderHTML()}
                </Swiper>
              )}
            </section>
          </section>
        </React.Fragment>
      );
    }
    return (
      <div className="LandingQuest--loading">
        <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
      </div>
    );
  };

  closeModal = () => {
    this.props.router.push("/quests");
  };

  renderModal(quest, guest) {
    const { t } = this.props;
    return (
      <section className="LandingQuest_ModalMaster active">
        <section className="Modal active">
          <button
            onClick={this.closeModal.bind(this)}
            className="Modal__layer"
            aria-hidden
          />
          <section className="Modal__wrapper">
            <div className="Modal__header">
              <figure
                style={{
                  backgroundImage: `url(${quest.game ? quest.game.cover : ""})`,
                }}
                className="Modal__header__background Modal__header__background2"
              />
              <figure
                className="Modal__header__logo"
                style={{
                  backgroundImage: `url(${quest.game ? quest.game.logo : ""})`,
                }}
              />
              <div className="Modal__header-type">
                {quest.type === "default" && (
                  <React.Fragment>{t("generalQuest")}</React.Fragment>
                )}
                {quest.type === "link out" && (
                  <React.Fragment>{t("externalQuest")}</React.Fragment>
                )}
                {quest.type !== "link out" && quest.type !== "default" && (
                  <React.Fragment>
                    {quest.type} {t("quest")}
                  </React.Fragment>
                )}
              </div>
              <button
                onClick={this.closeModal.bind(this)}
                className="Modal__close-btn"
                aria-hidden
              />
            </div>
            <div className="Modal__body">
              {!guest && (
                <React.Fragment>
                  {t("sorryMessage")}{" "}
                  <Link to="/quests" href="/quests">
                    {t("quests")}
                  </Link>
                  .
                </React.Fragment>
              )}
              {guest && (
                <React.Fragment>
                  {t("please")}{" "}
                  <Link to="/sign-in" href="/sign-in">
                    {t("logIn")}
                  </Link>{" "}
                  {t("or")}{" "}
                  <Link to="/sign-up" href="/sign-up">
                    {t("signUp")}
                  </Link>{" "}
                  {t("viewQuest")}
                </React.Fragment>
              )}
            </div>
            <div className="Modal__footer">
              <button onClick={this.closeModal.bind(this)}>Close</button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  render() {
    const {
      auth: {
        token,
        user: { role },
      },
      config: {
        quest: { data: quest, isLoadingQuest },
      },
    } = this.props;
    const { questPrivate } = this.state;

    const isAdmin = role === "admin";
    const isHelper = role === "helper";

    let guest = false;

    if (!token) {
      guest = true;
    }

    return (
      <React.Fragment>
        <section
          className={classNames("LandingQuest", {
            active: questPrivate && !isAdmin && !isHelper,
          })}
        >
          <section className="LandingQuest--wrapper">
            {quest && !isLoadingQuest && this.generateLandingQuest()}
          </section>
          {/* <section className="LandingQuest__questions" style={(quest.faqs && quest.faqs.length > 0) ? {} : { display: 'none' }}>
            <section className="LandingQuest__questions__wrapper">
              <section className="LandingQuest__questions_left">
                <div className="LandingQuest__questions_title">
                  Have any questions about Noiz?
                </div>
                <div className="LandingQuest__questions_contact">
                  <span className="LandingQuest__questions_contact_label">CONTACT US ANYTIME</span>
                  <br />
                  <span className="LandingQuest__questions_contact_email">
                    <a href="mailto:support@noiz.gg">support@noiz.gg</a>
                  </span>
                </div>
              </section>
              <section className="LandingQuest__questions_right">
                {quest.faqs && quest.faqs.map((obj, i) => {
                  return (
                    <div className="LandingQuest__questions_question" style={(this.state.faq_open_index === i) ? { borderBottom: '2px solid #f00' } : {}}>
                      <div className="LandingQuest__questions_question_top">
                        {obj.title}
                        <i
                          aria-hidden
                          className={
                            classNames(
                              'fa',
                              `w_${i}`,
                              { 'fa-minus-square-o': (this.state.faq_open_index === i)},
                              { 'fa-plus-square-o': this.state.faq_open_index !== i },
                            )
                          }
                          onClick={() => {
                            if (this.state.faq_open_index !== i) {
                              this.setState({ faq_open_index: i });
                            } else {
                              this.setState({ faq_open_index: -1 });
                            }
                          }}
                        />
                      </div>
                      <div
                        className="LandingQuest__questions_question_bottom"
                        style={
                          (this.state.faq_open_index === i) ?
                          { height: 'auto', display: 'block' } :
                          { height: 0, display: 'none' }}
                      >
                        {(obj.description) && ReactHtmlParser(obj.description)}
                      </div>
                    </div>
                  );
                })}
              </section>
            </section>
          </section> */}
        </section>
        {questPrivate &&
          !isAdmin &&
          !isHelper &&
          this.renderModal(quest, guest)}
      </React.Fragment>
    );
  }
}

LandingQuest.propTypes = {
  // router: PropTypes.shape({
  //   replace: PropTypes.func.isRequired,
  // }).isRequired,
};

export default withTranslation("landingQuest")(LandingQuest);
