/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router";
import classNames from "classnames";
import { forEach, map, find, sortBy } from "lodash";
import moment from "moment";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { CountryRegionData } from "react-country-region-selector";
import makeAnimated from "react-select/lib/animated";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TwitterIcon from "@material-ui/icons/Twitter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";

// import AdminTabs from '../../util/tabs/AdminTabs';
import snowFlake from "../../../../images/icons/frozen.png";

import UserPDFModal from "../../util/UserPDFModal";

import "./UserList.scss";

// Utils
import {
  getCorrectTwitchAverages,
  getUserQuestStatus,
  stableSort,
  getSorting,
  copyText,
} from "../../../../utils/functions";

import { simpleNotification } from "../../../../utils/notifications";

const TableHeaderCell = withStyles((theme) => ({
  head: {
    color: "#9b9b9b",
    fontWeight: "400",
    letterSpacing: "1.2px",
    fontSize: "14px",
  },
}))(TableCell);

const TinyTwitter = withStyles((theme) => ({
  fontSizeSmall: {
    fontSize: "12px",
    margin: 0,
    padding: 0,
  },
}))(TwitterIcon);

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#202227",
    border: "none",
    borderBottom: "2px solid #83858C",
    borderRadius: "none",
    margin: "0 auto",
    width: "100%",
  }),
  container: (styles) => ({
    ...styles,
    width: "200%",
  }),
  option: (styles) => ({
    ...styles,
    backgroundColor: "#202227",
    cursor: "default",
    width: "100%",
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#202227",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#ececec",
  }),
  input: (styles) => ({
    ...styles,
    color: "#ffffff",
    width: "100%",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#ececec",
    ":hover": {
      backgroundColor: "#202227",
      color: "white",
      cursor: "pointer",
    },
  }),
};

const theDomain =
  process.env.NODE_ENV === "production"
    ? "https://noiz.gg"
    : process.env.NODE_ENV === "staging"
    ? "https://staging.noiz.gg"
    : "localhost:3000";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      numPage: 1,
      questTab: 1,
      pdfDownloadModal: false,
      downloadModal: false,
      downloadUser: false,
      displayModal: false,
      filtersModal: false,
      addToQuestUserId: false,
      addToQuestModalOpen: false,
      gameTableUserId: false,
      questTableUserId: false,
      daysFilter: 1,
      filterError: false,
      addToQuestObj: false,
      toDisplay: {
        email: true,
        firstName: false,
        lastName: false,
        noiz: true,
        twitch: true,
        paymentEmail: false,
        avgViewers: true,
        followers: true,
        youtube: false,
        youtubeViews: false,
        youtubeLikes: false,
        youtubeDislikes: false,
        youtubeFavorites: false,
        youtubeComments: false,
        youtubeSubscribers: false,
        youtubeVideos: false,
        gender: false,
        country: false,
        timezone: false,
        createdAt: false,
        questsLength: false,
        actions: true,
        games: false,
        quests: true,
        twitterUsername: true,
        twitterFollowers: true,
        twitch30DayAvgViewers: false,
        twitch60DayAvgViewers: false,
        twitch90DayAvgViewers: false,
      },
      filters: {
        accountAll: true,
        accountTwitch: false,
        genderAll: true,
        genderMale: false,
        genderFemale: false,
        genderOther: false,
        onAndOffNoiz: true,
        offPlatform: false,
        onPlatform: false,
        searchAll: true,
        searchEmail: false,
        searchNoiz: false,
        searchTwitch: false,
        searchTwitter: false,
        searchValue: "",
        searchMinAvgViewersValue: "",
        searchMaxAvgViewersValue: "",
        questsLengthMaxValue: "",
        questsLengthMinValue: "",
        countries: [],
        games: [],
        timePeriod: 1,
      },
      filtersClear: {
        accountAll: true,
        accountTwitch: false,
        onAndOffNoiz: true,
        offPlatform: false,
        onPlatform: false,
        genderAll: true,
        genderMale: false,
        genderFemale: false,
        genderOther: false,
        searchAll: true,
        searchEmail: false,
        searchNoiz: false,
        searchTwitch: false,
        searchValue: "",
        searchMinAvgViewersValue: "",
        searchMaxAvgViewersValue: "",
        questsLengthMaxValue: "",
        questsLengthMinValue: "",
        countries: [],
        games: [],
        timePeriod: 1,
      },
      filtersBackup: {},
      sorting: {
        emailAsc: false,
        emailDesc: false,
        firstNameAsc: false,
        firstNameDesc: false,
        lastNameAsc: false,
        lastNameDesc: false,
        noizAsc: true,
        noizDesc: false,
        paymentEmailAsc: false,
        paymentEmailDesc: false,
        twitchAsc: false,
        twitchDesc: false,
        youtubeAsc: false,
        youtubeDesc: false,
        youtubeViewsAsc: false,
        youtubeViewsDesc: false,
        youtubeLikesAsc: false,
        youtubeLikesDesc: false,
        youtubeDislikesAsc: false,
        youtubeDislikesDesc: false,
        youtubeFavoritesAsc: false,
        youtubeFavoritesDesc: false,
        youtubeCommentsAsc: false,
        youtubeCommentsDesc: false,
        youtubeSubscribersAsc: false,
        youtubeSubscribersDesc: false,
        youtubeVideosAsc: false,
        youtubeVideosDesc: false,
        countryAsc: false,
        countryDesc: false,
        createdAtAsc: false,
        createdAtDesc: false,
        twitchAvgViewersAsc: false,
        twitchAvgViewersDesc: false,
        twitch30DayAvgViewersAsc: false,
        twitch30DayAvgViewersDesc: false,
        twitch60DayAvgViewersAsc: false,
        twitch60DayAvgViewersDesc: false,
        twitch90DayAvgViewersAsc: false,
        twitch90DayAvgViewersDesc: false,
        twitterUsernameAsc: false,
        twitterUsernameDesc: false,
        questsLengthAsc: false,
        questsLengthDesc: false,
      },
      defaultSorting: {
        emailAsc: false,
        emailDesc: false,
        firstNameAsc: false,
        firstNameDesc: false,
        lastNameAsc: false,
        lastNameDesc: false,
        noizAsc: false,
        noizDesc: false,
        paymentEmailAsc: false,
        paymentEmailDesc: false,
        twitchAsc: false,
        twitchDesc: false,
        youtubeAsc: false,
        youtubeDesc: false,
        youtubeViewsAsc: false,
        youtubeViewsDesc: false,
        youtubeLikesAsc: false,
        youtubeLikesDesc: false,
        youtubeDislikesAsc: false,
        youtubeDislikesDesc: false,
        youtubeFavoritesAsc: false,
        youtubeFavoritesDesc: false,
        youtubeCommentsAsc: false,
        youtubeCommentsDesc: false,
        youtubeSubscribersAsc: false,
        youtubeSubscribersDesc: false,
        youtubeVideosAsc: false,
        youtubeVideosDesc: false,
        countryAsc: false,
        countryDesc: false,
        createdAtAsc: false,
        createdAtDesc: false,
        twitchAvgViewersAsc: false,
        twitchAvgViewersDesc: false,
        twitch30DayAvgViewersAsc: false,
        twitch30DayAvgViewersDesc: false,
        twitch60DayAvgViewersAsc: false,
        twitch60DayAvgViewersDesc: false,
        twitch90DayAvgViewersAsc: false,
        twitch90DayAvgViewersDesc: false,
        twitterUsernameAsc: false,
        twitterUsernameDesc: false,
        questsLengthAsc: false,
        questsLengthDesc: false,
      },
      // gameOptions: [],
    };
    this.countryOptions = [];
    forEach(CountryRegionData, (obj) => {
      this.countryOptions.push({
        value: obj[0],
        label: obj[0],
      });
    });
  }

  UNSAFE_componentWillMount() {
    const { token, user } = this.props.auth;
    const isAdmin = user.role === "admin";

    if (!this.props.admin.users.isLoading && isAdmin) {
      this.props.getAUserList(
        this.state.numPage,
        JSON.stringify(this.state.filters),
        JSON.stringify(this.state.sorting),
        0,
        token
      );
      this.props.getATrackedGames(token);
      this.props.getAQuestList(token);
      // this.props.getAGameList('0', '0', token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (this.props.admin.trackedGames.isLoading === true && nextProps.admin.trackedGames.isLoading === false) {
    //   const { data: trackedGames } = nextProps.admin.trackedGames;
    //   this.setState({
    //     gameOptions: trackedGames,
    //   });
    // }
    const { t } = this.props;
    // User failed to be added to quest
    if (
      this.props.commandCenter.activeUserQuests.isLoading === true &&
      nextProps.commandCenter.activeUserQuests.isLoading === false
    ) {
      if (nextProps.commandCenter.activeUserQuests.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("error") + "!",
          message: nextProps.commandCenter.activeUserQuests.errors.message,
        });
      }
    }

    // User sucessfully added from quest
    if (
      this.props.commandCenter.activeUserQuests.isLoadingSpecial === true &&
      nextProps.commandCenter.activeUserQuests.isLoadingSpecial === false
    ) {
      if (!nextProps.commandCenter.activeUserQuests.errors.hasError) {
        const { token } = this.props.auth;

        simpleNotification({
          level: "success",
          title: t("success") + "!",
          message: nextProps.commandCenter.activeUserQuests.errors.message,
        });

        // Get users again
        this.props.getAUserList(
          this.state.numPage,
          JSON.stringify(this.state.filters),
          JSON.stringify(this.state.sorting),
          0,
          token
        );
        this.props.getATrackedGames(token);
        this.props.getAQuestList(token);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { token, user } = this.props.auth;
    const isAdmin = user.role === "admin";

    if (this.state.sorting !== prevState.sorting && isAdmin) {
      this.setState({
        numPage: 1,
      });
      this.props.getAUserList(
        1,
        JSON.stringify(this.state.filters),
        JSON.stringify(this.state.sorting),
        0,
        token
      );
    }
  }

  addToQuest(userId, questObj) {
    const { token } = this.props.auth;
    if (userId && questObj && questObj.value) {
      this.props.addCCUserQuest(userId, questObj.value, "pending", token);
    }
  }

  // Toggle open/closed the add to quest modal for userId
  toggleAddToQuestModal = (userId) => {
    if (this.state.addToQuestUserId === userId) {
      this.setState({
        addToQuestUserId: false,
        addToQuestModalOpen: false,
        addToQuestObj: false,
      });
    } else {
      this.setState({
        addToQuestUserId: userId,
        addToQuestModalOpen: true,
      });
    }
  };

  // Toggle open/closed the games subtable
  toggleGameSubtable = (userId) => {
    if (this.state.gameTableUserId === userId) {
      this.setState({
        gameTableUserId: false,
      });
    } else {
      this.setState({
        gameTableUserId: userId,
      });
    }
  };

  // Toggle open/closed the quest subtable
  toggleQuestSubtable = (userId) => {
    if (this.state.questTableUserId === userId) {
      this.setState({
        questTableUserId: false,
        questTab: 1,
      });
    } else {
      this.setState({
        questTableUserId: userId,
      });
    }
  };

  paginationRight = () => {
    const { token, user } = this.props.auth;
    const { pagination } = this.props.admin.users;
    const isAdmin = user.role === "admin";

    if (!isAdmin || pagination.totalPages < this.state.numPage + 1) {
      return;
    }

    if (!this.props.admin.users.isLoading) {
      this.props.getAUserList(
        this.state.numPage + 1,
        JSON.stringify(this.state.filters),
        JSON.stringify(this.state.sorting),
        0,
        token
      );
    }
    this.setState({
      numPage: this.state.numPage + 1,
    });
  };

  paginationLeft = () => {
    const { token, user } = this.props.auth;
    const isAdmin = user.role === "admin";

    if (!isAdmin || this.state.numPage === 1) {
      return;
    }

    if (!this.props.admin.users.isLoading) {
      this.props.getAUserList(
        this.state.numPage - 1,
        JSON.stringify(this.state.filters),
        JSON.stringify(this.state.sorting),
        0,
        token
      );
    }
    this.setState({
      numPage: this.state.numPage - 1,
    });
  };

  handleSearchValueChange(event) {
    this.setState({
      filters: {
        ...this.state.filters,
        searchValue: event.target.value,
      },
    });
  }

  handleSearchMinAvgViewersValueChange(event) {
    this.setState({
      filters: {
        ...this.state.filters,
        searchMinAvgViewersValue: event.target.value,
      },
    });
  }

  handleSearchMaxAvgViewersValueChange(event) {
    this.setState({
      filters: {
        ...this.state.filters,
        searchMaxAvgViewersValue: event.target.value,
      },
    });
  }

  handleQuestsLengthMaxValueChange(event) {
    this.setState({
      filters: {
        ...this.state.filters,
        questsLengthMaxValue: event.target.value,
      },
    });
  }

  handleQuestsLengthMinValueChange(event) {
    this.setState({
      filters: {
        ...this.state.filters,
        questsLengthMinValue: event.target.value,
      },
    });
  }

  openDownloadModal = () => {
    const { token, user } = this.props.auth;
    const isAdmin = user.role === "admin";

    if (isAdmin) {
      this.props.getAUserListCsv(
        0,
        JSON.stringify(this.state.filters),
        JSON.stringify(this.state.sorting),
        token
      );
      this.setState({
        downloadModal: true,
      });
    }
  };

  openPDFDownloadModal = (downloadUser) => {
    const { user } = this.props.auth;
    const isAdmin = user.role === "admin";

    if (isAdmin) {
      this.setState({
        downloadUser,
        pdfDownloadModal: true,
      });
    }
  };

  closeDownloadModal = () => {
    this.setState({
      downloadUser: false,
      downloadModal: false,
    });
  };

  closePDFDownloadModal = () => {
    this.setState({
      pdfDownloadModal: false,
    });
  };

  openDisplayModal = () => {
    this.setState({
      displayModal: true,
    });
  };

  closeDisplayModal = () => {
    this.setState({
      displayModal: false,
    });
  };

  openFiltersModal = () => {
    this.setState({
      filtersModal: true,
      filtersBackup: { ...this.state.filters },
    });
  };

  closeFiltersModal = () => {
    this.setState({
      filtersModal: false,
      filters: { ...this.state.filtersBackup },
    });
  };

  clearFiltersModal = () => {
    this.setState({
      filters: { ...this.state.filtersClear },
    });
  };

  applyFilters = () => {
    const { token, user } = this.props.auth;
    const isAdmin = user.role === "admin";
    const { searchAll, searchEmail, searchNoiz, searchTwitch, searchTwitter } =
      this.state.filters;

    // Non-admins must provide a search term and search parameter.
    if (!isAdmin) {
      if (
        this.state.filters.searchValue === "" ||
        (!searchAll &&
          !searchEmail &&
          !searchNoiz &&
          !searchTwitch &&
          !searchTwitter)
      ) {
        this.setState({
          filterError: true,
        });
        return;
      }
      this.setState({
        filterError: false,
      });
    }

    if (this.state.filters.games.length > 0) {
      this.setState({
        toDisplay: {
          ...this.state.toDisplay,
          games: true,
        },
      });
    }

    this.setState({
      filtersModal: false,
    });

    this.props.getAUserList(
      1,
      JSON.stringify(this.state.filters),
      JSON.stringify(this.state.sorting),
      isAdmin ? 0 : 1,
      token
    );
  };

  changeDisplayValue(name) {
    switch (name) {
      case "email":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            email: !prevState.toDisplay.email,
          },
        }));
        break;
      case "noiz":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            noiz: !prevState.toDisplay.noiz,
          },
        }));
        break;
      case "firstName":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            firstName: !prevState.toDisplay.firstName,
          },
        }));
        break;
      case "lastName":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            lastName: !prevState.toDisplay.lastName,
          },
        }));
        break;
      case "paymentEmail":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            paymentEmail: !prevState.toDisplay.paymentEmail,
          },
        }));
        break;
      case "twitch":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            twitch: !prevState.toDisplay.twitch,
          },
        }));
        break;
      case "avgViewers":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            avgViewers: !prevState.toDisplay.avgViewers,
          },
        }));
        break;
      case "followers":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            followers: !prevState.toDisplay.followers,
          },
        }));
        break;
      case "youtube":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            youtube: !prevState.toDisplay.youtube,
          },
        }));
        break;
      case "youtubeViews":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            youtubeViews: !prevState.toDisplay.youtubeViews,
          },
        }));
        break;
      case "youtubeLikes":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            youtubeLikes: !prevState.toDisplay.youtubeLikes,
          },
        }));
        break;
      case "youtubeDislikes":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            youtubeDislikes: !prevState.toDisplay.youtubeDislikes,
          },
        }));
        break;
      case "youtubeFavorites":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            youtubeFavorites: !prevState.toDisplay.youtubeFavorites,
          },
        }));
        break;
      case "youtubeComments":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            youtubeComments: !prevState.toDisplay.youtubeComments,
          },
        }));
        break;
      case "youtubeSubscribers":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            youtubeSubscribers: !prevState.toDisplay.youtubeSubscribers,
          },
        }));
        break;
      case "youtubeVideos":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            youtubeVideos: !prevState.toDisplay.youtubeVideos,
          },
        }));
        break;
      case "gender":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            gender: !prevState.toDisplay.gender,
          },
        }));
        break;
      case "country":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            country: !prevState.toDisplay.country,
          },
        }));
        break;
      case "timezone":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            timezone: !prevState.toDisplay.timezone,
          },
        }));
        break;
      case "createdAt":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            createdAt: !prevState.toDisplay.createdAt,
          },
        }));
        break;
      case "questsLength":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            questsLength: !prevState.toDisplay.questsLength,
          },
        }));
        break;
      case "games":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            games: !prevState.toDisplay.games,
          },
        }));
        break;
      case "quests":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            quests: !prevState.toDisplay.quests,
          },
        }));
        break;
      case "twitch30DayAvgViewers":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            twitch30DayAvgViewers: !prevState.toDisplay.twitch30DayAvgViewers,
          },
        }));
        break;
      case "twitch60DayAvgViewers":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            twitch60DayAvgViewers: !prevState.toDisplay.twitch60DayAvgViewers,
          },
        }));
        break;
      case "twitch90DayAvgViewers":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            twitch90DayAvgViewers: !prevState.toDisplay.twitch90DayAvgViewers,
          },
        }));
        break;
      case "twitterUsername":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            twitterUsername: !prevState.toDisplay.twitterUsername,
          },
        }));
        break;
      case "twitterFollowers":
        this.setState((prevState) => ({
          toDisplay: {
            ...prevState.toDisplay,
            twitterFollowers: !prevState.toDisplay.twitterFollowers,
          },
        }));
        break;
      default:
        this.setState({});
    }
  }

  changeFilterAccounts(name) {
    switch (name) {
      case "accountAll":
        this.setState({
          filters: {
            ...this.state.filters,
            accountAll: true,
            accountTwitch: false,
          },
        });
        break;
      case "accountTwitch":
        this.setState({
          filters: {
            ...this.state.filters,
            accountAll: false,
            accountTwitch: true,
          },
        });
        break;
      case "accountTwitter":
        this.setState({
          filters: {
            ...this.state.filters,
            accountAll: false,
            accountTwitter: true,
          },
        });
        break;
      default:
        this.setState({});
    }
  }

  changeFilterGender(name) {
    switch (name) {
      case "genderAll":
        this.setState({
          filters: {
            ...this.state.filters,
            genderAll: true,
            genderMale: false,
            genderFemale: false,
            genderOther: false,
          },
        });
        break;
      case "genderMale":
        this.setState({
          filters: {
            ...this.state.filters,
            genderAll: false,
            genderMale: true,
            genderFemale: false,
            genderOther: false,
          },
        });
        break;
      case "genderFemale":
        this.setState({
          filters: {
            ...this.state.filters,
            genderAll: false,
            genderMale: false,
            genderFemale: true,
            genderOther: false,
          },
        });
        break;
      case "genderOther":
        this.setState({
          filters: {
            ...this.state.filters,
            genderAll: false,
            genderMale: false,
            genderFemale: false,
            genderOther: true,
          },
        });
        break;
      default:
        this.setState({});
    }
  }

  changeFilterOffPlatform(name) {
    switch (name) {
      case "onAndOffNoiz":
        this.setState({
          filters: {
            ...this.state.filters,
            onAndOffNoiz: true,
            offPlatform: false,
            onPlatform: false,
          },
        });
        break;
      case "offPlatform":
        this.setState({
          filters: {
            ...this.state.filters,
            onAndOffNoiz: false,
            offPlatform: true,
            onPlatform: false,
          },
        });
        break;
      case "onPlatform":
        this.setState({
          filters: {
            ...this.state.filters,
            onAndOffNoiz: false,
            offPlatform: false,
            onPlatform: true,
          },
        });
        break;
      default:
        this.setState({});
    }
  }

  changeFilterSearch(name) {
    switch (name) {
      case "searchAll":
        this.setState({
          filters: {
            ...this.state.filters,
            searchAll: !this.state.filters.searchAll,
            searchEmail: false,
            searchNoiz: false,
            searchTwitch: false,
            searchTwitter: false,
          },
        });
        break;
      case "searchEmail":
        this.setState({
          filters: {
            ...this.state.filters,
            searchEmail: !this.state.filters.searchEmail,
            searchAll: false,
          },
        });
        break;
      case "searchNoiz":
        this.setState({
          filters: {
            ...this.state.filters,
            searchNoiz: !this.state.filters.searchNoiz,
            searchAll: false,
          },
        });
        break;
      case "searchTwitch":
        this.setState({
          filters: {
            ...this.state.filters,
            searchTwitch: !this.state.filters.searchTwitch,
            searchAll: false,
          },
        });
        break;
      case "searchTwitter":
        this.setState({
          filters: {
            ...this.state.filters,
            searchTwitter: !this.state.filters.searchTwitter,
            searchAll: false,
          },
        });
        break;
      default:
        this.setState({});
    }
  }

  changeFilterTimePeriod = (value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        timePeriod: value,
      },
    });
  };

  handleAddToQuestChange(selectedOptions) {
    // Max of one selected quest
    this.setState({
      addToQuestObj:
        selectedOptions.length > 1 ? selectedOptions[1] : selectedOptions[0],
    });
  }

  handleCountryFilterChange(selectedOptions) {
    this.setState({
      filters: {
        ...this.state.filters,
        countries: selectedOptions,
      },
    });
  }

  handleGameFilterChange(selectedOptions) {
    this.setState({
      filters: {
        ...this.state.filters,
        games: selectedOptions,
      },
    });
  }

  changeSorting = (value) => {
    switch (value) {
      case "noizAsc":
        this.setState({
          sorting: {
            ...this.state.defaultSorting,
            noizAsc: true,
          },
        });
        break;
      case "noizDesc":
        this.setState({
          sorting: {
            ...this.state.defaultSorting,
            noizDesc: true,
          },
        });
        break;
      default:
        this.setState({});
    }
  };

  toggleEmailSorting = () => {
    if (this.state.sorting.emailAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          emailDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          emailAsc: true,
        },
      });
    }
  };

  togglePaymentEmailSorting = () => {
    if (this.state.sorting.paymentEmailAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          paymentEmailDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          paymentEmailAsc: true,
        },
      });
    }
  };

  toggleNoizSorting = () => {
    if (this.state.sorting.noizAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          noizDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          noizAsc: true,
        },
      });
    }
  };

  toggleFirstNameSorting = () => {
    if (this.state.sorting.firstNameAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          firstNameDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          firstNameAsc: true,
        },
      });
    }
  };

  toggleLastNameSorting = () => {
    if (this.state.sorting.lastNameAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          lastNameDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          lastNameAsc: true,
        },
      });
    }
  };

  toggleTwitchSorting = () => {
    if (this.state.sorting.twitchAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitchDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitchAsc: true,
        },
      });
    }
  };

  toggleTwitchAvgViewersSorting = () => {
    if (this.state.sorting.twitchAvgViewersAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitchAvgViewersDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitchAvgViewersAsc: true,
        },
      });
    }
  };

  toggleTwitch30AverageSorting = () => {
    if (this.state.sorting.twitch30DayAvgViewersAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitch30DayAvgViewersDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitch30DayAvgViewersAsc: true,
        },
      });
    }
  };

  toggleTwitch60AverageSorting = () => {
    if (this.state.sorting.twitch60DayAvgViewersAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitch60DayAvgViewersDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitch60DayAvgViewersAsc: true,
        },
      });
    }
  };

  toggleTwitch90AverageSorting = () => {
    if (this.state.sorting.twitch90DayAvgViewersAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitch90DayAvgViewersDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitch90DayAvgViewersAsc: true,
        },
      });
    }
  };

  toggleTwitterFollowersSorting = () => {
    if (this.state.sorting.twitterFollowersAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitterFollowersDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitterFollowersAsc: true,
        },
      });
    }
  };

  toggleTwitterUsernameSorting = () => {
    if (this.state.sorting.twitterUsernameAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitterUsernameDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitterUsernameAsc: true,
        },
      });
    }
  };

  toggleTwitchFollowersSorting = () => {
    if (this.state.sorting.twitchFollowersAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitchFollowersDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          twitchFollowersAsc: true,
        },
      });
    }
  };

  toggleYoutubeSorting = () => {
    if (this.state.sorting.youtubeAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeAsc: true,
        },
      });
    }
  };

  toggleYoutubeViewsSorting = () => {
    if (this.state.sorting.youtubeViewsAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeViewsDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeViewsAsc: true,
        },
      });
    }
  };

  toggleYoutubeLikesSorting = () => {
    if (this.state.sorting.youtubeLikesAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeLikesDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeLikesAsc: true,
        },
      });
    }
  };

  toggleYoutubeDislikesSorting = () => {
    if (this.state.sorting.youtubeDislikesAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeDislikesDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeDislikesAsc: true,
        },
      });
    }
  };

  toggleYoutubeFavoritesSorting = () => {
    if (this.state.sorting.youtubeFavoritesAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeFavoritesDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeFavoritesAsc: true,
        },
      });
    }
  };

  toggleYoutubeCommentsSorting = () => {
    if (this.state.sorting.youtubeCommentsAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeCommentsDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeCommentsAsc: true,
        },
      });
    }
  };

  toggleYoutubeSubscribersSorting = () => {
    if (this.state.sorting.youtubeSubscribersAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeSubscribersDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeSubscribersAsc: true,
        },
      });
    }
  };

  toggleYoutubeVideosSorting = () => {
    if (this.state.sorting.youtubeVideosAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeVideosDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          youtubeVideosAsc: true,
        },
      });
    }
  };
  toggleCountrySorting = () => {
    if (this.state.sorting.countryAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          countryDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          countryAsc: true,
        },
      });
    }
  };

  toggleCreatedAtSorting = () => {
    if (this.state.sorting.createdAtAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          createdAtDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          createdAtAsc: true,
        },
      });
    }
  };

  toggleQuestsLenghtSorting = () => {
    if (this.state.sorting.questsLengthAsc) {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          questsLengthDesc: true,
        },
      });
    } else {
      this.setState({
        sorting: {
          ...this.state.defaultSorting,
          questsLengthAsc: true,
        },
      });
    }
  };

  changeQuestTab = (value) => {
    this.setState({
      questTab: value,
    });
  };

  generateRowsHTML = (users, userQuests) => {
    const { gameTableUserId, questTableUserId } = this.state;
    const { role } = this.props.auth.user;
    const isAdmin = role === "admin";
    const { t } = this.props;
    if (users.length >= 1) {
      return users.map((user) => {
        const { isFrozen, correctAverage, whoFroze, dateFrozen } =
          getCorrectTwitchAverages(user);

        // Render quests
        const quests = userQuests.filter((q) => q.userId === user._id);
        for (let i = 0; i < quests.length; i++) {
          const questStatus = getUserQuestStatus(
            quests[i].quest,
            quests[i],
            quests[i].timePlaying
          );
          quests[i].userStatus = questStatus;
        }

        const { blacklist } = user;
        const frozenText = `${t("frozenBy")} <strong>${whoFroze} </strong> ${t(
          "on"
        )} <strong>${moment(dateFrozen).format(
          "MMMM DD, YYYY - hh:mm a"
        )} </strong> </br> </br> <strong> ${t("notes")}: </strong> ${
          user.notes ? user.notes : ""
        }`;
        const twitchName = user.connectedAccounts.twitch.username
          ? user.connectedAccounts.twitch.username
          : "";
        const hasTwitter =
          user.connectedAccounts.twitter &&
          user.connectedAccounts.twitter.username;
        const twitterUsername = hasTwitter
          ? user.connectedAccounts.twitter.username
          : "";
        const twitterFollowers = hasTwitter
          ? user.connectedAccounts.twitter.followers_count
          : 0;

        return (
          <React.Fragment>
            <div
              key={user.username}
              className={classNames("row toggable", {
                open: gameTableUserId === user._id,
              })}
            >
              <div className="row-basic">
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.email ? "block" : "none",
                  }}
                >
                  {user.email ? user.email : "-"}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.firstName ? "block" : "none",
                  }}
                >
                  {user.firstName ? user.firstName : "-"}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.lastName ? "block" : "none",
                  }}
                >
                  {user.lastName ? user.lastName : "-"}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.noiz ? "block" : "none",
                  }}
                >
                  {/* <div className={classNames('table-cell')}></div> */}
                  {blacklist ? (
                    <span
                      data-tip={` <strong>${t("notes")}: </strong> ${
                        user.notes ? user.notes : ""
                      } (blacklisted)`}
                    >
                      {user.username}&nbsp;&nbsp;
                      <img
                        src="//images/icons/creep.png"
                        className="deadhead"
                        alt="blacklisted head"
                      />{" "}
                    </span>
                  ) : (
                    <span
                      data-tip={` <strong>${t("notes")}: </strong> ${
                        user.notes ? user.notes : ""
                      }`}
                    >
                      {user.username}
                    </span>
                  )}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.paymentEmail
                      ? "block"
                      : "none",
                  }}
                >
                  {user.paymentEmail ? user.paymentEmail : "-"}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.twitch ? "block" : "none",
                  }}
                >
                  {twitchName ? (
                    <a
                      href={`https://sullygnome.com/channel/${twitchName}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {twitchName || "-"}{" "}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>

                {/* <div className={classNames('table-cell', { dooDooList: blacklist })}>{ blacklist ? (<span data-tip={` NOTES: ${item.notes} (blacklisted)`} >{item.username}</span>) : (<span data-tip={`NOTES: ${item.notes}`}>{item.username}</span>)}</div> */}
                {/* {isFrozen ? override : displayAvg60} &nbsp; {isFrozen ? (<img src="//images/icons/frozen.png" data-tip={`Frozen by ${whoFroze} on ${moment(dateFrozen).format('MMMM DD, YYYY - hh:mm a')}`} className="snowflake" alt="This one frozen, yo" />) : null} </div> */}
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.avgViewers ? "block" : "none",
                  }}
                >
                  {correctAverage ? Math.round(correctAverage) : 0}
                  &nbsp;{" "}
                  {isFrozen ? (
                    <img
                      src={snowFlake}
                      data-tip={frozenText}
                      className="snowflake"
                      alt={t("snowflakeAlt")}
                    />
                  ) : null}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.twitch30DayAvgViewers
                      ? "block"
                      : "none",
                  }}
                >
                  {user.statistics && user.statistics.AVG30
                    ? Math.round(user.statistics.AVG30)
                    : 0}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.twitch60DayAvgViewers
                      ? "block"
                      : "none",
                  }}
                >
                  {user.statistics && user.statistics.AVG60
                    ? Math.round(user.statistics.AVG60)
                    : 0}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.twitch90DayAvgViewers
                      ? "block"
                      : "none",
                  }}
                >
                  {user.statistics && user.statistics.AVG90
                    ? Math.round(user.statistics.AVG90)
                    : 0}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.followers ? "block" : "none",
                  }}
                >
                  {user.connectedAccounts.twitch.followers
                    ? user.connectedAccounts.twitch.followers
                    : 0}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.twitterUsername
                      ? "block"
                      : "none",
                  }}
                >
                  {twitterUsername}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.twitterFollowers
                      ? "block"
                      : "none",
                  }}
                >
                  {twitterFollowers}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.youtube ? "block" : "none",
                  }}
                >
                  {user.connectedAccounts.youtube
                    ? user.connectedAccounts.youtube.username
                    : ""}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.youtubeViews
                      ? "block"
                      : "none",
                  }}
                >
                  {user.connectedAccounts.youtube
                    ? user.connectedAccounts.youtube.totalViewCount
                    : ""}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.youtubeLikes
                      ? "block"
                      : "none",
                  }}
                >
                  {user.connectedAccounts.youtube
                    ? user.connectedAccounts.youtube.totalLikeCount
                    : ""}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.youtubeDislikes
                      ? "block"
                      : "none",
                  }}
                >
                  {user.connectedAccounts.youtube
                    ? user.connectedAccounts.youtube.totalDislikeCount
                    : ""}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.youtubeFavorites
                      ? "block"
                      : "none",
                  }}
                >
                  {user.connectedAccounts.youtube
                    ? user.connectedAccounts.youtube.totalFavoriteCount
                    : ""}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.youtubeComments
                      ? "block"
                      : "none",
                  }}
                >
                  {user.connectedAccounts.youtube
                    ? user.connectedAccounts.youtube.totalCommentCount
                    : ""}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.youtubeSubscribers
                      ? "block"
                      : "none",
                  }}
                >
                  {user.connectedAccounts.youtube
                    ? user.connectedAccounts.youtube.subscriberCount
                    : ""}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.youtubeVideos
                      ? "block"
                      : "none",
                  }}
                >
                  {user.connectedAccounts.youtube
                    ? user.connectedAccounts.youtube.videoCount
                    : ""}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.gender ? "block" : "none",
                  }}
                >
                  {user.gender}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.country ? "block" : "none",
                  }}
                >
                  {user.country}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.timezone ? "block" : "none",
                  }}
                >
                  {user.timezone}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.questsLength
                      ? "block"
                      : "none",
                  }}
                >
                  {user.questsCount ? user.questsCount : 0}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.createdAt ? "block" : "none",
                  }}
                >
                  {moment(user.createdAt).format("MMMM DD, YYYY - hh:mm a")}
                </div>
                <div
                  className="table-cell"
                  style={{
                    display: this.state.toDisplay.actions ? "flex" : "none",
                  }}
                >
                  <Tooltip title={t("editUser")} placement="top">
                    <Link
                      to={`/admin/users/edit/${user._id}`}
                      activeClassName="active"
                      className="table-cell-cta"
                      href={`/admin/users/edit/${user._id}`}
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </Tooltip>
                  {isAdmin && (
                    <Tooltip title={t("addToQuest")} placement="top">
                      <button
                        className="table-cell-cta"
                        onClick={this.toggleAddToQuestModal.bind(
                          this,
                          user._id
                        )}
                      >
                        <i className="fa fa-plus" />
                      </button>
                    </Tooltip>
                  )}
                  {gameTableUserId !== user._id && (
                    <button
                      className="table-cell-cta"
                      style={{
                        display: this.state.toDisplay.games ? "block" : "none",
                      }}
                      onClick={this.toggleGameSubtable.bind(this, user._id)}
                    >
                      {t("showDetails")}
                    </button>
                  )}
                  {gameTableUserId === user._id && (
                    <button
                      className="table-cell-cta"
                      style={{
                        display: this.state.toDisplay.games ? "block" : "none",
                      }}
                      onClick={this.toggleGameSubtable.bind(this, user._id)}
                    >
                      {t("hideDetails")}
                    </button>
                  )}
                  {questTableUserId !== user._id && quests.length > 0 && (
                    <button
                      className="table-cell-cta"
                      style={{
                        display: this.state.toDisplay.quests ? "block" : "none",
                      }}
                      onClick={this.toggleQuestSubtable.bind(this, user._id)}
                    >
                      {t("showQuests")} {`(${quests.length})`}
                    </button>
                  )}
                  {questTableUserId === user._id && (
                    <button
                      className="table-cell-cta"
                      style={{
                        display: this.state.toDisplay.quests ? "block" : "none",
                      }}
                      onClick={this.toggleQuestSubtable.bind(this, user._id)}
                    >
                      {t("hideQuests")}
                    </button>
                  )}
                  {
                    <Tooltip title={t("downloadPDF")} placement="top">
                      <button
                        className="table-cell-cta"
                        onClick={this.openPDFDownloadModal.bind(this, user)}
                      >
                        <i className="fa fa-download" />
                      </button>
                    </Tooltip>
                  }
                </div>
              </div>
              <div className="row-details">
                <section className="row-details-content flex-table">
                  <div className="row">
                    <div className="table-cell headline">
                      <button
                        className={classNames("table-cell-cta", {
                          active: this.state.daysFilter === 1,
                        })}
                        onClick={this.changeMonthlyFilter.bind(this, 1)}
                      >
                        {t("days_30")}
                      </button>
                      <button
                        className={classNames("table-cell-cta", {
                          active: this.state.daysFilter === 2,
                        })}
                        onClick={this.changeMonthlyFilter.bind(this, 2)}
                      >
                        {t("days_60")}
                      </button>
                      <button
                        className={classNames("table-cell-cta", {
                          active: this.state.daysFilter === 3,
                        })}
                        onClick={this.changeMonthlyFilter.bind(this, 3)}
                      >
                        {t("days_90")}
                      </button>
                      <button
                        className={classNames("table-cell-cta", {
                          active: this.state.daysFilter === 4,
                        })}
                        onClick={this.changeMonthlyFilter.bind(this, 4)}
                      >
                        {t("days_120")}
                      </button>
                      <button
                        className={classNames("table-cell-cta", {
                          active: this.state.daysFilter === 5,
                        })}
                        onClick={this.changeMonthlyFilter.bind(this, 5)}
                      >
                        {t("days_120s")}
                      </button>
                    </div>
                  </div>
                  {this.renderGames(user)}
                </section>
              </div>
            </div>
            <div
              key={user.username}
              className={classNames("row toggable", {
                open: questTableUserId === user._id,
              })}
            >
              <div className="row-details">
                <section className="row-details-content">
                  <div className="Global__tabs">
                    <div
                      className={classNames("Global__tab", {
                        active: this.state.questTab === 1,
                      })}
                      onClick={this.changeQuestTab.bind(this, 1)}
                      aria-hidden
                    >
                      {t("all")} {`(${quests.length})`}
                    </div>
                    <div
                      className={classNames("Global__tab", {
                        active: this.state.questTab === 2,
                      })}
                      onClick={this.changeQuestTab.bind(this, 2)}
                      aria-hidden
                    >
                      {t("completed")}{" "}
                      {`(${
                        quests.filter((q) => q.userStatus === "Completed")
                          .length
                      })`}
                    </div>
                    <div
                      className={classNames("Global__tab", {
                        active: this.state.questTab === 3,
                      })}
                      onClick={this.changeQuestTab.bind(this, 3)}
                      aria-hidden
                    >
                      {t("notCompleted")}{" "}
                      {`(${
                        quests.filter((q) => q.userStatus === "Not Completed")
                          .length
                      })`}
                    </div>
                    <div
                      className={classNames("Global__tab", {
                        active: this.state.questTab === 4,
                      })}
                      onClick={this.changeQuestTab.bind(this, 4)}
                      aria-hidden
                    >
                      {t("inProgress")}{" "}
                      {`(${
                        quests.filter((q) => q.userStatus === "In Progress")
                          .length
                      })`}
                    </div>
                    <div
                      className={classNames("Global__tab", {
                        active: this.state.questTab === 5,
                      })}
                      onClick={this.changeQuestTab.bind(this, 5)}
                      aria-hidden
                    >
                      {t("awaitingApproval")}{" "}
                      {`(${
                        quests.filter(
                          (q) => q.userStatus === "Awaiting Approval"
                        ).length
                      })`}
                    </div>
                    <div
                      className={classNames("Global__tab", {
                        active: this.state.questTab === 6,
                      })}
                      onClick={this.changeQuestTab.bind(this, 6)}
                      aria-hidden
                    >
                      {t("rejected")}{" "}
                      {`(${
                        quests.filter((q) => q.userStatus === "Rejected").length
                      })`}
                    </div>
                  </div>
                  {this.state.questTab === 1 && this.renderQuests(user, quests)}
                  {this.state.questTab === 2 &&
                    this.renderQuests(
                      user,
                      quests.filter((q) => q.userStatus === "Completed")
                    )}
                  {this.state.questTab === 3 &&
                    this.renderQuests(
                      user,
                      quests.filter((q) => q.userStatus === "Not Completed")
                    )}
                  {this.state.questTab === 4 &&
                    this.renderQuests(
                      user,
                      quests.filter((q) => q.userStatus === "In Progress")
                    )}
                  {this.state.questTab === 5 &&
                    this.renderQuests(
                      user,
                      quests.filter((q) => q.userStatus === "Awaiting Approval")
                    )}
                  {this.state.questTab === 6 &&
                    this.renderQuests(
                      user,
                      quests.filter((q) => q.userStatus === "Rejected")
                    )}
                </section>
              </div>
              <ReactTooltip
                className="blueTip"
                effect="solid"
                place="top"
                html
              />
            </div>
          </React.Fragment>
        );
      });
    }
    return <div className="NoUsers">{t("noUsersFound")}</div>;
  };

  changeMonthlyFilter = (val) => {
    this.setState({
      daysFilter: val,
    });
  };

  renderQuests = (user, userQuests) => {
    const { role } = this.props.auth.user;
    const isAdmin = role === "admin";
    const sortedQuests = stableSort(
      userQuests,
      getSorting(this.state.order, this.state.orderBy)
    );
    const { t } = this.props;
    const tableHeaders = [
      {
        id: "title",
        numeric: false,
        disablePadding: false,
        label: t("quest"),
      },
      {
        id: "gameName",
        numeric: false,
        disablePadding: false,
        label: t("game"),
      },
      {
        id: "userStatus",
        numeric: false,
        disablePadding: false,
        label: t("status"),
      },
      {
        id: "totalPayment",
        numeric: false,
        disablePadding: false,
        label: t("paymentUponCompletion"),
      },
      {
        id: "paymentMade",
        numeric: false,
        disablePadding: false,
        label: t("paymentMade"),
      },
      {
        id: "expectedPayoutDate",
        numeric: false,
        disablePadding: false,
        label: t("expectedPayoutDate"),
      },
      {
        id: "endDateTime",
        numeric: false,
        disablePadding: false,
        label: t("questEndDate"),
      },
    ];

    // For admins, make room for editing buttons
    if (isAdmin) {
      tableHeaders.unshift({
        id: "",
        numeric: false,
        disablePadding: false,
        label: "",
      });
    }

    const createSortHandler = (property) => (event) => {
      this.handleRequestSort(event, property);
    };

    if (userQuests.length > 0) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((headCell) => (
                <TableHeaderCell
                  key={headCell.id}
                  align="center"
                  padding={headCell.disablePadding ? "none" : "default"}
                  sortDirection="asc"
                >
                  <TableSortLabel
                    active={this.state.orderBy === headCell.id}
                    direction={this.state.order}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={ArrowDropDownIcon}
                    style={{ marginLeft: "30px" }}
                  >
                    {headCell.label}
                    {this.state.orderBy === headCell.id ? (
                      <span style={{ display: "none" }}>
                        {this.state.order === "desc"
                          ? t("sortedDescending")
                          : t("sortedAscending")}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedQuests.map((userQuest) => {
              const { quest } = userQuest;

              let questString = "general";
              if (quest.type === "timed") {
                questString = "timed";
              } else if (
                (quest.type === "tiered" || quest.type === "tiered-one-time") &&
                quest.frequency === "one-time"
              ) {
                questString = "tiered";
              } else if (
                (quest.type === "tiered" ||
                  quest.type === "tiered-multi-days") &&
                quest.frequency === "daily"
              ) {
                questString = "tiered-multiple-days";
              }

              // Formatting for expected payout date (so sorting still works)
              let expectedPayoutDate = moment(userQuest.expectedPayoutDate);
              if (expectedPayoutDate.year() < 1970) {
                expectedPayoutDate = false;
              }

              return (
                <TableRow hover tabIndex={-1} key={quest.id}>
                  {isAdmin && (
                    <TableCell
                      style={{ position: "relative" }}
                      className="quest-title"
                      align="center"
                    >
                      <div style={{ minWidth: "100px" }}>
                        <Link
                          to={`/admin/command-center/${quest.game}/quests/${quest.id}/edit`}
                          activeClassName="active"
                          href={`/admin/command-center/${quest.game}/quests/${quest.id}/edit`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="quest-btn"
                        >
                          <i className="fa fa-pencil" />
                        </Link>
                        <Link
                          to={`/admin/command-center/${quest.game}/quests/${quest.id}/preapproval`}
                          activeClassName="active"
                          href={`/admin/command-center/${quest.game}/quests/${quest.id}/preapproval`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="quest-btn"
                        >
                          <i className="fa fa-check-square-o" />
                        </Link>
                        <Link
                          to={`/admin/command-center/${quest.game}/quests/${quest.id}/${questString}`}
                          activeClassName="active"
                          href={`/admin/command-center/${quest.game}/quests/${quest.id}/${questString}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="quest-btn"
                        >
                          <i className="fa fa-line-chart" />
                        </Link>
                      </div>
                    </TableCell>
                  )}
                  <TableCell align="center" className="quest-title">
                    <span className="full-title">{quest.title}</span>
                    <span style={{ marginLeft: "10px" }}>
                      {quest.title && quest.title.length > 80
                        ? `${quest.title.substring(0, 80)}...`
                        : quest.title}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    {userQuest.gameName ? userQuest.gameName : "-"}
                  </TableCell>
                  <TableCell align="center">{userQuest.userStatus}</TableCell>
                  <TableCell align="center">
                    {userQuest.totalPayment > 0
                      ? `${`$${userQuest.totalPayment.toFixed(2)}`}`
                      : "$0"}
                  </TableCell>
                  <TableCell align="center">
                    {userQuest.paymentMade ? t("yes") : t("no")}
                  </TableCell>
                  <TableCell align="center">
                    {expectedPayoutDate
                      ? moment(expectedPayoutDate).format("MM-DD-YYYY")
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {userQuest.endDateTime
                      ? moment(userQuest.endDateTime).format("MM-DD-YYYY")
                      : "-"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }
    return (
      <div className="row">
        <div className="table-cell">{t("noQuestsAvailable")}</div>
      </div>
    );
  };

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    const desc = this.state.order === "desc" && this.state.orderBy === property;
    this.setState({
      order: desc ? "asc" : "desc",
      orderBy: property,
    });
  };

  renderGames = (user) => {
    const { daysFilter } = this.state;
    const { t } = this.props;
    let filteredGames = [];

    if (daysFilter === 1) {
      filteredGames = user.userGame30;
    } else if (daysFilter === 2) {
      filteredGames = user.userGame60;
    } else if (daysFilter === 3) {
      filteredGames = user.userGame90;
    } else if (daysFilter === 4) {
      filteredGames = user.userGame120;
    } else if (daysFilter === 5) {
      filteredGames = user.userGame365;
    }

    filteredGames = sortBy(filteredGames, ["gameString"]);

    if (filteredGames && filteredGames.length > 0) {
      return (
        <React.Fragment>
          <div className="row">
            <div className="table-cell headline">{`${moment(
              filteredGames[0].greaterDate
            ).format("MMMM DD, YYYY - hh:mm a")} - ${moment(
              filteredGames[0].lowerDate
            ).format("MMMM DD, YYYY - hh:mm a")}`}</div>
          </div>
          <div className="row">
            <div className="table-cell headline">{t("game")}</div>
            <div className="table-cell headline">{t("streamTime")}</div>
            <div className="table-cell headline">{t("totalWatchTime")}</div>
            <div className="table-cell headline">{t("averageViewers")}</div>
            <div className="table-cell headline">{t("peakViewers")}</div>
          </div>
          {map(filteredGames, (mg, index) => (
            <div className="row" key={`${mg.gameString}-${index}`}>
              <div className="table-cell">
                {find(
                  this.state.filters.games,
                  (g) => g.label === mg.gameString
                )
                  ? "* "
                  : ""}
                {mg.gameString}
                {mg.game !== null ? (
                  <React.Fragment>
                    &nbsp;<span className="game-noiz">{t("noiz")}</span>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
              <div className="table-cell">{mg.timePlaying}</div>
              <div className="table-cell">
                {Math.round(mg.timePlaying * mg.averageViewers)}
              </div>
              <div className="table-cell">{Math.round(mg.averageViewers)}</div>
              <div className="table-cell">{mg.peakViewers}</div>
            </div>
          ))}
        </React.Fragment>
      );
    }
    return (
      <div className="row">
        <div className="table-cell">{t("noDataAvailable")}</div>
      </div>
    );
  };

  getCSVHeaders() {
    const { toDisplay } = this.state;
    const headersCSV = [];
    const { t } = this.props;
    if (toDisplay.email)
      headersCSV.push({ label: t("accountEmail"), key: "email" });
    if (toDisplay.firstName)
      headersCSV.push({ label: t("firstName"), key: "firstName" });
    if (toDisplay.lastName)
      headersCSV.push({ label: t("lastName"), key: "lastName" });
    if (toDisplay.noiz)
      headersCSV.push({ label: t("noizUsername"), key: "noiz" });
    if (toDisplay.twitch)
      headersCSV.push({ label: t("twitchUsername"), key: "twitch" });
    if (toDisplay.paymentEmail)
      headersCSV.push({ label: t("paymentEmail"), key: "paymentEmail" });

    if (toDisplay.avgViewers)
      headersCSV.push({ label: t("twitchAvgViewers"), key: "avgViewers" });
    if (toDisplay.twitch30DayAvgViewers)
      headersCSV.push({
        label: t("twitchDayCCV_30"),
        key: "twitch30DayAvgViewers",
      });
    if (toDisplay.twitch60DayAvgViewers)
      headersCSV.push({
        label: t("twitchDayCCV_60"),
        key: "twitch60DayAvgViewers",
      });
    if (toDisplay.twitch90DayAvgViewers)
      headersCSV.push({
        label: t("twitchDayCCV_90"),
        key: "twitch90DayAvgViewers",
      });
    if (toDisplay.followers)
      headersCSV.push({ label: t("twitchFollowers"), key: "followers" });
    if (toDisplay.twitterUsername)
      headersCSV.push({ label: t("twitterUsername"), key: "twitterUsername" });
    if (toDisplay.twitterFollowers)
      headersCSV.push({
        label: t("twitterFollowers"),
        key: "twitterFollowers",
      });

    if (toDisplay.gender)
      headersCSV.push({ label: t("Gender"), key: "gender" });
    if (toDisplay.country)
      headersCSV.push({ label: t("Country"), key: "country" });
    if (toDisplay.timezone)
      headersCSV.push({ label: t("timezone"), key: "timezone" });
    if (toDisplay.questsLength)
      headersCSV.push({ label: t("ofQuests"), key: "questsLength" });
    if (toDisplay.createdAt)
      headersCSV.push({ label: t("userCreatedAt"), key: "createdAt" });

    return headersCSV;
  }

  getCSVData() {
    const { data: users } = this.props.admin.usersCsv;
    const { toDisplay } = this.state;

    const searchData = [];
    const allData = [];

    if (users && users.length > 0) {
      users.forEach((u) => {
        const { correctAverage } = getCorrectTwitchAverages(u);

        const hasTwitter =
          u.connectedAccounts.twitter && u.connectedAccounts.twitter.username;
        const twitterUsername = hasTwitter
          ? u.connectedAccounts.twitter.username
          : "";
        const twitterFollowers = hasTwitter
          ? u.connectedAccounts.twitter.followers_count
          : 0;

        const tempObj = {};
        if (toDisplay.email) tempObj.email = u.email;
        if (toDisplay.firstName) tempObj.firstName = u.firstName;
        if (toDisplay.lastName) tempObj.lastName = u.lastName;
        if (toDisplay.noiz) tempObj.noiz = u.username;
        if (toDisplay.paymentEmail) tempObj.paymentEmail = u.paymentEmail;

        if (toDisplay.twitch)
          tempObj.twitch = u.connectedAccounts.twitch.username;
        if (toDisplay.avgViewers)
          tempObj.avgViewers = correctAverage ? Math.round(correctAverage) : 0;
        if (toDisplay.twitch30DayAvgViewers)
          tempObj.twitch30DayAvgViewers =
            u.statistics && u.statistics.AVG30
              ? Math.round(u.statistics.AVG30)
              : 0;
        if (toDisplay.twitch60DayAvgViewers)
          tempObj.twitch60DayAvgViewers =
            u.statistics && u.statistics.AVG60
              ? Math.round(u.statistics.AVG60)
              : 0;
        if (toDisplay.twitch90DayAvgViewers)
          tempObj.twitch90DayAvgViewers =
            u.statistics && u.statistics.AVG90
              ? Math.round(u.statistics.AVG90)
              : 0;
        if (toDisplay.followers)
          tempObj.followers = u.connectedAccounts.twitch.followers;

        if (toDisplay.twitterUsername)
          tempObj.twitterUsername = twitterUsername;
        if (toDisplay.twitterFollowers)
          tempObj.twitterFollowers = twitterFollowers;

        if (toDisplay.gender) tempObj.gender = u.gender;
        if (toDisplay.country) tempObj.country = u.country;
        if (toDisplay.timezone) tempObj.timezone = u.timezone;
        if (toDisplay.questsLength)
          tempObj.questsLength = u.questsCount ? u.questsCount : 0;
        if (toDisplay.createdAt)
          tempObj.createdAt = moment(u.createdAt).format(
            "MMMM DD, YYYY - hh:mm a"
          );

        searchData.push(tempObj);
        allData.push(tempObj);
      });
    }

    return { allData, searchData };
  }

  getGamesCSVHeaders() {
    const { t } = this.props;
    const headers = [
      { label: t("noiz"), key: "noiz" },
      { label: t("twitch"), key: "twitch" },
      { label: t("accountEmail"), key: "email" },
      { label: t("game"), key: "game" },
      { label: t("timePlaying"), key: "timePlaying" },
      { label: t("averageViewers"), key: "averageViewers" },
      { label: t("peakViewers"), key: "peakViewers" },
    ];

    return headers;
  }

  getGamesCSV(days) {
    const { data: users } = this.props.admin.usersCsv;
    const { filters } = this.state;

    const data = [];

    if (users && users.length > 0) {
      users.forEach((u) => {
        let userGame = [];
        if (days === 30) {
          userGame = u.userGame30;
        }
        if (days === 60) {
          userGame = u.userGame60;
        }
        if (days === 90) {
          userGame = u.userGame90;
        }
        if (days === 120) {
          userGame = u.userGame120;
        }
        if (days === 365) {
          userGame = u.userGame365;
        }
        userGame = sortBy(userGame, ["gameString"]);
        map(userGame, (ug) => {
          const tempObj = {
            noiz: u.username,
            twitch: u.connectedAccounts.twitch.username,
            email: u.email,
            game: ug.gameString,
            timePlaying: ug.timePlaying,
            averageViewers: Math.round(ug.averageViewers),
            peakViewers: ug.peakViewers,
          };
          if (filters.games.length <= 0) {
            data.push(tempObj);
          } else if (find(filters.games, (g) => g.label === ug.gameString)) {
            data.push(tempObj);
          }
        });
      });
    }

    return data;
  }

  getQuestsCSV() {
    const { data: users } = this.props.admin.usersCsv;
    const { userQuests } = this.props.admin.users;
    const { t } = this.props;
    const headers = [
      { label: t("noiz"), key: "noiz" },
      { label: t("twitch"), key: "twitch" },
      { label: t("accountEmail"), key: "email" },
      { label: t("quest"), key: "title" },
      { label: t("game"), key: "gameName" },
      { label: t("status"), key: "userStatus" },
      { label: t("paymentUponCompletion"), key: "totalPayment" },
      { label: t("paymentMade"), key: "paymentMade" },
      { label: t("expectedPayoutDate"), key: "expectedPayoutDate" },
      { label: t("questEndDate"), key: "endDateTime" },
    ];

    const data = [];

    if (users && users.length > 0) {
      users.forEach((u) => {
        const quests = userQuests.filter((q) => q.userId === u._id);

        quests.forEach((q) => {
          // Formatting for expected payout date (so sorting still works)
          let expectedPayoutDate = moment(q.expectedPayoutDate);
          if (expectedPayoutDate.year() < 1970) {
            expectedPayoutDate = false;
          }

          const questStatus = getUserQuestStatus(q.quest, q, q.timePlaying);

          const tempObj = {
            noiz: u.username,
            twitch: u.connectedAccounts.twitch.username,
            email: u.email,
            title: q.quest.title,
            gameName: q.gameName,
            userStatus: questStatus,
            totalPayment: q.totalPayment ? q.totalPayment.toFixed(2) : 0,
            paymentMade: q.paymentMade ? "Yes" : "No",
            expectedPayoutDate: expectedPayoutDate
              ? moment(expectedPayoutDate).format("MM-DD-YYYY")
              : "-",
            endDateTime: q.endDateTime
              ? moment(q.endDateTime).format("MM-DD-YYYY")
              : "-",
          };

          data.push(tempObj);
        });
      });
    }

    return { headers, data };
  }

  renderDownloadModal() {
    const { isLoading } = this.props.admin.usersCsv;
    const csv = this.getCSVData();
    const headersCsv = this.getCSVHeaders();
    const gamesHeadersCsv = this.getGamesCSVHeaders();
    const questsCsv = this.getQuestsCSV();
    const { t } = this.props;

    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button
            onClick={this.closeDownloadModal.bind(this)}
            className="Modal__layer"
            aria-hidden
          />
          <section className="Modal__wrapper Modal__download">
            {isLoading && (
              <div className="Modal__download--spinner">
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
              </div>
            )}
            {!isLoading && (
              <div className="Modal__download--content">
                <div className="Modal__download--header">{t("download")}</div>
                <div className="Modal__download--body">
                  <div className="Modal__download--row">
                    <CSVLink
                      data={csv.allData}
                      headers={headersCsv}
                      filename={`Users-AllResults(${this.renderFiltersString()}).csv`}
                    >
                      <i className="fa fa-download" />
                      &nbsp;{t("allResults")}
                    </CSVLink>
                  </div>
                  <div className="Modal__download--row">
                    <CSVLink
                      data={csv.searchData}
                      headers={headersCsv}
                      filename={`Users-SearchResults(${this.renderFiltersString()}).csv`}
                    >
                      <i className="fa fa-download" />
                      &nbsp;{t("searchResults")}
                    </CSVLink>
                  </div>
                  <div className="Modal__download--row">
                    <CSVLink
                      data={questsCsv.data}
                      headers={questsCsv.headers}
                      filename={`Users-SearchResults(${this.renderFiltersString()}).csv`}
                    >
                      <i className="fa fa-download" />
                      &nbsp;{t("searchResultsQuests")}
                    </CSVLink>
                  </div>
                  <div className="Modal__download--row">
                    {t("searchResultsGames")}:
                  </div>
                  <div className="Modal__download--row">
                    <CSVLink
                      data={this.getGamesCSV(30)}
                      headers={gamesHeadersCsv}
                      filename={`Users-SearchResultsGames30(${this.renderFiltersString()}).csv`}
                    >
                      <i className="fa fa-download" />
                      &nbsp;{t("days_30")}
                    </CSVLink>
                    <CSVLink
                      data={this.getGamesCSV(60)}
                      headers={gamesHeadersCsv}
                      filename={`Users-SearchResultsGames60(${this.renderFiltersString()}).csv`}
                    >
                      <i className="fa fa-download" />
                      &nbsp;{t("days_60")}
                    </CSVLink>
                  </div>
                  <div className="Modal__download--row">
                    <CSVLink
                      data={this.getGamesCSV(90)}
                      headers={gamesHeadersCsv}
                      filename={`Users-SearchResultsGames90(${this.renderFiltersString()}).csv`}
                    >
                      <i className="fa fa-download" />
                      &nbsp;{t("days_90")}
                    </CSVLink>
                    <CSVLink
                      data={this.getGamesCSV(120)}
                      headers={gamesHeadersCsv}
                      filename={`Users-SearchResultsGames120(${this.renderFiltersString()}).csv`}
                    >
                      <i className="fa fa-download" />
                      &nbsp;{t("days_120")}
                    </CSVLink>
                    <CSVLink
                      data={this.getGamesCSV(365)}
                      headers={gamesHeadersCsv}
                      filename={`Users-SearchResultsGames120Plus(${this.renderFiltersString()}).csv`}
                    >
                      <i className="fa fa-download" />
                      &nbsp;{t("days_120s")}
                    </CSVLink>
                  </div>
                </div>
                <div className="Modal__download--footer">
                  <button
                    onClick={this.closeDownloadModal.bind(this)}
                    className="Modal__download--cta"
                  >
                    <i className="fa fa-times" />
                    &nbsp;{t("close")}
                  </button>
                </div>
              </div>
            )}
          </section>
        </section>
      </section>
    );
  }

  renderDisplayAttributesModal() {
    const { t } = this.props;
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button
            onClick={this.closeDisplayModal.bind(this)}
            className="Modal__layer"
            aria-hidden
          />
          <section className="Modal__wrapper DisplayAttributes">
            <div className="DisplayAttributes__title">
              {t("displayAttributesTitle")}
            </div>
            <hr />
            <h2 style={{ marginBottom: "0px" }}> {t("general")}</h2>
            <div className="DisplayAttributes__row">
              <button
                onClick={this.changeDisplayValue.bind(this, "noiz")}
                className={classNames("", {
                  active: this.state.toDisplay.noiz,
                })}
              >
                {t("noizUsername")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "email")}
                className={classNames("", {
                  active: this.state.toDisplay.email,
                })}
              >
                {t("accountEmail")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "firstName")}
                className={classNames("", {
                  active: this.state.toDisplay.firstName,
                })}
              >
                {t("firstName")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "lastName")}
                className={classNames("", {
                  active: this.state.toDisplay.lastName,
                })}
              >
                {t("lastName")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "gender")}
                className={classNames("", {
                  active: this.state.toDisplay.gender,
                })}
              >
                {t("gender")}
              </button>
            </div>
            <div className="DisplayAttributes__row">
              <button
                onClick={this.changeDisplayValue.bind(this, "country")}
                className={classNames("", {
                  active: this.state.toDisplay.country,
                })}
              >
                {t("country")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "timezone")}
                className={classNames("", {
                  active: this.state.toDisplay.timezone,
                })}
              >
                {t("timezone")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "createdAt")}
                className={classNames("", {
                  active: this.state.toDisplay.createdAt,
                })}
              >
                {t("createdAt")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "questsLength")}
                className={classNames("", {
                  active: this.state.toDisplay.questsLength,
                })}
              >
                {t("ofQuests")}
              </button>

              <button
                onClick={this.changeDisplayValue.bind(this, "paymentEmail")}
                className={classNames("", {
                  active: this.state.toDisplay.paymentEmail,
                })}
              >
                {t("paymentEmail")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "games")}
                className={classNames("", {
                  active: this.state.toDisplay.games,
                })}
              >
                {t("games")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "quests")}
                className={classNames("", {
                  active: this.state.toDisplay.quests,
                })}
              >
                {t("quests")}
              </button>
            </div>

            <hr />
            <h2 style={{ marginBottom: "0px" }}>
              {" "}
              {t("twitch")} <i className="fa fa-twitch" />
            </h2>
            <div className="DisplayAttributes__row">
              <button
                onClick={this.changeDisplayValue.bind(this, "twitch")}
                className={classNames("", {
                  active: this.state.toDisplay.twitch,
                })}
              >
                {t("usernameTwitch")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "avgViewers")}
                className={classNames("", {
                  active: this.state.toDisplay.avgViewers,
                })}
              >
                {t("averageViewers")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "followers")}
                className={classNames("", {
                  active: this.state.toDisplay.followers,
                })}
              >
                {t("followers")}
              </button>
            </div>
            <div className="DisplayAttributes__row">
              <button
                onClick={this.changeDisplayValue.bind(
                  this,
                  "twitch30DayAvgViewers"
                )}
                className={classNames("", {
                  active: this.state.toDisplay.twitch30DayAvgViewers,
                })}
              >
                {t("dayCCV_30")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(
                  this,
                  "twitch60DayAvgViewers"
                )}
                className={classNames("", {
                  active: this.state.toDisplay.twitch60DayAvgViewers,
                })}
              >
                {t("dayCCV_60")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(
                  this,
                  "twitch90DayAvgViewers"
                )}
                className={classNames("", {
                  active: this.state.toDisplay.twitch90DayAvgViewers,
                })}
              >
                {t("dayCCV_90")}
              </button>
            </div>

            <hr />
            <h2 style={{ marginBottom: "0px" }}>
              {" "}
              {t("dayCCV_90")}{" "}
              <TwitterIcon style={{ width: "20px" }} alt="Twitter" />{" "}
            </h2>
            <div className="DisplayAttributes__row">
              <button
                onClick={this.changeDisplayValue.bind(this, "twitterUsername")}
                className={classNames("", {
                  active: this.state.toDisplay.twitterUsername,
                })}
              >
                {t("username")}
              </button>
              <button
                onClick={this.changeDisplayValue.bind(this, "twitterFollowers")}
                className={classNames("", {
                  active: this.state.toDisplay.twitterFollowers,
                })}
              >
                {t("twitterFollowers")}
              </button>
            </div>
            <hr />

            {/*
            <hr/>
            <h2 style={{marginBottom: '0px'}}> Youtube <i className="fa fa-youtube" /></h2>
            <div className="DisplayAttributes__row">
                <button
                  onClick={this.changeDisplayValue.bind(this, 'youtubeLikes')}
                  className={classNames('', { active: this.state.toDisplay.youtubeLikes })}
                >
                  Like Count
                </button>
                <button
                  onClick={this.changeDisplayValue.bind(this, 'youtubeDislikes')}
                  className={classNames('', { active: this.state.toDisplay.youtubeDislikes })}
                >
                  Dislike Count
                </button>
                <button
                  onClick={this.changeDisplayValue.bind(this, 'youtubeFavorites')}
                  className={classNames('', { active: this.state.toDisplay.youtubeFavorites })}
                >
                  Favorite Count
                </button>
                <button
                  onClick={this.changeDisplayValue.bind(this, 'youtube')}
                  className={classNames('', { active: this.state.toDisplay.youtube })}
                >
                  Username
                </button>
                <button
                  onClick={this.changeDisplayValue.bind(this, 'youtubeViews')}
                  className={classNames('', { active: this.state.toDisplay.youtubeViews })}
                >
                  View Count
                </button>
            </div>
            <div className="DisplayAttributes__row">
                <button
                  onClick={this.changeDisplayValue.bind(this, 'youtubeComments')}
                  className={classNames('', { active: this.state.toDisplay.youtubeComments })}
                >
                  Comment Count
                </button>
                <button
                  onClick={this.changeDisplayValue.bind(this, 'youtubeSubscribers')}
                  className={classNames('', { active: this.state.toDisplay.youtubeSubscribers })}
                >
                  Subscriber Count
                </button>
                <button
                  onClick={this.changeDisplayValue.bind(this, 'youtubeVideos')}
                  className={classNames('', { active: this.state.toDisplay.youtubeVideos })}
                >
                  Video Count
                </button>
            </div>
            */}
            <div className="DisplayAttributes__close">
              <button onClick={this.closeDisplayModal.bind(this)}>
                <i className="fa fa-times" /> {t("close")}
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  renderFiltersModal() {
    const { filterError } = this.state;
    const { role } = this.props.auth.user;
    const isAdmin = role === "admin";
    const { t } = this.props;

    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button
            onClick={this.closeFiltersModal.bind(this)}
            className="Modal__layer"
            aria-hidden
          />
          <section className="Modal__wrapper Filters">
            <div className="Filters__title">{t("selectTheFilters")}</div>

            <div className="Filters__section">
              <div className="Filters__title left">
                {t("reportForASpecificUser")}:
              </div>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  className="Filters__field__label"
                  style={{ marginRight: "10px" }}
                >
                  {t("searchBy")}:
                </div>
                <div
                  className="Filters__fieldGroup"
                  style={{ marginTop: "0px", marginBottom: "0px" }}
                >
                  <button
                    onClick={this.changeFilterSearch.bind(
                      this,
                      "searchAll",
                      false
                    )}
                    className={classNames("", {
                      active: this.state.filters.searchAll,
                    })}
                  >
                    {t("all")}
                  </button>
                  <button
                    onClick={this.changeFilterSearch.bind(
                      this,
                      "searchEmail",
                      false
                    )}
                    className={classNames("", {
                      active: this.state.filters.searchEmail,
                    })}
                  >
                    {t("email")}
                  </button>
                  <button
                    onClick={this.changeFilterSearch.bind(this, "searchNoiz")}
                    className={classNames("", {
                      active: this.state.filters.searchNoiz,
                    })}
                  >
                    {t("noiz")}
                  </button>
                  <button
                    onClick={this.changeFilterSearch.bind(this, "searchTwitch")}
                    className={classNames("", {
                      active: this.state.filters.searchTwitch,
                    })}
                  >
                    <i className="fa fa-twitch" /> {t("twitch")}
                  </button>
                  <button
                    onClick={this.changeFilterSearch.bind(
                      this,
                      "searchTwitter"
                    )}
                    className={classNames("", {
                      active: this.state.filters.searchTwitter,
                    })}
                  >
                    <TinyTwitter m={0} p={0} fontSize="small" alt="Twitter" />{" "}
                    {t("twitter")}
                  </button>
                </div>
              </div>
              <div className="Filters__fieldGroup" style={{ marginTop: "0px" }}>
                <input
                  type="text"
                  value={this.state.filters.searchValue}
                  onChange={this.handleSearchValueChange.bind(this)}
                  style={{ display: "flex" }}
                />
                {filterError && (
                  <span className="error"> {t("filterError")}. </span>
                )}
              </div>
            </div>

            {isAdmin && (
              <React.Fragment>
                {/* <div className="Filters__fieldGroup">
                  <div className="Filters__title left">
                    Bulk Reports:
                  </div>
                  <div className="Filters__section">
                    <div style={{ display: 'flex' }}>
                      <div className="Filters__field__label" style={{ marginRight: '10px' }}>
                        Source:
                      </div>
                      <button
                        onClick={this.changeFilterAccounts.bind(this, 'accountAll')}
                        className={classNames('', { active: this.state.filters.accountAll })}
                      >
                        All
                      </button>
                      <button
                        onClick={this.changeFilterAccounts.bind(this, 'accountTwitch')}
                        className={classNames('', { active: this.state.filters.accountTwitch })}
                      >
                        <i className="fa fa-twitch" /> Twitch
                      </button>
                      <button
                        onClick={this.changeFilterAccounts.bind(this, 'accountTwitter')}
                        className={classNames('', { active: this.state.filters.accountTwitter })}
                      >
                        <TinyTwitter m={0} p={0} fontSize='small'  alt="Twitter" /> Twitter
                      </button>
                    </div>
                  </div>
                </div>
                */}
                <div className="Filters__section">
                  <div className="Filters__title left">
                    {t("filterOptions")}:
                  </div>
                  <div className="Filters__fieldGroup">
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <div
                        className="Filters__field__label"
                        style={{
                          marginRight: "10px",
                          width: "100px",
                          textAlign: "left",
                        }}
                      >
                        {t("viewerCount")}:
                      </div>
                      <input
                        type="number"
                        min={0}
                        placeholder={t("min")}
                        value={this.state.filters.searchMinAvgViewersValue}
                        onChange={this.handleSearchMinAvgViewersValueChange.bind(
                          this
                        )}
                        className="minMax"
                      />
                      <span style={{ margin: "0px 10px" }}> - </span>
                      <input
                        type="number"
                        min={0}
                        placeholder={t("max")}
                        value={this.state.filters.searchMaxAvgViewersValue}
                        onChange={this.handleSearchMaxAvgViewersValueChange.bind(
                          this
                        )}
                        className="minMax"
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="Filters__fieldGroup">
                  <div className="Filters__section">
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <div className="Filters__field__label" style={{ marginRight: '10px', width: '100px', textAlign: 'left' }}>
                        # Of Quests:
                      </div>
                      <input
                        type="number"
                        min={0}
                        placeholder="Min"
                        value={this.state.filters.questsLengthMinValue}
                        onChange={this.handleQuestsLengthMinValueChange.bind(this)}
                        className="minMax"
                      />
                      <span style={{ margin: '0px 10px' }}> - </span>
                      <input
                        type="number"
                        min={0}
                        placeholder="Max"
                        value={this.state.filters.questsLengthMaxValue}
                        onChange={this.handleQuestsLengthMaxValueChange.bind(this)}
                        className="minMax"
                      />
                    </div>
                  </div>
                </div> */}

                <div className="Filters__section">
                  <div className="Filters__title left">{t("gender")}:</div>
                  <div className="Filters__fieldGroup">
                    <button
                      onClick={this.changeFilterGender.bind(this, "genderAll")}
                      className={classNames("", {
                        active: this.state.filters.genderAll,
                      })}
                    >
                      {t("all")}
                    </button>
                    <button
                      onClick={this.changeFilterGender.bind(this, "genderMale")}
                      className={classNames("", {
                        active: this.state.filters.genderMale,
                      })}
                    >
                      <i className="fa fa-mars" /> {t("male")}
                    </button>
                    <button
                      onClick={this.changeFilterGender.bind(
                        this,
                        "genderFemale",
                        false
                      )}
                      className={classNames("", {
                        active: this.state.filters.genderFemale,
                      })}
                    >
                      <i className="fa fa-venus" /> {t("female")}
                    </button>
                    <button
                      onClick={this.changeFilterGender.bind(
                        this,
                        "genderOther",
                        false
                      )}
                      className={classNames("", {
                        active: this.state.filters.genderOther,
                      })}
                    >
                      {t("other")}
                    </button>
                  </div>
                </div>

                <div className="Filters__section">
                  <div className="Filters__title left">
                    {t("noizStatusType")}:
                  </div>
                  <div className="Filters__fieldGroup">
                    <button
                      onClick={this.changeFilterOffPlatform.bind(
                        this,
                        "onAndOffNoiz"
                      )}
                      className={classNames("", {
                        active: this.state.filters.onAndOffNoiz,
                      })}
                    >
                      {t("allUsers")}
                    </button>
                    <button
                      onClick={this.changeFilterOffPlatform.bind(
                        this,
                        "offPlatform"
                      )}
                      className={classNames("", {
                        active: this.state.filters.offPlatform,
                      })}
                    >
                      {t("offPlatform")}
                    </button>
                    <button
                      onClick={this.changeFilterOffPlatform.bind(
                        this,
                        "onPlatform",
                        false
                      )}
                      className={classNames("", {
                        active: this.state.filters.onPlatform,
                      })}
                    >
                      {t("onPlatform")}
                    </button>
                  </div>
                </div>

                <div className="Filters__section">
                  <div className="Filters__title left">{t("countries")}:</div>
                  <Select
                    value={this.state.filters.countries}
                    closeMenuOnSelect={false}
                    components={makeAnimated()}
                    isMulti
                    onChange={(...args) =>
                      this.handleCountryFilterChange(...args)
                    }
                    placeholder={t("selectCountry")}
                    options={this.countryOptions}
                    styles={colourStyles}
                  />
                </div>
                {/*
                <div className="Filters__fieldGroup">
                  <div className="Filters__field__label">
                    Games:
                  </div>
                  <Select
                    value={this.state.filters.games}
                    closeMenuOnSelect={false}
                    components={makeAnimated()}
                    isMulti
                    onChange={(...args) => this.handleGameFilterChange(...args)}
                    options={gameOptions}
                    styles={colourStyles}
                  />
                </div>
                <div className="Filters__fieldGroup">
                  <div className="Filters__field__label">
                    Time Period:
                  </div>
                  <button
                    onClick={this.changeFilterTimePeriod.bind(this, 1)}
                    className={classNames('', { active: this.state.filters.timePeriod === 1 })}
                  >
                    30
                  </button>
                  <button
                    onClick={this.changeFilterTimePeriod.bind(this, 2)}
                    className={classNames('', { active: this.state.filters.timePeriod === 2 })}
                  >
                    60
                  </button>
                  <button
                    onClick={this.changeFilterTimePeriod.bind(this, 3)}
                    className={classNames('', { active: this.state.filters.timePeriod === 3 })}
                  >
                    90
                  </button>
                  <button
                    onClick={this.changeFilterTimePeriod.bind(this, 4)}
                    className={classNames('', { active: this.state.filters.timePeriod === 4 })}
                  >
                    120
                  </button>
                  <button
                    onClick={this.changeFilterTimePeriod.bind(this, 5)}
                    className={classNames('', { active: this.state.filters.timePeriod === 5 })}
                  >
                    +120
                  </button>
                </div>
                */}
              </React.Fragment>
            )}
            <div className="Filters__close">
              <button onClick={this.closeFiltersModal.bind(this)}>
                <i className="fa fa-times" /> {t("cancel")}
              </button>
              <button onClick={this.clearFiltersModal.bind(this)}>
                <i className="fa fa-eraser" /> {t("clear")}
              </button>
              <button onClick={this.applyFilters.bind(this)}>
                <i className="fa fa-check" /> {t("apply")}
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  renderFiltersString = () => {
    const { filters } = this.state;
    const filterArray = [];
    const { t } = this.props;

    if (filters.genderMale) {
      filterArray.push(t("males"));
    } else if (filters.genderFemale) {
      filterArray.push(t("females"));
    } else if (filters.genderOther) {
      filterArray.push(t("otherGender"));
    }

    if (filters.accountAll) {
      // filterArray.push('Twitch');
    } else if (filters.accountTwitch) {
      filterArray.push("Twitch");
    }

    if (filters.searchAll) {
      filterArray.push(`${t("email")}: ${filters.searchValue}`);
      filterArray.push(`Noiz: ${filters.searchValue}`);
      filterArray.push(`Twitch: ${filters.searchValue}`);
    } else if (filters.searchEmail) {
      filterArray.push(`${t("email")}: ${filters.searchValue}`);
    } else if (filters.searchNoiz) {
      filterArray.push(`Noiz: ${filters.searchValue}`);
    } else if (filters.searchTwitch) {
      filterArray.push(`Twitch: ${filters.searchValue}`);
    } else if (filters.searchTwitter) {
      filterArray.push(`Twitter: ${filters.searchValue}`);
    }

    if (filters.searchMinAvgViewersValue) {
      filterArray.push(
        `${t("minimumAverageViewers")}: ${filters.searchMinAvgViewersValue}`
      );
    }

    if (filters.searchMaxAvgViewersValue) {
      filterArray.push(
        `${t("maximumAverageViewers")}: ${filters.searchMaxAvgViewersValue}`
      );
    }

    if (filters.questsLengthMaxValue) {
      filterArray.push(
        `${t("maximumQuestsCount")}: ${filters.questsLengthMaxValue}`
      );
    }

    if (filters.questsLengthMinValue) {
      filterArray.push(
        `${t("minimumQuestsCount")}: ${filters.questsLengthMinValue}`
      );
    }

    if (filters.countries.length > 0) {
      const countriesLabels = map(filters.countries, "label");
      filterArray.push(`${t("countries")}: ${countriesLabels.join(" - ")}`);
    }

    if (filters.games.length > 0) {
      const gamesLabels = map(filters.games, "label");
      filterArray.push(`${t("games")}: ${gamesLabels.join(" - ")}`);
      if (filters.timePeriod === 1) {
        filterArray.push(t("days_30"));
      } else if (filters.timePeriod === 2) {
        filterArray.push(t("days_60"));
      } else if (filters.timePeriod === 3) {
        filterArray.push(t("days_90"));
      } else if (filters.timePeriod === 4) {
        filterArray.push(t("days_120"));
      } else if (filters.timePeriod === 5) {
        filterArray.push(t("days_120s"));
      }
    }

    if (filterArray.length === 0) {
      return "None";
    }

    return filterArray.join(", ");
  };

  renderAddToQuestModal() {
    const user = this.props.admin.users
      ? this.props.admin.users.data.filter(
          (user) => user._id === this.state.addToQuestUserId
        )
      : false;
    const username = user.length ? user[0].username : "";
    const { isLoading, data: quests } = this.props.admin.quests;
    const { userQuests } = this.props.admin.users;
    const { t } = this.props;

    const questItems = [];

    if (!isLoading) {
      // Remove disabled quests
      let questOptions = quests.filter((quest) => quest.status !== "disabled");

      // Remove quests this user is in
      let userQuestIds = userQuests.filter(
        (q) => q.userId === this.state.addToQuestUserId
      );
      userQuestIds = userQuestIds.map((uq) => uq.quest.id);
      questOptions = questOptions.filter(
        (quest) => !userQuestIds.includes(quest.id)
      );

      // Sort quests in descending creation date order
      questOptions.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));

      // Limit to 200 quests
      questOptions.splice(200);

      // Map to options
      questOptions.forEach((q) => {
        questItems.push({
          value: q.id,
          label: q.title,
          slug: q.slug,
        });
      });
    }
    const offPlatform = user[0]?.offPlatform;
    const qValue = !!this.state.addToQuestObj?.value;
    const showQuestClickThrough = offPlatform && qValue;
    console.log(questItems);
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button
            onClick={this.toggleAddToQuestModal.bind(
              this,
              this.state.addToQuestUserId
            )}
            className="Modal__layer"
            aria-hidden
          />
          <section className="Modal__wrapper AddToQuest">
            <div className="AddToQuest__title">
              {t("addToQuestTitle")}{" "}
              <span style={{ color: "#47b8d4" }}> {username} </span> to
            </div>
            {isLoading && (
              <div className="UserList__loading">
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
              </div>
            )}
            {!isLoading && (
              <React.Fragment>
                <div className="Filters__fieldGroup">
                  <div className="Filters__field__label" />
                  <Select
                    value={this.state.addToQuestObj}
                    closeMenuOnSelect={false}
                    components={makeAnimated()}
                    isMulti
                    onChange={(...args) => this.handleAddToQuestChange(...args)}
                    options={questItems}
                    styles={colourStyles}
                  />
                </div>
                <div className="AddToQuest__close">
                  {showQuestClickThrough && (
                    <div className="quest-acceptance-address">
                      <div>{t("questAcceptanceAddress")}</div>
                      <div data-tip={t("dataTip")} onClick={copyText}>
                        {`${theDomain}/quests/${
                          this.state.addToQuestObj.slug
                            ? this.state.addToQuestObj.slug
                            : "<QuestSLUG>"
                        }/${this.state.addToQuestUserId}`}
                      </div>
                    </div>
                  )}
                </div>
                <div className="AddToQuest__close">
                  <button
                    onClick={this.toggleAddToQuestModal.bind(
                      this,
                      this.state.addToQuestUserId
                    )}
                  >
                    <i className="fa fa-times" /> {t("cancel")}
                  </button>
                  <button
                    onClick={this.addToQuest.bind(
                      this,
                      this.state.addToQuestUserId,
                      this.state.addToQuestObj
                    )}
                  >
                    {t("addToQuest")}
                  </button>
                </div>
                <ReactTooltip
                  className="blueTip"
                  effect="solid"
                  place="bottom"
                  html
                />
              </React.Fragment>
            )}
          </section>
        </section>
      </section>
    );
  }

  render() {
    const { role } = this.props.auth.user;
    const isAdmin = role === "admin";
    const {
      data: users,
      pagination,
      isLoading,
      userQuests,
    } = this.props.admin.users;

    const { t } = this.props;

    return (
      <section className="AUserList">
        <section className="AUserList__wrapper">
          {/* <AdminTabs {...this.props} /> */}
          {isAdmin && (
            <h2 className="AUserList__title">
              {t("users")}:{" "}
              <span>
                {pagination.totalUsers} ({t("page")} {pagination.pageNumber}{" "}
                {t("of")} {pagination.totalPages})
              </span>
            </h2>
          )}
          {/*
          <div className="AUserList__actions">
            <Link
              to="/admin/users/create"
              activeClassName="active"
              href="/admin/users/create"
            >
              <button>Create User</button>
            </Link>
          </div>
          */}
          {isAdmin && (
            <div className="AUserList__actions">
              <Link
                to="/admin/users/create-brand"
                activeClassName="active"
                href="/admin/users/create-brand"
              >
                <button>{t("createBrandUser")}</button>
              </Link>
            </div>
          )}
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <div className="ToggleTable__header__left">
                {/* isAdmin && (
                  <button
                    className={classNames('')}
                    onClick={this.openDownloadModal.bind(this)}
                  >
                    Download
                  </button>
                )
                */}
              </div>
              <div className="ToggleTable__header__right">
                {isAdmin && (
                  <button
                    className={classNames("")}
                    onClick={this.openDisplayModal.bind(this)}
                  >
                    {t("display")}
                  </button>
                )}
                <button
                  className={classNames("")}
                  onClick={this.openFiltersModal.bind(this)}
                >
                  {t("filters")}
                </button>
                <button
                  className="regular"
                  onClick={this.paginationLeft.bind(this)}
                >
                  <i className="fa fa-arrow-left" />
                </button>
                <button
                  className="regular"
                  onClick={this.paginationRight.bind(this)}
                >
                  <i className="fa fa-arrow-right" />
                </button>
              </div>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">
                    {t("filters")}: {this.renderFiltersString()}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.email ? "block" : "none",
                    }}
                    onClick={this.toggleEmailSorting.bind(this)}
                    aria-hidden
                  >
                    {t("accountEmail")}&nbsp;
                    {this.state.sorting.emailAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.emailDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.emailAsc &&
                      !this.state.sorting.emailDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.firstName
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleFirstNameSorting.bind(this)}
                    aria-hidden
                  >
                    {t("firstName")}&nbsp;
                    {this.state.sorting.firstNameAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.firstNameDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.firstNameAsc &&
                      !this.state.sorting.firstNameDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.lastName ? "block" : "none",
                    }}
                    onClick={this.toggleLastNameSorting.bind(this)}
                    aria-hidden
                  >
                    {t("lastName")}&nbsp;
                    {this.state.sorting.lastNameAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.lastNameDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.lastNameAsc &&
                      !this.state.sorting.lastNameDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.noiz ? "block" : "none",
                    }}
                    onClick={this.toggleNoizSorting.bind(this)}
                    aria-hidden
                  >
                    Noiz&nbsp;
                    {this.state.sorting.noizAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.noizDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.noizAsc &&
                      !this.state.sorting.noizDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.paymentEmail
                        ? "block"
                        : "none",
                    }}
                    onClick={this.togglePaymentEmailSorting.bind(this)}
                    aria-hidden
                  >
                    {t("paymentEmail")}&nbsp;
                    {this.state.sorting.paymentEmailAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.paymentEmailDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.paymentEmailAsc &&
                      !this.state.sorting.paymentEmailDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.twitch ? "block" : "none",
                    }}
                    onClick={this.toggleTwitchSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-twitch" /> Twitch&nbsp;
                    {this.state.sorting.twitchAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.twitchDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.twitchAsc &&
                      !this.state.sorting.twitchDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.avgViewers
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleTwitchAvgViewersSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-twitch" /> {t("avgViewers")}&nbsp;
                    {this.state.sorting.twitchAvgViewersAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.twitchAvgViewersDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.twitchAvgViewersAsc &&
                      !this.state.sorting.twitchAvgViewersDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.twitch30DayAvgViewers
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleTwitch30AverageSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-twitch" /> {t("dayCCV_30")}&nbsp;
                    {this.state.sorting.twitch30DayAvgViewersAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.twitch30DayAvgViewersDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.twitch30DayAvgViewersAsc &&
                      !this.state.sorting.twitch30DayAvgViewersDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.twitch60DayAvgViewers
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleTwitch60AverageSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-twitch" /> {t("dayCCV_60")}&nbsp;
                    {this.state.sorting.twitch60DayAvgViewersAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.twitch60DayAvgViewersDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.twitch60DayAvgViewersAsc &&
                      !this.state.sorting.twitch60DayAvgViewersDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.twitch90DayAvgViewers
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleTwitch90AverageSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-twitch" /> {t("dayCCV_90")}&nbsp;
                    {this.state.sorting.twitch90DayAvgViewersAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.twitch90DayAvgViewersDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.twitch90DayAvgViewersAsc &&
                      !this.state.sorting.twitch90DayAvgViewersDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.followers
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleTwitchFollowersSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-twitch" /> {t("followers")}&nbsp;
                    {this.state.sorting.twitchFollowersAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.twitchFollowersDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.twitchFollowersAsc &&
                      !this.state.sorting.twitchFollowersDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.twitterUsername
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleTwitterUsernameSorting.bind(this)}
                    aria-hidden
                  >
                    <TwitterIcon /> Twitter &nbsp;
                    {this.state.sorting.twitterUsernameAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.twitterUsernameDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.twitterUsernameAsc &&
                      !this.state.sorting.twitterUsernameDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.twitterFollowers
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleTwitterUsernameSorting.bind(this)}
                    aria-hidden
                  >
                    <TwitterIcon />
                    {t("followers")}&nbsp;
                    {this.state.sorting.twitterFollowersAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.twitterFollowersDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.twitterFollowersAsc &&
                      !this.state.sorting.twitterFollowersDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.youtube ? "block" : "none",
                    }}
                    onClick={this.toggleYoutubeSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-youtube" /> Youtube&nbsp;
                    {this.state.sorting.youtubeAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.youtubeDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.youtubeAsc &&
                      !this.state.sorting.youtubeDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.youtubeViews
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleYoutubeViewsSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-youtube" /> {t("viewCount")}&nbsp;
                    {this.state.sorting.youtubeViewsAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.youtubeViewsDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.youtubeViewsAsc &&
                      !this.state.sorting.youtubeViewsDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.youtubeLikes
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleYoutubeLikesSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-youtube" />
                    {t("likeCount")}&nbsp;
                    {this.state.sorting.youtubeLikesAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.youtubeLikesDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.youtubeLikesAsc &&
                      !this.state.sorting.youtubeLikesDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.youtubeDislikes
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleYoutubeDislikesSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-youtube" /> {t("dislikeCount")}&nbsp;
                    {this.state.sorting.youtubeDislikesAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.youtubeDislikesDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.youtubeDislikesAsc &&
                      !this.state.sorting.youtubeDislikesDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.youtubeFavorites
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleYoutubeFavoritesSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-youtube" /> {t("favoriteCount")}&nbsp;
                    {this.state.sorting.youtubeFavoritesAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.youtubeFavoritesDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.youtubeFavoritesAsc &&
                      !this.state.sorting.youtubeFavoritesDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.youtubeComments
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleYoutubeCommentsSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-youtube" />
                    {t("commentCount")}&nbsp;
                    {this.state.sorting.youtubeCommentsAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.youtubeCommentsDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.youtubeCommentsAsc &&
                      !this.state.sorting.youtubeCommentsDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.youtubeSubscribers
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleYoutubeSubscribersSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-youtube" />
                    {t("subscriberCount")}&nbsp;
                    {this.state.sorting.youtubeSubscribersAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.youtubeSubscribersDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.youtubeSubscribersAsc &&
                      !this.state.sorting.youtubeSubscribersDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.youtubeVideos
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleYoutubeVideosSorting.bind(this)}
                    aria-hidden
                  >
                    <i className="fa fa-youtube" /> {t("videoCount")}&nbsp;
                    {this.state.sorting.youtubeVideosAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.youtubeVideosDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.youtubeVideosAsc &&
                      !this.state.sorting.youtubeVideosDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline"
                    style={{
                      display: this.state.toDisplay.gender ? "block" : "none",
                    }}
                  >
                    {t("gender")}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.country ? "block" : "none",
                    }}
                    onClick={this.toggleCountrySorting.bind(this)}
                    aria-hidden
                  >
                    {t("country")}&nbsp;
                    {this.state.sorting.countryAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.countryDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.countryAsc &&
                      !this.state.sorting.countryDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline"
                    style={{
                      display: this.state.toDisplay.timezone ? "block" : "none",
                    }}
                  >
                    {t("timezone")}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.questsLength
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleQuestsLenghtSorting.bind(this)}
                    aria-hidden
                  >
                    {t("ofQuests")}&nbsp;
                    {this.state.sorting.questsLengthAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.questsLengthDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.questsLengthAsc &&
                      !this.state.sorting.questsLengthDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline headline-clickable"
                    style={{
                      display: this.state.toDisplay.createdAt
                        ? "block"
                        : "none",
                    }}
                    onClick={this.toggleCreatedAtSorting.bind(this)}
                    aria-hidden
                  >
                    {t("createdAt")}&nbsp;
                    {this.state.sorting.createdAtAsc && (
                      <i className="fa fa-sort-asc" />
                    )}
                    {this.state.sorting.createdAtDesc && (
                      <i className="fa fa-sort-desc" />
                    )}
                    {!this.state.sorting.createdAtAsc &&
                      !this.state.sorting.createdAtDesc && (
                        <i className="fa fa-sort" />
                      )}
                  </div>
                  <div
                    className="table-cell headline"
                    style={{
                      display: this.state.toDisplay.actions ? "block" : "none",
                    }}
                  >
                    {t("actions")}
                  </div>
                </div>
                {isLoading && (
                  <div className="UserList__loading">
                    <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                  </div>
                )}
                {!isLoading && this.generateRowsHTML(users, userQuests)}
              </section>
            </section>
            <section className="ToggleTable__footer">
              <section className="ToggleTable__footer__left">
                <button onClick={this.paginationLeft.bind(this)}>
                  <i className="fa fa-caret-left" />
                </button>
              </section>
              <section className="ToggleTable__footer__right">
                <button onClick={this.paginationRight.bind(this)}>
                  <i className="fa fa-caret-right" />
                </button>
              </section>
            </section>
          </section>
        </section>
        {this.state.downloadModal && this.renderDownloadModal()}
        {this.state.pdfDownloadModal && (
          <UserPDFModal
            closeModal={this.closePDFDownloadModal.bind(this)}
            user={this.state.downloadUser}
          />
        )}
        {this.state.displayModal && this.renderDisplayAttributesModal()}
        {this.state.filtersModal && this.renderFiltersModal()}
        {this.state.sortingModal && this.renderSortingModal()}
        {this.state.addToQuestModalOpen && this.renderAddToQuestModal()}
      </section>
    );
  }
}

UserList.propTypes = {};

export default withTranslation("userList")(UserList);
