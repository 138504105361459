import React from "react";
import { useTranslation } from "react-i18next";
import PixelToad from "../../../images/client-portal/8-bit-toad-bw@3x.png";
import "./NothingHere.scss";

const NothingHere = ({ text }) => {
  const { t } = useTranslation("nothingHere");

  return (
    <div className="NothingHere mt-10">
      <div className="nothing-here-image">
        <img src={PixelToad} alt={t("noThingAlt")} />
      </div>
      <div>{text || t("noThing")}</div>
    </div>
  );
};

export default NothingHere;
