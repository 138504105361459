import React, { useState, useRef } from "react";

import {
  List,
  ListItem,
  ListItemText,
  Button,
  Avatar,
  ListItemAvatar,
  Paper,
  Popper,
  ClickAwayListener,
  Box,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { multiLanguage } from "../../utils/util";

import EnIcon from "./assets/flag_uk.svg";
import BrazilIcon from "./assets/flag_of_brazil.png";

const map = {
  en: EnIcon,
  "pt-BR": BrazilIcon,
};

function Language() {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { i18n, t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = (val) => {
    i18n.changeLanguage(val);
    setOpen(false);
    window.location.reload();
  };

  return (
    <Box sx={{ marginLeft: "20px", zIndex: "2" }} className="language">
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          component="img"
          src={map[multiLanguage]}
          sx={{ width: 40, height: 30, borderRadius: 0 }}
        ></Box>
        <Button ref={anchorRef} style={{ zIndex: 1 }} onClick={handleOpen}>
          {t("chooseLanguage")}
        </Button>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 50],
              },
            },
          ],
        }}
      >
        <Paper style={{ backgroundColor: "#fff" }}>
          <ClickAwayListener onClickAway={handleClose}>
            <List
              component="nav"
              style={{ width: 208, flexDirection: "column" }}
            >
              <ListItem
                alignItems="center"
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid #d8d8d8",
                }}
                onClick={() => handleToggle("en")}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{ width: 40, height: 30, borderRadius: 0 }}
                    alt="English"
                    src={EnIcon}
                  />
                </ListItemAvatar>
                <ListItemText primary="English" style={{ color: "#000" }} />
              </ListItem>
              <ListItem
                alignItems="center"
                style={{ cursor: "pointer" }}
                onClick={() => handleToggle("pt-BR")}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{ width: 40, height: 30, borderRadius: 0 }}
                    alt="Brazil"
                    src={BrazilIcon}
                  />
                </ListItemAvatar>
                <ListItemText primary="Brazil" style={{ color: "#000" }} />
              </ListItem>
            </List>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  );
}

export default Language;
