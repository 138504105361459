import React from "react";
import "./BrandSafetyModal.scss";
import "./ReputationSystemModal.scss";
import classNames from "classnames";
import { without } from "lodash";
import closeButton from "../../../../components/images/close-button.svg";
import { withTranslation } from "react-i18next";

class BrandSafetyModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const { content } = this.props;
    this.state = {
      comments: content && content.comments ? content.comments : "",
      selected: content && content.selected ? content.selected : [],
    };
  }

  handleSelect(value) {
    const { selected } = this.state;
    if (selected.indexOf(value) === -1) {
      this.setState({
        selected: [...selected, value],
      });
    } else {
      this.setState({
        selected: [...without(selected, value)],
      });
    }
  }

  onChange(event) {
    this.handleSelect(event.target.value);
  }

  handleCommentsChange = (event) => {
    this.setState({
      comments: event.target.value,
    });
  };

  validate = () => {
    const { comments, selected } = this.state;
    this.props.onSubmit(comments, selected);
  };

  render() {
    const { isEditing, username, t } = this.props;
    const { comments, selected } = this.state;
    const options = [
      { label: t("controversy"), value: "controversy" },
      { label: t("fraud"), value: "fraud" },
      { label: t("negativity"), value: "negativity" },
      { label: t("substances"), value: "substances" },
      { label: t("criminality"), value: "criminality" },
      { label: t("language"), value: "language" },
      { label: t("sexuality"), value: "sexuality" },
      { label: t("violence"), value: "violence" },
    ];

    return (
      <React.Fragment>
        <div className="NoizModalOverlay" />
        <div className="ReputationModal">
          {isEditing ? (
            <img
              alt="close-button"
              className="close"
              src={closeButton}
              onClick={this.props.onClose.bind(this)}
            />
          ) : null}
          <h2>
            {" "}
            {t("brandSafety")} <span className="username">{username}</span>?{" "}
          </h2>

          <span className="label">{t("standards")}</span>
          <div className="optionsListBrand">
            {options.map((opt) => (
              <span
                onClick={this.handleSelect.bind(this, opt.value)}
                className={classNames("option", {
                  selected: selected.indexOf(opt.value) > -1,
                })}
              >
                <input
                  checked={selected.indexOf(opt.value) > -1}
                  onChange={this.onChange.bind(this)}
                  value={opt.value}
                  type="checkbox"
                />{" "}
                {opt.label}
              </span>
            ))}
          </div>

          <span className="label">{t("CommentsTimeCode")}</span>
          <textarea
            name="comments"
            id="comments"
            value={comments}
            onChange={this.handleCommentsChange}
          />
          <span className="note">{t("note")}</span>

          <div className="buttons brand-safety">
            <button
              className="CTAButton__white"
              onClick={this.props.onBack.bind(this)}
            >
              {" "}
              {t("back")}{" "}
            </button>
            <button
              className="CTAButton__purple"
              onClick={this.validate.bind(this)}
            >
              {" "}
              {t("submit")}{" "}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

BrandSafetyModal.propTypes = {};

export default withTranslation("brandSafetyModal")(BrandSafetyModal);
