import React from 'react';

import { SetViewContext } from '../PortalContext';
import Icon from '../../../../global/images/icons/Icon';
import { activateIntercom } from '../../../../utils/functions';
import { useTranslation } from 'react-i18next';

import './AddNew.scss';



const AddNew = ({ type }) => {
  const { t } = useTranslation("portalAddNew");
const AddNewDict = {
  quest: {
    title: t("addInstantQuest"),
    background: "#0075e4",
    iconBackground: "#259bff",
    link: "/portal/quests/new",
    component: "QuestEdit",
  },
  product: {
    title: t("addNewProduct"),
    background: "#fd8d82",
    iconBackground: "#feb4a9",
    link: "/portal/products/new",
    component: "ProductEdit",
  },
  promoted: {
    title: t("addPromotedQuest"),
    background: "#a142ff",
    iconBackground: "##c868ff",
    link: "/portal/promoted-quest/new",
    component: "QuestEdit",
  },
  managedCampaign: {
    title: t("managedCampaign"),
    background: "#00c8a8",
    iconBackground: "#00e6c7",
    link: "",
    func: activateIntercom,
  },
};
  return (
    <SetViewContext.Consumer>
      {({ setView, view }) => {
        const defaultFunc = () =>
          setView({
            ...view,
            component: AddNewDict[type].component,
            currentProduct: "",
            currentQuest: "",
          });
        const func = AddNewDict[type].func || defaultFunc;
        return (
          <div className="AddNew_Wrapper">
            <div
              className="AddNew"
              onClick={func}
              style={{ backgroundColor: AddNewDict[type].background }}
            >
              <div className="AddNew__Details">
                <span>{AddNewDict[type].title}</span>
                <div
                  className="AddNew__Icon"
                  style={{ backgroundColor: AddNewDict[type].iconBackground }}
                >
                  {Icon({ color: "white", name: "plus" })}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </SetViewContext.Consumer>
  );
};

export default AddNew;
