import api from "../utils/api";
import { getErrorResponse } from "../utils/apiErrorHandler";

/* eslint-disable import/prefer-default-export */

export const getCCGameList = (action$) =>
  action$.ofType("GET_CC_GAME_LIST").mergeMap((action) =>
    api
      .getCCGameList(action.payload.values, action.payload.token)
      .map((response) => ({
        type: "GET_CC_GAME_LIST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_GAME_LIST_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_GAME_LIST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_GAME_LIST_ERROR", error })
      )
      .startWith({ type: "GET_CC_GAME_LIST_PENDING", payload: action.payload })
  );

export const uploadKeys = (action$) =>
  action$.ofType("UPLOAD_KEYS").mergeMap((action) =>
    api
      .uploadKeys(
        action.payload.multipartFormData,
        action.payload.gameId,
        action.payload.token
      )
      .map((response) => ({ type: "UPLOAD_KEYS_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("UPLOAD_KEYS_ABORTED"))
      .defaultIfEmpty({ type: "UPLOAD_KEYS_CANCELED" })
      .catch((error) => getErrorResponse({ type: "UPLOAD_KEYS_ERROR", error }))
      .startWith({ type: "UPLOAD_KEYS_PENDING", payload: action.payload })
  );

export const getGameKeys = (action$) =>
  action$.ofType("GET_GAME_KEYS").mergeMap((action) =>
    api
      .getGameKeys(action.payload.gameId, action.payload.token)
      .map((response) => ({ type: "GET_GAME_KEYS_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("GET_GAME_KEYS_ABORTED"))
      .defaultIfEmpty({ type: "GET_GAME_KEYS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_GAME_KEYS_ERROR", error })
      )
      .startWith({ type: "GET_GAME_KEYS_PENDING", payload: action.payload })
  );

export const deleteGameKeys = (action$) =>
  action$.ofType("DELETE_GAME_KEYS").mergeMap((action) =>
    api
      .deleteGameKeys(action.payload.gameKeyIds, action.payload.token)
      .map((response) => ({
        type: "DELETE_GAME_KEYS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("DELETE_GAME_KEYS_ABORTED"))
      .defaultIfEmpty({ type: "DELETE_GAME_KEYS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "DELETE_GAME_KEYS_ERROR", error })
      )
      .startWith({ type: "DELETE_GAME_KEYS_PENDING", payload: action.payload })
  );

export const getBountyPredictions = (action$) =>
  action$.ofType("GET_BOUNTY_PREDICTIONS").mergeMap((action) =>
    api
      .getBountyPredictions(action.payload.bountyId, action.payload.token)
      .map((response) => ({
        type: "GET_BOUNTY_PREDICTIONS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_BOUNTY_PREDICTIONS_ABORTED"))
      .defaultIfEmpty({ type: "GET_BOUNTY_PREDICTIONS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_BOUNTY_PREDICTIONS_ERROR", error })
      )
      .startWith({
        type: "GET_BOUNTY_PREDICTIONS_PENDING",
        payload: action.payload,
      })
  );

export const getWhiteList = (action$) =>
  action$.ofType("GET_WHITE_LIST").mergeMap((action) =>
    api
      .getWhiteList(action.payload.gameId, action.payload.token)
      .map((response) => ({
        type: "GET_WHITE_LIST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_WHITE_LIST_ABORTED"))
      .defaultIfEmpty({ type: "GET_WHITE_LIST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_WHITE_LIST_ERROR", error })
      )
      .startWith({ type: "GET_WHITE_LIST_PENDING", payload: action.payload })
  );

export const toggleUserInWhiteList = (action$) =>
  action$.ofType("TOGGLE_USER_IN_WHITE_LIST").mergeMap((action) =>
    api
      .toggleUserInWhiteList(
        action.payload.gameId,
        action.payload.userId,
        action.payload.token
      )
      .map((response) => ({
        type: "TOGGLE_USER_IN_WHITE_LIST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("TOGGLE_USER_IN_WHITE_LIST_ABORTED"))
      .defaultIfEmpty({ type: "TOGGLE_USER_IN_WHITE_LIST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "TOGGLE_USER_IN_WHITE_LIST_ERROR", error })
      )
      .startWith({
        type: "TOGGLE_USER_IN_WHITE_LIST_PENDING",
        payload: action.payload,
      })
  );

export const createUserAndWhiteListIt = (action$) =>
  action$.ofType("CREATE_USER_AND_WHITE_LIST_IT").mergeMap((action) =>
    api
      .createUserAndWhiteListIt(
        action.payload.gameId,
        action.payload.username,
        action.payload.email,
        action.payload.token
      )
      .map((response) => ({
        type: "CREATE_USER_AND_WHITE_LIST_IT_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("CREATE_USER_AND_WHITE_LIST_IT_ABORTED"))
      .defaultIfEmpty({ type: "CREATE_USER_AND_WHITE_LIST_IT_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "CREATE_USER_AND_WHITE_LIST_IT_ERROR", error })
      )
      .startWith({
        type: "CREATE_USER_AND_WHITE_LIST_IT_PENDING",
        payload: action.payload,
      })
  );

export const findUsers = (action$) =>
  action$.ofType("FIND_USERS").mergeMap((action) =>
    api
      .findUsers(action.payload.query, action.payload.token)
      .map((response) => ({ type: "FIND_USERS_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("FIND_USERS_ABORTED"))
      .defaultIfEmpty({ type: "FIND_USERS_CANCELED" })
      .catch((error) => getErrorResponse({ type: "FIND_USERS_ERROR", error }))
      .startWith({ type: "FIND_USERS_PENDING", payload: action.payload })
  );

export const getGameActivity = (action$) =>
  action$.ofType("GET_GAME_ACTIVITY").mergeMap((action) =>
    api
      .getGameActivity(
        action.payload.startDate,
        action.payload.endDate,
        action.payload.gameName,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_GAME_ACTIVITY_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_GAME_ACTIVITY_ABORTED"))
      .defaultIfEmpty({ type: "GET_GAME_ACTIVITY_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_GAME_ACTIVITY_ERROR", error })
      )
      .startWith({ type: "GET_GAME_ACTIVITY_PENDING", payload: action.payload })
  );

export const distributeGameKeys = (action$) =>
  action$.ofType("DISTRIBUTE_GAME_KEYS").mergeMap((action) =>
    api
      .distributeGameKeys(action.payload.gameId, action.payload.token)
      .map((response) => ({
        type: "DISTRIBUTE_GAME_KEYS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("DISTRIBUTE_GAME_KEYS_ABORTED"))
      .defaultIfEmpty({ type: "DISTRIBUTE_GAME_KEYS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "DISTRIBUTE_GAME_KEYS_ERROR", error })
      )
      .startWith({
        type: "DISTRIBUTE_GAME_KEYS_PENDING",
        payload: action.payload,
      })
  );

export const assignNewKeyPlatforms = (action$) =>
  action$.ofType("ASSIGN_NEW_KEY_PLATFORMS").mergeMap((action) =>
    api
      .assignNewKeyPlatforms(
        action.payload.gameId,
        action.payload.platformId,
        action.payload.keyIds,
        action.payload.token
      )
      .map((response) => ({
        type: "ASSIGN_NEW_KEY_PLATFORMS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("ASSIGN_NEW_KEY_PLATFORMS_ABORTED"))
      .defaultIfEmpty({ type: "ASSIGN_NEW_KEY_PLATFORMS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "ASSIGN_NEW_KEY_PLATFORMS_ERROR", error })
      )
      .startWith({
        type: "ASSIGN_NEW_KEY_PLATFORMS_PENDING",
        payload: action.payload,
      })
  );

export const getCCQuestList = (action$) =>
  action$.ofType("GET_CC_QUEST_LIST").mergeMap((action) =>
    api
      .getCCQuestList(action.payload.token)
      .map((response) => ({
        type: "GET_CC_QUEST_LIST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_QUEST_LIST_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_QUEST_LIST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_QUEST_LIST_ERROR", error })
      )
      .startWith({ type: "GET_CC_QUEST_LIST_PENDING", payload: action.payload })
  );

export const getCCQuest = (action$) =>
  action$.ofType("GET_CC_QUEST").mergeMap((action) =>
    api
      .getCCQuest(action.payload.questId, action.payload.token)
      .map((response) => ({ type: "GET_CC_QUEST_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("GET_CC_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_QUEST_CANCELED" })
      .catch((error) => getErrorResponse({ type: "GET_CC_QUEST_ERROR", error }))
      .startWith({ type: "GET_CC_QUEST_PENDING", payload: action.payload })
  );

export const copyCCQuest = (action$) =>
  action$.ofType("COPY_CC_QUEST").mergeMap((action) =>
    api
      .copyCCQuest(
        action.payload.questId,
        action.payload.gameId,
        action.payload.token
      )
      .map((response) => ({ type: "COPY_CC_QUEST_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("COPY_CC_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "COPY_CC_QUEST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "COPY_CC_QUEST_ERROR", error })
      )
      .startWith({ type: "COPY_CC_QUEST_PENDING", payload: action.payload })
  );

export const createCCQuest = (action$) =>
  action$.ofType("CREATE_CC_QUEST").mergeMap((action) =>
    api
      .createCCQuest(action.payload.quest, action.payload.token)
      .map((response) => ({
        type: "CREATE_CC_QUEST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("CREATE_CC_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "CREATE_CC_QUEST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "CREATE_CC_QUEST_ERROR", error })
      )
      .startWith({ type: "CREATE_CC_QUEST_PENDING", payload: action.payload })
  );

export const updateCCQuest = (action$) =>
  action$.ofType("UPDATE_CC_QUEST").mergeMap((action) =>
    api
      .updateCCQuest(
        action.payload.questId,
        action.payload.quest,
        action.payload.token
      )
      .map((response) => ({
        type: "UPDATE_CC_QUEST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("UPDATE_CC_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "UPDATE_CC_QUEST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "UPDATE_CC_QUEST_ERROR", error })
      )
      .startWith({ type: "UPDATE_CC_QUEST_PENDING", payload: action.payload })
  );

export const getCCQuestActivity = (action$) =>
  action$.ofType("GET_CC_QUEST_ACTIVITY").mergeMap((action) =>
    api
      .getCCQuestActivity(action.payload.questId, action.payload.token)
      .map((response) => ({
        type: "GET_CC_QUEST_ACTIVITY_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_QUEST_ACTIVITY_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_QUEST_ACTIVITY_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_QUEST_ACTIVITY_ERROR", error })
      )
      .startWith({
        type: "GET_CC_QUEST_ACTIVITY_PENDING",
        payload: action.payload,
      })
  );

export const getCCQuestSales = (action$) =>
  action$.ofType("GET_CC_QUEST_SALES").mergeMap((action) =>
    api
      .getCCQuestSales(action.payload.questId, action.payload.token)
      .map((response) => ({
        type: "GET_CC_QUEST_SALES_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_QUEST_SALES_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_QUEST_SALES_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_QUEST_SALES_ERROR", error })
      )
      .startWith({
        type: "GET_CC_QUEST_SALES_PENDING",
        payload: action.payload,
      })
  );

export const getCCQuestTimed = (action$) =>
  action$.ofType("GET_CC_QUEST_TIMED").mergeMap((action) =>
    api
      .getCCQuestTimed(action.payload.questId, action.payload.token)
      .map((response) => ({
        type: "GET_CC_QUEST_TIMED_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_QUEST_TIMED_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_QUEST_TIMED_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_QUEST_TIMED_ERROR", error })
      )
      .startWith({
        type: "GET_CC_QUEST_TIMED_PENDING",
        payload: action.payload,
      })
  );

export const deleteQuest = (action$) =>
  action$.ofType("DELETE_QUEST").mergeMap((action) =>
    api
      .deleteQuest(action.payload.questId, action.payload.token)
      .map((response) => ({ type: "DELETE_QUEST_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("DELETE_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "DELETE_QUEST_CANCELED" })
      .catch((error) => getErrorResponse({ type: "DELETE_QUEST_ERROR", error }))
      .startWith({ type: "DELETE_QUEST_PENDING", payload: action.payload })
  );

export const getCCQuestGeneral = (action$) =>
  action$.ofType("GET_CC_QUEST_GENERAL").mergeMap((action) =>
    api
      .getCCQuestGeneral(
        action.payload.numPage,
        action.payload.pageSize,
        action.payload.tab,
        action.payload.questId,
        action.payload.filtering,
        action.payload.type,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_CC_QUEST_GENERAL_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_QUEST_GENERAL_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_QUEST_GENERAL_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_QUEST_GENERAL_ERROR", error })
      )
      .startWith({
        type: "GET_CC_QUEST_GENERAL_PENDING",
        payload: action.payload,
      })
  );

export const getCCQuestGeneralCsv = (action$) =>
  action$.ofType("GET_CC_QUEST_GENERAL_CSV").mergeMap((action) =>
    api
      .getCCQuestGeneralCsv(
        action.payload.numPage,
        action.payload.pageSize,
        action.payload.tab,
        action.payload.questId,
        action.payload.filtering,
        action.payload.type,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_CC_QUEST_GENERAL_CSV_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_QUEST_GENERAL_CSV_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_QUEST_GENERAL_CSV_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_QUEST_GENERAL_CSV_ERROR", error })
      )
      .startWith({
        type: "GET_CC_QUEST_GENERAL_CSV_PENDING",
        payload: action.payload,
      })
  );

export const getCCQuestAffiliate = (action$) =>
  action$.ofType("GET_CC_QUEST_AFFILIATE").mergeMap((action) =>
    api
      .getCCQuestAffiliate(action.payload.questId, action.payload.token)
      .map((response) => ({
        type: "GET_CC_QUEST_AFFILIATE_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_QUEST_AFFILIATE_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_QUEST_AFFILIATE_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_QUEST_AFFILIATE_ERROR", error })
      )
      .startWith({
        type: "GET_CC_QUEST_AFFILIATE_PENDING",
        payload: action.payload,
      })
  );

export const postCCPreapproval = (action$) =>
  action$.ofType("POST_CC_PREAPPROVAL").mergeMap((action) =>
    api
      .postCCPreapproval(
        action.payload.questId,
        action.payload.userId,
        action.payload.status,
        action.payload.tab,
        action.payload.numPage,
        action.payload.pageSize,
        action.payload.token
      )
      .map((response) => ({
        type: "POST_CC_PREAPPROVAL_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("POST_CC_PREAPPROVAL_ABORTED"))
      .defaultIfEmpty({ type: "POST_CC_PREAPPROVAL_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "POST_CC_PREAPPROVAL_ERROR", error })
      )
      .startWith({
        type: "POST_CC_PREAPPROVAL_PENDING",
        payload: action.payload,
      })
  );

export const postCCRequirement = (action$) =>
  action$.ofType("POST_CC_REQUIREMENT").mergeMap((action) =>
    api
      .postCCRequirement(action.payload.data, action.payload.token)
      .map((response) => ({
        type: "POST_CC_REQUIREMENT_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("POST_CC_REQUIREMENT_ABORTED"))
      .defaultIfEmpty({ type: "POST_CC_REQUIREMENT_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "POST_CC_REQUIREMENT_ERROR", error })
      )
      .startWith({
        type: "POST_CC_REQUIREMENT_PENDING",
        payload: action.payload,
      })
  );

export const postCCRequirementMultiple = (action$) =>
  action$.ofType("POST_CC_REQUIREMENT_MULTIPLE").mergeMap((action) =>
    api
      .postCCRequirementMultiple(
        action.payload.data,
        action.payload.questId,
        action.payload.token
      )
      .map((response) => ({
        type: "POST_CC_REQUIREMENT_MULTIPLE_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("POST_CC_REQUIREMENT_MULTIPLE_ABORTED"))
      .defaultIfEmpty({ type: "POST_CC_REQUIREMENT_MULTIPLE_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "POST_CC_REQUIREMENT_MULTIPLE_ERROR", error })
      )
      .startWith({
        type: "POST_CC_REQUIREMENT_MULTIPLE_PENDING",
        payload: action.payload,
      })
  );

export const postCCAffiliateResolution = (action$) =>
  action$.ofType("POST_CC_AFFILIATE_RESOLUTION").mergeMap((action) =>
    api
      .postCCAffiliateResolution(action.payload.data, action.payload.token)
      .map((response) => ({
        type: "POST_CC_AFFILIATE_RESOLUTION_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("POST_CC_AFFILIATE_RESOLUTION_ABORTED"))
      .defaultIfEmpty({ type: "POST_CC_AFFILIATE_RESOLUTION_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "POST_CC_AFFILIATE_RESOLUTION_ERROR", error })
      )
      .startWith({
        type: "POST_CC_AFFILIATE_RESOLUTION_PENDING",
        payload: action.payload,
      })
  );

export const getCCQuestTiered = (action$) =>
  action$.ofType("GET_CC_QUEST_TIERED").mergeMap((action) =>
    api
      .getCCQuestTiered(action.payload.questId, action.payload.token)
      .map((response) => ({
        type: "GET_CC_QUEST_TIERED_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_QUEST_TIERED_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_QUEST_TIERED_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_QUEST_TIERED_ERROR", error })
      )
      .startWith({
        type: "GET_CC_QUEST_TIERED_PENDING",
        payload: action.payload,
      })
  );

export const getCCQuestTieredMultipleDays = (action$) =>
  action$.ofType("GET_CC_QUEST_TIERED_MULTIPLE_DAYS").mergeMap((action) =>
    api
      .getCCQuestTieredMultipleDays(
        action.payload.questId,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_CC_QUEST_TIERED_MULTIPLE_DAYS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_QUEST_TIERED_MULTIPLE_DAYS_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_QUEST_TIERED_MULTIPLE_DAYS_CANCELED" })
      .catch((error) =>
        getErrorResponse({
          type: "GET_CC_QUEST_TIERED_MULTIPLE_DAYS_ERROR",
          error,
        })
      )
      .startWith({
        type: "GET_CC_QUEST_TIERED_MULTIPLE_DAYS_PENDING",
        payload: action.payload,
      })
  );

export const requestUploadCCImageUrl = (action$) =>
  action$.ofType("REQUEST_UPLOAD_CC_IMAGE_URL").mergeMap((action) =>
    api
      .requestUploadCCImageUrl(
        action.payload.slug,
        action.payload.type,
        action.payload.token
      )
      .map((response) => ({
        type: "REQUEST_UPLOAD_CC_IMAGE_URL_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("REQUEST_UPLOAD_CC_IMAGE_URL_ABORTED"))
      .defaultIfEmpty({ type: "REQUEST_UPLOAD_CC_IMAGE_URL_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "REQUEST_UPLOAD_CC_IMAGE_URL_ERROR", error })
      )
      .startWith({
        type: "REQUEST_UPLOAD_CC_IMAGE_URL_PENDING",
        payload: action.payload,
      })
  );

export const getCCCampaignListByGame = (action$) =>
  action$.ofType("GET_CC_CAMPAIGN_LIST_BY_GAME").mergeMap((action) =>
    api
      .getCCCampaignListByGame(action.payload.gameId, action.payload.token)
      .map((response) => ({
        type: "GET_CC_CAMPAIGN_LIST_BY_GAME_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_CAMPAIGN_LIST_BY_GAME_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_CAMPAIGN_LIST_BY_GAME_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_CAMPAIGN_LIST_BY_GAME_ERROR", error })
      )
      .startWith({
        type: "GET_CC_CAMPAIGN_LIST_BY_GAME_PENDING",
        payload: action.payload,
      })
  );

export const getCCCampaign = (action$) =>
  action$.ofType("GET_CC_CAMPAIGN").mergeMap((action) =>
    api
      .getCCCampaign(
        action.payload.gameId,
        action.payload.campaignId,
        action.payload.token
      )
      .map((response) => ({
        type: "GET_CC_CAMPAIGN_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_CAMPAIGN_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_CAMPAIGN_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_CAMPAIGN_ERROR", error })
      )
      .startWith({ type: "GET_CC_CAMPAIGN_PENDING", payload: action.payload })
  );

export const createCCCampaign = (action$) =>
  action$.ofType("CREATE_CC_CAMPAIGN").mergeMap((action) =>
    api
      .createCCCampaign(action.payload.campaignData, action.payload.token)
      .map((response) => ({
        type: "CREATE_CC_CAMPAIGN_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("CREATE_CC_CAMPAIGN_ABORTED"))
      .defaultIfEmpty({ type: "CREATE_CC_CAMPAIGN_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "CREATE_CC_CAMPAIGN_ERROR", error })
      )
      .startWith({
        type: "CREATE_CC_CAMPAIGN_PENDING",
        payload: action.payload,
      })
  );

export const updateCCCampaign = (action$) =>
  action$.ofType("UPDATE_CC_CAMPAIGN").mergeMap((action) =>
    api
      .updateCCCampaign(
        action.payload.campaignId,
        action.payload.campaignData,
        action.payload.token
      )
      .map((response) => ({
        type: "UPDATE_CC_CAMPAIGN_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("UPDATE_CC_CAMPAIGN_ABORTED"))
      .defaultIfEmpty({ type: "UPDATE_CC_CAMPAIGN_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "UPDATE_CC_CAMPAIGN_ERROR", error })
      )
      .startWith({
        type: "UPDATE_CC_CAMPAIGN_PENDING",
        payload: action.payload,
      })
  );

export const updateCCGamesPlatform = (action$) =>
  action$.ofType("UPDATE_CC_GAMES_PLATFORM").mergeMap((action) =>
    api
      .updateCCGamesPlatform(
        action.payload.gameIds,
        action.payload.platformIds,
        action.payload.token
      )
      .map((response) => ({
        type: "UPDATE_CC_GAMES_PLATFORM_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("UPDATE_CC_GAMES_PLATFORM_ABORTED"))
      .defaultIfEmpty({ type: "UPDATE_CC_GAMES_PLATFORM_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "UPDATE_CC_GAMES_PLATFORM_ERROR", error })
      )
      .startWith({
        type: "UPDATE_CC_GAMES_PLATFORM_PENDING",
        payload: action.payload,
      })
  );

export const updateCCQuestsPlatform = (action$) =>
  action$.ofType("UPDATE_CC_QUESTS_PLATFORM").mergeMap((action) =>
    api
      .updateCCQuestsPlatform(
        action.payload.questIds,
        action.payload.platformIds,
        action.payload.token
      )
      .map((response) => ({
        type: "UPDATE_CC_QUESTS_PLATFORM_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("UPDATE_CC_QUESTS_PLATFORM_ABORTED"))
      .defaultIfEmpty({ type: "UPDATE_CC_QUESTS_PLATFORM_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "UPDATE_CC_QUESTS_PLATFORM_ERROR", error })
      )
      .startWith({
        type: "UPDATE_CC_QUESTS_PLATFORM_PENDING",
        payload: action.payload,
      })
  );

export const addCCUserQuest = (action$) =>
  action$.ofType("ADD_CC_USER_QUEST").mergeMap((action) =>
    api
      .addCCUserQuest(
        action.payload.userId,
        action.payload.questId,
        action.payload.status,
        action.payload.token
      )
      .map((response) => ({
        type: "ADD_CC_USER_QUEST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("ADD_CC_USER_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "ADD_CC_USER_QUEST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "ADD_CC_USER_QUEST_ERROR", error })
      )
      .startWith({ type: "ADD_CC_USER_QUEST_PENDING", payload: action.payload })
  );

export const removeCCUserQuest = (action$) =>
  action$.ofType("REMOVE_CC_USER_QUEST").mergeMap((action) =>
    api
      .removeCCUserQuest(
        action.payload.userId,
        action.payload.questId,
        action.payload.token
      )
      .map((response) => ({
        type: "REMOVE_CC_USER_QUEST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("REMOVE_CC_USER_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "REMOVE_CC_USER_QUEST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "REMOVE_CC_USER_QUEST_ERROR", error })
      )
      .startWith({
        type: "REMOVE_CC_USER_QUEST_PENDING",
        payload: action.payload,
      })
  );

export const moveCCUserQuest = (action$) =>
  action$.ofType("MOVE_CC_USER_QUEST").mergeMap((action) =>
    api
      .moveCCUserQuest(
        action.payload.userId,
        action.payload.fromQuestId,
        action.payload.toQuestId,
        action.payload.status,
        action.payload.token
      )
      .map((response) => ({
        type: "MOVE_CC_USER_QUEST_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("MOVE_CC_USER_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "MOVE_CC_USER_QUEST_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "MOVE_CC_USER_QUEST_ERROR", error })
      )
      .startWith({
        type: "MOVE_CC_USER_QUEST_PENDING",
        payload: action.payload,
      })
  );

export const getCCActiveQuestsByGame = (action$) =>
  action$.ofType("GET_CC_ACTIVE_QUESTS_BY_GAME").mergeMap((action) =>
    api
      .getCCActiveQuestsByGame(action.payload.gameId, action.payload.token)
      .map((response) => ({
        type: "GET_CC_ACTIVE_QUESTS_BY_GAME_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_CC_ACTIVE_QUESTS_BY_GAME_ABORTED"))
      .defaultIfEmpty({ type: "GET_CC_ACTIVE_QUESTS_BY_GAME_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_CC_ACTIVE_QUESTS_BY_GAME_ERROR", error })
      )
      .startWith({
        type: "GET_CC_ACTIVE_QUESTS_BY_GAME_PENDING",
        payload: action.payload,
      })
  );

export const getLinksDashboard = (action$) =>
  action$.ofType("GET_LINKS_DASHBOARD").mergeMap((action) =>
    api
      .getLinksDashboard(action.payload.campaignId, action.payload.token)
      .map((response) => ({
        type: "GET_LINKS_DASHBOARD_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("GET_LINKS_DASHBOARD_ABORTED"))
      .defaultIfEmpty({ type: "GET_LINKS_DASHBOARD_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_LINKS_DASHBOARD_ERROR", error })
      )
      .startWith({
        type: "GET_LINKS_DASHBOARD_PENDING",
        payload: action.payload,
      })
  );

export const uploadLinks = (action$) =>
  action$.ofType("UPLOAD_LINKS").mergeMap((action) =>
    api
      .uploadLinks(
        action.payload.multipartFormData,
        action.payload.campaignId,
        action.payload.token
      )
      .map((response) => ({ type: "UPLOAD_LINKS_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("UPLOAD_LINKS_ABORTED"))
      .defaultIfEmpty({ type: "UPLOAD_LINKS_CANCELED" })
      .catch((error) => getErrorResponse({ type: "UPLOAD_LINKS_ERROR", error }))
      .startWith({ type: "UPLOAD_LINKS_PENDING", payload: action.payload })
  );

export const assignMasterLinks = (action$) =>
  action$.ofType("ASSIGN_MASTER_LINKS").mergeMap((action) =>
    api
      .assignMasterLinks(action.payload.campaignId, action.payload.token)
      .map((response) => ({
        type: "ASSIGN_MASTER_LINKS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("ASSIGN_MASTER_LINKS_ABORTED"))
      .defaultIfEmpty({ type: "ASSIGN_MASTER_LINKS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "ASSIGN_MASTER_LINKS_ERROR", error })
      )
      .startWith({
        type: "ASSIGN_MASTER_LINKS_PENDING",
        payload: action.payload,
      })
  );

export const assignCustomLinks = (action$) =>
  action$.ofType("ASSIGN_CUSTOM_LINKS").mergeMap((action) =>
    api
      .assignCustomLinks(action.payload.campaignId, action.payload.token)
      .map((response) => ({
        type: "ASSIGN_CUSTOM_LINKS_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("ASSIGN_CUSTOM_LINKS_ABORTED"))
      .defaultIfEmpty({ type: "ASSIGN_CUSTOM_LINKS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "ASSIGN_CUSTOM_LINKS_ERROR", error })
      )
      .startWith({
        type: "ASSIGN_CUSTOM_LINKS_PENDING",
        payload: action.payload,
      })
  );

export const deleteLinks = (action$) =>
  action$.ofType("DELETE_LINKS").mergeMap((action) =>
    api
      .deleteLinks(
        action.payload.assignedLinks,
        action.payload.unassignedLinks,
        action.payload.token
      )
      .map((response) => ({ type: "DELETE_LINKS_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("DELETE_LINKS_ABORTED"))
      .defaultIfEmpty({ type: "DELETE_LINKS_CANCELED" })
      .catch((error) => getErrorResponse({ type: "DELETE_LINKS_ERROR", error }))
      .startWith({ type: "DELETE_LINKS_PENDING", payload: action.payload })
  );

export const postReputationRating = (action$) =>
  action$.ofType("POST_REPUTATION_RATING").mergeMap((action) =>
    api
      .postReputationRating(
        action.payload.questId,
        action.payload.userId,
        action.payload.reputationObj,
        action.payload.token
      )
      .map((response) => ({
        type: "POST_REPUTATION_RATING_SUCCESS",
        payload: response,
      }))
      .takeUntil(action$.ofType("POST_REPUTATION_RATING_ABORTED"))
      .defaultIfEmpty({ type: "POST_REPUTATION_RATING_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "POST_REPUTATION_RATING_ERROR", error })
      )
      .startWith({
        type: "POST_REPUTATION_RATING_PENDING",
        payload: action.payload,
      })
  );
