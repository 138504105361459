/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';

class ArrayDropDown extends Component {
  render() {
    const {
      customLabel,
      input,
      isClearable,
      isMulti,
      meta: { touched, error },
      optionsToRender = [],
      placeholder,
      styles,
    } = this.props;

    const hasError = touched && error !== undefined;
    return (
      <div className="input-container">
        <label>
          {customLabel}
        </label>
        <div className="input-wrapper">
          <Select
            value={input.value}
            onChange={input.onChange}
            onBlur={() => input.onBlur(input.value)}
            options={optionsToRender}
            placeholder={placeholder}
            styles={styles}
            isMulti={isMulti}
            simpleValue
            isClearable={isClearable}
            className={classNames('form-control', {
                touched, error: hasError, ok: !hasError, empty: !input.value, 'not-empty': input.value,
            })}
          />
          {(false && hasError && touched) &&
          <div className="error-icon">
            <i className="fa fa-exclamation-circle" />
          </div>}
          {(false && !hasError && touched) &&
          <div className="ok-icon">
            <i className="fa fa-check" />
          </div>}
          {hasError && <div className="error-message">{error}</div>}
        </div>
      </div>
    );
  }
}

ArrayDropDown.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  optionsToRender: PropTypes.shape({}).isRequired,
  // meta: PropTypes.shape({
  //   touched: PropTypes.bool,
  //   error: PropTypes.bool,
  // }),
  placeholder: PropTypes.string,
};

ArrayDropDown.defaultProps = {
  placeholder: '',
  // meta: {
  //   error: false,
  //   touched: false,
  // },
};


export default ArrayDropDown;
