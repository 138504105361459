import React from 'react';
import {useTranslation } from 'react-i18next'

import Categories from '../../CustomInputs/Categories';
import CustomTextField from '../../CustomInputs/CustomTextField';
import SocialNetworks from '../../CustomInputs/SocialNetworks';

const Social = ({ form, handleFormChange, errors }) => {
  const { t } = useTranslation('portalLeft')
  
  return (
    <>
      <CustomTextField
        header={t("websiteUrl")}
        name="gamePageURL"
        value={form.gamePageURL}
        onChange={handleFormChange}
        error={errors.includes("gamePageURL")}
      />
      <CustomTextField
        header={t("headline")}
        name="headline"
        value={form.headline}
        onChange={handleFormChange}
        error={errors.includes("headline")}
      />
      <CustomTextField
        header={t("headlineVideo")}
        name="headlineVideo"
        value={form.headlineVideo}
        onChange={handleFormChange}
        error={errors.includes("headlineVideo")}
      />
      <Categories value={form.categories} handleFormChange={handleFormChange} />
      <SocialNetworks
        value={form.socialNetworks}
        form={form}
        handleFormChange={handleFormChange}
        enableNameInCards={false}
      />
    </>
  );
}

export default Social;
