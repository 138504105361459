import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { SetViewContext } from "../PortalContext";
import { textTruncate, navigationAssembly } from "../../../../utils/functions";

import Icon from "../../../../global/images/icons/Icon";
import IntercomPortal from "../mvp/IntercomPortal";

import { useTranslation } from "react-i18next";

import "./ActiveItems.scss";

const ItemsBlank = ({ type }) => {
  const { t } = useTranslation("activeItems");
  return (
    <div className="ActiveItems__Blank">
      <Icon color="#302f34" name="empty" />
      <div className="ActiveItems__Blank_Text">
        {t("notHaveAny")} {type === "Quest" && "Active "}
        {type}s
      </div>
      {type === "Quest" && (
        <IntercomPortal
          children={
            <div className="ActiveItems__Blank_Button">
              {t("addNew")} {type}
            </div>
          }
        />
      )}
      {type !== "Quest" && (
        <div className="ActiveItems__Blank_Button">
          {t("addNew")} {type}
        </div>
      )}
    </div>
  );
};

const ItemButton = ({ tip, img, itemId, type }) => {
  return (
    <Tooltip title={tip} placement="top">
      <SetViewContext.Consumer>
        {({ setView, view }) => (
          <div
            className="ActiveItems__Item_Button"
            onClick={() => {
              const component = `${type}${img === "edit" ? "Edit" : "Detail"}`;
              setView({
                ...view,
                component,
                // REMOVED FALLBACKS
                ...(type === "Quest" && { currentQuest: itemId }),
                ...(type === "Product" && { currentProduct: itemId }),
                ...(type === "Campaign" && {
                  campaignId: itemId,
                  component: "report",
                  tab: "quests",
                }),
              });
            }}
          >
            <Icon color="white" name={img} />
          </div>
        )}
      </SetViewContext.Consumer>
    </Tooltip>
  );
};

const ItemCard = ({
  activeItem: { id, img, title, name, cover, game = {}, campaign },
  buttons,
  type,
}) => {
  const componentRef = useRef(null);

  const [componentWidth, setComponentWidth] = useState(0);

  useEffect(() => {
    setComponentWidth(componentRef?.current?.offsetWidth || 0);
  }, [componentRef.current]);

  const { cover: gameCover = "" } = game;
  const correctId = type === "Campaign" ? campaign : id;

  const itemTitle = (title || name).toUpperCase();

  const characterCount = itemTitle?.length || 0;
  const titleSize = characterCount * 14;
  const truncateTitle = titleSize > componentWidth;

  const displayTitle = itemTitle ? (
    truncateTitle ? (
      <Tooltip placement="top" title={itemTitle}>
        <span className="ActiveItems__Item_Title">
          {textTruncate(itemTitle, Math.floor(componentWidth / 14))}
        </span>
      </Tooltip>
    ) : (
      <span className="ActiveItems__Item_Title">{itemTitle}</span>
    )
  ) : (
    ""
  );

  return (
    <div ref={componentRef} className="ActiveItems__Item mb-20">
      <div className="ActiveItems__Item_Details">
        <img
          className="ActiveItems__Item_Image"
          src={img || cover || gameCover}
          alt={title || name}
        />
        {displayTitle}
      </div>
      <div className="ActiveItems__Item_Buttons">
        {buttons.map((b, i) => (
          <ItemButton
            key={`${correctId}-${type}-${i}`}
            {...b}
            itemId={correctId}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};

const ActiveItems = ({ type, items, buttons }) => {
  const { t } = useTranslation("activeItems");
  const content =
    items && items.length > 0 ? (
      <>
        {items.slice(0, 3).map((aq) => (
          <ItemCard
            key={`${type}-${aq.id}`}
            activeItem={aq}
            buttons={buttons}
            type={type}
          />
        ))}
        <SetViewContext.Consumer>
          {({ setView, view }) => (
            <div
              className="ActiveItems__View_All"
              onClick={() => setView(navigationAssembly(type, view))}
            >
              {t("viewAll")} {type}s
            </div>
          )}
        </SetViewContext.Consumer>
      </>
    ) : (
      <ItemsBlank type={type} />
    );

  return (
    <div className="ActiveItems mb-20">
      <div className="ActiveItems__Title mb-10">
        <div className="ActiveItems__Title__Title">
          {type === "Quest"
            ? t("activeQuests")
            : `${t("your")} ${t(`activeItemsType.${type}`)}s`}
        </div>
        <div className="ActiveItems__Title__Subtitle">
          {type === "Product"
            ? t("mostRecent")
            : !items || items.length === 0
            ? t("all")
            : t("thisMonth")}
        </div>
      </div>
      {content}
    </div>
  );
};

export default ActiveItems;
