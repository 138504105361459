import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import CustomInput from "../../utils/redux-forms-custom-fields/CustomInput";
import CustomInputCheckbox from "../../utils/redux-forms-custom-fields/CustomInputCheckbox";
import CustomDropDownGeneric from "../../utils/redux-forms-custom-fields/CustomDropDownGeneric";
import CustomDateTimePicker from "../../utils/redux-forms-custom-fields/CustomDateTimePicker";
import CustomDropDown from "../../utils/redux-forms-custom-fields/CustomDropDown";
import { simpleNotification } from "../../utils/notifications";
import { getCorrectTwitchAverages } from "../../utils/functions";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const dateStoreFormat = "YYYY-MM-DD";
const dateDisplayFormat = "MM-DD-YYYY";

class AdminUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      twitchConnected: true,
      twitterConnected: true,
    };
  }

  onDisconnectTwitch = () => {
    this.setState({
      twitchConnected: false,
    });

    const { t } = this.props;

    simpleNotification({
      level: "warning",
      title: t("disconnectTwitch"),
      message: t("disconnectTwitchMessage"),
    });
  };

  onDisconnectTwitter = () => {
    const { t } = this.props;
    this.setState({
      twitterConnected: false,
    });
    simpleNotification({
      level: "warning",
      title: t("disconnectTwitter"),
      message: t("disconnectTwitterMessage"),
    });
  };

  submit = (info) => {
    const { twitchConnected, twitterConnected } = this.state;
    this.props.onSubmit(info, twitchConnected, twitterConnected);
  };

  render() {
    const { handleSubmit, adminUserForm, t } = this.props;
    const { twitchConnected, twitterConnected } = this.state;
    let recoveryToken = "";
    let AVGViewers = "";
    try {
      recoveryToken =
        adminUserForm && adminUserForm.values
          ? adminUserForm.values.recoveryToken
          : "";

      if (this.props.adminUserForm) {
        const { values } = adminUserForm;
        AVGViewers = getCorrectTwitchAverages(values, true).correctAverage;
      }
    } catch (err) {
      console.log(err);
    }
    const recoveryLink =
      recoveryToken !== ""
        ? `${window.location.origin}/recover?recovery-token=${recoveryToken}`
        : "";

    return (
      <form
        className="form-container admin-user"
        autoComplete="off"
        onSubmit={handleSubmit(this.submit.bind(this))}
      >
        <Fragment>
          <div className="input-container">
            <label>{t("userID")}</label>
            <div className="input-wrapper">
              <input
                autoComplete="off"
                id="recoveryLink"
                name="recoveryLink"
                type="text"
                placeholder=""
                className="ok empty"
                value={this.props.currUserId}
              />
            </div>
          </div>
          <Field
            type="text"
            name="firstName"
            id="firstName"
            placeholder={t("firstName")}
            customLabel={<label>{t("firstName")}</label>}
            component={CustomInput}
          />
          <Field
            type="text"
            name="lastName"
            id="lastName"
            placeholder={t("lastName")}
            customLabel={<label>{t("lastName")}</label>}
            component={CustomInput}
          />
          <Field
            type="text"
            name="username"
            id="username"
            placeholder={t("username")}
            customLabel={<label>{t("username")}</label>}
            component={CustomInput}
          />
          <Field
            type="text"
            name="email"
            id="email"
            placeholder={t("email")}
            customLabel={<label>{t("email")}</label>}
            component={CustomInput}
          />
          <Field
            name="role"
            id="role"
            placeholder={t("selectRole")}
            customLabel={<label>{t("role")}</label>}
            component={CustomDropDownGeneric}
            options={["user", "admin", "publisher", "helper", "brand"]}
          />
          {adminUserForm &&
          adminUserForm.values &&
          adminUserForm.values.role === "brand" ? (
            <Field
              type="text"
              name="company"
              id="company"
              placeholder={t("companyName")}
              customLabel={<label>{t("companyName")}</label>}
              component={CustomInput}
            />
          ) : null}
          <Field
            name="status"
            id="status"
            placeholder={t("selectStatus")}
            customLabel={<label>{t("status")}</label>}
            component={CustomDropDownGeneric}
            options={["active", "on wait list", "deleted"]}
          />
          <Field
            type="text"
            name="birthDate"
            id="birthDate"
            placeholder={t("birthDate")}
            dateStoreFormat={dateStoreFormat}
            dateDisplayFormat={dateDisplayFormat}
            customLabel={
              <label className="datepicker-label">{t("birthDate")}</label>
            }
            component={CustomDateTimePicker}
          />
          <Field
            type="text"
            name="country"
            id="country"
            customLabel={<label>{t("country")}</label>}
            component={CustomDropDown}
          />
          <Field
            type="text"
            name="language"
            id="language"
            customLabel={<label>{t("language")}</label>}
            component={CustomInput}
          />
          {/* <Field
            type="text"
            name="twitter"
            id="twitter"
            customLabel={(<label>Twitter</label>)}
            component={CustomInput}
          /> */}
          <Field
            type="text"
            name="discord"
            id="discord"
            customLabel={<label>{t("discord")}</label>}
            component={CustomInput}
          />
          <Field
            name="blacklist"
            id="blacklist"
            customLabel={<label>{t("blacklist")}</label>}
            component={CustomInputCheckbox}
          />
          <Field
            name="offPlatform"
            id="offPlatform"
            customLabel={<label>{t("offPlatform")}</label>}
            component={CustomInputCheckbox}
          />
          <Field
            type="textarea"
            name="notes"
            id="notes"
            customLabel={<label>{t("notes")}</label>}
            className="extra-big-text-area"
            component={CustomInput}
          />
        </Fragment>

        {/* { (adminUserForm && adminUserForm.values &&
          adminUserForm.values.firstName &&
          adminUserForm.values.lastName &&
          adminUserForm.values.username &&
          adminUserForm.values.email) && (
          <Fragment>
            <div className="input-container">
              <label>First Name</label>
              <div className="input-wrapper">
                <input type="text" value={`${adminUserForm.values.firstName}`} className="touched ok not-empty" disabled />
              </div>
            </div>
            <div className="input-container">
              <label>Last Name</label>
              <div className="input-wrapper">
                <input type="text" value={`${adminUserForm.values.lastName}`} className="touched ok not-empty" disabled />
              </div>
            </div>
            <div className="input-container">
              <label>Username</label>
              <div className="input-wrapper">
                <input type="text" value={`${adminUserForm.values.username}`} className="touched ok not-empty" disabled />
              </div>
            </div>
            <div className="input-container">
              <label>Email</label>
              <div className="input-wrapper">
                <input type="text" value={`${adminUserForm.values.email}`} className="touched ok not-empty" disabled />
              </div>
            </div>
        
          </Fragment>
        )
      } */}
        {twitchConnected &&
          adminUserForm &&
          adminUserForm.values &&
          adminUserForm.values.connectedAccounts &&
          adminUserForm.values.connectedAccounts.twitch &&
          adminUserForm.values.connectedAccounts.twitch.username && (
            <Fragment>
              <div
                className="AUserForm__disconnectPlatform--container"
                onClick={this.onDisconnectTwitch.bind(this)}
                aria-hidden
              >
                <div className="AUserForm__disconnectPlatform">
                  {t("disconnectTwitchAccount")}
                </div>
              </div>
              <div className="input-container">
                <label>{t("twitchUsername")}</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    value={`${adminUserForm.values.connectedAccounts.twitch.username}`}
                    className="touched ok not-empty"
                    disabled
                  />
                </div>
              </div>
              <Fragment>
                <div className="input-container">
                  <label>{t("actualAverageTwitchViewers")}</label>
                  <div className="input-wrapper">
                    <input
                      autoComplete="off"
                      id="recoveryLink"
                      name="recoveryLink"
                      type="text"
                      placeholder=""
                      className="ok empty"
                      disabled
                      value={Math.round(AVGViewers)}
                    />
                  </div>
                </div>
                {/* <Field
                type="text"
                name="connectedAccounts.twitch.AVGViewers"
                id="connectedAccounts.twitch.AVGViewers"
                placeholder="Average Viewers"
                customLabel={(<label>Twitch Average Viewers</label>)}
                component={CustomInput}

              /> */}

                <Field
                  name="freezeTwitchViewers.isFrozen"
                  id="freezeTwitchViewers.isFrozen"
                  customLabel={<label>{t("freezeTwitchViewers")}</label>}
                  component={CustomInputCheckbox}
                />
                <Field
                  type="text"
                  name="twitchAVGOverride"
                  id="twitchAVGOverride"
                  placeholder="0"
                  customLabel={
                    <label>{t("twitchAverageViewerOverride")}</label>
                  }
                  component={CustomInput}
                />
              </Fragment>
            </Fragment>
          )}

        {twitterConnected &&
          adminUserForm &&
          adminUserForm.values &&
          adminUserForm.values.connectedAccounts &&
          adminUserForm.values.connectedAccounts.twitter &&
          adminUserForm.values.connectedAccounts.twitter.username && (
            <Fragment>
              <div
                className="AUserForm__disconnectPlatform--container"
                onClick={this.onDisconnectTwitter.bind(this)}
                aria-hidden
              >
                <div className="AUserForm__disconnectPlatform">
                  {t("disconnectTwitterAccount")}
                </div>
              </div>
              <div className="input-container">
                <label>{t("twitterUsername")}</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    value={`${adminUserForm.values.connectedAccounts.twitter.username}`}
                    className="touched ok not-empty"
                    disabled
                  />
                </div>
              </div>
            </Fragment>
          )}

        <div className="input-container">
          <label>{t("passwordRecoveryLink")}</label>
          <div className="input-wrapper">
            <input
              autoComplete="off"
              id="recoveryLink"
              name="recoveryLink"
              type="text"
              placeholder=""
              className="ok empty"
              disabled
              value={recoveryLink}
            />
          </div>
        </div>

        <div className="form__footer">
          <button className="AUserForm__save--btn">{t("save")}</button>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.username || values.username.trim() === "") {
    errors.username = t("validate.fieldRequired");
  }
  if (!values.email || values.email.trim() === "") {
    errors.email = t("validate.fieldRequired");
  }
  if (!values.role || values.role.trim() === "") {
    errors.role = t("validate.fieldRequired");
  }
  if (!values.status || values.status.trim() === "") {
    errors.status = t("validate.fieldRequired");
  }
  return errors;
};

AdminUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation("adminUserForm")(
  reduxForm({
    form: "adminUserForm",
    validate,
  })(AdminUserForm)
);
