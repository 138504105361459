import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";

import ViewerTable from "./ViewerTable";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { calculatePaymentAmount } from "../../../../utils/functions";
import ConfirmModal from "./ConfirmModal";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import { simpleNotification } from "../../../../utils/notifications";
import "./EditTier.scss";
import { useTranslation } from "react-i18next";
import { updateUserQuest } from "../../../../utils/apiv2";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    boxShadow: "none",
    outline: "none",
  },
  paper: {
    backgroundColor: "#202227",
    boxShadow: theme.shadows[5],
    padding: "60px 40px",
    color: "white",
    // maxWidth: '30%',
  },
  paper2: {
    padding: "15px 10px",
    marginTop: "25px",
  },
  topMast: {
    backgroundColor: "#313337",
    padding: "20px 40px",
  },
  mainContainer: {
    width: "100%",
    height: "100%",
    padding: "40px 40px",
  },
  textField: {
    width: 200,
  },
  errorButton: {},
  confirmButton: {
    backgroundColor: "green",
  },
  buttonGrid: {
    width: "100%",
    marginTop: "30px",
  },
  inputBox: {
    // minHeight: '100px',
    // height: '200px',

    lineHeight: 2,
  },
  warning: {
    color: "red",
    marginTop: "20px",
    // width: '20%',
  },
  memberBox: {
    backgroundColor: "#424242",
    marginTop: "25px",
    padding: "20px",
  },
  h1: {
    fontWeight: 600,
    fontSize: "25px",
  },
  memberEdit: {
    marginLeft: "15px",
  },
  noBack: {
    backgroundColor: "transparent",
  },
  confirm: {
    marginTop: "25px",
  },
}));

export default function EditTier(props) {
  const { t } = useTranslation("editTier");
  const { averageViewersAtJoin, viewerTiers, memberTiers, user } = props;
  const { username } = user;
  const classes = useStyles();
  const paymentInfo = calculatePaymentAmount(
    user,
    averageViewersAtJoin,
    viewerTiers,
    memberTiers
  );
  const [open, setOpen] = React.useState(false);
  const [paymentSelections, setPaymentSelections] = React.useState(null);
  const [member, setMemberTiers] = React.useState(paymentInfo.memberTier);
  const [commitCheck, setCommitCheck] = React.useState(false);

  const handleDelete = () => {
    setMemberTiers(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    const { token } = props.auth;

    const { questId } = props.router.params;
    const userId = user.id;
    const tier = member ? "member" : "viewer";
    const rehydrateArguments = props.locMod
      ? [
          ...props.pagination,
          questId,
          props.currentFiltering,
          props.locMod,
          token,
        ]
      : [questId, token];

    let body = {};
    if (!member) {
      const averageViewersAtJoin = viewerTiers.find(
        (tier) => tier.paymentAmount === paymentSelections
      )?.minimumAverageViewers;
      body = {
        averageViewersAtJoin,
        tier,
        userId,
        questId,
      };
    } else if (member) {
      const { paymentAmount } = member;
      body = {
        paymentAmount,
        tier,
        userId,
        questId,
      };
    }
    try {
      await updateUserQuest(body);
      simpleNotification({
        level: "success",
        title: t("userUpdateSuccess"),
        message: t("userUpdateSuccessMessage"),
      });
    } catch (err) {
      simpleNotification({
        level: "error",
        title: t("userUpdateFail"),
        message: t("userUpdateFailMessage"),
      });
    } finally {
      // console.log(rehydrateArguments, 'rehydrate');
      props.rehydrateCallback(...rehydrateArguments);
    }
  };

  const displayPayment = member ? member.paymentAmount : "";
  return (
    <div>
      <Tooltip title={t("editTier")} placement="top">
        <button className="small-btn" onClick={handleOpen}>
          <span style={{ fontSize: "14px" }}>$</span>
        </button>
      </Tooltip>

      {/* <IconButton size="small" className={classes.noBack} color="primary" onClick={handleOpen} aria-label="Edit Member Tier">
        <AttachMoneyIcon size="small" className={classes.noBack} />
      </IconButton> */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Grid
              xs={12}
              container
              direction="column"
              justify="flex-start"
              align="flex-start"
              spacing={4}
            />
            <Grid className={classes.h1}> {t("viewerTier")}</Grid>
            <ViewerTable
              {...props}
              setPaymentSelections={setPaymentSelections}
              paymentInfo={paymentInfo}
              member={member}
            />

            <Grid>
              <Grid
                container
                xs={3}
                direction="column"
                justify="flex-start"
                align="flex-start"
                className={classes.h1}
              >
                {" "}
                {t("memberTier")}:&nbsp;
                <Paper className={classes.paper2}>
                  <Grid
                    container
                    xs={12}
                    classNames={classNames({
                      noTierSelected: !displayPayment,
                      memberTierSelected: displayPayment,
                    })}
                    direction="row"
                    justify="space-around"
                    align="center"
                  >
                    {displayPayment ? `$${displayPayment}` : t("none")} &nbsp;
                    <Grid>
                      <ConfirmModal
                        member={member}
                        username={username}
                        submitCallback={setMemberTiers}
                        add={!displayPayment}
                      />
                    </Grid>
                    {displayPayment && (
                      <Grid>
                        <IconButton onClick={handleDelete} size="small">
                          <DeleteIcon size="small" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid
              container
              className={classes.buttonGrid}
              xs={12}
              direction="row"
              width="100%"
              justify="space-between"
              alignItems="center"
            >
              <Grid>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={!commitCheck}
                >
                  {t("accept")}
                </Button>
              </Grid>
              <Grid>
                <Button onClick={handleClose} className={classes.errorButton}>
                  {t("cancel")}
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.confirm}>
              <Switch
                checked={commitCheck}
                onChange={() => setCommitCheck(!commitCheck)}
              />{" "}
              {t("commitCheck")}
            </Grid>
            <Grid className={classes.confirm} style={{ color: "red" }}>
              {t("commitWarn")}
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}
