import { extractInstantQuestPayment } from "../../../../../utils/functions";

import { t } from "i18next";

export const filterQuests = (questData = [], search, filters) => {
  let filterdQuests = questData.filter((quest) => {
    if (!quest) return false;
    let validQuest = true;
    const today = new Date();
    if (
      filters[t("portalLeft:activeQuests")] ||
      filters[t("portalLeft:activeCampaigns")]
    ) {
      const startDate = new Date(quest.startDateTime);
      const endDate = new Date(quest.endDateTime);

      validQuest = today >= startDate && today <= endDate;
    }

    if (validQuest && filters[t("portalLeft:recentlyAdded")]) {
      const createdDate = new Date(quest.createdAt);
      const thirtyDays = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

      validQuest = createdDate >= thirtyDays;
    }

    if (validQuest && search) {
      validQuest =
        quest?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        quest?.title?.toLowerCase()?.includes(search.toLowerCase()) ||
        quest?.game?.name.toLowerCase().includes(search.toLowerCase()) ||
        (Math.round(quest.budget * 100) / 100)
          .toFixed(2)
          .toString()
          .includes(search);
    }

    return validQuest;
  });

  if (filters.sorting) {
    const sort = filters.sorting.split(" ");
    const sortKey = sort[2] ? `${sort[0]} ${sort[1]}` : sort[0];
    const sortOrder = sort[2] || sort[1];

    filterdQuests = filterdQuests.sort((a, b) => {
      if (sortKey === "Budget") {
        return sortOrder === "Descending"
          ? b.budget - a.budget
          : a.budget - b.budget;
      } else if (sortKey === "Date Created") {
        return sortOrder === "Descending"
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : new Date(a.createdAt) - new Date(b.createdAt);
      } else if (sortKey === "Highest Reward") {
        const aa = extractInstantQuestPayment(a);
        const bb = extractInstantQuestPayment(b);

        return sortOrder === "Descending" ? bb - aa : aa - bb;
      } else if (sortKey === "Quest Title") {
        return sortOrder === "Descending"
          ? b.title.localeCompare(a.title)
          : a.title.localeCompare(b.title);
      } else if (sortKey === "Campaign Name") {
        return sortOrder === "Descending"
          ? b.name.localeCompare(a.name)
          : a.name.localeCompare(b.name);
      } else return -1;
    });
  }

  return filterdQuests;
};
