
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { BlueSwitch } from './link-styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from "react-i18next";


export const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const linkStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const CheckBox = (props) => {
  const { passUp, item, selectAll } = props;
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    passUp(item, event.target.checked);
    setChecked(event.target.checked);
  };
  useEffect(() => {
    // Update the document title using the browser API
    if (selectAll && selectAll.selected && !checked) {
      setChecked(true);
    } else if (selectAll && !selectAll.selected && checked) {
      setChecked(false);
    }
  }, [selectAll, checked]);


  return (<Checkbox
    checked={checked}
    onChange={handleChange}
    inputProps={{ 'aria-label': 'primary checkbox' }}
  />);
};

export const SelectAllCheckBox = (props) => {
  const { callBack, tab } = props;
  const [checked, setChecked] = useState(false);
  const [oldTab, setOldTab] = useState(false);
  const { t } = useTranslation("trackedLinks");

  const handleChange = (event) => {
    callBack(event.target.checked);
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (oldTab !== tab) {
      callBack(false);
      setChecked(false);
      setOldTab(tab);
    }
  }, [oldTab, tab, callBack]);

  return (
    <Tooltip title={t("selectAllTip")}>
      <FormControlLabel
        style={{ color: "white" }}
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        }
        label={t("selectAll")}
      />
    </Tooltip>
  );
};


export const LinkTabs = (props) => {
  const classes = linkStyles();
  const [value, setValue] = useState(0);
  const {
    tabList, Table,
  } = props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.setTab(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {tabList.map((t, i) => (<Tab label={t.name} key={i} {...a11yProps(i)} />))}

        </Tabs>
      </AppBar>
      {tabList.map((t, i) => (
        <TabPanel value={value} key={i} index={i}>
          <Table {...props} dataKey={i} />
        </TabPanel>))}

    </div>
  );
};

export const CampaignSelect = (props) => {
  const [value, setValue] = useState(null);
  const { t } = useTranslation("trackedLinks");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.selectCampaign(newValue);
  };

  useEffect(() => {
    if (value) {
      const { id } = value;
      const campaign = props.campaigns.find(item => item.id === id);
      const allKeys = ['masterLink', 'customLinks', 'trackedLinksEnabled', 'rebrandedLinks'];
      if (campaign) {
        for (let i = 0; i < allKeys.length; i++) {
          const key = allKeys[i];
          if (campaign[key] !== value[key]) {
            setValue(campaign);
            props.selectCampaign(campaign);
          }
        }
      }
    }
  }, [value, props]);

  return (
    <Autocomplete
      id="combo-box-demo"
      options={props.campaigns}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ width: 300 }}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label={t("campaignSelect")} variant="outlined" />
      )}
    />
  );
};

export const blueSwitch = (checked, change, name) => (<BlueSwitch
  checked={checked}
  onChange={change}
  name={name}
  inputProps={{ 'aria-label': 'primary checkbox' }}
/>);
