import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Carousel from "../../global/components/carousel/Carousel";
import ItemCard from "./ItemCard";
import { fade } from "../../utils/transitionIndex";
// import './GalleryModal.scss';
import "./QuestGalleryItem.scss";
import { useTranslation } from "react-i18next";

const Activator = (props) => {
  const { handleOpen, isImage, isMobile, img } = props;

  return isImage ? (
    <div onClick={isMobile ? () => null : handleOpen} className="galleryItem">
      {isMobile ? (
        <a href={img} target="_blank" rel="noreferrer">
          <img className="galleryImage" src={img} alt="gallery item" />{" "}
        </a>
      ) : (
        <img src={img} className="galleryImage" alt="gallery item" />
      )}
      <span className="cornerBtn" />
    </div>
  ) : (
    <></>
  );
};
// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: "90% !important",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "80%",
    maxHeight: "100%",
    backgroundColor: "transparent",
    border: "0px solid transparent",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
}));

const GalleryModal = (props) => {
  const { img = "", isMobile = false, items = [], isImage = true } = props;
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const processedImages = images.filter(item => item !== img).map(item => ({ image: item }));

  // const prepImageArray = pImages => (img ? [{ image: img }, ...pImages] : pImages);

  const itemCards = items.map((item) => (
    <ItemCard baseCSS item={{ image: item }} />
  ));

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Carousel
        showLearnMore={false}
        items={itemCards}
        width="90%"
        centerMode={false}
        showArrows
        baseCSS
      />
    </div>
  );

  const { t } = useTranslation("galleryModal");

  return (
    <>
      {!isImage && (
        <button onClick={handleOpen} className="CTAButton__lightGrey">
          {t("viewAll")}
        </button>
      )}

      <Activator
        isImage={isImage}
        isMobile={isMobile}
        handleOpen={handleOpen}
        img={img}
      />
      <TransitionGroup>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <CSSTransition {...fade()}>{body}</CSSTransition>
        </Modal>
      </TransitionGroup>
    </>
  );
};

export default GalleryModal;
