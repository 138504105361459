// Components (external)
import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

// Styles
import "./StatusBar.scss";

export default function StatusBar(props) {
  const { status } = props;
  const { t } = useTranslation("quest_StatusBar");

  let statusMessage = "";
  let statusLinkMessage = "";
  let statusLink = "";

  // Update language and styling depending on status of bar
  switch (status) {
    case "pending":
      statusMessage = t("pendingMessage");
      break;
    case "no-twitch":
      statusMessage = t("no-twitch.statusMessage");
      statusLinkMessage = t("no-twitch.statusLinkMessage");
      statusLink = "../connect-accounts";
      break;
    case "accv-min":
      statusMessage = t("accv-min.statusMessage");
      statusLinkMessage = t("accv-min.statusLinkMessage");
      statusLink = "../quests";
      break;
    default:
      break;
  }

  const classes = classNames({
    StatusBar: true,
    pending: status === "pending",
    "no-twitch": status === "no-twitch",
    "accv-min": status === "accv-min",
    hidden: !status,
  });

  return (
    <div className={classes}>
      <span>
        {statusMessage}
        <a href={statusLink}> {statusLinkMessage} </a>
      </span>
    </div>
  );
}
