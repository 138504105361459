import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import './SearchFilter.scss';
import { useTranslation } from 'react-i18next';

const MAX_OPTIONS_SHOWN = 100;

function SearchFilter(props) {
  const [search, setSearch] = useState('');

  const {t}=useTranslation("searchFilter")
  
  const {
    allowSearch,
    clearSelected,
    display,
    isLoading,
    justify,
    options,
    selected,
    title,
    toggleSelectOption,
  } = props;

  // Dynamic classes to allow for opening menus
  const classes = classNames({
    SearchFilter: true,
    display: !!display,
    right: justify === 'right',
    left: justify !== 'right',
  });

  // Grab selected objs to get full titles
  let selectedObjs = [];
  if (selected && options) {
    selectedObjs = options.filter(option => selected.includes(option.name));
  }

  const selectedString = selectedObjs ? selectedObjs.map(obj => obj.name).join(', ') : '';

  let filteredOptions = options;
  if (search) {
    filteredOptions = options
      .filter(option => option.name.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <div className={classes}>
      <div className="selectedBar">
        <div className="selections">
          <span className="selected">
            {selected && selected.length ? `(${selected.length}) ${t("selected")}` : title}
          </span>
        </div>
        <span className="selectedString">
          {selectedString}
        </span>
        <button onClick={clearSelected}>
          {t("clear")}
        </button>
      </div>
      {allowSearch && (
        <span className="input-wrapper">
          <input onChange={e => setSearch(e.target.value)} />
          <i className="fa fa-search" />
        </span>
      )}
      <div className="InsightSearch">
        {isLoading ? (
          <CircularProgress
            thickness={2}
            style={{
              width: '40px',
              height: '40px',
              color: '#49e1ca'
            }}
          />
        ) : filteredOptions ? (
          <>
            {filteredOptions.slice(0, MAX_OPTIONS_SHOWN).map(option => (
              <span
                className={selected.includes(option.name) ? 'selected' : ''}
                key={option.id}
                onClick={() => toggleSelectOption(option.name)}>
                {option.name}
              </span>
            ))}
            {filteredOptions.length > MAX_OPTIONS_SHOWN ? (
              <span className="selected">
                .... {t("and")} {filteredOptions.length - MAX_OPTIONS_SHOWN} {t("more")}
              </span>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SearchFilter;