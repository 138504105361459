import React from "react";
import { withTranslation } from "react-i18next";
// import PropTypes from 'prop-types';
// import { isEmpty } from 'lodash';
// import Raven from 'raven-js';

import { Link } from "react-router";
import "./WhatsNoiz.scss";

// import { isAuthenticated } from '../../utils/authChecker';
// import { simpleNotification } from '../../utils/notifications';
import noizLogo from "../../images/logo-noiz-white.svg";
class WhatsNoiz extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  UNSAFE_componentWillMount() {}

  render() {
    const { t } = this.props;
    return (
      <section className="WhatsNoiz">
        <section className="WhatsNoiz__container">
          <section className="Hero">
            <h2 className="Hero__title">{t("heroTitle")}</h2>
            <p className="Hero__description">
              <img src={noizLogo} alt="Noiz Logo" /> {t("heroDescription")}
            </p>
            <p className="Hero__description offset">
              {t("heroDescriptionOffset")}
            </p>

            <section className="WhatsNoiz__steps two-column-container">
              <section className="column">
                <div className="noiz-icon clock" />
                <h4>{t("step1")}</h4>
                <p>{t("step1Message")}</p>
              </section>
              <section className="column">
                <div className="noiz-icon money" />
                <h4>{t("step2")}</h4>
                <p>{t("step2Message")}</p>
              </section>
            </section>

            <Link
              className="cta-button site-gradient-btn"
              to="/quests"
              href="/quests"
            >
              {t("ok")}
            </Link>
          </section>

          {/* <section className="whats-noiz">
            <h2 className="whats-noiz__title">New Broadcaster Early Access title coming soon!</h2>
            <section className="whats-noiz__features">
              <section className="feature">
                <figure className="feature__image">
                  <img src="" alt="Early Game Access" />
                </figure>
                <h4 className="feature__title">
                  Get exclusive early access to upcoming games in exchange for streaming.
                </h4>
              </section>
              <section className="feature">
                <figure className="feature__image">
                  <img src="" alt="Lorem Ipsum dolar" />
                </figure>
                <h4 className="feature__title">
                  Make money. If you stream at a particular date and time, you can get paid based on your live viewers.
                  (currently only opened to US broadcasters)
                </h4>
              </section>
            </section>
          </section> */}
        </section>
      </section>
    );
  }
}

export default withTranslation("whatsNoiz")(WhatsNoiz);
