import React from "react";
import { Link } from "react-router";
import CampaignSelector from "../main/brand/CampaignSelector";
import ReactTooltip from "react-tooltip";
import { getMyQuestsFiltered } from "../../utils/functions";
import { useTranslation } from "react-i18next";

import "./NavMenu.scss";

function NavMenu({
  auth,
  brandUser,
  isBrandUser,
  isClientPortal,
  router,
  toggleMyQuestsMenu,
  user,
  visible,
}) {
  const { t } = useTranslation("navMenu");
  if (!visible) return null;
  const { isLoadingMyQuests, myQuestsFiltered, requirementsOutstanding } =
    getMyQuestsFiltered(user);
  const myQuestsCount =
    myQuestsFiltered.length > 0 &&
    !isLoadingMyQuests &&
    myQuestsFiltered.length;

  return (
    <nav className="navigation">
      <ul className="navigation-left">
        {!isBrandUser && (
          <>
            <li>
              <Link activeClassName="active" to="/quests">
                {t("quests")}
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/contact">
                {t("contact")}
              </Link>
            </li>
          </>
        )}
        {auth.user.role === "admin" && isClientPortal && (
          <CampaignSelector
            campaign={brandUser.campaign.data}
            campaigns={brandUser.campaigns}
            router={router}
          />
        )}
      </ul>
      <ul className="navigation-right">
        {!isBrandUser && (
          <li>
            <button
              onClick={toggleMyQuestsMenu}
              className="custom-btn"
              role="link"
            >
              <i className="noiz-scroll" />
              <span>
                {t("questBook")}
                {myQuestsCount ? `&nbsp;${myQuestsCount}` : ""}
              </span>
            </button>
            {requirementsOutstanding > 0 && (
              <>
                <ReactTooltip />
                <p data-tip={t("questsTip")}>
                  <i
                    className="fa fa-exclamation-circle"
                    onClick={toggleMyQuestsMenu}
                    style={{ color: "#dd4a41", margin: "0px 20px" }}
                  />
                </p>
              </>
            )}
          </li>
        )}
      </ul>
    </nav>
  );
}

export default NavMenu;
