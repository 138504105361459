import React from "react";
import { useTranslation } from "react-i18next";

import "./LiveTag.scss";

const LiveTag = () => {
  const { t } = useTranslation("liveTag");
  return (
    <div className="LiveTag">
      <div className="LiveTag__Box1" />
      <div className="LiveTag__Tag">{t("live")}</div>
      <div className="LiveTag__Box2" />
    </div>
  );
};

export default LiveTag;
