import React from "react";
import { numberWithCommas } from "../../../../utils/functions";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import "./TopPerformingTweets.scss";
import { useTranslation } from "react-i18next";

const TableHeaderCell = withStyles((theme) => ({
  head: {
    color: "#ffffff",
    fontWeight: "bold",
    letterSpacing: "0.86px",
    fontSize: "12px",
    textTransform: "uppercase",
    border: "0px",
    width: "80px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "0px",
    "&:nth-of-type(odd)": {
      backgroundColor: "#3f434d",
    },

    "&:last-of-type": {
      borderRadius: "12px",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    border: "0px",
  },
}))(TableCell);

export const TopPerformingTweets = ({ data: topTweets = [] }) => {
  const { t } = useTranslation("topPerformingTweets");
  return (
    <>
      <div className="TopTweets_Container" style={{ padding: "28px 0 0 0" }}>
        <h2 style={{ margin: "0 0 20px 24px" }}>
          {" "}
          {t("topPerformingTweets")}{" "}
        </h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell colSpan="3" align="left">
                {t("channel")}
              </TableHeaderCell>
              <TableHeaderCell colSpan="2" align="center">
                {t("followers")}
              </TableHeaderCell>
              <TableHeaderCell colSpan="2" align="center">
                {t("replies")}
              </TableHeaderCell>
              <TableHeaderCell colSpan="2" align="center">
                {t("likes")}
              </TableHeaderCell>
              <TableHeaderCell colSpan="2" align="center">
                {t("retweets")}
              </TableHeaderCell>
              <TableHeaderCell colSpan="2" align="center">
                {t("totalEngagements")}
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topTweets.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell colSpan="3" align="left">
                  {item.username ? `${index + 1}. ` : "-"}
                  {item.profileImage ? (
                    <img
                      alt="profileImage"
                      className="tweetProfileImage"
                      src={item.profileImage}
                      onError={(i) => (i.target.style.display = "none")}
                    />
                  ) : null}
                  {item.username ? item.username : ""}
                </StyledTableCell>
                <StyledTableCell colSpan="2" align="center">
                  {item.followers ? numberWithCommas(item.followers) : "-"}
                </StyledTableCell>
                <StyledTableCell colSpan="2" align="center">
                  {item.comments ? numberWithCommas(item.comments) : "-"}
                </StyledTableCell>
                <StyledTableCell colSpan="2" align="center">
                  {item.likes ? numberWithCommas(item.likes) : "-"}
                </StyledTableCell>
                <StyledTableCell colSpan="2" align="center">
                  {item.retweetsAndQuotes
                    ? numberWithCommas(item.retweetsAndQuotes)
                    : "-"}
                </StyledTableCell>
                <StyledTableCell colSpan="2" align="center">
                  {item.engagements ? numberWithCommas(item.engagements) : "-"}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
