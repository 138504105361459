import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import './BrandServiceSlides.scss';
import brandList from '../assetsV2/brandsIndex';
import anime from 'animejs';
import { useTranslation } from 'react-i18next';

const HOurBrands = (props) => {
  const { t } = useTranslation();
  const {
    pOffset, layoutFactor = 1, offsetAdjust = 0, isMobile = false, isTablet = false,
  } = props;
  const ref = React.createRef();
  const onScreen = useOnScreen(ref, '100px', 0.5);
  const [debounce, setDebounce] = React.useState(true);
  const coverLayerOffset = isMobile ? 1.2 : isTablet ? 1 : 0.6;

  useEffect(() => {
    if (onScreen && debounce) {
      anime({
        targets: '.cascade-fade-h',
        opacity: [1, 0],
        translateY: 100,
        duration: 1000,
        direction: 'reverse',
        easing: 'linear',
        delay: anime.stagger(60, { direction: 'reverse' }),
      });
      setDebounce(false);
    }
  }, [onScreen, debounce]);
  
  return (
    <>
      <ParallaxLayer
        className="slide-h-container"
        factor={layoutFactor}
        offset={pOffset + offsetAdjust}
        speed={0}
      >
        <div>
          <div className="brand-title cascade-fade-h fade-up-prep">
            {t("brandsContentTitle")}
          </div>
        </div>
        <div ref={ref} />
        <div className="brand-logo-grid">
          {brandList.map((logo, i) => (
            <div
              className="cascade-fade-h fade-up-prep"
              key={i}
              style={{
                height: "130px",
                width: "130px",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  height: "100%",
                  verticalAlign: "middle",
                }}
              />
              <img
                alt="logos"
                src={logo}
                style={{
                  margin: "auto",
                  maxHeight: "100%",
                  verticalAlign: "middle",
                  width: "100%",
                }}
              />
            </div>
          ))}
        </div>
      </ParallaxLayer>
      <ParallaxLayer
        className="slide-h-background-2"
        offset={pOffset + offsetAdjust + coverLayerOffset}
        speed={0}
      />
      <ParallaxLayer
        className="slide-h-background"
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        speed={0}
      />
    </>
  );
};

export default HOurBrands;
