/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

class CustomDropDownAccount extends Component {
  render() {
    const {
      input,
      meta: { touched, error },
      t,
    } = this.props;
    const hasError = touched && error !== undefined;
    const renderSelectOptions = (option) => (
      <option key={option} value={option}>
        {option}
      </option>
    );
    return (
      <div className="input-container">
        <label>{this.props.customLabel}</label>
        <div className="input-wrapper">
          <select
            {...input}
            onChange={(value) => {
              input.onChange(value);
            }}
            className={classNames({
              touched,
              error: hasError,
              ok: !hasError,
              empty: !input.value,
              "not-empty": input.value,
            })}
          >
            <option value="">{t("selectAccount")}</option>
            {this.props.options.map(renderSelectOptions)}
          </select>
          {hasError && touched && (
            <div className="error-icon">
              <i className="fa fa-exclamation-circle" />
            </div>
          )}
          {!hasError && touched && (
            <div className="ok-icon">
              <i className="fa fa-check" />
            </div>
          )}
          {hasError && <div className="error-message">{error}</div>}
        </div>
      </div>
    );
  }
}

CustomDropDownAccount.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  // meta: PropTypes.shape({
  //   touched: PropTypes.bool,
  //   error: PropTypes.bool,
  // }),
  placeholder: PropTypes.string,
};

CustomDropDownAccount.defaultProps = {
  placeholder: "",
  // meta: {
  //   error: false,
  //   touched: false,
  // },
};

/*
<CountryDropdown
            {...input}
            id={custom.id}
            value={input.value}
            name={input.name}
            onChange={
                      (value) => {
                          if (custom.handleChange) { custom.handleChange(value); }
                          input.onChange(value);
                      }
                  }
            className={classNames('country-dropdown', {
              touched, error: hasError, ok: !hasError, empty: !input.value, 'not-empty': input.value,
            })}
          />
*/

/*
{(hasError && touched) &&
          <div className="error-icon">
            <i className="fa fa-exclamation-circle" />
          </div>
              }
          {(!hasError && touched) &&
          <div className="ok-icon">
            <i className="fa fa-check" />
          </div>
              }
          {hasError &&
          <div className="error-message">{error}</div>
              }
*/

export default withTranslation("customDropDownAccount")(CustomDropDownAccount);
