import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router';
import './BasicMenu.scss';

function BasicMenu({ visible }) {
  const { t } = useTranslation();
  if (!visible) return null;

  return (
    <nav className="basic-menu">
      <ul>
        <li className="menu-item">
          <Link activeClassName="active" onlyActiveOnIndex to="/">
            {t("home")}
          </Link>
        </li>
        <li className="menu-item">
          <Link activeClassName="active" to="/about-us">
            {t("aboutUs")}
          </Link>
        </li>
        <li className="menu-item">
          <Link activeClassName="active" to="/brand-services">
            {t("brandServices")}
          </Link>
        </li>
        <li className="menu-item">
          <Link activeClassName="active" to="/careers">
            {t("careers")}
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/brand-services#contact-us">{t("contact")}</Link>
        </li>
        <li className="brands-btn">
          <Link activeClassName="active" to="/portal/sign-in">
            {t("brands")}
          </Link>
        </li>
        {/* removed for safety deploys */}
        {/* <li className="brands-btn">
          <Link
            activeClassName="active"
            to="/brand-services"
          >
            Brands
          </Link>
        </li> */}
        <li className="creators-btn">
          <Link activeClassName="active" to="/sign-up">
            {t("creators")}
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default BasicMenu;
