import React from 'react';
import { reducer as formReducer } from 'redux-form';
import { findIndex } from 'lodash';

// Here you can manipulate readux-from "forms"
const form = formReducer.plugin({
  completeRegistrationForm: (state, action) => { // <------ 'editMyInfoForm' is the form name given to reduxForm()
    switch (action.type) {
      case 'RESET_EDIT_USER_INFO':
        return {
          ...state,
          values: {},
          fields: {},
        };
      case 'ASSIGN_EDIT_USER_INFO':
        return {
          ...state,
          values: {
            ...action.payload.fields,
            year: (action.payload.fields.birthDate) ? action.payload.fields.birthDate.substring(0, 4) : 'year',
            day: (action.payload.fields.birthDate) ? action.payload.fields.birthDate.substring(8, 10) : 'day',
          },
          fields: {},
          loaded: true,
        };
      default:
        return state;
    }
  },
  adminUserForm: (state, action) => {
    switch (action.type) {
      case 'RESET_EDIT_USER_INFO':
        return {
          ...state,
          values: {},
          fields: {},
        };
      case 'ASSIGN_EDIT_USER_INFO':
        return {
          ...state,
          values: {
            ...action.payload.fields,
          },
          fields: {},
          loaded: true,
        };
      default:
        return state;
    }
  },
  questForm: (state, action) => {
    switch (action.type) {
      case 'ASSIGN_QUEST_INFO':
        return {
          ...state,
          values: {
            ...action.payload.fields,
            startDateTime: action.payload.fields.startDateTime ? new Date(action.payload.fields.startDateTime).toISOString().replace('T', ' ').substring(0, 16) : new Date().toISOString().split('T')[0],
            endDateTime: action.payload.fields.endDateTime ? new Date(action.payload.fields.endDateTime).toISOString().replace('T', ' ').substring(0, 16) : new Date().toISOString().split('T')[0],
            openRegistrationDate: action.payload.fields.openRegistrationDate ? new Date(action.payload.fields.openRegistrationDate).toISOString().replace('T', ' ').substring(0, 16) : '',
            closeRegistrationDate: action.payload.fields.closeRegistrationDate ? new Date(action.payload.fields.closeRegistrationDate).toISOString().replace('T', ' ').substring(0, 16) : '',
            expectedPayoutDate: action.payload.fields.expectedPayoutDate ? new Date(action.payload.fields.expectedPayoutDate).toISOString().replace('T', ' ').substring(0, 16) : '',
            platforms: action.payload.fields.platforms ? action.payload.fields.platforms.map(p => ({
              value: p.id,
              label: <span style={{ zIndex: '1000' }}>{p.platform}</span>,
            }))[0] : {},
          },
          fields: {},
          loaded: true,
        };
      case 'SWAP_QUEST_STEP':
        return {
          ...state,
          values: {
            ...state.values,
            blocks: (action.payload.kind === 'blocks') ? action.payload.arr : state.values.blocks,
            stepsPage2: (action.payload.kind === 'page-2') ? action.payload.arr : state.values.stepsPage2,
            stepsPage3: (action.payload.kind === 'page-3') ? action.payload.arr : state.values.stepsPage3,
          },
          fields: {},
          loaded: true,
        };
      case 'SWAP_REQUIREMENTS':
        return {
          ...state,
          values: {
            ...state.values,
            requirements: action.payload.arr,
          },
          fields: {},
          loaded: true,
        };
      case 'TITLE_SLUG_APPLY':
        return {
          ...state,
          values: {
            ...state.values,
            slug: action.payload.str,
          },
          fields: {},
          loaded: true,
        };
      case 'RESET_QUEST_INFO':
        return {
          ...state,
          values: {},
          fields: {},
        };
      case 'ASSIGN_QUEST_DEFAULT_INFO':
        return {
          ...state,
          values: {
            isPrivate: true,
            emailFailure: {
              shouldSend: true,
            },
            emailReminder: {
              shouldSend: true,
            },
            requirePreapproval: true,
            hasLanding: true,
            status: 'active',
            userCanleave: true,
            // platformToStream: 'twitch',
            plus18: true,
            countries: [{ value: 'United States', label: <span style={{ zIndex: '1000' }}>United States</span> }],
            languages: [{ value: 'en', label: <span style={{ zIndex: '1000' }}>English</span> }],
          },
          fields: {},
        };
      case 'ASSIGN_QUEST_SLUG_ERROR':
        return {
          ...state,
          syncErrors: {
            slug: action.payload.errorMessage,
          },
          loaded: true,
        };
      case 'UPDATE_CC_QUEST_FORM':
        let requirements;
        if (action.payload.fields.icon) {
          requirements = state.values.requirements;
          requirements[action.payload.fields.pointInQuest][action.payload.fields.index].icon = action.payload.fields.icon;
          requirements[action.payload.fields.pointInQuest][action.payload.fields.index].customIcon = true;
        }

        return {
          ...state,
          values: {
            ...state.values,
            requiredBanner: (action.payload.fields.requiredBanner) ? action.payload.fields.requiredBanner : state.values.requiredBanner,
            regularImage: (action.payload.fields.regularImage) ? action.payload.fields.regularImage : state.values.regularImage,
            overlayImage: (action.payload.fields.overlayImage) ? action.payload.fields.overlayImage : state.values.overlayImage,
            requirements: action.payload.fields.icon ? requirements : state.values.requirements,
          },
          fields: {},
          loaded: true,
        };
      case 'REMOVE_ICON':
        const requirementsNew = state.values.requirements ? state.values.requirements : [];
        if (action.payload.fields.pointInQuest) {
          requirementsNew[action.payload.fields.pointInQuest][action.payload.fields.index].icon = null;
          requirementsNew[action.payload.fields.pointInQuest][action.payload.fields.index].customIcon = false;
        }
        return {
          ...state,
          values: {
            ...state.values,
            requirements: requirementsNew,
          },
          fields: {},
          loaded: true,
        };
      default:
        return state;
    }
  },
  profileInfoForm: (state, action) => {
    switch (action.type) {
      case 'ASSIGN_PROFILE_INFO_FORM':
        return {
          ...state,
          values: {
            ...action.payload.fields,
            year: (action.payload.fields.birthDate) ? action.payload.fields.birthDate.substring(0, 4) : 'year',
            day: (action.payload.fields.birthDate) ? action.payload.fields.birthDate.substring(8, 10) : 'day',
          },
          fields: {},
          loaded: true,
        };
      default:
        return state;
    }
  },
  adminGameForm: (state, action) => {
    switch (action.type) {
      case 'ASSIGN_GAME_INFO':
        return {
          ...state,
          values: {
            ...action.payload.fields,
            isOnSale: action.payload.fields.isOnSale || false,
            isPrivate: action.payload.fields.isPrivate || false,
            displayOnNoizStore: action.payload.fields.displayOnNoizStore || false,
            saleEndDate: action.payload.fields.saleEndDate ? new Date(action.payload.fields.saleEndDate).toISOString().replace('T', ' ').substring(0, 16) : new Date().toISOString().split('T')[0],
            twitter: findIndex(action.payload.fields.socialNetworks, { name: 'twitter' }) >= 0 ? action.payload.fields.socialNetworks[findIndex(action.payload.fields.socialNetworks, { name: 'twitter' })].url : '',
            facebook: findIndex(action.payload.fields.socialNetworks, { name: 'facebook' }) >= 0 ? action.payload.fields.socialNetworks[findIndex(action.payload.fields.socialNetworks, { name: 'facebook' })].url : '',
            instagram: findIndex(action.payload.fields.socialNetworks, { name: 'instagram' }) >= 0 ? action.payload.fields.socialNetworks[findIndex(action.payload.fields.socialNetworks, { name: 'instagram' })].url : '',
            twitch: findIndex(action.payload.fields.socialNetworks, { name: 'twitch' }) >= 0 ? action.payload.fields.socialNetworks[findIndex(action.payload.fields.socialNetworks, { name: 'twitch' })].url : '',
            youtube: findIndex(action.payload.fields.socialNetworks, { name: 'youtube' }) >= 0 ? action.payload.fields.socialNetworks[findIndex(action.payload.fields.socialNetworks, { name: 'youtube' })].url : '',
            platforms: action.payload.fields.platforms.map(p => ({
              value: p.id,
              label: <span style={{ zIndex: '1000' }}>{p.platform}</span>,
            })) || [],
          },
          fields: {},
          loaded: true,
        };
      case 'SWAP_GAME_GALLERY':
        return {
          ...state,
          values: {
            ...state.values,
            gallery: action.payload.arr,
          },
          fields: {},
          loaded: true,
        };
      case 'UPDATED_ADMIN_GAME_FORM':
        return {
          ...state,
          values: {
            ...state.values,
            cover: action.payload.fields.cover || state.values.cover,
            coverPortrait: action.payload.fields.coverPortrait || state.values.coverPortrait,
            logo: action.payload.fields.logo || state.values.logo,
            developerLogo: action.payload.fields.developerLogo || state.values.developerLogo,
            gallery: action.payload.fields.gallery || state.values.gallery,
            description: action.payload.fields.description || state.values.description,
            saleDescription: action.payload.fields.saleDescription || state.values.saleDescription,
            soldOutMessage: action.payload.fields.soldOutMessage || state.values.soldOutMessage,
            actionMessage: action.payload.fields.actionMessage || state.values.actionMessage,
          },
        };
      case 'ASSIGN_GAME_SLUG_ERROR':
        return {
          ...state,
          syncErrors: {
            slug: action.payload.errorMessage,
          },
          loaded: true,
        };
      case 'ASSIGN_GAME_DEFAULT_INFO':
        return {
          ...state,
          values: {
            isOnSale: false,
            isPrivate: false,
          },
          fields: {},
        };
      default:
        return state;
    }
  },
  adminGameFeaturedForm: (state, action) => {
    switch (action.type) {
      case 'ASSIGN_GAME_FEATURED_INFO':
        return {
          ...state,
          values: {
            ...action.payload.fields,
          },
          fields: {},
          loaded: true,
        };
      default:
        return state;
    }
  },
  loginUpdateForm: (state, action) => {
    switch (action.type) {
      case 'ASSIGN_LOGIN_UPDATE_FORM':
        return {
          ...state,
          values: {
            ...action.payload.fields,
          },
          fields: {},
          loaded: true,
        };
      default:
        return state;
    }
  },
  campaignForm: (state, action) => {
    switch (action.type) {
      case 'ASSIGN_CAMPAIGN_INFO_FORM':
        return {
          ...state,
          values: {
            ...action.payload.fields,
            startDateTime: action.payload.fields.startDateTime ? new Date(action.payload.fields.startDateTime).toISOString().replace('T', ' ').substring(0, 16) : new Date().toISOString().split('T')[0],
            endDateTime: action.payload.fields.endDateTime ? new Date(action.payload.fields.endDateTime).toISOString().replace('T', ' ').substring(0, 16) : new Date().toISOString().split('T')[0],
          },
          fields: {},
          loaded: true,
        };
      case 'ASSIGN_CAMPAIGN_DEFAULT_INFO':
        return {
          ...state,
          values: {
          },
          fields: {},
        };
      default:
        return state;
    }
  },
});

export default form;
