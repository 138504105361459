import AFullService from './AFullService'; // mobile-factor: 1
import BPartnerShips from './BPartnerShips'; // mobile-factor: 1
import CNice from './CNice'; // mobile-factor: 1.2
import DNoizNetwork from './DNoizNetwork'; // mobile-factor: 1.5
import EMacroInfluencer from './EMacroInfluencer'; // mobile-factor 1.2
import FMicroInfluencer from './FMicroInfluencer'; // mobile-factor 1.2
import GTheRightMix from './GTheRightMix'; // mobile-factor 1.2
import HOurBrands from './HOurBrands'; // mobile-factor 1.8
import InfluencerMarketing from './InfluencerMarketing'; // mobile-factor 1.8
import JManagedCampaign from './JManagedCampaign'; // mobile-factor  1.8
import KSponsoredContent from './KSponsoredContent'; // mobile-factor  1.8
import LContactUs from './LContactUs';

// FACTORS are multiples of windowheight that feed react-spring-parallax
// OFFSET is the page on which the slide will fall
// SPEED affects how dramatic the parallax effect is *using it on backgrounds creates spaces between slides*

const mobilePageFactors = [1, // AFullService
  1, // BPartnerShips
  1, // CNice
  1, // DNoizNetwork
  1, // EMacroInfluencer
  1, // FMicroInfluencer
  1, // GTheRightMix
  2, // HOurBrands
  3, // InfluencerMarketing
  1.5, // JManagedCampaign
  2.3, // KSponsoredContent
  1.2, // LContactUs
];
const tabletPageFactors = [1, // A Full Service
  0.9, // BPartnerShips
  0.8, // CNice
  1, // DNoizNetwork
  0.6, // EMacroInfluencer
  0.6, // FMicroInfluencer
  0.6, // GTheRightMix
  1.6, // HOurBrands
  2.1, // InfluencerMarketing
  1.5, // JManagedCampaign
  2, // KSponsoredContent
  1.2, // LContactUs
];
const desktopPageFactors = [1, // AFullService
  1, // BPartnerShips
  0.8, // CNice
  1.8, // DNoizNetwork
  0.7, // EMacroInfluencer
  0.7, // FMicroInfluencer
  0.7, // GTheRightMix
  1, // HOurBrands
  1.2, // InfluencerMarketing
  1.2, // JManagedCampaign
  1.1, // KSponsoredContent
  1.2, // LContactUs
];


const twoDec = x => parseFloat(Number.parseFloat(x).toFixed(2));

const pageMaker = r => r.reduce((acc, item) => {
  acc += item;
  return acc;
}, 0.3);

const mobilePage = pageMaker(mobilePageFactors) + 0.5;
const tabletPage = pageMaker(tabletPageFactors);
const desktopPage = pageMaker(desktopPageFactors) + 0.4;
// max desktop page adjustment in BrandServices.js

const offsetAdjustment = (factors) => {
  let adj = 0;

  const noBase = factors.map(f => f - 1);

  const final = noBase.map((fac) => {
    const adjustment = adj;
    adj += fac;
    return twoDec(adjustment);
  });
  return final;
};

const mobileOffsets = offsetAdjustment(mobilePageFactors);
const tabletOffsets = offsetAdjustment(tabletPageFactors);
const desktopOffsets = offsetAdjustment(desktopPageFactors);
const componentList = [AFullService, BPartnerShips, CNice, DNoizNetwork, EMacroInfluencer, FMicroInfluencer, GTheRightMix, HOurBrands, InfluencerMarketing, JManagedCampaign, KSponsoredContent, LContactUs];


export const brandServices = componentList.map((c, i) => ({
  Slide: c, mobileOffset: mobileOffsets[i] || 0, tabletOffset: tabletOffsets[i] || 0, desktopOffset: desktopOffsets[i] || 0, mobileFactor: mobilePageFactors[i] || 1, tabletFactor: tabletPageFactors[i] || 1, desktopFactor: desktopPageFactors[i] || 1,
}));


export const mobilePages = mobilePage;
export const tabletPages = tabletPage;
export const desktopPages = desktopPage;

