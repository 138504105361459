import React from "react";

import ActiveItems from "./ActiveItems";
import AddNew from "./AddNew";
import QuestInsights from "./QuestInsights";
import ReviewApplicantsDeliverables from "./ReviewApplicantsDeliverables";

import {
  defaultProductMvpButtons,
  defaultMvpCampaignButtons,
  defaultMvpQuestButtons,
} from "./PortalRightConfig";

import "./PortalRight.scss";

const PortalRight = (props) => {
  const {
    activeQuests,
    applicants,
    campaigns,
    deliverables,
    products,
    // quests,
    tab,
    view: { component },
  } = props;

  const filteredDeliverables = deliverables.filter(
    (d) => d.status === "to-review"
  );

  const HomeRightDefault = () => (
    <>
      <AddNew type={"quest"} />
      <AddNew type={"product"} />
      <ReviewApplicantsDeliverables
        applicants={applicants}
        deliverables={filteredDeliverables}
      />
      <QuestInsights campaigns={campaigns} />
      <ActiveItems
        items={campaigns}
        buttons={defaultMvpCampaignButtons}
        type={"Campaign"}
      />
      <ActiveItems
        items={activeQuests}
        buttons={defaultMvpQuestButtons}
        type={"Quest"}
      />
    </>
  );

  const QuestRightDefault = () => (
    <>
      <AddNew type={"managedCampaign"} />
      <QuestInsights campaigns={campaigns} />
      <ActiveItems
        items={products}
        buttons={defaultProductMvpButtons}
        type={"Product"}
      />
      <ReviewApplicantsDeliverables
        applicants={applicants}
        deliverables={filteredDeliverables}
      />
    </>
  );

  return (
    <div className="PortalRight">
      {(tab === "home" ||
        tab === "discover" ||
        (!tab && component === "Profile")) && <HomeRightDefault />}
      {tab === "quests" && <QuestRightDefault />}
    </div>
  );
};

export default PortalRight;
