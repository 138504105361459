import React from "react";
import NoizLogo from "../logo/noiz-logo.png";
import Invader from "../images/gifs/invade.gif";
import "./MaintenanceMode.scss";
import { useTranslation } from "react-i18next";

const MaintenanceMode = () => {
  const { t } = useTranslation("maintenanceMode");
  return (
    <div className="maintenance-mode">
      <video autoPlay muted loop id="maintenance-mode-myVideo">
        <source
          src="https://http-error-pages.s3.us-west-1.amazonaws.com/spaceBack.5f74cb3c.mp4"
          type="video/mp4"
        />
      </video>
      <div className="maintenance-mode-content">
        <img
          alt="spaceman"
          className="maintenance-mode-spaceman"
          src={Invader}
        />
        <div className="maintenance-mode-noiz-logo">
          <img alt="Noiz" src={NoizLogo} />
        </div>
        <h1>{t("downForScheduledMaintenance")}</h1>
        <p>{t("message")}</p>
        <p>
          {t("inTheMeantime")}{" "}
          <a
            className="maintenance-mode-link"
            href="https://discord.com/invite/N2PrJxK"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("discord")}
          </a>{" "}
          &{" "}
          <a
            className="maintenance-mode-link"
            href="https://twitter.com/noizgg"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>{" "}
          {t("forUpdates")}
        </p>
      </div>
    </div>
  );
};

export default MaintenanceMode;
