import React from 'react';
import {
  AppBar,
  Tab,
  Tabs,
  Tooltip,
} from '@material-ui/core';

export default function TiersWhitelist(props) {
  const {
    memberTiers,
    memberTiersTabClasses,
    setSubTab,
    subTab,
    viewerTiers,
    viewerTiersTabClasses,
    whitelist,
    whitelistTabClasses,
  } = props;

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={subTab}
          onChange={(e, newTab) => setSubTab(newTab)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tooltip title="These are tiers that will allow people to join based on average viewers." placement="top">
            <Tab
              id="viewerTiers"
              label={<>Viewer Tiers&nbsp;<i className={viewerTiersTabClasses} /></>}
            />
          </Tooltip>
          <Tooltip title="These are tiers that will allow users to join based on Twitch User Name." placement="top">
            <Tab
              id="memberTiers"
              label={<>Member Tiers&nbsp;<i className={memberTiersTabClasses} /></>}
            />
          </Tooltip>
          <Tooltip title="Whitelist Twitch Usernames for private access." placement="top">
            <Tab
              id="whitelist"
              label={<>Whitelist&nbsp;<i className={whitelistTabClasses} /></>}
            />
          </Tooltip>
        </Tabs>
      </AppBar>
      {viewerTiers()}
      {memberTiers()}
      {whitelist()}
    </div>
  );
}
