import { useEffect, useRef, useState } from 'react';

const useInfiniteLoading = (props) => {
  const { getItems, pageToLoad, setPageToLoad, pageSize } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const initialPageLoaded = useRef(false);
  const [hasNext, setHasNext] = useState(true); /* 1 */
  // const [hasPrevious, setHasPrevious] = useState(() => pageToLoad.current !== 1); /* 2 */

  const resetItems = () => {
    setPageToLoad(1);
    setItems([]);
    setHasNext(true);
    // setHasPrevious(pageToLoad.current !== 1);
    loadItems(1);
  };

  const loadItems = async page => {
    setIsLoading(true);
    const data = await getItems(page);
    // setHasNext(data.totalPages > pageToLoad.current); /* 3 */
    // setHasPrevious(pageToLoad.current > 1); /* 4 */

    if (data) {
      if (data.length < pageSize) {
        setHasNext(false);
      }

      setItems((prevItems) => {
        /* 5 */
        return [...(prevItems || []), ...data.data.data];
      });
    } else {
      setHasNext(false);
    }

    setIsLoading(false);
  };

  const loadNext = () => {
    const newPage = pageToLoad + 1;
    loadItems(newPage, 'append');
    setPageToLoad(newPage);
  };

  // const loadPrevious = () => {
  //   pageToLoad.current = Number(pageToLoad.current) - 1;
  //   loadItems(pageToLoad.current, 'prepend');
  // };

  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }

    loadItems(pageToLoad, 'append');
    initialPageLoaded.current = true;
  }, [loadItems]);

  return {
    items,
    hasNext,
    // hasPrevious,
    loadNext,
    // loadPrevious,
    isLoading,
    resetItems,
  };
};

export default useInfiniteLoading;
