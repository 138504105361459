/* eslint-disable import/prefer-default-export */

/* export const getConfigList = token => ({
  type: 'GET_CONFIG_LIST',
  payload: {
    token,
  },
}); */

export const joinQuest = (questId, data, token) => ({
  type: "JOIN_QUEST",
  payload: {
    questId,
    data,
    token,
  },
});

export const joinQuestOffplatform = (questId, userId) => ({
  type: "JOIN_QUEST_OFFPLATFORM",
  payload: {
    questId,
    userId,
  },
});

export const leaveQuest = (questId, reason, token) => ({
  type: "LEAVE_QUEST",
  payload: {
    questId,
    reason,
    token,
  },
});

export const requestGameKeyByQuest = (questId, token) => ({
  type: "REQUEST_GAME_KEY_BY_QUEST",
  payload: {
    questId,
    token,
  },
});

export const getQuest = (questId, openModal, token) => ({
  type: "GET_QUEST",
  payload: {
    questId,
    openModal,
    token,
  },
});

export const closeQuest = () => ({
  type: "CLOSE_QUEST",
  payload: {},
});

export const getSFGame = (gameId) => ({
  type: "GET_SF_GAME",
  payload: {
    gameId,
  },
});

export const getSFGameBySlug = (slug) => ({
  type: "GET_SF_GAME_BY_SLUG",
  payload: {
    slug,
  },
});

export const postGamePayment = (description, source, currency, amount) => ({
  type: "POST_GAME_PAYMENT",
  payload: {
    description,
    source,
    currency,
    amount,
  },
});

export const getQuestByIdentifier = (identifier, type = "slug", token) => ({
  type: "GET_QUEST_BY_IDENTIFIER",
  payload: {
    identifier,
    type,
    token,
  },
});

export const getQuestBySlugGuest = (slug, userId) => ({
  type: "GET_QUEST_BY_SLUG_GUEST",
  payload: {
    slug,
    userId,
  },
});

export const requestReferralCodeByQuest = (questId, token) => ({
  type: "REQUEST_REFERRAL_CODE_BY_QUEST",
  payload: {
    questId,
    token,
  },
});

export const getStoreGames = (token) => ({
  type: "GET_STORE_GAMES",
  payload: {
    token,
  },
});
