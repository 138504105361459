/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router";
// import classNames from 'classnames';
import AdminTabs from "../../util/tabs/AdminTabs";
import "./GameList.scss";
import { withTranslation } from "react-i18next";

class GameList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      numPage: 1,
      filter: 0,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    if (!this.props.admin.games.isLoading) {
      this.props.getAGameList(this.state.numPage, this.state.filter, token);
    }
  }

  paginationRight = () => {
    const { token } = this.props.auth;
    if (!this.props.admin.games.isLoading) {
      this.props.getAGameList(this.state.numPage + 1, this.state.filter, token);
    }
    this.setState({
      numPage: this.state.numPage + 1,
    });
  };

  paginationLeft = () => {
    const { token } = this.props.auth;
    if (this.state.numPage === 1) {
      return;
    }
    if (!this.props.admin.games.isLoading) {
      this.props.getAGameList(this.state.numPage - 1, this.state.filter, token);
    }
    this.setState({
      numPage: this.state.numPage - 1,
    });
  };

  generateRowsHTML = (games) => {
    const { t } = this.props;
    if (games.length >= 1) {
      return games.map((game) => (
        <div className="row">
          <div className="table-cell">{game.name}</div>
          <div className="table-cell">{game.status}</div>
          <div className="table-cell">
            <Link
              to={`/admin/games/edit/${game.id}`}
              activeClassName="active"
              href={`/admin/games/edit/${game.id}`}
            >
              <button className="remove-key">{t("edit")}</button>
            </Link>
          </div>
        </div>
      ));
    }
    return <div className="NoGames">{t("noGamesFound")}</div>;
  };

  render() {
    const { t } = this.props;
    const { data: games, pagination = {} } = this.props.admin.games;
    const leftDisabled = pagination.pageNumber === "1";
    const rightDisabled = pagination.pageNumber >= pagination.totalPages;

    const disabledStyling = {
      cursor: "not-allowed",
      opacity: "0.2",
    };

    return (
      <section className="AGameList">
        <section className="AGameList__wrapper">
          <AdminTabs {...this.props} />
          <h2 className="AGameList__title">{t("games")}</h2>
          <div className="AGameList__actions">
            <Link
              to="/admin/games/create"
              activeClassName="active"
              href="/admin/game/create"
            >
              <button>{t("createGame")}</button>
            </Link>
            <Link
              to="/admin/games/featured"
              activeClassName="active"
              href="/admin/game/featured"
            >
              <button>{t("featured")}</button>
            </Link>
          </div>
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <div className="ToggleTable__header__left">
                {t("games")}:
                <div>
                  <React.Fragment>
                    {pagination.totalGames} ({t("page")} {pagination.pageNumber}{" "}
                    {t("of")} {pagination.totalPages})
                  </React.Fragment>
                </div>
              </div>
              <div className="ToggleTable__header__right">
                <button
                  disabled={leftDisabled}
                  onClick={this.paginationLeft.bind(this)}
                  style={leftDisabled ? disabledStyling : {}}
                >
                  <i className="fa fa-caret-left" />
                </button>
                <button
                  disabled={rightDisabled}
                  onClick={this.paginationRight.bind(this)}
                  style={rightDisabled ? disabledStyling : {}}
                >
                  <i className="fa fa-caret-right" />
                </button>
              </div>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">{t("name")}</div>
                  <div className="table-cell headline">{t("status")}</div>
                  <div className="table-cell headline">{t("actions")}</div>
                </div>
                {this.generateRowsHTML(games)}
              </section>
            </section>
            <section className="ToggleTable__footer">
              <section className="ToggleTable__footer__left">
                <button
                  disabled={leftDisabled}
                  onClick={this.paginationLeft.bind(this)}
                  style={leftDisabled ? disabledStyling : {}}
                >
                  <i className="fa fa-caret-left" />
                </button>
              </section>
              <section className="ToggleTable__footer__right">
                <button
                  disabled={rightDisabled}
                  onClick={this.paginationRight.bind(this)}
                  style={rightDisabled ? disabledStyling : {}}
                >
                  <i className="fa fa-caret-right" />
                </button>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

GameList.propTypes = {};

export default withTranslation("gameList")(GameList);
