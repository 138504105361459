import React from "react";
import Calendar from "react-calendar";
import moment from "moment-timezone";
import { numberWithCommas } from "../../../../utils/functions";
import { get30DayDateRange } from "../helpers/index.js";
import "./TimeframeModule.scss";
import { withTranslation } from "react-i18next";

class TimeframeModule extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      dateSelections: [
        moment(props.dates[0]).format("MM/DD/YYYY"),
        moment(props.dates[1]).format("MM/DD/YYYY"),
      ],
      showing30DayAverage: true,
      showingCustomDateRange: false,
      showingDateRangeSelector: false,
      timeframeDates: props.dates,
      timeframeMenu: false,
    };
  }

  handleChangeTimeframePeriod = (e) => {
    this.setState({ timeframePeriod: parseInt(e.target.value) });
  };

  handleChangeTimeframeDates = (timeframeDates) => {
    this.setState({ timeframeDates });
  };

  handleChangeDateSelector = (id, e) => {
    const newDateSelections = [...this.state.dateSelections];
    newDateSelections[id] = e.target.value;
    this.setState({ dateSelections: newDateSelections });
    const newDate = moment(e.target.value, "MM/DD/YYYY", true);
    if (newDate.isValid()) {
      const newTimeframeDates = [...this.state.timeframeDates];
      newTimeframeDates[id] = newDate;
      this.setState({ timeframeDates: newTimeframeDates });
    }
  };

  renderTimeframeMenu = () => {
    const {
      dateSelections,
      showing30DayAverage,
      showingCustomDateRange,
      showingDateRangeSelector,
      timeframeDates,
    } = this.state;

    const { t } = this.props;

    const today = new Date();

    return (
      <>
        <div className="timeframeMenu">
          <div className="selectionTabs">
            <span
              className={showing30DayAverage ? "active" : ""}
              onClick={() => {
                this.props.applyDates(get30DayDateRange());
                this.setState({
                  showing30DayAverage: true,
                  showingCustomDateRange: false,
                  timeframeMenu: false,
                });
              }}
            >
              30 {t("dayAverage")}
            </span>
            <span
              className={showingCustomDateRange ? "active" : ""}
              onClick={() => this.setState({ showingDateRangeSelector: true })}
            >
              {showingDateRangeSelector && <i className="fa fa-chevron-left" />}
              {t("customDateRange")}
            </span>
          </div>
        </div>
        {showingDateRangeSelector && (
          <div className="dateContainer">
            <Calendar
              onChange={this.handleChangeTimeframeDates}
              value={timeframeDates}
              showDoubleView // show two months pls
              selectRange // allow for range selection
              formatShortWeekday={(locale, date) =>
                date.toLocaleDateString(locale, { weekday: "long" }).charAt(0)
              } // format week names as 1st letter
              showNeighboringMonth={false}
              tileDisabled={(e) => e.date > today} // disable future dates
            />
            <div className="dateRange">
              <span style={{ margin: "10px 0px" }}>{t("datesSelected")}:</span>
              <div className="selector">
                <input
                  className="dateInput"
                  onChange={this.handleChangeDateSelector.bind(this, 0)}
                  type="text"
                  value={dateSelections[0]}
                />
                <span style={{ margin: "0px 5px" }}> - </span>
                <input
                  className="dateInput"
                  onChange={this.handleChangeDateSelector.bind(this, 1)}
                  value={dateSelections[1]}
                  type="text"
                />
              </div>
              <button
                className="apply"
                onClick={() => {
                  this.props.applyDates(this.state.timeframeDates);
                  this.setState({
                    showing30DayAverage: false,
                    showingCustomDateRange: true,
                    timeframeMenu: false,
                  });
                }}
              >
                {t("apply")}
              </button>
              <button
                className="apply cancel"
                onClick={() => this.setState({ timeframeMenu: false })}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  render() {
    let { totalReach, t } = this.props;
    const { dates, userData } = this.props;
    const { timeframeMenu } = this.state;

    let dateStart = null;
    let dateEnd = null;

    if (dates) {
      dateStart = moment(dates[0]).format("MM/DD/YY");
      dateEnd = moment(dates[1]).format("MM/DD/YY");
    }

    const numInfluencers =
      userData && userData.length ? numberWithCommas(userData.length) : "0";
    totalReach = totalReach
      ? numberWithCommas(totalReach.toFixed(0)).toLocaleString()
      : "0";

    return (
      <>
        <div
          className="TimeframeModule"
          onClick={() => this.setState({ timeframeMenu: !timeframeMenu })}
        >
          <div>
            {t("dateRange")}:
            <span>
              <h2>
                {dateStart} - {dateEnd}
              </h2>
              <i className={`fa fa-chevron-${timeframeMenu ? "up" : "down"}`} />
            </span>
          </div>
        </div>
        {timeframeMenu && this.renderTimeframeMenu()}
        <div className="DataModule">
          {t("influencers")}:
          <span>
            <h2>{numInfluencers}</h2>
          </span>
        </div>
        <div className="DataModule" style={{ borderTop: "1px solid #565b69" }}>
          {t("totalReach")}:
          <span>
            <h2>{totalReach}</h2>
            <span style={{ marginLeft: "5px" }}>{t("ACCV")}</span>
          </span>
        </div>
      </>
    );
  }
}

export default withTranslation("timeframeModule")(TimeframeModule);
