import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { numberWithCommas } from "../../../../utils/functions";
import { useTranslation } from "react-i18next";

function RegionRow({ region, index }) {
  const { t } = useTranslation("regionRow");
  const userAccvTotal = region.userAccvTotal
    ? numberWithCommas(region.userAccvTotal.toFixed(0))
    : 0;
  const accvPercent = region.accvPercent ? region.accvPercent : "0%";
  const userCount = region.userCount
    ? numberWithCommas(region.userCount.toFixed(0))
    : 0;
  const usersPercent = region.usersPercent ? region.usersPercent : "0%";
  const classes = classNames({
    RegionCell: true,
    even: !!(index % 2),
    odd: !(index % 2),
  });

  return (
    <TableRow>
      <TableCell
        className={classes}
        style={{ paddingLeft: "32px" }}
        colSpan={2}
      >
        {index + 1}. {getCountryEmoji(region.country)} {region.country}
      </TableCell>
      <TableCell className={classes} colSpan={8}>
        <div className="completionSection accv">
          <div className="completionBarWrapper">
            <span className="completionBar" style={{ width: accvPercent }} />
            <span className="completionVal">
              {userAccvTotal} {t("ACCV")} ({accvPercent})
            </span>
          </div>
        </div>
        <span style={{ display: "block", height: "5px" }} />
        <div className="completionSection influencers">
          <div className="completionBarWrapper">
            <span className="completionBar" style={{ width: usersPercent }} />
            <span className="completionVal">
              {userCount} {t("influencers")} ({usersPercent})
            </span>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

const getCountryEmoji = (country) => {
  switch (country) {
    case "United States":
      return (
        <span role="img" aria-labelledby="us">
          🇺🇸
        </span>
      );
    case "France":
      return (
        <span role="img" aria-labelledby="fr">
          🇫🇷
        </span>
      );
    case "United Kingdom":
      return (
        <span role="img" aria-labelledby="uk">
          🇬🇧
        </span>
      );
    case "Portugal":
      return (
        <span role="img" aria-labelledby="pt">
          🇵🇹
        </span>
      );
    case "Russia":
      return (
        <span role="img" aria-labelledby="ru">
          🇷🇺
        </span>
      );
    case "Canada":
      return (
        <span role="img" aria-labelledby="ca">
          🇺🇸
        </span>
      );
    case "Brazil":
      return (
        <span role="img" aria-labelledby="br">
          🇧🇷
        </span>
      );
    case "South Korea":
      return (
        <span role="img" aria-labelledby="sk">
          🇰🇷
        </span>
      );
    case "Germany":
      return (
        <span role="img" aria-labelledby="de">
          🇩🇪
        </span>
      );
    case "Australia":
      return (
        <span role="img" aria-labelledby="au">
          🇦🇺
        </span>
      );
    case "China":
      return (
        <span role="img" aria-labelledby="cn">
          🇨🇳
        </span>
      );
    case "Japan":
      return (
        <span role="img" aria-labelledby="jp">
          🇯🇵
        </span>
      );
    default:
      break;
  }
};

export default RegionRow;
