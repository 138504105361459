import React, { useEffect, useState } from "react";

import { formatTiersForTable, finalsRow } from "./TableHelpers";
import TableBuilder from "./TableBuilder";
import "./ReportTable.scss";
import { getReportsTable } from "../../../../../utils/apiv2";

const ReportTable = ({ campaign = "", quests = [], token = "" }) => {
  const preppedQuests = quests.length
    ? quests.reduce((acc, quest, i) => {
        if (i === quests.length) {
          acc += `${quest}`;
        } else {
          acc += `${quest}, `;
        }
        return acc;
      })
    : "";

  const [data, setData] = useState([]);
  const [questReference, setQuestReference] = useState([]);

  useEffect(() => {
    const init = async () => {
      try {
        const res = await getReportsTable(campaign, preppedQuests);
        setData(res.data.data.tieredData);
        setQuestReference(res.data.data.questsReference);
      } catch (error) {
        console.log(error, "err");
      }
    };
    init();
  }, [campaign]);

  const tableData = formatTiersForTable(data, questReference);
  const totals = finalsRow(tableData);

  return (
    <div className="ReportTable_Container">
      <TableBuilder tableData={tableData} totals={totals} />
    </div>
  );
};

export default ReportTable;
