import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";

import Icon from "../../../../../global/images/icons/Icon";
// import Loading from "../loading/Loading";
import { enterArbitrationBrand } from "../../../../../utils/apiv2";
import CustomCheckbox from "../../CustomInputs/CustomCheckbox";
import { getDefaultIcon } from "../../../../../utils/functions";
import classnames from "classnames";
import "./Arbitration.scss";
import "./ReviewDeliverable.scss";
const streamTimeId = "requiredStreamTimeMinutes"; // since no id exists for this on the model.

const StreamerDeliverablesCard = (StreamerDeliverablesProps) => {
  const {
    content,
    subtype,
    timeRequired,
    title,
    type,
    quest,
    getter,
    setter,
    id,
  } = StreamerDeliverablesProps;
  const clickable = content && !!!timeRequired;

  const checked = getter(id);
  return (
    <div
      className={classnames(
        "StreamerDeliverablesCard",
        clickable && "Clickable"
      )}
    >
      {timeRequired ? (
        <Icon name="clock" />
      ) : (
        <img
          className="StreamerDeliverablesCard__Icon"
          src={getDefaultIcon(type, subtype, quest)}
          alt={title}
        />
      )}
      <div className="StreamerDeliverablesCard__Title">{title}</div>
      <div className={classnames("StreamerDeliverablesCard__Completion")}>
        <CustomCheckbox
          checked={checked}
          onChange={() => setter(id || streamTimeId, !checked)}
        />
      </div>
    </div>
  );
};

const StreamerDeliverables = ({ deliverables, getter, setter }) => {
  const { t } = useTranslation("portalLeft_reviewDeliverable");

  return (
    <div className="StreamerDeliverables">
      <div className="StreamerDeliverables__Title">
        {t("selectDisputedRequirements")}
      </div>
      <div className="StreamerDeliverables__List">
        {deliverables.map((qr) => (
          <StreamerDeliverablesCard
            getter={getter}
            setter={setter}
            key={qr.id}
            {...qr}
          />
        ))}
      </div>
    </div>
  );
};

const Arbitration = (props) => {
  const {
    open,
    setOpen = () =>
      console.log("you need to set a callback to close the ConfirmModal"),
    requirements = [],
    setArbitration = () =>
      console.log("you need to set a callback to set arbitration"),
  } = props;
  const { t } = useTranslation("portalLeft_reviewDeliverable");

  const handleClose = () => setOpen(false);
  const mustSelectReq = t("mustSelectReq");
  const mustAddComment = t("mustAddComment");
  const [selectedDeliverables, setSelectedDeliverables] = useState([]);
  const [comments, setComments] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [errors, setErrors] = useState([]);
  const onTextAreaChange = (e) => setComments(e.target.value);

  const addRemoveDeliverable = (id) => {
    if (selectedDeliverables.includes(id)) {
      setSelectedDeliverables(selectedDeliverables.filter((d) => d !== id));
    } else {
      setSelectedDeliverables([...selectedDeliverables, id]);
    }
  };

  const containsDeliverable = (id) => selectedDeliverables.includes(id);

  console.log(comments, selectedDeliverables);
  const closeAndZeroOut = () => {
    setComments("");
    setSelectedDeliverables([]);
    setAgreedToTerms(false);
    setErrors([]);
    setArbitration();
    handleClose();
  };

  const submitToArbitration = async () => {
    const errors = [];
    if (selectedDeliverables.length === 0) {
      errors.push(mustSelectReq);
    }
    if (comments.length === 0) {
      errors.push(mustAddComment);
    }
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    setErrors(errors);
    const data = {
      disputedRequirements: selectedDeliverables,
      disputeReason: comments,
      userquestId: props?.userQuest?.id,
    };

    await enterArbitrationBrand(data);
    closeAndZeroOut();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="BrandArbitration_ModalBox">
        <div className="BrandArbitration_Deliverables ReviewDeliverable">
          <StreamerDeliverables
            deliverables={requirements}
            setter={addRemoveDeliverable}
            getter={containsDeliverable}
          />
        </div>

        <div className="BrandArbitration_Comments">
          <h1>{t("comments")}</h1>
          <textarea onChange={onTextAreaChange} placeholder={t("placeholder")}>
            {comments}
          </textarea>
          <div className="BrandArbitration_AgreementText">
            <CustomCheckbox
              checked={agreedToTerms}
              onChange={() => setAgreedToTerms(!agreedToTerms)}
            />
            &nbsp; {t("agree")}
          </div>
          <ul className="BrandArbitration_ErrorList">
            {errors.map((e, i) => (
              <li key={i} className="BrandArbitration_Error">
                {e}
              </li>
            ))}
          </ul>
          <button
            className={`BrandArbitration_RejectButton ${
              agreedToTerms ? "Active" : null
            } `}
            onClick={submitToArbitration}
          >
            {" "}
            {t("startNoizReview")}{" "}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default Arbitration;
