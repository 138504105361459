/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
import { withTranslation } from "react-i18next";
import './CareersPost.scss';

const quickStyle = { fontWeight: 800, fontStyle: 'italic' };

class UXIndi extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    const { t } = this.props
    return (
      <section className="CareersPost">
        <section className="CareersPost__container">
          <section className="CareersPostSummary">
            <section className="CareersPostSummary__content">
              <section className="PostingItem">
                <a
                  className="PostingItem__apply-btn"
                  href="mailto:angie@noiz.gg"
                >
                  {t("applyJob")} <i className="fa fa-envelope" />
                </a>
                <section className="PostingItem__wrapper">
                  <h4 className="PostingItem__title">{t("title")}</h4>
                  <section className="PostingItem__categories">
                    <span className="PostingItem__categories--location">
                      {t("sub_title")}
                    </span>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section className="CareersPostDetails">
            <section className="CareersPostDetails__content">
              <div>
                <p dir="ltr">{t("section_1")}</p>
                <p dir="ltr">
                  <strong>{t("section_2.title")}</strong>
                </p>
                <ul>
                  {Array.from({ length: 7 }).map((item, index) => (
                    <li dir="ltr">
                      <p dir="ltr">{t(`section_2.content.${index}`)}</p>
                    </li>
                  ))}
                </ul>
                <p dir="ltr">
                  <strong>{t("section_3.title")}&nbsp;</strong>
                </p>
                <ul>
                  {Array.from({ length: 7 }).map((item, index) => (
                    <li dir="ltr">
                      <p dir="ltr">{t(`section_3.content.${index}`)}</p>
                    </li>
                  ))}
                </ul>
                <p dir="ltr">
                  <strong>{t("section_4.title")}</strong>
                  &nbsp;
                </p>
                <p dir="ltr">{t("section_4.content")}</p>
                <p dir="ltr">
                  <strong>{t("section_5.title")}</strong>
                </p>
                <p dir="ltr">{t("section_5.content.0")}</p>
                <p dir="ltr" style={quickStyle}>
                  {t("section_5.content.1")}
                </p>
                <p dir="ltr" style={quickStyle}>
                  {t("section_5.content.2")}
                </p>
              </div>

              <p className="CareersPostDetails__content--cta">
                {t("email")} <a href="mailto:angie@noiz.gg">angie@noiz.gg</a>
              </p>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation("uxEv")(UXIndi);
