import React, { useEffect, useState } from "react";
import LoadingGif from "../assets/LoadingGif";
import PortraitCards from "./PortraitCards/PortraitCards";

const Products = ({ products, productsLoading }) => {
  const [preparedProducts, setPreparedProducts] = useState([]);

  const editFn = (id, newItem = false) => ({
    ...(newItem ? {} : { currentProduct: id }),
    component: "ProductEdit",
  });
  const detailFn = (id) => ({ currentProduct: id, component: "ProductDetail" });
  const viewAllFn = (type, view) => ({ ...view, component: `ViewAll${type}s` });

  useEffect(() => {
    let p = products.map(({ id, coverPortrait, cover, name, quests }) => ({
      id,
      img: coverPortrait || cover,
      name,
      quests,
    }));
    if (p.length >= 10) {
      p = p.slice(0, 10);
    }
    setPreparedProducts(p);
  }, []);

  if (productsLoading) {
    return <LoadingGif />;
  }

  return (
    <PortraitCards
      items={preparedProducts}
      type="products"
      proper="Product"
      editFn={editFn}
      detailFn={detailFn}
      viewAllFn={viewAllFn}
      showViewAll={true}
    />
  );
};

export default Products;
