import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import "./CustomInputs.scss";

const CustomDate = (props) => {
  const { header, name, value, onChange, error } = props;

  const { t } = useTranslation("components");
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [year, setYear] = useState(null);

  const getDayOption = (day = 31) => {
    const defaultDayOptions = [];
    for (let i = 1; i <= day; i++) {
      defaultDayOptions.push(
        <option key={`day-${i}`} value={i}>
          {i}
        </option>
      );
    }
    return defaultDayOptions;
  };

  const getYearOption = (y = year || new Date().getUTCFullYear()) => {
    const today = new Date();
    const yearOptions = [];
    for (let i = y - 5 || today.getUTCFullYear(); i < y + 5; i++) {
      yearOptions.push(
        <option key={`year-${i}`} value={i}>
          {i}
        </option>
      );
    }
    return yearOptions;
  };

  const [dayOptions, setDayOptions] = useState(getDayOption());
  const [yearOptions, setYearOptions] = useState(getYearOption());

  useEffect(() => {
    if (value && value !== "-") {
      const date = new Date(value);
      setDayOptions(
        getDayOption(getMonthDay(date.getUTCFullYear(), date.getUTCMonth() + 1))
      );
      setYearOptions(getYearOption(date.getUTCFullYear()));
      setMonth(date.getUTCMonth() + 1);
      setDay(date.getUTCDate());
      setYear(date.getUTCFullYear());
    }
  }, []);

  useEffect(() => {
    if (!value || value === "-") return;
    const date = new Date(value);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const maxDay = getDayOption(getMonthDay(year, month));
    setYearOptions(getYearOption(year));
    setDayOptions(maxDay);
    setYear(year);
    setMonth(month);
    setDay(day > maxDay.length ? maxDay.length : day);
  }, [value]);

  const getMonthDay = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const handleChange = (e, target) => {
    let newDate = "";
    const fullCheck = month && day && year;
    if (fullCheck) {
      newDate = `${year}-${month}-${day}`;
    }
    if (target === "month") {
      if (fullCheck) {
        newDate = `${year}-${e.target.value}-${day}`;
      }
      setMonth(e.target.value);
      const maxDay = getDayOption(getMonthDay(year, e.target.value));
      setDayOptions(maxDay);
      if (day >= maxDay.length) {
        setDay(maxDay.length);
        newDate = `${year}-${e.target.value}-${maxDay.length}`;
      }
    } else if (target === "day") {
      if (fullCheck) {
        newDate = `${year}-${month}-${e.target.value}`;
      }
      setDay(e.target.value);
    } else if (target === "year") {
      if (fullCheck) {
        newDate = `${e.target.value}-${month}-${day}`;
      }
      setYear(e.target.value);
      const maxDay = getDayOption(getMonthDay(e.target.value, month));
      setDayOptions(maxDay);
      if (day >= maxDay.length) {
        setDay(maxDay.length);
        newDate = `${e.target.value}-${month}-${maxDay.length}`;
      }
    }
    if (newDate) {
      onChange(name, new Date(newDate));
    }
  };

  const monthOptions = [];
  for (let i = 1; i <= 12; i++) {
    monthOptions.push(
      <option key={`month-${i}`} value={i}>
        {t("customDate.months", { returnObjects: true })[i - 1]}
      </option>
    );
  }

  const defaultOption = (name) => (
    <option value="" key={`${name}-default`} disabled defaultValue hidden>
      {name}
    </option>
  );

  return (
    <div className="CustomInput">
      <div className="CustomInput__Header">{header}</div>
      <div className="CustomInput__Date">
        <select
          className={classnames(
            "CustomInput__Dropdown InputClass Month",
            error ? "Error" : ""
          )}
          name="month"
          value={month || ""}
          onChange={(e) => handleChange(e, "month")}
        >
          {[defaultOption(t("month")), ...monthOptions]}
        </select>
        <select
          className={classnames(
            "CustomInput__Dropdown InputClass Day",
            error ? "Error" : ""
          )}
          name="day"
          value={day || ""}
          onChange={(e) => handleChange(e, "day")}
        >
          {[defaultOption(t("day")), ...dayOptions]}
        </select>
        <select
          className={classnames(
            "CustomInput__Dropdown InputClass Year",
            error ? "Error" : ""
          )}
          name="year"
          value={year || ""}
          onChange={(e) => handleChange(e, "year")}
        >
          {[defaultOption(t("year")), ...yearOptions]}
        </select>
      </div>
    </div>
  );
};

export default CustomDate;
