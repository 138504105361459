import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Icon from "../../../../../global/images/icons/Icon";
import { simpleNotification } from "../../../../../utils/notifications";
import { uploadToS3 } from "../../../../../utils/util";

import "./Assets.scss";

const Assets = (props) => {
  const {
    form: { cover, coverPortrait, logo, developerLogo, gallery = [] },
    errors,
    handleFormChange,
    preSignedUrl: {
      data: { preSignedUrl, url: signedUrl },
    },
    brandUserUploadImageUrl,
    token,
  } = props;

  const { t } = useTranslation("portalLeft");
  const [uploading, setUploading] = useState("");
  const [pendingFile, setPendingFile] = useState(null);
  const [modal, setModal] = useState({
    cover: false,
    coverPortrait: false,
    logo: false,
    developerLogo: false,
  });

  useEffect(() => {
    if (pendingFile && preSignedUrl) {
      uploadToS3(pendingFile, preSignedUrl, handleLoad);
    }
  }, [preSignedUrl]);

  const handleLoad = (e) => {
    if (e.target.status !== 200) {
      simpleNotification({
        level: "error",
        title: t("error"),
        message: t("uploadImgError"),
      });
    } else {
      let newGallery = [...gallery];

      newGallery.push({
        thumb: signedUrl,
        type: "image",
        url: signedUrl,
      });

      handleFormChange(uploading, signedUrl);
      handleFormChange("gallery", newGallery);
    }

    setUploading("");
    setPendingFile(null);
  };

  const handleFileUpload = (files, e, formName) => {
    setUploading(formName);
    try {
      const file = files?.length ? files[0] : e[0];
      setPendingFile(file);
      const fileNameSplit = file.name.split(".");
      const format = fileNameSplit[fileNameSplit.length - 1];

      brandUserUploadImageUrl(format, token);
    } catch (e) {
      setUploading("");
      simpleNotification({
        level: "error",
        title: t("fileErrorTitle"),
        message: t("readFileError"),
        autoDismiss: 7,
      });
    }
  };

  const GalleryCards = ({ handleImgChange }) => (
    <div className="GalleryCards">
      {gallery
        .filter((g) => g.type === "image")
        .map(({ url }) => (
          <img
            alt={url}
            className="GalleryCards__Img"
            key={url}
            onClick={() => handleImgChange(url)}
            src={url}
          />
        ))}
    </div>
  );

  const EmptyCard = ({ name, formName }) => (
    <>
      <div className="EmptyCard">
        <Icon name="upload" color="white" />
        <div className="Assets_Card_Text">{name}</div>
        {gallery.length > 0 && (
          <div
            className="Assets_Card_Select"
            onClick={() =>
              setModal((prevState) => ({
                ...prevState,
                [formName]: !prevState[formName],
              }))
            }
          >
            {t("selectFromProduct")}
          </div>
        )}
        {!uploading && (
          <Dropzone
            className="Assets_Card_Upload"
            onDrop={(e, event) => handleFileUpload(event, e, formName)}
            disablePreview={false}
            multiple={false}
            accept={[".jpg", ".png"]}
            inputProps={{ id: "file" }}
          >
            {t("uploadImage")}
          </Dropzone>
        )}
        {!!uploading && <i className="fa fa-spinner fa-spin fa-3x fa-fw" />}
      </div>
      <div
        className={classnames(
          "Assets_Card_Modal",
          modal[formName] ? "Active" : "Inactive"
        )}
      >
        <GalleryCards
          handleImgChange={(url) => {
            setModal((prevState) => ({
              ...prevState,
              [formName]: false,
            }));
            handleFormChange(formName, url);
          }}
        />
      </div>
    </>
  );

  const PopulatedContent = ({ name, formValue, formName }) => (
    <>
      <img className="Assets_Card_Img" src={formValue} alt={name} />
      <div className="Assets_Card_Text">{name}</div>
      <div
        className="Assets_Card_Delete"
        onClick={() => handleFormChange(formName, "")}
      >
        <Icon name="trash" color="white" />
      </div>
    </>
  );

  const AssetCard = ({ name, formValue, formName }) => (
    <div className="Assets_Card">
      <div className="Assets_Card_Header">
        {t("assetsCardHeader")} {name.toUpperCase()}
      </div>
      <div
        className={classnames(
          "Assets_Card_Content",
          formValue ? "Populated" : "Empty",
          errors.includes(formName) ? "Error" : ""
        )}
      >
        {formValue ? (
          <PopulatedContent
            name={name}
            formValue={formValue}
            formName={formName}
          />
        ) : (
          <EmptyCard name={name} formName={formName} />
        )}
      </div>
    </div>
  );

  return (
    <div className="Assets">
      <div className="Assets_Header">{t("assetsHeader")}</div>
      <AssetCard name={t("cover")} formName="cover" formValue={cover} />
      <AssetCard
        name={t("coverPortrait")}
        formName="coverPortrait"
        formValue={coverPortrait}
      />
      <AssetCard name={t("productLogo")} formName="logo" formValue={logo} />
      <AssetCard
        name={t("developerLogo")}
        formName="developerLogo"
        formValue={developerLogo}
      />
    </div>
  );
};

export default Assets;
