import React from 'react';

// import Icon from '../../global/images/icons/Icon';
import QuestItem from './QuestItem';

import './GameQuestList.scss';

function GameQuestList({ data, user, userQuests }) {
  const {
    cover = '',
    isBrand = false,
    logo = '',
    // owner = {},
    name = '',
    quests = [],
  } = data;

  return (
    <div className={`GQL__Wrapper`}>
      <div className='game-quest-list'>
        <div className="hero">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${cover})`,
            }}
          />
          <div className="bg gradient" />
          <div className="icon-container">
            <div
              className="icon"
              style={{
                backgroundImage: `url(${logo})`,
              }}
            />
          </div>
          <span className="title">
            {name.toUpperCase()}
          </span>
        </div>
        <div className="quest-list">
          {quests.map(quest => (
            <QuestItem
              key={quest.id}
              isBrand={isBrand}
              quest={quest}
              user={user}
              userQuest={userQuests.find(uq => uq.quest === quest.id) || {}}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GameQuestList;
