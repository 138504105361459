/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import CustomInput from "../../utils/redux-forms-custom-fields/CustomInput";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

class RecoverPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit = (userFormInfo) => {
    const userInfo = {
      identity: userFormInfo.identity.toLowerCase(),
      password: userFormInfo.password,
    };
    this.props.onSubmit(userInfo);
  };

  render() {
    const { handleSubmit, t } = this.props;

    return (
      <form
        className="form-container signup"
        autoComplete="off"
        onSubmit={handleSubmit(this.submit.bind(this))}
      >
        <Field
          type="text"
          name="identity"
          id="identity"
          placeholder={t("yourEmail")}
          customLabel2={
            <label data-label={t("label")} data-label-small={t("yourEmail")} />
          }
          component={CustomInput}
        />
        <div className="form__message">{t("message")}</div>
        <div className="form__footer">
          <div className="form__footer__left">
            <button className="cta-button">{t("send")}</button>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.identity || values.identity.trim() === "") {
    errors.identity = t("validate.fieldRequired");
  }
  return errors;
};

RecoverPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation("recoverPasswordForm")(
  reduxForm({
    form: "recoverPasswordForm",
    validate,
  })(RecoverPasswordForm)
);
