import React from "react";
import classNames from "classnames";
import { Link } from "react-router";
import CompleteRegistrationForm from "../../forms/CompleteRegistrationForm";
import { simpleNotification } from "../../../utils/notifications";
import { immutablePop, isLeapYear, pad } from "../../../utils/functions";
import "../login.css";
import "./CompleteRegistration.scss";
import { withTranslation } from "react-i18next";

class CompleteRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showingErrors: false,
      errors: {
        hasError: false,
        code: "",
        message: "",
        all: {},
      },
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;
    const userInfo = {
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      birthDate: user.birthDate ? user.birthDate : "",
      country: user.country ? user.country : "United States",
      timezone: user.timezone ? user.timezone : "",
      gender: user.gender ? user.gender : "",
    };
    const month = userInfo.birthDate
      ? userInfo.birthDate.substring(5, 7)
      : "month";
    userInfo.month = formatMonth(month);
    this.props.assignEditUserInfo(userInfo);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    // Updating things when twitch finish the request loading.
    if (
      this.props.auth.isLoading === true &&
      nextProps.auth.isLoading === false
    ) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.auth.errors.hasError) {
        this.setState({
          showingErrors: true,
          errors: nextProps.auth.errors,
        });
      } else {
        simpleNotification({
          level: "success",
          title: t("informationUpdated"),
          message: t("informationUpdatedMessage"),
        });

        const auth = this.props.auth.user.connectedAccounts;

        if (!(auth.twitch.id || auth.twitch.username)) {
          this.props.router.replace("/connect-accounts");
        } else {
          this.props.router.replace("/quests");
        }
      }
    }
  }

  onSubmit = (userInfo) => {
    const { token } = this.props.auth;
    this.props.updateUserInfo(userInfo, token);
  };

  dismissErrors = () => {
    this.setState({
      showingErrors: false,
      errors: {
        hasError: false,
        code: "",
        message: "",
        all: {},
      },
    });
  };

  render() {
    const { showingErrors, errors } = this.state;
    const { form, t } = this.props;
    let days = [];
    if (
      form &&
      form.completeRegistrationForm &&
      form.completeRegistrationForm.values &&
      form.completeRegistrationForm.values.month
    ) {
      days = getDays(
        form.completeRegistrationForm.values.year,
        form.completeRegistrationForm.values.month
      );
    }

    return (
      <section className="CompleteRegistration LogIn">
        <section className="ModalMaster">
          <section className={classNames("Modal active")}>
            <div className="Modal__layer" role="button" aria-hidden />
            <section className="Modal__wrapper">
              <div className="Modal__left">
                <div className="logo" />
                <span className="blurb">{t("blurb")}</span>
                <div className="shapes-container">
                  <div className="box box1" />
                  <div className="box box2" />
                </div>
              </div>
              <div className="Modal__right">
                <div className="Modal__content">
                  <ul className="Modal__tabs">
                    <li>
                      <Link
                        className="Modal__tab"
                        to="/complete-registration"
                        href="/complete-registration"
                        role="button"
                        aria-hidden
                        activeClassName="active"
                      >
                        {t("completeInfo")}
                      </Link>
                    </li>
                  </ul>
                  <section
                    className={classNames("errors-container", {
                      active: showingErrors,
                    })}
                  >
                    <button
                      className="error-dismiss"
                      type="button"
                      onClick={this.dismissErrors.bind(this)}
                    />
                    <div className="error-message">{errors.message}</div>
                  </section>
                  <CompleteRegistrationForm
                    userTimezone={this.props.auth.user.timezone}
                    onSubmit={this.onSubmit.bind(this)}
                    daysValues={days}
                    auth={this.props.auth}
                  />
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

const days1 = [...Array(31).keys()].map((n) => pad(n + 1));
const days2 = immutablePop(days1);
const days3 = immutablePop(days2);
const days4 = immutablePop(days3);
const formatMonth = (month) => {
  switch (month) {
    case "01":
      return "January";
    case "02":
      return "February";
    case "03":
      return "March";
    case "04":
      return "April";
    case "05":
      return "May";
    case "06":
      return "June";
    case "07":
      return "July";
    case "08":
      return "August";
    case "09":
      return "September";
    case "10":
      return "October";
    case "11":
      return "November";
    case "12":
      return "December";
    default:
      return "Month";
  }
};

const getDays = (y, m) => {
  if (m === "February") return isLeapYear(y) ? days3 : days4;
  if (["April", "June", "September", "November"].includes(m)) return days2;
  return days1;
};

export default withTranslation("completeRegistration")(CompleteRegistration);
