import React from "react";
import { Link } from "react-router";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

import ConnectPlatforms from "../connect-platforms/ConnectPlatforms";
import { simpleNotification } from "../../utils/notifications";
import "./ConnectAccounts.scss";

class ConnectAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    // if (user.role === 'brand') {
    //   this.props.router.replace('/portal');
    // }
    if (
      this.props.router.location.pathname === "/connect-accounts/twitch" &&
      !isEmpty(this.props.router.location.query.code) &&
      !isEmpty(this.props.router.location.query.scope)
    ) {
      // Requesting a JWT token with the oauth callback code.
      const { code, scope } = this.props.router.location.query;
      this.props.loginWithTwitchOAuthCode(code, scope, token);
    }
    if (
      this.props.router.location.pathname === "/connect-accounts/twitter" &&
      !isEmpty(this.props.router.location.query.oauth_token) &&
      !isEmpty(this.props.router.location.query.oauth_verifier)
    ) {
      const { oauth_token, oauth_verifier } = this.props.router.location.query;
      this.props.loginWithTwitterOAuthCode(oauth_token, oauth_verifier, token);
    }
    if (
      this.props.router.location.pathname === "/connect-accounts/youtube" &&
      !isEmpty(this.props.router.location.query.code) &&
      !isEmpty(this.props.router.location.query.scope)
    ) {
      const { code, scope } = this.props.router.location.query;
      this.props.loginWithYoutubeOAuthCode(code, scope, token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    if (
      this.props.auth.youtube.isLoading === true &&
      nextProps.auth.youtube.isLoading === false
    ) {
      this.props.router.replace("/connect-accounts");
      if (nextProps.auth.youtube.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("youtubeLoginError"),
          message: nextProps.auth.youtube.errors.message,
          autoDismiss: 0,
          position: "tl",
        });
        this.props.router.replace("/connect-accounts");
      }
    }
    if (
      this.props.auth.youtube.isDisconnecting === true &&
      nextProps.auth.youtube.isDisconnecting === false
    ) {
      if (nextProps.auth.youtube.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("youtubeDisconnectError"),
          message: nextProps.auth.youtube.errors.message,
          autoDismiss: 0,
          position: "tl",
        });
      } else {
        simpleNotification({
          level: "warning",
          title: t("disconnected"),
          message: t("youtubeAccountDisconnected"),
          autoDismiss: 0,
          position: "tl",
        });
      }
    }
    if (
      this.props.auth.twitter.isLoading === true &&
      nextProps.auth.twitter.isLoading === false
    ) {
      if (nextProps.auth.twitter.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("twitterLoginError"),
          message: nextProps.auth.twitter.errors.message,
          autoDismiss: 0,
          position: "tl",
        });
      }
    }

    // Updating things when twitch finish the request loading.
    if (
      this.props.auth.twitch.isLoading === true &&
      nextProps.auth.twitch.isLoading === false
    ) {
      // When user successfully access.
      // const { user } = nextProps.auth;
      // const isBasicInfoCompleted = (user.firstName && user.lastName && user.birthDate && user.country);
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.auth.twitch.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("twitchLoginError"),
          message: nextProps.auth.twitch.errors.message,
          autoDismiss: 0,
          position: "tl",
        });
        // Removing the query strings code and scope from url.
        if (localStorage.getItem("backToBooth") === "true") {
          localStorage.removeItem("backToBooth");
          this.props.router.replace("/make-some-noiz");
        } else {
          this.props.router.replace("/connect-accounts");
        }
      } else if (localStorage.getItem("backToBooth") === "true") {
        localStorage.removeItem("backToBooth");
        this.props.router.replace("/make-some-noiz?connected=true");
      } else {
        this.props.router.replace("/connect-accounts");
      }
    }
  }

  goBack = () => {
    const pathArray = document.referrer.split("/");
    const host = pathArray[2];
    const canGoBack =
      host === "noiz.gg" ||
      host === "localhost:3000" ||
      host === "staging.noiz.gg" ||
      host === "noiz.xyz";
    const fullReferrer = document.referrer !== "";
    if (canGoBack && fullReferrer && this.props.router.location.key) {
      this.props.router.goBack();
    } else {
      this.props.router.replace("/");
    }
  };

  displayNotConnectedMessage = () => {
    const { t } = this.props;
    simpleNotification({
      level: "warning",
      title: "",
      message: t("displayNotConnectedMessage"),
    });
  };

  render() {
    const {
      t,
      auth: { user },
    } = this.props;
    const isAnyAccountConnected =
      user.connectedAccounts.twitch.id || user.connectedAccounts.youtube.id;

    return (
      <section className="ConnectAccounts">
        <section className="ModalMaster">
          <section className={classNames("Modal Modal__welcome active")}>
            <div
              className="Modal__layer"
              onClick={(e) => {
                if (
                  !(
                    user.connectedAccounts.twitch.id ||
                    user.connectedAccounts.twitch.username
                  )
                ) {
                  e.preventDefault();
                } else {
                  this.goBack();
                }
              }}
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              {(user.connectedAccounts.twitch.id ||
                user.connectedAccounts.twitch.username) && (
                <button
                  className="Modal__close-btn"
                  onClick={this.goBack}
                  aria-hidden
                />
              )}
              <div className="Welcome__header">
                <h1>
                  {t("welcome")} {`${user.firstName} ${user.lastName}`}
                </h1>
              </div>
              <ConnectPlatforms
                auth={this.props.auth}
                config={this.props.config}
                disconnectTwitch={this.props.disconnectTwitch}
                disconnectYoutube={this.props.disconnectYoutube}
              />
              <div className="Welcome__footer">
                <div className="Welcome__footer__left"></div>
                <div className="Welcome__footer__right">
                  <div className="Welcome__footer__right__button">
                    {isAnyAccountConnected && (
                      <button onClick={this.goBack}>{t("close")}</button>
                    )}
                    {!isAnyAccountConnected && (
                      <>
                        <button
                          className="disabled"
                          onClick={this.displayNotConnectedMessage.bind(this)}
                        >
                          {t("close")}
                        </button>
                        <Link className="logout" to="/logout" href="/logout">
                          {t("logout")}
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation("connectAccounts")(ConnectAccounts);
