import React from "react";
// import material ui tooltip
import { Tooltip } from "@material-ui/core";
import { isMvp } from "./renderingHelpers";

import { t } from "i18next";

export const ComingSoon = ({ children, placement = "left", styl = {} }) => {
  if (!isMvp) return <>{children}</>;
  return (
    <Tooltip title={t("comingSoon")} placement={placement}>
      <span style={{ opacity: 0.3, ...styl }}>
        <span style={{ pointerEvents: "none" }}>{children}</span>
      </span>
    </Tooltip>
  );
};

export const CustomTooltipTextBlur = ({
  children,
  placement = "left",
  styl = {},
  text = t("comingSoon"),
}) => (
  <Tooltip title={text} placement={placement}>
    <span style={{ opacity: 0.3, ...styl }}>
      <span style={{ pointerEvents: "none" }}>{children}</span>
    </span>
  </Tooltip>
);

export const MakeInvisible = ({ children }) => {
  if (!isMvp) return children;
  return <></>;
};
