import React from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";

import defaultAvatar from "../../images/avatars/defaultSpaceman.png";

import ConnectPlatforms from "../connect-platforms/ConnectPlatforms";
import ProfileInfoForm from "../forms/ProfileInfoForm";
import { withTranslation } from "react-i18next";

import { simpleNotification } from "../../utils/notifications";
import { getHeadersFromS3Url } from "../../utils/functions";

import { updateAvatarUrl } from "../../utils/apiv2";

import "./Profile.scss";
import Payment from "../../global/components/Payment/Payment";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      dropzoneActive: false,
      activeModal: 0,
    };
    this.days1 = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ];
    this.days2 = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ];
    this.days3 = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
    ];
    this.days4 = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
    ];
  }

  componentDidMount() {
    const { user } = this.props.auth;
    const userInfo = {
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      username: user.username ? user.username : "",
      email: user.email ? user.email : "",
      paymentEmail: user.paymentEmail ? user.paymentEmail : "",
      birthDate: user.birthDate ? user.birthDate : "",
      country: user.country ? user.country : "United States",
      password: "••••••••",
      gender: user.gender ? user.gender : "",
      twitter: user.twitter ? user.twitter : "",
      discord: user.discord ? user.discord : "",
      avatar: user.avatar ? user.avatar : "",
    };
    const month = userInfo.birthDate
      ? userInfo.birthDate.substring(5, 7)
      : "01";
    userInfo.month = this.formatMonth(month);
    this.props.assignProfileInfoForm(userInfo);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    // Updating things when twitch finish the request loading.
    if (
      this.props.auth.isLoading === true &&
      nextProps.auth.isLoading === false
    ) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (!nextProps.auth.errors.hasError) {
        simpleNotification({
          level: "success",
          title: t("accountInfoUpdated"),
          message: t("accountSuccessfullyUpdated"),
        });
      }
      if (nextProps.auth.errors.hasError) {
        if (nextProps.auth.errors.code === "E_OLDPASSWORD_DOES_NOT_MATCH") {
          simpleNotification({
            level: "error",
            title: t("passwordIncorrect"),
            message: t("accountUpdated"),
          });
        } else {
          simpleNotification({
            level: "error",
            title: t("warning"),
            message: t("updatingAccountError"),
          });
        }
      }
    }
    if (
      this.props.auth.youtube.isDisconnecting === true &&
      nextProps.auth.youtube.isDisconnecting === false
    ) {
      if (nextProps.auth.youtube.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("youtubeDisconnectError"),
          message: nextProps.auth.youtube.errors.message,
          autoDismiss: 0,
          position: "tl",
        });
      } else {
        simpleNotification({
          level: "warning",
          title: t("disconnected"),
          message: t("youtubeAccountDisconnected"),
          autoDismiss: 0,
          position: "tl",
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { t } = this.props;
    // only update chart if the data has changed
    if (
      prevProps.auth.isLoading === true &&
      this.props.auth.isLoading === false
    ) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (!this.props.auth.errors.hasError) {
        simpleNotification({
          level: "success",
          title: t("accountInfoUpdated"),
          message: t("accountSuccessfullyUpdated"),
        });
        const { user } = this.props.auth;
        const userInfo = {
          firstName: user.firstName ? user.firstName : "",
          lastName: user.lastName ? user.lastName : "",
          username: user.username ? user.username : "",
          email: user.email ? user.email : "",
          paymentEmail: user.paymentEmail ? user.paymentEmail : "",
          birthDate: user.birthDate ? user.birthDate : "",
          country: user.country ? user.country : "United States",
          password: "••••••••",
          gender: user.gender ? user.gender : "",
          twitter: user.twitter ? user.twitter : "",
          discord: user.discord ? user.discord : "",
          avatar: user.avatar ? user.avatar : "",
        };
        const month = userInfo.birthDate
          ? userInfo.birthDate.substring(5, 7)
          : "01";
        userInfo.month = this.formatMonth(month);
        this.props.assignProfileInfoForm(userInfo);
      }
    }
  }

  onSubmit = (userInfo) => {
    const { token } = this.props.auth;
    const info = {
      ...userInfo,
    };
    this.props.updateProfileInfo(info, token);
  };

  onDragEnter() {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave() {
    this.setState({ dropzoneActive: false });
  }

  onDrop(files, e) {
    const final = files && files.length ? files[0] : e[0];
    this.setState({ dropzoneActive: false });
    this.uploadToS3(final);
  }

  formatMonth = (month) => {
    const { t } = this.props;
    switch (month) {
      case "01":
        return t("january");
      case "02":
        return t("february");
      case "03":
        return t("march");
      case "04":
        return t("april");
      case "05":
        return t("may");
      case "06":
        return t("june");
      case "07":
        return t("july");
      case "08":
        return t("august");
      case "09":
        return t("september");
      case "10":
        return t("october");
      case "11":
        return t("november");
      case "12":
        return t("december");
      default:
        return t("month");
    }
  };

  addZeroes = (param) => {
    const num = param.toString();
    let value = Number(num);
    const res = num.split(".");
    if (res.length === 1 || res[1].length < 3) {
      value = value.toFixed(2);
    }
    return value;
  };

  padWithZeroes = (number, length) => {
    let myString = number.toString();
    while (myString.length < length) {
      myString = `0${myString}`;
    }
    return myString;
  };

  changeStep = (nStep) => {
    this.setState({
      activeModal: nStep,
    });
  };

  handleSelectedFile = (event) => {
    this.uploadToS3(event.target.files[0]);
  };

  handleProgress = (event) => {
    console.log("profile", event);
  };

  handleLoad = (event, url) => {
    console.log(event.currentTarget, "currne");
    const { t } = this.props;
    if (event.target.status === 200) {
      console.log(event.target, "currne");
      const { responseURL } = event.target;
      const toWrite = responseURL.split("?")[0];
      const { token, user } = this.props.auth;
      this.props.updateProfileInfo({ ...user, avatar: toWrite }, token);
    } else {
      simpleNotification({
        level: "error",
        title: t("error"),
        message: t("errorMessage"),
        autoDismiss: 5,
        position: "tl",
      });
    }
    this.setState({
      activeModal: 0,
    });
  };

  disconnectYoutube = () => {
    const { token, user } = this.props.auth;
    this.props.disconnectYoutube(token, user);
  };

  uploadToS3 = async (file) => {
    const { name, type } = file;
    console.log(type, "type");
    console.log(file, "file");
    const ext = name.split(".").pop();
    try {
      const res = await updateAvatarUrl({ type, format: ext });
      console.log(res);
      const { preSignedUrl } = res.data.data;
      console.log(preSignedUrl, "signedurl");
      const headerObject = getHeadersFromS3Url(preSignedUrl);
      console.log(headerObject, "headerObject");
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", preSignedUrl);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.addEventListener("progress", this.handleProgress);
      xhr.addEventListener("load", this.handleLoad);
      xhr.send(file);
    } catch (error) {
      console.error("uploadToS3", error);
    }
  };
  openActiveModal = () => {
    this.setState({ activeModal: 1 });
  };
  render() {
    const { user } = this.props.auth;
    const { activeModal } = this.state;
    const { form, t } = this.props;

    console.log("user", user);

    let days = [];
    if (
      form &&
      form.profileInfoForm &&
      form.profileInfoForm.values &&
      form.profileInfoForm.values.month
    ) {
      const y = form.profileInfoForm.values.year;
      switch (form.profileInfoForm.values.month) {
        case "January":
          days = this.days1;
          break;
        case "February":
          if (0 === y % 4 && (0 !== y % 100 || 0 === y % 400)) {
            days = this.days3;
          } else {
            days = this.days4;
          }
          break;
        case "March":
          days = this.days1;
          break;
        case "April":
          days = this.days2;
          break;
        case "May":
          days = this.days1;
          break;
        case "June":
          days = this.days2;
          break;
        case "July":
          days = this.days1;
          break;
        case "August":
          days = this.days1;
          break;
        case "September":
          days = this.days2;
          break;
        case "October":
          days = this.days1;
          break;
        case "November":
          days = this.days2;
          break;
        default:
          days = this.days1;
          break;
      }
    }

    return (
      <section className="Profile">
        <section className="Profile__container">
          <div className="Profile__top">
            <div className="Profile__top__left">{t("myAccount")}</div>
          </div>
          <div className="Profile__info">
            <div className="Profile__info__title">{t("accountInfo")}</div>
            <div className="Profile__info__content">
              <div className="Profile__info__left">
                <div className="UserInfo">
                  <section className="UserInfo__profile">
                    <div className="UserInfo__profile--container">
                      <img
                        className="UserInfo__profile--container-avatar"
                        src={user?.avatar || defaultAvatar}
                        alt={user?.displayName || user?.username}
                        onClick={this.openActiveModal}
                      />
                      <div
                        className="UserInfo__profile__button"
                        onClick={this.openActiveModal}
                      >
                        {t("edit")}{" "}
                        <i className="fa fa-pencil add-hover-glow ml-4" />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="Profile__info__right">
                <ProfileInfoForm
                  profileInfoForm={this.props.form.profileInfoForm}
                  enableReinitialize
                  initialValues={this.props.auth.user}
                  onSubmit={this.onSubmit.bind(this)}
                  daysValues={days}
                />
              </div>
            </div>
          </div>

          <Payment
            account
            streamer={user.role === "user" ? true : false}
          ></Payment>

          {/* <ConnectPayment /> */}

          <div className="Profile__connect">
            <div className="Profile__connect__title">
              {t("connectAccount.title")}
            </div>
            <div className="Profile__connect__message">
              {t("connectAccount.content")}{" "}
              <a href="mailto:support@noiz.gg">support@noiz.gg</a>.
            </div>

            <div className="Profile__connect__content">
              <ConnectPlatforms
                config={this.props.config}
                auth={this.props.auth}
                disconnectTwitch={this.props.disconnectTwitch}
                disconnectYoutube={this.props.disconnectYoutube}
              />
            </div>
          </div>
        </section>
        <section className="ProfileUpdateImage">
          <section className="ModalMaster">
            <section
              className={classNames(
                "Modal Modal__updateavatar Modal__updateavatar__one",
                {
                  active: activeModal === 1,
                }
              )}
            >
              <div
                onClick={this.changeStep.bind(this, 0)}
                className="Modal__layer"
                role="button"
                aria-hidden
              />
              <section className="Modal__wrapper">
                {this.props.user.preSignedUrl.isLoading && (
                  <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                )}
                {!this.props.user.preSignedUrl.isLoading && (
                  <React.Fragment>
                    <div
                      className={classNames("Modal__updateavatar__border", {
                        active: this.state.dropzoneActive,
                      })}
                    />
                    <div
                      className="Modal__close-btn"
                      style={{
                        display: this.state.dropzoneActive ? "none" : "block",
                      }}
                      onClick={this.changeStep.bind(this, 0)}
                      role="button"
                      aria-hidden
                    />
                    <Dropzone
                      ref={(node) => {
                        this.dropzoneRef = node;
                      }}
                      disableClick={this.state.disableClick}
                      className="Modal__updateavatar__content"
                      onDrop={(e, event) => this.onDrop(event, e)}
                      onDragEnter={this.onDragEnter.bind(this)}
                      onDragLeave={this.onDragLeave.bind(this)}
                      disablePreview={false}
                      multiple={false}
                      accept={[".jpg", ".png"]}
                      inputProps={{ id: "file" }}
                    >
                      <div className="Modal__updateavatar__image" />
                      <div className="Modal__updateavatar__title">
                        {t("modalUpdateAvatarTitle")}
                      </div>
                      <div className="Modal__updateavatar__or">{t("or")}</div>
                      <div className="Modal__updateavatar__action">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          className="inputFile"
                          onChange={this.handleSelectedFile}
                        />
                        <button className="cta__button">
                          {t("chooseFile")}
                        </button>
                      </div>
                    </Dropzone>
                  </React.Fragment>
                )}
              </section>
            </section>
            <section
              className={classNames(
                "Modal Modal__updateavatar Modal__updateavatar__two",
                {
                  active: activeModal === 2,
                }
              )}
            >
              <div
                onClick={this.changeStep.bind(this, 0)}
                className="Modal__layer"
                role="button"
                aria-hidden
              />
              <section className="Modal__wrapper">
                <div className="Modal__updateavatar__border" />
                <div
                  onClick={this.changeStep.bind(this, 0)}
                  className="Modal__close-btn"
                  role="button"
                  aria-hidden
                />
                <section className="Modal__updateavatar__content">
                  <div className="Modal__updateavatar__image" />
                  <div className="Modal__updateavatar__title">
                    {t("uploadingYourAvatar")}
                  </div>
                  <div className="Modal__updateavatar__loading">
                    <div className="Modal__updateavatar__loading__progress" />
                    <div className="Modal__updateavatar__loading__percentage">
                      42%
                    </div>
                    <div className="Modal__updateavatar__loading__fileinfo">
                      <div className="Modal__updateavatar__loading__top">
                        filename.jpg
                      </div>
                      <div className="Modal__updateavatar__loading__bottom">
                        320kb
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </section>
            <section
              className={classNames(
                "Modal Modal__updateavatar Modal__updateavatar__three",
                {
                  active: activeModal === 3,
                }
              )}
            >
              <div
                onClick={this.changeStep.bind(this, 0)}
                className="Modal__layer"
                role="button"
                aria-hidden
              />
              <section className="Modal__wrapper">
                <div
                  onClick={this.changeStep.bind(this, 0)}
                  className="Modal__close-btn"
                  role="button"
                  aria-hidden
                />
                <section className="Modal__updateavatar__content">
                  <section className="Modal__updateavatar__avatar">
                    <div
                      className="Modal__updateavatar__avatar--container"
                      aria-hidden
                    >
                      <figure
                        className="Modal__updateavatar__avatar--container-avatar"
                        style={{ backgroundImage: `url(${user.avatar})` }}
                      />
                    </div>
                  </section>
                  <div className="Modal__updateavatar__title2">
                    {t("uploadCompleted")}
                  </div>
                  <div
                    onClick={this.changeStep.bind(this, 1)}
                    className="Modal__updateavatar__button"
                    aria-hidden
                  >
                    {t("changeImage")}
                  </div>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

Profile.propTypes = {
  // router: PropTypes.shape({
  //  replace: PropTypes.func.isRequired,
  //  location: PropTypes.shape({
  //    pathname: PropTypes.string.isRequired,
  //  }).isRequired,
  // }).isRequired,
  // auth: PropTypes.shape({
  //   token: PropTypes.string.isRequired,
  //   user: PropTypes.shape({
  //
  //   }).isRequired,
  // }).isRequired,
  // user: PropTypes.shape({
  //   currentAgreement: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  //   currentScheduledBroadcast: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  // }).isRequired,
  // logout: PropTypes.func.isRequired,
  // children: PropTypes.element.isRequired,
};

export default withTranslation("profile")(Profile);
