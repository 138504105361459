/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import { orderBy } from "lodash";
import { Field, FieldArray, reduxForm } from "redux-form";
import CustomDropDownArray from "../../utils/redux-forms-custom-fields/CustomDropDownArray";
import { withTranslation } from "react-i18next";

const renderFeatured = ({ fields, meta: { touched, error }, ...custom }) => {
  const { games, t } = custom;
  let configGames = games.map((game) => ({
    value: game.name,
    label: game.name,
  }));
  configGames = orderBy(configGames, ["label"], ["asc"]);
  return (
    <>
      {fields.map((member, index) => (
        <div className="gallery__item" key={index}>
          <div
            onClick={() => fields.remove(index)}
            className="Modal__close-btn"
            aria-hidden
          />
          <Field
            name={`${member}.game`}
            placeholder={t("placeholder.game")}
            customLabel={<label>{t("game")}</label>}
            component={CustomDropDownArray}
            options={configGames}
          />
        </div>
      ))}
      <div className="gallery__add">
        <span onClick={() => fields.push({})} aria-hidden>
          {t("addItem")}
        </span>
      </div>
    </>
  );
};

class AdminGameFeaturedForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit = (info) => {
    this.props.onSubmit(info);
  };

  render() {
    const { handleSubmit } = this.props;
    const { games, t } = this.props;
    return (
      <form
        className="form-container admin-game-form"
        autoComplete="off"
        onSubmit={handleSubmit(this.submit.bind(this))}
      >
        <div className="info-tab-wrap">
          <FieldArray
            name="featured"
            component={renderFeatured}
            t={t}
            games={games}
          />
        </div>
        <div className="form__footer">
          <button className="AGameForm__save--btn">{t("save")}</button>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  return errors;
};

AdminGameFeaturedForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation("adminGameFeaturedForm")(
  reduxForm({
    form: "adminGameFeaturedForm",
    validate,
  })(AdminGameFeaturedForm)
);
