import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";

import defaultAvatar from "../../../images/avatars/defaultSpaceman.png";
import Icon from "../../../global/images/icons/Icon";

import { defaultBaseView, SetViewContext } from "./PortalContext";
import { ComingSoon, CustomTooltipTextBlur } from "./mvp/ComingSoon";
import { getUserBrandPaymentMethods } from "../../../utils/apiv2";

import "./PortalNav.scss";

const Splotch = () => (
  <div className="PortalNav__Tab_Icon_Splotch">
    <Icon color="rgb(144, 64, 250)" name="splotch" />
  </div>
);

const TabIcon = ({ setTab, icon, active = false }) => (
  <div className="PortalNav__Tab_Icon" onClick={setTab}>
    {active ? <Splotch /> : null}
    <div className="PortalNav__Tab_Icon_SVG">
      <Icon color="white" name={icon} />
    </div>
  </div>
);

const AddQuest = () => {
  const { t } = useTranslation("portalNav");
  return (
    <SetViewContext.Consumer>
      {({ setView, view }) => (
        <div
          className="PortalNav__Tab_AddQuest"
          onClick={() =>
            setView({
              component: "QuestEdit",
              tab: view?.tab || "home",
            })
          }
        >
          <div className="PortalNav__Tab_AddQuest_Icon">
            <Icon color="white" name="plus" />
          </div>
          <div className="PortalNav__Tab_AddQuest_Text">{t("addNewQuest")}</div>
        </div>
      )}
    </SetViewContext.Consumer>
  );
};

const UserProfile = ({ user }) => {
  const { t } = useTranslation("portalNav");
  return (
    <div className="PortalNav__Tab_UserProfile">
      <img
        className="PortalNav__Tab_UserProfile_Img"
        src={
          user?.avatar ||
          user?.connectedAccounts?.twitch?.profile_image_url ||
          defaultAvatar
        }
        alt={user?.username}
        onClick={() => window.location.replace("/portal?component=Profile")}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = defaultAvatar;
        }}
      />
      <span className="PortalNav__Tab_UserProfile_Hello">{t("hello")}</span>
      <span className="PortalNav__Tab_UserProfile_Username">
        {user?.username}
      </span>
    </div>
  );
};

const Logout = () => {
  const { t } = useTranslation("portalNav");
  return (
    <Link to="/logout">
      <div className="PortalNav__Tab_Logout">
        <Icon color="white" name="logout" />
        &nbsp;<span className="PortalNav__Tab_Logout_Text">{t("logOut")}</span>
      </div>
    </Link>
  );
};
const PortalNav = (props) => {
  const {
    auth: { token, user },
    getBrandGame,
    getBrandQuest,
    navDict,
    view,
    setView,
  } = props;
  const { t } = useTranslation("portalNav");
  const [paymentMethods, setPaymentMethods] = useState([]);

  const getPaymentMethods = async () => {
    const {
      data: { data: paymentInfo },
    } = (await getUserBrandPaymentMethods()) || { data: [] };
    if (paymentInfo) setPaymentMethods(paymentInfo);
  };

  // write a drop down input component to pick a country

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const validPaymentAccount = paymentMethods.length;
  const outOfDatePaymentAccount =
    validPaymentAccount &&
    new Date(
      `${paymentMethods[0]?.exp_year}-${paymentMethods[0]?.exp_month}-01`
    ) < new Date();

  return (
    <div className="PortalNav_Wrapper">
      <div className="PortalNav">
        {navDict.map(({ icon, tabName, mvp }) => {
          // console.log(mvp, tabName, 'mvp')
          const theTab = (
            <TabIcon
              active={view?.tab === tabName}
              icon={icon}
              key={tabName}
              setTab={() => {
                setView({ ...defaultBaseView, tab: tabName });
                getBrandGame({ statusGame: "all" }, token);
                getBrandQuest(
                  { statusQuest: "all", statusGame: "all", pageSize: 0 },
                  token
                );
              }}
            />
          );
          return !mvp ? (
            <ComingSoon key={`${tabName}-comingsoon`} children={theTab} />
          ) : (
            theTab
          );
        })}
        {validPaymentAccount ? (
          <AddQuest />
        ) : outOfDatePaymentAccount ? (
          <CustomTooltipTextBlur
            text={t("paymentMethod")}
            children={<AddQuest />}
          />
        ) : (
          <CustomTooltipTextBlur
            text={t("paymentMethodProfile")}
            children={<AddQuest />}
          />
        )}
        <UserProfile user={user} />
        <Logout />
      </div>
    </div>
  );
};

export default PortalNav;
