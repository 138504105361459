// Components (external)
import React, { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";

import { Checkbox } from "@material-ui/core";

// Styles
import "./QuestModal.scss";

// Components (internal)
import { fade } from "../../utils/transitionIndex";
import QuestParticipationAgreement from "../static-pages/QuestParticipationAgreement";

import { debounce } from "../../utils/functions";
import { validateEmail } from "../../utils/functions";

const QuestModal = (props) => {
  const {
    activeQuestionIndex,
    closeModal,
    joinQuest,
    nextQuestion,
    open,
    prevQuestion,
    questId,
    questions = [],
    setActiveQuestionIndex,
    token,
  } = props;
  
  const { t } = useTranslation("quest_QuestModal");
  const debounceJoin = debounce(joinQuest, 500);

  const questAgreementIndicatorClasses = classNames({
    indicator: true,
    active: activeQuestionIndex === questions.length,
  });

  const questAgreementClasses = classNames({
    questAgreement: true,
    active: activeQuestionIndex === questions.length,
  });

  const [accepted, setAccepted] = useState(false);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    setAnswers(
      questions.map(({ isRequired, title, type, preFill, isEmailQ }) => ({
        errors: isRequired && !preFill,
        information: preFill || "",
        isRequired,
        isEmailQ,
        title,
        type,
      }))
    );
  }, [questions]);

  const handleAcceptedChange = (event) => {
    setAccepted(event.target.checked);
  };

  const handleAnswerChange = (event, index) => {
    const { value } = event.target;
    const newAnswers = [...answers];
    newAnswers[index].information = value;
    if (value.trim().length > 0) newAnswers[index].errors = false;
    setAnswers(newAnswers);
  };

  const handleSubmit = () => {
    const prepareAnswers = answers.map((a) => {
      delete a.isRequired;
      return a;
    });

    const data = {
      userInformation: prepareAnswers,
    };
    const jsonData = JSON.stringify(data);
    debounceJoin(questId, jsonData, token);
    setActiveQuestionIndex(0);
    closeModal();
  };
  const questionsHtml = questions.map((question, index) => {
    const questionClasses = classNames({
      question: true,
      active: index === activeQuestionIndex,
    });

    return (
      <div className={questionClasses}>
        <h3 className="quest-agreement-title">{question.title}</h3>
        <span>{question.description}</span>
        {!question?.isEmailQ ? (
          <textarea
            className="payment-confirmation-input"
            name={question.title.trim()}
            onChange={(e) => handleAnswerChange(e, index)}
            value={answers[index] && answers[index].information}
          />
        ) : (
          <>
            <input
              className="payment-confirmation-input"
              name={question.title.trim()}
              onChange={(e) => handleAnswerChange(e, index)}
              value={answers[index] && answers[index].information}
            />

            <span className="payment-confirmation-warning">
              {!validateEmail(answers[index]?.information) && (
                <>{t("enterEmailAddress")}</>
              )}
            </span>
          </>
        )}
      </div>
    );
  });

  const questionsIndicatorsHtml = questions.map((question, index) => {
    const indicatorClasses = classNames({
      indicator: true,
      active: index === activeQuestionIndex,
    });

    return (
      <Fragment>
        <span className={indicatorClasses} />
      </Fragment>
    );
  });

  const leftSideButton = {
    fn: activeQuestionIndex === 0 ? closeModal : prevQuestion,
    text: activeQuestionIndex === 0 ? t("cancel") : t("back"),
  };
  const activeAnswer = answers[activeQuestionIndex];

  const isEmailAndNotValid =
    activeAnswer?.isEmailQ && !validateEmail(activeAnswer?.information);
  const agreeMentIsntAccepted =
    activeQuestionIndex === questions.length && !accepted;
  const fieldIsRequiredAndEmpty =
    activeAnswer?.isRequired && activeAnswer?.information.trim().length === 0;
  const rightSideButton = {
    disabled:
      (activeAnswer && activeAnswer?.errors) ||
      agreeMentIsntAccepted ||
      fieldIsRequiredAndEmpty ||
      isEmailAndNotValid,
    fn: activeQuestionIndex === questions.length ? handleSubmit : nextQuestion,
    text: activeQuestionIndex === questions.length ? t("submit") : t("next"),
  };

  const modalButtons = ({ disabled = false, fn, text }) => (
    <button
      className={`${disabled ? "CTAButton__red" : "CTAButton__lightGrey"}`}
      disabled={disabled}
      onClick={fn}
    >
      {disabled ? t("required") : text}
    </button>
  );

  return (
    <TransitionGroup>
      {open && (
        <CSSTransition {...fade()}>
          <div className="QuestModal">
            <div className="quest-modal-closer">
              <div onClick={closeModal}>✕</div>
            </div>
            {questionsHtml}
            <div className={questAgreementClasses}>
              <h1>{t("questAgreement")}</h1>
              <div className="agreementCopy">
                <QuestParticipationAgreement />
              </div>
              <div className="checkbox-container-questmodal">
                <div>{t("agree")}</div>
                <div>
                  <Checkbox
                    checked={accepted}
                    onChange={handleAcceptedChange}
                  />
                </div>
              </div>
            </div>
            <div className="buttonsBar">
              {modalButtons(leftSideButton)}
              {modalButtons(rightSideButton)}
            </div>
            <div className="indicators">
              {questionsIndicatorsHtml}
              <span className={questAgreementIndicatorClasses} />
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default QuestModal;
