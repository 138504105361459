/* eslint-disable import/prefer-default-export */
export const loginWithTwitchOAuthCode = (code, scope, token) => ({
  type: "LOGIN_TWITCH_OAUTH_CODE",
  payload: {
    code,
    scope,
    token,
  },
});
export const loginWithTwitterOAuthCode = (
  oauth_token,
  oauth_verifier,
  token
) => ({
  type: "LOGIN_TWITTER_OAUTH_CODE",
  payload: {
    oauth_token,
    oauth_verifier,
    token,
  },
});

export const loginWithYoutubeOAuthCode = (code, scope, token) => ({
  type: "LOGIN_YOUTUBE_OAUTH_CODE",
  payload: {
    code,
    scope,
    token,
  },
});

export const signIn = (payload) => ({
  type: "SIGN_IN",
  payload,
});

export const signUp = (userInfo) => ({
  type: "SIGN_UP",
  payload: {
    userInfo,
  },
});

export const recover = (identity) => ({
  type: "RECOVER",
  payload: {
    ...identity,
  },
});

export const recoverValidate = (recoveryToken) => ({
  type: "RECOVER_VALIDATE",
  payload: {
    recoveryToken,
  },
});

export const recoverChangePassword = (recoveryToken, password, rePassword) => ({
  type: "RECOVER_CHANGE_PASSWORD",
  payload: {
    recoveryToken,
    password,
    rePassword,
  },
});

export const logout = () => ({
  type: "LOGOUT",
});

export const updateUserInfo = (userInfo, token) => ({
  type: "UPDATE_USER_INFO",
  payload: {
    userInfo,
    token,
  },
});

export const setGlobalInit = () => ({
  type: "GLOBAL_INIT",
});

export const getUserStats = (username) => ({
  type: "GET_USER_STATISTICS",
  payload: {
    username,
  },
});

export const disconnectTwitch = (token, user) => ({
  type: "TWITCH_DISCONNECTION",
  payload: {
    token,
    user,
  },
});

export const disconnectYoutube = (token, user) => ({
  type: "YOUTUBE_DISCONNECTION",
  payload: {
    token,
    user,
  },
});

export const updateProfileInfo = (profileInfo, token) => ({
  type: "UPDATE_PROFILE_INFO",
  payload: {
    profileInfo,
    token,
  },
});

export const validateUserHandshake = (handshake, token) => ({
  type: "VALIDATE_USER_HANDSHAKE",
  payload: {
    handshake,
    token,
  },
});
