import React, { useEffect, useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { makeStyles } from "@material-ui/core/styles";

import { debounce } from "../../../utils/functions";

import "./AddGameModal.scss";
import { useTranslation } from "react-i18next";
import { pingTwitchGames } from "../../../utils/apiv2";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const handlePingTwitchGames = async (searchValue, setFunc) => {
  try {
    const resp = await pingTwitchGames(searchValue);
    setFunc(resp.data.games);
  } catch (error) {
    console.error(error);
  }
};

const debouncePingingTwitchGames = debounce(handlePingTwitchGames, 1000);

const AddGameModal = (props) => {
  const classes = useStyles();

  const { t } = useTranslation("addGameModal");

  const { handleAddSelectedGame, values } = props;

  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [availableGames, setAvailableGames] = useState([]);

  useEffect(() => {
    if (searchValue && searchValue.length > 1)
      debouncePingingTwitchGames(searchValue, setAvailableGames);
    else setAvailableGames([]);
  }, [searchValue]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSearchValue("");
    setAvailableGames([]);
    setOpen(false);
  };

  const handleGameClick = (game) => {
    handleAddSelectedGame(game);
    setAvailableGames(availableGames.filter((g) => g !== game));
  };

  const handleTextChange = (e) => {
    setSearchValue(e.target.value);
  };

  const renderAvailableGames = availableGames
    .filter((g) => !values.includes(g))
    .map((g) => (
      <ListItem key={g} id={g} button onClick={() => handleGameClick(g)}>
        <ListItemIcon>
          <AddBoxIcon />
        </ListItemIcon>
        <ListItemText primary={g} />
      </ListItem>
    ));

  const AddModal = (
    <div id="AddModal" style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">
        {t("add")}
        {values && values.length ? " More" : ""}
        {t("requiredGames")}
      </h2>
      <div id="simple-modal-description" className="AddGameModal--list">
        <Paper style={{ height: 200, overflow: "auto" }}>
          <List>{renderAvailableGames}</List>
        </Paper>
      </div>
      <TextField
        className="AddGameModal--textfield"
        label={t("gameName")}
        onChange={handleTextChange}
        value={searchValue}
        variant="filled"
      />
      <Button onClick={handleClose} className="AddGameModal--close">
        {t("close")}
      </Button>
    </div>
  );

  return (
    <div className="AddGameModal">
      <Button
        variant="contained"
        onClick={handleOpen}
        color="primary"
        className="AddGameModal--button"
      >
        {t("addMoreRequiredGames")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {AddModal}
      </Modal>
    </div>
  );
};

export default AddGameModal;
