/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router";
import "./AccessDenied.scss";
// import PropTypes from 'prop-types';

class AccessDenied extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { t } = this.props;
    return (
      <section className="AccessDenied">
        <div className="AccessDenied__wrapper">
          <div className="AccessDenied__content">
            <h1>{t("oh")}</h1>
            <p>{t("title")}</p>
            <p className="sub">
              {t("sub")}
              <a href="mailto:support@noiz.gg">support@noiz.gg</a>
            </p>
            <Link className="site-gradient-btn" to="/" href="/">
              {t("back")}
            </Link>
          </div>
          <div className="noiz-icon treasure" />
        </div>
      </section>
    );
  }
}

export default withTranslation("accessDenied")(AccessDenied);
