import React from 'react';
import classNames from 'classnames';
import SignUpForm from '../../forms/SignUpForm';
import Language from "../../language/index";
import { Link } from "react-router";
import { withTranslation } from "react-i18next";


import '../login.scss';
import './SignUp.scss';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showingErrors: false,
      errors: {
        hasError: false,
        code: "",
        message: "",
        all: {},
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.auth.isLoading === true &&
      nextProps.auth.isLoading === false
    ) {
      // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.auth.errors.hasError) {
        this.setState({
          showingErrors: true,
          errors: nextProps.auth.errors,
        });
      } else {
        // Redirecting user to Complete Registration
        localStorage.setItem("showWelcome", "true");
        this.props.router.replace("/complete-registration");
      }
    }
  }

  onSubmit = (userInfo) => {
    let userInfo2;
    if (localStorage.getItem("referredSignUp")) {
      userInfo2 = {
        ...userInfo,
        referredSignUp: localStorage.getItem("referredSignUp"),
      };
    } else {
      userInfo2 = {
        ...userInfo,
      };
    }
    this.props.signUp(userInfo2);
  };

  dismissErrors = () => {
    this.setState({
      showingErrors: false,
      errors: {
        hasError: false,
        code: "",
        message: "",
        all: {},
      },
    });
  };

  render() {
    const { showingErrors, errors } = this.state;
    const { t } = this.props;

    return (
      <section className="SignUp LogIn">
        <div className="language-wrapper">
          <Language />
        </div>
        <section className="ModalMaster">
          <section className={classNames("Modal active")}>
            <Link
              to="/"
              href="/"
              className="Modal__layer"
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div className="Modal__left">
                <div className="logo" />
                <span className="blurb">{t("desc")}</span>

                <div className="shapes-container">
                  <div className="box box1" />
                  <div className="box box2" />
                </div>
              </div>
              <div className="Modal__right">
                <Link
                  className="Modal__close-btn"
                  to="/"
                  href="/"
                  role="button"
                  aria-hidden
                />
                <div className="Modal__content">
                  <ul className="Modal__tabs">
                    <li>
                      <Link
                        className="Modal__tab"
                        to="/sign-up"
                        href="/sign-up"
                        role="button"
                        aria-hidden
                        activeClassName="active"
                      >
                        {t("signUp.title")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="Modal__tab"
                        to="/sign-in"
                        href="/sign-in"
                        role="button"
                        aria-hidden
                        activeClassName="active"
                      >
                        {t("signIn.title")}
                      </Link>
                    </li>
                  </ul>
                  <section
                    className={classNames("errors-container", {
                      active: showingErrors,
                    })}
                  >
                    <button
                      className="error-dismiss"
                      type="button"
                      onClick={this.dismissErrors.bind(this)}
                    />
                    <div className="error-message">{errors.message}</div>
                  </section>
                  <SignUpForm
                    auth={this.props.auth}
                    onSubmit={this.onSubmit.bind(this)}
                  />
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation("sign")(SignUp);
