/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */

import React, { Component } from "react";
// import classNames from 'classnames';
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { withTranslation } from "react-i18next";

class CustomInputYesNoRadio extends Component {
  render() {
    const { input, t, ...custom } = this.props;
    return (
      <div className="input-container">
        <label>{custom.customLabel ? custom.customLabel : ""}</label>
        <div className="input-wrapper" style={{ flexFlow: "row" }}>
          <label className="container-radios-yes">
            {t("yes")}
            <Field
              {...input}
              component="input"
              type="checkbox"
              name={input.name}
              checked={input.value}
              onChange={() => {
                if (input.value === false) {
                  input.onChange(!input.value);
                }
              }}
              style={{ width: "initial", marginRight: "5px" }}
            />
            <span className="checkmark" />
          </label>
          <label className="container-radios-no">
            {t("no")}
            <Field
              {...input}
              component="input"
              type="checkbox"
              name={input.name}
              checked={input.value}
              onChange={() => {
                if (input.value === true) {
                  input.onChange(!input.value);
                }
              }}
              style={{ width: "initial", marginRight: "5px" }}
            />
            <span className="checkmark" />
          </label>
        </div>
      </div>
    );
  }
}

CustomInputYesNoRadio.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
};

CustomInputYesNoRadio.defaultProps = {
  placeholder: "",
};

export default withTranslation("customInputYesNoRadio")(CustomInputYesNoRadio);
