import React from "react";
import classNames from "classnames";
import { Field, reduxForm, change } from "redux-form";
import moment from "moment";
import CustomInput from "../../utils/redux-forms-custom-fields/CustomInput";
import CustomSelectBasic from "../../utils/redux-forms-custom-fields/CustomSelectBasic";
import CustomDropDown from "../../utils/redux-forms-custom-fields/CustomDropDown";
import { simpleNotification } from "../../utils/notifications";
import CustomDropDownGeneric from "../../utils/redux-forms-custom-fields/CustomDropDownGeneric";
import { formatMonth, formatMonth2 } from "../../utils/functions";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const dateStoreFormat = "YYYY-MM-DD";

class ProfileInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.changePassword = {};
    this.state = {
      formEditable: false,
      streamingConsoles: props.initialValues.streamingConsoles
        ? props.initialValues.streamingConsoles
        : {
            pc: false,
            xbox: false,
            playstation: false,
            nintendo: false,
            mobile: false,
          },
    };
  }
  UNSAFE_componentWillReceiveProps() {
    const { t } = this.props;
    this.changePassword = {
      text: t("changePassword"),
      checked: false,
    };
  }

  handleCancel = () => {
    const { profileInfoForm, t } = this.props;
    let isValidDate = false;
    if (
      profileInfoForm &&
      profileInfoForm.values &&
      profileInfoForm.values.birthDate
    ) {
      isValidDate = moment(
        profileInfoForm.values.birthDate,
        dateStoreFormat,
        true
      ).isValid();
    }
    simpleNotification({
      level: "warning",
      title: t("changesNotSaved"),
      message: t("changesNotSavedMessage"),
    });
    this.changePassword.text = t("changePassword");
    this.changePassword.checked = false;
    this.props.reset();
    this.setState({
      formEditable: false,
    });
    if (isValidDate) {
      if (profileInfoForm.values.birthDate) {
        const month = profileInfoForm.values.birthDate.substring(5, 7);
        this.props.dispatch(
          change(
            "profileInfoForm",
            "year",
            profileInfoForm.values.birthDate.substring(0, 4)
          )
        );
        this.props.dispatch(
          change(
            "profileInfoForm",
            "day",
            profileInfoForm.values.birthDate.substring(8, 10)
          )
        );
        this.props.dispatch(
          change("profileInfoForm", "month", formatMonth2(month))
        );
      } else {
        this.props.dispatch(change("profileInfoForm", "year", "year"));
        this.props.dispatch(change("profileInfoForm", "day", "day"));
        this.props.dispatch(change("profileInfoForm", "month", "month"));
      }
    }
  };

  handleEdit = () => {
    this.setState({
      formEditable: true,
    });
  };

  submit({
    firstName,
    lastName,
    username,
    email,
    paymentEmail,
    country,
    oldPassword,
    newPassword,
    gender,
    birthDate,
    day,
    month,
    year,
    twitter,
    discord,
  }) {
    let userFormInfo = {};
    if (!this.changePassword.checked) {
      userFormInfo = {
        firstName,
        lastName,
        username,
        email,
        paymentEmail,
        country,
        gender,
        changePassword: false,
        birthDate,
        day,
        month,
        year,
        twitter,
        discord,
      };
    } else {
      userFormInfo = {
        firstName,
        lastName,
        username,
        email,
        paymentEmail,
        country,
        gender,
        changePassword: true,
        oldPassword,
        newPassword,
        birthDate,
        day,
        month,
        year,
        twitter,
        discord,
      };
    }

    const { t } = this.props;

    userFormInfo.birthDate = `${userFormInfo.year}-${formatMonth(
      userFormInfo.month
    )}-${userFormInfo.day}`;
    userFormInfo.streamingConsoles = this.state.streamingConsoles;

    // Passing this params a level up on submit parent function.
    this.setState({
      formEditable: false,
    });
    this.changePassword.text = t("changePassword");
    this.changePassword.checked = false;
    this.props.onSubmit(userFormInfo);
    const { profileInfoForm } = this.props;
    let isValidDate = false;
    if (
      profileInfoForm &&
      profileInfoForm.values &&
      profileInfoForm.values.birthDate
    ) {
      isValidDate = moment(
        profileInfoForm.values.birthDate,
        dateStoreFormat,
        true
      ).isValid();
    }
    if (isValidDate) {
      if (profileInfoForm.values.birthDate) {
        const monthTemp = profileInfoForm.values.birthDate.substring(5, 7);
        this.props.dispatch(
          change(
            "profileInfoForm",
            "year",
            profileInfoForm.values.birthDate.substring(0, 4)
          )
        );
        this.props.dispatch(
          change(
            "profileInfoForm",
            "day",
            profileInfoForm.values.birthDate.substring(8, 10)
          )
        );
        this.props.dispatch(
          change("profileInfoForm", "month", formatMonth2(monthTemp))
        );
      } else {
        this.props.dispatch(change("profileInfoForm", "year", "year"));
        this.props.dispatch(change("profileInfoForm", "day", "day"));
        this.props.dispatch(change("profileInfoForm", "month", "month"));
      }
    }
    this.props.reset();
  }

  changePasswordClick() {
    const { t } = this.props;
    this.changePassword.checked = !this.changePassword.checked;
    if (this.changePassword.checked) {
      this.changePassword.text = t("cancel");
    } else {
      this.changePassword.text = t("changePassword");
    }
    this.setState({});
  }

  onChangeStreamingConsoles(checkbox) {
    const newStreamingConsoles = this.state.streamingConsoles;
    newStreamingConsoles[checkbox.target.id] =
      !this.state.streamingConsoles[checkbox.target.id];
    this.setState({
      streamingConsoles: newStreamingConsoles,
    });
  }

  render() {
    const { t, handleSubmit, profileInfoForm, daysValues } = this.props;
    const { initialValues: activeUser } = this.props;
    const hasTwitter =
      activeUser.connectedAccounts &&
      activeUser.connectedAccounts.twitter &&
      activeUser.connectedAccounts.twitter.id;
    let isValidDate = false;
    const recoveryLink =
      profileInfoForm &&
      profileInfoForm.initial &&
      profileInfoForm.initial.recoveryToken
        ? `${window.location.origin}/recover?recovery-token=${profileInfoForm.initial.recoveryToken}`
        : "";
    if (
      profileInfoForm &&
      profileInfoForm.values &&
      profileInfoForm.values.birthDate
    ) {
      isValidDate = moment(
        profileInfoForm.values.birthDate,
        dateStoreFormat,
        true
      ).isValid();
    }

    const streamingConsoleOptions = [
      { label: t("streamingConsoleOptions.0"), value: "pc" },
      { label: t("streamingConsoleOptions.1"), value: "xbox" },
      { label: t("streamingConsoleOptions.2"), value: "playstation" },
      { label: t("streamingConsoleOptions.3"), value: "nintendo" },
      { label: t("streamingConsoleOptions.4"), value: "mobile" },
    ];

    return (
      <form
        className="form-container Profile__info__form"
        autoComplete="off"
        onSubmit={handleSubmit(this.submit.bind(this))}
      >
        <div className="Profile__info__form__row">
          <div
            className="Profile__info__form__left"
            style={{ display: this.state.formEditable ? "block" : "none" }}
          >
            <Field
              type="text"
              name="firstName"
              id="firstName"
              placeholder={t("placeholder.firstName")}
              customLabel={<div>{t("firstName")}</div>}
              component={CustomInput}
            />
          </div>
          <div
            className="Profile__info__form__left"
            style={{ display: !this.state.formEditable ? "block" : "none" }}
          >
            <div className="input-container">
              <label htmlFor="firstName">
                <div>{t("firstName")}</div>
              </label>
              <div className="input-wrapper">
                {profileInfoForm &&
                  profileInfoForm.values &&
                  profileInfoForm.values.firstName && (
                    <input
                      type="text"
                      value={`${profileInfoForm.values.firstName}`}
                      className="touched ok not-empty"
                      disabled
                    />
                  )}
                {(!profileInfoForm ||
                  !profileInfoForm.values ||
                  !profileInfoForm.values.firstName) && (
                  <input
                    type="text"
                    value=""
                    className="touched ok not-empty"
                    disabled
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className="Profile__info__form__right"
            style={{ display: this.state.formEditable ? "block" : "none" }}
          >
            <Field
              type="text"
              name="lastName"
              id="lastName"
              placeholder={t("placeholder.lastName")}
              customLabel={<div>{t("lastName")}</div>}
              component={CustomInput}
            />
          </div>
          <div
            className="Profile__info__form__right"
            style={{ display: !this.state.formEditable ? "block" : "none" }}
          >
            <div className="input-container">
              <label htmlFor="lastName">
                <div>{t("lastName")}</div>
              </label>
              <div className="input-wrapper">
                {profileInfoForm &&
                  profileInfoForm.values &&
                  profileInfoForm.values.lastName && (
                    <input
                      type="text"
                      value={`${profileInfoForm.values.lastName}`}
                      className="touched ok not-empty"
                      disabled
                    />
                  )}
                {(!profileInfoForm ||
                  !profileInfoForm.values ||
                  !profileInfoForm.values.lastName) && (
                  <input
                    type="text"
                    value=""
                    className="touched ok not-empty"
                    disabled
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="Profile__info__form__row"
          style={{ marginBottom: "20px" }}
        >
          <div
            className="Profile__info__form__single"
            style={{ display: this.state.formEditable ? "block" : "none" }}
          >
            <Field
              type="text"
              name="username"
              id="username"
              placeholder={t("username")}
              customLabel={<div>{t("username")}</div>}
              component={CustomInput}
            />
          </div>
          <div
            className="Profile__info__form__single"
            style={{ display: !this.state.formEditable ? "block" : "none" }}
          >
            <div className="input-container">
              <label htmlFor="username">
                <div>{t("username")}</div>
              </label>
              <div className="input-wrapper">
                {profileInfoForm &&
                  profileInfoForm.values &&
                  profileInfoForm.values.username && (
                    <input
                      type="text"
                      value={`${profileInfoForm.values.username}`}
                      className="touched ok not-empty"
                      disabled
                    />
                  )}
                {(!profileInfoForm ||
                  !profileInfoForm.values ||
                  !profileInfoForm.values.username) && (
                  <input
                    type="text"
                    value=""
                    className="touched ok not-empty"
                    disabled
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div
            className="Profile__info__form__left"
            style={{ display: this.state.formEditable ? "block" : "none" }}
          >
            <Field
              type="text"
              name="email"
              id="email"
              placeholder="youremail@gmail.com"
              customLabel={<div>{t("email")}</div>}
              component={CustomInput}
            />
          </div>
          <div
            className="Profile__info__form__left"
            style={{ display: !this.state.formEditable ? "block" : "none" }}
          >
            <div className="input-container">
              <label htmlFor="email">
                <div>{t("email")}</div>
              </label>
              <div className="input-wrapper">
                {profileInfoForm &&
                  profileInfoForm.values &&
                  profileInfoForm.values.email && (
                    <input
                      type="text"
                      value={`${profileInfoForm.values.email}`}
                      className="touched ok not-empty"
                      disabled
                    />
                  )}
                {(!profileInfoForm ||
                  !profileInfoForm.values ||
                  !profileInfoForm.values.email) && (
                  <input
                    type="text"
                    value=""
                    className="touched ok not-empty"
                    disabled
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className="Profile__info__form__right"
            style={{ display: this.state.formEditable ? "block" : "none" }}
          >
            <Field
              type="text"
              name="paymentEmail"
              id="paymentEmail"
              placeholder="paypalemail@gmail.com"
              customLabel={<div>{t("payPalEmailAddress")}</div>}
              component={CustomInput}
            />
          </div>
          <div
            className="Profile__info__form__right"
            style={{ display: !this.state.formEditable ? "block" : "none" }}
          >
            <div className="input-container">
              <label htmlFor="paymentEmail">
                <div>{t("payPalEmailAddress")}</div>
              </label>
              <div className="input-wrapper">
                {profileInfoForm &&
                  profileInfoForm.values &&
                  profileInfoForm.values.paymentEmail && (
                    <input
                      type="text"
                      value={`${profileInfoForm.values.paymentEmail}`}
                      className="touched ok not-empty"
                      disabled
                    />
                  )}
                {(!profileInfoForm ||
                  !profileInfoForm.values ||
                  !profileInfoForm.values.paymentEmail) && (
                  <input
                    type="text"
                    value=""
                    className="touched ok not-empty"
                    disabled
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div
            className="Profile__info__form__left"
            style={{
              display:
                this.state.formEditable && !hasTwitter ? "block" : "none",
            }}
          >
            <Field
              type="text"
              name="twitter"
              id="twitter"
              placeholder={t("twitterPerson")}
              customLabel={<div>{t("twitter")}</div>}
              component={CustomInput}
            />
          </div>
          <div
            className="Profile__info__form__left"
            style={{
              display:
                !this.state.formEditable || hasTwitter ? "block" : "none",
            }}
          >
            <div className="input-container">
              <label htmlFor="twitter">
                <div>{t("twitter")}</div>
              </label>
              <div className="input-wrapper">
                {profileInfoForm &&
                  profileInfoForm.values &&
                  profileInfoForm.values.twitter && (
                    <input
                      type="text"
                      value={`${profileInfoForm.values.twitter}`}
                      className="touched ok not-empty"
                      disabled
                    />
                  )}
                {(!profileInfoForm ||
                  !profileInfoForm.values ||
                  !profileInfoForm.values.twitter) && (
                  <input
                    type="text"
                    value=""
                    className="touched ok not-empty"
                    disabled
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className="Profile__info__form__right"
            style={{ display: this.state.formEditable ? "block" : "none" }}
          >
            <Field
              type="text"
              name="discord"
              id="discord"
              placeholder={t("discord")}
              customLabel={<div>{t("discord")}</div>}
              component={CustomInput}
            />
          </div>
          <div
            className="Profile__info__form__right"
            style={{ display: !this.state.formEditable ? "block" : "none" }}
          >
            <div className="input-container">
              <label htmlFor="discord">
                <div>{t("discord")}</div>
              </label>
              <div className="input-wrapper">
                {profileInfoForm &&
                  profileInfoForm.values &&
                  profileInfoForm.values.discord && (
                    <input
                      type="text"
                      value={`${profileInfoForm.values.discord}`}
                      className="touched ok not-empty"
                      disabled
                    />
                  )}
                {(!profileInfoForm ||
                  !profileInfoForm.values ||
                  !profileInfoForm.values.discord) && (
                  <input
                    type="text"
                    value=""
                    className="touched ok not-empty"
                    disabled
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div
            className="Profile__info__form__left"
            style={{ display: this.state.formEditable ? "none" : "block" }}
          >
            <div className="input-container">
              <label htmlFor="birthDate">
                <div>{t("birthDate")}</div>
              </label>
              <div className="input-wrapper">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {profileInfoForm &&
                    profileInfoForm.values &&
                    profileInfoForm.values.birthDate &&
                    isValidDate && (
                      <>
                        <input
                          type="text"
                          value={`${profileInfoForm.values.month}`}
                          style={{ flexBasis: "44%" }}
                          disabled
                        />
                        <input
                          type="text"
                          value={`${profileInfoForm.values.day}`}
                          style={{ flexBasis: "23%" }}
                          disabled
                        />
                        <input
                          type="text"
                          value={`${profileInfoForm.values.year}`}
                          style={{ flexBasis: "30%" }}
                          disabled
                        />
                      </>
                    )}
                  {(!profileInfoForm ||
                    !profileInfoForm.values ||
                    !profileInfoForm.values.birthDate ||
                    !isValidDate) && (
                    <>
                      <input
                        type="text"
                        value=""
                        style={{ flexBasis: "44%" }}
                        disabled
                      />
                      <input
                        type="text"
                        value=""
                        style={{ flexBasis: "23%" }}
                        disabled
                      />
                      <input
                        type="text"
                        value=""
                        style={{ flexBasis: "30%" }}
                        disabled
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="Profile__info__form__left"
            style={{ display: this.state.formEditable ? "block" : "none" }}
          >
            <div className="input-container">
              <label htmlFor="birthDate">
                <div>{t("birthDate")}</div>
              </label>
              <div className="input-wrapper">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Field
                    type="text"
                    name="month"
                    id="month"
                    flexBasis="44%"
                    time="months"
                    component={CustomSelectBasic}
                  />
                  <Field
                    type="text"
                    name="day"
                    id="day"
                    flexBasis="23%"
                    time="days"
                    component={CustomSelectBasic}
                    options={daysValues}
                  />
                  <Field
                    type="text"
                    name="year"
                    id="year"
                    flexBasis="30%"
                    time="years"
                    component={CustomSelectBasic}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="Profile__info__form__right"
            style={{ display: this.state.formEditable ? "block" : "none" }}
          >
            <Field
              type="text"
              name="country"
              id="country"
              placeholder={t("country")}
              customLabel={<div>{t("country")}</div>}
              defaultOptionLabel={t("country")}
              component={CustomDropDown}
            />
          </div>
          <div
            className="Profile__info__form__right"
            style={{ display: !this.state.formEditable ? "block" : "none" }}
          >
            <div className="input-container">
              <label htmlFor="country">
                <div>{t("country")}</div>
              </label>
              <div className="input-wrapper">
                {profileInfoForm &&
                  profileInfoForm.values &&
                  profileInfoForm.values.country && (
                    <input
                      type="text"
                      value={`${profileInfoForm.values.country}`}
                      className="touched ok not-empty"
                      disabled
                    />
                  )}
                {(!profileInfoForm ||
                  !profileInfoForm.values ||
                  !profileInfoForm.values.country) && (
                  <input
                    type="text"
                    value=""
                    className="touched ok not-empty"
                    disabled
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div
            className="Profile__info__form__left"
            style={{ display: this.state.formEditable ? "block" : "none" }}
          >
            <Field
              name="gender"
              id="gender"
              placeholder={t("selectGender")}
              customLabel={<div>{t("gender")}</div>}
              component={CustomDropDownGeneric}
              options={["Male", "Female", "Other"]}
            />
          </div>
          <div
            className="Profile__info__form__left"
            style={{ display: !this.state.formEditable ? "block" : "none" }}
          >
            <div className="input-container">
              <label htmlFor="gender">
                <div>{t("gender")}</div>
              </label>
              <div className="input-wrapper">
                {profileInfoForm &&
                  profileInfoForm.values &&
                  profileInfoForm.values.gender && (
                    <input
                      type="text"
                      value={`${profileInfoForm.values.gender}`}
                      className="touched ok not-empty"
                      disabled
                    />
                  )}
                {(!profileInfoForm ||
                  !profileInfoForm.values ||
                  !profileInfoForm.values.gender) && (
                  <input
                    type="text"
                    value=""
                    className="touched ok not-empty"
                    disabled
                  />
                )}
              </div>
            </div>
          </div>
          <div className="Profile__info__form__right">
            <div className="input-container">
              <label htmlFor="password">
                <div>{t("password")}</div>
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  value="••••••••"
                  placeholder="••••••••"
                  className="touched ok not-empty"
                  disabled
                />
              </div>
            </div>
            <div
              className="Profile__info__change-password"
              style={{ display: this.state.formEditable ? "block" : "none" }}
              onClick={this.changePasswordClick.bind(this)}
              aria-hidden
            >
              {this.changePassword.text}
            </div>
          </div>
        </div>
        <div
          className={classNames("Profile__info__form__row", {
            mobile__hide: !this.changePassword.checked,
          })}
          style={{
            display: this.changePassword.checked ? "flex" : "none",
            opacity: this.changePassword.checked ? "1" : "0",
          }}
        >
          <div className="Profile__info__form__left">
            {/*
              <Field
                type="password"
                name="newPassword"
                id="newPassword"
                placeholder="••••••••"
                customLabel={(<div>{t("newPassword")}</div>)}
                component={CustomInput}
              />
            */}
            <Field
              type="password"
              name="oldPassword"
              id="oldPassword"
              placeholder="••••••••"
              customLabel={<div>{t("oldPassword")}</div>}
              component={CustomInput}
            />
          </div>
          <div className="Profile__info__form__right">
            <Field
              type="password"
              name="newPassword"
              id="newPassword"
              placeholder="••••••••"
              customLabel={<div>{t("newPassword")}</div>}
              component={CustomInput}
            />
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div className="Profile__info__form__single">
            <label style={{ marginBottom: "10px" }}>
              {t("streamingConsoles")}
            </label>
            {streamingConsoleOptions.map((platform) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <input
                  name={`${platform.value}`}
                  id={`${platform.value}`}
                  customLabel={`${platform.label}`}
                  type="checkbox"
                  style={{ width: "30px" }}
                  onChange={this.onChangeStreamingConsoles.bind(this)}
                  defaultChecked={
                    this.state.streamingConsoles[`${platform.value}`]
                  }
                  disabled={!this.state.formEditable}
                />
                <span className={this.state.formEditable ? "" : "disabled"}>
                  {platform.label}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="Profile__info__form__row" style={{ display: "none" }}>
          <div className="Profile__info__form__single">
            <div className="input-container">
              <label htmlFor="">
                <div>{t("passwordRecoveryLink")}</div>
              </label>
              <div
                className="input-wrapper"
                onClick={() => {
                  document.getElementById("password-recovery-link").select();
                  document.execCommand("copy");
                }}
              >
                <input
                  style={{ fontSize: "12px" }}
                  type="text"
                  value={recoveryLink}
                  id="password-recovery-link"
                  className="touched ok not-empty"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div
            className="Profile__info__form__last"
            style={{ display: this.state.formEditable ? "flex" : "none" }}
          >
            <div
              className="Profile__info__form__cancel cta2__button"
              onClick={this.handleCancel.bind(this)}
              aria-hidden
            >
              {t("cancel")}
            </div>
            <button className="Profile__info__form__save cta__button">
              {t("save")}
            </button>
          </div>
          <div
            className="Profile__info__form__last"
            style={{ display: !this.state.formEditable ? "flex" : "none" }}
          >
            <div
              className="Profile__info__form__cancel cta__button"
              onClick={this.handleEdit.bind(this)}
              aria-hidden
            >
              {t("edit")}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.firstName || values.firstName.trim() === "") {
    errors.firstName = t("validate.fieldRequired");
  }
  if (!values.lastName || values.lastName.trim() === "") {
    errors.lastName = t("validate.fieldRequired");
  }

  if (!values.username || values.username.trim() === "") {
    errors.username = t("validate.fieldRequired");
  } else if (!values.username.match("^[a-zA-Z0-9_]*$")) {
    errors.username = t("validate.usernameRule");
  } else if (values.username.length > 25) {
    errors.username = t("validate.usernameLength");
  }

  if (!values.email || values.email.trim() === "") {
    errors.email = t("validate.fieldRequired");
  }
  if (!values.paymentEmail || values.paymentEmail.trim() === "") {
    errors.paymentEmail = t("validate.fieldRequired");
  }
  if (!values.day || values.day.trim() === "" || values.day === "day") {
    errors.day = t("validate.required");
  }
  if (!values.month || values.month.trim() === "" || values.month === "month") {
    errors.month = t("validate.required");
  }
  if (!values.year || values.year.trim() === "" || values.year === "year") {
    errors.year = t("validate.required");
  }
  if (!values.country || values.country.trim() === "") {
    errors.country = t("validate.fieldRequired");
  }
  // if (values.twitter && !validateTwitterHandle(values.twitter)) {
  //   errors.twitter = 'Username missing "@"';
  // }

  // if (!values.oldPassword || values.oldPassword.trim() === '') {
  //   errors.oldPassword = 'This field is required';
  // }

  const checkSpecial = /[*@!#%&()^~{}$]+/.test(values.newPassword);
  const checkUpper = /[A-Z]+/.test(values.newPassword);
  const checkLower = /[a-z]+/.test(values.newPassword);

  if (values.newPassword) {
    if (values.newPassword.length < 8) {
      errors.newPassword = t("validate.passwordAtLeast_8");
    } else if (values.newPassword.length > 30) {
      errors.newPassword = t("validate.passwordLessThan_30");
    } else if (!checkSpecial || !checkLower || !checkUpper) {
      errors.newPassword = t("validate.passwordRule");
    }
  }

  return errors;
};

ProfileInfoForm.propTypes = {};

export default withTranslation(["profileInfoForm", "forms"])(
  reduxForm({
    form: "profileInfoForm",
    initialValues: { check: false },
    validate,
  })(ProfileInfoForm)
);
