import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import './BrandServiceSlides.scss';
import dataVis from '../assetsV2/diagonal.png';
import anime from 'animejs';
import { useTranslation } from 'react-i18next';

// icons
import controller from "../assetsV2/iconography/game-icon.svg";
import scale from "../assetsV2/iconography/scalability-icon.svg";
import location from "../assetsV2/iconography/region-icon.svg";
import clock from "../assetsV2/iconography/always-on-icon.svg";
import payments from "../assetsV2/iconography/payments-icon.svg";
import shield from "../assetsV2/iconography/safety-icon.svg";

const InfluencerMarketing = (props) => {
  const { t } = useTranslation("brandServices");
  const {
    pOffset, layoutFactor = 1, offsetAdjust = 0,
  } = props;
  const ref = React.createRef();
  const onScreen = useOnScreen(ref);

  const [debounce, setDebounce] = React.useState(false);

  useEffect(() => {
    if (!debounce && onScreen) {
      anime({
        targets: '.influencer-fade',
        opacity: [1, 0],
        translateY: 100,
        duration: 800,
        direction: 'reverse',
        easing: 'linear',
        delay: anime.stagger(60, { direction: 'reverse' }),
      });

      setDebounce(true);
    }
  }, [onScreen, debounce]);
  return (
    <>
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        className="background-i"
        speed={0}
        style={{
          //  zIndex: 0, background: `url("${dataVis}")`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat !important',
          zIndex: 0,
          background: `url("${dataVis}")`,
        }}
      />
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        speed={0}
        className="slide-i-container"
      >
        <div ref={ref} />
        <div className="influencer-fade brand-i-title cascade-fade-i ">
          {t("marketingSolutions.title")}
        </div>

        <div className="brand-i-grid">
          <div className="brand-text-stack">
            <div className="influencer-fade row-flex cascade-fade-i">
              <div className="image-round">
                <img
                  src={controller}
                  alt={t("marketingSolutions.module_1.title")}
                />
              </div>
              <div className="text-flex">
                <div className="title">
                  {t("marketingSolutions.module_1.title")}
                </div>
                <div className="body">
                  {t("marketingSolutions.module_1.describe")}
                </div>
              </div>
            </div>
          </div>
          <div className="brand-text-stack">
            <div className="influencer-fade row-flex cascade-fade-i">
              <div className="image-round">
                <img src={scale} alt={t("marketingSolutions.module_2.title")} />
              </div>
              <div className="text-flex">
                <div className="title">
                  {t("marketingSolutions.module_2.title")}
                </div>
                <div className="body">
                  {t("marketingSolutions.module_2.describe")}
                </div>
              </div>
            </div>
          </div>
          <div className="brand-text-stack">
            <div className="influencer-fade row-flex cascade-fade-i">
              <div className="image-round">
                <img
                  src={location}
                  alt={t("marketingSolutions.module_3.title")}
                />
              </div>
              <div className="text-flex">
                <div className="title">
                  {t("marketingSolutions.module_3.title")}
                </div>
                <div className="body">
                  {t("marketingSolutions.module_3.describe")}
                </div>
              </div>
            </div>
          </div>
          <div className="brand-text-stack">
            <div className="influencer-fade row-flex cascade-fade-i">
              <div className="image-round">
                <img src={clock} alt={t("marketingSolutions.module_4.title")} />
              </div>
              <div className="text-flex">
                <div className="title">
                  {t("marketingSolutions.module_4.title")}
                </div>
                <div className="body">
                  {t("marketingSolutions.module_4.describe")}
                </div>
              </div>
            </div>
          </div>
          <div className="brand-text-stack">
            <div className="influencer-fade row-flex cascade-fade-i">
              <div className="image-round">
                <img
                  src={payments}
                  alt={t("marketingSolutions.module_5.title")}
                />
              </div>
              <div className="text-flex">
                <div className="title">
                  {t("marketingSolutions.module_5.title")}
                </div>
                <div className="body">
                  {t("marketingSolutions.module_5.describe")}
                </div>
              </div>
            </div>
          </div>
          <div className="brand-text-stack">
            <div className="influencer-fade row-flex cascade-fade-i">
              <div className="image-round">
                <img
                  src={shield}
                  alt={t("marketingSolutions.module_6.title")}
                />
              </div>
              <div className="text-flex">
                <div className="title">
                  {t("marketingSolutions.module_6.title")}
                </div>
                <div className="body">
                  {t("marketingSolutions.module_6.describe")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ParallaxLayer>
    </>
  );
};


export default InfluencerMarketing;

