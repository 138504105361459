/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router";
import { without, find, filter, orderBy, sumBy, findIndex } from "lodash";
import classNames from "classnames";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import { simpleNotification } from "../../../../utils/notifications";
import {
  rounding,
  getReferralBonus,
  getTwitchAverageViewers,
  viewerTiersWithSpacesLeft,
  getUserQuestStatus,
  getQuestRequirements,
  getNumApprovedRequirements,
} from "../../../../utils/functions";
import "./QuestTiered.scss";
import EditModal from "../QuestTimeEditModal/EditModal";
import EditTier from "../edit-tier/EditTier";
import QuestDetailsTable from "./QuestDetailsTable";
import QuestRequirementsTable from "./QuestRequirementsTable";

// Review Modals
import BrandSafetyModal from "../reputation-system/BrandSafetyModal";
import QualityModal from "../reputation-system/QualityModal";
import CongenialityModal from "../reputation-system/CongenialityModal";
import { paymentVendorProcessingFee } from "../../../../utils/functions";
import { withTranslation } from "react-i18next";

class QuestTiered extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const { questType } = props.params;

    this.state = {
      tab: questType === "general" ? 2 : 1,
      showingElements: [],
      reviewRequirementSort: {
        column: "noiz",
      },
      /* Reputation system */
      reputationRatings: [],
      brandSafetyModalOpen: false,
      qualityModalOpen: false,
      congenialityModalOpen: false,
      isEditingReputation: false,
      // Current user/modal values
      modalUser: false,
      questReqId: false,
      quality: {
        comments: "",
        rating: {
          value: 0,
          label: "",
        },
      },
      vodRatings: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    if (questId) {
      this.props.getCCQuestTiered(questId, token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    const { isLoadingCurrent } = this.props.commandCenter.questTiered;
    const { isLoadingNext } = nextProps.commandCenter.questTiered;
    if (isLoadingCurrent === true && isLoadingNext === false) {
      const { data: quest } = this.props.commandCenter.quest;
      const { twitchAccountRequired, youtubeAccountRequired } = quest;
      let platform;
      if (twitchAccountRequired) {
        platform = "twitch";
      } else if (youtubeAccountRequired) {
        platform = "youtube";
      } else {
        platform = "twitch";
      }
      this.setState({
        platform,
      });
    }

    if (
      this.props.commandCenter.activeUserQuests.isLoadingSpecial === true &&
      nextProps.commandCenter.activeUserQuests.isLoadingSpecial === false
    ) {
      if (nextProps.commandCenter.activeUserQuests.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("error"),
          message: nextProps.commandCenter.activeUserQuests.errors.message,
        });
      } else {
        const { token } = this.props.auth;
        const { questId } = this.props.router.params;
        this.props.getCCQuestTiered(questId, token);
      }
    }

    /* Reputation system */
    // Open modals on response
    const { data: reputationSystem } = nextProps.commandCenter.reputationSystem;
    if (reputationSystem && reputationSystem.modalData) {
      if (reputationSystem.modalData.vodApproved) {
        this.setState({
          qualityModalOpen: true,
          modalUser: reputationSystem.modalData.user,
          questReqId: reputationSystem.modalData.questReqId,
        });
      }

      if (reputationSystem.modalData.requirementsCompleted) {
        this.setState({
          congenialityModalOpen: true,
          modalUser: reputationSystem.modalData.user,
        });
      }
    }

    // Save updated ratings to state
    if (
      nextProps.commandCenter.questTiered &&
      nextProps.commandCenter.questTiered.reputationRatings
    ) {
      this.setState({
        reputationRatings:
          nextProps.commandCenter.questTiered.reputationRatings,
      });
    }

    // Success/error from reputation submission
    if (
      this.props.commandCenter.reputationSystem.isLoading &&
      !nextProps.commandCenter.reputationSystem.isLoading
    ) {
      if (nextProps.commandCenter.reputationSystem.errors.hasError) {
        simpleNotification({
          level: "error",
          title: t("error"),
          message: nextProps.commandCenter.reputationSystem.errors.message,
        });
      } else {
        simpleNotification({
          level: "success",
          title: t("reputationInfoUpdated"),
          message: t("responseSaved"),
        });
      }
    }
  }

  reviewRequirementSortChange = (column) => {
    const { reviewRequirementSort } = this.state;
    if (column === reviewRequirementSort.column) {
      if (reviewRequirementSort.direction === "asc") {
        this.setState({
          reviewRequirementSort: {
            ...this.state.reviewRequirementSort,
            direction: "desc",
          },
        });
      } else {
        this.setState({
          reviewRequirementSort: {
            ...this.state.reviewRequirementSort,
            direction: "asc",
          },
        });
      }
    } else {
      this.setState({
        reviewRequirementSort: {
          ...this.state.reviewRequirementSort,
          column,
          direction: "asc",
        },
      });
    }
  };

  rounding = (number) => parseFloat(number).toFixed(2);

  padWithZeroes = (number, length) => {
    let myString = number.toString();
    while (myString.length < length) {
      myString = `0${myString}`;
    }
    return myString;
  };

  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [...showingElements, elementId],
      });
    } else {
      this.setState({
        showingElements: [...without(showingElements, elementId)],
      });
    }
  };

  generateLinkRowsHTML = (questTier, quest) => {
    const { showingElements } = this.state;
    const { twitchAccountRequired, youtubeAccountRequired } = quest;
    const { t } = this.props;
    let platform;
    if (twitchAccountRequired) {
      platform = "twitch";
    } else if (youtubeAccountRequired) {
      platform = "youtube";
    } else {
      platform = "twitch";
    }

    if (questTier.length > 0) {
      return questTier.map((item, index) => {
        let pre = "";
        if (item && item.link && !item.link.toLowerCase().startsWith("http")) {
          pre = "http://";
        }
        return (
          <div
            key={questTier.username}
            className={classNames("row toggable tooltip", {
              open: showingElements.indexOf(item.username) !== -1,
            })}
          >
            <div className="row-basic">
              <div className="table-cell">{index + 1}</div>
              <div className="table-cell">{item.username}</div>
              <div className="table-cell">
                {platform === "twitch" && (
                  <a
                    href={`https://www.twitch.tv/${item.twitchUsername}/videos?filter=archives&sort=time`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.twitchUsername}
                  </a>
                )}
              </div>
              {item.link && (
                <div className="table-cell">
                  <Link
                    href={`${pre}${item.link.toLowerCase()}`}
                    target="_blank"
                  >
                    {item.link}
                  </Link>
                </div>
              )}
              {!item.link && <div className="table-cell" />}
            </div>
          </div>
        );
      });
    }
    return <div className="NoQuestTiered">{t("noQuestTiered")}</div>;
  };

  generateRowsReferralHTML = (questSale, quest) => {
    const { showingElements } = this.state;
    const { t } = this.props;
    let platform;
    const { twitchAccountRequired, youtubeAccountRequired } = quest;
    if (twitchAccountRequired) {
      platform = "twitch";
    } else if (youtubeAccountRequired) {
      platform = "youtube";
    } else {
      platform = "twitch";
    }

    if (questSale.length > 0) {
      return questSale.map((questSaleItem, index) => {
        let saleKey;
        if (platform === "twitch") {
          saleKey = questSale.twitchUsername;
        } else {
          saleKey = questSale.twitchUsername;
        }

        return (
          <div
            key={saleKey}
            className={classNames("row toggable", {
              open:
                showingElements.indexOf(questSaleItem.twitchUsername) !== -1,
            })}
          >
            <div className="row-basic">
              <div className="table-cell">{index + 1}</div>
              <div className="table-cell">{questSaleItem.twitchUsername}</div>
              <div className="table-cell">{questSaleItem.username}</div>
              <div className="table-cell">
                {questSaleItem.numberOfReferrals}
              </div>
              <div className="table-cell">
                ${this.rounding(questSaleItem.totalSelled / 100)}
              </div>
              <div className="table-cell">
                ${this.rounding(getReferralBonus(questSaleItem, quest))}
              </div>
              <div className="table-cell">
                ${this.rounding(questSaleItem.totalEarnings / 100)}
              </div>
              <div className="table-cell">
                {quest.twitchAccountRequired &&
                  showingElements.indexOf(questSaleItem.twitchUsername) ===
                    -1 &&
                  questSaleItem.numberOfReferrals > 0 && (
                    <button
                      className="small-btn"
                      onClick={this.toggleElementInfo.bind(
                        this,
                        questSaleItem.twitchUsername
                      )}
                    >
                      {t("showDetails")}
                    </button>
                  )}
                {quest.twitchAccountRequired &&
                  showingElements.indexOf(questSaleItem.twitchUsername) !==
                    -1 && (
                    <button
                      className="small-btn open"
                      onClick={this.toggleElementInfo.bind(
                        this,
                        questSaleItem.twitchUsername
                      )}
                    >
                      {t("hideDetails")}
                    </button>
                  )}
              </div>
            </div>
            <div className="row-details">
              <section className="StreamActivityTable flex-table">
                <div className="row">
                  <div className="table-cell headline">{t("date")}</div>
                  <div className="table-cell headline">{t("status")}</div>
                  <div className="table-cell headline">{t("earning")}</div>
                  <div className="table-cell headline">{t("price")}</div>
                </div>
                {this.generateSalesRowsHTML(questSaleItem.purchases)}
              </section>
            </div>
          </div>
        );
      });
    }
    return <div className="NoQuestSale">{t("noQuestSale")}</div>;
  };

  generateSalesRowsHTML = (saleActivity) => {
    const { t } = this.props;
    if (saleActivity.length > 0) {
      return saleActivity.map((saleItem) => (
        <div key={`-${saleItem.name}`} className="row">
          <div className="table-cell">{`${saleItem.month}/${
            saleItem.dayOfMonth
          }/${saleItem.year} ${this.padWithZeroes(
            saleItem.hour,
            2
          )}:${this.padWithZeroes(saleItem.minute, 2)}`}</div>
          <div className="table-cell">{t("success")}</div>
          <div className="table-cell">
            ${this.rounding(saleItem.earning / 100)}
          </div>
          <div className="table-cell">
            ${this.rounding(saleItem.price / 100)}
          </div>
        </div>
      ));
    }
    return <div className="NoSale">{t("noSale")}</div>;
  };

  changeTab = (value) => {
    this.setState({
      tab: value,
    });
  };

  getEditTierModal = (quest, users, user) => (
    <EditTier
      {...this.props}
      memberTiers={quest.memberTiers}
      viewerTiers={viewerTiersWithSpacesLeft(quest, users)}
      averageViewersAtJoin={user.averageViewersAtJoin}
      user={user}
      rehydrateCallback={this.props.getCCQuestTiered}
      locMod=""
    />
  );

  getEditUserModal = (user) => (
    <EditModal
      {...this.props}
      currentUser={user}
      rehydrateCallback={this.props.getCCQuestTiered}
    />
  );

  // Submit vod data and close modal
  handleBrandSafetySubmit = (comments, selected) => {
    // Create update object
    const brandSafety = { comments, selected };
    const { modalUser, questReqId, vodRatings } = this.state;

    let vodRatingObj = vodRatings.length
      ? vodRatings.filter((v) => v.questReqId === questReqId)
      : {};
    if (vodRatingObj.length > 0) {
      vodRatingObj = vodRatingObj[0];
    }

    const repObj = {
      vodRating: {
        brandSafety,
        quality: vodRatingObj.quality ? vodRatingObj.quality : {},
        questReqId,
      },
    };

    const { token } = this.props.auth;
    const { questId } = this.props.router.params;

    this.props.postReputationRating(
      questId,
      modalUser.id,
      JSON.stringify(repObj),
      token
    );

    // Update reputation in state
    const { reputationRatings } = this.state;
    const repIndex = findIndex(reputationRatings, { user: modalUser.id });
    if (repIndex > -1) {
      reputationRatings[repIndex] = repObj.vodRating;
    } else {
      reputationRatings.push(repObj.vodRating);
    }

    this.setState({
      reputationRatings,
    });

    // Close modal
    this.setState({
      brandSafetyModalOpen: false,
    });
  };

  // Save quality response to state and open brand safety modal
  handleQualityModalChange = (comments, rating) => {
    const quality = { comments, rating };
    const { questReqId, vodRatings } = this.state;

    let vodRatingObj = vodRatings.length
      ? vodRatings.filter((v) => v.questReqId === questReqId)
      : [];
    if (vodRatingObj.length > 0) vodRatingObj = vodRatingObj[0];

    vodRatingObj.quality = quality;
    vodRatingObj.questReqId = questReqId;

    // Brand safety modal opens after quality modal
    this.setState({
      vodRatings: [...vodRatings, vodRatingObj],
      qualityModalOpen: false,
      brandSafetyModalOpen: true,
    });
  };

  // Submit congeniality and close modals
  handleCongenialityModalSubmit = (comments, rating) => {
    // Create update object
    const repObj = {
      congeniality: {
        comments,
        rating,
      },
    };

    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    const { modalUser } = this.state;

    this.props.postReputationRating(
      questId,
      modalUser.id,
      JSON.stringify(repObj),
      token
    );

    // Update reputation in state
    const { reputationRatings } = this.state;
    const repIndex = findIndex(reputationRatings, { user: modalUser.id });
    if (repIndex > -1) {
      reputationRatings[repIndex].congeniality = repObj.congeniality;
    } else {
      reputationRatings.push(repObj.congeniality);
    }

    this.setState({
      reputationRatings,
    });

    // All modals close after congeniality modal
    this.resetReputationModals();
  };

  // Set current user's reputation data in state for response/editing
  setUserData = (userId) => {
    const { data: questTieredAll, reputationRatings } =
      this.props.commandCenter.questTiered;

    const user = questTieredAll.filter((u) => u.id === userId);
    const reputation = reputationRatings.filter((r) => r.user === userId);

    if (user.length) {
      this.setState({
        modalUser: user[0],
      });

      if (reputation.length) {
        const { congeniality, vodRatings } = reputation[0];
        this.setState({
          isEditingReputation: true,
          congeniality,
          vodRatings,
        });
      } else {
        this.setState({ isEditingReputation: false });
      }
    } else {
      this.setState({ isEditingReputation: false });
    }
  };

  openCongenialityModal = (userId) => {
    this.setUserData(userId);
    this.setState({
      congenialityModalOpen: true,
    });
  };

  openVODModals = (userId, questReqId) => {
    this.setUserData(userId);
    this.setState({
      qualityModalOpen: true,
      questReqId,
    });
  };

  // Close all modals and set responses to default empty
  resetReputationModals = () => {
    this.setState({
      qualityModalOpen: false,
      brandSafetyModalOpen: false,
      congenialityModalOpen: false,
      quality: {
        comments: "",
        rating: {
          value: 0,
          label: "",
        },
      },
      vodRatings: [],
    });
  };

  render() {
    const { questType } = this.props.params;
    const { gameId } = this.props.router.params;
    const { data: userQuests } = this.props.commandCenter.userQuests;
    const { data: quest } = this.props.commandCenter.quest;
    const {
      data: questTieredAll,
      dataReferral: questSales,
      dataKeysAvailables: keysAvailables,
      questSummary,
      isLoading,
    } = this.props.commandCenter.questTiered;
    const {
      showingElements,
      brandSafetyModalOpen,
      qualityModalOpen,
      congenialityModalOpen,
      modalUser,
      vodRatings,
      congeniality,
      questReqId,
      isEditingReputation,
      reputationRatings,
    } = this.state;

    const { t } = this.props;

    let peakViewersWithoutManual = 0;
    if (questSummary && questSummary.peakViewersWithoutManual) {
      peakViewersWithoutManual = questSummary.peakViewersWithoutManual;
    }

    // Filter down to approved users only
    const questTiered = questTieredAll.filter(
      (qt) => qt.status !== "leftQuest"
    );

    let vodRating =
      vodRatings && questReqId
        ? vodRatings.filter((r) => r.questReqId === questReqId)
        : { brandSafety: "", quality: "" };
    if (vodRating.length) vodRating = vodRating[0];

    const { twitchAccountRequired, youtubeAccountRequired } = quest;
    let platform;
    if (twitchAccountRequired) {
      platform = "twitch";
    } else if (youtubeAccountRequired) {
      platform = "youtube";
    } else {
      platform = "twitch";
    }

    const soldKeysReferral = sumBy(questSales, "numberOfReferrals");

    let totalPayableReferral = sumBy(questSales, "totalEarnings");
    if (quest && questSales.length > 0) {
      for (let i = 0; i < questSales.length; i += 1) {
        totalPayableReferral += getReferralBonus(questSales[i], quest) * 100;
      }
    }

    const headersReferralCSV = [
      { label: t("questName"), key: "questName" },
      { label: t("noizUsername"), key: "noizUsername" },
      ...(platform === "twitch"
        ? [{ label: t("twitchUsername"), key: "twitchUsername" }]
        : []),
      { label: t("paymentEmail"), key: "paymentEmail" },
      { label: t("numberSold"), key: "numberSold" },
      { label: t("totalSold"), key: "totalSold" },
      { label: t("earnings"), key: "earnings" },
      { label: t("processingFee"), key: "processingFee" },
      { label: t("toPay"), key: "toPay" },
      { label: t("expectedPayoutDate"), key: "expectedPayoutDate" },
    ];

    const dataReferralCSV = [];

    for (let i = 0; i < questSales.length; i += 1) {
      dataReferralCSV.push({
        questName: quest.title,
        noizUsername: questSales[i].user.username,
        ...(platform === "twitch"
          ? {
              twitchUsername:
                questSales[i].user.connectedAccounts.twitch.username,
            }
          : {}),
        paymentEmail: questSales[i].user.paymentEmail,
        numberSold: questSales[i].numberOfReferrals,
        totalSold: `${this.rounding(questSales[i].totalSelled / 100)}`,
        earnings: `${this.rounding(questSales[i].totalEarnings / 100)}`,
        processingFee: `${this.rounding(
          paymentVendorProcessingFee(questSales[i].totalEarnings) / 100
        )}`,
        toPay: `${this.rounding(
          (questSales[i].totalEarnings -
            paymentVendorProcessingFee(questSales[i].totalEarnings)) /
            100
        )}`,
        expectedPayoutDate:
          quest && quest.expectedPayoutDate
            ? moment(quest.expectedPayoutDate, "YYYY-MM-DD")
                .format("MMMM DD YYYY")
                .toString()
            : "",
      });
    }

    let totalMinutesStreamed = 0;
    let totalHoursStreamed = 0;
    let peakCCV = 0;
    let totalSuccesful = 0;
    let totalFails = 0;
    let percentageCompletedQuest = 0;
    let totalEarning = 0;
    let totalProcessingFee = 0;
    let totalToPay = 0;
    let totalMinutesWatched = 0;
    let projectedMinutesWatched = 0;

    const headersCSV = [
      { label: t("questName"), key: "questName" },
      { label: t("noizUsername"), key: "noizUsername" },
      ...(platform === "twitch"
        ? [{ label: t("twitchUsername"), key: "twitchUsername" }]
        : []),
      { label: t("siteAverageViewers"), key: "siteAverageViewers" },
      { label: t("accountEmail"), key: "accountEmail" },
      { label: t("paymentEmail"), key: "paymentEmail" },
      { label: t("country"), key: "country" },
      { label: t("questAverageViewers"), key: "questAverageViewers" },
      { label: t("peakViewers"), key: "peakViewers" },
      { label: t("hoursWatched"), key: "hoursWatched" },
      { label: t("timePlaying"), key: "timePlaying" },
      { label: t("completed"), key: "completed" },
      { label: t("earnings"), key: "earnings" },
      { label: t("processingFee"), key: "processingFee" },
      { label: t("toPay"), key: "toPay" },
      { label: t("gameKey"), key: "gameKey" },
      { label: t("tier"), key: "tier" },
      { label: t("expectedPayoutDate"), key: "expectedPayoutDate" },
      { label: t("link"), key: "link" },
      { label: t("endDateTime"), key: "endDateTime" },
      { label: t("dateAccepted"), key: "dateAccepted" },
    ];

    const today = moment().tz("America/Los_Angeles");
    const hoursOffset = -today.utcOffset() / 60;
    const dataCSV = [];

    const timeIsUp = moment(quest.endDateTime)
      .add(hoursOffset, "hours")
      .tz("America/Los_Angeles");
    const questEnded =
      moment.tz("America/Los_Angeles").toDate() > timeIsUp.toDate();

    for (let i = 0; i < questTiered.length; i += 1) {
      const userQuest = userQuests.filter(
        (uq) => uq.user.id === questTiered[i].id
      );
      const completed =
        getUserQuestStatus(quest, userQuest[0], questTiered[i].timePlaying) ===
        "Completed";

      let siteAverageViewers = 0;
      if (platform === "twitch") {
        siteAverageViewers = getTwitchAverageViewers(questTiered[i].user);
      }

      const averageViewersAtJoin = questTiered[i].averageViewersAtJoin
        ? Math.round(questTiered[i].averageViewersAtJoin)
        : 0;

      let acceptedDate = null;
      if (questTiered[i].dateAccepted) {
        acceptedDate = questTiered[i].dateAccepted;
      } else {
        acceptedDate = questTiered[i].dateJoined;
      }

      dataCSV.push({
        questName: quest.title,
        noizUsername: questTiered[i].username,
        ...(platform === "twitch"
          ? { twitchUsername: questTiered[i].twitchUsername }
          : {}),
        // twitchUsername: questTiered[i].twitchUsername,
        accountEmail: questTiered[i].user ? questTiered[i].user.email : "- - -",
        paymentEmail: questTiered[i].user
          ? questTiered[i].user.paymentEmail
          : "- - -",
        country: questTiered[i].user.country,
        siteAverageViewers,
        questAverageViewers: questTiered[i].averageViewers
          ? parseInt(questTiered[i].averageViewers, 10)
          : "0",
        peakViewers: questTiered[i].peakViewers
          ? parseInt(questTiered[i].peakViewers, 10)
          : "0",
        hoursWatched:
          questTiered[i].averageViewers && questTiered[i].timePlaying
            ? parseFloat(
                (questTiered[i].averageViewers * questTiered[i].timePlaying) /
                  60
              ).toFixed(2)
            : "0",
        timePlaying: questTiered[i].timePlaying,
        completed: completed ? "completed" : " - - -",
        earnings:
          questTiered[i].timePlaying >=
            parseInt(quest.requiredStreamTimeMinutes, 10) &&
          questTiered[i].paymentAmount > 0
            ? rounding(questTiered[i].paymentAmount)
            : "0",
        processingFee:
          questTiered[i].timePlaying >=
            parseInt(quest.requiredStreamTimeMinutes, 10) &&
          questTiered[i].paymentAmount > 0
            ? rounding(paymentVendorProcessingFee(questTiered[i].paymentAmount))
            : "0",
        toPay:
          questTiered[i].timePlaying >=
            parseInt(quest.requiredStreamTimeMinutes, 10) &&
          questTiered[i].paymentAmount > 0
            ? rounding(
                questTiered[i].paymentAmount -
                  paymentVendorProcessingFee(questTiered[i].paymentAmount)
              )
            : "0",
        gameKey: questTiered[i].key,
        tier:
          questTiered[i].tier === "viewer"
            ? `${questTiered[i].tier} (${averageViewersAtJoin} viewers)`
            : questTiered[i].tier,
        expectedPayoutDate:
          quest && quest.expectedPayoutDate
            ? moment(quest.expectedPayoutDate, "YYYY-MM-DD")
                .format("MMMM DD, YYYY")
                .toString()
            : "",
        link: questTiered[i].link ? questTiered[i].link : "",
        endDateTime: questTiered[i].endDateTime
          ? moment(questTiered[i].endDateTime)
              .tz("America/Los_Angeles")
              .format("MMMM DD, YYYY -  - hh:mm a")
              .toString()
          : "",
        dateAccepted: acceptedDate
          ? moment(acceptedDate)
              .tz("America/Los_Angeles")
              .format("MMMM DD, YYYY -  - hh:mm a")
              .toString()
          : "",
      });

      totalMinutesStreamed += questTiered[i].timePlaying;
      totalMinutesWatched += parseFloat(
        parseFloat(
          questTiered[i].averageViewers * questTiered[i].timePlaying
        ).toFixed(2)
      );
      projectedMinutesWatched +=
        (questTiered[i].averageViewersAtJoin || 0) *
        (quest.requiredStreamTimeMinutes || 0);

      if (
        questTiered[i].peakViewers &&
        parseInt(questTiered[i].peakViewers, 10) > peakCCV
      ) {
        peakCCV = parseInt(questTiered[i].peakViewers, 10);
      }

      if (completed) {
        totalEarning += questTiered[i].paymentAmount;
        totalProcessingFee += paymentVendorProcessingFee(
          questTiered[i].paymentAmount
        );
        totalToPay +=
          questTiered[i].paymentAmount -
          paymentVendorProcessingFee(questTiered[i].paymentAmount);
        totalSuccesful += 1;
      } else {
        totalFails = questEnded ? totalFails + 1 : totalFails;
      }
    }

    totalHoursStreamed = rounding(totalMinutesStreamed / 60);
    percentageCompletedQuest =
      totalSuccesful === 0
        ? "0"
        : rounding((totalSuccesful / questTiered.length) * 100);
    totalEarning = rounding(totalEarning);
    totalProcessingFee = rounding(totalProcessingFee);
    totalToPay = rounding(totalToPay);
    projectedMinutesWatched = Math.round(projectedMinutesWatched);

    const memberTiersOpenSpots = [];
    const viewerTiersSpotsOccupied = [];

    if (quest.memberTiers && quest.memberTiers.length) {
      for (let i = 0; i < quest.memberTiers.length; i += 1) {
        let found;
        if (platform === "twitch") {
          found = find(
            questTiered,
            (o) =>
              o.tier === "member" &&
              o.twitchUsername === quest.memberTiers[i].identity
          );
        } else {
          found = find(
            questTiered,
            (o) =>
              o.tier === "member" &&
              o.twitchUsername === quest.memberTiers[i].identity
          );
        }

        if (found) {
          memberTiersOpenSpots.push(0);
        } else {
          memberTiersOpenSpots.push(1);
        }
      }
    }

    if (quest.viewerTiers && quest.viewerTiers.length) {
      for (let i = 0; i < quest.viewerTiers.length; i += 1) {
        const found = filter(
          questTiered,
          (o) =>
            o.tier === "viewer" &&
            o.paymentAmount === quest.viewerTiers[i].paymentAmount
        );
        viewerTiersSpotsOccupied.push(found.length);
      }
    }

    if (isLoading) {
      return (
        <section className="CCQuestTiered loading">
          <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
        </section>
      );
    }

    let totalPayable = 0;
    let totalPossible = 0;
    let totalReserved = 0;

    if (quest && questTiered && questTiered.length > 0) {
      const requirements = getQuestRequirements(quest, true);
      const numReqs = requirements ? requirements.length : 0;

      questTiered.forEach((item) => {
        const userQuest = userQuests.filter((uq) => uq.user.id === item.id);

        if (item.paymentAmount > 0) {
          totalReserved += item.paymentAmount;

          if (
            item.timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10)
          ) {
            totalPossible += item.paymentAmount;

            if (userQuest[0] && userQuest[0].requirements) {
              const numCompletedReqs = getNumApprovedRequirements(
                quest,
                userQuest[0]
              );
              if (numCompletedReqs >= numReqs) {
                totalPayable += item.paymentAmount;
              }
            }
          }
        }
      });
    }

    const containsLinks = filter(questTiered, (o) => o.link).length;

    let totalRequirementsNotCompleted = 0;

    const totalToApprove = [];
    const totalToReject = [];

    const approvalRequired = true;
    const questRequirements = getQuestRequirements(quest, approvalRequired);

    if (userQuests && userQuests.length > 0) {
      userQuests.forEach((userQuest) => {
        if (
          userQuest &&
          (userQuest.status === "approved" || userQuest.status === "normal") &&
          questRequirements &&
          questRequirements.length > 0
        ) {
          const userQuestRequirements = userQuest.requirements || [];

          const pendingRequirements = [];

          questRequirements.forEach(({ id: questReqId }) => {
            const foundPendingReq = userQuestRequirements.find(
              (r) => r.questReqId === questReqId && r.status === "to-review"
            );
            if (foundPendingReq) {
              pendingRequirements.push(foundPendingReq);
            }
          });

          if (pendingRequirements.length) {
            totalRequirementsNotCompleted += pendingRequirements.length;

            pendingRequirements.forEach((x) => {
              totalToApprove.push({
                userId: userQuest.user.id,
                title: x.title,
                type: x.type,
                status: "to-review",
                resolution: true,
                subtype: x.subtype,
              });
              totalToReject.push({
                userId: userQuest.user.id,
                title: x.title,
                type: x.type,
                status: "to-review",
                resolution: true,
                subtype: x.subtype,
              });
            });
          }
        }
      });
    }

    return (
      <section className="CCQuestTiered">
        <Link
          to={`/admin/command-center/${gameId}/quests`}
          activeClassName="active"
        >
          <button className="CCQuests__back--btn">{t("back")}</button>
        </Link>
        <h2>
          {t("title")}: {quest.title}
        </h2>

        {/* Tabs */}
        {questType === "general" ? (
          <div className="Global__tabs">
            <div
              className={classNames("Global__tab", {
                active: this.state.tab === 2,
              })}
              onClick={this.changeTab.bind(this, 2)}
              aria-hidden
            >
              {quest.isReferral === true ? t("streamDetails") : t("details")}
            </div>
          </div>
        ) : (
          <div className="Global__tabs">
            <div
              className={classNames("Global__tab", {
                active: this.state.tab === 1,
              })}
              onClick={this.changeTab.bind(this, 1)}
              aria-hidden
            >
              {t("summary")}
            </div>
            <div
              className={classNames("Global__tab", {
                active: this.state.tab === 2,
              })}
              onClick={this.changeTab.bind(this, 2)}
              aria-hidden
            >
              {quest.isReferral === true ? t("streamDetails") : t("details")}
            </div>
            {quest.isReferral === true && (
              <div
                className={classNames("Global__tab", {
                  active: this.state.tab === 3,
                })}
                onClick={this.changeTab.bind(this, 3)}
                aria-hidden
              >
                {t("referralDetails")}
              </div>
            )}
            <div
              className={classNames("Global__tab", {
                active: this.state.tab === 4,
              })}
              onClick={this.changeTab.bind(this, 4)}
              aria-hidden
            >
              {t("tierSummary")}
            </div>
            {containsLinks > 0 && (
              <div
                className={classNames("Global__tab", {
                  active: this.state.tab === 5,
                })}
                onClick={this.changeTab.bind(this, 5)}
                aria-hidden
              >
                {t("links")}
              </div>
            )}
            <div
              className={classNames("Global__tab", {
                active: this.state.tab === 6,
              })}
              onClick={this.changeTab.bind(this, 6)}
              aria-hidden
            >
              {t("requirements")} ({totalRequirementsNotCompleted})
            </div>
            {/* <div
              className={classNames('Global__tab', { active: this.state.tab === 7 })}
              onClick={this.changeTab.bind(this, 7)}
              aria-hidden
            >
              Submission Requirements
            </div> */}
          </div>
        )}

        {/* Tab 1: Summary */}
        {this.state.tab === 1 && (
          <section className="ToggleTable">
            <section className="ToggleTable__body-wrapper">
              <section className="ToggleTable__body">
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalCompletes")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext">
                        {t("totalCompletesTip")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot green">
                      {totalSuccesful}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalInQuest")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("totalInQuestTip")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {questTiered ? questTiered.length : 0}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("completionRate")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext">
                        {t("completionRateTip")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot green">
                      {percentageCompletedQuest || "0"}%
                    </div>
                  </div>
                </div>
                <div
                  className="ToggleTable__body-row"
                  style={{ display: quest.paymentAmount > 0 ? "flex" : "none" }}
                >
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalPayouts")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("totalPayoutsTip")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalEarning}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalProcessingFee")}{" "}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">
                        {t("totalProcessingFeeTip")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalProcessingFee}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalToPay")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("totalToPayTip")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalToPay}
                    </div>
                  </div>
                </div>
                <div
                  className="ToggleTable__body-row"
                  style={{ display: quest.isReferral ? "flex" : "none" }}
                >
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("availableKeys")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("availableKeysTip")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {keysAvailables}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("soldKeys")}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("soldKeysTip")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {soldKeysReferral}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalPayable")}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("totalPayableTip")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${this.padWithZeroes(totalPayableReferral / 100)}
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalChatters")}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("totalChattersTip")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {(quest.chatDetails && quest.chatDetails.totalChatters) ||
                        "---"}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalMessages")}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("totalMessagesTip")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {(quest.chatDetails && quest.chatDetails.totalMessages) ||
                        "---"}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("averageMessagesPerMinute")}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">
                        {t("averageMessagesPerMinuteTip")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {(quest.chatDetails &&
                        Math.round(
                          (quest.chatDetails.avgMessagesPerMinute +
                            Number.EPSILON) *
                            100
                        ) / 100) ||
                        "---"}
                    </div>
                  </div>
                </div>

                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalPayable")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("totalPayableTip2")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalPayable ? totalPayable.toFixed(2) : 0}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalPossible")} <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("totalPossibleTip")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalPossible ? totalPossible.toFixed(2) : 0}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalReserved")}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">{t("totalReservedTip")}</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalReserved ? totalReserved.toFixed(2) : 0}
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem divider"></div>
                </div>
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalHoursStreamed")}{" "}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">
                        {t("totalHoursStreamedTip")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {totalHoursStreamed}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("projectedTotalMinutesWatched")}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">
                        {t("projectedTotalMinutesWatchedTip")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {projectedMinutesWatched || "0"}
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      {t("totalMinutesWatched")}{" "}
                      <i className="fa fa-info-circle" />
                      <div className="tooltiptext">
                        {t("totalMinutesWatchedTip")}
                      </div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {totalMinutesWatched || "0"}
                    </div>
                  </div>
                  {peakViewersWithoutManual ? (
                    <div className="ToggleTable__body-rowItem">
                      <div className="ToggleTable__body-itemTop tooltip">
                        {t("singleAllStreams")}{" "}
                        <i className="fa fa-info-circle" />
                        <div className="tooltiptext">
                          {t("singleAllStreamsTip")}
                        </div>
                      </div>
                      <div className="ToggleTable__body-itemBot">
                        {`${peakCCV || "0"} / ${
                          peakViewersWithoutManual || "0"
                        }`}
                      </div>
                    </div>
                  ) : (
                    <div className="ToggleTable__body-rowItem">
                      <div className="ToggleTable__body-itemTop tooltip">
                        {t("singleStream")}
                        <i className="fa fa-info-circle" />
                        <div className="tooltiptext">
                          {t("singleStreamTip")}
                        </div>
                      </div>
                      <div className="ToggleTable__body-itemBot">
                        {peakCCV || "0"}
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </section>
          </section>
        )}

        {/* Tab 2: Details */}
        {this.state.tab === 2 && (
          <QuestDetailsTable
            getEditTierModal={this.getEditTierModal}
            getEditUserModal={this.getEditUserModal}
            auth={this.props.auth}
            quest={quest}
            users={questTieredAll}
            questType={questType}
            userQuests={userQuests}
            postCCRequirement={this.props.postCCRequirement}
            openCongenialityModal={this.openCongenialityModal}
            openVODModals={this.openVODModals}
            reputationRatings={reputationRatings}
          />
        )}
        {this.state.tab === 3 && (
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">
                {t("questUsers")}: <span>{questSales.length}</span>
              </h3>
              <CSVLink
                data={dataReferralCSV}
                headers={headersReferralCSV}
                filename={
                  quest && quest.game
                    ? `${quest.game.name} - ${quest.title}.csv`
                    : "report.csv"
                }
              >
                <i className="fa fa-download" />
              </CSVLink>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">
                    {platform === "twitch" ? "Twitch" : ""}
                  </div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">{t("keysSold")}</div>
                  <div className="table-cell headline">{t("totalSold")}</div>
                  <div className="table-cell headline">{t("bonus")}</div>
                  <div className="table-cell headline">
                    {t("totalEarnings")}
                  </div>
                  <div className="table-cell headline">{t("actions")}</div>
                </div>
                {this.generateRowsReferralHTML(questSales, quest)}
              </section>
            </section>
          </section>
        )}
        {this.state.tab === 4 && (
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div
                  className="ToggleTable__table--section"
                  style={{
                    display:
                      quest.memberTiers && quest.memberTiers.length !== 0
                        ? "block"
                        : "none",
                  }}
                >
                  <div className="ToggleTable__table--title">
                    {t("memberTiers")}
                  </div>
                  <div className="row">
                    <div
                      className="table-cell headline"
                      style={{ maxWidth: "inherit" }}
                    >
                      {t("twitchUsername")}
                    </div>
                    <div
                      className="table-cell headline"
                      style={{ fontWeight: "normal" }}
                    >
                      {t("paymentAmount")}
                    </div>
                    <div className="table-cell headline">
                      {t("openSpotsLeft")}
                    </div>
                  </div>
                  {quest.memberTiers &&
                    quest.memberTiers.map((mt, mtIndex) => (
                      <div key={mt.paymentAmount} className="row">
                        <div
                          className="table-cell"
                          style={{ maxWidth: "inherit" }}
                        >
                          {mt.identity}
                        </div>
                        <div
                          className="table-cell"
                          style={{ fontWeight: "normal" }}
                        >
                          ${mt.paymentAmount}
                        </div>
                        <div className="table-cell">
                          {memberTiersOpenSpots[mtIndex] <= 0
                            ? t("full")
                            : memberTiersOpenSpots[mtIndex]}
                        </div>
                      </div>
                    ))}
                </div>
                <div
                  className="ToggleTable__table--section"
                  style={{
                    display:
                      quest.viewerTiers && quest.viewerTiers.length !== 0
                        ? "block"
                        : "none",
                  }}
                >
                  <div className="ToggleTable__table--title">
                    {t("viewerTiers")}
                  </div>
                  <div className="row">
                    <div
                      className="table-cell headline"
                      style={{ maxWidth: "inherit" }}
                    >
                      {t("paymentAmount")}
                    </div>
                    <div
                      className="table-cell headline"
                      style={{ fontWeight: "normal" }}
                    >
                      {t("minimumViewers")}
                    </div>
                    <div className="table-cell headline">
                      {t("openSpotsLeft")}
                    </div>
                    <div className="table-cell headline" />
                  </div>
                  {quest.viewerTiers &&
                    quest.viewerTiers.map((vt, vtIndex) => {
                      const openSpots =
                        vt.spotsAvailable - viewerTiersSpotsOccupied[vtIndex];
                      return (
                        <div
                          key={vt.paymentAmount}
                          className={classNames("row toggable", {
                            open:
                              showingElements.indexOf(vt.paymentAmount) !== -1,
                          })}
                        >
                          <div className="row-basic">
                            <div
                              className="table-cell"
                              style={{ maxWidth: "inherit" }}
                            >
                              ${vt.paymentAmount}
                            </div>
                            <div
                              className="table-cell"
                              style={{ fontWeight: "normal" }}
                            >
                              {vt.minimumAverageViewers}
                            </div>
                            <div className="table-cell">
                              {openSpots <= 0 ? (
                                t("full")
                              ) : (
                                <React.Fragment>
                                  {openSpots}/{vt.spotsAvailable}
                                </React.Fragment>
                              )}
                            </div>
                            <div className="table-cell last">
                              {showingElements.indexOf(vt.paymentAmount) ===
                                -1 && (
                                <button
                                  className="small-btn"
                                  onClick={this.toggleElementInfo.bind(
                                    this,
                                    vt.paymentAmount
                                  )}
                                >
                                  {t("showDetails")}
                                </button>
                              )}
                              {showingElements.indexOf(vt.paymentAmount) !==
                                -1 && (
                                <button
                                  className="small-btn open"
                                  onClick={this.toggleElementInfo.bind(
                                    this,
                                    vt.paymentAmount
                                  )}
                                >
                                  {t("hideDetails")}
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="row-details">
                            <section className="flex-table">
                              <div className="row">
                                <div className="table-cell headline">
                                  {t("username")}
                                </div>
                                <div className="table-cell headline">
                                  {platform === "twitch" ? "Twitch" : ""}{" "}
                                  {t("username")}
                                </div>
                                <div className="table-cell headline">
                                  {t("averageViewers")}
                                </div>
                              </div>
                              {questTiered.length > 0 &&
                                questTiered.map((item) => {
                                  const viewerTiers = orderBy(
                                    quest.viewerTiers,
                                    ["minimumAverageViewers"],
                                    ["desc"]
                                  );
                                  let higherPaymentAmount = 0;
                                  let highlight = <React.Fragment />;
                                  for (
                                    let i = 0;
                                    i < viewerTiers.length;
                                    i += 1
                                  ) {
                                    if (
                                      item.averageViewersAtJoin >=
                                      viewerTiers[i].minimumAverageViewers
                                    ) {
                                      higherPaymentAmount =
                                        viewerTiers[i].paymentAmount;
                                      break;
                                    }
                                  }
                                  if (
                                    higherPaymentAmount > item.paymentAmount
                                  ) {
                                    highlight = (
                                      <div className="tooltip">
                                        <i className="fa fa-info-circle" />
                                        <div className="tooltiptext nodecoration">
                                          {t("noDecoration")}
                                        </div>
                                      </div>
                                    );
                                  }
                                  if (item.paymentAmount === vt.paymentAmount) {
                                    return (
                                      <div key={item.id} className="row">
                                        <div className="table-cell">
                                          {item.username}
                                        </div>
                                        <div className="table-cell">
                                          {platform === "twitch"
                                            ? item.twitchUsername
                                            : ""}
                                        </div>
                                        <div className="table-cell">
                                          {item.averageViewersAtJoin
                                            ? Math.round(
                                                item.averageViewersAtJoin
                                              )
                                            : "0"}
                                          {highlight}
                                        </div>
                                      </div>
                                    );
                                  }
                                  return <React.Fragment />;
                                })}
                              {questTiered.length <= 0 && (
                                <div className="NoStreamActivity">
                                  {t("noStreamActivity")}
                                </div>
                              )}
                            </section>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </section>
            </section>
          </section>
        )}
        {this.state.tab === 5 && (
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">
                {t("users")}: <span>{questTiered.length}</span>
              </h3>
              <CSVLink
                data={dataCSV}
                headers={headersCSV}
                filename={
                  quest && quest.game
                    ? `${quest.game.name} - ${quest.title}.csv`
                    : "report.csv"
                }
              >
                <i className="fa fa-download" />
              </CSVLink>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">
                    {platform === "twitch" ? "Twitch" : ""}
                  </div>
                  <div className="table-cell headline">{t("link")}</div>
                </div>
                {this.generateLinkRowsHTML(questTiered, quest)}
              </section>
            </section>
          </section>
        )}
        {this.state.tab === 6 && (
          <QuestRequirementsTable
            userQuests={userQuests}
            quest={quest}
            postCCRequirementMultiple={this.props.postCCRequirementMultiple}
            auth={this.props.auth}
          />
        )}
        {qualityModalOpen && (
          <QualityModal
            username={modalUser.username}
            content={vodRating.quality}
            questReqId={questReqId}
            isEditing={isEditingReputation}
            onClose={this.resetReputationModals.bind(this)}
            onNext={this.handleQualityModalChange}
          />
        )}
        {brandSafetyModalOpen && (
          <BrandSafetyModal
            username={modalUser.username}
            content={vodRating.brandSafety}
            questReqId={questReqId}
            isEditing={isEditingReputation}
            onClose={this.resetReputationModals.bind(this)}
            onBack={() => {
              this.setState({
                brandSafetyModalOpen: false,
                qualityModalOpen: true,
              });
            }}
            onSubmit={this.handleBrandSafetySubmit}
          />
        )}
        {
          // If all modals open at once, congeniality comes last
          congenialityModalOpen &&
            !(qualityModalOpen || brandSafetyModalOpen) && (
              <CongenialityModal
                username={modalUser.username}
                content={congeniality}
                isEditing={isEditingReputation}
                onClose={this.resetReputationModals.bind(this)}
                onSubmit={this.handleCongenialityModalSubmit}
              />
            )
        }
      </section>
    );
  }
}

QuestTiered.propTypes = {};

export default withTranslation("questTiered")(QuestTiered);
