/* eslint max-len: ["error", { "code": 800 }] */
import React from "react";
import $ from "jquery";
import qs from "qs";
import { withTranslation } from "react-i18next";
import { simpleNotification } from "../../utils/notifications";
import "./Contact.scss";
import IconParticles from "../icon-particles/IconParticles";

import streamerContact from "./assets/streamer-contact.jpg";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      // showingErrors: false,
      // errors: {
      //   hasError: false,
      //   code: '',
      //   message: '',
      //   all: {},
      // },
      // activeModal: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.auth.isLoading === true &&
      nextProps.auth.isLoading === false
    ) {
      // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.auth.errors.hasError) {
        this.setState({
          showingErrors: true,
          errors: nextProps.auth.errors,
        });
      }
    }
  }

  onSubmit = (allFields) => {
    this.submitGForm(allFields);
  };

  submitGForm = (allFields) => {
    const { t } = this.props;
    const baseURL =
      "https://docs.google.com/forms/d/e/1FAIpQLSfImSeGuX-GyABX6L0pWny9FuuzB8xK3FSuQaKac8r-djjTzA/formResponse?";
    const baseGFormFields = {
      submit: "Submit",
      emailAddress: allFields.email ? allFields.email : "",
      "entry.351391559": allFields.contactName ? allFields.contactName : "",
      "entry.1587419735": allFields.website ? allFields.website : "",
      "entry.591337854": allFields.companyName ? allFields.companyName : "",
      "entry.617606079": allFields.comments ? allFields.comments : "",
    };

    const parameters = qs.stringify(baseGFormFields);

    const completeUrl = `${baseURL}${parameters}`;

    $.ajax({
      type: "GET",
      url: completeUrl,
      contentType: "application/x-www-form-urlencoded",
      success: () => {
        this.setState({
          activeModal: true,
        });
        simpleNotification({
          level: "success",
          title: t("thankYou"),
          message: t("successMessage"),
        });
      },
      error: () => {
        this.setState({
          activeModal: true,
        });
        simpleNotification({
          level: "success",
          title: t("thankYou"),
          message: t("successMessage"),
        });
      },
    });
  };

  render() {
    const { t } = this.props;
    return (
      <section className="Contact">
        <section className="Contact__container">
          <section className="Hero">
            <IconParticles />
            <section className="Hero__content">
              <h2 className="Hero__title">{t("title")}</h2>
            </section>
          </section>

          <section className="MainContact">
            <section className="MainContact__content">
              <section className="MainContact__content--right">
                <h2 className="MainContact__content--title">
                  {t("sectionTitle")}
                </h2>
                <p className="MainContact__content--description">
                  {t("section_1")}{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="mailto:hello@noiz.gg"
                  >
                    hello@noiz.gg
                  </a>{" "}
                  {t("section_2")}{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://twitter.com/noizgg"
                  >
                    Twitter
                  </a>{" "}
                  {t("and")}{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://discordapp.com/invite/N2PrJxK"
                  >
                    {t("discord")}
                  </a>
                  ! <br />
                  <br />
                  <strong>
                    {" "}
                    {t("section_3")}{" "}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="mailto:support@noiz.gg"
                    >
                      support@noiz.gg{" "}
                    </a>
                  </strong>
                </p>
                <section className="MainContact__art">
                  <div className="MainContact__decoration">
                    <div className="d1" />
                    <div className="d2" />
                    <div className="d3" />
                    <div className="d4" />
                    <div className="d5" />
                    <div className="d6" />
                    <div className="d7" />
                  </div>
                  <img
                    className="MainContact__art-img"
                    src={streamerContact}
                    alt="Community Icon"
                  />
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation("contact")(Contact);
