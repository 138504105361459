import React from 'react';

import SocialIcons from '../../../../../global/components/SocialIcons/SocialIcons';
import { makeProper } from '../../../../../utils/functions';
import {useTranslation } from 'react-i18next'

import './SocialNetworks.scss';

const SocialNetworks = ({ socialNetworks }) => {
  const { t } = useTranslation('portalLeft')
  
  return (
    <div className="SocialNetworks">
      <div className="SocialNetworks__Header">{t("socialNetworks")}</div>
      <div className="SocialNetworks__Cards">
        {socialNetworks.map(({ name, url }) => (
          <SocialIcons
            key={name}
            network={name}
            tooltip={name === "youtube" ? "YouTube" : makeProper(name)}
            url={url}
          />
        ))}
      </div>
    </div>
  );
}

export default SocialNetworks;
