import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { simpleNotification } from "../../../utils/notifications";
import RecoverForm from "../../forms/RecoverForm";
import RecoverPasswordForm from "../../forms/RecoverPasswordForm";
import "../login.scss";
import "./Recover.scss";

function Recover({
  auth,
  location,
  recover,
  recoverChangePassword,
  recoverValidate,
  router,
}) {
  const [authToken, setAuthToken] = useState(null);
  const [showingErrors, setShowingErrors] = useState(false);
  const [showingSuccess, setShowingSuccess] = useState(false);
  const { t } = useTranslation("recover");
  const recoveryToken = location.query["recovery-token"];

  // Handle error/success states
  useEffect(() => {
    if (auth.isLoading === false) {
      if (auth.errors.hasError) {
        if (showingSuccess) setShowingSuccess(false);
        if (!showingErrors) setShowingErrors(true);
      } else {
        if (showingErrors) setShowingErrors(false);
        if (auth.recoverSuccess && !showingSuccess) setShowingSuccess(true);
      }
    }
  }, [auth]);

  // Notify of successful password recovery and redirect
  useEffect(() => {
    if (authToken === null) {
      setAuthToken(auth.token);
      return;
    }

    if (authToken !== auth.token) {
      simpleNotification({
        level: "success",
        title: t("passwordRecovered"),
        message: t("passwordRecoveredMessage"),
      });
      router.replace("/");
      return;
    }
  }, [auth, authToken]);

  // Validate recovery token
  useEffect(() => {
    if (recoveryToken) recoverValidate(recoveryToken);
  }, [recoveryToken, recoverValidate]);

  return (
    <section className="Recover LogIn">
      <section className="ModalMaster">
        <section className="Modal active">
          <Link aria-hidden className="Modal__layer" role="button" to="/" />
          <section className="Modal__wrapper">
            <div className="Modal__left">
              <div className="logo" />
              <span className="blurb">{t("blurb")}</span>
              <div className="shapes-container">
                <div className="box box1" />
                <div className="box box2" />
              </div>
            </div>
            <div className="Modal__right">
              <Link
                className="Modal__close-btn"
                to="/"
                href="/"
                role="button"
                aria-hidden
              />
              <div className="Modal__content">
                <ul className="Modal__tabs">
                  <li>
                    <Link
                      activeClassName="active"
                      aria-hidden
                      className="Modal__tab"
                      role="button"
                      to="/sign-up"
                    >
                      {t("signUp")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClassName="active"
                      aria-hidden
                      className="Modal__tab"
                      role="button"
                      to="/recover"
                    >
                      {t("recover")}
                    </Link>
                  </li>
                </ul>
                <section
                  className={`errors-container ${
                    showingErrors ? "active" : ""
                  }`}
                >
                  <button
                    className="error-dismiss"
                    onClick={() => setShowingErrors(false)}
                    type="button"
                  />
                  <div className="error-message">{auth.errors.message}</div>
                </section>
                <section
                  className={`success-container ${
                    showingSuccess & !recoveryToken ? "active" : ""
                  }`}
                >
                  <button
                    className="success-dismiss"
                    onClick={() => setShowingSuccess(false)}
                    type="button"
                  />
                  <div className="success-message">{t("successMessage")}</div>
                </section>
                {recoveryToken && (
                  <RecoverPasswordForm
                    onSubmit={(allFields) => {
                      const { password, rePassword } = allFields;
                      recoverChangePassword(
                        recoveryToken,
                        password,
                        rePassword
                      );
                    }}
                  />
                )}
                {!recoveryToken && (
                  <RecoverForm onSubmit={(identity) => recover(identity)} />
                )}
              </div>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
}

export default Recover;
