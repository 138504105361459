import React, { useState } from 'react';
import { Link } from 'react-router';
import { inRoles } from '../../utils/authChecker';
import { useTranslation } from 'react-i18next';

import defaultAvatar from '../../images/avatars/defaultSpaceman.png';
import './AccountMenu.scss';

function AccountMenu({ auth, isBrandUser, visible }) {
  const {
    user: {
      avatar,
      company,
      displayName,
      username,
    }
  } = auth;
  
  const { t } = useTranslation("accountMenu");
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const toggle = () => setOpen(!open);

  if (!visible) return null;

  return (
    <>
      <div
        className={`hidden-layer ${open ? "open" : ""}`}
        onClick={close}
        onKeyPress={close}
      />
      <button className="user-info" onClick={toggle} onKeyPress={toggle}>
        <section className="about">
          <span className="name">{username}</span>
          {isBrandUser && company ? (
            <span className="company">{company}</span>
          ) : null}
        </section>
        <section className="profile">
          <div className="container">
            {/* <figure
              className="avatar"
              style={{ backgroundImage: `url(${avatar})` }}
            /> */}
            <img
              className="avatar"
              src={avatar || defaultAvatar}
              alt={displayName}
            />
          </div>
          <i className={`fa ${open ? "fa-caret-up" : "fa-caret-down"}`} />
        </section>
      </button>
      {open && (
        <ul className="account-menu" onClick={close}>
          <MenuItem
            linkTo={isBrandUser ? "/portal?component=Profile" : "/my-account"}
            text={t("myAccount")}
          />
          {isBrandUser && (
            <MenuItem
              linkTo="/portal?component=default&tab=home"
              text={t("clientPortal")}
            />
          )}
          {!isBrandUser && (
            <>
              <MenuItem linkTo="/my-quests" text={t("myQuests")} />
              <MenuItem
                linkTo="/connect-accounts"
                text={t("connectAccounts")}
              />
            </>
          )}
          {inRoles(["admin", "helper"]) && (
            <MenuItem
              linkTo="/admin/command-center"
              text={t("commandCenter")}
            />
          )}
          {inRoles(["admin", "demo"]) && (
            <MenuItem linkTo="/portal" text={t("portal")} />
          )}
          {!isBrandUser && <MenuItem linkTo="/faqs" text={t("FAQs")} />}
          <MenuItem linkTo="/logout" text={t("logout")} />
        </ul>
      )}
    </>
  );
}

function MenuItem({ linkTo, text }) {
  return (
    <li>
      <Link
        activeClassName="active"
        to={linkTo}
      >
        {text}
      </Link>
    </li>
  );
}

export default AccountMenu;
