/* eslint-disable */
import React from "react";
import { withTranslation } from "react-i18next";

import "./static.css";

class TermsOfUse extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }
  render() {
    const { t } = this.props;
    return (
      <section className="TermsOfUse static">
        <h1 dir="ltr">{t("title.0")}</h1>
        <h1 dir="ltr">{t("title.1")}</h1>
        <br />
        <br />
        <p dir="ltr">
          {t("section_1.0")}
          <a href="http://www.noiz.gg"> www.noiz.gg</a> {t("section_1.1")}{" "}
          <a href="http://www.eminence.gg"> www.eminence.gg</a>.
          {t("section_1.2")}
        </p>

        <p dir="ltr">{t("section_2")}</p>

        <p dir="ltr">{t("section_3")}</p>
        <p dir="ltr">{t("section_4")}</p>

        <ol>
          {Array.from({ length: 10 }).map((item, index) => (
            <li dir="ltr">
              <p dir="ltr">{t(`section_4_${index + 1}`)}</p>
            </li>
          ))}
          <li dir="ltr">
            <p dir="ltr">
              {t("section_4_11.0")},&nbsp;
              <a href="https://www.youtube.com/t/terms" target="_blank">
                {t("section_4_11.1")}
              </a>
              .
            </p>
          </li>
        </ol>

        <h2 dir="ltr">{t("section_5")}</h2>

        <ol>
          <li dir="ltr">
            <p dir="ltr">{t("section_5_1")}</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">{t("section_5_2")}</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">{t("section_5_3")}</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">{t("section_5_4.title")}</p>
          </li>
          <ol>
            {Array.from({ length: 5 }).map((item, index) => (
              <li dir="ltr">
                <p dir="ltr">{t(`section_5_4.content.${index}`)}</p>
              </li>
            ))}
          </ol>
          <li dir="ltr">
            <p dir="ltr">{t("section_6")}</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">{t("section_7.title")}</p>
          </li>
          <ol>
            {Array.from({ length: 3 }).map((item, index) => (
              <li dir="ltr">
                <p dir="ltr">{t(`section_7.content.${index}`)}</p>
              </li>
            ))}
          </ol>
        </ol>

        <h2 dir="ltr">{t("section_8.title")}</h2>

        <ol>
          {Array.from({ length: 6 }).map((item, index) => (
            <li dir="ltr">
              <p dir="ltr">{t(`section_8.content.${index}`)}</p>
            </li>
          ))}
        </ol>

        <h2 dir="ltr">{t("section_9.title")}</h2>

        <ol>
          {Array.from({ length: 9 }).map((item, index) => (
            <li dir="ltr">
              <p dir="ltr">{t(`section_9.content.${index}`)}</p>
            </li>
          ))}
        </ol>

        <p dir="ltr">{t("section_10")}</p>

        <p dir="ltr">
          {t("section_11.0")}
          <a href="http://www.jamsadr.com/rules-employment-arbitration">
            http://www.jamsadr.com/rules-employment-arbitration
          </a>
          . {t("section_11.1")}
        </p>
        <div></div>
      </section>
    );
  }
}

export default withTranslation("termsOfUse")(TermsOfUse);
