import React, { useEffect, useState } from "react";

import { SetViewContext } from "../../PortalContext";
import GallerySocials from "./GallerySocials";
import NotFound from "../../../../not-found/NotFound";
import Icon from "../../../../../global/images/icons/Icon";
import Quests from "./ProductQuests";
import Loading from "../../../../../global/components/loading/Loading";

import { htmlToPlainText, textTruncate } from "../../../../../utils/functions";
import { useTranslation } from "react-i18next";

import "./ProductDetail.scss";

const dateOptions = { year: "numeric", month: "long", day: "numeric" };

const ProductDetail = (props) => {
  const {
    currentProduct,
    brandUser: {
      games: {
        data: { games: products },
        isLoading,
      },
    },
    getBrandGame,
    tab,
    token,
  } = props;

  const { t } = useTranslation("portalLeft");
  const [product, setProduct] = useState({});

  const getProduct = () => {
    if (currentProduct) {
      const foundProduct = products?.find((p) => p.id === currentProduct);

      if (foundProduct) {
        setProduct(foundProduct);
      }
    }
  };

  useEffect(() => {
    if (currentProduct) {
      const foundProduct = products?.find((p) => p.id === currentProduct);

      if (!foundProduct) {
        getBrandGame({ gameId: currentProduct }, token);
      }
    }
  }, []);

  useEffect(() => {
    getProduct();
  }, [currentProduct]);

  useEffect(() => {
    getProduct();
  }, [isLoading]);

  const {
    id,
    cover,
    coverPortrait,
    description,
    developer,
    developerLogo,
    gallery,
    gamePageURL,
    name,
    quests,
    releaseDate,
    socialNetworks,
  } = product;

  const devStyle = developerLogo ? {} : { marginLeft: 0 };
  const theReleaseDate = new Date(releaseDate || "").toLocaleDateString(
    "en-US",
    dateOptions
  );
  const toDisplayDate =
    theReleaseDate && theReleaseDate !== "Invalid Date" ? theReleaseDate : "";
  const ProductDetailMasthead = () => (
    <div className="ProductDetailMasthead">
      <img className="ProductDetailMasthead__Cover" src={cover} alt={name} />
      <SetViewContext.Consumer>
        {({ setView, view }) => (
          <div
            className="ProductDetailMasthead__Edit"
            onClick={() =>
              setView({
                ...view,
                currentProduct: id,
                component: "ProductEdit",
              })
            }
          >
            <Icon name="edit" />
            <div className="ProductDetailMasthead__Edit__Text">
              {t("editProduct")}
            </div>
          </div>
        )}
      </SetViewContext.Consumer>
      <div className="ProductDetailMasthead__Fade" />
      <div className="ProductDetailMasthead__Details">
        <div className="ProductDetailMasthead__Details__Left">
          <img
            className="ProductDetailMasthead__Details__Left__Portrait"
            src={coverPortrait || cover}
            alt={name}
          />
          <div
            className="ProductDetailMasthead__Details__Left__Website"
            onClick={() => window.open(gamePageURL, "_blank")}
          >
            {t("visitWebsite")}
          </div>
        </div>
        <div className="ProductDetailMasthead__Details__Right">
          <div className="ProductDetailMasthead__Details__Right__Name">
            {name}
          </div>
          <div className="ProductDetailMasthead__Details__Right__ReleaseDate">
            {toDisplayDate}{" "}
          </div>
          <div className="ProductDetailMasthead__Details__Right__Description">
            {textTruncate(htmlToPlainText(description), 150)}
          </div>
          <div className="ProductDetailMasthead__Details__Right__Developer">
            {developerLogo && (
              <img
                className="ProductDetailMasthead__Details__Right__Developer__Logo"
                src={developerLogo}
                alt={developer}
              />
            )}
            <div
              className="ProductDetailMasthead__Details__Right__Developer__Name"
              style={devStyle}
            >
              {developer}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="ProductDetail__Wrapper">
      {Object.keys(product).length === 0 && <Loading isBrand></Loading>}
      {Object.keys(product).length !== 0 && !id && (
        <NotFound
          home={`/portal?component=default&tab=${tab}`}
          buttonText={"Back"}
        />
      )}
      {Object.keys(product).length !== 0 && id && (
        <>
          <ProductDetailMasthead />
          <Quests quests={quests || []} productImg={coverPortrait || cover} />
          <GallerySocials
            gallery={gallery || []}
            socialNetworks={socialNetworks || []}
          />
        </>
      )}
    </div>
  );
};

export default ProductDetail;
