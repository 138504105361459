/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router";
import { orderBy, filter } from "lodash";
import moment from "moment-timezone";
import { Tooltip } from "@material-ui/core";
import classNames from "classnames";
import { simpleNotification } from "../../../../utils/notifications";
import { withTranslation } from "react-i18next";
// import { Field, FieldArray, reduxForm } from 'redux-form';
// import CustomDropDownArray from '../../../../utils/redux-forms-custom-fields/CustomDropDownArray';
import "./Quests.scss";
import { deleteQuestById } from "../../../../utils/apiv2";
const quickWidth = {};

// const quickWidth = {maxWidth: '10%'}

class Quests extends React.Component {
  constructor(props) {
    super(props);
    this.idCopy = React.createRef();
    this.props = props;
    this.state = {
      copyModal: false,
      questCopyId: null,
      selectGame: null,
      tab: 1,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    localStorage.setItem("questsPathname", this.props.router.location.pathname);
    this.setState({ selectGame: gameId });
    if (!this.props.commandCenter.quests.isLoading) {
      this.props.getAdminQuestListByGame(gameId, token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      localStorage.getItem("questsPathname") !==
      nextProps.router.location.pathname
    ) {
      localStorage.setItem(
        "questsPathname",
        nextProps.router.location.pathname
      );
      const { token } = this.props.auth;
      const { gameId } = this.props.router.params;
      this.setState({ selectGame: gameId });
      if (!this.props.commandCenter.quests.isLoading) {
        this.props.getAdminQuestListByGame(gameId, token);
      }
    }
  }

  copyText = (event) => {
    const text = event.target.innerHTML;
    navigator.clipboard.writeText(text);
    simpleNotification({
      level: "success",
      title: "Successfully Copied",
      message: `${text}`,
      autoDismiss: 2,
    });
  };

  generateRowsHTML = (quests, gameId) => {
    const today = moment().tz("America/Los_Angeles");
    const hoursOffset = -today.utcOffset() / 60;
    const { t } = this.props;

    if (quests.length >= 1) {
      return quests.map((quest, index) => (
        <div className="row" key={quest.id}>
          <div className="table-cell">{quest.title}</div>
          <div
            className="table-cell"
            ref={this.idCopy}
            style={{ cursor: "pointer", ...quickWidth }}
            onClick={(e) => this.copyText(e)}
            aria-hidden
          >
            {quest.id}
          </div>
          <div className="table-cell">
            {quest.startDateTime
              ? moment(quest.startDateTime)
                  .tz("America/Los_Angeles")
                  .add(hoursOffset, "hours")
                  .format("MM-DD-YY hh:mm A")
              : "-"}
          </div>
          <div className="table-cell">
            {quest.endDateTime
              ? moment(quest.endDateTime)
                  .tz("America/Los_Angeles")
                  .add(hoursOffset, "hours")
                  .format("MM-DD-YY hh:mm A")
              : "-"}
          </div>
          <div className="table-cell">
            {quest.slug && (
              <Link
                to={`/quests/${quest.slug}`}
                activeClassName="active"
                href={`/quests/${quest.slug}`}
                className="remove-key"
                target="_blank"
              >
                <i className="fa fa-external-link" />
              </Link>
            )}
            {!quest.slug && (
              <span className="not-available">{t("notAvailable")}</span>
            )}
          </div>
          <div className="table-cell">{quest.needsReview}</div>
          <div className="table-cell">
            <Tooltip title={t("edit")} placement="top">
              <Link
                to={`/admin/command-center/${gameId}/quests/${quest.id}/edit`}
                activeClassName="active"
                href={`/admin/command-center/${gameId}/quests/${quest.id}/edit`}
                className="remove-key"
              >
                <i className="fa fa-pencil" />
              </Link>
            </Tooltip>
            {quest.requirePreapproval && (
              <Tooltip title={t("preapproval")} placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/preapproval`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/preapproval`}
                  className="remove-key"
                >
                  <i className="fa fa-check-square-o" />
                </Link>
              </Tooltip>
            )}
            {(quest.type === "paid-bounty" || quest.type === "paid bounty") && (
              <Tooltip title={t("details")} placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/activity`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/activity`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            )}
            {quest.type === "referral" && (
              <Tooltip title={t("details")} placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/sales`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/sales`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            )}
            {quest.type === "timed" && (
              <Tooltip title={t("details")} placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/timed`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/timed`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            )}
            {(quest.type === "general" || quest.type === "default") && (
              <Tooltip title={t("details")} placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${
                    quest.id
                  }/${"general"}`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${
                    quest.id
                  }/${"general"}`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            )}
            {quest.type === "affiliate" && (
              <Tooltip title={t("details")} placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/affiliate`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/affiliate`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            )}
            {(quest.type === "tiered" || quest.type === "tiered-one-time") &&
              quest.frequency === "one-time" && (
                <Tooltip title={t("details")} placement="top">
                  <Link
                    to={`/admin/command-center/${gameId}/quests/${quest.id}/${quest.type}`}
                    activeClassName="active"
                    href={`/admin/command-center/${gameId}/quests/${quest.id}/${quest.type}`}
                    className="remove-key"
                  >
                    <i className="fa fa-line-chart" />
                  </Link>
                </Tooltip>
              )}
            {(quest.type === "tiered" || quest.type === "tiered-multi-days") &&
              quest.frequency === "daily" && (
                <Tooltip title={t("details")} placement="top">
                  <Link
                    to={`/admin/command-center/${gameId}/quests/${quest.id}/tiered-multiple-days`}
                    activeClassName="active"
                    href={`/admin/command-center/${gameId}/quests/${quest.id}/tiered-multiple-days`}
                    className="remove-key"
                  >
                    <i className="fa fa-line-chart" />
                  </Link>
                </Tooltip>
              )}
            {moment(quest.startDateTime).diff(moment(Date.now())) > 0 && (
              <Tooltip title={t("delete")} placement="top">
                <button
                  className="remove-key"
                  onClick={() => {
                    this.deleteQuest(quest, index);
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              </Tooltip>
            )}
            <Tooltip title={t("duplicate")} placement="top">
              <button
                className="remove-key"
                onClick={() => {
                  this.setState({
                    copyModal: true,
                    questCopyId: quest.id,
                    selectGame: gameId,
                  });
                }}
              >
                <i className="fa fa-copy" />
              </button>
            </Tooltip>
          </div>
        </div>
      ));
    }
    return <div className="NoQuests">{t("noQuests")}</div>;
  };

  async deleteQuest(quest, index) {
    const { t } = this.props;
    if (window.confirm(t("deleteConfirm"))) {
      try {
        await deleteQuestById(quest.id);
        this.props.commandCenter.quests.data.splice(index, 1);
        this.setState({ quests: this.props.commandCenter.quests.data });
      } catch (error) {
        console.error("deleteQuest", error);
      }
    }
  }

  copyCCQuest() {
    const { t } = this.props;

    if (window.confirm(t("copyConfirm"))) {
      const { token } = this.props.auth;
      this.props.copyCCQuest(
        this.state.questCopyId,
        this.state.selectGame,
        token
      );
      this.setState({ copyModal: false });
    }
  }

  closeCopyModal = () => {
    this.setState({
      copyModal: false,
    });
  };

  renderCopyModal() {
    const { data: games } = this.props.commandCenter.games;
    // const { gameId } = this.props.router.params;
    let configGames = games.map((game) => ({
      value: game.id,
      label: game.name,
    }));
    configGames = orderBy(configGames, ["label"], ["asc"]);
    const renderSelectOptions = (option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    );
    const { t } = this.props;

    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button
            onClick={this.closeCopyModal.bind(this)}
            className="Modal__layer"
            aria-hidden
          />
          <section className="Modal__wrapper Copy">
            <div className="Modal__copy__header">{t("selectGame")}</div>
            <div className="Modal__copy__body">
              <select
                onChange={(event) =>
                  this.setState({ selectGame: event.target.value })
                }
                className=""
                value={this.state.selectGame}
              >
                {configGames.map(renderSelectOptions)}
              </select>
            </div>
            <div className="Modal__copy__footer">
              <button onClick={this.closeCopyModal.bind(this)}>
                <i className="fa fa-times" /> {t("cancel")}
              </button>
              <button onClick={this.copyCCQuest.bind(this)}>
                <i className="fa fa-check" /> {t("copy")}
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  render() {
    const { gameId } = this.props.router.params;
    const { data: quests, isLoading } = this.props.commandCenter.quests;
    const { tab } = this.state;
    const { t } = this.props;

    if (isLoading) {
      return <i className="fa fa-spinner fa-spin fa-3x fa-fw" />;
    }

    const today = moment().tz("America/Los_Angeles");
    const hoursOffset = -today.utcOffset() / 60;

    let activeQuests = filter(
      quests,
      (x) =>
        moment(x.endDateTime)
          .tz("America/Los_Angeles")
          .add(hoursOffset, "hours") > today
    );
    let finishedQuests = filter(
      quests,
      (x) =>
        moment(x.endDateTime)
          .tz("America/Los_Angeles")
          .add(hoursOffset, "hours") <= today
    );

    activeQuests = orderBy(activeQuests, ["endDateTime"], ["desc"]);
    finishedQuests = orderBy(finishedQuests, ["endDateTime"], ["desc"]);

    let showQuests = [];

    if (tab === 1) {
      showQuests = [...activeQuests];
    } else if (tab === 2) {
      showQuests = [...finishedQuests];
    }

    return (
      <section className="CCQuests">
        <Link
          to={`/admin/command-center/${gameId}/quests/create`}
          activeClassName="active"
          href={`/admin/command-center/${gameId}/quests/create`}
        >
          <button className="CCQuests__create--btn CTAButton__purple">
            {t("btnCreate")}
          </button>
        </Link>
        <div className="Global__tabs">
          <div
            className={classNames("Global__tab", { active: tab === 1 })}
            onClick={() => this.setState({ tab: 1 })}
            aria-hidden
          >
            {t("active")} ({activeQuests.length})
          </div>
          <div
            className={classNames("Global__tab", { active: tab === 2 })}
            onClick={() => this.setState({ tab: 2 })}
            aria-hidden
          >
            {t("finished")} ({finishedQuests.length})
          </div>
        </div>
        <section className="ToggleTable">
          <section className="ToggleTable__table-wrapper">
            <section className="ToggleTable__table flex-table">
              <div className="row">
                <div className="table-cell headline">{t("headline")}</div>
                <div className="table-cell headline" style={quickWidth}>
                  {t("questId")}
                </div>
                <div className="table-cell headline">
                  {t("startDate")} ({t("tracking")})
                </div>
                <div className="table-cell headline">
                  {t("endDate")} ({t("tracking")})
                </div>
                <div className="table-cell headline">{t("landingPage")}</div>
                <div className="table-cell headline">{t("pendingReview")}</div>
                <div className="table-cell headline">{t("actions")}</div>
              </div>
              {this.generateRowsHTML(showQuests, gameId)}
            </section>
          </section>
        </section>
        {this.state.copyModal && this.renderCopyModal()}
      </section>
    );
  }
}

Quests.propTypes = {};

export default withTranslation("quests")(Quests);
