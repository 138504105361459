import React from "react";
import { useTranslation } from "react-i18next";


import { GameButton, ActionButton, CampaignGameButton, CampaignActionButton } from "./QuestCampaignButtons";
import { SetViewContext } from "../../PortalContext";
import { extractInstantQuestPayment } from "../../../../../utils/functions";
import { activateIntercom } from "../../../../../utils/functions";

import Icon from "../../../../../global/images/icons/Icon";

import "./QuestsDisplay.scss";
import "./CampaignDisplay.scss";

export const BlankCampaign = () => {
  const { t } = useTranslation('portalLeft')
    
  return (
    <div className="QuestDisplay__Blank">
      {Icon({ color: "#302f34", name: "loudspeaker" })}
      <div className="QuestDisplay__Blank_Text">
        {t("questDisplayBlankText")}
      </div>
      <div className="QuestDisplay__Blank_Button" onClick={activateIntercom}>
        {t("questDisplayBlankButton")}
      </div>
    </div>
  );
}

export const QuestCard = ({ quest, quest: { img, title, budget, startDate, endDate, createdAt, game, viewerTiers = [], id: questId } }) => {
  const rewardPayment = extractInstantQuestPayment(quest);
  const { cover = "", id: gameId } = game;
  const { t } = useTranslation("portalLeft");

  return (
    <div className="QuestDisplay__Item">
      <img className="QuestDisplay__Item_Img" src={cover} alt={title} />
      <div className="QuestDisplay__Item_Details">
        <div className="QuestDisplay__Item_Title">{title}</div>
        <div className="QuestDisplay__Item_Rewards">
          <div className="QuestDisplay__Item_Rewards_Title">
            {t("rewardsTitle")}
          </div>
          <div className="QuestDisplay__Item_Rewards_Details">
            {rewardPayment > 0 && (
              <>
                <Icon name="moneybag" />
                <div className="QuestDisplay__Item_Rewards_Details_Text">
                  ${(Math.round(rewardPayment * 100) / 100).toFixed(2)}
                </div>
              </>
            )}
            {rewardPayment === -1 && (
              <>
                <Icon name="key" />
                <div className="QuestDisplay__Item_Rewards_Details_Text">
                  {t("freeKey")}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="QuestDisplay__Item_Actions">
          <ActionButton
            tooltip={t("edit")}
            icon="edit"
            url="/edit"
            args={{ component: "QuestEdit", currentQuest: questId }}
          />
          <ActionButton
            tooltip={t("reports")}
            icon="graph"
            url="/reports"
            args={{ component: "report", game: gameId, questId }}
          />
          <ActionButton
            tooltip={t("addUsers")}
            icon="addperson"
            url="/preapproval"
            args={{ component: "ViewAllApplicants", currentQuest: questId }}
          />
          {/* ViewAllApplicants&currentQuest=62bb79115e4ec85a6a107583 */}
          <GameButton
            game={game}
            args={{ component: "ProductEdit", currentProduct: gameId }}
          />
        </div>
        <SetViewContext.Consumer>
          {({ setView, view }) => (
            <div
              className="QuestDisplay__Item_View_Details"
              onClick={() =>
                setView({
                  ...view,
                  currentQuest: quest.id,
                  component: "QuestDetail",
                })
              }
            >
              {t("viewDetails")}
            </div>
          )}
        </SetViewContext.Consumer>
      </div>
    </div>
  );
};

export const CampaignCard = ({ campaign, campaign: { campaign: campaignId, img, name, budget, startDate, endDate, createdAt, game, totalQuests, hoursStreamed, hoursWatched } }) => {
  const { cover = "" } = game;
  const { t } = useTranslation("portalLeft");

  return (
    <div className="CampaignDisplay__Item">
      <div>
        <img className="CampaignDisplay__Item_Img" src={cover} alt={name} />
      </div>
      <div className="CampaignDisplay__Item_Details">
        <div className="CampaignDisplay__Item_Title">{name}</div>

        <div className="CampaignDisplay__Item_Rewards">
          <div>
            <div className="CampaignDisplay__Item_Rewards_Title">
              {t("hoursWatched")}
            </div>
            <div className="CampaignDisplay__Item_Rewards_Details">
              {Math.round(hoursWatched, 2)}
            </div>
          </div>
          <div>
            <div className="CampaignDisplay__Item_Rewards_Title">
              {t("totalQuests")}
            </div>
            <div className="CampaignDisplay__Item_Rewards_Details">
              {totalQuests}
            </div>
          </div>
          <div>
            <div className="CampaignDisplay__Item_Rewards_Title">
              {t("budget")}
            </div>
            <div className="CampaignDisplay__Item_Rewards_Details">
              {Icon({ color: "white", name: "moneybag" })}
              <div className="CampaignDisplay__Item_Rewards_Details_Text">
                ${(Math.round(budget * 100) / 100).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div className="CampaignDisplay__Item_Actions">
          <CampaignActionButton
            style={{ width: "100px" }}
            tooltip="Reports"
            icon="graph"
            url="/reports"
            args={{ component: "report", game: game.id, campaignId }}
          />
          <CampaignGameButton game={game} />
        </div>
        {/* <div className="QuestDisplay__Item_View_Details">
        View Details
      </div> */}
      </div>
    </div>
  );
};
