import React from "react";
import "./ReputationSystemModal.scss";
import "./CongenialityModal.scss";
import classNames from "classnames";
import closeButton from "../../../../components/images/close-button.svg";

import notGreatEmoji from "../../../../components/images/emojis/not-great@3x.png";
import decentEmoji from "../../../../components/images/emojis/decent@3x.png";
import goodEmoji from "../../../../components/images/emojis/good@3x.png";
import { withTranslation } from "react-i18next";

class CongenialityModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const { content } = this.props;
    this.state = {
      comments: content && content.comments ? content.comments : "",
      commentsError: false,
      rating: content && content.rating ? content.rating : false,
      ratingError: false,
    };

    this.NEUTRAL_VALUE = 2;
  }

  handleSelect = (option) => {
    const { label, value } = option;
    let { commentsError } = this.state;

    if (value === this.NEUTRAL_VALUE) commentsError = false;

    this.setState({
      rating: {
        label,
        value,
      },
      commentsError,
    });
  };

  onChange(event) {
    this.handleSelect(event.target.value);
  }

  handleCommentsChange = (event) => {
    this.setState({
      comments: event.target.value,
    });
  };

  validate = () => {
    const { comments, rating } = this.state;

    let commentsError = false;
    let ratingError = false;

    if (comments === "" && rating.value && rating.value !== this.NEUTRAL_VALUE)
      commentsError = true; // Comments required unless response is neutral
    if (!rating.value) ratingError = true;

    if (commentsError || ratingError) {
      this.setState({
        commentsError,
        ratingError,
      });
    } else {
      this.props.onSubmit(comments, rating);
    }
  };

  render() {
    const { isEditing, username, t } = this.props;
    const { comments, commentsError, rating, ratingError } = this.state;
    const options = [
      { label: t("difficult"), value: 1, img: notGreatEmoji },
      { label: t("fine"), value: 2, img: decentEmoji },
      { label: t("super"), value: 3, img: goodEmoji },
    ];

    return (
      <React.Fragment>
        <div className="NoizModalOverlay" />
        <div className="ReputationModal">
          {isEditing ? (
            <img
              alt="close-button"
              className="close"
              src={closeButton}
              onClick={this.props.onClose.bind(this)}
            />
          ) : null}

          <h2>
            {" "}
            {t("howWouldYou")} <span className="username">{username}</span>?{" "}
          </h2>
          <div className="optionsList congeniality">
            {options.map((opt) => (
              <span
                onClick={this.handleSelect.bind(this, opt)}
                className={classNames("option", {
                  selected: rating.value === opt.value,
                })}
              >
                <img className="emoji" alt={opt.value} src={opt.img} />
                {opt.label}
              </span>
            ))}
          </div>
          {ratingError && <span className="error">{t("ratingRequired")}</span>}

          <span className="label">{t("comments")}</span>
          <textarea
            name="comments"
            id="comments"
            value={comments}
            onChange={this.handleCommentsChange}
          />
          <span className="note">{t("note")}</span>
          {commentsError && (
            <span className="error">{t("commentsRequired")}</span>
          )}

          <div className="buttons congeniality">
            <button
              className="CTAButton__purple"
              onClick={this.validate.bind(this)}
            >
              {" "}
              {t("submit")}{" "}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CongenialityModal.propTypes = {};

export default withTranslation("congenialityModal")(CongenialityModal);
