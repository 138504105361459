/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import AccountMenu from "./AccountMenu";
import BasicMenu from "./BasicMenu";
import Logo from "../logo/Logo";
import MobileMenu from "./MobileMenu";
import NavMenu from "./NavMenu";
import PortalNav from "./PortalNav";
import ToggleMobileMenu from "./ToggleMobileMenu";
import Language from "../language";

import classNames from "classnames";
import { isAuthenticated } from "../../utils/authChecker";
import "./MainNav.scss";

function MainNav({
  auth,
  brandUser,
  isBrandUser,
  isAdmin,
  isClientPortal,
  pathname,
  router,
  subdomain,
  toggleMyQuestsMenu,
  user,
  visible,
}) {
  if (!visible) return null;
  const [fadingOut, setFadingOut] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const update = () => {
      if (mobile && window.innerWidth >= 768) {
        setMobile(false);
      } else if (!mobile && window.innerWidth < 768) {
        setMobile(true);
      }
    };

    window.addEventListener("resize", update);
    return () => window.removeEventListener("resize", update);
  }, [mobile]);

  const closeMenu = () => {
    if (window.innerWidth < 768) {
      setMenuOpen(false);
    }
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);
  if (pathname === "/make-some-noiz") return null;
  const authenticated = isAuthenticated();
  const inAboutUs = pathname === "/about-us";
  const inBrandService = pathname === "/brand-services";
  const inContact = pathname === "/contact";
  const inCareers = pathname.startsWith("/careers");
  const inPrivacyPolicy = pathname === "/privacy-policy";
  const inRoot = pathname === "/";
  const inTermsOfUse = pathname === "/terms-of-use";
  const isBrands = subdomain === "brands" || subdomain === "brands.staging";
  const showBasicMenu =
    (inRoot ||
      inAboutUs ||
      inBrandService ||
      (inContact && !authenticated) ||
      inCareers ||
      inTermsOfUse ||
      inPrivacyPolicy) &&
    ["staging", "", "192.168"].includes(subdomain) &&
    !isClientPortal &&
    !mobile;

  const headerClassNames = classNames("Main__Header", {
    Portal: isClientPortal,
    "basic-menu": showBasicMenu,
    white: isBrands,
  });

  const wrapperClassNames = classNames("Main__Header__wrapper", {
    borderBottom: inAboutUs || inRoot || pathname.startsWith("/quest"),
    constrain: pathname === "/quests" || pathname === "/contact",
    forceFullWidth: pathname === "/admin",
  });

  return (
    <header className={headerClassNames}>
      <section className={wrapperClassNames}>
        <Logo black={isBrands} />
        <PortalNav
          campaign={brandUser.campaign}
          campaigns={brandUser.campaigns}
          router={router}
          visible={isClientPortal}
        />
        <BasicMenu visible={showBasicMenu} />
        <MobileMenu
          authenticated={authenticated}
          closeMenu={closeMenu}
          fadingOut={fadingOut}
          isBrands={isBrands}
          isAdmin={isAdmin}
          isBrandUser={isBrandUser}
          isClientPortal={isClientPortal}
          visible={mobile && menuOpen}
        />
        <NavMenu
          auth={auth}
          brandUser={brandUser}
          isBrandUser={isBrandUser}
          isAdmin={isAdmin}
          isClientPortal={isClientPortal}
          router={router}
          toggleMyQuestsMenu={toggleMyQuestsMenu}
          user={user}
          visible={!mobile && !showBasicMenu && authenticated}
        />
        <AccountMenu
          auth={auth}
          isBrandUser={isBrandUser}
          visible={!mobile && authenticated && !showBasicMenu}
        />
        <ToggleMobileMenu
          fadingOut={fadingOut}
          open={menuOpen}
          setFadingOut={setFadingOut}
          toggleMenu={toggleMenu}
          visible={mobile && !(isClientPortal && !authenticated)}
        />
      </section>
      
      <Language></Language>
    </header>
  );
}

export default MainNav;
