/* eslint-disable */
import React from "react";
import { withTranslation } from "react-i18next";

import "./static.css";

class PrivacyPolicy extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <section className="PrivacyPolicy static">
        <h1 dir="ltr">{t("title.0")}</h1>
        <h1 dir="ltr">{t("title.1")}</h1>
        <br />
        <br />
        <p dir="ltr">
          {t("section_1.title.0")}
          <a href="http://www.eminence.gg" rel="nofollow noopener noreferrer">
            {" "}
            www.eminence.gg
          </a>{" "}
          {t("section_1.title.1")} <a href="http://www.noiz.gg"> www.noiz.gg</a>
          . {t("section_1.title.2")}
        </p>

        <ol>
          <li dir="ltr">
            <p dir="ltr">{t("section_1.content.0")}</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">{t("section_1.content.1")}</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <p dir="ltr">{t("section_1.content.2")}</p>
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">{t("section_1.content.3")}</p>
          </li>
        </ol>
        <h2 dir="ltr">{t("section_2.title")}</h2>

        <p dir="ltr">{t("section_2.content.0")}</p>

        <p dir="ltr">{t("section_2.content.1")}</p>

        <p dir="ltr">{t("section_2.content.2")}</p>

        <p dir="ltr">{t("section_2.content.3")}</p>

        <h2 dir="ltr">{t("section_3.title")}</h2>

        {Array.from({ length: 5 }).map((item, index) => {
          <p dir="ltr">{t(`section_3.content.${index}`)}</p>;
        })}

        <h2 dir="ltr">{t("section_4.title")}</h2>
        {Array.from({ length: 3 }).map((item, index) => (
          <p dir="ltr">{t(`section_4.content.${index}`)}</p>
        ))}
        <h2 dir="ltr">{t("section_5.title")}</h2>
        <p dir="ltr">{t("section_5.content")}</p>

        <h2 dir="ltr">{t("section_6.title")}</h2>
        <p dir="ltr">{t("section_6.content.0")}</p>
        <p dir="ltr">{t("section_6.content.1")}</p>

        <h2 dir="ltr">{t("section_7.title")}</h2>
        <p dir="ltr">{t(`section_6.content.0`)}</p>
        <p dir="ltr">{t(`section_6.content.1`)}</p>

        <h2 dir="ltr">{t("section_8.title")}</h2>
        {Array.from({ length: 3 }).map((item, index) => (
          <p dir="ltr">{t(`section_8.content.${index}`)}</p>
        ))}

        <h2 dir="ltr">{t("section_9.title")}</h2>
        {Array.from({ length: 7 }).map((item, index) => (
          <p dir="ltr">{t(`section_9.content.${index}`)}</p>
        ))}

        <p dir="ltr">
          {t(`section_9.content_2.0`)}{" "}
          <a
            href="https://developers.google.com/youtube/terms/developer-policies#definition-youtube-api-services"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            YouTube API Services
          </a>
          . YouTube API Services are governed by Google’s Privacy Policy,{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {t(`section_9.content_2.1`)}
          </a>
          . {t(`section_9.content_2.2`)}
        </p>

        <p dir="ltr">
          I{t(`section_9.content_3`)}{" "}
          <a
            href="https://security.google.com/settings/security/permissions"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            https://security.google.com/settings/security/permissions
          </a>
          .
        </p>

        <h2 dir="ltr">{t("section_10.title")}</h2>
        <p dir="ltr">{t("section_10.content")}</p>

        <h2 dir="ltr">{t("section_11.title")}</h2>
        <p dir="ltr">{t("section_11.content")}</p>

        <h2 dir="ltr">{t("section_12.title")}</h2>
        <p dir="ltr">{t("section_12.content")}</p>

        <h2 dir="ltr">{t("section_13.title")}</h2>
        <p dir="ltr">{t("section_13.content")}</p>

        <h2 dir="ltr">{t("section_14.title")}</h2>
        {Array.from({ length: 3 }).map((item, index) => (
          <p dir="ltr">{t(`section_14.content.${index}`)}</p>
        ))}

        <h2 dir="ltr">{t("section_15.title")}</h2>
        <p dir="ltr">
          {t("section_15.content")}{" "}
          <a href="mailto:support@noiz.gg" rel="nofollow noopener noreferrer">
            support@noiz.gg
          </a>
          .
        </p>
      </section>
    );
  }
}

export default withTranslation("privacyPolicy")(PrivacyPolicy);
