import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
import ReadyPayModal from "./ReadyPayModal";
import moment from "moment";
import { getReadyPayment } from "../../../../utils/apiv2";
import Select from "react-select";
import { arrayDropDownColors } from "../../../../utils/constants";


import "./PaymentList.scss";
const paidStatusOption = [
  { label: "paid", value: true },
  { label: "unpaid", value: false },
];
const TableHeaderCell = withStyles((theme) => ({
  head: {
    color: "#9b9b9b",
    fontWeight: "400",
    letterSpacing: "1.2px",
    fontSize: "14px",
  },
}))(TableCell);

const ReadyPayment = (props) => {
  const { t } = useTranslation("readyPayment");

  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [pagination, setPagination] = useState({});
  const [numPage, setNumPage] = useState(1);
  const [paidStatus,setPaidStatus] = useState(false)
  const { readyToPayButton } = props;

  const getTableHeaders = () => {
    const tableHeaders = [
      {
        id: "username",
        numeric: false,
        disablePadding: true,
        label: "Noiz",
      },
      //   {
      //     id: `${platform}Username`,
      //     numeric: false,
      //     disablePadding: true,
      //     label: platform.charAt(0).toUpperCase() + platform.slice(1),
      //   },
      {
        id: "paymentAmount",
        numeric: true,
        disablePadding: true,
        label: t("paymentAmount"),
      },
      {
        id: "averageViewers",
        numeric: true,
        disablePadding: true,
        label: t("averageViewers"),
      },
      {
        id: "timePlaying",
        numeric: true,
        disablePadding: true,
        label: t("timePlaying"),
      },
      {
        id: "paid",
        numeric: false,
        disablePadding: true,
        label: t("status"),
      },
      {
        id: "dateAccepted",
        numeric: false,
        disablePadding: true,
        label: t("dateAccepted"),
      },
      {
        id: "actions",
        numeric: false,
        disablePadding: true,
        label: t("actions"),
      },
    ];
    return tableHeaders;
  };

  // Sort & define payment rows; return elements to display
  const getPaymentRows = (payments, fetchPayment) => {
    if (payments.length) {
      return payments.map((payment) => (
        <TableRow hover tabIndex={-1} key={payment.id}>
          <TableCell align="center">
            {payment.username ? payment.username : "-"}
          </TableCell>
          <TableCell align="center">
            {payment.paymentAmount
              ? `$${Math.abs(payment.paymentAmount).toFixed(2)}`
              : "-"}
          </TableCell>
          <TableCell align="center">{payment.averageViewers ?? "-"}</TableCell>
          {/* <TableCell align="center">{payment.questTitle ? payment.questTitle : '-'}</TableCell> */}
          <TableCell align="center">
            {payment.timePlaying ? payment.timePlaying : "-"}
          </TableCell>
          <TableCell align="center">
            {payment.paid ? "paid" : "unpaid"}
          </TableCell>
          <TableCell align="center">
            {payment.dateAccepted
              ? moment(payment.dateAccepted).format("MMMM DD, YYYY")
              : "-"}
          </TableCell>
          <TableCell align="center">
            <ReadyPayModal
              paid={payment.paid}
              userQuestId={payment.userQuestId}
              userId={payment.id}
              userName={payment.username}
              payment={`$${Math.abs(payment.paymentAmount || 0).toFixed(2)}`}
              fetchPayment={fetchPayment}
            ></ReadyPayModal>
          </TableCell>
        </TableRow>
      ));
    }
  };

  const fetchPayment = async (page = 1,paid) => {
    setLoading(true);
    try {
      const status = paid ?? paidStatus.value;
      const result = await getReadyPayment(page, status);
      const { dataList, totalCnt, totalPages } = result.data.data;

      setPayments(dataList);
      setPagination({
        totalPayments: totalCnt,
        pageNumber: page,
        totalPages,
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    } 
  };

  const paginationRight = async () => {
    if (pagination.totalPages < numPage + 1) {
      return;
    }
    if (!loading) {
      await fetchPayment(numPage + 1);
    }
    setNumPage(numPage + 1);
  };

  const paginationLeft = async () => {
    if (numPage === 1) {
      return;
    }
    if (!loading) {
      await fetchPayment(numPage - 1);
    }
    setNumPage(numPage - 1);
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  return (
    <>
      <h2 className="AUserList__title">
        {" "}
        {t("readyToPay")}:{" "}
        <span>
          {pagination?.totalPayments} ({t("page")} {pagination?.pageNumber}{" "}
          {t("of")} {pagination?.totalPages})
        </span>
      </h2>
      <section className="ToggleTable">
        <section className="ToggleTable__header justify-between">
          <div className="ToggleTable__header__left">{readyToPayButton()}</div>
          <div>
            <Select
              defaultValue={paidStatusOption[1]}
              styles={{
                ...arrayDropDownColors,
                input: () => ({ width: "100px" }),
                menu: () => ({ position: "absolute", width: "100%" }),
              }}
              options={paidStatusOption}
              onChange={(val) => { 
                setNumPage(1)
                setPaidStatus(val)
                fetchPayment(1,val.value);
              }}
            ></Select>
          </div>
        </section>
        <section className="ToggleTable__table-wrapper">
          <section className="ToggleTable__table flex-table">
            <Table>
              <TableHead>
                <TableRow>
                  {getTableHeaders().map((headCell) => (
                    <TableHeaderCell
                      key={headCell.id}
                      align="center"
                      padding={headCell.disablePadding ? "none" : "default"}
                      sortDirection="asc"
                    >
                      {headCell.label}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && payments && getPaymentRows(payments, fetchPayment)}
              </TableBody>
            </Table>
            <div className="NoPayments">
              {loading && (
                <div className="PaymentList__loading">
                  <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                </div>
              )}
              {!loading && !payments?.length && (
                <span>{t("noPaymentsFound")}</span>
              )}
            </div>
          </section>
        </section>
        <section className="ToggleTable__footer">
          <section className="ToggleTable__footer__left">
            <button onClick={paginationLeft}>
              <i className="fa fa-caret-left" />
            </button>
          </section>
          <section className="ToggleTable__footer__right">
            <button onClick={paginationRight}>
              <i className="fa fa-caret-right" />
            </button>
          </section>
        </section>
      </section>
    </>
  );
};

export default ReadyPayment;
