import { filter } from "lodash";

const user = (state = {}, action) => {
  switch (action.type) {
    //  <----------- START NOIZ V2 REDUCERS LOGIC ---------->
    case "GET_INITIAL_INFO_PENDING":
      return {
        ...state,
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: true,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myBounties: {
          ...state.myBounties,
          isLoading: true,
          errors: {
            ...state.myBounties.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myQuests: {
          ...state.myQuests,
          isLoading: true,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          isLoading: true,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        timedActivity: {
          ...state.timedActivity,
          isLoading: true,
          errors: {
            ...state.timedActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_INITIAL_INFO_ERROR":
      return {
        ...state,
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: false,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        myBounties: {
          ...state.myBounties,
          isLoading: false,
          errors: {
            ...state.myBounties.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        myQuests: {
          ...state.myQuests,
          isLoading: false,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          isLoading: false,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        timedActivity: {
          ...state.timedActivity,
          isLoading: false,
          errors: {
            ...state.timedActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_INITIAL_INFO_ABORTED":
      return {
        ...state,
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: false,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myBounties: {
          ...state.myBounties,
          isLoading: false,
          errors: {
            ...state.myBounties.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myQuests: {
          ...state.myQuests,
          isLoading: false,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          isLoading: false,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        timedActivity: {
          ...state.timedActivity,
          isLoading: false,
          errors: {
            ...state.timedActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_INITIAL_INFO_SUCCESS":
      return {
        ...state,
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          data: action.payload.response.data.myPreApprovedGameKeys,
          isLoading: false,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myBounties: {
          ...state.myBounties,
          data: action.payload.response.data.myBounties,
          isLoading: false,
          errors: {
            ...state.myBounties.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myQuests: {
          ...state.myQuests,
          data: action.payload.response.data.myQuests,
          isLoading: false,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          data: action.payload.response.data.myUserQuests,
          isLoading: false,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        timedActivity: {
          ...state.timedActivity,
          data: action.payload.response.data.timedActivity,
          isLoading: false,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "REQUEST_GAME_KEY_PENDING":
      return {
        ...state,
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: true,
          isRequestingAGameKey: true,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "REQUEST_GAME_KEY_ERROR":
      return {
        ...state,
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: false,
          isRequestingAGameKey: false,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "REQUEST_GAME_KEY_ABORTED":
      return {
        ...state,
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: false,
          isRequestingAGameKey: false,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "REQUEST_GAME_KEY_SUCCESS": {
      return {
        ...state,
        game: {
          data: action.payload.response.game,
        },
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          data: [
            filter(
              state.myPreApprovedGameKeys.data,
              action.payload.response.game.id
            ),
            action.payload.response.game,
          ],
          isLoading: false,
          isRequestingAGameKey: false,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    }
    case "JOIN_TO_BOUNTY_PENDING":
      return {
        ...state,
        myBounties: {
          ...state.myBounties,
          isLoading: true,
          isJoiningToABounty: true,
          errors: {
            ...state.myBounties.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "JOIN_TO_BOUNTY_ERROR":
      return {
        ...state,
        myBounties: {
          ...state.myBounties,
          isLoading: false,
          isJoiningToABounty: false,
          errors: {
            ...state.myBounties.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "JOIN_TO_BOUNTY_ABORTED":
      return {
        ...state,
        myBounties: {
          ...state.myBounties,
          isLoading: false,
          isJoiningToABounty: false,
          errors: {
            ...state.myBounties.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "JOIN_TO_BOUNTY_SUCCESS": {
      return {
        ...state,
        myBounties: {
          ...state.myBounties,
          data: [
            ...filter(
              state.myBounties.data,
              (bounty) => action.payload.response.bounty.id !== bounty.id
            ),
            action.payload.response.bounty,
          ],
          isLoading: false,
          isJoiningToABounty: false,
          errors: {
            ...state.myBounties.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    }
    case "LEAVE_BOUNTY_PENDING":
      return {
        ...state,
        myBounties: {
          ...state.myBounties,
          isLoading: true,
          isLeavingABounty: true,
          errors: {
            ...state.myBounties.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "LEAVE_BOUNTY_ERROR":
      return {
        ...state,
        myBounties: {
          ...state.myBounties,
          isLoading: false,
          isLeavingABounty: false,
          errors: {
            ...state.myBounties.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "LEAVE_BOUNTY_ABORTED":
      return {
        ...state,
        myBounties: {
          ...state.myBounties,
          isLoading: false,
          isLeavingABounty: false,
          errors: {
            ...state.myBounties.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "LEAVE_BOUNTY_SUCCESS": {
      return {
        ...state,
        myBounties: {
          ...state.myBounties,
          data: [
            ...filter(
              state.myBounties.data,
              (bounty) => action.payload.response.bounty.id !== bounty.id
            ),
          ],
          isLoading: false,
          isLeavingABounty: false,
          errors: {
            ...state.myBounties.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    }
    //  <----------- END NOIZ V2 REDUCERS LOGIC ---------->
    case "JOIN_QUEST_PENDING":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          isLoading: true,
          isJoiningToAQuest: true,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          isLoading: true,
          isJoiningToAQuest: true,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "JOIN_QUEST_ERROR":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.myQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.myUserQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "JOIN_QUEST_ABORTED":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "JOIN_QUEST_SUCCESS":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          data: [...state.myQuests.data, action.payload.response.quest],
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          data: [
            ...filter(
              state.myUserQuests.data,
              (userQuest) =>
                action.payload.response.userQuest.id !== userQuest.id
            ),
            action.payload.response.userQuest,
          ],
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "LEAVE_QUEST_PENDING":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          isLoading: true,
          isLeavingAQuest: true,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "LEAVE_QUEST_ERROR":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          isLoading: false,
          isLeavingAQuest: false,
          errors: {
            ...state.myQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "LEAVE_QUEST_ABORTED":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          isLoading: false,
          isLeavingAQuest: false,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "LEAVE_QUEST_SUCCESS":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          data: [
            ...filter(
              state.myQuests.data,
              (quest) => action.payload.response.quest.id !== quest.id
            ),
          ],
          isLoading: false,
          isLeavingAQuest: false,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_PAYMENTS_PENDING":
      return {
        ...state,
        myPayments: {
          ...state.myPayments,
          isLoading: true,
          errors: {
            ...state.myPayments.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_PAYMENTS_ERROR":
      return {
        ...state,
        myPayments: {
          ...state.myPayments,
          isLoading: false,
          errors: {
            ...state.myPayments.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_USER_PAYMENTS_ABORTED":
      return {
        ...state,
        myPayments: {
          ...state.myPayments,
          isLoading: false,
          errors: {
            ...state.myPayments.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_PAYMENTS_SUCCESS":
      return {
        ...state,
        myPayments: {
          ...state.myPayments,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.myPayments.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_TODAY_ACTIVITY_PENDING":
      return {
        ...state,
        todayActivity: {
          ...state.todayActivity,
          isLoading: true,
          errors: {
            ...state.todayActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_TODAY_ACTIVITY_ERROR":
      return {
        ...state,
        todayActivity: {
          ...state.todayActivity,
          isLoading: false,
          errors: {
            ...state.todayActivity.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_USER_TODAY_ACTIVITY_ABORTED":
      return {
        ...state,
        todayActivity: {
          ...state.todayActivity,
          isLoading: false,
          errors: {
            ...state.todayActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_TODAY_ACTIVITY_SUCCESS":
      return {
        ...state,
        todayActivity: {
          ...state.todayActivity,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.todayActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_REFERRALS_ACTIVITY_PENDING":
      return {
        ...state,
        referralsActivity: {
          ...state.referralsActivity,
          isLoading: true,
          errors: {
            ...state.referralsActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_REFERRALS_ACTIVITY_ERROR":
      return {
        ...state,
        referralsActivity: {
          ...state.referralsActivity,
          isLoading: false,
          errors: {
            ...state.referralsActivity.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_USER_REFERRALS_ACTIVITY_ABORTED":
      return {
        ...state,
        referralsActivity: {
          ...state.referralsActivity,
          isLoading: false,
          errors: {
            ...state.referralsActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_REFERRALS_ACTIVITY_SUCCESS":
      return {
        ...state,
        referralsActivity: {
          ...state.referralsActivity,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.referralsActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_TIMED_ACTIVITY_PENDING":
      return {
        ...state,
        timedActivity: {
          ...state.timedActivity,
          isLoading: true,
          errors: {
            ...state.timedActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_TIMED_ACTIVITY_ERROR":
      return {
        ...state,
        timedActivity: {
          ...state.timedActivity,
          isLoading: false,
          errors: {
            ...state.timedActivity.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_USER_TIMED_ACTIVITY_ABORTED":
      return {
        ...state,
        timedActivity: {
          ...state.timedActivity,
          isLoading: false,
          errors: {
            ...state.timedActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_USER_TIMED_ACTIVITY_SUCCESS":
      return {
        ...state,
        timedActivity: {
          ...state.timedActivity,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.timedActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "SUBMIT_LINK_PENDING":
      return {
        ...state,
        link: {
          ...state.link,
          isLoading: true,
          errors: {
            ...state.link.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "SUBMIT_LINK_ERROR":
      return {
        ...state,
        link: {
          ...state.link,
          isLoading: false,
          errors: {
            ...state.link.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "SUBMIT_LINK_ABORTED":
      return {
        ...state,
        link: {
          ...state.link,
          isLoading: false,
          errors: {
            ...state.link.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "SUBMIT_LINK_SUCCESS":
      return {
        ...state,
        link: {
          ...state.link,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.link.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_MY_QUESTS_PAGE_DATA_PENDING":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          isLoading: true,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          isLoading: true,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        allUserPayments: {
          ...state.allUserPayments,
          isLoading: true,
          errors: {
            ...state.allUserPayments.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        tieredActivity: {
          ...state.tieredActivity,
          isLoading: true,
          errors: {
            ...state.tieredActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        tieredMultipleDaysActivity: {
          ...state.tieredMultipleDaysActivity,
          isLoading: true,
          errors: {
            ...state.tieredMultipleDaysActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_MY_QUESTS_PAGE_DATA_ERROR":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          isLoading: false,
          errors: {
            ...state.myQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        allUserPayments: {
          ...state.allUserPayments,
          data: action.payload.response?.allUserPayments
            ? action.payload.response.allUserPayments
            : {},
          isLoading: false,
          errors: {
            ...state.myQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          isLoading: false,
          errors: {
            ...state.myUserQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        tieredActivity: {
          ...state.tieredActivity,
          isLoading: false,
          errors: {
            ...state.tieredActivity.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        tieredMultipleDaysActivity: {
          ...state.tieredMultipleDaysActivity,
          isLoading: false,
          errors: {
            ...state.tieredMultipleDaysActivity.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_MY_QUESTS_PAGE_DATA_ABORTED":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          isLoading: false,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        allUserPayments: {
          ...state.allUserPayments,
          isLoading: false,
          errors: {
            ...state.allUserPayments.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          isLoading: false,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        tieredActivity: {
          ...state.tieredActivity,
          isLoading: false,
          errors: {
            ...state.tieredActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        tieredMultipleDaysActivity: {
          ...state.tieredMultipleDaysActivity,
          isLoading: false,
          errors: {
            ...state.tieredMultipleDaysActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_MY_QUESTS_PAGE_DATA_SUCCESS":
      return {
        ...state,
        myQuests: {
          ...state.myQuests,
          data: action.payload.response.myQuests,
          isLoading: false,
          errors: {
            ...state.myQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        allUserPayments: {
          ...state.allUserPayments,
          data: action.payload.response.allUserPayments,
          isLoading: false,
          errors: {
            ...state.allUserPayments.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        myUserQuests: {
          ...state.myUserQuests,
          data: action.payload.response.myUserQuests,
          isLoading: false,
          errors: {
            ...state.myUserQuests.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        tieredActivity: {
          ...state.tieredActivity,
          data: action.payload.response.tieredActivity,
          isLoading: false,
          errors: {
            ...state.tieredActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
        tieredMultipleDaysActivity: {
          ...state.tieredMultipleDaysActivity,
          data: action.payload.response.tieredMultipleDaysActivity,
          isLoading: false,
          errors: {
            ...state.tieredMultipleDaysActivity.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "REQUEST_UPDATE_AVATAR_URL_PENDING":
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: true,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "REQUEST_UPDATE_AVATAR_URL_ERROR":
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "REQUEST_UPDATE_AVATAR_URL_ABORTED":
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "REQUEST_UPDATE_AVATAR_URL_SUCCESS":
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: "",
            message: "",
          },
        },
      };
    case "GET_BROADCAST_SUCCESS":
      return {
        ...state,
        broadcasts: {
          ...state.broadcasts,
          data: action.payload.response,
          isLoading: false,
        },
      };
    case "GET_BROADCAST_ABORTED":
      return {
        ...state,
        broadcasts: {
          ...state.broadcasts,
          isLoading: false,
        },
      };
    case "GET_BROADCAST_CANCELED":
      return {
        ...state,
        broadcasts: {
          ...state.broadcasts,
          isLoading: false,
        },
      };
    case "GET_BROADCAST_ERROR":
      return {
        ...state,
        broadcasts: {
          ...state.broadcasts,
          isLoading: false,
          errors: {
            ...state.broadcasts.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_BROADCAST_PENDING":
      return {
        ...state,
        broadcasts: {
          ...state.broadcasts,
          isLoading: true,
        },
      };
    default:
      return state;
  }
};

export default user;
