/* eslint max-len: ["error", { "code": 800 }] */
import React from "react";
import "./CareersPost.scss";
import { withTranslation } from "react-i18next";

const titleStyle = {
  backgroundColor: "transparent",
  fontWeight: 700,
  fontStyle: "normal",
  fontVariant: "normal",
  textDecoration: "none",
  lineHeight: "1.8",

  verticalAlign: "baseline",
  whiteSpace: "pre-wrap",
  marginBottom: "40px",
};

const textStyle = {
  listStyleType: "disc",

  lineHeight: "1.8",

  backgroundColor: "transparent",
  fontWeight: 400,
  fontStyle: "normal",
  fontVariant: "normal",
  textDecoration: "none",
  verticalAlign: "baseline",
  whiteSpace: "pre",
};

const subStyle = {
  backgroundColor: "transparent",
  fontWeight: 400,
  lineHeight: "1.8",

  fontStyle: "normal",
  fontVariant: "normal",
  textDecoration: "none",
  verticalAlign: "baseline",
  whiteSpace: "pre-wrap",
};

const spacingStyle = {
  lineHeight: "1.8",

  marginTop: "5px",
  marginBottom: "5px",
};

const topStyle = {
  lineHeight: "1.8",

  backgroundColor: "transparent",
  fontWeight: 400,
  fontStyle: "normal",
  fontVariant: "normal",
  textDecoration: "none",
  verticalAlign: "baseline",
  whiteSpace: "pre-wrap",
};

class BrandSalesManager extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}
  // replace all inline styles with classNames

  render() {
    const { t } = this.props
    return (
      <section className="CareersPost">
        <section className="CareersPost__container">
          <section className="CareersPostSummary">
            <section className="CareersPostSummary__content">
              <section className="PostingItem">
                <a
                  className="PostingItem__apply-btn"
                  href="mailto:angie@noiz.gg"
                >
                  {t("applyJob")} <i className="fa fa-envelope" />
                </a>
                <section className="PostingItem__wrapper">
                  <h4 className="PostingItem__title">{t("title")}</h4>
                  <section className="PostingItem__categories">
                    <span className="PostingItem__categories--location">
                      {t("sub_title")}
                    </span>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section className="CareersPostDetails">
            <section className="CareersPostDetails__content">
              <>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>{t("section_1.title")}</span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={topStyle}>{t("section_1.content")}</span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>
                    <br></br>
                    {t("section_2.title")}
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={topStyle}>{t("section_2.content")}</span>
                  <span style={topStyle}>
                    <br />
                  </span>
                  <span style={topStyle}>
                    <br />
                  </span>
                  <span style={titleStyle}>
                    {t("section_3")}
                    <br></br>
                  </span>
                  <span style={topStyle}>
                    <br />
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>{t("section_3_1.title")}&nbsp;</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  {Array.from({ length: 4 }).map((item, index) => (
                    <li aria-level={1} dir="ltr" style={textStyle}>
                      <p dir="ltr" style={spacingStyle}>
                        <span style={subStyle}>
                          {t(`section_3_1.content.${index}`)}
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>
                    <br />
                  </span>
                  <span style={titleStyle}>{t("section_3_2.title")}&nbsp;</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  {Array.from({ length: 10 }).map((item, index) => (
                    <li aria-level={1} dir="ltr" style={textStyle}>
                      <p dir="ltr" style={spacingStyle}>
                        <span style={subStyle}>
                          {t(`section_3_2.content.${index}`)}
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>{t("section_3_3.title")}&nbsp;</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  {Array.from({ length: 9 }).map((item, index) => (
                    <li aria-level={1} dir="ltr" style={textStyle}>
                      <p dir="ltr" style={spacingStyle}>
                        <span style={subStyle}>
                          {t(`section_3_3.content.${index}`)}
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
                <br></br>

                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>{t("section_3_3.title")}</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  {Array.from({ length: 2 }).map((item, index) => (
                    <li aria-level={1} dir="ltr" style={textStyle}>
                      <p dir="ltr" style={spacingStyle}>
                        <span style={subStyle}>
                          {t(`section_3_4.content.${index}`)}
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
                <p>
                  <br />
                </p>
                <p>
                  <br />
                </p>
              </>
              <p>
                <span style={{ fontWeight: 400 }}>{t("section_4")}</span>
              </p>
              <p className="CareersPostDetails__content--cta">
                {t("email")} <a href="mailto:angie@noiz.gg">angie@noiz.gg</a>
              </p>
            </section>
          </section>
        </section>
      </section>
    );
  }
}



export default withTranslation("brandSalesManager")(BrandSalesManager);
