/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */

import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/lib/Creatable";
import { find } from "lodash";
import { withTranslation } from "react-i18next";

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label: `#${label}`,
  value: label,
});

class CustomTagField extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      inputValue: "",
      alreadyInValues: false,
      isValidTag: true,
    };
  }

  render() {
    const {
      input,
      meta: { touched, error },
      tracking,
      t,
      ...custom
    } = this.props;
    const hasError = touched && error !== undefined;
    const { inputValue, alreadyInValues, isValidTag } = this.state;
    const tagValidation = /^(^|\B)(?![0-9_]+\b)([a-zA-Z0-9_]{1,30})$/;
    // const makeDataFunc = tracking ? createTrackingOption : createOption;
    const makeDataFunc = createOption;

    return (
      <div className="input-container">
        <label htmlFor={input.label}>{custom.customLabel}</label>
        <div className="input-wrapper">
          <CreatableSelect
            {...input}
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(value) => input.onChange(value)}
            onInputChange={(value) => {
              // console.log(value, !tracking ? tagValidation.test(value) : 'tracking override TRUE');
              this.setState({
                inputValue: value,
                alreadyInValues: find(input.value, ["value", value]),
                isValidTag:
                  value !== "" && !tracking ? tagValidation.test(value) : true,
              });
            }}
            onKeyDown={(event) => {
              if (!inputValue) return;
              switch (event.key) {
                case "Enter":
                case ",":
                case " ":
                case "Tab": {
                  const isValidTagInternal = !tracking
                    ? tagValidation.test(inputValue)
                    : true;
                  const alreadyIn = find(input.value, ["value", inputValue]);
                  if (!alreadyIn && isValidTagInternal) {
                    this.setState({
                      inputValue: "",
                      isValidTag: isValidTagInternal,
                    });
                    input.onChange([...input.value, makeDataFunc(inputValue)]);
                  }
                  event.preventDefault();
                  break;
                }
                default:
                  break;
              }
            }}
            placeholder={t("tagPlaceholder")}
            className={classNames("react-select-container", {
              touched,
              error: hasError,
              ok: !hasError,
              empty: !input.value,
              "not-empty": input.value,
              ...input.className,
            })}
            classNamePrefix="react-select"
            value={input.value}
            onBlur={(event) => {
              if (inputValue !== "") {
                const isValidTagInternal = !tracking
                  ? tagValidation.test(inputValue)
                  : true;
                const alreadyIn = find(input.value, ["value", inputValue]);
                if (!alreadyIn && isValidTagInternal) {
                  this.setState({
                    inputValue: "",
                    isValidTag: isValidTagInternal,
                  });
                  input.onChange([...input.value, makeDataFunc(inputValue)]);
                }
              }
              event.preventDefault();
            }}
          />
          {custom.customLabel2}
          {hasError && touched && (
            <div className="error-icon" style={{ right: "35px", top: "14px" }}>
              <i className="fa fa-exclamation" />
            </div>
          )}
          {!hasError && touched && (
            <div className="ok-icon" style={{ right: "35px", top: "14px" }}>
              <i className="fa fa-check" />
            </div>
          )}
          {hasError && <div className="error-message">{error}</div>}
          {alreadyInValues && (
            <div className="error-message">{t("tagAlreadyExists")}</div>
          )}
          {!isValidTag && (
            <div className="error-message">{t("invalidTag")}</div>
          )}
        </div>
      </div>
    );
  }
}

CustomTagField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  // meta: PropTypes.shape({
  //   touched: PropTypes.bool,
  //   error: PropTypes.bool,
  // }),
  placeholder: PropTypes.string,
};

CustomTagField.defaultProps = {
  placeholder: "",
  // meta: {
  //   error: false,
  //   touched: false,
  // },
};

export default withTranslation("customTagField")(CustomTagField);
