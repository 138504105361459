import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import {useTranslation } from 'react-i18next'

import Icon from '../../../../../global/images/icons/Icon';
import { simpleNotification } from '../../../../../utils/notifications';
import { uploadToS3 } from '../../../../../utils/util';

import './Gallery.scss';

const Gallery = (props) => {
  const {
    gallery = [],
    handleFormChange,
    preSignedUrl: {
      data: {
        preSignedUrl,
        url: signedUrl,
      },
    },
    brandUserUploadImageUrl,
    token,
  } = props;

  const { t } = useTranslation('portalLeft')
  const [uploading, setUploading] = useState(false);
  const [pendingFile, setPendingFile] = useState(null);

  useEffect(() => {
    if (pendingFile && preSignedUrl) {
      uploadToS3(pendingFile, preSignedUrl, handleLoad);
    }
  }, [preSignedUrl]);

  const handleGalleryChange = ({ url, add = false }) => {
    let newGallery = [...gallery];

    if (add) {
      newGallery.push({
        thumb: url,
        type: 'image',
        url,
      });
    } else {
      const index = newGallery.findIndex(object => object.url === url);
      newGallery = newGallery.slice(0, index).concat(newGallery.slice(index + 1));
    }

    handleFormChange('gallery', newGallery);
  };

  const handleLoad = (e) => {
    setUploading(false);
    setPendingFile(null);

    if (e.target.status !== 200) {
      simpleNotification({
        level: "error",
        title: t("error"),
        message: t("uploadImgError"),
      });
    } else {
      handleGalleryChange({ url: signedUrl, add: true });
    }
  };

  const handleFileUpload = (files, e) => {
    setUploading(true);
    try {
      const file = files?.length ? files[0] : e[0];
      setPendingFile(file);
      const fileNameSplit = file.name.split('.');
      const format = fileNameSplit[fileNameSplit.length - 1];

      brandUserUploadImageUrl(format, token);
    } catch (e) {
      setUploading(false);
      simpleNotification({
        level: "error",
        title: t("fileErrorTitle"),
        message: t("readFileError"),
        autoDismiss: 7,
      });
    }
  };

  const galleryCards = gallery.map(({ thumb, type, url }) => (
    <div className="Gallery_Card" key={url} >
      <img className="Gallery_Card_Img" src={url} alt="url" />
      <div className="Gallery_Card_Overlay" onClick={() => handleGalleryChange({ url, add: false })} >
        <Icon name="trash" className="Gallery_Card_Overlay_Icon" />
      </div>
    </div>
  ));

  return (
    <>
      <div className="Gallery_Header">{t("galleryHeader")}</div>
      <div className="Gallery_Items">
        {uploading && <i className="fa fa-spinner fa-spin fa-3x fa-fw" />}
        {!uploading && <div className="Gallery_Items">{galleryCards}</div>}
      </div>
      {!uploading && (
        <Dropzone
          className="Gallery_Dropzone"
          onDrop={(e, event) => handleFileUpload(event, e)}
          disablePreview={false}
          multiple={false}
          accept={[".jpg", ".png"]}
          inputProps={{ id: "file" }}
        >
          <Icon name="upload" color="#37393c" />
          <div className="Modal__upload__title">{t("modalUploadTitle")}</div>
          <button className="Gallery_Dropzone_Button">
            {t("uploadFiles")}
          </button>
        </Dropzone>
      )}
    </>
  );
};

export default Gallery;
