import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import ReactTooltip from "react-tooltip";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Pagination from "./Pagination";
import GameCard from "./GameCard";
import GameQuestList from "./GameQuestList";
import { isAuthenticated } from "../../utils/authChecker";
import "./QuestIndex.scss";

function QuestIndex(props) {
  const {
    auth: { token, user },
    config: {
      featuredGames: { data: featuredGames, isLoading: isLoadingFeaturedGames },
    },
    getOptimizedAllQuests,
    getOptimizedEndedQuests,
    home: {
      quests: {
        all: { data: allQuests, isLoading: isLoadingAllQuests, page: pageAll },
        ended: { data: endedQuests, isLoading: isLoadingEndedQuests, page: pageEnded },
      },
    },
    user: {
      myUserQuests: { data: myUserQuests, isLoading: isLoadingMyUserQuests },
    },
  } = props;

  const { t } = useTranslation("questIndex");

  const [activeTab, setActiveTab] = useState("current");
  const current = activeTab === "current";

  useEffect(() => {
    if (isAuthenticated()) runPostAuthChecks(props);
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      if (current) {
        getOptimizedAllQuests("all", 1, token);
      } else {
        getOptimizedEndedQuests("ended", pageEnded === 0 ? 1 : pageEnded, token);
      }
    }
  }, [current, token]);

  if (isLoadingFeaturedGames || isLoadingMyUserQuests) return <LoadingSpinner />;

  const data = current ? allQuests.groupedByGame : endedQuests.groupedByGame;
  const { limit, page, total } = current ? props.home.quests.all : props.home.quests.ended;
  const showLoadingSpinnerForQuests =
    (isLoadingAllQuests && current) || (isLoadingEndedQuests && !current);

  return (
    <div className="quest-index">
      <div className="featured-games">
        <Carousel // This carousel has issues w/ showing pixels to the left/right that shouldn't be shown
          autoPlay
          infiniteLoop
          interval={10000}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          width="100%"
        >
          {featuredGames &&
            featuredGames.map((game) => <GameCard data={game} key={game.id} />)}
        </Carousel>
      </div>
      <div className="tab-bar">
        <span
          className={`tab-span ${current ? "active" : ""}`}
          onClick={() => setActiveTab("current")}
        >
          {t("current")}
        </span>
        <span
          className={`tab-span ${!current ? "active" : ""}`}
          onClick={() => setActiveTab("past")}
        >
          {t("past")}
        </span>
        <Pagination
          currentPage={page}
          handleClick={(argPage) => {
            if (current) {
              if (pageAll === argPage || isLoadingAllQuests) return;
              getOptimizedAllQuests("all", argPage, token);
            } else {
              if (pageEnded === argPage || isLoadingEndedQuests) return;
              getOptimizedEndedQuests("ended", argPage, token);
            }
          }}
          limit={limit}
          total={total}
        />
      </div>
      {showLoadingSpinnerForQuests && (
        <div style={{ height: 500, position: "relative" }}>
          <LoadingSpinner />
        </div>
      )}
      {!showLoadingSpinnerForQuests && (!data || data.length <= 0) && (
        <div className="no-quests">
          {t("noQuests")}{" "}
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to="https://discord.gg/Hwb2Rrg"
          >
            {t("discord")}
          </Link>
          .
        </div>
      )}
      {!showLoadingSpinnerForQuests &&
        data &&
        data.map((gq) => (
          <GameQuestList
            data={gq}
            key={gq.name}
            user={user}
            userQuests={myUserQuests.filter((uq) => uq.game === gq.id)}
          />
        ))}
      <Pagination
        currentPage={page}
        handleClick={(argPage) => {
          if (current) {
            if (pageAll === argPage || isLoadingAllQuests) return;
            getOptimizedAllQuests("all", argPage, token);
          } else {
            if (pageEnded === argPage || isLoadingEndedQuests) return;
            getOptimizedEndedQuests("ended", argPage, token);
          }
        }}
        limit={limit}
        total={total}
      />
      <ReactTooltip
        className="tool-tip"
        effect="float"
        place="top"
        multiline
        html
      />
    </div>
  );
}

const LoadingSpinner = () => (
  <div className='loading-spinner'>
    <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
  </div>
);

const runPostAuthChecks = props => {
  const {
    auth: { token, user },
    getInitialInfo,
    getQuest,
    getUserReferralsActivity,
    getUserTodayActivity,
    router,
  } = props;

  if (!(user.firstName && user.lastName && user.country)) {
    router.replace("/complete-registration");
  } else if (!(user.connectedAccounts.twitch.id || user.connectedAccounts.youtube.id)) {
    router.replace("/connect-accounts");
  } else {
    getInitialInfo(token);
    getUserTodayActivity(token);
    getUserReferralsActivity(token);
    // getOptimizedAllQuests("all", 1, token);

    if (localStorage.getItem("justLoggedIn")) {
      localStorage.removeItem("justLoggedIn");
    }

    if (localStorage.getItem("showWelcome")) {
      router.replace("/welcome");
      localStorage.removeItem("showWelcome");
      return;
    }

    if (localStorage.getItem("redirectAfterAuth")) {
      router.replace(localStorage.getItem("redirectAfterAuth"));
      localStorage.removeItem("redirectAfterAuth");
      localStorage.setItem("justLoggedIn", true);
      return;
    }

    if (localStorage.getItem("questId")) {
      getQuest(localStorage.getItem("questId"), true, token);
      localStorage.removeItem("questId");
    }
  }
};

export default QuestIndex;
