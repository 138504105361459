/* eslint-disable */
import React from "react";
import { withTranslation } from "react-i18next";

import "./static.css";

class DnlKeyQuestTermsConditions extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <section className="PrivacyPolicy static">
        <h1>{t("title")}</h1>
        <h2>{t("section_1.title")}</h2>
        <ul>
          <li>{t("section_1.content.0")}</li>
          <li>{t("section_1.content.1")}</li>
        </ul>
        <h2>{t("section_2.title")}</h2>
        <ul>
          <li>{t("section_2.content")}</li>
        </ul>
        <h2>{t("section_3.title")}</h2>
        <strong>{t("section_3.content_1.0")}</strong>
        {t("section_3.content_1.1")}
        <br />
        <br />
        <strong>{t("section_3.content_2.0")}</strong> Noiz.gg and our
        {t("section_3.content_2.1")}
        <p>{t("lastUpdated")}</p>
      </section>
    );
  }
}

export default withTranslation("dnlKeyQuestTermsConditions")(
  DnlKeyQuestTermsConditions
);
