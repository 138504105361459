import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetViewContext } from '../PortalContext';
import Icon from '../../../../global/images/icons/Icon';

import './QuestInsights.scss';

const QuestInsights = (props) => {
  const { campaigns = [] } = props;
  const { t } = useTranslation("questInsights");
  const [searchValue, setSearchValue] = useState('');

  const possibleOptions = searchValue ? campaigns.filter(c => c.name.toLowerCase().includes(searchValue.toLowerCase()) || c.game.name.toLowerCase().includes(searchValue.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name)) : [];

  return (
    <div className="QuestInsights_Wrapper mb-20">
      <div className="QuestInsights">
        <div className="QuestInsights__Header">{t("campaignInsights")}</div>
        <div className="QuestInsights__Content">
          <div className="QuestInsights__Content_Search_Bar">
            <Icon name="search" color="white" />
            <input
              type="text"
              id="quest-insight-search-input"
              label={t("questInsightSearch")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          {!!searchValue?.length && !possibleOptions?.length && (
            <div className="QuestInsights__Values">
              <div className="QuestInsights__Values__NoValues">
                {t("noCampaignsFound")}
              </div>
            </div>
          )}
          {!!searchValue?.length && !!possibleOptions?.length && (
            <SetViewContext.Consumer>
              {({ setView, view }) => (
                <div className="QuestInsights__Values">
                  {possibleOptions.map((c) => (
                    <div
                      className="QuestInsights__Values__Value"
                      key={c.id}
                      onClick={() =>
                        setView({
                          ...view,
                          component: "report",
                          campaignId: c.campaign,
                          game: c.game.id,
                        })
                      }
                    >
                      {c.name}
                    </div>
                  ))}
                </div>
              )}
            </SetViewContext.Consumer>
          )}
          {/* <div className="QuestInsights__Content_Search_Submit">
            <Icon name='play' color='white' />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default QuestInsights;
