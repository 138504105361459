import React, { useState } from 'react';

import useInfiniteLoading from '../../../../../global/hooks/useInfiniteLoading';

import { discoverStreamers } from '../../../../../utils/apiv2';
import { SetViewContext } from '../../PortalContext';
import { getTwitchAverageViewers, getUserAvatar, nFormatter } from '../../../../../utils/functions';
import { getRandomAvatar } from '../../../../../global/images/icons/Avatar';
import { useTranslation } from 'react-i18next'

import Icon from '../../../../../global/images/icons/Icon';
import LoadingGif from '../../assets/LoadingGif';
import './Discover.scss';

const DiscoverStreamerItem = (props) => {
  const {
    connectedAccounts = {},
    displayName,
    id,
    liveStream = {},
    userGame30 = [],
    username,
  } = props;

  const { t } = useTranslation('portalLeft')
  const { twitch = {} } = connectedAccounts;
  const { followers = 0, username: twitchUsername = '' } = twitch;
  const { gameName = '', viewerCount = 0 } = liveStream;

  const isLive = !!gameName;
  let viewers = '---';
  let gameString = '---';

  if (isLive) {
    viewers = nFormatter(viewerCount);
    gameString = gameName;
  }
  else if (userGame30.length) {
    viewers = nFormatter(getTwitchAverageViewers(props, false)) + ' AVG';
    gameString = userGame30.sort((a, b) => b.averageViewers - a.averageViewers)[0]?.gameString || '---';
  }

  const avatarUrl = getUserAvatar(props);

  return (
    <li className="DiscoverStreamers__List__Item" key={id}>
      <img
        alt={displayName || username}
        className="DiscoverStreamers__List__Item__Avatar"
        onError={(e) => (e.target.src = getRandomAvatar())}
        src={avatarUrl}
      />
      <div className="DiscoverStreamers__List__Item__Info">
        <div
          className="DiscoverStreamers__List__Item__Info__Live"
          onClick={() =>
            window.open(`https://twitch.tv/${twitchUsername}`, "_blank")
          }
        >
          <Icon name="live" color={isLive ? "#fe5777" : "#777779"} />
          <div
            className={`DiscoverStreamers__List__Item__Info__Live__Live ${
              !isLive && "Offline"
            }`}
          >
            {isLive ? t("live") : t("offline")}
          </div>
          <Icon name="linkout" color={isLive ? "white" : "#777779"} />
        </div>
        <div className="DiscoverStreamers__List__Item__Info__Name">
          {displayName || username}
        </div>
        <div className="DiscoverStreamers__List__Item__Info__Stats">
          <div className="DiscoverStreamers__List__Item__Info__Stats__Stat">
            <Icon name="gamepad" />
            <div
              className={`DiscoverStreamers__List__Item__Info__Stats__Stat__Text ${
                isLive ? "GameNameOnline" : "Offline"
              }`}
            >
              {gameString}
            </div>
          </div>
          <div className="DiscoverStreamers__List__Item__Info__Stats__Stat">
            <Icon name="eye" />
            <div className="DiscoverStreamers__List__Item__Info__Stats__Stat__Text">
              {viewers}
            </div>
          </div>
          <div className="DiscoverStreamers__List__Item__Info__Stats__Stat">
            <Icon name="users" />
            <div className="DiscoverStreamers__List__Item__Info__Stats__Stat__Text">
              {nFormatter(followers)} {t("followers")}
            </div>
          </div>
        </div>
      </div>
      <SetViewContext.Consumer>
        {({ setView, view }) => (
          <button
            className="DiscoverStreamers__List__Item__ProfileButton"
            onClick={() =>
              setView({
                ...view,
                component: "StreamerProfile",
                currentUser: username,
              })
            }
          >
            {t("viewProfile")}
          </button>
        )}
      </SetViewContext.Consumer>
    </li>
  );
};

export const LiveUsers = () => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation("portalLeft");

  const { items, hasNext, loadNext, isLoading } = useInfiniteLoading({
    getItems: (pageToLoad) => discoverStreamers({ isLive: true, numPage: pageToLoad, pageSize: 10 }),
    pageToLoad: page,
    setPageToLoad: setPage,
    pageSize: 10,
  });

  return (
    <>
      <ul className="DiscoverStreamers__List">
        {items.map((item) => (
          <DiscoverStreamerItem {...item} />
        ))}
      </ul>
      {isLoading && <LoadingGif />}
      {!isLoading && hasNext && (
        <div className="DiscoverStreamers__LoadMore">
          <button
            className="DiscoverStreamers__LoadMore__Button"
            onClick={loadNext}
          >
            {t("loadMore")}
          </button>
        </div>
      )}
    </>
  );
};

export const AllUsers = () => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation("portalLeft");

  const { items, hasNext, loadNext, isLoading } = useInfiniteLoading({
    getItems: (pageToLoad) => discoverStreamers({ numPage: pageToLoad, pageSize: 10 }),
    pageToLoad: page,
    setPageToLoad: setPage,
    pageSize: 10,
  });

  return (
    <>
      <ul className="DiscoverStreamers__List">
        {items.map((item) => (
          <DiscoverStreamerItem {...item} />
        ))}
      </ul>
      {isLoading && <LoadingGif />}
      {!isLoading && hasNext && (
        <div className="DiscoverStreamers__LoadMore">
          <button
            className="DiscoverStreamers__LoadMore__Button"
            onClick={loadNext}
          >
            {t("loadMore")}
          </button>
        </div>
      )}
    </>
  );
};

export const SearchUsers = ({ search }) => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation("portalLeft");

  const { items, hasNext, loadNext, isLoading } = useInfiniteLoading({
    getItems: (pageToLoad) => discoverStreamers({ numPage: pageToLoad, pageSize: 10, search: search.trim().toLowerCase() }),
    pageToLoad: page,
    setPageToLoad: setPage,
    pageSize: 10,
  });

  return (
    <>
      <ul className="DiscoverStreamers__List">
        {items.map((item) => (
          <DiscoverStreamerItem {...item} />
        ))}
      </ul>
      {isLoading && <LoadingGif />}
      {!isLoading && hasNext && (
        <div className="DiscoverStreamers__LoadMore">
          <button
            className="DiscoverStreamers__LoadMore__Button"
            onClick={loadNext}
          >
            {t("loadMore")}
          </button>
        </div>
      )}
    </>
  );
};
