import React from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";

import Icon from "../../images/icons/Icon";
import Loading from "../loading/Loading";
import CustomCheckbox from "../../../components/brand/portal/CustomInputs/CustomCheckbox";

import "./ConfirmModal.scss";
import { useTranslation } from "react-i18next";

const ConfirmModal = ({
  callback,
  loading,
  open,
  content,
  setOpen = () =>
    console.log("you need to set a callback to close the ConfirmModal"),
  title,
  confirmText,
  isGreen = false,
  useConfirmCheckbox = false,
}) => {
  const { t } = useTranslation("componentsConfirmModal");
  const handleClose = () => setOpen(false);

  const [agreedToTerms, setAgreedToTerms] = React.useState(false);

  const enableConfirmButton = useConfirmCheckbox ? agreedToTerms : true;
  const disabledConfirmButton = useConfirmCheckbox
    ? !enableConfirmButton
    : false;

  const callbackAndClose = async () => {
    await callback();
    handleClose();
  };

  const finalCallback = disabledConfirmButton ? null : callbackAndClose;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="DeleteModal__Box">
        <div className="DeleteModal">
          <div className="DeleteModal__Close" onClick={handleClose}>
            <Icon name="xmark" />
          </div>
          <div className="DeleteModal__Title">{title || t("title")}</div>
          <div className="DeleteModal__Content">
            {content || t("content")}
            {loading && (
              <div
                className={`DeleteModal__Button Active ${
                  isGreen ? "green" : ""
                }`}
                disabled
              >
                <Loading className="mt-1 mb-1" />
              </div>
            )}
            {!loading && (
              <div
                className={`DeleteModal__Button Active ${
                  isGreen ? "green" : ""
                } ${disabledConfirmButton ? "disabled" : ""}`}
                disabled={disabledConfirmButton}
                onClick={finalCallback}
              >
                {confirmText || t("confirmText")}
              </div>
            )}
            {useConfirmCheckbox && (
              <CustomCheckbox
                header={t("header")}
                value={agreedToTerms}
                onChange={() => setAgreedToTerms(!agreedToTerms)}
              />
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
