import api from "../utils/api";
import { getErrorResponse } from "../utils/apiErrorHandler";

export const loginTwitch = (action$) =>
  action$.ofType("LOGIN_TWITCH_OAUTH_CODE").mergeMap((action) =>
    api
      .loginWithTwitchOAuthCode(
        action.payload.code,
        action.payload.scope,
        action.payload.token
      )
      .map((response) => ({
        type: "LOGIN_TWITCH_OAUTH_CODE_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("LOGIN_TWITCH_OAUTH_CODE_ABORTED"))
      .defaultIfEmpty({ type: "LOGIN_TWITCH_OAUTH_CODE_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "LOGIN_TWITCH_OAUTH_CODE_ERROR", error })
      )
      .startWith({
        type: "LOGIN_TWITCH_OAUTH_CODE_PENDING",
        payload: action.payload,
      })
  );

export const loginWithTwitterOAuthCode = (action$) =>
  action$.ofType("LOGIN_TWITTER_OAUTH_CODE").mergeMap((action) =>
    api
      .loginWithTwitterOAuthCode(
        action.payload.oauth_token,
        action.payload.oauth_verifier,
        action.payload.token
      )
      .map((response) => ({
        type: "LOGIN_TWITTER_OAUTH_CODE_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("LOGIN_TWITTER_OAUTH_CODE_ABORTED"))
      .defaultIfEmpty({ type: "LOGIN_TWITTER_OAUTH_CODE_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "LOGIN_TWITTER_OAUTH_CODE_ERROR", error })
      )
      .startWith({
        type: "LOGIN_TWITTER_OAUTH_CODE_PENDING",
        payload: action.payload,
      })
  );

export const loginYoutube = (action$) =>
  action$.ofType("LOGIN_YOUTUBE_OAUTH_CODE").mergeMap((action) =>
    api
      .loginWithYoutubeOAuthCode(
        action.payload.code,
        action.payload.scope,
        action.payload.token
      )
      .map((response) => ({
        type: "LOGIN_YOUTUBE_OAUTH_CODE_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("LOGIN_YOUTUBE_OAUTH_CODE_ABORTED"))
      .defaultIfEmpty({ type: "LOGIN_YOUTUBE_OAUTH_CODE_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "LOGIN_YOUTUBE_OAUTH_CODE_ERROR", error })
      )
      .startWith({
        type: "LOGIN_YOUTUBE_OAUTH_CODE_PENDING",
        payload: action.payload,
      })
  );

export const updateUserInfo = (action$) =>
  action$.ofType("UPDATE_USER_INFO").mergeMap((action) =>
    api
      .updateUserInfo(action.payload.userInfo, action.payload.token)
      .map((response) => ({
        type: "UPDATE_USER_INFO_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("UPDATE_USER_INFO_ABORTED"))
      .defaultIfEmpty({ type: "UPDATE_USER_INFO_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "UPDATE_USER_INFO_ERROR", error })
      )
      .startWith({ type: "UPDATE_USER_INFO_PENDING", payload: action.payload })
  );

export const GET_USER_STATISTICS = (action$) =>
  action$.ofType("GET_USER_STATISTICS").mergeMap((action) =>
    api
      .getUserStatistics(action.payload.username)
      .map((response) => ({
        type: "GET_USER_STATISTICS_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("GET_USER_STATISTICS_ABORTED"))
      .defaultIfEmpty({ type: "GET_USER_STATISTICS_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "GET_USER_STATISTICS_ERROR", error })
      )
      .startWith({
        type: "GET_USER_STATISTICS_PENDING",
        payload: action.payload,
      })
  );

export const signIn = (action$) =>
  action$.ofType("SIGN_IN").mergeMap((action) =>
    api
      .signIn(action.payload)
      .map((response) => ({
        type: "SIGN_IN_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("SIGN_IN_ABORTED"))
      .defaultIfEmpty({ type: "SIGN_IN_CANCELED" })
      .catch((error) => getErrorResponse({ type: "SIGN_IN_ERROR", error }))
      .startWith({ type: "SIGN_IN_PENDING", payload: action.payload })
  );

export const signUp = (action$) =>
  action$.ofType("SIGN_UP").mergeMap((action) =>
    api
      .signUp(action.payload.userInfo)
      .map((response) => ({
        type: "SIGN_UP_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("SIGN_UP_ABORTED"))
      .defaultIfEmpty({ type: "SIGN_UP_CANCELED" })
      .catch((error) => getErrorResponse({ type: "SIGN_UP_ERROR", error }))
      .startWith({ type: "SIGN_UP_PENDING", payload: action.payload })
  );

export const recover = (action$) =>
  action$.ofType("RECOVER").mergeMap((action) =>
    api
      .recover(action.payload.identity)
      .map((response) => ({
        type: "RECOVER_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("RECOVER_ABORTED"))
      .defaultIfEmpty({ type: "RECOVER_CANCELED" })
      .catch((error) => getErrorResponse({ type: "RECOVER_ERROR", error }))
      .startWith({ type: "RECOVER_PENDING", payload: action.payload })
  );

export const recoverValidate = (action$) =>
  action$.ofType("RECOVER_VALIDATE").mergeMap((action) =>
    api
      .recoverValidate(action.payload.recoveryToken)
      .map((response) => ({
        type: "RECOVER_VALIDATE_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("RECOVER_VALIDATE_ABORTED"))
      .defaultIfEmpty({ type: "RECOVER_VALIDATE_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "RECOVER_VALIDATE_ERROR", error })
      )
      .startWith({ type: "RECOVER_VALIDATE_PENDING", payload: action.payload })
  );

export const recoverChangePassword = (action$) =>
  action$.ofType("RECOVER_CHANGE_PASSWORD").mergeMap((action) =>
    api
      .recoverChangePassword(
        action.payload.recoveryToken,
        action.payload.password,
        action.payload.rePassword
      )
      .map((response) => ({
        type: "RECOVER_CHANGE_PASSWORD_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("RECOVER_CHANGE_PASSWORD_ABORTED"))
      .defaultIfEmpty({ type: "RECOVER_CHANGE_PASSWORD_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "RECOVER_CHANGE_PASSWORD_ERROR", error })
      )
      .startWith({
        type: "RECOVER_CHANGE_PASSWORD_PENDING",
        payload: action.payload,
      })
  );

export const disconnectTwitch = (action$) =>
  action$.ofType("TWITCH_DISCONNECTION").mergeMap((action) =>
    api
      .disconnectTwitch(action.payload.token, action.payload.user)
      .map((response) => ({
        type: "DISCONNECTING_TWITCH_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("DISCONNECTING_TWITCH_ABORTED"))
      .defaultIfEmpty({ type: "DISCONNECTING_TWITCH_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "DISCONNECTING_TWITCH_ERROR", error })
      )
      .startWith({
        type: "DISCONNECTING_TWITCH_PENDING",
        payload: action.payload,
      })
  );

export const disconnectYoutube = (action$) =>
  action$.ofType("YOUTUBE_DISCONNECTION").mergeMap((action) =>
    api
      .disconnectYoutube(action.payload.token, action.payload.user)
      .map((response) => ({
        type: "YOUTUBE_DISCONNECTION_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("YOUTUBE_DISCONNECTION_ABORTED"))
      .defaultIfEmpty({ type: "YOUTUBE_DISCONNECTION_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "YOUTUBE_DISCONNECTION_ERROR", error })
      )
      .startWith({
        type: "YOUTUBE_DISCONNECTION_PENDING",
        payload: action.payload,
      })
  );

export const updateProfileInfo = (action$) =>
  action$.ofType("UPDATE_PROFILE_INFO").mergeMap((action) =>
    api
      .updateProfileInfo(action.payload.profileInfo, action.payload.token)
      .map((response) => ({
        type: "UPDATE_PROFILE_INFO_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("UPDATE_PROFILE_INFO_ABORTED"))
      .defaultIfEmpty({ type: "UPDATE_PROFILE_INFO_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "UPDATE_PROFILE_INFO_ERROR", error })
      )
      .startWith({
        type: "UPDATE_PROFILE_INFO_PENDING",
        payload: action.payload,
      })
  );

export const validateUserHandshake = (action$) =>
  action$.ofType("VALIDATE_USER_HANDSHAKE").mergeMap((action) =>
    api
      .validateUserHandshake(action.payload.handshake, action.payload.token)
      .map((response) => ({
        type: "VALIDATE_USER_HANDSHAKE_SUCCESS",
        payload: response.response,
      }))
      .takeUntil(action$.ofType("VALIDATE_USER_HANDSHAKE_ABORTED"))
      .defaultIfEmpty({ type: "VALIDATE_USER_HANDSHAKE_CANCELED" })
      .catch((error) =>
        getErrorResponse({ type: "VALIDATE_USER_HANDSHAKE_ERROR", error })
      )
      .startWith({
        type: "VALIDATE_USER_HANDSHAKE_PENDING",
        payload: action.payload,
      })
  );
