import React, { useEffect, useState } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { simpleNotification } from "../../../../../utils/notifications";

import Assets from "./Assets";
import DeleteModal from "./DeleteModal";
import Gallery from "./Gallery";
import General from "./General";
import Loading from "../../../../../global/components/loading/Loading";
import NotFound from "../../../../not-found/NotFound";
import Social from "./Social";

import "./ProductEdit.scss";
import { deleteProduct } from "../../../../../utils/apiv2";

const defaultForm = {
  id: "",
  name: "",
  slug: "",
  nameInTwitch: "",
  // nameInYoutube: '',
  price: 0,
  salePrice: 0,
  saleEndDate: null,
  status: false,
  sponsorType: "",
  developer: "",
  publisher: "",
  releaseDate: null,
  // isPrivate: false,
  isOnSale: false,
  displayOnNoizStore: false,
  storeDescription: "",
  gallery: [],
  cover: "",
  coverPortrait: "",
  logo: "",
  developerLogo: "",
  video: "",
  description: "",
  headlineVideo: "",
  headline: "",
  gamePageURL: "",
  platforms: [],
  categories: [],
  socialNetworks: [],
};

const ProductEdit = (props) => {
  const {
    brandUser: {
      games: {
        data: { games: products = [] },
        errors: { hasError, message: errorMessage },
        isLoading: gamesLoading,
        isUpdating: gamesUpdating,
      },
      preSignedUrl,
      user: { id: userId },
    },
    brandUserUploadImageUrl,
    currentProduct,
    getAllPlatforms,
    platforms: { data: platforms = [] },
    postBrandGame,
    putBrandGame,
    tab,
    token,
  } = props;

  const isLoading = gamesLoading;

  const { t } = useTranslation("portalLeft");
  const [deleteModal, setDeleteModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [productDeleted, setProductDeleted] = useState(false);

  useEffect(() => {
    try {
      getAllPlatforms(token);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const foundProduct = products?.find(
      (p) => p.id.toString() === currentProduct
    );
    if (foundProduct) {
      setForm(foundProduct);
      setLoad(true);
    }
  }, [products]);

  useEffect(() => {
    if (currentProduct) {
      const foundProduct = products?.find(
        (p) => p.id.toString() === currentProduct
      );
      if (foundProduct) {
        foundProduct.status = foundProduct.status === "active";
        setForm(foundProduct);
        setLoad(true);
      } else {
        setLoad(false);
      }
    } else {
      setLoad(true);
    }
  }, [currentProduct]);

  useEffect(() => {
    if (hasError && !isLoading) {
      setLoad(false);
      simpleNotification({
        level: "error",
        title: t("error"),
        message: errorMessage,
      });
    }
  }, [hasError]);

  useEffect(() => {
    if (!hasError) {
      const foundProduct = products?.find((p) => p.name === form.name);

      if (foundProduct) {
        simpleNotification({
          level: "success",
          title: t("success"),
          message: `${t("product")} ${
            currentProduct ? t("updated") : t("created")
          } ${t("successfully")}`,
        });

        if (!currentProduct) {
          window.location.replace(
            `/portal/?tab=${tab}&component=ProductEdit&currentProduct=${foundProduct.id}`
          );
        }
      }
    }
  }, [gamesUpdating, gamesLoading]);

  const today = new Date();
  const activeQuests =
    form?.quests?.filter(
      (q) =>
        new Date(q.startDateTime) <= today && new Date(q.endDateTime) > today
    ) || [];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateProductForm(form);

    setErrors(validationErrors);

    if (validationErrors?.length === 0) {
      const data = { ...form };

      data.platforms = data?.platforms?.map((platform) => platform.id);
      data.status = data.status ? "active" : "disabled";

      if (currentProduct) {
        putBrandGame(data, token);
      } else {
        data.owner = userId;
        postBrandGame(data, token);
      }
    } else {
      simpleNotification({
        level: "error",
        title: t("error"),
        message: t("fillError"),
      });
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const resp = await deleteProduct(currentProduct);
      if (resp.status === 200) {
        setProductDeleted(true);
        simpleNotification({
          type: "success",
          title: t("success"),
          message: t("productDeleted"),
        });
        window.location.replace("/portal");
      }
    } catch (error) {
      simpleNotification({
        level: "error",
        title: `${t("error")}`,
        message: `${errorMessage}`,
      });
    }
  };

  const handleFormChange = (name, value) => {
    if (name) {
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (errors.includes(name)) {
      setErrors(errors.filter((error) => error !== name));
    }
  };

  const PageSelector = ({ value, text, fields }) => {
    const errorPresent = fields.some((e) => errors.includes(e));
    const fill = page >= value;

    return (
      <div className="ProductEdit__Pages_Page" onClick={() => setPage(value)}>
        <div className="Product_Navigation">
          <div
            className={classnames(
              "Product_Navigation_Bouy",
              errorPresent ? "ErrorPresent" : fill && "Fill"
            )}
          />
        </div>
        <div
          className={classnames(
            "ProductEdit__Pages_Page_Text",
            errorPresent ? "ErrorPresent" : fill && "Fill"
          )}
        >
          {text}
        </div>
      </div>
    );
  };

  const Buttons = () => (
    <div className="ProductEdit__Buttons">
      {page === 4 && (
        <button
          className="ProductEdit__Buttons_Button Publish"
          onClick={handleSubmit}
        >
          {t("publishProduct")}
        </button>
      )}
      {page < 4 && (
        <>
          <button
            className="ProductEdit__Buttons_Button Save"
            onClick={handleSubmit}
          >
            {t("save")}
          </button>
          <button
            className="ProductEdit__Buttons_Button Continue"
            onClick={() => setPage(page + 1)}
          >
            {t("continue")}
          </button>
        </>
      )}
    </div>
  );

  const formArgs = {
    form,
    handleFormChange,
    errors,
  };

  const platformOptions = platforms.reduce((acc, ele) => {
    const { baseService, platform } = ele;

    if (!acc[baseService]) {
      acc[baseService] = [];
    }

    acc[baseService].push({ value: ele, name: platform, attribute: "id" });

    return acc;
  }, {});

  // TODO: Need to address products that are not games and its associated platforms for selection

  return (
    <div className="ProductEdit__Wrapper">
      {isLoading && !deleteModal && <Loading />}
      {!isLoading && !load && !deleteModal && (
        <NotFound
          home={`/portal?component=default&tab=${tab}`}
          buttonText={t("back")}
        />
      )}
      {productDeleted && !!deleteModal && <Loading />}
      {!productDeleted && !!deleteModal && load && (
        <DeleteModal
          deleteFn={handleDelete}
          loading={isLoading}
          open={deleteModal}
          setOpen={setDeleteModal}
          secondaryStatement={!!activeQuests?.length}
        />
      )}
      {!isLoading && load && !productDeleted && (
        <div className="ProductEdit">
          <div className="ProductEdit__Header">
            {`${currentProduct ? t("edit") : t("addNew")} ${t("product")}`}
            {!!currentProduct && (
              <div
                className="ProductEdit__Delete"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                {t("delete")}
              </div>
            )}
          </div>
          <div className="ProductEdit__Pages">
            <div className="ProductEdit__Pages_Bar" />
            <PageSelector
              value={1}
              text={t("general")}
              fields={[
                "name",
                "nameInTwitch",
                "sponsorType",
                "description",
                "developer",
                "publisher",
                "status",
                "releaseDate",
                "platforms",
                // 'isPrivate',
              ]}
            />
            <PageSelector value={2} text={t("gallery")} fields={["gallery"]} />
            <PageSelector
              value={3}
              text={t("assets")}
              fields={["cover", "coverPortrait", "logo", "developerLogo"]}
            />
            <PageSelector
              value={4}
              text={t("social")}
              fields={[
                "gamePageURL",
                "headline",
                "headlineVideo",
                "categories",
                "socialNetworks",
              ]}
            />
          </div>
          <div className="ProductEdit__Content">
            {page === 1 && (
              <General {...formArgs} platformOptions={platformOptions} />
            )}
            {page === 2 && (
              <Gallery
                handleFormChange={handleFormChange}
                gallery={form.gallery}
                preSignedUrl={preSignedUrl}
                brandUserUploadImageUrl={brandUserUploadImageUrl}
                token={token}
              />
            )}
            {page === 3 && (
              <Assets
                {...formArgs}
                handleFormChange={handleFormChange}
                preSignedUrl={preSignedUrl}
                brandUserUploadImageUrl={brandUserUploadImageUrl}
                token={token}
              />
            )}
            {page === 4 && <Social {...formArgs} />}
          </div>
          <Buttons />
        </div>
      )}
    </div>
  );
};

const validateProductForm = (form) => {
  const errors = [];

  if (!form.name || !form.name.trim()) {
    errors.push("name");
  }

  // if (!form.status) {
  //   errors.push('status');
  // }

  if (!form.sponsorType || !form.sponsorType.trim()) {
    errors.push("sponsorType");
  }

  // if (!form.developer || !form.developer.trim()) {
  //   errors.push('developer');
  // }

  // if (!form.publisher || !form.publisher.trim()) {
  //   errors.push('publisher');
  // }

  // if (!form.releaseDate) {
  //   errors.push('releaseDate');
  // }

  // if (!form.cover || !form.cover.trim()) {
  //   errors.push('cover');
  // }

  // if (!form.coverPortrait || !form.coverPortrait.trim()) {
  //   errors.push('coverPortrait');
  // }

  // if (!form.logo || !form.logo.trim()) {
  //   errors.push('logo');
  // }

  // if (!form.developerLogo || !form.developerLogo.trim()) {
  //   errors.push('developerLogo');
  // }

  if (!form.description || !form.description.trim()) {
    errors.push("description");
  }

  // if (!form.headlineVideo || !form.headlineVideo.trim()) {
  //   errors.push('headlineVideo');
  // }

  // if (!form.headline || !form.headline.trim()) {
  //   errors.push('headline');
  // }

  // if (!form.gamePageURL || !form.gamePageURL.trim()) {
  //   errors.push('gamePageURL');
  // }

  if (
    form.sponsorType === "game" &&
    (!form.nameInTwitch || !form.nameInTwitch.trim())
  ) {
    errors.push("nameInTwitch");
  }

  return errors;
};

export default ProductEdit;
