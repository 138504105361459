import React from "react";
import {
  Paper,
  Modal,
  Backdrop,
  Fade,
  Button,
  Grid,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { simpleNotification } from "../../../../utils/notifications";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    boxShadow: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  paper: {
    backgroundColor: "#212227",
    boxShadow: theme.shadows[5],
    color: "white",
    width: "480px",
    maxWidth: "90%",
  },
  topMast: {
    backgroundColor: "#313337",
    padding: "20px 40px",
  },
  mainContainer: {
    width: "100%",
    height: "100%",
    padding: "40px 40px",
  },
  textField: {
    boxShadow: "none !important",
    "-webkit-appearance": "none",
  },
  errorButton: {},
  confirmButton: {
    backgroundColor: "green",
  },
  buttonGrid: {
    width: "100%",
    marginTop: "30px",
  },
  inputBox: {
    lineHeight: 2,
  },
  warning: {
    color: "red",
    marginTop: "20px",
  },
  openButton: {
    color: "white",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    padding: "5px 10px",
  },
}));

export default function RequirementModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const { title, handleSubmit, selectedCheckboxesLength, useMaterialUI } =
    props;
  const { t } = useTranslation("requirementModal");

  const handleOpen = () => {
    if (selectedCheckboxesLength <= 0) {
      simpleNotification({
        level: "warning",
        title: t("warning"),
        message: t("warningMessage"),
      });
      return;
    }
    setOpen(true);
    setReason("");
  };

  const handleClose = () => {
    setOpen(false);
    setReason("");
  };

  // Approved primary button styling
  let buttonProps = {};
  if (title === "Approve") {
    buttonProps = {
      variant: "contained",
      color: "primary",
    };
  }

  const btn = useMaterialUI ? (
    <Tooltip
      title={`${title} ${t("selectedRequirements")}`}
      {...buttonProps}
      placement="top"
    >
      <Button {...useMaterialUI} onClick={handleOpen}>
        {title} {t("selected")}
      </Button>
    </Tooltip>
  ) : (
    <button
      type="button"
      className={classes.openButton}
      {...buttonProps}
      onClick={handleOpen}
    >
      {title} {t("selected")}
    </button>
  );

  return (
    <div className="RequirementModal">
      {btn}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Grid
              xs={12}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid
                container
                xs={12}
                justify="space-around"
                align="flex-start"
                direction="column"
                className={classes.mainContainer}
              >
                <Grid className="RejectReason--input">
                  <TextField
                    id="reason"
                    label="Note"
                    type="text"
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    boxShadow={0}
                    className={`${classes.textField}`}
                  />
                </Grid>
                <Grid
                  container
                  className={classes.buttonGrid}
                  xs={12}
                  direction="row"
                  width="100%"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleSubmit(reason);
                        handleClose();
                      }}
                    >
                      {title}
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      onClick={handleClose}
                      className={classes.errorButton}
                    >
                      {t("cancel")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}
