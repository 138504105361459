import React, { useEffect } from "react";
import { ParallaxLayer } from "../../../custom_modules/react-spring/src/renderprops/addons/index";
import useOnScreen from "../useOnScreen";
import "./BrandServiceSlides.scss";
import computer from "../assetsV2/insight-dashboard-macbook-mockup.png";
import niceLogo from "../assetsV2/nice-logo.png";
import datAngleBox from "../assetsV2/frame.svg";
import { fadeInTop, fadeInLeft, rotateIn } from "./animations/allTransitions";
import { useTranslation } from "react-i18next";

// Little helpers ...

const CNice = (props) => {
  const { t } = useTranslation();
  const { pOffset, isDesktop, layoutFactor = 1, offsetAdjust = 0 } = props;
  const ref = React.createRef();
  const [debounce, setDebounce] = React.useState(true);

  const onScreen = useOnScreen(ref, "100px", 0.5);
  useEffect(() => {
    if (onScreen && debounce) {
      fadeInLeft("brand-computer");
      fadeInTop("stacked-nice-text");
      rotateIn("rotate-brand-svg");
      setDebounce(false);
    }
  });

  return (
    <>
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        className="nice-slide-c-container"
      >
        <div ref={ref} />
        <div className="brand-computer" style={{ opacity: 0 }}>
          <img src={computer} alt="brand dashboard" className="c-computer" />
        </div>
        <div className="stacked-nice-text" style={{ opacity: 0 }}>
          <img src={niceLogo} alt="Nice Logo" />
          <div>{t("stackedNiceText")}</div>
        </div>
      </ParallaxLayer>
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        className="brand-angle-svg"
      >
        {isDesktop && (
          <img
            style={{ opacity: 0 }}
            src={datAngleBox}
            className="rotate-brand-svg"
            alt="angle svg"
          />
        )}
      </ParallaxLayer>
    </>
  );
};

export default CNice;
