/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */

import React from "react";
import StripeCheckout from "react-stripe-checkout";
import "react-id-swiper/src/styles/css/swiper.css";
import { simpleNotification } from "../../../utils/notifications";
import "./Checkout.scss";
import { withTranslation } from "react-i18next";
import { stripePayment } from "../../../utils/apiv2";

const CURRENCY = "USD";

const fromDollarToCent = (amount) =>
  Math.round(parseFloat(parseFloat(amount) * 100));
const roundingToString = (number) => parseFloat(number).toFixed(2);

class ConnectCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  successPayment = () => {
    const { gameSlug, questSlug, username } = this.props.params;
    this.props.router.push(`/buy/${gameSlug}/${questSlug}/${username}/success`);
  };

  errorPayment = () => {
    const { t } = this.props;
    simpleNotification({
      level: "error",
      title: t("warning"),
      message: t("errorPaymentMessage"),
    });
    const { gameSlug, questSlug, username } = this.props.params;
    this.props.router.push(`/buy/${gameSlug}/${questSlug}/${username}`);
  };

  postMethod = (amount, description, slug) => async (token) => {
    try {
      await stripePayment({
        description,
        source: token.id,
        currency: CURRENCY,
        amount: fromDollarToCent(amount),
        referrals: localStorage.getItem("referrals"),
        slug,
        tokenEmail: token.owner ? token.owner.email : token.email,
      });
      this.successPayment();
    } catch (error) {
      this.errorPayment();
    }
  };

  render() {
    const { name, description, amount, publicKey, slug, t } = this.props;
    return (
      <StripeCheckout
        name={name}
        description={description}
        label={`${t("buyNowFor")} $${roundingToString(amount)}`}
        image="/apple-touch-icon.png"
        panelLabel="Pay"
        amount={fromDollarToCent(amount)}
        token={this.postMethod(amount, description, slug)}
        currency={CURRENCY}
        stripeKey={publicKey}
      />
    );
  }
}

export default withTranslation("connectCheckout")(ConnectCheckout);
