import React from "react";
import { withTranslation } from "react-i18next";

import classNames from "classnames";
import { localeURI } from "../../utils/util";
import twitch_logo from "../images/modal_welcome_1.png";
import twitter_logo from "../images/white_twitter.png";
import youtube_logo from "../images/modal_welcome_2.png";
import "./ConnectPlatforms.scss";

class ConnectPlatforms extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      connectTwitch: 0,
      connectYoutube: 0,
      connectTwitter: 0,
    };
  }

  connectTwitch = () => {
    const { user } = this.props.auth;
    if (
      user.connectedAccounts.twitch.id ||
      user.connectedAccounts.twitch.username
    ) {
      return;
    }
    window.location.href = localeURI("/api/v1/auth/twitch");
    if (this.state.connectTwitch === 2) {
      this.setState({
        connectTwitch: 0,
      });
    }
  };

  connectTwitter = () => {
    const { user } = this.props.auth;
    if (
      user.connectedAccounts.twitter &&
      (user.connectedAccounts.twitter.id ||
        user.connectedAccounts.twitter.username)
    ) {
      return;
    }
    window.location.href = localeURI("/api/v1/auth/twitter");
    if (this.state.connectTwitter === 2) {
      this.setState({
        connectTwitter: 0,
      });
    }
  };

  disconnectTwitch = () => {
    const { token, user } = this.props.auth;
    this.props.disconnectTwitch(token, user);
  };

  connectYoutube = () => {
    const { user } = this.props.auth;
    if (
      user.connectedAccounts.youtube &&
      (user.connectedAccounts.youtube.id ||
        user.connectedAccounts.youtube.username)
    ) {
      return;
    }
    window.location.href = localeURI("/api/v1/auth/youtube");
    if (this.state.connectYoutube === 2) {
      this.setState({
        connectYoutube: 0,
      });
    }
  };

  disconnectYoutube = () => {
    const { token, user } = this.props.auth;
    this.props.disconnectYoutube(token, user);
  };

  render() {
    const { t } = this.props;
    const { user } = this.props.auth;

    const twitterUser = user.connectedAccounts.twitter
      ? user.connectedAccounts.twitter.username
      : null;
    const twitterId = user.connectedAccounts.twitter
      ? user.connectedAccounts.twitter.id
      : null;

    const hasTwitter = !!user.connectedAccounts.twitter;

    return (
      <div className="ConnectPlatforms">
        <div className="ConnectPlatforms--squares">
          <div
            onClick={this.connectYoutube.bind(this)}
            className={classNames(
              "ConnectPlatforms--square ConnectPlatforms--square1",
              { not_connected: this.state.connectYoutube === 0 },
              { connecting: this.state.connectYoutube === 1 },
              {
                connected:
                  user.connectedAccounts.youtube.id ||
                  user.connectedAccounts.youtube.username,
              }
            )}
            onMouseOver={() => {
              this.setState({ connected_hovered_youtube: true });
            }}
            onMouseLeave={() => {
              this.setState({ connected_hovered_youtube: false });
            }}
            onFocus={() => {}}
            aria-hidden
          >
            <div className="ConnectPlatforms--border" />
            {!this.state.connected_hovered_youtube && (
              <div className="ConnectPlatforms--check">
                <i className="fa fa-check" />
              </div>
            )}
            <div className="ConnectPlatforms--inner">
              <div className="ConnectPlatforms--inner1">
                <img className="responsive" src={youtube_logo} alt="youtube" />
              </div>
              <div className="ConnectPlatforms--inner2">
                <button className="ConnectPlatforms--disconnected">
                  {t("connect")}
                </button>
                <div className="ConnectPlatforms--connecting">
                  {t("connectingYourTwitch")}
                  <br />
                  {t("accountToNOIZ")}
                </div>
                <button className="ConnectPlatforms--connected desktop">
                  {this.state.connected_hovered_youtube
                    ? `${t("connectedAs")}  ${
                        user.connectedAccounts.youtube.username
                      }`
                    : `${t("connected")} `}
                </button>
                <button
                  className={classNames(
                    "ConnectPlatforms--connected",
                    "mobile",
                    {
                      connected:
                        user.connectedAccounts.youtube.id ||
                        user.connectedAccounts.youtube.username,
                    }
                  )}
                >
                  {t("connectedAs")} {""}
                  {user.connectedAccounts.youtube.username}
                </button>
              </div>
            </div>
          </div>
          <div className="ConnectPlatforms--between" />

          <div
            onClick={this.connectTwitch.bind(this)}
            className={classNames(
              "ConnectPlatforms--square ConnectPlatforms--square2",
              { not_connected: this.state.connectTwitch === 0 },
              { connecting: this.state.connectTwitch === 1 },
              {
                connected:
                  user.connectedAccounts.twitch.id ||
                  user.connectedAccounts.twitch.username,
              }
            )}
            onMouseOver={() => {
              this.setState({ connected_hovered_twitch: true });
            }}
            onMouseLeave={() => {
              this.setState({ connected_hovered_twitch: false });
            }}
            onFocus={() => {}}
            aria-hidden
          >
            <div className="ConnectPlatforms--border" />
            {!this.state.connected_hovered_twitch && (
              <div className="ConnectPlatforms--check">
                <i className="fa fa-check" />
              </div>
            )}
            <div className="ConnectPlatforms--inner">
              <div className="ConnectPlatforms--inner1">
                <img className="responsive" src={twitch_logo} alt="twitch" />
              </div>
              <div className="ConnectPlatforms--inner2">
                <button className="ConnectPlatforms--disconnected">
                  {t("connect")}
                </button>
                <div className="ConnectPlatforms--connecting">
                  {t("connectingYourTwitch")}
                  <br />
                  {t("accountToNOIZ")}
                </div>
                <button className="ConnectPlatforms--connected desktop">
                  {this.state.connected_hovered_twitch
                    ? `${t("connectedAs")}  ${
                        user.connectedAccounts.twitch.username
                      }`
                    : `${t("connected")} `}
                </button>
                <button
                  className={classNames(
                    "ConnectPlatforms--connected",
                    "mobile",
                    {
                      connected:
                        user.connectedAccounts.twitch.id ||
                        user.connectedAccounts.twitch.username,
                    }
                  )}
                >
                  {t("connectedAs")} {""}
                  {user.connectedAccounts.twitch.username}
                </button>
              </div>
            </div>
          </div>
          <div className="ConnectPlatforms--between" />

          <div
            onClick={this.connectTwitter.bind(this)}
            className={classNames(
              "ConnectPlatforms--square ConnectPlatforms--square3",
              { not_connected: this.state.connectTwitter === 0 },
              { connecting: this.state.connectTwitter === 1 },
              { connected: hasTwitter && (twitterId || twitterUser) }
            )}
            onMouseOver={() => {
              this.setState({ connected_hovered_twitter: true });
            }}
            onMouseLeave={() => {
              this.setState({ connected_hovered_twitter: false });
            }}
            onFocus={() => {}}
            aria-hidden
          >
            <div className="ConnectPlatforms--border" />
            {!this.state.connected_hovered_twitter && (
              <div className="ConnectPlatforms--check">
                <i className="fa fa-check" />
              </div>
            )}
            <div className="ConnectPlatforms--inner">
              <div className="ConnectPlatforms--inner1">
                <img
                  className="responsive"
                  style={{ maxHeight: "87px", maxWidth: "300px" }}
                  src={twitter_logo}
                  alt="Twitter Logo"
                />
              </div>
              <div className="ConnectPlatforms--inner2">
                <button className="ConnectPlatforms--disconnected">
                  {t("connect")}
                </button>
                <div className="ConnectPlatforms--connecting">
                  {t("connectingYourTwitter")}
                  <br />
                  {t("accountToNOIZ")}
                </div>
                <button className="ConnectPlatforms--connected desktop">
                  {this.state.connected_hovered_twitter
                    ? `${t("connectedAs")}  ${hasTwitter && twitterUser}`
                    : `${t("connected")} `}
                </button>
                <button
                  className={classNames(
                    "ConnectPlatforms--connected",
                    "mobile",
                    { connected: hasTwitter && (twitterId || twitterUser) }
                  )}
                >
                  {t("connectedAs")} {""}
                  {hasTwitter && twitterUser}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConnectPlatforms.propTypes = {};

export default withTranslation("connectPlatforms")(ConnectPlatforms);
