import React from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import './BrandServiceSlides.scss';
import ContactForm from './ContactForm';
import { useTranslation } from 'react-i18next';
// Little helpers ...

const ContactUs = (props) => {
  const { pOffset, layoutFactor = 1, offsetAdjust = 0 } = props;
  const { t } = useTranslation("brandServices");

  return (
    <>
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        speed={0}
        className="contact-us-container"
        id="contact-us-container"
      >
        <ContactForm />
        <div className="grey-brand-body-text brand-vert-stack cascade-fade-l fade-in-prep">
          <div className="brand-title">{t("contactUs.title")}</div>
          <div>{t("contactUs.describe")}</div>
        </div>
      </ParallaxLayer>
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        speed={0.8}
        className="brand-angle-svg"
      />
    </>
  );
};


export default ContactUs;

