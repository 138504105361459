import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Icon from "../../../../../global/images/icons/Icon";

import { SetViewContext } from "../.././PortalContext";
import { activateIntercom, textTruncate } from "../../../../../utils/functions";
import { useTranslation } from "react-i18next";

import "./PortraitCards.scss";

const dateOptions = { month: "2-digit", day: "2-digit" };
const maxStringLength = 30;
const returnProperTooltip = (str) => (str.length > maxStringLength ? str : "");

const PortraitBlankCard = ({ proper, editFn, isProduct = false }) => {
  const { t } = useTranslation("portalLeft");
  const lowerCaseProper = t(
    `${proper?.replace(proper[0], proper[0].toLowerCase())}`
  );
  return (
    <SetViewContext.Consumer>
      {({ setView, view }) => (
        <>
          {isProduct && (
            <div
              className="Portraits__Card PortraitBlank"
              onClick={() =>
                setView({
                  ...view,
                  component: `${proper}Edit`,
                })
              }
            >
              <div className="Portraits__Blank_Icon">
                {Icon({ color: "white", name: "plus" })}
              </div>
              <div className="Portraits__Blank_Text">
                {t("addNew")} {lowerCaseProper}
              </div>
            </div>
          )}
          {!isProduct && (
            <div
              className="Portraits__Card PortraitBlank"
              onClick={() => activateIntercom()}
            >
              <div className="Portraits__Blank_Icon">
                {Icon({ color: "white", name: "plus" })}
              </div>
              <div className="Portraits__Blank_Text">
                {t("addNew")} {lowerCaseProper}
              </div>
            </div>
          )}
        </>
      )}
    </SetViewContext.Consumer>
  );
};

const PortraitCardEdit = ({ setView, view, editFn, id }) => (
  <div
    className="Portraits__Card_Edit"
    onClick={() => setView({ ...view, ...editFn(id) })}
  >
    <Icon name="edit" />
  </div>
);

const PortraitCard = ({
  item: { id, img, name, quests, startDateTime, endDateTime },
  type,
  proper,
  editFn,
  detailFn,
}) => {
  const { t } = useTranslation("portalLeft");

  return (
    <SetViewContext.Consumer>
      {({ setView, view }) => (
        <div className="Portraits__Card PortraitCard">
          <div className="Portraits__Card_Image">
            <PortraitCardEdit
              setView={setView}
              view={view}
              editFn={editFn}
              id={id}
            />
            <img src={img} alt={name} />
          </div>
          <div className="Portraits__Card_Details">
            <Tooltip title={returnProperTooltip(name)} placement="top">
              <span className="Portraits__Card_Title">
                {textTruncate(name || "", maxStringLength)}
              </span>
            </Tooltip>
            <span className="Portraits__Card_Subtitle">
              {type === "products" && (
                <>
                  <div className="Portraits__Card_Subtitle_Icon">
                    {Icon({ color: "white", name: "scroll" })}
                  </div>
                  &nbsp;{quests.length} {t("quest")}
                  {quests.length !== 1 && "S"}
                </>
              )}
              {type === "quests" && (
                <>
                  {new Date(startDateTime).toLocaleDateString(
                    "en-US",
                    dateOptions
                  )}{" "}
                  -{" "}
                  {new Date(endDateTime).toLocaleDateString(
                    "en-US",
                    dateOptions
                  )}
                </>
              )}
            </span>
          </div>
          <div
            className="Portraits__Card_View"
            onClick={() => setView({ ...view, ...detailFn(id) })}
          >
            {t("view")}{" "}
            {t(`${proper?.replace(proper[0], proper[0].toLowerCase())}`)}
          </div>
        </div>
      )}
    </SetViewContext.Consumer>
  );
};

const PortraitCards = (props) => {
  const { t } = useTranslation("portalLeft");
  const {
    items,
    viewAllFn = () => null,
    showViewAll = true,
    auth,
    ...rest
  } = props;
  // const addQuestLength = 1;
  const content = [];
  const isProduct = props.type === "products" || props.type === "quests";
  items?.forEach((item) =>
    content.push(<PortraitCard key={item.id} item={item} {...rest} />)
  );

  // if (content.length < addQuestLength) {
  //   for (let i = content.length; i < addQuestLength -1; i++) {
  //     content.push(<PortraitBlankCard proper={rest.proper} editFn={rest.editFn} isProduct={isProduct} />);
  //   }
  // }

  content.push(
    <PortraitBlankCard
      key={"blank-key-card"}
      proper={rest.proper}
      editFn={rest.editFn}
      isProduct={isProduct}
    />
  );

  return (
    <div className="Portraits">
      <div className="Portraits__Title">
        <h2>
          {t(
            `${rest?.proper?.replace(
              rest.proper[0],
              rest.proper[0].toLowerCase()
            )}`
          )}
        </h2>
        {showViewAll && (
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <div
                className="Portraits__Title_View_All"
                onClick={() => setView(viewAllFn(rest.proper, view))}
              >
                <span>{t("viewAll")}</span>
                <i className="fa fa-chevron-right" />
              </div>
            )}
          </SetViewContext.Consumer>
        )}
      </div>
      <div className="Portraits__List">{content}</div>
    </div>
  );
};

export default PortraitCards;
