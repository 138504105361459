import React from "react";
import { htmlToPlainText } from "../../utils/functions";
import "./Quest.scss";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

const GameSection = (props) => {
  const {
    gameDescription = "",
    developer,
    // gamePageURL,
    genres,
    id,
    logo,
    publisher,
    questDescription = "",
    tagline,
    gameTitle = "",
    sponsorType,
    tabWidth = 1000,
  } = props;

  const { t } = useTranslation("gameSection");

  const changeText = window.innerWidth <= tabWidth;

  const isAGame = sponsorType === "game";

  const mainDesc = questDescription
    ? ReactHtmlParser(questDescription)
    : htmlToPlainText(gameDescription).trim();

  return (
    <>
      <div className="GameSection">
        <div className="gameImage">
          <img src={logo} alt="client logo" />
          <button
            className="CTAButton__lightGrey button-hover-glow"
            onClick={() => window.open(`/game/${id}`, "_blank")}
          >
           {t("learnMore")}
          </button>
        </div>
        <div className="gameDesc">
          <h1>{tagline || gameTitle}</h1>
          <span
            className="subtitle-text make-visible"
            style={{ fontSize: `${changeText ? "calc(6px + 1vw)" : "13px"}` }}
          >
            {mainDesc}
          </span>
        </div>
      </div>
      <div className="GameDescription">
        {!isAGame && (
          <div className="gameItem">
            <span className="subtitle">{t("sponsor")}</span>
            <span className="subtitle-text make-visible">{gameTitle}</span>
          </div>
        )}

        {isAGame && developer && (
          <div className="gameItem">
            <span className="subtitle">{t("gameDeveloper")}</span>
            <span className="subtitle-text make-visible">{developer}</span>
          </div>
        )}
        {isAGame && publisher && (
          <div className="gameItem">
            <span className="subtitle">{t("gamePublisher")}</span>
            <span className="subtitle-text make-visible">{publisher}</span>
          </div>
        )}
        {isAGame && genres.length > 0 && (
          <div className="gameItem">
            <span className="subtitle">{t("gameGenre")}</span>
            <span className="subtitle-text make-visible">
              {genres.map((g) => g.name).join(", ")}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default GameSection;
