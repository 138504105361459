/* eslint max-len: ["error", { "code": 800 }] */
import React from "react";
import "./CareersPost.scss";
import { withTranslation } from "react-i18next";

class CommunityManager extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    const { t } = this.props;
    return (
      <section className="CareersPost">
        <section className="CareersPost__container">
          <section className="CareersPostSummary">
            <section className="CareersPostSummary__content">
              <section className="PostingItem">
                <a
                  className="PostingItem__apply-btn"
                  href="mailto:angie@noiz.gg,sandy@noiz.gg"
                >
                  {t("applyJob")} <i className="fa fa-envelope" />
                </a>
                <section className="PostingItem__wrapper">
                  <h4 className="PostingItem__title">{t("title")}</h4>
                  <section className="PostingItem__categories">
                    <span className="PostingItem__categories--location">
                      {t("sub_title")}
                    </span>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section className="CareersPostDetails">
            <section className="CareersPostDetails__content">
              <div>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    {t("section_1")}&nbsp;
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>{t("section_2")}</span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>{t("section_3")}</span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>{t("section_4")}</span>
                </p>
                <p>
                  <h4>{t("section_5.title")}</h4>
                </p>
                <ul>
                  {Array.from({ length: 9 }).map((item, index) => (
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        {t(`section_5.content.${index}`)}
                      </span>
                    </li>
                  ))}
                </ul>
                <p>
                  <h4>{t("section_6.title")}</h4>
                </p>
                <ul>
                  {Array.from({ length: 12 }).map((item, index) => (
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        {t(`section_6.content.${index}`)}
                      </span>
                    </li>
                  ))}
                </ul>
                <p>
                  <em>{t("section_7")}</em>
                </p>
              </div>
              <p className="CareersPostDetails__content--cta">
                {t("email")} <a href="mailto:angie@noiz.gg">angie@noiz.gg</a>
              </p>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default withTranslation("communityManager")(CommunityManager);
