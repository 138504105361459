/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router";
import { without, find } from "lodash";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import Countdown from "react-countdown-now";
import classNames from "classnames";

import {
  getCSVQuest as getCSV,
  getKeys,
} from "../../../../utils/downloadReports";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// Utils
import {
  getSorting,
  stableSort,
  getCompletionStatus,
  getNumApprovedRequirements,
  getQuestRequirements,
  getStreamTimeCompleted,
  keyDistributionStepExists,
  parseLinks,
} from "../../../../utils/functions";

// Custom Modal
import CustomLinkModal from "./CustomLinkModal";

// Styles
import "./QuestDetailsTable.scss";
import { withTranslation } from "react-i18next";

const TableHeaderCell = withStyles((theme) => ({
  head: {
    color: "#9b9b9b",
    fontWeight: "400",
    letterSpacing: "1.2px",
    fontSize: "14px",
  },
}))(TableCell);

class QuestDetailsTable extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      orderBy: "dateAccepted",
      order: "asc",
      showingElements: [],
      usersTab: 0,
    };
  }

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    const desc = this.state.order === "desc" && this.state.orderBy === property;
    this.setState({
      order: desc ? "asc" : "desc",
      orderBy: property,
    });
  };

  // Determine user's completion status; return element to display
  getCompletionElement = (user) => {
    const { userQuests, quest, t } = this.props;
    const userQuest = find(userQuests, (o) => o.user.id === user.id);
    const { timeCompleted, untilDateTime, status } = getCompletionStatus(
      quest,
      userQuest,
      user.timePlaying
    );

    if (status === "C") {
      return (
        <TableCell align="center">
          {" "}
          <span className="success">C</span>
        </TableCell>
      );
    } else if (status === "P") {
      return (
        <TableCell align="center">
          <span className="info">P</span>
        </TableCell>
      );
    } else if (!timeCompleted) {
      return (
        <TableCell align="center">
          <Countdown
            date={untilDateTime}
            daysInHours
            renderer={({ hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span className="error">F</span>;
              }
              return (
                <span>
                  {hours}:{minutes}:{seconds} {t("leftToStream")}
                </span>
              );
            }}
          />
        </TableCell>
      );
    }
    return (
      <TableCell align="center">
        <span className="error">F</span>
      </TableCell>
    );
  };

  // Open requirements table for user; set state.showingElements
  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [...showingElements, elementId],
      });
    } else {
      this.setState({
        showingElements: [...without(showingElements, elementId)],
      });
    }
  };

  handleComplete = (user, quest, requirement, content) => {
    const { id, title, type, subtype } = requirement;

    const reqToUpdate = {
      userId: user.id,
      questId: quest.id,
      reqId: id,
      title,
      type,
      subtype,
      status: "completed",
      override: true,
      overriddenByUser: this.props.auth.user.id,
    };

    if (content) reqToUpdate.content = content;
    else reqToUpdate.content = "Admin Override";

    const data = JSON.stringify(reqToUpdate);

    this.props.postCCRequirement(data, this.props.auth.token);
  };

  // Sort & define user rows based on quest type & sorting state; return elements to display
  getUserRows = (users, questType, platform, keyDistribute) => {
    const { quest, userQuests } = this.props;
    const { usersTab, order, orderBy } = this.state;
    const sortedUsers = stableSort(users, getSorting(order, orderBy));
    const numberRequirements =
      getQuestRequirements(quest, true).length + 1 || 1;

    if (questType === "tiered" || questType === "tiered-one-time") {
      return sortedUsers.map((user) => {
        let tier = "(?)";
        if (user.tier === "viewer") {
          tier = "V";
        } else if (user.tier === "member") {
          tier = "M";
        }

        const username = platform === "twitch" ? user.twitchUsername : "NA";
        const userQuest = find(userQuests, (o) => o.user.id === user.id);
        let sumCompletedRequirements = getNumApprovedRequirements(
          quest,
          userQuest
        );
        const completedStreamTime = getStreamTimeCompleted(
          quest,
          user.timePlaying
        );
        if (completedStreamTime) sumCompletedRequirements += 1;

        return (
          <React.Fragment>
            <TableRow hover tabIndex={-1} key={user.id}>
              <TableCell align="center">
                {user.user ? user.user.username : "-"}
              </TableCell>
              <TableCell align="center">{username}</TableCell>
              <TableCell align="center">
                {user.paymentAmount ? `$${user.paymentAmount}-${tier}` : "$0"}
              </TableCell>
              <TableCell align="center">
                {user.averageViewers ? Math.floor(user.averageViewers) : "0"}
              </TableCell>
              <TableCell align="center">
                {user.timePlaying ? user.timePlaying : "0"}
              </TableCell>
              {this.getCompletionElement(user)}
              <TableCell align="center">
                {user.dateAccepted
                  ? moment(user.dateAccepted).format("MM/DD/YY hh:mm a")
                  : "-"}
              </TableCell>
              <TableCell align="center">
                <div style={{ display: "flex" }}>
                  {this.props.getEditUserModal(user)}
                  {this.props.getEditTierModal(quest, users, user)}
                  {sumCompletedRequirements >= numberRequirements - 1 && (
                    <Tooltip title="Edit reputation responses" placement="top">
                      <button
                        className="small-btn"
                        onClick={this.props.openCongenialityModal.bind(
                          this,
                          user.id
                        )}
                      >
                        <i className="fa fa-address-card" />
                      </button>
                    </Tooltip>
                  )}
                  {numberRequirements > 0 && (
                    <React.Fragment>
                      {sumCompletedRequirements}/{numberRequirements}&nbsp;
                    </React.Fragment>
                  )}
                  {this.state.showingElements.indexOf(user.username) === -1 && (
                    <button
                      className="small-btn"
                      onClick={this.toggleElementInfo.bind(this, user.username)}
                    >
                      +
                    </button>
                  )}
                  {this.state.showingElements.indexOf(user.username) !== -1 && (
                    <button
                      className="small-btn open"
                      onClick={this.toggleElementInfo.bind(this, user.username)}
                    >
                      -
                    </button>
                  )}
                </div>
              </TableCell>
              {usersTab === 0 &&
                keyDistribute &&
                (!user.key || user.key === "") && (
                  <TableCell>
                    <Tooltip title="Missing Key" placement="top">
                      <i style={{ color: "#FA5053" }} className="fa fa-key" />
                    </Tooltip>
                  </TableCell>
                )}
              {usersTab === 0 &&
                keyDistribute &&
                user.key &&
                user.key !== "" && <TableCell />}
            </TableRow>
            {this.state.showingElements.indexOf(user.username) !== -1 &&
              this.getUserRequirements(user, userQuest)}
          </React.Fragment>
        );
      });

      // General/default quest
    }
    return sortedUsers.map((user) => (
      <TableRow hover tabIndex={-1} key={user.id}>
        <TableCell align="center">
          {user.user ? user.user.username : "-"}
        </TableCell>
        <TableCell align="center">
          {user.link ? (
            <a href={user.link} target="_blank" rel="noopener noreferrer">
              {" "}
              {user.link}{" "}
            </a>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="center">
          {user.dateAccepted
            ? moment(user.dateAccepted).format("MM/DD/YY - hh:mm a")
            : "-"}
        </TableCell>
      </TableRow>
    ));
  };

  // Create user requirements table; return element to display
  getUserRequirements = (user, userQuest) => {
    const { quest, t } = this.props;
    const completed = quest.requiredStreamTimeMinutes <= user.timePlaying;

    const requirements = getQuestRequirements(quest, true);

    return (
      <>
        <TableRow>
          <TableCell colSpan="8">
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell align="left">
                    {" "}
                    {t("markComplete")}{" "}
                  </TableHeaderCell>
                  <TableHeaderCell align="left">
                    {" "}
                    {t("requirement")}{" "}
                  </TableHeaderCell>
                  <TableHeaderCell align="left">
                    {" "}
                    {t("reason")}{" "}
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requirements &&
                  requirements.length > 0 &&
                  requirements.map((r) => {
                    const { type, subtype, approvalRequired } = r;

                    // Find user quest
                    const userQuestRequirements =
                      userQuest && userQuest.requirements
                        ? userQuest.requirements
                        : [];
                    const ur = find(
                      userQuestRequirements,
                      (o) => o.questReqId === r.id
                    );
                    const views = ur && ur.views ? ur.views : "n/a";

                    // Decide how to color code
                    const statusClass = classNames({
                      success: ur && ur.status === "completed",
                      error: ur && ur.status === "rejected",
                      warning:
                        !ur ||
                        (ur &&
                          ur.status !== "completed" &&
                          ur.status !== "rejected"),
                    });

                    // Format link-based submissions
                    let linkHTML = <React.Fragment />;

                    if (
                      ur &&
                      (ur.type === "submit-tweet" || ur.type === "submit-link")
                    ) {
                      if (!ur.content.toLowerCase().startsWith("http")) {
                        linkHTML = (
                          <Link
                            href={`https://${ur.content.toLowerCase()}`}
                            target="_blank"
                          >
                            {ur.content}
                          </Link>
                        );
                      } else if (ur.content) {
                        linkHTML = (
                          <Link
                            href={`${ur.content.toLowerCase()}`}
                            target="_blank"
                          >
                            {ur.content}
                          </Link>
                        );
                      }
                    }

                    // Create table cell for submission
                    let text = "";

                    if (ur) {
                      if (type === "submit-tweet") {
                        text = (
                          <TableCell>
                            <span className={statusClass}>
                              {t("twitterLink")}:&nbsp;{linkHTML}
                            </span>
                          </TableCell>
                        );
                      } else if (
                        type === "submit-link" &&
                        subtype &&
                        subtype === "vod"
                      ) {
                        text = (
                          <TableCell>
                            <span className={statusClass}>
                              {`${t("vodLink")}:`}&nbsp;{linkHTML}
                              {` - ${t("views")}: `}
                              {views}
                            </span>
                            {ur.status === "completed" ? (
                              <button
                                className="small-btn"
                                style={{ margin: "0 0 0 5px" }}
                                onClick={this.props.openVODModals.bind(
                                  this,
                                  user.id,
                                  ur.questReqId
                                )}
                              >
                                <i className="fa fa-address-card" />
                              </button>
                            ) : null}
                          </TableCell>
                        );
                      } else if (type === "submit-link") {
                        text = (
                          <TableCell>
                            <span className={statusClass}>
                              {subtype === "vod"
                                ? `${t("vodLink")}:`
                                : `${t("clipLink")}:`}
                              &nbsp;{linkHTML}
                            </span>
                          </TableCell>
                        );
                      } else if (type === "submit-text") {
                        text = (
                          <TableCell>
                            <span className={statusClass}>
                              {r.title}: {parseLinks(ur.content)}
                            </span>
                          </TableCell>
                        );
                      } else if (approvalRequired) {
                        text = (
                          <TableCell>
                            <span className={statusClass}>{`${t(
                              "checkboxSubmitted"
                            )}: ${r.title}`}</span>
                          </TableCell>
                        );
                      }
                    } else if (type === "submit-tweet") {
                      text = (
                        <TableCell>
                          <span className="warning">
                            <i>{t("twitterLinkPending")}</i>
                          </span>
                        </TableCell>
                      );
                    } else if (type === "submit-link") {
                      text = (
                        <TableCell>
                          <span className="warning">
                            <i>
                              {subtype === "vod"
                                ? t("vodLinkPending")
                                : t("clipLinkPending")}
                            </i>
                          </span>
                        </TableCell>
                      );
                    } else if (type === "submit-text") {
                      text = (
                        <TableCell>
                          <span className="warning">
                            <i>{t("textSubmissionPending")}</i>
                          </span>
                        </TableCell>
                      );
                    } else if (approvalRequired) {
                      text = (
                        <TableCell>
                          <span className="warning">
                            {t("checkboxPending")}
                          </span>
                        </TableCell>
                      );
                    }

                    // Create table cell for note
                    let noteHtml = <TableCell />;
                    if (ur && ur.note) {
                      noteHtml = (
                        <TableCell>
                          <span className={statusClass}>
                            <i>{ur.note}</i>
                          </span>
                        </TableCell>
                      );
                    }

                    // Create table cell for admin override button (if applicable)
                    let completeButton = <TableCell />;
                    if (!ur || (ur && ur.status !== "completed")) {
                      completeButton = (
                        <TableCell>
                          <CustomLinkModal
                            completeSelected={(link) =>
                              this.handleComplete(user, quest, r, link)
                            }
                            selectedCheckboxesLength={1}
                            {...(ur && ur.link
                              ? { existingLink: ur.link }
                              : {})}
                          />
                        </TableCell>
                      );
                    }

                    // else if (ur) {
                    //   completeButton = (
                    //     <TableCell>
                    //       <Tooltip title="Mark this requirement as 'Complete'" placement="top">
                    //         <button
                    //           className="small-btn"
                    //           onClick={() => this.handleComplete(user, quest, r, null)}
                    //         >
                    //           <i className="fa fa-check" />
                    //         </button>
                    //       </Tooltip>
                    //     </TableCell>
                    //   );
                    // }

                    return (
                      <TableRow>
                        {completeButton}
                        {text}
                        {noteHtml}
                      </TableRow>
                    );
                  })}

                {/* Stream time requirement */}
                {completed ? (
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <span className="success">
                        {t("streamedFor")} {quest.requiredStreamTimeMinutes}{" "}
                        {t("minutes")}.
                      </span>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>{this.props.getEditUserModal(user)}</TableCell>
                    <TableCell>
                      <span className="warning">
                        {t("streamedFor")} {quest.requiredStreamTimeMinutes}{" "}
                        {t("minutes")}.
                      </span>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )}

                {/* Promo link */}
                {userQuest.promoLink && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeaderCell colSpan="8" align="left">
                          {" "}
                          {t("promoLink")}{" "}
                        </TableHeaderCell>
                        <TableHeaderCell colSpan="8" align="left">
                          {" "}
                          {t("clicks")}
                        </TableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan="8">
                          <a
                            href={`http://${userQuest.promoLink}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {userQuest.promoLink}
                          </a>
                        </TableCell>
                        <TableCell colSpan="8">
                          {userQuest.promoLinkClicks}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
        {userQuest.leftQuestReason && (
          <TableRow>
            <TableCell colSpan="8">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell align="left">
                      {t("reasonForLeaving")}
                    </TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ padding: "0.67em" }}>
                    <TableCell colSpan="8">
                      {userQuest.leftQuestReason}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  // Define table headers based on quest type; return array of headers
  getTableHeaders = (questType, platform, keyDistribute) => {
    const { usersTab } = this.state;
    const { t } = this.props;

    let tableHeaders = [];

    switch (questType) {
      case "tiered":
      case "tiered-one-time":
        tableHeaders = [
          {
            id: "username",
            numeric: false,
            disablePadding: true,
            label: "Noiz",
          },
          {
            id: `${platform}Username`,
            numeric: false,
            disablePadding: true,
            label: platform.charAt(0).toUpperCase() + platform.slice(1),
          },
          {
            id: "paymentAmount",
            numeric: true,
            disablePadding: true,
            label: t("paymentAmount"),
          },
          {
            id: "averageViewers",
            numeric: true,
            disablePadding: true,
            label: t("averageViewers"),
          },
          {
            id: "timePlaying",
            numeric: true,
            disablePadding: true,
            label: t("timePlaying"),
          },
          {
            id: "completionStatus",
            numeric: false,
            disablePadding: true,
            label: t("status"),
          },
          {
            id: "dateAccepted",
            numeric: false,
            disablePadding: true,
            label: t("dateAccepted"),
          },
          {
            id: "actions",
            numeric: false,
            disablePadding: true,
            label: t("actions"),
          },
        ];
        break;
      default:
        tableHeaders = [
          {
            id: "username",
            numeric: false,
            disablePadding: false,
            label: "Noiz",
          },
          {
            id: "link",
            numeric: false,
            disablePadding: false,
            label: t("link"),
          },
          {
            id: "dateAccepted",
            numeric: false,
            disablePadding: false,
            label: t("dateAccepted"),
          },
        ];
        break;
    }

    if (usersTab === 0 && keyDistribute) {
      tableHeaders.push({
        id: "gameKey",
        numeric: false,
        disablePadding: true,
        label: "",
      });
    }

    return tableHeaders;
  };

  // Define data for csv download; return object with headers and data

  renderFirstRow = (approvedUsers, leftQuestUsers) => {
    const { usersTab } = this.state;
    const { t } = this.props;
    return (
      <div
        className="Global__subTabs"
        style={{ padding: "0px 30px 10px 30px" }}
      >
        <div
          className={classNames("Global__subTab", { active: usersTab === 0 })}
          onClick={() => this.setState({ usersTab: 0 })}
          aria-hidden
        >
          {t("inQuest")} ({approvedUsers.length})
        </div>
        {leftQuestUsers.length ? (
          <div
            className={classNames("Global__subTab", { active: usersTab === 1 })}
            onClick={() => this.setState({ usersTab: 1 })}
            aria-hidden
          >
            {t("leftQuest")} ({leftQuestUsers.length})
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const { questType, users, quest, userQuests, t } = this.props;
    const { twitchAccountRequired } = quest;
    const { usersTab } = this.state;

    let platform = "twitch";
    if (twitchAccountRequired) {
      platform = "twitch";
    }

    users.forEach((user) => {
      const userQuest = find(userQuests, (o) => o.user.id === user.id);
      user.completionStatus = getCompletionStatus(
        quest,
        userQuest,
        user.timePlaying
      );
      if (userQuest.chatDetails) {
        user.chatDetails = userQuest.chatDetails;
      }
    });

    const approvedUsers = users.filter((user) => user.status !== "leftQuest");
    const failedUsers = approvedUsers.filter(
      (user) => user.completionStatus.status === "F"
    );
    const leftQuestUsers = users.filter((user) => user.status === "leftQuest");
    const currentTabUsers = usersTab === 0 ? approvedUsers : leftQuestUsers;

    const keyDistribute = quest.keyRequired && keyDistributionStepExists(quest);

    const csv = getCSV(currentTabUsers, platform, quest, userQuests);
    const keysCSV = getKeys(
      [].concat(failedUsers, leftQuestUsers),
      platform,
      quest
    );

    const createSortHandler = (property) => (event) => {
      this.handleRequestSort(event, property);
    };

    return (
      <section className="ToggleTable">
        {currentTabUsers.length ? (
          <React.Fragment>
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">
                {t("users")}: <span>{currentTabUsers?.length}</span>
              </h3>
              {currentTabUsers?.length ? (
                <div className="ToggleTable__header-buttons">
                  <Tooltip title="Report CSV" placement="top">
                    <CSVLink
                      data={csv.dataCSV}
                      headers={csv.headers}
                      filename={
                        quest && quest.game
                          ? `${quest.game.name} - ${quest.title}.csv`
                          : "report.csv"
                      }
                    >
                      <i className="fa fa-download" />
                    </CSVLink>
                  </Tooltip>
                  {quest.keyRequired && (
                    <Tooltip title={t("keysToRevoke")} placement="top">
                      <CSVLink
                        data={keysCSV.dataCSV}
                        headers={keysCSV.headers}
                        filename={
                          quest && quest.game
                            ? `${quest.game.name} - ${quest.title} - KeysToRevoke.csv`
                            : "keysToRevoke.csv"
                        }
                      >
                        <i className="fa fa-key" />
                      </CSVLink>
                    </Tooltip>
                  )}
                </div>
              ) : null}
            </section>
            {this.renderFirstRow(approvedUsers, leftQuestUsers)}
            <Table>
              <TableHead>
                <TableRow>
                  {this.getTableHeaders(questType, platform, keyDistribute).map(
                    (headCell) => (
                      <TableHeaderCell
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection="asc"
                      >
                        <TableSortLabel
                          active={this.state.orderBy === headCell.id}
                          direction={this.state.order}
                          onClick={createSortHandler(headCell.id)}
                          IconComponent={ArrowDropDownIcon}
                          style={{ marginLeft: "30px" }}
                        >
                          {headCell.label}
                          {this.state.orderBy === headCell.id ? (
                            <span style={{ display: "none" }}>
                              {this.state.order === "desc"
                                ? t("sortedDescending")
                                : t("sortedAscending")}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableHeaderCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.getUserRows(
                  currentTabUsers,
                  questType,
                  platform,
                  keyDistribute
                )}
              </TableBody>
            </Table>
          </React.Fragment>
        ) : (
          <div className="NoQuestTiered">{t("noJoined")}</div>
        )}
      </section>
    );
  }
}

QuestDetailsTable.propTypes = {};

export default withTranslation("questDetailsTable")(QuestDetailsTable);
